export const getRemainingMillisecondsToMinute = (now = new Date()) => {
  return 60000 - now.getSeconds() * 1000 - now.getMilliseconds()
}

export const setMinuteListener = (fn, timeout = getRemainingMillisecondsToMinute()) => {
  fn()
  const ref = {}
  setTimeout(() => {
    const interval = setInterval(fn, 60000)
    ref.clearInterval = () => clearInterval(interval)
  }, timeout)
  return ref
}
