import axios from 'axios'

export const checkAppVersionAndUpdate = async () => {
  const currentVersion = process.env.REACT_APP_VERSION
  if (!currentVersion) {
    return
  }

  const bucketUrl = `${process.env.REACT_APP_FRONTEND_URL}/index.html`
  try {
    const response = await axios.get(bucketUrl)

    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(response.data, 'text/html')
    const latestAvailableVersion = getVersionFromDoc(htmlDoc)

    if (currentVersion !== latestAvailableVersion) {
      window.location.reload(true)
    }
  } catch (err) {
    console.error('Unable to access remote index.html file. GET request made to', bucketUrl, 'failed.')
  }
}

const getVersionFromDoc = (htmlDoc) => {
  const metaTags = htmlDoc.getElementsByTagName('meta')

  for (let i in metaTags) {
    let tag = metaTags[i]
    if (tag.name === 'version') {
      return tag.content
    }
  }
}
