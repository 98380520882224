import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { BlurLayer, ReusableButton } from 'gipsy-ui'

import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { getSrc } from 'utils/image'

const componentName = 'OnboardingVideoOverlay'

export default function OnboardingVideoOverlay({ onButtonClicked }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pushShortcutsGroup([], componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  return (
    <StyledBlurLayer active name={'onboarding-video'}>
      <Content>
        <IconWrapper>
          <img alt={'Gipsybot Zen'} src={getSrc('/images/gipsybot-zen-filled.svg')} />
        </IconWrapper>
        <Title>{translations.onboarding.videoTutorial.title}</Title>
        <EmbedVideoContainer>
          <EmbedVideoWrapper>
            <EmbedVideo
              src='https://www.loom.com/embed/1bcb13e670214cc3b64849bb36b5faa4'
              frameBorder='0'
              autoPlay
              webkitAllowFullScreen
              mozAllowFullScreen
              allowFullScreen
            />
          </EmbedVideoWrapper>
        </EmbedVideoContainer>
        <StyledButton borderRadius={8} height={54} onClick={onButtonClicked} width={220}>
          {translations.onboarding.videoTutorial.button}
        </StyledButton>
      </Content>
    </StyledBlurLayer>
  )
}

const StyledBlurLayer = styled(BlurLayer)`
  align-items: center;
  display: flex;
  justify-content: center;

  &.onboarding-video-layer,
  &.onboarding-video-layer.active {
    ::before {
      background: ${styles.colors.textMediumDarkColor};
      opacity: 0.6;
      z-index: -1;
    }

    .onboarding-video-content {
      max-height: 752px;
      max-width: 1110px;
      overflow: visible;
      width: 70%;
    }
  }
`

StyledBlurLayer.displayName = 'StyledBlurLayer'

const Content = styled.div`
  background-color: white;
  border-radius: 10px;
  height: 100%;
  padding: 54px 35px 24px;
  position: relative;
  text-align: center;
  width: 100%;
`

Content.displayName = 'Content'

const IconWrapper = styled.div`
  height: 65px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  width: 65px;
`

IconWrapper.displayName = 'IconWrapper'

const Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 42px;
`

Title.displayName = 'Title'

const EmbedVideoContainer = styled.div`
  margin: 0 auto;
  max-height: 488px;
  max-width: 836px;
  width: 100%;
`

EmbedVideoContainer.displayName = 'EmbedVideoContainer'

const EmbedVideoWrapper = styled.div`
  height: 100%;
  padding-bottom: 58.5%;
  position: relative;
  width: 100%;
`

EmbedVideoWrapper.displayName = 'EmbedVideoWrapper'

const EmbedVideo = styled.iframe`
  border-radius: 12px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

EmbedVideo.displayName = 'EmbedVideo'

const StyledButton = styled(ReusableButton)`
  margin: 42px auto;
`

StyledButton.displayName = 'StyledButton'
