import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'

import { constants, styles, translations } from 'gipsy-misc'
import { FixedTooltip, Icon, SquareCheckbox } from 'gipsy-ui'

import { spinAnimation } from 'features/keyframes'

export default function AccountCalendar({
  calendar,
  email,
  onChangeDefaultCalendar,
  onOpenSettings,
  onToggleSelect,
  syncingMap,
}) {
  const refreshIconRef = useRef(null)

  const handleChange = () => {
    onToggleSelect(email, calendar)
  }

  const isDefaultCalendar = !!calendar.appDefaultWriteCalendar

  const handleSettingsClicked = (e) => {
    e.stopPropagation()

    if (isDefaultCalendar) {
      onOpenSettings()
    } else {
      onChangeDefaultCalendar(email, calendar)
    }
  }

  useLayoutEffect(() => {
    const iconEl = refreshIconRef.current

    if (!iconEl) return

    iconEl.style.animation = 'none'
    window.requestAnimationFrame(() => {
      iconEl.style.animation = ''
    })
  }, [syncingMap])

  const syncing = syncingMap[`${email}_${calendar.calendarId}`]

  return (
    <Container onClick={handleChange}>
      <NameContainer>
        <SquareCheckbox checked={!!calendar.isSelected} disabled={syncing} size={18} color={calendar.color} />
        <Name>{calendar.nameOverride || calendar.name}</Name>
        {syncing && (
          <RefreshIconWrapper ref={refreshIconRef}>
            <Icon fill={styles.colors.primaryColor} icon='Refresh' size={12} />
          </RefreshIconWrapper>
        )}
      </NameContainer>
      {calendar.hasWriteAccess && (
        <Settings onClick={handleSettingsClicked}>
          {isDefaultCalendar ? (
            <>
              <Default>{translations.calendarList.default}</Default>
              <FixedTooltip
                content={`${translations.calendarList.defaultTooltip}`}
                horizontalOffset={-6}
                portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
                style={{
                  padding: '10px 5px 10px 10px',
                  width: '250px',
                }}
                verticalOffset={-4}>
                <IconWrapper>
                  <StyledIcon icon={'Info'} size={12} />
                </IconWrapper>
              </FixedTooltip>
              <Icon icon='SettingsCog' size={14} hoverFill={styles.colors.primaryColor} />
            </>
          ) : (
            <SetDefault>{translations.calendarList.setDefault}</SetDefault>
          )}
        </Settings>
      )}
    </Container>
  )
}

const SetDefault = styled.span`
  color: ${styles.colors.primaryColor};
  display: none;
  font-size: 11px;
  text-transform: lowercase;
`

SetDefault.displayName = 'SetDefault'

const Container = styled.div`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;

  :hover {
    background: ${styles.colors.veryLightGrey};

    ${SetDefault} {
      display: block;
    }
  }
`

Container.displayName = 'Container'

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 8px;
  padding: 8px 0 8px 8px;
  overflow: hidden;
`

NameContainer.displayName = 'NameContainer'

const Name = styled.span`
  display: block;
  font-size: 14px;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

Name.displayName = 'Name'

const RefreshIconWrapper = styled.div`
  animation: ${spinAnimation} 1s ease-in-out infinite;
  flex-shrink: 0;
  margin: 2px 0 0 8px;
`

RefreshIconWrapper.displayName = 'RefreshIconWrapper'

const RefreshIcon = styled(Icon)``

RefreshIcon.displayName = 'RefreshIcon'

const Settings = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  padding: 8px 8px 8px 0;
`

Settings.displayName = 'Settings'

const Default = styled.span`
  color: ${styles.colors.darkGrey};
  display: block;
  margin-right: 8px;
  font-size: 10px;
  text-transform: uppercase;
`

Default.displayName = 'Default'

const StyledIcon = styled(Icon)`
  margin: 0 8px 0 0px;
`

StyledIcon.displayName = 'StyledIcon'

const IconWrapper = styled.div`
  position: relative;

  &:hover {
    ${StyledIcon} {
      path {
        fill: ${styles.colors.primaryColor};
      }
    }
  }
`

IconWrapper.displayName = 'IconWrapper'
