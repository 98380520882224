import React from 'react'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { Icon } from 'gipsy-ui'

import { Cell, ItemDuration, Row } from './commonUIComponents'

function ItemSession({ date, duration, time }) {
  return (
    <Row>
      <Cell></Cell>
      <SessionDate>
        <StyledIcon fill="none" icon="ClockOulined" size={13} stroke={styles.colors.textMediumDarkColor} />{' '}
        <SessionDay>{date}</SessionDay>
      </SessionDate>
      <Cell>{time}</Cell>
      <SessionLasted textAlign={'end'}>{translations.timeReports.lasted}</SessionLasted>
      <ItemDuration fontWeight={400} textAlign={'end'}>
        {duration}
      </ItemDuration>
    </Row>
  )
}

const SessionDate = styled(Cell)`
  align-items: center;
  display: flex;
  font-weight: 500;
`

const StyledIcon = styled(Icon)`
  margin-right: 22px;
`

const SessionDay = styled.span`
  margin-right: 30px;
  width: 130px;
`

const SessionLasted = styled(Cell)`
  font-weight: 400;
`

export default ItemSession
