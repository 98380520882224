import { css } from 'styled-components'

export const breakpoints = {
  phone: 480,
  'phone-wide': 600,
  tablet: 768,
  'tablet-wide': 944,
  desktop: 1264,
  'desktop-wide': 1900,
}

// Iterate through the sizes and create a media template
const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `

  return acc
}, {})

export default media
