import { types } from 'store/supportPanel/types'

const initialState = {
  loadChat: false,
  shown: false,
}

const supportPanelReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.HIDE_CHAT: {
      return {
        ...state,
        loadChat: false,
      }
    }

    case types.HIDE_SUPPORT_PANEL: {
      return initialState
    }

    case types.SHOW_CHAT: {
      return {
        loadChat: true,
        shown: true,
      }
    }

    case types.SHOW_SUPPORT_PANEL: {
      return {
        ...state,
        shown: true,
      }
    }

    default: {
      return state
    }
  }
}

export default supportPanelReducer
