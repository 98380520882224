import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { user as userApi } from 'gipsy-api'
import { models, styles, translations } from 'gipsy-misc'
import { Icon, Separator, SquareCheckbox, Switch } from 'gipsy-ui'

import {
  CloseIcon,
  Content,
  SavedContainer,
  StyledBlurLayer,
  Subtitle,
  Title,
  TitleContainer,
} from 'features/calendar/components/CalendarList/components/commonUIComponents'
import { layoutBlurLayerPortalId } from 'features/layout/index'
import RealTime from 'features/realTime'
import { setUser } from 'store/session/actions'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

const componentName = 'SettingsLightbox'

export default function SettingsLightbox({ onClose }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.session.user)

  const [isSaved, setSaved] = useState(false)

  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    dispatch(pushShortcutsGroup([], componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  const setSave = () => {
    setSaved(true)
    timeoutRef.current = setTimeout(() => {
      setSaved(false)
    }, 3000)
  }

  const updateCalendarPreferences = async (paramName, writePreferences) => {
    const newCalendarPreferences = { ...user.settingsPreferences.calendar }
    newCalendarPreferences[paramName] = writePreferences
    const newUser = { ...user }
    newUser.settingsPreferences.calendar = newCalendarPreferences
    dispatch(setUser(newUser))
    setSave()
    await userApi.patch(newCalendarPreferences, {
      calendarPreferences: true,
    })
    RealTime.publishMessage('', [models.realtime.topics.user])
  }

  const portalNode = document.querySelector(`#${layoutBlurLayerPortalId}`)
  const calendarPreferences = user.settingsPreferences.calendar

  return portalNode
    ? createPortal(
        <StyledBlurLayer active maxWidth='1200px' name='lightbox' onClose={onClose} width='75%'>
          <Content>
            <CloseIcon icon='Close' onClick={onClose} size={12} />
            <TitleContainer>
              <Title>{translations.calendarSettings.sections.writeIntegrations.title}</Title>
              {isSaved && (
                <SavedContainer>
                  <Icon icon='Save' fill={styles.colors.greenColor} size={12} style={{ marginRight: 8 }} />
                  <span>{translations.settings.section.common.saved}</span>
                </SavedContainer>
              )}
            </TitleContainer>
            <Subtitle>{translations.calendarSettings.sections.writeIntegrations.description}</Subtitle>
            <SettingsContainer>
              <SectionTitle title={translations.general.tasks} />
              <WritePreferences
                paramName='tasks'
                subtitle={translations.calendarSettings.sections.writeIntegrations.scheduledToDate}
                toggleText={translations.calendarSettings.sections.writeIntegrations.toggleScheduledToDate}
                updateCalendarPreferences={updateCalendarPreferences}
                writePreference={calendarPreferences.tasks}
              />
              <WritePreferences
                paramName='pinnedTasks'
                subtitle={translations.calendarSettings.sections.writeIntegrations.scheduledToTime}
                updateCalendarPreferences={updateCalendarPreferences}
                writePreference={calendarPreferences.pinnedTasks}
              />
              <SectionTitle title={translations.general.sprint.plural} />
              <WritePreferences
                paramName='sprints'
                updateCalendarPreferences={updateCalendarPreferences}
                writePreference={calendarPreferences.sprints}
              />
              <SectionTitle title={translations.calendarSettings.sections.writeIntegrations.completedAndFS} />
              <WritePreferences
                paramName='focusSessions'
                updateCalendarPreferences={updateCalendarPreferences}
                writePreference={calendarPreferences.focusSessions}
              />
            </SettingsContainer>
          </Content>
        </StyledBlurLayer>,
        portalNode
      )
    : null
}

const SettingsContainer = styled.div`
  overflow: auto;
  padding-left: 16px;
`

SettingsContainer.displayName = 'SettingsContainer'

function SectionTitle({ title }) {
  return (
    <SectionTitleContainer>
      <TitleSpan>{title}</TitleSpan>
      <Separator />
    </SectionTitleContainer>
  )
}

export const SectionTitleContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 20px;
  margin-top: 30px;

  ${Separator} {
    flex-grow: 1;
    margin-left: 20px;
  }
`

export const TitleSpan = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${styles.colors.textDarkColor};
  flex-grow: 0;
  flex-shrink: 1;
`

function WritePreferences({ subtitle, writePreference, updateCalendarPreferences, paramName, toggleText }) {
  const toggleWriteInCalendar = ({ value }) => {
    const newWritePreferences = { ...writePreference, writeInCalendar: !!value }
    updateCalendarPreferences(paramName, newWritePreferences)
  }

  const onChangeCheckbox = ({ value }) => {
    const newWritePreferences = { ...writePreference, writeBusy: !!value }
    updateCalendarPreferences(paramName, newWritePreferences)
  }

  return (
    <WritePreferencesContainer>
      {subtitle && <SubtitleContainer>{subtitle}</SubtitleContainer>}
      <ToggleLineContainer>
        <Switch
          size={'medium'}
          paramName='writeInCalendar'
          onChange={toggleWriteInCalendar}
          value={writePreference.writeInCalendar}></Switch>
        <TextContainer>
          {toggleText || translations.calendarSettings.sections.writeIntegrations.displayIn}
        </TextContainer>
      </ToggleLineContainer>
      <CheckLineContainer inactive={!writePreference.writeInCalendar}>
        <SquareCheckbox checked={writePreference.writeBusy} onChange={onChangeCheckbox} size={16} />
        <TextContainer>{translations.calendarSettings.sections.writeIntegrations.hideTitle}</TextContainer>
      </CheckLineContainer>
    </WritePreferencesContainer>
  )
}

const WritePreferencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-bottom: 20px;
`

WritePreferencesContainer.displayName = 'WritePreferencesContainer'

const SubtitleContainer = styled.span`
  align-items: center;
  color: ${styles.colors.textDarkColor};
  display: flex;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 15px;
`

SubtitleContainer.displayName = 'SubtitleContainer'

const ToggleLineContainer = styled.div`
  align-items: center;
  display: flex;
`

ToggleLineContainer.displayName = 'ToggleLineContainer'

const CheckLineContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: 55px;
  margin-top: 20px;

  ${(props) =>
    props.inactive &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`

CheckLineContainer.displayName = 'CheckLineContainer'

const TextContainer = styled.span`
  margin-left: 15px;
  font-size: 15px;
  color: ${styles.colors.textDarkColor};
`

TextContainer.displayName = 'TextContainer'
