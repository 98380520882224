/* eslint-disable no-throw-literal */
import { createAction } from 'redux-actions'
import { types } from 'store/project/types'
import { findInListById } from 'logic/list'

const _updateProject = createAction(types.UPDATE_PROJECT)
const _reorderProject = createAction(types.REORDER_PROJECT)
const _removeProject = createAction(types.REMOVE_PROJECT)
const _addProject = createAction(types.ADD_PROJECT)
const _fetchProjects = createAction(types.FETCH_PROJECTS)
const _setProjectsLoaded = createAction(types.SET_PROJECTS_LOADED)

/* ************************************************************* */
/* ****************** Actions - Async Request ****************** */
/* ************************************************************* */
export const addProject = (project, immediate) => async (dispatch, getState, { api }) => {
  if (immediate) {
    const tempKey = project.id
    dispatch(_addProject({ project }))
    const _project = await api.projects.create(project)
    dispatch(_updateProject({ project: _project, projectId: tempKey }))
  } else {
    const _project = await api.projects.create(project)
    dispatch(_addProject({ project: _project }))
  }
}

export const archiveProject = (projectId) => async (dispatch, getState, { api }) => {
  dispatch(_removeProject({ projectId }))
  await api.projects.archive(projectId)
}

export const unarchiveProject = (project) => async (dispatch, getState, { api }) => {
  dispatch(_addProject({ project }))
  await api.projects.unarchive(project.id)
}

export const updateProject = (project) => async (dispatch, getState, { api }) => {
  dispatch(_updateProject({ project: project, projectId: project.id }))
  await api.projects.edit(project)
}

export const removeProject = (projectId) => async (dispatch, getState, { api }) => {
  dispatch(_removeProject({ projectId }))
  await api.projects.del(projectId)
}

export const dragAndDropProject = ({ draggableId, source, destination }) => async (dispatch, getState, { api }) => {
  const droppedProject = findInListById(getState().projects.items, draggableId)
  dispatch(_reorderProject({ project: droppedProject, from: source.index, to: destination.index }))
  await api.projects.dragAndDropProject(draggableId, destination.index + 1)
}

export const fetchActiveProjects = () => async (dispatch, getState, { api }) => {
  const projects = await api.projects.get(api.projects.activeParam)
  dispatch(_fetchProjects({ projects }))
  dispatch(_setProjectsLoaded(true))
}
