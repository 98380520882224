import React from 'react'
import styled from 'styled-components'

import { styles, utils } from 'gipsy-misc'

function CalendarEventInfo({ left, color, height, isPast, pin, estimatedTime, date, showTimeOnly }) {
  let hour, minute
  if (estimatedTime) {
    const estimatedTimeObj = utils.datetime.getHourAndMinuteFromNanoseconds(estimatedTime)
    hour = estimatedTimeObj.hour
    minute = estimatedTimeObj.minute
  }

  return pin ? (
    <EstimatedTimeContainer color={isPast ? styles.colors.pinkColor : color} height={height} left={left}>
      {!showTimeOnly && (isPast || !utils.datetime.isToday(pin.time)) ? (
        <React.Fragment>
          <SmallTextContainer>{utils.datetime.humanizeDate(pin && pin.time)} </SmallTextContainer>
          <SmallTextContainer>{utils.datetime.beautifyTime(pin && pin.time)}</SmallTextContainer>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <BigTextContainer>{utils.datetime.beautifyTime(pin && pin.time)} </BigTextContainer>
          {estimatedTime && (
            <SmallTextContainer>
              {!!hour && `${hour}h `}
              {!!minute && `${minute}min`}
            </SmallTextContainer>
          )}
        </React.Fragment>
      )}
    </EstimatedTimeContainer>
  ) : (
    <EstimatedTimeContainer color={isPast ? styles.colors.pinkColor : color} height={height} left={left}>
      <SmallTextContainer>{utils.datetime.humanizeDate(date)} </SmallTextContainer>
    </EstimatedTimeContainer>
  )
}

export default React.memo(CalendarEventInfo)

export const EstimatedTimeContainer = styled.div`
  position: absolute;
  top: 0;
  width: 60px;
  height: ${({ height }) => height || '100%'};
  left: ${(props) => props.left || 0}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
  color: ${(props) => props.color || styles.colors.darkGrey};
  span {
    text-align: right;
  }
`

const BigTextContainer = styled.span`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-transform: lowercase;
`

const SmallTextContainer = styled.span`
  font-size: 10px;
`
