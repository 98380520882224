import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import clsx from 'clsx'

import { styles, translations } from 'gipsy-misc'

import Icon from 'Icon'

import { IconContainer } from './RecurrenceDropdownSelector'

export default function TaskSelectInput({
  active,
  className,
  isSingleInstance,
  onClear,
  onClick,
  onClickOutside,
  placeholder,
  task,
}) {
  const containerRef = useRef(null)

  const handleClearClicked = (e) => {
    e.stopPropagation()
    onClear()
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current?.contains?.(e.target)) return
      onClickOutside?.(e)
    }

    window.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClickOutside])

  const isTaskRecurrent = task?.recurrencyInformation

  return (
    <Container active={active} className={clsx('TaskSelectInput', className)} onClick={onClick} ref={containerRef}>
      {task ? (
        <>
          {isTaskRecurrent && <IconContainer fill={styles.colors.darkGrey} icon='Repeat' size={13} />}
          <TitleContainer>
            <TaskTitle>{task.title}</TaskTitle>
            {isTaskRecurrent && (
              <InstanceIndicator>
                ({isSingleInstance ? translations.general.individualInstance : translations.general.allInstances})
              </InstanceIndicator>
            )}
          </TitleContainer>
          <ClearIcon fill={styles.colors.textMediumDarkColor} icon='Close' onClick={handleClearClicked} size={10} />
        </>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
      <InputCaret active={active} fill={styles.colors.textMediumDarkColor} icon='CaretDown' size={10} />
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  height: 40px;
  line-height: 16px;
  padding: 12px 16px;
  width: 100%;

  ${({ active }) =>
    active &&
    css`
      border-color: ${styles.colors.primaryColor};
    `}
`

Container.displayName = 'Container'

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
`

TitleContainer.displayName = 'TitleContainer'

const TaskTitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16px;
`

TaskTitle.displayName = 'TaskTitle'

const InstanceIndicator = styled.span`
  display: block;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`

InstanceIndicator.displayName = 'InstanceIndicator'

const Placeholder = styled.span`
  color: ${styles.colors.darkGrey};
`

Placeholder.displayName = 'Placeholder'

const ClearIcon = styled(Icon)`
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 18px;
  margin-right: 14px;
`

ClearIcon.displayName = 'ClearIcon'

const InputCaret = styled(Icon)`
  flex-shrink: 0;
  margin-left: auto;

  ${({ active }) =>
    active &&
    css`
      transform: rotate(180deg);
    `}
`

InputCaret.displayName = 'InputCaret'
