import { random } from 'utils/string'

class Resolve {
  constructor(delay = 1000) {
    this.delay = delay
    /* { id: { timeout, promise } } */
    this.pending = {}
  }

  resolveNext = (id) => {
    /* in case of manual cancel */
    if (!this.pending[id]) {
      return
    }
    const { resolve, reject } = this.pending[id]
    const result = this.pending[id].next()

    /* if next returns a promise */
    if (resolve && reject && result && result.then) {
      result.then(resolve).catch(reject)
    }
    delete this.pending[id]
  }

  /*
   ** !!! BE CAREFUL : make sure to wrap this call inside try/catch : prevent loading bundle.js on unhandled promise rejection !!!
   ** call this method if you want to execute your "next" promise function only once after a delay
   ** next should be a function returning a promise (ie: api.todolist.get),
   ** this method will resolve | reject your promise
   */
  awaitOnce = (id, next, delay = this.delay) => {
    if (typeof next !== 'function') {
      return Promise.reject('2nd parameter should be a function')
    }
    return new Promise((resolve, reject) => {
      id = id || random()
      if (this.pending[id]) {
        clearTimeout(this.pending[id].timeout)
        /* IMPORTANT : WRAP your call inside try/catch */
        this.pending[id].reject({ id, cancel: true })
      }

      this.pending[id] = {
        resolve,
        reject,
        next,
        timeout: setTimeout(() => this.resolveNext(id), delay),
      }
    })
  }

  /*
   ** call this method if you want to execute your "next" callback only once after a delay
   */
  once = (id, next, delay = this.delay) => {
    if (typeof next !== 'function') {
      return
    }
    id = id || random()
    if (this.pending[id]) {
      clearTimeout(this.pending[id].timeout)
    }

    this.pending[id] = {
      next,
      timeout: setTimeout(() => this.resolveNext(id), delay),
    }
  }

  cancel = (id) => {
    if (this.pending[id] && this.pending[id].timeout) {
      clearTimeout(this.pending[id].timeout)
    }
    if (this.pending[id] && this.pending[id].reject) {
      this.pending[id].reject({ id, cancel: true })
    }
    delete this.pending[id]
  }
}

export default new Resolve()
