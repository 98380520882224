import React from 'react'
import Select from 'react-select'

import { styles } from 'gipsy-misc'

const getCustomStyles = (propsStyles = {}) => {
  return {
    control: (provided) => {
      return {
        // none of react-select's styles are passed to <Control />
        ...provided,
        width: 220,
        backgroundColor: 'inherit',
        borderRadius: '8px',
        border: `1px solid ${styles.colors.middleGrey}`,
        boxShadow: 'none',
        '&:hover': { borderColor: styles.colors.darkGrey },
        ...propsStyles.control,
      }
    },
    container: (provided) => ({
      ...provided,
      ...propsStyles.container,
    }),
    option: (provided, state) => {
      const { isSelected } = state
      return {
        ...provided,
        backgroundColor: isSelected ? styles.colors.middleGrey : 'transparent',
        color: 'inherit',
        fontWeight: 400,
        '&:hover': { backgroundColor: styles.colors.darkGrey },
        ...propsStyles.option,
      }
    },
    placeholder: (provided) => ({
      ...provided,
      color: styles.colors.darkGrey,
      fontSize: '15px',
      ...propsStyles.placeholder,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      color: styles.colors.middleGrey,
      ...propsStyles.indicatorSeparator,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: styles.colors.middleGrey,
      '&:hover': { color: styles.colors.darkGrey },
      ...propsStyles.clearIndicator,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: styles.colors.textMediumDarkColor,
      '&:hover': { color: styles.colors.textMediumDarkColor },
      ...propsStyles.dropdownIndicator,
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        color: styles.colors.textMediumDarkColor,
        fontSize: '15px',
        fontWeight: 400,
        ...propsStyles.singleValue,
      }
    },
  }
}

const StyledSelect = ({ onChange, options, defaultOption, isClearable, styles, value }) => {
  const extraProps = {
    styles: getCustomStyles(styles),
  }

  if (value) {
    extraProps.value = value
  }

  return (
    <Select
      isDisabled={false}
      isClearable={isClearable}
      isSearchable={false}
      options={options}
      defaultValue={defaultOption}
      onChange={onChange}
      {...extraProps}
    />
  )
}

export default StyledSelect
