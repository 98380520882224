import axios from 'utils/axios'
import { utils } from 'gipsy-misc'
import listeners from './listeners'

export function getAuthURL(provider, forceConsent = false, redirectUrl = null, loginHint) {
  utils.provider.validateProvider(provider)
  let url = `/oauth/${provider}/get-authurl`
  if (forceConsent) {
    url = utils.request.appendToUrl(url, { forceConsent: true })
  }
  if (redirectUrl) {
    url = utils.request.appendToUrl(url, { redirectUrl })
  }
  if (loginHint) {
    url = utils.request.appendToUrl(url, { loginHint })
  }
  return axios.get(url)
}

export async function authenticate(provider, code, nonce, tz, redirectUrl = null, utmInfos) {
  utils.provider.validateProvider(provider)
  const body = code
  let url = `/oauth/${provider}/authenticate?nonce=${nonce}&timezone=${tz}`
  if (redirectUrl) {
    url = utils.request.appendToUrl(url, { redirectUrl })
  }
  url = utils.request.appendToUrl(url, utmInfos)

  const res = await axios.post(url, body)
  const { idToken, refreshToken } = res
  listeners.onTokenChange({ idToken, refreshToken })

  return res
}

export async function authenticateMobile(provider, code, tz) {
  utils.provider.validateProvider(provider)
  const body = code
  let url = `/oauth/${provider}/mobile/authenticate?&timezone=${tz}`
  const res = await axios.post(url, body)
  const { idToken, refreshToken } = res
  listeners.onTokenChange({ idToken, refreshToken })

  return res
}

export function signout() {
  const url = `/authenticate/signout`
  listeners.onTokenChange({ idToken: null, refreshToken: null })

  return axios.get(url)
}

export function get(tz) {
  const url = `/authenticate/session?timezone=${tz}`
  return axios.get(url)
}
