import React, { useRef, useState, useCallback } from 'react'
import { Transition } from 'react-transition-group'
import styled, { css, keyframes } from 'styled-components'
import Icon from 'Icon'
import { styles, translations } from 'gipsy-misc'
import { PopupItem as PopupItemContainer, PopupItemContent } from 'Popup/components'

export const creationItemHeight = 47

const getOpacityFromTransitionState = ({ transitionState }) => {
  switch (transitionState) {
    case 'entered': {
      return css`
        opacity: 1;
      `
    }
    case 'exiting': {
      return css`
        opacity: 0;
      `
    }
    case 'exited': {
      return css`
        display: none;
      `
    }
    default: {
      return css``
    }
  }
}

function JustCopiedMessage(props) {
  return (
    <JustCopiedMessageContainer transitionState={props.transitionState}>
      <TickIcon icon={'Tick'} size={10} fill={styles.colors.greenColor} />
      <JustCopiedMessageText>{translations.task.urlInfo.copied}</JustCopiedMessageText>
    </JustCopiedMessageContainer>
  )
}

const TickIcon = styled(Icon)`
  & path {
    fill: ${styles.colors.greenColor} !important;
  }
  margin-right: 4px;
  margin-left: 4px;
`

const JustCopiedMessageContainer = styled.span`
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 32px;
  ${getOpacityFromTransitionState};
`

const JustCopiedMessageText = styled.span`
  color: ${styles.colors.greenColor};
  margin-right: 8px;
`

const fadeIn = keyframes`   
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const ActionIcon = styled(Icon)`
  animation: ${fadeIn} 0.25s ease-in-out forwards;
`

const ActionIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  line-height: 32px;
  :not(:last-of-type) {
    margin-right: 12px;
  }
  ${(props) =>
    props.hoverColor &&
    css`
      ${ActionIcon}:hover {
        path {
          fill: ${props.hoverColor} !important;
        }
      }
    `}
`

export const ModifyComponent = ({
  className,
  onClickDelete,
  onClickToggleEdit,
  onClickCopy,
  iconSize = 12,
  iconHoverColor,
  readOnly,
  disableDelete = false,
}) => {
  const justCopiedMessageTimeOut = useRef(null)
  const [isJustCopied, setJustCopied] = useState(false)

  const showJustCopiedMessage = useCallback(() => {
    setJustCopied(true)
    if (justCopiedMessageTimeOut.current) clearTimeout(justCopiedMessageTimeOut.current)
    justCopiedMessageTimeOut.current = setTimeout(() => setJustCopied(false), 2500)
  }, [])

  const _onClickCopy = useCallback(
    (e) => {
      e.persist && e.persist()
      onClickCopy(e)
      showJustCopiedMessage()
    },
    [showJustCopiedMessage, onClickCopy]
  )

  return (
    <ModifyComponentContainer className={className}>
      <>
        {onClickCopy && (
          <Transition
            in={isJustCopied}
            timeout={{
              enter: 250,
              exit: 150,
            }}>
            {(transitionState) => (
              <>
                <JustCopiedMessage transitionState={transitionState} />
                {transitionState === 'exited' && (
                  <ActionIconContainer hoverColor={iconHoverColor} onClick={_onClickCopy}>
                    <ActionIcon fadeIn icon={'Copy'} size={iconSize} fill={styles.colors.darkGrey} />
                  </ActionIconContainer>
                )}
              </>
            )}
          </Transition>
        )}
        {readOnly ? null : (
          <>
            <ActionIconContainer hoverColor={iconHoverColor} onClick={onClickToggleEdit}>
              <ActionIcon icon={'Edit'} size={iconSize} fill={styles.colors.darkGrey} />
            </ActionIconContainer>
            {!disableDelete && (
              <ActionIconContainer hoverColor={iconHoverColor} onClick={onClickDelete}>
                <ActionIcon icon={'Trash'} size={iconSize} fill={styles.colors.darkGrey} />
              </ActionIconContainer>
            )}
          </>
        )}
      </>
    </ModifyComponentContainer>
  )
}

const ModifyComponentContainer = styled.div`
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
  display: none;
`

const StyledPopupItemContainer = styled(PopupItemContainer)`
  padding: 0;
  width: 100%;
  height: ${creationItemHeight}px;
  :hover {
    ${ModifyComponentContainer} {
      display: flex;
      path {
        fill: ${styles.colors.darkGrey};
      }
    }
  }
`

export const LinePopupItemContainer = React.forwardRef((props, ref) => {
  return (
    <StyledPopupItemContainer ref={ref} hoverBackgroundColor={'white'} {...props}>
      {props.children}
    </StyledPopupItemContainer>
  )
})

export const OkButton = styled.div`
  background-color: ${styles.colors.veryLightGrey};
  color: ${styles.colors.textMediumDarkColor};
  cursor: pointer;
  font-size: ${styles.fonts.fontSizeSmall};
  border-radius: 8px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || 48}px;
  height: 32px;
  margin-left: auto;
`

export const LinePopupItemContent = styled(PopupItemContent)`
  padding: 7px ${(props) => props.lateralPadding || 16}px;
`
