import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import Icon from 'Icon'
import { LineContainer, LineContentContainer } from 'LineView/components/commonUIComponents'
import { CustomPopup, PopupItem } from 'Popup/components'
import SideIconContainer from 'LineComponents/SideIconContainer'
import { Controls } from 'SprintTaskCompressedLine/Controls'
import { Title } from 'SprintTaskCompressedLine/Title'

import { translations, utils } from 'gipsy-misc'
import moment from 'moment'

const moreIconSize = 16

export default function LineViewCompressed({
  innerLeftPadding,
  innerRightPadding,
  item,
  sprint,
  keepJustCompleted,
  onComplete,
  disableUncomplete,
  onDelete,
  onRemove,
  onStartFocus,
  session,
}) {
  const [isHovered, setHovered] = useState(false)
  const [showActionsPopup, setShowActionsPopup] = useState(false)
  const [isTitleStruckThrough, setIsTitleStruckThrough] = useState(false)
  const lineRef = useRef()

  const moreButtonRef = useRef(null)

  const _onStartFocus = ({ urlsToOpen }) => {
    onStartFocus({
      taskId: item.id,
      task: item,
      startTime: moment(),
      urlsToOpen,
    })
  }

  const onMouseEnter = () => setHovered(true)

  const onMouseLeave = () => {
    setHovered(false)
    setShowActionsPopup(false)
  }

  const onClickMoreActionsButton = () => setShowActionsPopup(!showActionsPopup)

  const onJustCompleted = useCallback(() => {
    setIsTitleStruckThrough(true)
  }, [])

  const focusedTaskId = session?.focusSession?.taskId
  const isTaskOnFocusSession = focusedTaskId === item.id
  const showMoreActionsButton = !isTaskOnFocusSession && onDelete

  const lineContainerClasses = 'line-compressed-container fs-mask'
  const isPastItem = utils.task.isPast(item)

  if (isTaskOnFocusSession) return null

  return (
    <StyledLineContainer
      ref={lineRef}
      className={lineContainerClasses}
      data-itemid={item.id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      paddingLeft={innerLeftPadding}
      paddingRight={innerRightPadding}>
      <CompressedContainer>
        <Controls
          onStartFocus={_onStartFocus}
          item={item}
          disableUncomplete={disableUncomplete}
          sprintId={sprint?.id}
          onComplete={onComplete}
          onJustCompleted={onJustCompleted}
          keepJustCompleted={keepJustCompleted}
          focusedTaskId={focusedTaskId}
        />
        <Title
          item={item}
          title={item.title}
          whenData={item.when?.date}
          isPastItem={isPastItem}
          isStruckThrough={isTitleStruckThrough || item.completed}
        />
      </CompressedContainer>
      {showMoreActionsButton && (
        <MoreIconContainer onClick={onClickMoreActionsButton} ref={moreButtonRef} shown={isHovered}>
          <Icon icon='More' size={moreIconSize} />
        </MoreIconContainer>
      )}
      {showMoreActionsButton && showActionsPopup && (
        <StyledCustomPopup left={`100%`}>
          {onRemove && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => onRemove(item)}>
              <span>{translations.general.remove}</span>
            </PopupItem>
          )}
          {onDelete && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => onDelete(item)}>
              <span>{translations.general.delete}</span>
            </PopupItem>
          )}
        </StyledCustomPopup>
      )}
    </StyledLineContainer>
  )
}

const StyledLineContainer = styled(LineContainer)`
  direction: ltr;
  position: static;
`

const StyledCustomPopup = styled(CustomPopup)``

const moreIconSectionWidth = 28

const CompressedContainer = styled(LineContentContainer)`
  align-items: center;
  display: grid;
  grid-template-columns: 75px auto;
  width: calc(100% - ${moreIconSectionWidth}px);
  box-shadow: unset;
  :hover {
    box-shadow: 0 1px 1px rgba(33, 21, 81, 0.08);
  }
`

const MoreIconContainer = styled(SideIconContainer)`
  margin-left: 8px;
  display: flex;
  align-items: center;
  z-index: 1;
  position: static;
  cursor: ${({ shown }) => (shown ? 'pointer' : 'auto')};
`
