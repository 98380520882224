import { createAction } from 'redux-actions'

import { types } from 'store/taskPanel/types'

const _addFilteredId = createAction(types.ADD_FILTERED_ID)
const _removeFilteredId = createAction(types.REMOVE_FILTERED_ID)
const _replaceFilteredId = createAction(types.REPLACE_FILTERED_ID)
const _resetFilteredIds = createAction(types.RESET_FILTERED_IDS)

export const addFilteredId = (id) => (dispatch) => dispatch(_addFilteredId(id))
export const removeFilteredId = (id) => (dispatch) => dispatch(_removeFilteredId(id))
export const replaceFilteredId = (id, replacementId) => (dispatch) =>
  dispatch(_replaceFilteredId({ id, replacementId }))
export const resetFilteredIds = () => (dispatch) => dispatch(_resetFilteredIds())
