import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import EditingLine from './EditingLine'
import { buildCreatingItem, convertItemPropToArray } from './utils'
import { models, translations, utils } from 'gipsy-misc'

import { recurrenceComponentsUtils } from 'Recurrence'

const componentName = 'EditingLine'
const { DailyScheduleOptions } = models.recurrency
function EditingLineContainer(props) {
  const {
    componentSource,
    onCreate,
    onSave,
    onCancel,
    onCancelEdit,
    session,
    onDelete,
    item,
    innerRef,
    hideScheduleSection,
    hideSpentTimeInput,
    registerShortcuts,
    unregisterShortcuts,
    isCreating,
    ignoreOutsideClicks,
    onTogglePin,
    clearHighLightedEvent,
    activeTags,
    allProjects,
    onChange,
    onUpdateFocusSession,
    onDeleteFocusSession,
    renderCreateBottomButtons,
    renderEditBottomButtons,
    history,
    getCurrentPathName,
    readOnlyDate,
    inChromeExtension,
    hideBlockToCalendarOption,
    marginBottom,
    marginLeft,
    marginRight,
    activeSprintsMap,
    canBlockToCalendar,
    onClickFocusSession,
    hideSprint,
    startSprintCreation,
    onStartFocus,
    onStartFsAndCreate,
    onClick,
    hideCreateButton,
    hideDateInput,
    keepTitleAfterCreation,
    verticalView,
    when: whenProp,
    pin: pinProp,
    urlInfo: urlInfoProp,
    project: projectProp,
    tag: tagProp,
    title: titleProp,
    estimatedTime: estimatedTimeProp,
  } = props

  const creatingModeProps = useMemo(
    () => ({
      when: whenProp,
      urlInfo: urlInfoProp,
      project: projectProp,
      tag: tagProp,
      title: titleProp || '',
      session,
    }),
    [whenProp, urlInfoProp, projectProp, titleProp, session, tagProp]
  )

  const [editingItem, setEditingItem] = useState(item ?? buildCreatingItem(creatingModeProps))
  const [isTitleFocused, setIsTitleFocused] = useState(false)
  const [lastDateSet, setLastDateSet] = useState({
    pin: editingItem.pin,
    when: editingItem.when,
  })
  const [selectedRecurrency, setSelectedRecurrency] = useState(
    recurrenceComponentsUtils.getRecurrencyOptionsFromRecurrencyInformation(item)
  )

  const lineRef = useRef(null)

  const sessionId = session.id
  const itemId = editingItem?.id
  const itemFocusSessions = item?.focusSessions

  const [isFocusSessionsPopupShown, setIsFocusSessionsPopupShown] = useState(false)

  const createItem = useCallback(
    ({ eventName } = {}) => {
      const itemData = utils.task.buildTaskToCreate(editingItem, sessionId)
      if (getCurrentPathName) {
        onCreate(itemData, history, {
          componentSource: 'inlineAddTask',
          pageSource: getCurrentPathName(),
          eventName,
        })
      } else {
        onCreate(itemData, null, { eventName })
      }

      setEditingItem((editingItem) => {
        const newEditingItem = {
          ...buildCreatingItem(creatingModeProps),
        }
        if (keepTitleAfterCreation) {
          newEditingItem.title = editingItem.title
        }
        return newEditingItem
      })
    },
    [editingItem, sessionId, onCreate, history, getCurrentPathName, keepTitleAfterCreation, creatingModeProps]
  )

  const startFsAndCreateTask = useCallback(() => {
    const itemData = utils.task.buildTaskToCreate(editingItem, sessionId)
    return onStartFsAndCreate(itemData, componentSource)
  }, [componentSource, editingItem, onStartFsAndCreate, sessionId])

  const saveItem = useCallback(async () => {
    if (editingItem.tags) {
      editingItem.tagsId = editingItem.tags.map((el) => el.id)
    }

    if (editingItem.projects) {
      editingItem.projectsId = editingItem.projects.map((el) => el.id)
    }

    await onSave(editingItem)
  }, [editingItem, onSave])

  const hideFocusSessionsPopup = useCallback(() => {
    setIsFocusSessionsPopupShown(false)
    clearHighLightedEvent?.()
  }, [clearHighLightedEvent])

  useEffect(() => {
    if (isCreating) {
      setEditingItem((editingItem) => ({
        ...editingItem,
        projects: editingItem.projects ?? convertItemPropToArray(projectProp),
      }))
    }
  }, [projectProp, isCreating])

  useEffect(() => {
    if (isCreating) {
      setEditingItem((editingItem) => ({
        ...editingItem,
        tags: editingItem.tags ?? convertItemPropToArray(tagProp),
      }))
    }
  }, [tagProp, isCreating])

  useEffect(() => {
    if (isCreating) {
      setEditingItem((editingItem) => ({
        ...editingItem,
        urlsInfo: editingItem.urlsInfo ?? convertItemPropToArray(urlInfoProp),
      }))
    }
  }, [urlInfoProp, isCreating])

  useEffect(() => {
    setEditingItem((editingItem) => ({
      ...editingItem,
      when: whenProp ?? editingItem.when ?? { date: '' },
    }))
  }, [whenProp, isCreating])

  useEffect(() => {
    if (isCreating) {
      setEditingItem((editingItem) => ({
        ...editingItem,
        title: editingItem.title ?? titleProp,
      }))
    }
  }, [titleProp, isCreating])

  useEffect(() => {
    setEditingItem((editingItem) => ({
      ...editingItem,
      when: pinProp
        ? {
            date: moment(pinProp.time).format('YYYY-MM-DD'),
          }
        : editingItem.when,
      pin: pinProp,
      estimatedTime: estimatedTimeProp || 0,
    }))
  }, [estimatedTimeProp, pinProp])

  useEffect(() => {
    if (registerShortcuts) {
      const shortcuts = [
        {
          key: 'Enter',
          label: isCreating ? translations.general.create : translations.general.save,
          callback: () => (isCreating ? createItem() : saveItem()),
        },
        {
          key: 'Escape',
          label: translations.general.cancel,
          callback: () => (isCreating ? onCancel() : onCancelEdit()),
        },
      ]

      if (isTitleFocused) {
        shortcuts.push(
          {
            key: '#',
            label: translations.general.openProjects,
          },
          {
            key: '$',
            label: translations.general.openTags,
          }
        )
      }

      registerShortcuts(shortcuts, componentName)
      return () => unregisterShortcuts?.(componentName)
    }
  }, [createItem, isCreating, isTitleFocused, onCancel, onCancelEdit, registerShortcuts, saveItem, unregisterShortcuts])

  useEffect(() => {
    function handleClickOutsideWhenEdit(e) {
      const activeBlurLayer = document.querySelector('.blur-layer.active')
      const recurrencePresets = document.querySelector('.ScheduleMode__RecurrencePresetsPopup')
      const customRecurrencePopup = document.querySelector('.ScheduleMode__CustomRecurrenceSettingsPopup')

      if (
        activeBlurLayer ||
        lineRef?.current?.contains(e.target) ||
        recurrencePresets?.contains?.(e.target) ||
        customRecurrencePopup?.contains?.(e.target) ||
        ignoreOutsideClicks
      ) {
        return
      }
      hideFocusSessionsPopup()
      if (isCreating) {
        if (!!editingItem?.title) {
          createItem({ eventName: utils.task.clickOutside })
        } else {
          onCancel()
        }
      } else {
        saveItem()
      }
    }
    document.addEventListener('mousedown', handleClickOutsideWhenEdit)
    return () => document.removeEventListener('mousedown', handleClickOutsideWhenEdit)
  }, [ignoreOutsideClicks, isCreating, saveItem, createItem, hideFocusSessionsPopup, onCancel, editingItem.title])

  const toggleFocusSessionsPopup = () => setIsFocusSessionsPopupShown((value) => !value)

  const _onClick = useCallback(() => onClick?.(itemId), [itemId, onClick])

  const _onChange = useCallback(
    (taskId, data, extraParams = {}) => {
      const updatedItem = utils.task.computeTaskOnChange(editingItem, data, extraParams)
      onChange?.(updatedItem)
      setEditingItem(updatedItem)
    },
    [editingItem, onChange]
  )

  const onFieldChange = useCallback(
    (value, extraParams) => {
      _onChange(itemId, value, extraParams)
    },
    [itemId, _onChange]
  )

  const onTagsChange = onFieldChange
  const onProjectsChange = onFieldChange

  const onTitleChange = (e) => {
    const value = typeof e === 'string' ? e : e.target.value
    onFieldChange({
      paramName: 'title',
      value: value,
    })
  }
  const onUrlsInfoChange = ({ value: urlsInfo }) => onFieldChange({ paramName: 'urlsInfo', value: urlsInfo })
  const onSprintChange = (sprintInfo) => onFieldChange({ paramName: 'sprintInfo', value: sprintInfo })

  const onDateChange = (dateData, extraParams) => {
    if (!dateData.value) {
      setSelectedRecurrency({
        customSettings: null,
        type: DailyScheduleOptions.NoRepeat,
      })
    }

    onFieldChange(
      {
        method: 'change',
        ...dateData,
      },
      extraParams
    )
  }

  const onFocusSessionsChange = useCallback(
    (updatedFocusSessions) => onFieldChange({ paramName: 'focusSessions', value: updatedFocusSessions }),
    [onFieldChange]
  )

  const onPinChange = useCallback(
    ({ start, end }) => {
      const newEstimatedTime = utils.datetime.convertMilliSecondsToNanoSecond(end.diff(start))
      onFieldChange(
        {
          paramName: 'pin.time',
          value: start.toDate(),
        },
        {
          estimatedTime: newEstimatedTime,
        }
      )
    },
    [onFieldChange]
  )

  const onRecurrenceChange = useCallback((type, customSettings = null) => {
    setSelectedRecurrency({ customSettings, type })
    setEditingItem((editingItem) => {
      const updatedItem = { ...editingItem }
      const recurrencyDetails = recurrenceComponentsUtils.getRecurrencyDetails({
        customTypeSettings: customSettings,
        recurrencyType: type,
        sprintStartTime: moment(editingItem.pin?.time ?? editingItem.when?.date ?? undefined),
      })

      if (!recurrencyDetails) {
        delete updatedItem.recurrencyInformation
      } else {
        updatedItem.recurrencyInformation = { recurrencyDetails }
      }

      delete updatedItem.sprintInfo
      return updatedItem
    })
  }, [])

  const _onTogglePin = useCallback(() => {
    if (onTogglePin) {
      onTogglePin({ item: editingItem, isCreating })
    } else {
      setEditingItem((prev) => {
        const updatedItem = { ...prev }

        if (prev?.pin?.time) {
          delete updatedItem.pin
          updatedItem.estimatedTime = 0
          setSelectedRecurrency({
            customSettings: null,
            type: DailyScheduleOptions.NoRepeat,
          })
        } else {
          const time = utils.task.computeTimeForToggledPin(updatedItem.when?.date)
          updatedItem.pin = { time: time.format() }
          updatedItem.estimatedTime = utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 })
          updatedItem.when = { date: time.format('YYYY-MM-DD') }
        }

        return updatedItem
      })
    }
  }, [onTogglePin, editingItem, isCreating])
  const _onDelete = useCallback(() => onDelete?.(itemId), [itemId, onDelete])

  const _onUpdateFocusSession = useCallback(
    (updatedFocusSession) => {
      onUpdateFocusSession?.(updatedFocusSession)
      // TODO: use immutableJS for this mutation
      const updatedFocusSessions = JSON.parse(JSON.stringify(itemFocusSessions))
      const idx = updatedFocusSessions.findIndex((fs) => fs.id === updatedFocusSession.id)
      if (idx >= 0) {
        updatedFocusSessions[idx] = updatedFocusSession
      }
      onFocusSessionsChange(updatedFocusSessions)
    },
    [onUpdateFocusSession, itemFocusSessions, onFocusSessionsChange]
  )

  const _onDeleteFocusSession = useCallback(
    ({ focusSession: focusSessionToDelete }) => {
      onDeleteFocusSession?.({ focusSession: focusSessionToDelete })
      const updatedFocusSessions = itemFocusSessions.filter((fs) => fs.id !== focusSessionToDelete.id)
      onFocusSessionsChange(updatedFocusSessions)
    },
    [onDeleteFocusSession, itemFocusSessions, onFocusSessionsChange]
  )

  useEffect(() => {
    setLastDateSet((prev) => {
      const newValue = { ...prev }

      if (pinProp?.time) {
        newValue.pin = pinProp
      }

      if (whenProp?.date) {
        newValue.when = whenProp
      }

      if (whenProp?.date && !pinProp?.time) {
        newValue.pin = pinProp?.time // allow pin to be undefined to revert to all day tasks
      }

      return newValue
    })
  }, [pinProp, whenProp])

  const onScheduleModeToggled = useCallback(
    (onScheduleMode) => {
      if (onScheduleMode) {
        setEditingItem((editingItem) => {
          const updatedItem = {
            ...editingItem,
          }

          if (lastDateSet.pin) {
            updatedItem.pin = lastDateSet.pin
          }

          if (lastDateSet.when) {
            updatedItem.when = lastDateSet.when
          }

          delete updatedItem.sprintInfo
          return updatedItem
        })
      }
    },
    [lastDateSet]
  )

  const setLineRef = (ref) => {
    lineRef.current = ref
    if (innerRef) {
      innerRef.current = ref
    }
  }

  return (
    <EditingLine
      ref={setLineRef}
      isCreating={isCreating}
      clearHighLightedEvent={clearHighLightedEvent}
      item={editingItem}
      onTogglePin={_onTogglePin}
      onDelete={_onDelete}
      toggleFocusSessionsPopup={toggleFocusSessionsPopup}
      onTitleChange={onTitleChange}
      renderBottomButtons={isCreating ? renderCreateBottomButtons : renderEditBottomButtons}
      onStartFsAndCreate={startFsAndCreateTask}
      activeTags={activeTags}
      allProjects={allProjects}
      onProjectsChange={onProjectsChange}
      onTagsChange={onTagsChange}
      onUpdateFocusSession={_onUpdateFocusSession}
      onDeleteFocusSession={_onDeleteFocusSession}
      isFocusSessionsPopupShown={isFocusSessionsPopupShown}
      onUrlsInfoChange={onUrlsInfoChange}
      onDateChange={onDateChange}
      readOnlyDate={readOnlyDate}
      inChromeExtension={inChromeExtension}
      hideBlockToCalendarOption={hideBlockToCalendarOption}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      activeSprintsMap={activeSprintsMap}
      canBlockToCalendar={canBlockToCalendar}
      onClickFocusSession={onClickFocusSession}
      onClick={_onClick}
      hideSprint={hideSprint}
      startSprintCreation={startSprintCreation}
      onPinChange={onPinChange}
      onScheduleModeToggled={onScheduleModeToggled}
      onStartFocus={onStartFocus}
      onSprintChange={onSprintChange}
      onCreate={createItem}
      onSave={saveItem}
      hideCreateButton={hideCreateButton}
      hideDateInput={hideDateInput}
      hideSpentTimeInput={hideSpentTimeInput}
      onCancel={isCreating ? onCancel : onCancelEdit}
      onRecurrenceChange={onRecurrenceChange}
      selectedRecurrency={selectedRecurrency}
      showScheduleSection={!hideScheduleSection}
      firstDayOfWeek={session?.user?.settingsPreferences?.calendar?.firstDay}
      setIsTitleFocused={setIsTitleFocused}
      verticalView={verticalView}
    />
  )
}

EditingLineContainer.propTypes = {
  clearHighLightedEvent: PropTypes.func,
  registerShortcuts: PropTypes.func,
  unregisterShortcuts: PropTypes.func,
}

export default EditingLineContainer
