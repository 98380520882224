import moment from 'moment'

import { utils } from 'gipsy-misc'

import { OPTIONS } from 'features/filters/SortDropdown'

export const computeActiveTagPath = (tag) => {
  if (!tag) {
    return '/'
  }
  return `/tag/active/${tag.id}`
}

const isTagRankBefore = (tagId, a, b) => {
  const aHasRank = !!a.tagsRank?.[tagId]
  const bHasRank = !!b.tagsRank?.[tagId]

  if (!aHasRank && !bHasRank) {
    return moment(a.creationTime).isBefore(moment(b.creationTime)) ? 1 : -1
  }

  if (!aHasRank || !bHasRank) {
    return aHasRank ? 1 : -1
  }

  if (a.tagsRank[tagId] === b.tagsRank[tagId]) return 0
  return a.tagsRank[tagId] < b.tagsRank[tagId] ? 1 : -1
}

const sortByTagRank = (list, tagId) => [...list].sort((a, b) => isTagRankBefore(tagId, b, a))

export const sortTaskList = (sortOption, list, tagId) => {
  switch (sortOption) {
    case OPTIONS.SCHEDULED_DATE: {
      return utils.task.sortByScheduledDate(list)
    }
    case OPTIONS.NONE: {
      return sortByTagRank(list, tagId)
    }
    default: {
      return list
    }
  }
}
