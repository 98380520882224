import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  paramName: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

class TextArea extends Component {
  /*
   ** We use handmade shouldComponentUpdate instead of PureComponent in order to ignore the onChange function
   ** it allows us to create a new function on every render in the parent : onChange={() => { doSomething() }}
   ** without rerendering the component everytime the parent is rendered (PureComponent have this side effect )
   */
  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onChange']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }
      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    return false
  }

  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange({
        paramName: this.props.paramName,
        value: e.target.value,
      })
    }
  }

  onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur({
        paramName: this.props.paramName,
        value: e.target.value,
      })
    }
  }

  render() {
    const { onChange, onBlur, className, paramName, ...props } = this.props
    return (
      <textarea
        onChange={this.onChange}
        onBlur={this.onBlur}
        className={'gp-textarea gp-textarea-default' + (className ? ` ${className}` : '')}
        {...props}
      />
    )
  }
}

TextArea.propTypes = propTypes

export default TextArea
