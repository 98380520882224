import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { styles, translations } from 'gipsy-misc'

import asanaLogo from 'assets/asana.png'
import FocusSessionsPopup from 'FocusSession/Popup'
import Icon from 'Icon'
import Switch from 'Switch'

import { AttributesContainer, ButtonsContainer, TitleContainer } from './components/commonUIComponents'
import LinkWrapper from './components/LinkWrapper'
import { ProjectsWrapper } from './components/ProjectsWrapper'
import ScheduleMode from './components/ScheduleMode'
import { SpentTimeWrapper } from './components/SpentTimeWrapper'
import { SprintWrapper } from './components/SprintWrapper'
import { TagsWrapper } from './components/TagsWrapper'
import { Title } from './components/Title'
import { getMakeTriggerForProjectsTags } from './utils'

const scheduleSectionVerticalModeMinWidth = 302

export default React.forwardRef(function EditingLine(
  {
    activeSprintsMap,
    activeTags,
    allProjects,
    clearHighLightedEvent,
    firstDayOfWeek,
    hideCreateButton,
    hideSpentTimeInput,
    inChromeExtension,
    isCreating,
    isFocusSessionsPopupShown,
    item,
    marginBottom,
    marginLeft,
    marginRight,
    onCancel,
    onClickFocusSession,
    onCreate,
    onDateChange,
    onDelete,
    onDeleteFocusSession,
    onPinChange,
    onProjectsChange,
    onRecurrenceChange,
    onSave,
    onScheduleModeToggled,
    onSprintChange,
    onStartFocus,
    onStartFsAndCreate,
    onTagsChange,
    onTitleChange,
    onTogglePin,
    onUpdateFocusSession,
    onUrlsInfoChange,
    readOnlyDate,
    renderBottomButtons,
    selectedRecurrency,
    setIsTitleFocused,
    showScheduleSection,
    startSprintCreation,
    toggleFocusSessionsPopup,
    verticalView,
  },
  ref
) {
  const [scheduleMode, setScheduleMode] = useState(true)
  const [trackedWidths, setTrackedWidths] = useState({ content: null })

  const contentRef = useRef(null)
  const titleRef = useRef(null)

  useLayoutEffect(() => {
    const setContentClientWidth = () => {
      setTrackedWidths((prev) => ({
        content: contentRef.current?.clientWidth || prev.content,
      }))
    }

    setContentClientWidth()
    window.addEventListener('resize', setContentClientWidth)

    return () => {
      window.removeEventListener('resize', setContentClientWidth)
    }
  }, [])

  useEffect(() => {
    if (titleRef.current) {
      const titleNode = titleRef.current
      titleNode.focus()
      titleNode.selectionStart = titleNode.selectionEnd = titleNode.value.length
    }
  }, [])

  const handleItemSelected = useCallback(
    ({ currentTrigger, item }) => {
      switch (currentTrigger) {
        case '#':
          onProjectsChange({
            value: item,
            method: 'add',
            paramName: 'projects',
          })
          break
        case '$':
          onTagsChange({
            value: item,
            method: 'add',
            paramName: 'tags',
          })
          break
        default:
          break
      }
    },
    [onProjectsChange, onTagsChange]
  )

  const toggleScheduleMode = useCallback(() => {
    setScheduleMode((prev) => {
      const newValue = !prev
      onScheduleModeToggled?.(newValue)
      return newValue
    })
  }, [onScheduleModeToggled])

  const getLineBounds = useCallback(() => {
    if (!contentRef.current) return

    return contentRef.current.getBoundingClientRect()
  }, [])

  const hasFocusSessions = item.focusSessions?.length > 0
  const isAsanaTask = !!item.asanaInfo
  const makeTrigger = getMakeTriggerForProjectsTags(activeTags, allProjects)
  const shouldShrinkAttributes = !trackedWidths.content || trackedWidths.content < 400
  const showSpentTimeWrapper = !isCreating && item.focusSessions && item.focusSessions.length > 0 && !hideSpentTimeInput
  const shrinkStartButton = trackedWidths.content < 675
  const verticalMode = !inChromeExtension && (verticalView || trackedWidths.content < 682)

  return (
    <LineContainer
      className='fs-mask'
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      ref={ref}>
      <LineContent hideScheduleSection={!showScheduleSection} ref={contentRef} vertical={verticalMode}>
        <AttributesSection>
          <TitleContainer>
            <Title
              disableRightBorderRadius={isAsanaTask}
              handleItemSelected={handleItemSelected}
              makeTrigger={makeTrigger}
              onTitleChange={onTitleChange}
              pinTime={item.pin && item.pin.time}
              setIsTitleFocused={setIsTitleFocused}
              title={item.title}
              titleRef={titleRef}
            />
            {isAsanaTask && (
              <AsanaIconContainer>
                <AsanaIcon alt={'asana logo'} src={asanaLogo} />
              </AsanaIconContainer>
            )}
          </TitleContainer>
          {isFocusSessionsPopupShown && hasFocusSessions && (
            <FocusSessionsPopup
              clearHighLightedEvent={clearHighLightedEvent}
              firstDayOfWeek={firstDayOfWeek}
              inChromeExtension={inChromeExtension}
              item={item}
              maxWidth={trackedWidths.content}
              onClickFocusSession={onClickFocusSession}
              onDeleteFocusSession={onDeleteFocusSession}
              onUpdateFocusSession={onUpdateFocusSession}
            />
          )}
          <AttributesContainer>
            {showSpentTimeWrapper && (
              <SpentTimeWrapper
                item={item}
                shrinked={shouldShrinkAttributes}
                toggleFocusSessionsPopup={toggleFocusSessionsPopup}
              />
            )}
            <LinkWrapper
              maxWidth={trackedWidths.content}
              onChange={onUrlsInfoChange}
              shrinked={shouldShrinkAttributes}
              value={item.urlsInfo}
            />
            <TagsWrapper
              activeTags={activeTags}
              onChange={onTagsChange}
              shrinked={shouldShrinkAttributes}
              value={item.tags}
            />
            <ProjectsWrapper
              allProjects={allProjects}
              getLineBounds={getLineBounds}
              onChange={onProjectsChange}
              shrinked={shouldShrinkAttributes}
              value={item.projects}
            />
          </AttributesContainer>
          {(!verticalMode || (verticalMode && !showScheduleSection)) && (
            <ButtonsContainer>
              {renderBottomButtons?.({
                onCancel,
                onCancelEdit: onCancel,
                onCreate,
                onDelete,
                onSave,
                onStartFocus,
                onStartFsAndCreate,
                shrinkStartButton,
              })}
            </ButtonsContainer>
          )}
        </AttributesSection>
        {showScheduleSection && (
          <SchedulingSection verticalMode={verticalMode}>
            <ModeIndicatorContainer>
              <ScheduleModeIndicator active={scheduleMode}>
                <span>{translations.general.schedule}</span>
                <Icon
                  fill='transparent'
                  icon='PinOutline'
                  size={14}
                  stroke={scheduleMode ? styles.colors.primaryColor : styles.colors.darkGrey}
                />
              </ScheduleModeIndicator>
              <Switch onChange={toggleScheduleMode} paramName='mode' value={!scheduleMode} />
              <AddToBlockModeIndicator active={!scheduleMode}>
                <Icon
                  fill={!scheduleMode ? styles.colors.orangeColor : styles.colors.darkGrey}
                  icon='Sprint'
                  size={14}
                />
                <span>{translations.general.addToBlock}</span>
              </AddToBlockModeIndicator>
            </ModeIndicatorContainer>
            <AnimatePresence exitBeforeEnter>
              {scheduleMode ? (
                <ModeWrapper
                  animate='enter'
                  exit='exit'
                  initial='initial'
                  key='schedulingModeControls'
                  variants={modeTransitionVariants}>
                  <ScheduleMode
                    firstDayOfWeek={firstDayOfWeek}
                    getLineBounds={getLineBounds}
                    inChromeExtension={inChromeExtension}
                    isAsanaTask={isAsanaTask}
                    isCreating={isCreating}
                    item={item}
                    onDateChange={onDateChange}
                    onPinChange={onPinChange}
                    onRecurrenceChange={onRecurrenceChange}
                    onTogglePin={onTogglePin}
                    readOnlyDate={readOnlyDate}
                    selectedRecurrency={selectedRecurrency}
                    shouldShrinkAttributes={shouldShrinkAttributes}
                    verticalView={verticalMode}
                  />
                </ModeWrapper>
              ) : (
                <ModeWrapper
                  animate='enter'
                  exit='exit'
                  initial='initial'
                  key='addToBlockModeControls'
                  variants={modeTransitionVariants}>
                  <SprintWrapper
                    activeSprintsMap={activeSprintsMap}
                    getLineBounds={getLineBounds}
                    hideCreateButton={hideCreateButton || inChromeExtension}
                    inChromeExtension={inChromeExtension}
                    maxWidth={trackedWidths.content}
                    onChange={onSprintChange}
                    shrinked={shouldShrinkAttributes}
                    startSprintCreation={startSprintCreation}
                    value={item.sprintInfo}
                    verticalView={verticalMode}
                  />
                </ModeWrapper>
              )}
            </AnimatePresence>
            {verticalMode && (
              <ButtonsContainer>
                {renderBottomButtons?.({
                  onCancel,
                  onCancelEdit: onCancel,
                  onCreate,
                  onDelete,
                  onSave,
                  onStartFocus,
                  onStartFsAndCreate,
                })}
              </ButtonsContainer>
            )}
          </SchedulingSection>
        )}
      </LineContent>
    </LineContainer>
  )
})

const modeTransitionVariants = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  initial: {
    opacity: 1,
  },
}

export const LineContainer = styled.div`
  ${({ marginBottom, marginLeft, marginRight }) => css`
    margin-bottom: ${Number.isFinite(marginBottom) ? marginBottom : 5}px;
    margin-left: ${marginLeft}px;
    margin-right: ${marginRight}px;
  `}
`

LineContainer.displayName = 'LineContainer'

const AttributesSection = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: ${styles.shadows.taskBox};
  display: flex;
  flex: 1;
  flex-flow: column;
  min-width: 0;
  padding: 8px;
`

AttributesSection.displayName = 'AttributesSection'

const ScheduleModeIndicator = styled.div`
  align-items: center;
  color: ${styles.colors.darkGrey};
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  padding: 0 8px;

  & > :nth-child(1) {
    margin-right: 8px;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${styles.colors.primaryColor};
    `}
`

ScheduleModeIndicator.displayName = 'ScheduleModeIndicator'

const LineContent = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;

  & > div:nth-child(1) {
    margin-right: ${({ hideScheduleSection }) => (hideScheduleSection ? 0 : 8)}px;
  }

  ${({ hideScheduleSection, vertical }) =>
    vertical &&
    css`
      flex-flow: column;
      min-width: ${hideScheduleSection ? 0 : scheduleSectionVerticalModeMinWidth}px;

      & > div:nth-child(1) {
        margin-right: 0;
      }

      ${AttributesSection} {
        border-radius: ${hideScheduleSection ? '10px' : '10px 10px 0 0'};
      }

      ${AttributesSection} + ${AttributesSection} {
        border-radius: 0 0 10px 10px;
      }

      ${ScheduleModeIndicator} {
        font-size: 12px;
      }
    `}
`

LineContent.displayName = 'LineContent'

const AsanaIconContainer = styled.span`
  align-items: center;
  border: 1px solid ${styles.colors.greyBorderColor};
  border-bottom-right-radius: 8px;
  border-left: none;
  border-top-right-radius: 8px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  width: 35px;
`

AsanaIconContainer.displayName = 'AsanaIconContainer'

const AsanaIcon = styled.img`
  height: 18px;
  width: 18px;
`

AsanaIcon.displayName = 'AsanaIcon'

const SchedulingSection = styled(AttributesSection)`
  min-height: 132px;

  ${({ verticalMode }) =>
    verticalMode &&
    css`
      min-height: 172px;
      min-width: ${scheduleSectionVerticalModeMinWidth}px;
    `}
`

SchedulingSection.displayName = 'SchedulingSection'

const ModeIndicatorContainer = styled.div`
  align-items: center;
  background-color: ${styles.colors.veryLightGrey};
  border-radius: 8px;
  display: flex;
  min-height: 32px;
  padding: 4px;

  & .switch {
    flex-shrink: 0;
    height: 24px;
    width: 40px;

    .slider {
      background-color: ${styles.colors.primaryColor};

      ::before {
        left: 4px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }

    input:checked + .slider {
      background-color: ${styles.colors.orangeColor};

      ::before {
        left: calc(100% - 4px);
        top: 50%;
        transform: translate(-100%, -50%);
      }
    }
  }
`

ModeIndicatorContainer.displayName = 'ModeIndicatorContainer'

const AddToBlockModeIndicator = styled(ScheduleModeIndicator)`
  ${({ active }) =>
    active &&
    css`
      color: ${styles.colors.orangeColor};
    `}
`

AddToBlockModeIndicator.displayName = 'AddToBlockModeIndicator'

const ModeWrapper = styled(motion.div)`
  flex: 1;
  margin-top: 8px;
`

ModeWrapper.displayName = 'ModeWrapper'
