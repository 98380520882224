import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { models, translations } from 'gipsy-misc'
import { AnimateHeight } from 'gipsy-ui'

import HomebaseLine from 'features/list/components/line'
import { sections } from 'pages/today/active/container'
import { SubGroupTitleSeparator } from 'pages/today/active/components'

function OtherItems({ canMoveToTopToday, canScheduleToToday, getTaskProps, items, pageSource }) {
  const [expanded, setExpanded] = useState(true)

  return (
    <>
      <SubGroupTitleSeparator
        isExpanded={expanded}
        onClick={() => setExpanded(!expanded)}
        title={translations.todayView.other}
      />

      <AnimateHeight duration={300} height={expanded ? 'auto' : 0}>
        <Droppable droppableId={JSON.stringify({ group: sections.OTHER })} type={models.item.type.TASK}>
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              {items.map((item, index) => (
                <OtherItem
                  index={index}
                  item={item}
                  key={item.id}
                  pageSource={pageSource}
                  taskProps={getTaskProps({
                    canMoveToTopToday: canMoveToTopToday && index > 0,
                    canScheduleToToday,
                    item,
                  })}
                />
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </AnimateHeight>
    </>
  )
}

const OtherItem = React.memo(({ index, item, pageSource, taskProps }) => (
  <Draggable draggableId={JSON.stringify({ id: item.id, type: item.type })} index={index}>
    {(draggableProvided, snapshot) => {
      return (
        <HomebaseLine
          componentSource={models.item.type.TASK}
          draggableProps={draggableProvided.draggableProps}
          draggableStyle={draggableProvided.draggableProps.style}
          dragHandleProps={draggableProvided.dragHandleProps}
          innerRef={draggableProvided.innerRef}
          isDragging={snapshot.isDragging}
          item={item}
          pageSource={pageSource}
          {...taskProps}
        />
      )
    }}
  </Draggable>
))

export default React.memo(OtherItems)
