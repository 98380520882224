import React, { useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { models, styles, translations } from 'gipsy-misc'

import Icon from 'Icon'
import { CustomPopup } from 'Popup/components'

import { popupStyles, PopupTail, TailWrapper } from './commonUIComponents'
import { getRecurrencyLabel } from '../utils'

const { DailyScheduleOptions } = models.recurrency

export default function RecurrencePresetsPopup({
  className,
  onChange,
  onClickOutside,
  onCustomClicked,
  sprintStartTime,
  typeSelected,
}) {
  const [popupPosition, setPopupPosition] = useState({})

  const popupRef = useRef(null)

  const handleChange = (typeChosen) => {
    if (typeChosen === typeSelected) return
    onChange(typeChosen)
  }

  const selectNoRepeat = () => handleChange(DailyScheduleOptions.NoRepeat)
  const selectDaily = () => handleChange(DailyScheduleOptions.Daily)
  const selectWeeklyOnCurrentDay = () => handleChange(DailyScheduleOptions.WeeklyOnCurrentDay)
  const selectEveryWeekday = () => handleChange(DailyScheduleOptions.EveryWeekday)

  useLayoutEffect(() => {
    if (!popupRef.current) return

    const popupBounds = popupRef.current.getBoundingClientRect()
    const maxTop = document.documentElement.clientHeight - popupBounds.height
    let top = popupBounds.top

    if (top >= maxTop) {
      top = 0 - popupBounds.height - 14

      setPopupPosition({
        top: `${top}px`,
      })
    }
  }, [])

  return (
    <Popup
      className={`RecurrencePresetsPopup ${className}`}
      flipTail={!!popupPosition?.top}
      onClickOutside={onClickOutside}
      ref={popupRef}
      style={popupPosition}>
      <PopupTail />
      <PopupContent>
        <TypePreset
          onClick={selectNoRepeat}
          selected={typeSelected === DailyScheduleOptions.NoRepeat}
          text={translations.sprint.recurrency.noRepeat}
        />
        <TypePreset
          onClick={selectDaily}
          selected={typeSelected === DailyScheduleOptions.Daily}
          text={translations.sprint.recurrency.daily}
        />
        <TypePreset
          onClick={selectWeeklyOnCurrentDay}
          selected={typeSelected === DailyScheduleOptions.WeeklyOnCurrentDay}
          text={getRecurrencyLabel({
            recurrencyType: DailyScheduleOptions.WeeklyOnCurrentDay,
            sprintStartTime,
          })}
        />
        <TypePreset
          onClick={selectEveryWeekday}
          selected={typeSelected === DailyScheduleOptions.EveryWeekday}
          text={translations.sprint.recurrency.everyWeekday}
        />
        <TypePreset
          onClick={onCustomClicked}
          selected={typeSelected === DailyScheduleOptions.Custom}
          text={translations.sprint.recurrency.custom}
        />
      </PopupContent>
    </Popup>
  )
}

function TypePreset({ onClick, selected, text }) {
  return (
    <Preset onClick={onClick} selected={selected}>
      {text}
      {selected && <CheckedIcon icon='Tick' fill={styles.colors.primaryColor} size={9} />}
    </Preset>
  )
}

const Preset = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 14px;
  padding: 8px 31px 8px 21px;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${styles.colors.veryLightGrey};
    color: ${styles.colors.primaryColor};
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 500;
    `}
`

Preset.displayName = 'Preset'

const CheckedIcon = styled(Icon)`
  right: 14px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
`

CheckedIcon.displayName = 'CheckedIcon'

const Popup = styled(CustomPopup)`
  ${popupStyles}

  max-width: 256px;

  ${TailWrapper} {
    left: 56px;
  }

  ${({ flipTail }) =>
    flipTail &&
    css`
      ${TailWrapper} {
        bottom: -11px;
        top: unset;
        transform: rotate(-180deg);
      }
    `}
`

Popup.displayName = 'Popup'

const PopupContent = styled.div`
  padding: 6px 0;
`

PopupContent.displayName = 'PopupContent'
