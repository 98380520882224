import React from 'react'
import styled, { css } from 'styled-components'
import AnimateHeight from 'react-animate-height'

import { styles, translations } from 'gipsy-misc'

import RecurrenceDropdownHandler from 'ListDropdowns/RecurrenceDropdownHandler'

import { DropdownSelector, IconContainer, LabelContainer, SprintContainer } from './DropdownSelector'

export default function RecurrenceDropdownSelector({ onClick, recurrenceId, recurrenceInstances, selectedOption }) {
  return (
    <RecurrenceDropdownHandler
      recurrenceId={recurrenceId}
      recurrenceInstances={recurrenceInstances}
      selectedOption={selectedOption}>
      {({
        displayingAllInstances,
        firstInstance,
        instancesToRender,
        isGroupExpanded,
        loadMoreInstances,
        toggleGroupExpansion,
      }) => (
        <>
          <StyledSprintContainer onClick={toggleGroupExpansion}>
            <StyledIconContainer icon='Sprint' fill={styles.colors.orangeColor} size={12} />
            <StyledIconContainer icon='Repeat' fill={styles.colors.orangeColor} size={12} />
            <LabelContainer>
              <span>{firstInstance.title}</span>
            </LabelContainer>
            <GroupCaret expanded={isGroupExpanded} icon='CaretDown' fill={styles.colors.darkGrey} size={10} />
          </StyledSprintContainer>
          <StyledAnimateHeight duration={300} height={isGroupExpanded ? 'auto' : 0}>
            {instancesToRender.map((sprint) => (
              <DropdownSelector
                groupMode
                key={sprint.id}
                label={sprint.title}
                onClick={() => onClick(sprint)}
                selected={selectedOption?.id === sprint.id}
                value={sprint}
              />
            ))}
            {!displayingAllInstances && (
              <LoadMoreInstancesContainer>
                <LoadMoreInstancesButton onClick={loadMoreInstances}>
                  {translations.general.loadMoreInstances}
                </LoadMoreInstancesButton>
              </LoadMoreInstancesContainer>
            )}
          </StyledAnimateHeight>
        </>
      )}
    </RecurrenceDropdownHandler>
  )
}

const StyledSprintContainer = styled(SprintContainer)`
  min-height: 63px;

  :hover {
    background-color: ${styles.colors.veryLightGrey};
  }
`

StyledSprintContainer.displayName = 'StyledSprintContainer'

const StyledIconContainer = styled(IconContainer)`
  align-self: unset;
  margin-top: unset;
`

StyledIconContainer.displayName = 'StyledIconContainer'

export const GroupCaret = styled(StyledIconContainer)`
  margin-left: auto;
  margin-right: 3px;
  transform: rotate(90deg);

  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(0deg);
    `}
`

GroupCaret.displayName = 'GroupCaret'

const StyledAnimateHeight = styled(AnimateHeight)`
  width: 100%;
`

StyledAnimateHeight.displayName = 'StyledAnimateHeight'

const LoadMoreInstancesContainer = styled.div`
  display: flex;
  min-height: 33px;
`

LoadMoreInstancesContainer.displayName = 'LoadMoreInstancesContainer'

const LoadMoreInstancesButton = styled.span`
  color: ${styles.colors.orangeColor};
  cursor: pointer;
  font-size: 12px;
  line-height: 40px;
  margin: auto;
  opacity: 0.6;
  text-decoration: underline;

  :hover {
    opacity: 1;
  }
`

LoadMoreInstancesButton.displayName = 'LoadMoreInstancesButton'
