import { produce } from 'immer'

import types from './types'

const defaultState = []

function setAccounts(accounts) {
  return accounts
}

function reducer(state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SET_ACCOUNTS: {
        return setAccounts(action.payload)
      }

      default: {
        return draft
      }
    }
  })
}

export default reducer
