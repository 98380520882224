import moment from 'moment'

import { utils } from 'gipsy-misc'

export const changeSlotStartDate = (selectedSlot, newStartTime) => {
  const startTime = moment(selectedSlot.start)
  const endTime = moment(selectedSlot.end)
  const diff = moment.duration(endTime.diff(startTime))
  const updatedStartTime = moment(newStartTime).hour(startTime.hour()).minute(startTime.minute())
  const newEndTime = moment(updatedStartTime)
  newEndTime.add(diff)
  if (selectedSlot) {
    return {
      ...selectedSlot,
      start: updatedStartTime.toDate(),
      end: newEndTime.toDate(),
    }
  }
}

export const changeSlotStartTime = (selectedSlot, newStartTime) => {
  const startTime = moment(selectedSlot.start)
  const endTime = moment(selectedSlot.end)
  const diff = moment.duration(endTime.diff(startTime))
  const updatedStartTime = moment(newStartTime).date(startTime.date()).month(startTime.month()).year(startTime.year())
  const newEndTime = moment(updatedStartTime)
  newEndTime.add(diff)
  if (selectedSlot) {
    return {
      ...selectedSlot,
      start: updatedStartTime.toDate(),
      end: newEndTime.toDate(),
    }
  }
}

export const changeSlotDuration = (selectedSlot, newDurationObj) => {
  const newEstimatedTime = utils.datetime.getNanosecondsFromHourAndMinute(newDurationObj)
  const newEnd = new Date(selectedSlot.start)
  newEnd.setMinutes(newEnd.getMinutes() + utils.datetime.convertNanosecondsToMinute(newEstimatedTime))
  return {
    ...selectedSlot,
    start: selectedSlot.start,
    end: newEnd,
  }
}

export const computeSlotFromCalendarTask = (task) => {
  const date = task.when?.date || moment()
  if (task.pin && task.pin.time) {
    const start = new Date(task.pin.time)
    let end = moment(start).add(30, 'minutes').toDate()

    if (task.estimatedTime) {
      const { hour, minute, second } = utils.datetime.getHourAndMinuteAndSecondFromNanoseconds(task.estimatedTime)
      end = moment(start).add(hour, 'hours').add(minute, 'minutes').add(second, 'seconds').toDate()
    }

    return { start, end }
  }
  return {
    allDay: true,
    start: moment(date, 'YYYY-MM-DD').toDate(),
    end: moment(date, 'YYYY-MM-DD').add(30, 'minute').toDate(),
  }
}

export const getItemDurationInMinutes = (item) => {
  const estimatedTimeObj = utils.datetime.getDurationObj({
    start: moment(item?.pin?.time),
    end: moment(item?.pin?.time).add(utils.datetime.convertNanosecondsToMinute(item?.estimatedTime), 'minutes'),
  })

  let duration =
    (Number.isFinite(estimatedTimeObj.hour) ? estimatedTimeObj.hour * 60 : 0) +
    (Number.isFinite(estimatedTimeObj.minute) ? estimatedTimeObj.minute : 0)

  if (duration === 0) {
    duration = 15
  }

  return duration
}
