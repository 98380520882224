import moment from 'moment'

export const getWeekDay = (date: string): number => {
  const dateMoment = moment(date)
  const dateWeekday = dateMoment.day()
  return dateWeekday
}

export const addDaysToDate = (date: string, numDays: number): string => {
  const newDateMoment = moment(date).add(numDays, 'days')
  return newDateMoment.format('YYYY-MM-DD')
}

export const getStartOfWeek = (date: string, firstDay: number): any => {
  if (firstDay === 1) {
    return moment(date).startOf('isoWeek').format('YYYY-MM-DD')
  } else {
    return moment(date).startOf('week').format('YYYY-MM-DD')
  }
}

export const getEndOfWeek = (date: string, firstDay: number): string => {
  if (firstDay === 1) {
    return moment(date).endOf('isoWeek').format('YYYY-MM-DD')
  } else {
    return moment(date).endOf('week').format('YYYY-MM-DD')
  }
}
