import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { CustomPopup, Icon } from 'gipsy-ui'

export const sortOptionsKey = 'KOSMO_ACTIVETASKS_SORTOPTIONS'

export const OPTIONS = {
  NONE: 'NONE',
  SCHEDULED_DATE: 'SCHEDULED_DATE',
}

function SortDropdown({ onChange, optionSelected = OPTIONS.NONE }) {
  const [shown, setShown] = useState(false)

  const toggleDropdownShown = () => {
    setShown((prev) => !prev)
  }

  const closeDropdown = () => {
    setShown(false)
  }

  const getLabel = (optionId) => {
    switch (optionId) {
      case OPTIONS.NONE: {
        return translations.general.sort
      }

      case OPTIONS.SCHEDULED_DATE: {
        return translations.general.scheduledDate
      }

      default: {
        return null
      }
    }
  }

  const handleOptionSelected = (option) => {
    onChange?.(option)
    closeDropdown()
  }

  return (
    <Container>
      <Trigger
        active={shown}
        hasValue={optionSelected && optionSelected !== OPTIONS.NONE}
        onClick={toggleDropdownShown}>
        <StyledIcon icon='SortArrows' size={16} />
        {getLabel(optionSelected)}
      </Trigger>
      {shown && (
        <StyledPopup onClickOutside={closeDropdown}>
          <Option onClick={() => handleOptionSelected(OPTIONS.NONE)}>{translations.general.none}</Option>
          <Option onClick={() => handleOptionSelected(OPTIONS.SCHEDULED_DATE)}>
            {translations.general.scheduledDate}
          </Option>
        </StyledPopup>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const Trigger = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 1px solid ${styles.colors.greyBorderColor};
  border-radius: 8px;
  color: ${styles.colors.darkGrey};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  min-width: 94px;
  padding: 8px;
  outline: none;

  ${({ active, hasValue }) =>
    (active || hasValue) &&
    css`
      color: ${active ? styles.colors.primaryColor : styles.colors.textMediumDarkColor};

      ${StyledIcon} {
        rect,
        path {
          fill: ${active ? styles.colors.primaryColor : styles.colors.textMediumDarkColor};
        }
      }
    `}
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 8px;
`

const Option = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
  user-select: none;

  &:hover {
    background-color: ${styles.colors.veryLightGrey};
  }
`

const StyledPopup = styled(CustomPopup)`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 7px rgba(33, 21, 81, 0.14);
  margin: 0;
  right: 0;
  top: calc(100% + 7px);
  width: 175px;

  ${Option} {
    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
`

export default SortDropdown
