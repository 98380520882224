import { useEffect, useCallback } from 'react'

export default function useClickOutside(ref, callback) {
  const handleClick = useCallback(
    (e) => {
      e.persist?.()
      if (ref?.current && !ref.current.contains(e.target)) {
        callback(e)
      }
    },
    [callback, ref]
  )

  useEffect(() => {
    // we need to do it on capture(first) phase to avoid attaching it while/after a 'open click event' is bubbling
    document.addEventListener('mousedown', handleClick, true)
    return () => {
      document.removeEventListener('mousedown', handleClick, true)
    }
  }, [handleClick])
}
