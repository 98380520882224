import React from 'react'
import { styles, translations } from 'gipsy-misc'
import styled, { css } from 'styled-components'
import { Icon } from 'gipsy-ui'

export default React.memo(
  React.forwardRef((props, ref) => {
    const { onChange, title, isEmptyTitleErrorShown } = props
    return (
      <TitleInputContainer className='TitleSection' hasError={isEmptyTitleErrorShown}>
        <TitleInputIcon icon={'Sprint'} size={12} fill={styles.colors.orangeColor} />
        <TitleInput
          ref={ref}
          placeholder={translations.sprint.nameYour}
          value={title ? title : ''}
          onChange={onChange}
        />
        {isEmptyTitleErrorShown && <ErrorContainer>{translations.sprint.panel.emptyTitleErr}</ErrorContainer>}
      </TitleInputContainer>
    )
  })
)

const TitleInputContainer = styled.div`
  display: flex;
  height: 55px;
  width: 100%;
  padding: 20px;
  align-items: center;
  position: relative;
  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${styles.colors.errorColor};
      border-radius: 5px;
    `}
`

const TitleInput = styled.input`
  margin-left: 6px;
  border: none;
  flex-grow: 1;
  color: ${styles.colors.orangeColor};
  display: inline-flex;
  font-size: ${styles.fonts.fontSize} !important;
  font-weight: 500;
  ::placeholder {
    color: ${styles.colors.orangeColor};
    opacity: 0.4;
    font-size: ${styles.fonts.fontSize} !important;
  }
`

const TitleInputIcon = styled(Icon)`
  margin-top: 2px;
`

const ErrorContainer = styled.div`
  position: absolute;
  color: red;
  font-size: 10px;
  bottom: 0;
  left: 20px;
`
