import React from 'react'
import styled from 'styled-components'

import Features from './components/Features'
import Header from './components/Header'
import Testimonials from './components/Testimonials'
import UsedBy from './components/UsedBy'

interface Props {
  goToGoogleAuth: () => void
  goToMicrosoftAuth: () => void
}

export default function TimeBlockingExp({ goToGoogleAuth, goToMicrosoftAuth }: Props) {
  return (
    <Container>
      <Header goToGoogleAuth={goToGoogleAuth} goToMicrosoftAuth={goToMicrosoftAuth} />
      <Features />
      <Testimonials />
      <UsedBy />
    </Container>
  )
}

const Container = styled.div`
  background-color: white;
  height: 100%;
  overflow: auto;
  width: 100%;
`

Container.displayName = 'Container'
