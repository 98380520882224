import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { styles, translations } from 'gipsy-misc'
import { EditingLine, SimpleButton } from 'gipsy-ui'

import { StyledDeleteButton, RightButtonsContainer } from 'features/list/components/commonUI'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { getActiveSprintsMapByScheduledTimeAndGroupedByRecurrence } from 'store/items/selectors'

export default function EditableLine(props) {
  const [isEditing, setIsEditing] = useState(false)
  const {
    canBlockToCalendar,
    estimatedTime,
    renderRegularLine,
    onCancel,
    onClickFocusSession,
    onSave,
    onDelete,
    onDeleteFocusSession,
    onTogglePin,
    onUpdateFocusSession,
    item,
    hideDateInput,
    hideScheduleSection,
    hideSpentTimeInput,
    hideSprint,
    marginLeft,
    marginRight,
    pin,
    startSprintCreation,
    startEdition,
    when,
  } = props
  const dispatch = useDispatch()
  const session = useSelector((state) => state.session)
  const activeTags = useSelector((state) => state.tags.items)
  const allProjects = useSelector((state) => state.projects.items)
  const activeSprintsMap = useSelector((state) => getActiveSprintsMapByScheduledTimeAndGroupedByRecurrence(state.items))
  const registerShortcuts = useCallback((...args) => dispatch(pushShortcutsGroup(...args)), [dispatch])
  const unregisterShortcuts = useCallback((...args) => dispatch(popShortcutsGroup(...args)), [dispatch])

  const _onSave = useCallback(
    (...args) => {
      onSave(...args)
      setIsEditing(false)
    },
    [onSave]
  )

  const editableLineRef = useRef(null)

  const startEditMode = useCallback(() => setIsEditing(true), [])
  const endEditMode = useCallback(() => setIsEditing(false), [])

  useEffect(() => {
    if (!isEditing && startEdition) {
      startEditMode()
    }
  }, [isEditing, startEdition, startEditMode])

  const renderEditBottomButtons = ({ onSave, onDelete, onCancelEdit }) => (
    <>
      <StyledDeleteButton onClick={onDelete} />
      <RightButtonsContainer>
        <SimpleButton
          width={100}
          height={32}
          backgroundColor={styles.colors.veryLightGrey}
          textColor={styles.colors.textMediumDarkColor}
          onClick={onCancelEdit}
          text={translations.general.cancel}
        />
        <SimpleButton
          width={100}
          height={32}
          backgroundColor={styles.colors.primaryColor}
          textColor={'white'}
          onClick={onSave}
          text={translations.general.save}
        />
      </RightButtonsContainer>
    </>
  )

  const _onCancel = useCallback(() => {
    setIsEditing(false)
    onCancel?.()
  }, [onCancel])

  if (isEditing) {
    return (
      <EditingLine
        canBlockToCalendar={canBlockToCalendar}
        estimatedTime={estimatedTime}
        hideDateInput={hideDateInput}
        hideScheduleSection={hideScheduleSection}
        hideSpentTimeInput={hideSpentTimeInput}
        innerRef={editableLineRef}
        activeTags={activeTags}
        allProjects={allProjects}
        activeSprintsMap={activeSprintsMap}
        renderEditBottomButtons={renderEditBottomButtons}
        session={session}
        registerShortcuts={registerShortcuts}
        unregisterShortcuts={unregisterShortcuts}
        item={item}
        onSave={_onSave}
        onDelete={onDelete}
        onDeleteFocusSession={onDeleteFocusSession}
        onCancelEdit={_onCancel}
        onClickFocusSession={onClickFocusSession}
        onTogglePin={onTogglePin}
        onUpdateFocusSession={onUpdateFocusSession}
        hideSprint={hideSprint}
        marginLeft={marginLeft}
        marginRight={marginRight}
        pin={pin}
        startSprintCreation={startSprintCreation}
        when={when}
      />
    )
  } else {
    return renderRegularLine({ endEditMode, startEditMode })
  }
}
