import React from 'react'
import { connect } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'

import { PageContainer, PageBody, NavigationContainer, NavigationTitle } from 'features/layout/pages'
import ReportsList from './components/ReportsList'
import { translations } from 'gipsy-misc'

const mapStateToProps = (state) => ({
  session: state.session,
  areProjectsLoaded: state.projects.areProjectsLoaded,
  areTagsLoaded: state.tags.areTagsLoaded,
  projects: state.projects.items,
  tags: state.tags.items,
})

function TimeReports({ session, areProjectsLoaded, areTagsLoaded, actions, projects, tags }) {
  const { projectId, tagId } = useParams()
  if (!areProjectsLoaded || !areTagsLoaded) return null
  const currentProject = projects.find((p) => p.id === projectId)
  const currentTag = tags.find((t) => t.id === tagId)

  return (
    <PageContainer>
      <PageBody>
        <NavigationContainer>
          <NavigationTitle>{translations.timeReports.title}</NavigationTitle>
        </NavigationContainer>
        <ReportsList
          user={session.user}
          defaultProjectIdToFilter={currentProject?.id}
          defaultTagIdToFilter={currentTag?.id}
        />
      </PageBody>
    </PageContainer>
  )
}

export default withRouter(connect(mapStateToProps, null, null)(TimeReports))
