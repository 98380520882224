import set from 'lodash/set'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { random } from 'utils/number'
import { isObjectEqual, areObjectsMatchingAtLeastOneField } from 'utils/object'

export const pickRandomInArray = (array) => {
  if (!Array.isArray(array) || !array || !array.length) {
    return null
  }
  return array[random(0, array.length)]
}

/* find element in array, deep compare whole object for just fields passed in params */
export const getObjectIndexInArray = (array, elementA, pickFields) => {
  if (!array) {
    return -1
  }
  for (let i = 0, l = array.length; i < l; ++i) {
    let elementB = array[i]
    if (isObjectEqual(elementA, elementB, pickFields)) {
      return i
    }
  }
  return -1
}

/* find element in array, deep compare whole object for just fields passed in params */
export const getObjectIndexInArrayMatchingAtLeastOneField = (array, elementA, pickFields) => {
  if (!array || !elementA) {
    return -1
  }
  for (let i = 0, l = array.length; i < l; ++i) {
    let elementB = array[i]
    if (areObjectsMatchingAtLeastOneField(elementA, elementB, pickFields)) {
      return i
    }
  }
  return -1
}

/* example : getObjectIndexInArrayByPath(array, 'item.id', 'id') */
export const getObjectIndexInArrayByPath = (array, path, value) => {
  if (!array || !Array.isArray(array)) {
    throw new Error('First argument should be an array')
  }
  if (!path) {
    throw new Error('Second argument should be a string')
  }
  for (let i = 0, l = array.length; i < l; ++i) {
    const obj = array[i]
    if (get(obj, path) === value) {
      return i
    }
  }

  return -1
}

/* return a new array and a new object at index with the a new value */
export const setValueInArrayAtIndexByPath = (array, index, path, value) => {
  if (!array || !Array.isArray(array)) {
    throw new Error('First argument should be an array')
  }
  if (!path) {
    throw new Error('Third argument should be a string')
  }
  if (index < 0 || index > array.length) {
    throw new Error('Second argument should be a valid index')
  }

  const object = cloneDeep(array[index])
  set(object, path, value)

  const newArray = [...array.slice(0, index), object, ...array.slice(index + 1)]

  return newArray
}

export const generateArrayOfNumbers = (max, iteration = 1, startAt = 0) => {
  const array = []
  for (let i = startAt; i <= max; i = i + iteration) {
    array.push(i)
  }
  return array
}
