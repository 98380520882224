import styled, { css } from 'styled-components'
import { styles } from 'gipsy-misc'

const PopupItem = styled.div`
  cursor: ${(props) => (props.disabled || props.disableHover ? 'initial' : 'pointer')};
  font-size: ${styles.fonts.fontSizeSmall};
  display: flex;
  align-items: center;
  padding: 10px 25px 10px 12px;
  width: 100%;
  color: ${(props) => props.textColor || styles.colors.textMediumDarkColor};
  font-weight: normal;
  transition: color 200ms ease;
  &:not(:last-child) {
    border-bottom: 1px solid ${styles.colors.middleGrey};
  }
  :first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  :last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ${(props) =>
    props.iconColor &&
    css`
      path {
        fill: ${props.iconColor};
      }
    `}

  ${(props) =>
    !props.disableHover &&
    css`
      :hover {
        background: ${props.hoverBackgroundColor || styles.colors.veryLightGrey};
        color: ${props.hoverColor || styles.colors.primaryColor};
        path {
          fill: ${(props) => props.hoverColor || styles.colors.primaryColor};
        }
      }
    `}
`

export default PopupItem
