import moment from 'moment'

import variables from 'assets/styles/variables.js'

export const getEventDate = (event) => {
  return moment(event.start).format('YYYY-MM-DD')
}

export const sortDateStrings = (dateStrings) =>
  dateStrings.sort((a, b) => {
    const aDate = new Date(a)
    const bDate = new Date(b)
    return aDate.getTime() - bDate.getTime()
  })

export const insertEvent = (stateItems, newEvent) => {
  const stateItemsCopy = { ...stateItems }
  const date = getEventDate(newEvent)
  stateItemsCopy[date] = stateItemsCopy[date] || []
  stateItemsCopy[date].push(newEvent)

  return stateItemsCopy
}

export const generateDateRange = (from, to) => {
  from = moment(from).format('YYYY-MM-DD')
  to = moment(to).format('YYYY-MM-DD')
  if (moment(from).isAfter(moment(to))) {
    ;[from, to] = [to, from]
  }
  const dateRange = [from]
  let currentDate = from

  while (currentDate !== to) {
    const nextDay = moment(currentDate).add(1, 'day').format('YYYY-MM-DD')
    dateRange.push(nextDay)
    currentDate = nextDay
  }
  return dateRange
}

export const computePopupTopAndLeftPosition = (
  relativeContainerNodeBounds,
  selectedNodeBounds,
  popupHeight,
  popupWidth,
  positionVerticallyOnly
) => {
  const {
    top: containerTop,
    left: containerLeft,
    width: containerWidth,
    height: containerHeight,
  } = relativeContainerNodeBounds

  const maxTop = containerHeight - popupHeight - 56
  let top = Math.min(selectedNodeBounds.top - containerTop - 16, maxTop)
  let left
  let shouldFlipTail = false

  if (!positionVerticallyOnly) {
    const maxLeft = containerWidth - popupWidth - 16
    const minLeft = 0

    left = minLeft
    left = Math.min(
      selectedNodeBounds.left - containerLeft - popupWidth - variables.addCalendarTaskPopupTailWidth - 4,
      maxLeft
    )

    if (left < minLeft) {
      left =
        selectedNodeBounds.left - containerLeft + selectedNodeBounds.width + variables.addCalendarTaskPopupTailWidth + 4
      shouldFlipTail = true
    }
  }

  return { left, shouldFlipTail, top }
}

export const computeOnboardingHelperPosition = (
  relativeContainerNodeBounds,
  selectedNodeBounds,
  helperHeight,
  helperWidth
) => {
  const {
    height: containerHeight,
    top: containerTop,
    left: containerLeft,
    width: containerWidth,
  } = relativeContainerNodeBounds

  const maxTop = containerHeight - helperHeight
  const minTop = 0
  let top = Math.min(Math.max(minTop, selectedNodeBounds.top - containerTop - helperHeight - 45), maxTop)
  let left

  const maxLeft = containerWidth - helperWidth
  const minLeft = 0

  left = Math.min(selectedNodeBounds.left + selectedNodeBounds.width / 2 - containerLeft - helperWidth / 2, maxLeft)

  if (left < minLeft) {
    left = selectedNodeBounds.left - containerLeft + selectedNodeBounds.width
  }

  return { left, top }
}
