import React from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { BlurLayer, SquareCheckbox, PrimaryButton, SecondaryButton } from 'gipsy-ui'

import { fadeIn } from 'features/keyframes'
import { layoutBlurLayerPortalId } from 'features/layout/index'
import { getSrc } from 'utils/image'

function Prompt({
  acceptText,
  checked,
  checkText,
  contentMaxWidth,
  dismissText,
  iconAlt,
  iconSrc,
  onAccept,
  onCheck,
  onDismiss,
  subtitle,
  title,
}) {
  const hasCheckbox = checked !== undefined
  const portalNode = document.querySelector(`#${layoutBlurLayerPortalId}`)

  if (!portalNode) return null

  return createPortal(
    <PromptBlurLayer active contentMaxWidth={contentMaxWidth} name='header-prompt'>
      <Content>
        <IconWrapper>
          <img alt={iconAlt} src={getSrc(iconSrc)} />
        </IconWrapper>
        <PromptContent hasCheckbox={hasCheckbox} hasSubtitle={subtitle}>
          {title && <PromtTitle>{title}</PromtTitle>}
          {subtitle && <PromptSubtitle>{subtitle}</PromptSubtitle>}
          {hasCheckbox && (
            <CheckboxContainer>
              <SquareCheckbox checked={checked} onChange={onCheck} size={16} />
              <CheckboxLabel>{checkText}</CheckboxLabel>
            </CheckboxContainer>
          )}
        </PromptContent>
        <PromptButtons>
          <LaterButton height={32} onClick={onDismiss} text={dismissText} width={116} />
          <PrimaryButton height={32} onClick={onAccept} text={acceptText} width={116} />
        </PromptButtons>
      </Content>
    </PromptBlurLayer>,
    portalNode
  )
}

const PromptBlurLayer = styled(BlurLayer)`
  animation: ${fadeIn} 300ms ease-in-out forwards;
  align-items: center;
  display: flex;
  justify-content: center;

  &.header-prompt-layer,
  &.header-prompt-layer.active {
    ::before {
      background: ${styles.colors.textMediumDarkColor};
      opacity: 0.6;
      z-index: -1;
    }

    .header-prompt-content {
      max-width: ${({ contentMaxWidth }) => (contentMaxWidth ? contentMaxWidth : 412)}px;
      overflow: visible;
    }
  }
`

PromptBlurLayer.displayName = 'PromptBlurLayer'

const Content = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 54px 35px 24px;
  position: relative;
  text-align: center;
  width: 100%;
`

Content.displayName = 'Content'

const IconWrapper = styled.div`
  background-color: white;
  border-radius: 50px;
  height: 75px;
  left: 50%;
  padding: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  width: 75px;
`

IconWrapper.displayName = 'IconWrapper'

const PromptContent = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 64px;

  ${({ hasCheckbox }) =>
    hasCheckbox &&
    css`
      margin: 0;
    `}

  ${({ hasSubtitle }) =>
    hasSubtitle &&
    css`
      margin: 0 0 38px;
    `}
`

PromptContent.displayName = 'PromptContent'

const PromtTitle = styled.p`
  margin: 0;
`

PromtTitle.displayName = 'PromtTitle'

const PromptSubtitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 26px 0 0;
`

PromptSubtitle.displayName = 'PromptSubtitle'

const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 18px 0 22px;
`

CheckboxContainer.displayName = 'CheckboxContainer'

const CheckboxLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 9px;
`

CheckboxLabel.displayName = 'CheckboxLabel'

const PromptButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

PromptButtons.displayName = 'PromptButtons'

const LaterButton = styled(SecondaryButton)`
  margin-right: 8px;
`

LaterButton.displayName = 'LaterButton'

export function ExtensionInstallPrompt({ onAccept, onDismiss }) {
  return (
    <Prompt
      acceptText={translations.chromeext.promptInstall}
      dismissText={translations.chromeext.promptDismissed}
      iconAlt={'Chrome Icon'}
      iconSrc={'/images/chrome.png'}
      onAccept={onAccept}
      onDismiss={onDismiss}
      title={translations.chromeext.promptContent}
    />
  )
}

export function OnboardingExtensionInstallPrompt({ onAccept, onDismiss }) {
  return (
    <Prompt
      acceptText={translations.chromeext.promptInstall}
      contentMaxWidth={468}
      dismissText={translations.chromeext.promptDismissed}
      iconAlt={'Chrome Icon'}
      iconSrc={'/images/chrome.png'}
      onAccept={onAccept}
      onDismiss={onDismiss}
      subtitle={translations.onboarding.extPrompt.subtitle}
      title={translations.onboarding.extPrompt.title}
    />
  )
}

export function SlackIntegrationPrompt({ checked, onAccept, onCheck, onDismiss }) {
  return (
    <Prompt
      acceptText={translations.slack.promptInstall}
      checked={checked}
      checkText={translations.slack.promptCheck}
      dismissText={translations.slack.promptDismissed}
      iconAlt={'Chrome Icon'}
      iconSrc={'/images/slack_icon.svg'}
      onAccept={onAccept}
      onCheck={onCheck}
      onDismiss={onDismiss}
      title={translations.slack.promptContent}
    />
  )
}
