import React from 'react'
import { connect } from 'react-redux'
import ShortcutsBar from 'features/shortcuts/components/ShortcutsBar'

const mapStateToProps = (state) => ({
  shortcutsGroupStack: state.shortcuts.shortcutsGroupStack,
  isTaskPanelShown: state.taskPanel.settings.isShown,
})

function Shortcuts({ shortcutsGroupStack }) {
  if (shortcutsGroupStack.length < 1) return null

  const currentShortcuts = shortcutsGroupStack[shortcutsGroupStack.length - 1].items

  if (currentShortcuts.length < 1) return null

  return <ShortcutsBar currentShortcuts={currentShortcuts} />
}

export default connect(mapStateToProps)(Shortcuts)
