import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, styles, translations } from 'gipsy-misc'
import { BlurLayer, Icon, SimpleButton } from 'gipsy-ui'

import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { getSrc } from 'utils/image'

const componentName = 'MulticalendarOnboardingOverlay'

export default function MulticalendarOnboardingOverlay() {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      dispatch(pushShortcutsGroup([], componentName))
    })

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  const handleClose = () => {
    mixpanelApi.track({ event: mixpanel.onboardingMulticalendarFlowDoneEvent }, 'Closed')
    history.push('/')
  }

  const handleDiscoverMore = () => {
    mixpanelApi.track({ event: mixpanel.onboardingMulticalendarFlowDoneEvent }, 'Discover More')
    history.push('/onboarding', { currentStep: 3, fromMulticalendarOnboarding: true })
  }

  return (
    <StyledBlurLayer active name={'multicalendar-onboarding-overlay'} onClose={handleClose}>
      <CloseIcon icon='Close' onClick={handleClose} size={12} />
      <Content>
        <Logo alt='gipsybot-logo' src={getSrc('/images/gipsybot-zen-filled.svg')} />
        <Title>{translations.onboarding.multicalendarOverlay.title}</Title>
        <Subtitle>{translations.onboarding.multicalendarOverlay.more}</Subtitle>
        <Features>
          <FeatureContainer>
            <CheckIcon fill={styles.colors.greenPrimaryColor} icon='Checked' size={14} />
            <span>{translations.onboarding.multicalendarOverlay.manageTasks}</span>
          </FeatureContainer>
          <FeatureContainer>
            <CheckIcon fill={styles.colors.greenPrimaryColor} icon='Checked' size={14} />
            <span>{translations.onboarding.multicalendarOverlay.blockTime}</span>
          </FeatureContainer>
          <FeatureContainer>
            <CheckIcon fill={styles.colors.greenPrimaryColor} icon='Checked' size={14} />
            <span>{translations.onboarding.multicalendarOverlay.muteDistractions}</span>
          </FeatureContainer>
        </Features>
        <DiscoverMoreButton
          height={56}
          text={translations.onboarding.multicalendarOverlay.discoverMore}
          onClick={handleDiscoverMore}
          width={258}
        />
      </Content>
    </StyledBlurLayer>
  )
}

const StyledBlurLayer = styled(BlurLayer)`
  align-items: center;
  display: flex;
  justify-content: center;

  &.multicalendar-onboarding-overlay-layer,
  &.multicalendar-onboarding-overlay-layer.active {
    ::before {
      background: ${styles.colors.textMediumDarkColor};
      opacity: 0.6;
      z-index: -1;
    }

    .multicalendar-onboarding-overlay-content {
      max-height: 90%;
      max-width: 494px;
      overflow: visible;
      position: relative;
      width: 100%;
    }
  }
`

StyledBlurLayer.displayName = 'StyledBlurLayer'

const Content = styled.div`
  background-color: white;
  border-radius: 10px;
  height: 100%;
  padding: 50px 50px 44px 50px;
  position: relative;
  text-align: center;
  width: 100%;
`

Content.displayName = 'Content'

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 18px;
  z-index: 1;
`

CloseIcon.displayName = 'CloseIcon'

const Logo = styled.img`
  display: block;
  height: 77px;
  margin: 0 auto 24px;
  width: 77px;
`

Logo.displayName = 'Logo'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 28px;
  font-weight: 500;
  margin: 0 0 40px;
`

Title.displayName = 'Title'

const Subtitle = styled(Title)`
  margin-bottom: 30px;
`

Subtitle.displayName = 'Subtitle'

const Features = styled.div`
  margin: 0 auto 40px;
  width: 280px;
`

Features.displayName = 'Features'

const FeatureContainer = styled.div`
  align-items: center;
  color: ${styles.colors.textDarkColor};
  display: flex;
  line-height: 29px;
  font-size: 18px;
  text-align: left;
  width: 100%;
`

FeatureContainer.displayName = 'FeatureContainer'

const CheckIcon = styled(Icon)`
  margin-right: 20px;
`

CheckIcon.displayName = 'CheckIcon'

const DiscoverMoreButton = styled(SimpleButton)`
  font-size: 18px;
`

DiscoverMoreButton.displayName = 'DiscoverMoreButton'
