const orangeColor = '#FF8A00'
const blueColor = '#4D9FFF'
const lightBlueColor = '#9fe1e7'
const redColor = '#FF5555'
const purpleColor = '#B37BE8'
const veryLightGrey = '#F7F7FA'
const textMediumDarkColor = '#4D4375'

const colors = {
  /* Primary  :
      these are our primary colors used in the app
  */
  textDarkColor: '#211551' /* Text dark color : used for title, bold text etc ... */,
  textMediumDarkColor: textMediumDarkColor,
  darkGrey: '#b2aec2',
  veryLightGrey: veryLightGrey,
  middleGrey: '#E9E8EE',
  pinkColor: '#ff2171',

  greenPrimaryColor: '#40E0B0',

  primaryColor: '#643fdb',
  lightVioletColor: '#D1C5F4',
  superlightVioletColor: '#FAF9FE',

  navbarBackgroundColor: '#ffffff',

  // event colors
  focusSessionFill: purpleColor,
  focusSessionText: 'white',
  sprintFill: orangeColor,
  sprintText: 'white',
  taskFill: veryLightGrey,
  taskText: textMediumDarkColor,

  // -----------------------------------------------------------------

  /*
  Secondary :
*/

  redColor: redColor,
  orangeColor: orangeColor,
  blueColor: blueColor,
  lightBlueColor: lightBlueColor,
  purpleColor: purpleColor,

  projectColors: [
    { color: redColor },
    { color: orangeColor },
    { color: '#DA9A58' },
    { color: '#F9CA28' },
    { color: '#8ECF66' },
    { color: '#5AE1C3' },
    { color: blueColor },
    { color: purpleColor },
    { color: '#F980BA' },
    { color: '#AAB3BB' },
    { color: '#543A49' },
  ],

  // -----------------------------------------------------------------

  /*
  Other colors + legacy one
*/

  buttonDarkColor: '#adabbc' /* grey dark: used for icon/button */,
  buttonLightColor: '#eaeaea' /* grey light : used for icon/button */,

  // color used from new color panel

  grey: '#AAA7B8',
  shadowColor: '#EBEAEE', //it is used in several places so I am adding it to config

  /* Violet color */
  primaryDarkColor: '#512cc5' /* used for : background button icon (a bit darker than the button color) */,
  backgroundPrimaryColor:
    'linear-gradient(180deg, #744aff 0%, #643fdb 100%)' /* violet color used in background of some buttons */,

  /* Pink color */
  lightPink: '#ffeaf2',

  /* Green color */
  greenColor: '#48d8a9',
  greenDarkColor: '#45c6a0',
  greenLightColor: '#c6feea' /* green light color : */,
  greenGradientColor: 'linear-gradient(346.1deg, #49D5A8 2.34%, #45DFAD 96.98%);',

  /* Error / Red color */
  errorColor: '#de3f3f',
  errorDarkColor: '#9e2020',

  /* Grey color */
  borderColor: '#eaeaea' /* grey light color : used for outline buttons, container border, input etc ... */,
  greyBorderColor: '#e9e8ee' /* grey light color : used for outline buttons, container border, input etc ... */,
  lightVioletBorderColor: '#ad9aea',
  backgroundGreyColor: '#f1f3f4',

  lightOrangeColor: '#fff4e6',
}

export default colors
