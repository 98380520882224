const fonts = {
  fontSize: '16px',
  fontSizeSmall: '14px',
  fontSizeXSmall: '12px',
  fontSizeXXSmall: '10px',
  Helvetica: 'Helvetica',
  Arial: 'Arial',
  primaryFont: 'NeurialGrotesk, Helvetica, Arial',
  secondaryFont: 'NeurialGrotesk, Helvetica, Arial',
  lineHeightH2: '48px',
}
export default fonts
