import React from 'react'
import styled from 'styled-components'
import PlayIcon from 'PlayIcon'
import { styles, translations } from 'gipsy-misc'

const buttonHeight = 32
const borderRadius = 8

export function StartAndCreateButton(props) {
  const { startIconSize = 20 } = props
  return (
    <StartAndCreateButtonContainer
      isHidden={props.isHidden}
      margin={props.margin}
      width={props.width}
      onClick={props.onClick}
      title={props.shrinked ? translations.general.start : ''}>
      <PlayIcon playIconBackground={styles.colors.primaryColor} fillColor={'white'} size={startIconSize} />
      {!props.shrinked && <StartContainer startIconSize={startIconSize}>{translations.general.start}</StartContainer>}
    </StartAndCreateButtonContainer>
  )
}

const StartAndCreateButtonContainer = styled.div`
  margin: ${({ margin }) => (margin ? margin : 'none')};
  position: relative;
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  height: ${buttonHeight}px;
  border-radius: ${borderRadius}px;
  border: 1px solid ${styles.colors.primaryColor};
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
`

const StartContainer = styled.div`
  color: ${styles.colors.primaryColor};
  font-size: ${styles.fonts.fontSizeSmall};
  line-height: ${styles.fonts.fontSizeSmall};
  margin-left: ${(props) => props.startIconSize / 2}px;
  font-weight: 500;
`
