import get from 'lodash/get'
import { capitalize } from 'utils/string'
import { getObjectIndexInArrayMatchingAtLeastOneField } from 'utils/array'
import injected from 'injected'

export const betaListedUsers = {
  '0afdaa38-a740-4632-9652-2aaa7f6a7d04': true, // Nicolas
  'cf246364-b8f2-4349-a2a5-96bf1ea5e9bd': true, // Alina
  '717f16d6-6976-4e9b-84af-bb820e199f80': true, // Martin
  'fb701d3f-c8d7-46a9-8801-072a7f95c050': true, // Scott Haney
  '2a93588c-2af0-4a41-bb90-5ea94aeea66f': true, // Mik Skuza
}

export const isUserInBetaList = (user) => {
  return injected.env !== 'kosmotime' || betaListedUsers[user.id]
}

export const getDisplayName = (user) => {
  let displayName = 'Unknown'
  if (user.displayName) {
    displayName = user.displayName.substr(0, user.displayName.indexOf(' '))
  } else if (user.email) {
    displayName = user.email.substr(0, user.email.indexOf('@'))
  }
  return capitalize(displayName)
}

export const getDisplayNames = (users) => {
  let displayNames = ''
  for (let i in users) {
    if (i > 0) {
      displayNames += ', '
    }
    displayNames += getDisplayName(users[i])
  }
  return displayNames
}

export const getDisplayNamesFromObject = (dict) => {
  const values = Object.values(dict)
  let displayNames = ''
  for (let i in values) {
    if (i > 0) {
      displayNames += ', '
    }
    displayNames += values[i]
  }
  return displayNames
}

export const getKey = (user) => {
  return get(user, 'userId') || get(user, 'email')
}

export const areSameUser = (user1, user2) => {
  return getKey(user1) === getKey(user2)
}

export const toUser = (obj) => {
  if (!obj) {
    return undefined
  }
  const user = {}
  user.userId = obj.userId
  user.email = obj.email
  user.displayName = obj.displayName
  user.picture = obj.picture
  return user
}

export const getUserIndexInList = (array, user) => {
  return getObjectIndexInArrayMatchingAtLeastOneField(array, user, ['userId', 'email'])
}

export const computeUserFromSessionUser = (obj) => {
  if (!obj) {
    return undefined
  }
  const user = {}
  user.userId = obj.id
  user.displayName = obj.displayName
  user.picture = obj.picture
  return user
}
