/*
 ** increment the currentValue by "i" (positive or negative) but find the nearest multiple of "i"
 ** i.e.
 ** 16 incremented by 15 would return 30
 ** 12 increment by 15 would return 15
 */
export const incrementToNearestMultiple = (currentValue, i) => {
  if (isNaN(parseInt(currentValue)) || isNaN(parseInt(i))) {
    return currentValue
  }
  i = parseInt(i)
  currentValue = parseInt(currentValue)
  let result
  const modulo = currentValue % i
  if (i > 0) {
    result = currentValue + i - modulo
  } else {
    result = modulo > 0 ? currentValue - modulo : currentValue + i
  }
  return result
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export const random = (min, max) => Math.floor(Math.random() * (max - min) + min)
