import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import AnimateHeight from 'react-animate-height'

import Icon from 'Icon'
import CalendarEventInfo, { EstimatedTimeContainer } from 'LineView/components/CalendarEventInfo'
import Popup from 'Popup'
import { PopupItem } from 'Popup/components'
import {
  LineContainer,
  LineTopRow,
  LineBottomRow,
  LineContent,
  LineContentContainer,
  CheckboxSection,
  StartButtonContainer,
  StartButtonActionsSection,
  ReadModeTitle,
  PrimaryActionButton,
} from 'LineView/components/commonUIComponents'
import SideIconContainer from 'LineComponents/SideIconContainer'
import Tooltip from 'Tooltip'

import { styles, translations, utils } from 'gipsy-misc'
import moment from 'moment'

const expandedBorderWidth = 8
const defaultLineHeight = '36px'
const defaultMoreIconRightPosition = -25
const biggerLineHeight = '64px'
class SprintLine extends PureComponent {
  static propTypes = {
    sprint: PropTypes.object.isRequired,
    innerLeftPadding: PropTypes.number,
    innerRightPadding: PropTypes.number,
    inProgress: PropTypes.bool,
    taskInFocusSession: PropTypes.element,
    startExpanded: PropTypes.bool,
    showCompletionInfo: PropTypes.bool,
    lineThrough: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    containerBackgroundColor: PropTypes.string,
    mouseDownEventListener: PropTypes.func,
    onClickOutside: PropTypes.func,
  }
  state = {
    isExpanded: !!this.props.inProgress,
    isSprintLineHovered: false,
    isContainerHovered: false,
    showActionsPopup: false,
  }

  componentDidMount() {
    if (this.props.taskInFocusSession) {
      this.setState({ isExpanded: false })
    }
    document.addEventListener('mousedown', this.handleAllClickOutside)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.taskInFocusSession && this.props.taskInFocusSession) {
      this.setState({ isExpanded: false })
    } else if (prevProps.taskInFocusSession && !this.props.taskInFocusSession) {
      this.setState({ isExpanded: true })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleAllClickOutside)
  }

  setActionsPopupRef = (ref) => {
    this.actionsPopupRef = ref
  }

  setMoreButtonRef = (ref) => {
    this.moreButtonRef = ref
  }

  moveDownEventListener = () => {
    const { sprint } = this.props
    this.props.mouseDownEventListener && this.props.mouseDownEventListener(sprint)
  }

  handleAllClickOutside = (e) => {
    if (
      !(this.actionsPopupRef && this.actionsPopupRef.contains(e.target)) &&
      !(this.moreButtonRef && this.moreButtonRef.contains(e.target))
    ) {
      this.setShowActionsPopup(false)
      if (this.props.onClickOutside) {
        const { sprint } = this.props
        this.props.onClickOutside(sprint)
      }
    }
  }

  onClickLine = () => {
    this.toggleCollapse()
    const { onClickCallback, sprint } = this.props
    if (onClickCallback) {
      onClickCallback(sprint)
    }
  }

  toggleCollapse = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  onMouseEnterSprintLine = () => {
    this.setState({ isSprintLineHovered: true })
  }
  onMouseLeaveSprintLine = () => {
    this.setState({ isSprintLineHovered: false })
  }

  onMouseEnterContainer = () => {
    this.setState({ isContainerHovered: true })
  }
  onMouseLeaveContainer = () => {
    this.setState({ isContainerHovered: false })
  }

  getDoneText = (task) => {
    return translations.general.doneAt.replace('%date', moment(task.completionTime).format('h:mm A'))
  }

  getCollapseText = () => {
    const { sprint } = this.props
    const tasksCount = sprint.tasks ? sprint.tasks.length : 0
    let text = translations.line.noTasks

    if (tasksCount === 1) {
      text = translations.line.oneTask
    } else if (tasksCount > 1) {
      text = translations.line.multipleTasks.replace('[count]', tasksCount)
    }

    return text
  }

  onClickMoreActionsButton = () => {
    this.setShowActionsPopup(!this.state.showActionsPopup)
  }

  setShowActionsPopup = (value) => {
    this.setState({ showActionsPopup: value })
  }

  onClickEdit = () => {
    const { sprint } = this.props
    this.props.onClickEdit && this.props.onClickEdit(sprint)
    this.setShowActionsPopup(false)
  }

  onClickDelete = () => {
    const { sprint } = this.props
    this.props.onClickDelete && this.props.onClickDelete(sprint)
  }

  onClickEnd = () => {
    const { sprint } = this.props
    this.props.onEnd && this.props.onEnd(sprint.id)
  }

  onClickPlanNew = () => {
    const { sprint } = this.props
    this.props.onPlanNew && this.props.onPlanNew(sprint.id)
  }

  getContainerBackgroundColor = () => {
    let containerBackgroundColor = this.props.containerBackgroundColor || `${styles.colors.middleGrey}`
    if (this.props.inProgress) {
      containerBackgroundColor = lighterOrange
    }
    if (!this.state.isExpanded) {
      containerBackgroundColor = 'transparent'
    }
    return containerBackgroundColor
  }

  render() {
    const {
      displayTimeOnlyForEventInfo,
      sprint,
      innerLeftPadding,
      innerRightPadding,
      children,
      inProgress,
      hideCalendarInfo,
      hideMoreIcon,
      taskInFocusSession,
      showCompletionInfo,
      color,
      lineThrough,
      onClickDelete,
      onEnd,
      onClickEdit,
      className,
      innerRef,
      draggableProps,
      dragHandleProps,
      dragIconPadding = 25,
      draggableStyle,
      isDragging,
      isCalendarDraggable,
      startButtonWidth,
      hideDragIcon,
      theme,
    } = this.props

    const lineHeight = showCompletionInfo ? biggerLineHeight : defaultLineHeight
    const { isExpanded, isSprintLineHovered, isContainerHovered, showActionsPopup } = this.state
    let marginBottom = styles.spacings.marginAfterLine

    if (inProgress && !isExpanded && !taskInFocusSession) {
      marginBottom = 0
    }

    const linePadding = showCompletionInfo ? styles.spacings.small : styles.spacings.xsmall

    let containerBackgroundColor = this.getContainerBackgroundColor()

    let doneText
    let estimatedTimeData
    if (showCompletionInfo) {
      doneText = this.getDoneText(sprint)
      estimatedTimeData = utils.datetime.getHourAndMinuteFromNanoseconds(sprint.estimatedTime)
    }

    const showMoreIcon = !hideMoreIcon && (onClickDelete || onEnd || onClickEdit)

    const endTime = moment(sprint.pin && sprint.pin.time).add(
      utils.datetime.convertNanosecondsToMinute(sprint.estimatedTime),
      'm'
    )
    const isPast = endTime <= moment()

    let title = ''
    title += sprint.title

    const lineContainerClassName = `sprint-line-container fs-mask ${isCalendarDraggable ? 'calendar-draggable' : ''} ${
      isDragging ? 'dragging' : ''
    }`

    const isDraggable = this.props.isDraggable || isCalendarDraggable
    const displayDragIcon =
      !hideDragIcon && isDraggable && ((!isExpanded && isContainerHovered) || (isExpanded && isSprintLineHovered))

    return (
      <Container
        className={className}
        marginBottom={marginBottom}
        marginLeft={innerLeftPadding}
        marginRight={innerRightPadding}
        inProgress={inProgress}
        containerBackgroundColor={containerBackgroundColor}
        isExpanded={isExpanded}
        onMouseEnter={this.onMouseEnterContainer}
        onMouseLeave={this.onMouseLeaveContainer}
        theme={theme}>
        <StyledLineContainer
          className={lineContainerClassName}
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          style={draggableStyle}
          data-itemid={sprint.id}
          isDragging={isDragging}
          paddingLeft={0}
          paddingRight={0}
          onMouseEnter={this.onMouseEnterSprintLine}
          onMouseLeave={this.onMouseLeaveSprintLine}>
          {isDraggable && (
            <DragIconContainer left={-Math.abs(dragIconPadding)} shown={displayDragIcon}>
              <Icon icon='DragHandle' size={16} />
            </DragIconContainer>
          )}
          {!hideCalendarInfo && (
            <CalendarEventInfo
              left={innerLeftPadding ? -innerLeftPadding : -75}
              pin={sprint.pin}
              estimatedTime={sprint.estimatedTime}
              color={inProgress && styles.colors.textMediumDarkColor}
              height={lineHeight}
              isPast={isPast}
              showTimeOnly={displayTimeOnlyForEventInfo}
            />
          )}

          <LineContentContainer
            disableHover
            disableEdit
            isDraggable={isCalendarDraggable}
            lineHeight={lineHeight}
            onClick={this.onClickLine}
            theme={theme}>
            <StartButtonContainer width={startButtonWidth} shrink>
              <StartButtonActionsSection width={startButtonWidth} alignCenter linePadding={linePadding}>
                <Icon icon={'Sprint'} fill={styles.colors.orangeColor} size={16} />
              </StartButtonActionsSection>
              <CheckboxSection theme={theme} width={startButtonWidth} />
            </StartButtonContainer>
            <StyledLineContent linePadding={linePadding} theme={theme}>
              <LineTopRow alignCenter>
                <LineLeftSectionRow>
                  {isPast && !showCompletionInfo ? <EmojiText marginRight={5}>{'☠️'}</EmojiText> : ''}
                  {!!sprint.recurrencyInformation ? (
                    <InnerRepeatContainer>
                      <RepeatIcon size={10} icon={'Repeat'} fill={color || styles.colors.orangeColor} />
                      <Tooltip
                        text={utils.recurrency.details.getRecurrencyLabel(
                          sprint.recurrencyInformation.recurrencyDetails
                        )}
                        vs={15}
                        hs={10}
                        position='top right'
                      />
                    </InnerRepeatContainer>
                  ) : (
                    ''
                  )}
                  <Title color={color || styles.colors.orangeColor} lineThrough={lineThrough}>
                    {title}
                  </Title>
                  {inProgress && (
                    <TimeReminder>
                      <span>{translations.events.inProgress}</span>
                    </TimeReminder>
                  )}
                </LineLeftSectionRow>
                <PrimaryActionButton alignCenter disableHover theme={theme}>
                  <span> {this.getCollapseText()}</span>
                  <PrimaryActionIcon icon={`${isExpanded ? 'SingleChevronDown' : 'SingleChevronLeft'}`} size={10} />
                </PrimaryActionButton>
              </LineTopRow>
              {showCompletionInfo && (
                <LineBottomRow>
                  <TitleDate className={'titledate'}>
                    <TitleDateItem className={'titledateitem'}>
                      <span>{doneText}</span>
                      <TitleDateSeparator>{'-'}</TitleDateSeparator>
                      <span>
                        {!!estimatedTimeData.hour && `${estimatedTimeData.hour}h `}
                        {!!estimatedTimeData.minute && `${estimatedTimeData.minute}min`}
                      </span>
                    </TitleDateItem>
                  </TitleDate>
                </LineBottomRow>
              )}
            </StyledLineContent>
          </LineContentContainer>
          {showMoreIcon && (
            <MoreIconContainer
              ref={this.setMoreButtonRef}
              onClick={this.onClickMoreActionsButton}
              shown={isSprintLineHovered || showActionsPopup}
              // 50 comes from the right position of he more icon in the line component
              right={
                innerRightPadding
                  ? 50 - innerRightPadding - (inProgress ? expandedBorderWidth : 0)
                  : defaultMoreIconRightPosition
              }>
              <Icon icon='More' size={16} />
            </MoreIconContainer>
          )}
          {showActionsPopup && (
            <Popup ref={this.setActionsPopupRef} top={'30px'} left={`calc(100% + 10px)`}>
              {inProgress && onEnd && (
                <PopupItem hoverBackgroundColor={'white'} onClick={this.onClickEnd}>
                  <span> {translations.general.finish} </span>
                </PopupItem>
              )}
              {isPast && onEnd && (
                <PopupItem hoverBackgroundColor={'white'} onClick={this.onClickEnd}>
                  <span> {translations.general.finish} </span>
                </PopupItem>
              )}
              {onClickEdit && (
                <PopupItem hoverBackgroundColor={'white'} onClick={this.onClickEdit}>
                  <span> {translations.general.edit} </span>
                </PopupItem>
              )}
              {onClickDelete && (
                <PopupItem hoverBackgroundColor={'white'} onClick={this.onClickDelete}>
                  <span> {translations.general.delete} </span>
                </PopupItem>
              )}
            </Popup>
          )}
        </StyledLineContainer>
        {taskInFocusSession && (
          <AnimateHeight duration={150} height={taskInFocusSession || isExpanded ? 'auto' : 0}>
            <ExpandableContainer marginTop={5}>{taskInFocusSession}</ExpandableContainer>
          </AnimateHeight>
        )}
        <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0}>
          <ExpandableContainer marginTop={taskInFocusSession ? 0 : 5}>{children ? children : null}</ExpandableContainer>
        </AnimateHeight>
      </Container>
    )
  }
}

export default SprintLine

const lighterOrange = `${styles.colors.orangeColor}20`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f5;
  border: solid ${(props) => (props.inProgress ? expandedBorderWidth : 0)}px white;
  border-radius: ${(props) => (props.inProgress ? 18 : 12)}px;
  transition: background 0.6s ease;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
  margin-left: ${(props) => props.marginLeft}px;
  margin-right: ${(props) => props.marginRight}px;
  box-shadow: ${(props) =>
    props.inProgress && `0 0 0 1px ${styles.colors.orangeColor}, 0 17px 26px rgba(33, 21, 81, 0.1)`};
  position: relative;

  /* HACK: this pseudo element enables mouseEnter/mouseLeave event in the element's margin */
  ::before {
    content: '';
    height: 36px;
    position: absolute;
    left: ${(props) => -props.marginLeft}px;
    width: calc(100% + ${(props) => props.marginLeft + props.marginRight}px);
  }

  ${({ theme }) => {
    switch (theme) {
      case 'orange': {
        return css`
          margin-bottom: 8px;
        `
      }

      default:
        return ''
    }
  }}
`

const DragIconContainer = styled(SideIconContainer)`
  top: calc(50% - 4px);
  left: ${(props) => props.left}px;
  transform: translateY(-50%);
  cursor: ${({ shown }) => (shown ? 'grab' : 'auto')};
`

const StyledLineContainer = styled(LineContainer)`
  ${EstimatedTimeContainer} {
    opacity: 1;
  }

  ${DragIconContainer} {
    opacity: 0;
  }

  :hover {
    ${EstimatedTimeContainer} {
      opacity: 0;
    }

    ${DragIconContainer} {
      opacity: 1;
    }
  }
`

StyledLineContainer.displayName = 'StyledLineContainer'

const PrimaryActionIcon = styled(Icon)`
  margin-left: 6px;
`

const lineHeightTitle = 20
const Title = styled(ReadModeTitle)`
  flex-shrink: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: ${lineHeightTitle}px;
  margin-right: 10px;
  text-decoration: ${(props) => props.lineThrough && 'line-through'};
`
const TitleDate = styled.div`
  color: ${styles.colors.blueColor};
  font-size: ${styles.fonts.fontSizeXSmall};
  font-weight: 500;
  display: flex;
  align-items: center;
`

const TitleDateItem = styled.span`
  display: flex;
  align-items: center;
`

const TitleDateSeparator = styled.span`
  margin: 0 4px;
`

const MoreIconContainer = styled.div`
  position: absolute;
  top: 11px;
  right: ${(props) => props.right || 0}px;
  cursor: ${({ shown }) => (shown ? 'pointer' : 'auto')};
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  display: block;
  :hover {
    path {
      fill: ${styles.colors.textDarkColor};
    }
  }
`

const StyledLineContent = styled(LineContent)`
  cursor: pointer;

  ${({ theme }) => {
    switch (theme) {
      case 'orange': {
        return css`
          padding-left: 20px;
        `
      }

      default:
        return ''
    }
  }}
`

const TimeReminder = styled.span`
  color: ${styles.colors.orangeColor};
  background-color: ${lighterOrange};
  padding: 0 8px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 10px;
  letter-spacing: 1px;
  line-height: 16px;
  flex-shrink: 0;
  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  & > * {
    animation: flickerAnimation 2s infinite;
  }
`

const LineLeftSectionRow = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`

const ExpandableContainer = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
`

const InnerRepeatContainer = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 5px;
`

const RepeatIcon = styled(Icon)`
  pointer-events: none;
  margin-top: 1px; // to properly align it with title
`

const EmojiText = styled.div`
  line-height: ${lineHeightTitle}px;
  margin-right: 3px;
  pointer-events: none;
`
