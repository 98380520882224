import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import parser from 'react-html-parser'

import { mixpanel as mixpanelApi, multicalendarsync as multicalendarsyncApi } from 'gipsy-api'
import { constants, mixpanel, styles, translations } from 'gipsy-misc'
import { SimpleButton, buttonType } from 'gipsy-ui'

import AccountItem from 'features/accountItem'
import { LogoContainer, TitleContainer } from 'features/auth/components'
import useSignInWindow from 'features/hooks/useSignInWindow'
import { SyncingLoading } from 'features/integrations/components'
import AddAccount from 'features/addAccount'
import { getMainAccount, getSecondaryAccounts } from 'store/accounts/selectors'
import { handleAPIError } from 'store/app/actions'
import { refetchEvents } from 'store/calendar/actions'
import { setUser } from 'store/session/actions'
import { getSrc } from 'utils/image'

export default function AddAccountsStep({ onStepDone, stepNumber, syncOptionSelected }) {
  const dispatch = useDispatch()
  const mainAccount = useSelector((state) => getMainAccount(state))
  const secondaryAccounts = useSelector((state) => getSecondaryAccounts(state))
  const user = useSelector((state) => state.session.user)
  const { reauthenticate } = useSignInWindow()

  const [isSyncing, setIsSyncing] = useState(false)

  const invalidAccounts = [mainAccount, ...secondaryAccounts].reduce((invalidCount, account) => {
    return invalidCount + (account?.areCredentialsInvalid || account?.missingScopes ? 1 : 0)
  }, 0)
  const hasInvalidAccounts = invalidAccounts > 0

  let numAccounts = 1
  secondaryAccounts.forEach(() => {
    numAccounts++
  })

  const hasSynced = numAccounts >= 2

  const handleReauthenticate = (provider, email) => {
    mixpanelApi.track({ event: mixpanel.onboardingGrantAccessClickedEvent })
    reauthenticate(provider, email)
  }

  const handleStepDone = (skipSync) => {
    if (skipSync) {
      mixpanelApi.track({ event: mixpanel.onboardingMainAccountAddedDontSyncClickedEvent })
    }

    onStepDone(stepNumber, skipSync)
  }

  const handleStartSync = async () => {
    if (isSyncing) return

    setIsSyncing(true)
    mixpanelApi.track({ event: mixpanel.onboardingCongratsStartSyncClickedEvent })
    const result = await multicalendarsyncApi.select(syncOptionSelected)
    let shouldRollback = false

    if (result?.error || result?.status === constants.runningJobStatus.failed) {
      shouldRollback = true
      dispatch(handleAPIError({ data: { code: 'unexpected', ...result } }))
    } else if (result?.status === constants.runningJobStatus.notFound) {
      shouldRollback = true
    } else if (!result?.status) {
      // the job didn't run - because the option selected is the same as the one in the back-end
      // this should not happen with new accounts
      mixpanelApi.track({ event: mixpanel.onboardingSyncLoadingDoneEvent })
      handleStepDone(false)
      return
    }

    if (shouldRollback) {
      setIsSyncing(false)
      mixpanelApi.track({ event: mixpanel.onboardingSyncLoadingDoneEvent })
      return
    }

    const newCalendarSettings = { ...user.settingsPreferences.calendar }
    newCalendarSettings.multiCalendarSyncKind = syncOptionSelected
    const updatedUser = { ...user }
    updatedUser.settingsPreferences.calendar = newCalendarSettings
    dispatch(setUser(updatedUser))
    dispatch(refetchEvents())
    mixpanelApi.track({ event: mixpanel.onboardingSyncLoadingDoneEvent })
    handleStepDone(false)
  }

  return !isSyncing ? (
    <AddAccountsContainer>
      <StyledLogoContainer
        src={
          hasSynced || hasInvalidAccounts
            ? getSrc('/images/gipsybot-default.svg')
            : getSrc('/images/gipsybot-zen-filled.svg')
        }
      />
      {(hasInvalidAccounts || hasSynced) && (
        <StyledTitleContainer marginBottom={45}>
          {parser(
            hasInvalidAccounts
              ? translations.onboarding.addAccountsStep.invalidAccountWarning.replace(
                  '[invalidAccounts]',
                  invalidAccounts
                )
              : translations.onboarding.addAccountsStep.syncedTitle
          )}
        </StyledTitleContainer>
      )}
      <AccountItemWrapper marginBottom={hasSynced ? 22 : 20}>
        <AccountItem account={mainAccount} onReauthenticate={handleReauthenticate} showTitle />
      </AccountItemWrapper>
      {!hasSynced && !hasInvalidAccounts ? (
        <>
          {secondaryAccounts.map((account, index) => (
            <AccountItemWrapper key={index} marginBottom={index === 0 ? 24 : 0}>
              <AccountItem account={account} onReauthenticate={handleReauthenticate} showTitle={index === 0} />
            </AccountItemWrapper>
          ))}
          <StyledTitleContainer marginBottom={18}>
            {translations.onboarding.addAccountsStep.oneAccountMissingLabel}
          </StyledTitleContainer>
          <StyledAddAccount
            buttonType={buttonType.primary}
            width={260}
            buttonHeight={56}
            text={translations.integrations.actionButtons.addAccount}
          />
          <SkipSyncButton onClick={() => handleStepDone(true)}>
            {translations.onboarding.addAccountsStep.skipSync}
          </SkipSyncButton>
        </>
      ) : (
        <>
          <SecondaryAccountsContainer>
            {secondaryAccounts.map((account, index) => (
              <AccountItemWrapper key={index}>
                <AccountItem account={account} onReauthenticate={handleReauthenticate} showTitle={index === 0} />
              </AccountItemWrapper>
            ))}
          </SecondaryAccountsContainer>
          <ButtonsContainer>
            <StyledAddAnotherAccountButton
              buttonType={buttonType.secondary}
              height={48}
              text={translations.onboarding.addAccountsStep.addAnotherAccount}
            />
            <StartSyncButton
              borderRadius={8}
              disabled={hasInvalidAccounts}
              height={48}
              onClick={handleStartSync}
              text={translations.onboarding.addAccountsStep.startSync}
            />
          </ButtonsContainer>
        </>
      )}
    </AddAccountsContainer>
  ) : (
    <SyncingLoading height='100%' />
  )
}

const AddAccountsContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: ${styles.shadows.taskMenuHover};
  height: 100%;
  margin: 0 auto;
  max-width: 676px;
  padding: 82px 26px 34px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

AddAccountsContainer.displayName = 'AddAccountsContainer'

const StyledLogoContainer = styled(LogoContainer)`
  left: 50%;
  margin-bottom: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
`

StyledLogoContainer.displayName = 'StyledLogoContainer'

const SecondaryAccountsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  overflow: auto;
  width: 100%;
`
SecondaryAccountsContainer.displayName = 'SecondaryAccountsContainer'

const StyledTitleContainer = styled(TitleContainer)`
  font-size: 24px;
  margin: 0 50px;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
`

StyledTitleContainer.displayName = 'StyledTitleContainer'

const AccountItemWrapper = styled.div`
  width: 100%;

  ${({ marginBottom }) => css`
    margin-bottom: ${marginBottom}px;
  `}
`

AccountItemWrapper.displayName = 'AccountItemWrapper'

const StyledAddAccount = styled(AddAccount)`
  font-size: 18px;
  margin: 36px 0 25px;
`

StyledAddAccount.displayName = 'StyledAddAccount'

export const SkipSyncButton = styled.span`
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
`

SkipSyncButton.displayName = 'SkipSyncButton'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  padding: 0 56px;
  width: 100%;
`

ButtonsContainer.displayName = 'ButtonsContainer'

const StyledAddAnotherAccountButton = styled(AddAccount)`
  flex: 1;
  margin-right: 16px;
`
StyledAddAnotherAccountButton.displayName = 'StyledAddAnotherAccountButton'

const StartSyncButton = styled(SimpleButton)`
  flex: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`

StartSyncButton.displayName = 'StartSyncButton'
