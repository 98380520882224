import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  paramName: PropTypes.string.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
}

class Switch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value && this.state.value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      })
    }
  }

  /*
   ** We use handmade shouldComponentUpdate instead of PureComponent in order to ignore the onChange function
   ** it allows us to create a new function on every render in the parent : onChange={() => { doSomething() }}
   ** without rerendering the component everytime the parent is rendered (PureComponent have this side effect )
   */
  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onChange']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }
      if (prop === 'value' && (this.state.value !== nextState.value || this.state.value !== nextProps.value)) {
        return true
      }

      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    return false
  }

  onChange = (e) => {
    this.setState({
      value: this.props.value !== undefined ? this.props.value : e.target.checked,
    })

    this.props.onChange({
      paramName: this.props.paramName,
      value: e.target.checked,
    })
  }

  render() {
    const { className, size } = this.props

    return (
      <label className={'switch' + (className ? ` ${className}` : '') + (size ? ` ${size}` : '')}>
        <input type="checkbox" onChange={this.onChange} checked={this.state.value} />
        <span className="slider round" />
      </label>
    )
  }
}

Switch.propTypes = propTypes

export default Switch
