import { difference } from 'lodash'

class Observer {
  constructor() {
    this.listeners = []
    this.observer = new MutationObserver(this.onChange)
    this.observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    })
  }

  findListenerByPath = (path) => {
    for (let i = 0, l = this.listeners.length; i < l; ++i) {
      if (this.listeners[i].path === path) {
        return this.listeners[i]
      }
    }
    return null
  }

  when = (path, then) => {
    /* stop if the DOM element is already listened */
    if (this.findListenerByPath(path)) {
      return
    }
    if (typeof then === 'function') {
      then = { isCreated: then }
    }
    /* add the listener */
    this.listeners.push({
      path,
      ...then,
    })
    this.handleListener(this.listeners[this.listeners.length - 1])
  }

  onChange = (mutations) => {
    for (let i = 0, l = this.listeners.length; i < l; ++i) {
      this.handleListener(this.listeners[i])
    }
  }

  isReady = (path) => {
    try {
      return document.querySelector(path)
    } catch (err) {
      console.error(err)
      return false
    }
  }

  getAllNewMatches = (listener) => {
    const newMatchesList = Array.from(document.querySelectorAll(listener.path))
    const addedMatches = difference(newMatchesList, listener.matches)

    return { addedMatches, newMatchesList }
  }

  disconnect = () => {
    if (this.observer && this.observer.disconnect) {
      this.observer.disconnect()
    }
  }

  handleListener = (listener) => {
    /* if DOM element existed before and disappeared */
    if (!this.isReady(listener.path)) {
      if (listener.exists) {
        listener.exists = false
        listener.matches = []
        if (listener.isRemoved) {
          listener.isRemoved()
        }
      }
      return
    }

    if (listener.matches && listener.matches.length) {
      const { addedMatches, newMatchesList } = this.getAllNewMatches(listener)
      if (addedMatches.length) {
        if (listener.onNewMatchesCreated) {
          listener.onNewMatchesCreated(addedMatches)
          listener.matches = newMatchesList
        }
      }
    }

    /* if DOM element appeared */
    if (!listener.exists && listener.isCreated) {
      listener.exists = true
      listener.matches = [document.querySelector(listener.path)]
      listener.isCreated()
    }
  }
}

export default Observer
