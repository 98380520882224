import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion, useAnimation } from 'framer-motion'
import { ThirdPartyDraggable } from '@fullcalendar/interaction'
import moment from 'moment'

import { sprint as sprintApi } from 'gipsy-api'
import { models, styles, utils } from 'gipsy-misc'

import variables from 'assets/styles/variables.js'
import { handleAPIError } from 'store/app/actions'
import { pageSource } from 'features/source'
import CalendarPanel from 'features/calendar/components/CalendarPanel'
import { setCalendarExpanded } from 'store/calendar/actions/settings'
import usePageActions from 'features/hooks/usePageActions2'
import RealTime from 'features/realTime'
import { TaskPanelDragDropContext } from 'features/taskPanel/components'
import { setPanelOpen as setTaskPanelOpen } from 'store/taskPanel/actions'
import { updateItems } from 'store/items/actions'

import { CancellableRequestsQueue } from './utils'

const { calendarPanelWidth, calendarLateralPadding } = variables

const pageSources = {
  '/': pageSource.calendarView,
  '/tasks': pageSource.todayView,
  '/tasks-all': pageSource.allTasksView,
}

const Context = createContext({})
Context.displayName = 'CalendarPanelContextProvider'

function CalendarPanelContext({ children, pathname, showCalendar, windowWidth }) {
  const controls = useAnimation()
  const dispatch = useDispatch()
  const {
    completeTask,
    createInlineTask,
    createSprint,
    editSprint,
    findItemById,
    saveTask,
    updateCalendarItem,
  } = usePageActions()
  const isTaskPanelDragging = useSelector((state) => state.taskPanel.dragAndDrop.isDragging)

  const [calendarTaskProps, setCalendarTaskProps] = useState({
    creatingCalendarTask: null,
    editingCalendarTask: null,
    ignoreOutsideClicks: false,
  })

  const [composerState, setComposerState] = useState({
    creatingSprint: null,
    customCancelButton: undefined,
    editingSprint: null,
    ignoreOutsideClicks: false,
    isSprintComposerShown: false,
    sprintActionsCallbacks: undefined,
  })

  const [localTaskProps, setLocalTaskProps] = useState({
    creatingTask: null,
    editingTask: null,
    isCreatingInlineTask: false,
    keepCreatingTasks: false,
  })

  const [dragData, setDragData] = useState({
    draggingData: undefined,
    isDragging: false,
  })

  const [, setPrevPathname] = useState(pathname)

  const calendarContainerRef = useRef(null)
  const calendarDraggableRef = useRef(null)
  const findItemByIdRef = useRef(null)
  const sprintRollbackHelper = useRef(new CancellableRequestsQueue())
  findItemByIdRef.current = findItemById

  useEffect(() => {
    const isRootRoute = pathname === '/'
    dispatch(setCalendarExpanded(isRootRoute))
    dispatch(setTaskPanelOpen(isRootRoute))
  }, [dispatch, pathname])

  useLayoutEffect(() => {
    controls.start({
      transition: {
        delay: styles.transitions.calendarSlide,
        duration: 0,
      },
      width: showCalendar ? calendarPanelWidth + calendarLateralPadding : 0,
    })

    return () => {
      controls.stop()
    }
  }, [controls, showCalendar])

  const handleThirdPartyDragData = useCallback((eventEl) => {
    const item = findItemByIdRef.current?.([eventEl.dataset.itemid])

    if (item) {
      return {
        item,
        id: item.id,
        title: item.title,
        color: item.type === models.item.type.SPRINT ? styles.colors.sprintFill : styles.colors.taskFill,
        textColor:
          item.type === models.item.type.SPRINT || item.color ? styles.colors.sprintText : styles.colors.taskText,
        duration: utils.datetime.convertNanoSecondsToMilliSeconds(item.estimatedTime) || '00:30',
      }
    }
  }, [])

  const initThirdPartyDraggable = useCallback(
    (domNode) => {
      calendarDraggableRef.current = new ThirdPartyDraggable(domNode, {
        itemSelector: '.line-container.calendar-draggable, .sprint-line-container.calendar-draggable',
        mirrorSelector:
          '.line-container.calendar-draggable.dragging, .sprint-line-container.calendar-draggable.dragging',
        eventData: (eventEl) => handleThirdPartyDragData(eventEl),
      })
    },
    [handleThirdPartyDragData]
  )

  const destroyThirdPartyDraggable = useCallback(() => {
    calendarDraggableRef.current?.destroy?.()

    return () => {
      initThirdPartyDraggable(document.body)
    }
  }, [initThirdPartyDraggable])

  useEffect(() => {
    initThirdPartyDraggable(document.body)
  }, [initThirdPartyDraggable])

  const getDroppableId = useCallback((draggableIdStr) => {
    const draggableData = JSON.parse(draggableIdStr)
    const { group, subgroup, ...extraParams } = draggableData

    return { group, subgroup, extraParams }
  }, [])

  const onDragStart = useCallback(
    (data) => {
      const { source } = data
      const draggingData = getDroppableId(source.droppableId)
      setDragData({ isDragging: true, draggingData })
    },
    [getDroppableId]
  )

  const onDragEnd = useCallback(() => setDragData({ isDragging: false, draggingData: undefined }), [])

  const clearCalendarTaskState = useCallback(() => {
    setCalendarTaskProps({
      creatingCalendarTask: null,
      editingCalendarTask: null,
      ignoreOutsideClicks: false,
    })
  }, [])

  const clearLocalTaskState = useCallback(({ keepCreatingTasks } = {}) => {
    setLocalTaskProps({
      creatingTask: null,
      editingTask: null,
      keepCreatingTasks: !!keepCreatingTasks,
      isCreatingInlineTask: false,
    })
  }, [])

  const resetComposerState = useCallback(() => {
    setComposerState(() => ({
      creatingSprint: null,
      customCancelButton: undefined,
      editingSprint: null,
      ignoreOutsideClicks: false,
      isSprintComposerShown: false,
      sprintActionsCallbacks: null,
    }))
  }, [])

  const showSprintComposer = useCallback((value) => {
    setComposerState((prev) => ({
      ...prev,
      isSprintComposerShown: value,
    }))
  }, [])

  const hideSprintComposer = useCallback(() => {
    resetComposerState()
  }, [resetComposerState])

  const cancelTaskAction = useCallback(() => {
    clearLocalTaskState()
  }, [clearLocalTaskState])

  const onClickCancelSprint = useCallback(() => {
    let { editingSprint, sprintActionsCallbacks } = composerState

    if (sprintActionsCallbacks) {
      sprintActionsCallbacks = { ...sprintActionsCallbacks }
    }

    if (editingSprint) {
      const hasTasks = !!editingSprint.tasks
      let sprintUpdates = { ...editingSprint }

      if (hasTasks) {
        // we need to account for possible deleted or completed tasks by the user and avoid bringing them back when reverting changes
        const tasksStillActive = editingSprint.tasks.reduce((tasks, task) => {
          const stateTask = findItemById(task.id)

          if (!!stateTask && !stateTask.completed) {
            tasks.push(stateTask)
          }

          return tasks
        }, [])
        sprintUpdates.tasks = tasksStillActive
        dispatch(updateItems(tasksStillActive))
        updateCalendarItem(sprintUpdates.id, sprintUpdates)
      }

      sprintRollbackHelper.current.cancel(async () => {
        try {
          if (hasTasks) {
            if (!sprintUpdates.tasksId) {
              sprintUpdates.tasksId = sprintUpdates.tasks.map((task) => task.id)
            }

            await sprintApi.editWithTasks(sprintUpdates)
          } else {
            await sprintApi.edit(editingSprint)
          }

          RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
        } catch (err) {
          dispatch(handleAPIError(err, { sprint: editingSprint }))
        }
      })
    }

    resetComposerState()
    sprintActionsCallbacks?.onCancelCreateSprintCallback?.()
  }, [composerState, dispatch, findItemById, resetComposerState, updateCalendarItem])

  const startSprintAction = useCallback(
    ({ sprintActionsCallbacks, creatingSprint, editingSprint, customCancelButton } = {}) => {
      setComposerState((prev) => ({
        ...prev,
        creatingSprint,
        customCancelButton,
        editingSprint,
        ignoreOutsideClicks: true,
        isSprintComposerShown: true,
        sprintActionsCallbacks,
      }))
    },
    []
  )

  const startSprintCreation = useCallback(
    ({
      sprintActionsCallbacks,
      creatingSprint = {
        startTime: moment(),
        estimatedTime: utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 }),
        title: '',
      },
      customCancelButton,
    } = {}) => {
      startSprintAction({
        sprintActionsCallbacks,
        creatingSprint,
        customCancelButton,
      })
    },
    [startSprintAction]
  )

  const startSprintEdition = useCallback(
    ({ sprintActionsCallbacks, editingSprint, customCancelButton } = {}) => {
      startSprintAction({
        sprintActionsCallbacks,
        editingSprint,
        customCancelButton,
      })
    },
    [startSprintAction]
  )

  const onCreateSprint = useCallback(
    async (sprint) => {
      let { sprintActionsCallbacks } = composerState

      if (sprintActionsCallbacks) {
        sprintActionsCallbacks = { ...sprintActionsCallbacks }
      }

      resetComposerState()

      const callback = (sprint, err) => {
        sprintActionsCallbacks?.onCreateSprintCallback?.(sprint, err)
      }

      const response = await createSprint(sprint)
      callback(response)
    },
    [composerState, createSprint, resetComposerState]
  )

  const onEditSprint = useCallback(
    (sprint, options) => {
      resetComposerState()
      editSprint(sprint, options)
    },
    [editSprint, resetComposerState]
  )

  const onClickEditSprint = useCallback(
    (editingSprint) => {
      startSprintAction({
        editingSprint,
      })
    },
    [startSprintAction]
  )

  const cancelCalendarTaskAction = useCallback(() => {
    const { creatingCalendarTask, editingCalendarTask } = calendarTaskProps
    const task = creatingCalendarTask || editingCalendarTask

    if (task) {
      clearCalendarTaskState()
    }
  }, [calendarTaskProps, clearCalendarTaskState])

  const onCreateInlineTask = useCallback(
    async (task, { componentSource = 'inlineAddTask', dontShowCreationAlert } = {}) => {
      const response = await createInlineTask({
        context: { componentSource, pageSource: pageSources[pathname] },
        dontShowCreationAlert,
        task,
      })

      return response
    },
    [createInlineTask, pathname]
  )

  const onCreateCalendarTask = useCallback(
    (task, options) => {
      clearCalendarTaskState()
      onCreateInlineTask(task, options)
    },
    [clearCalendarTaskState, onCreateInlineTask]
  )

  const onDeleteCalendarTaskCallback = useCallback(() => {
    clearCalendarTaskState()
  }, [clearCalendarTaskState])

  const startCalendarTaskAction = useCallback(
    ({ item, isCreating }) => {
      setCalendarTaskProps((prev) => {
        const newState = {
          ...prev,
          ignoreOutsideClicks: true,
        }

        if (isCreating) {
          newState.creatingCalendarTask = item
        } else {
          newState.editingCalendarTask = item
        }

        return newState
      })
      clearLocalTaskState()
    },
    [clearLocalTaskState]
  )

  const onTogglePin = useCallback(
    ({ item, isCreating }) => {
      const isPinned = item.pin && item.pin.time
      let updatedItem

      if (!isPinned) {
        const time = utils.task.computeTimeForToggledPin(item.when?.date)
        updatedItem = {
          ...item,
          estimatedTime: utils.datetime.convertMinuteToNanoseconds(15),
          pin: {
            time: time.format(),
          },
        }
      } else {
        updatedItem = utils.task.computeTaskOnChange(
          item,
          { paramName: 'pin.time', value: null },
          {
            estimatedTime: null,
          }
        )
      }

      startCalendarTaskAction({ item: updatedItem, isCreating })
    },
    [startCalendarTaskAction]
  )

  const onTogglePinFromCalendarPanel = useCallback(
    ({ item, isCreating }) => {
      if (isCreating) {
        setLocalTaskProps((prev) => ({ ...prev, creatingTask: item }))
      } else {
        setLocalTaskProps((prev) => ({ ...prev, editingTask: item }))
      }

      clearCalendarTaskState()
    },
    [clearCalendarTaskState]
  )

  const onSaveCalendarTask = useCallback(
    (task, options) => {
      clearCalendarTaskState()
      return saveTask(task, options)
    },
    [clearCalendarTaskState, saveTask]
  )

  const startInlineTaskCreation = useCallback(() => {
    setLocalTaskProps((prev) => ({
      ...prev,
      isCreatingInlineTask: true,
    }))
  }, [])

  const onTaskEditStart = useCallback(
    ({ item }) => {
      const { editingTask } = localTaskProps

      if (!showCalendar) {
        setLocalTaskProps((prev) => ({ ...prev, editingTask: item }))
        return
      }

      if (editingTask && item.id === editingTask.id) {
        setLocalTaskProps((prev) => ({ ...prev, editingTask: null }))
      }

      const isPinned = item.pin && item.pin.time

      if (isPinned) {
        startCalendarTaskAction({ item })
      }
    },
    [localTaskProps, showCalendar, startCalendarTaskAction]
  )

  const setIgnoreOutsideClicks = useCallback((value) => {
    setCalendarTaskProps((prev) => ({
      ...prev,
      ignoreOutsideClicks: value,
    }))
  }, [])

  useEffect(() => {
    if (!showCalendar) {
      clearCalendarTaskState()
      clearLocalTaskState()
    }
  }, [clearCalendarTaskState, clearLocalTaskState, showCalendar])

  const sprintComposerProps = useMemo(
    () => ({
      ...composerState,
      hideSprintComposer,
      onClickCancelSprint,
      onClickEditSprint,
      onCreateSprint,
      onEditSprint,
      showSprintComposer,
      startSprintCreation,
      startSprintEdition,
    }),
    [
      composerState,
      hideSprintComposer,
      onClickCancelSprint,
      onClickEditSprint,
      onCreateSprint,
      onEditSprint,
      showSprintComposer,
      startSprintCreation,
      startSprintEdition,
    ]
  )

  useEffect(() => {
    setPrevPathname((prev) => {
      if (prev !== pathname) {
        if (sprintComposerProps?.isSprintComposerShown) {
          sprintComposerProps?.hideSprintComposer?.()
        }

        clearCalendarTaskState()
        clearLocalTaskState()
        return pathname
      }

      return prev
    })
  }, [clearCalendarTaskState, clearLocalTaskState, pathname, sprintComposerProps])

  const { creatingCalendarTask, editingCalendarTask } = calendarTaskProps
  const isDragging = dragData.isDragging || isTaskPanelDragging

  const calendarContextProps = useMemo(
    () => ({
      ...calendarTaskProps,
      ...localTaskProps,
      cancelCalendarTaskAction,
      cancelTaskAction,
      clearLocalTaskState,
      destroyThirdPartyDraggable,
      getDroppableId,
      isDragging,
      onCreateCalendarTask,
      onDragEnd,
      onDragStart,
      onDeleteCalendarTaskCallback,
      onSaveCalendarTask,
      onTaskEditStart,
      onTogglePin,
      onTogglePinFromCalendarPanel,
      setIgnoreOutsideClicks,
      showCalendar,
      sprintComposerProps,
      sprintRollbackHelper: sprintRollbackHelper.current,
      startCalendarTaskAction,
      startInlineTaskCreation,
    }),
    [
      calendarTaskProps,
      cancelCalendarTaskAction,
      cancelTaskAction,
      clearLocalTaskState,
      destroyThirdPartyDraggable,
      getDroppableId,
      isDragging,
      localTaskProps,
      onCreateCalendarTask,
      onDeleteCalendarTaskCallback,
      onDragEnd,
      onDragStart,
      onSaveCalendarTask,
      onTaskEditStart,
      onTogglePin,
      onTogglePinFromCalendarPanel,
      setIgnoreOutsideClicks,
      showCalendar,
      sprintComposerProps,
      startCalendarTaskAction,
      startInlineTaskCreation,
    ]
  )

  const inOnboarding = pathname.includes('onboarding')
  const isSprintComposerShown = sprintComposerProps.isSprintComposerShown

  return (
    <Context.Provider value={calendarContextProps}>
      <TaskPanelDragDropContext inOnboarding={inOnboarding} isSprintComposerShown={isSprintComposerShown}>
        <>
          {children}
          <motion.div animate={controls}>
            <CalendarPanel
              calendarContainerRef={calendarContainerRef}
              cancelCalendarTaskAction={cancelCalendarTaskAction}
              creatingCalendarTask={creatingCalendarTask}
              editingCalendarTask={editingCalendarTask}
              inOnboarding={inOnboarding}
              isDragging={isDragging}
              onCompleteTask={completeTask} // TODO: move inside calendar
              onCreateCalendarTask={onCreateCalendarTask}
              onDeleteTaskCallback={onDeleteCalendarTaskCallback}
              onTogglePinFromCalendarPanel={onTogglePinFromCalendarPanel}
              onSaveCalendarTask={onSaveCalendarTask}
              showCalendar={showCalendar}
              windowWidth={windowWidth}
              {...sprintComposerProps}
            />
          </motion.div>
        </>
      </TaskPanelDragDropContext>
    </Context.Provider>
  )
}

export default CalendarPanelContext

export const useCalendarPanelContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useCalendarPanelContext must be used within a CalendarPanelContextProvider')
  }

  return context
}
