import md5 from 'crypto-js/md5'

export const addIdToItem = (item, type, userId = '') => {
  return { ...item, ...generateId(type, userId) }
}

export const generateId = (type, userId) => {
  const creationTime = new Date()
  const id = md5(`${userId}_${type}_${creationTime.getTime()}`).toString()
  return { creationTime, id }
}
