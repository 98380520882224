import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import parser from 'react-html-parser'

import { styles, translations } from 'gipsy-misc'
import { SimpleButton, buttonType } from 'gipsy-ui'

import AccountItem, { Wrapper as AccountInfoWrapper, Title as AccountTitle } from 'features/accountItem'
import { LogoContainer, TitleContainer } from 'features/auth/components'
import useSignInWindow from 'features/hooks/useSignInWindow'
import AddAccount from 'features/addAccount'
import { SyncingLoading } from 'features/integrations/components'
import { getMainAccount, getSecondaryAccounts } from 'store/accounts/selectors'
import { getSrc } from 'utils/image'

export default function SyncAccountsPopup({
  numAccounts,
  invalidCredentialsCount,
  isSyncing,
  onClose,
  onStartSync,
  syncOption,
}) {
  const mainAccount = useSelector((state) => getMainAccount(state))
  const secondaryAccounts = useSelector((state) => getSecondaryAccounts(state))
  const { reauthenticate } = useSignInWindow()

  const handleStartSync = () => {
    onStartSync?.(syncOption)
  }

  const areCredsInvalid = invalidCredentialsCount > 0
  const hasSynced = numAccounts >= 2

  return isSyncing ? (
    <SyncingLoading />
  ) : (
    <Container>
      <StyledLogoContainer
        src={areCredsInvalid ? getSrc('/images/gipsybot-default.svg') : getSrc('/images/gipsybot-zen-filled.svg')}
      />
      {(areCredsInvalid || hasSynced) && (
        <StyledTitleContainer marginBottom={45}>
          {parser(
            areCredsInvalid
              ? translations.multiCalendarSettings.syncAccountsPopup.invalidAccountWarning.replace(
                  '[invalidAccounts]',
                  invalidCredentialsCount
                )
              : translations.multiCalendarSettings.syncAccountsPopup.syncedTitle
          )}
        </StyledTitleContainer>
      )}
      <AccountItemWrapper marginBottom={hasSynced ? 22 : 20}>
        <AccountItem account={mainAccount} onReauthenticate={reauthenticate} showTitle />
      </AccountItemWrapper>
      {!hasSynced && !areCredsInvalid ? (
        <>
          {secondaryAccounts.map((account, index) => (
            <AccountItemWrapper key={index} marginBottom={index === 0 ? 24 : 0}>
              <AccountItem account={account} onReauthenticate={reauthenticate} showTitle={index === 0} />
            </AccountItemWrapper>
          ))}
          <StyledTitleContainer marginBottom={18}>
            {translations.multiCalendarSettings.syncAccountsPopup.oneMissing}
          </StyledTitleContainer>

          <StyledAddAccount
            buttonType={buttonType.primary}
            width={260}
            buttonHeight={56}
            text={translations.integrations.actionButtons.addAccount}
          />
          <SkipSyncButton onClick={onClose}>
            {translations.multiCalendarSettings.syncAccountsPopup.skipSync}
          </SkipSyncButton>
        </>
      ) : (
        <>
          <SecondaryAccountsContainer>
            {secondaryAccounts.map((account, index) => (
              <AccountItemWrapper key={index}>
                <AccountItem account={account} onReauthenticate={reauthenticate} showTitle={index === 0} />
              </AccountItemWrapper>
            ))}
          </SecondaryAccountsContainer>
          <ButtonsContainer>
            <StyledAddAnotherAccountButton
              buttonType={buttonType.secondary}
              height={48}
              text={translations.onboarding.addAccountsStep.addAnotherAccount}
            />
            <SyncSettingsButton
              borderRadius={8}
              disabled={areCredsInvalid}
              height={48}
              onClick={handleStartSync}
              text={translations.multiCalendarSettings.syncAccountsPopup.startSync}
            />
          </ButtonsContainer>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  background: white;
  border-radius: 16px;
  box-shadow: ${styles.shadows.taskMenuHover};
  padding: 78px 26px 40px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

Container.displayName = 'Container'

const StyledLogoContainer = styled(LogoContainer)`
  left: 50%;
  margin-bottom: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
`

StyledLogoContainer.displayName = 'StyledLogoContainer'

const StyledTitleContainer = styled(TitleContainer)`
  font-size: 24px;
  margin: 0 24px;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
`

StyledTitleContainer.displayName = 'StyledTitleContainer'

const SecondaryAccountsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  overflow: auto;
  width: 100%;
`
SecondaryAccountsContainer.displayName = 'SecondaryAccountsContainer'

const AccountItemWrapper = styled.div`
  width: 100%;

  ${({ marginBottom }) => css`
    margin-bottom: ${marginBottom}px;
  `}

  ${AccountTitle} {
    margin-left: 26px;
  }

  ${AccountInfoWrapper} {
    padding-left: 8px;
    padding-right: 8px;
  }
`

AccountItemWrapper.displayName = 'AccountItemWrapper'

const StyledAddAccount = styled(AddAccount)`
  font-size: 18px;
  margin: 36px 0 30px;
`

StyledAddAccount.displayName = 'StyledAddAccount'

export const SkipSyncButton = styled.span`
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
`

SkipSyncButton.displayName = 'SkipSyncButton'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  padding: 0 8px;
  width: 100%;
`

ButtonsContainer.displayName = 'ButtonsContainer'

const StyledAddAnotherAccountButton = styled(AddAccount)`
  flex: 1;
  margin-right: 16px;
`
StyledAddAnotherAccountButton.displayName = 'StyledAddAnotherAccountButton'

const SyncSettingsButton = styled(SimpleButton)`
  flex: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`

SyncSettingsButton.displayName = 'SyncSettingsButton'
