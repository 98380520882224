import { types } from 'store/taskPanel/types'

const initialState = {
  isDragging: false,
  filteredItemsIds: {},
}

export function dragAndDropReducer(state = initialState, action) {
  const { payload } = action
  let newFilteredItems = {}
  switch (action.type) {
    case types.SET_DRAGGING:
      return {
        ...state,
        isDragging: payload,
      }
    case types.SET_FILTERED_ITEMS_IDS:
      if (Array.isArray(payload.filteredItemsIds)) {
        newFilteredItems = payload.filteredItemsIds.forEach(
          (filteredItem) => (newFilteredItems[filteredItem.id] = true)
        )
      }
      return {
        ...state,
        filteredItemsIds: newFilteredItems,
      }
    case types.ADD_FILTERED_ITEM_ID:
      newFilteredItems = {
        ...state.filteredItemsIds,
        [payload.itemId]: true,
      }
      return {
        ...state,
        filteredItemsIds: newFilteredItems,
      }

    case types.UPDATE_FILTERED_ITEM_ID:
      newFilteredItems = { ...state.filteredItemsIds }
      if (newFilteredItems[payload.previousId]) {
        delete newFilteredItems[payload.previousId]
        newFilteredItems[payload.itemId] = true
      }
      return {
        ...state,
        filteredItemsIds: newFilteredItems,
      }
    case types.REMOVE_FILTERED_ITEM_ID:
      newFilteredItems = {
        ...state.filteredItemsIds,
      }
      delete newFilteredItems[payload.itemId]
      return {
        ...state,
        filteredItemsIds: newFilteredItems,
      }
    default:
      return state
  }
}
