import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled, { css } from 'styled-components'
import parser from 'react-html-parser'
import produce from 'immer'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, models, styles, translations, utils } from 'gipsy-misc'
import { SimpleButton } from 'gipsy-ui'

import HomebaseLine from 'features/list/components/line'
import TitleSection from 'features/sprintComposer/components/TitleSection'
import { updateItem } from 'store/items/actions'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { getSrc } from 'utils/image'

const componentName = 'SprintEditor'

export default function SprintEditor({ onCreateTask, onDeleteTask, onSave, onSaveTask, sprint }) {
  const dispatch = useDispatch()
  const session = useSelector((state) => state.session)

  const [editingSprint, setEditingSprint] = useState({ ...sprint, tasks: [] })
  const [showShortcutTip, setShowShortcutTip] = useState(true)
  const [startCreation, setStartCreation] = useState(false)

  const handleTitleChange = (e) => {
    const newTitle = e.target.value

    setEditingSprint((prev) =>
      produce(prev, (draft) => {
        draft.title = newTitle
      })
    )
  }

  const handleTaskCreation = async (task) => {
    task = utils.ids.addIdToItem(task, models.item.type.TASK, session.id)
    task.sprintInfo = editingSprint

    setEditingSprint((prev) =>
      produce(prev, (draft) => {
        draft.tasks.push(task)
      })
    )

    const result = await onCreateTask({ context: { fromOnboarding: true }, task })
    const createdTask = result.task

    if (createdTask.id !== task.id) {
      createdTask.sprintInfo = editingSprint
      dispatch(updateItem(createdTask, task.id))
      setEditingSprint((prev) =>
        produce(prev, (draft) => {
          const prevTaskIdx = draft.tasks.findIndex((t) => t.id === task.id)

          if (prevTaskIdx !== -1) {
            draft.tasks.splice(prevTaskIdx, 1, createdTask)
          }
        })
      )
    }
  }

  const handleTaskDeletion = (id) => {
    onDeleteTask(id)
    setEditingSprint((prev) =>
      produce(prev, (draft) => {
        const prevTaskIdx = draft.tasks.findIndex((t) => t.id === id)

        if (prevTaskIdx !== -1) {
          draft.tasks.splice(prevTaskIdx, 1)
        }
      })
    )
  }

  const handleTaskSave = (task) => {
    onSaveTask(task)
    setEditingSprint((prev) =>
      produce(prev, (draft) => {
        const prevTaskIdx = draft.tasks.findIndex((t) => t.id === task.id)

        if (prevTaskIdx !== -1) {
          draft.tasks.splice(prevTaskIdx, 1, task)
        }
      })
    )
  }

  const handleDragEnd = (data) => {
    const sourceIdx = data.source.index
    const destinationIdx = data.destination.index
    setEditingSprint((prev) =>
      produce(prev, (draft) => {
        const draggedTask = draft.tasks[sourceIdx]
        draft.tasks.splice(sourceIdx, 1)
        draft.tasks = [...draft.tasks.slice(0, destinationIdx), draggedTask, ...draft.tasks.slice(destinationIdx)]
      })
    )
  }

  const saveButtonDisabled = !editingSprint.tasks.length

  const handleFocusBlockSave = useCallback(() => {
    if (saveButtonDisabled) return

    mixpanelApi.track({ event: mixpanel.onboardingSaveFocusBlockEvent }, editingSprint.tasks.length)

    onSave({
      ...editingSprint,
      tasksId: editingSprint.tasks.map((task) => task.id),
    })
  }, [editingSprint, onSave, saveButtonDisabled])

  const dismissTip = () => {
    setShowShortcutTip(false)
  }

  useEffect(() => {
    if (startCreation) {
      dispatch(pushShortcutsGroup([], componentName))
      return () => {
        dispatch(popShortcutsGroup(componentName))
      }
    }

    const wrappedShortcut = (fn) => {
      dismissTip()
      fn()
    }

    const shortcuts = [
      {
        key: 'q',
        label: translations.calendar.createTask,
        callback: () => wrappedShortcut(activateTaskCreationState),
      },
    ]

    if (!saveButtonDisabled) {
      shortcuts.unshift({
        key: 'Enter',
        label: translations.general.save,
        callback: () => wrappedShortcut(handleFocusBlockSave),
      })
    }

    dispatch(pushShortcutsGroup(shortcuts, componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch, handleFocusBlockSave, saveButtonDisabled, startCreation])

  const trackAddTaskToFocusBlock = () => {
    mixpanelApi.track({ event: mixpanel.onboardingAddTaskToFocusBlockClickedEvent })
  }

  const activateTaskCreationState = () => setStartCreation(true)
  const deactivateTaskCreationState = () => setStartCreation(false)

  return (
    <Container>
      <Title>{parser(translations.onboarding.sprintEditor.title)}</Title>
      <SprintContainer>
        <SprintTitle>
          <TitleSection
            isEmptyTitleErrorShown={!editingSprint.title.length}
            onChange={handleTitleChange}
            title={editingSprint.title}
          />
        </SprintTitle>
        <SprintContent empty={saveButtonDisabled}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='tasks'>
              {(droppableProvided) => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {editingSprint.tasks.map((task, index) => (
                    <Draggable draggableId={task.id} index={index} key={task.id}>
                      {(draggableProvided, snapshot) => (
                        <HomebaseLine
                          draggableProps={draggableProvided.draggableProps}
                          draggableStyle={draggableProvided.draggableProps.style}
                          dragHandleProps={draggableProvided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                          hideBlockToCalendarOption
                          hideCompleteCheckbox
                          hideDateInput
                          hideMoreActions
                          hideScheduleSection
                          hideSprint
                          hideStartButton
                          hideWhenDate
                          innerRef={draggableProvided.innerRef}
                          item={task}
                          onDelete={handleTaskDeletion}
                          onSave={handleTaskSave}
                        />
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <HomebaseLine
            creating
            hideBlockToCalendarOption
            hideDateInput
            hideMoreActions
            hideScheduleSection
            hideSprint
            onCancel={deactivateTaskCreationState}
            onCreate={handleTaskCreation}
            onStartCreation={trackAddTaskToFocusBlock}
            startCreation={startCreation}
            startSprintCreation={false}
          />
        </SprintContent>
      </SprintContainer>
      <SaveButton
        backgroundColor={styles.colors.orangeColor}
        borderRadius={8}
        disabled={saveButtonDisabled}
        fontWeight={500}
        onClick={handleFocusBlockSave}
        text={translations.onboarding.sprintEditor.save}
        width={172}
      />
      {showShortcutTip && (
        <ShortcutTip>
          <ShortcutTipArrow alt='tip' src={getSrc('/images/tip-arrow.svg')} />
          <ShortcutTipLabel>
            <span>{parser(translations.onboarding.sprintEditor.tip)}</span>
            <br />
            <ShortcutDismissButton
              backgroundColor='transparent'
              borderColor={styles.colors.darkGrey}
              borderRadius={8}
              fontWeight={700}
              height={34}
              onClick={dismissTip}
              text={translations.onboarding.sprintEditor.tipDismiss}
              textColor={styles.colors.darkGrey}
              width={49}
            />
          </ShortcutTipLabel>
        </ShortcutTip>
      )}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: ${styles.colors.veryLightGrey};
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 5;
`

Container.displayName = 'Container'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 40px;
  text-align: center;

  & strong {
    font-weight: 500;
  }
`

Title.displayName = 'Title'

const SprintContainer = styled.div`
  margin-bottom: 24px;
  max-width: 688px;
  width: 100%;

  .TitleSection {
    height: auto;
    padding: 0;
  }
`

SprintContainer.displayName = 'SprintContainer'

const SprintTitle = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: ${styles.shadows.taskBox};
  color: ${styles.colors.orangeColor};
  display: flex;
  font-size: 18px;
  font-weight: 500;
  padding: 18px 22px;
  position: relative;
  z-index: 1;
`

SprintTitle.displayName = 'SprintTitle'

const SprintContent = styled.div`
  background-color: ${styles.colors.middleGrey};
  border-radius: 0 0 12px 12px;
  margin-top: -4px;
  padding: 28px 18px 16px;
  z-index: 0;

  ${({ empty }) =>
    empty &&
    css`
      padding-top: 12px;
    `}

  & .creation-line-container {
    border-color: ${styles.colors.primaryColor};
    color: ${styles.colors.primaryColor};
    margin-top: 16px;

    .icon {
      path {
        fill: ${styles.colors.primaryColor};
      }
      rect {
        stroke: ${styles.colors.primaryColor};
      }
    }

    :hover {
      background-color: ${styles.colors.primaryColor};
      color: white;

      .icon {
        path {
          fill: white;
        }
        rect {
          stroke: white;
        }
      }
    }
  }
`

SprintContent.displayName = 'SprintContent'

const SaveButton = styled(SimpleButton)`
  flex-shrink: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`

SaveButton.displayName = 'SaveButton'

const ShortcutTip = styled.div`
  bottom: 64px;
  display: flex;
  left: calc(50% + 50px);
  position: fixed;
  z-index: 10;
`

ShortcutTip.displayName = 'ShortcutTip'

const ShortcutTipArrow = styled.img`
  height: 74px;
  margin-right: 12px;
  margin-top: 16px;
  width: 74px;
`

ShortcutTipArrow.displayName = 'ShortcutTipArrow'

const ShortcutTipLabel = styled.div``

ShortcutTipLabel.displayName = 'ShortcutTipLabel'

const ShortcutDismissButton = styled(SimpleButton)`
  margin-top: 16px;
`

ShortcutDismissButton.displayName = 'ShortcutDismissButton'
