import moment from 'moment'
import translations from 'translations'

export const durationTimeUnitsBySecond = 1000000000
export const durationTimeUnitsByMilliSecond = 1000000

export const defaultStartTime = { hour: 9, minute: 0 }
export const defaultScrollToTime = moment({ hour: 6, minute: 30 })

// dateStr must be a string representing a date in the format
// YYYY-MM-DD
export const toDate = (dateStr) => {
  return moment(dateStr, 'YYYY-MM-DD').toDate()
}

// dateStr must be a string representing a date in the format
// YYYY-MM-DD
export const addToDate = (dateStr, numDays) => {
  return moment(dateStr, 'YYYY-MM-DD').add(numDays, 'day').format('YYYY-MM-DD')
}

export const format = (dateStr) => {
  return moment(dateStr, 'YYYY-MM-DD').format('YYYY-MM-DD')
}

/* will return (n) if (n) is not a number */
export const nbToStr2digit = (n) => {
  if (typeof n !== 'number') {
    return n
  }
  return n > 9 ? '' + n : ('0' + n).slice(-2)
}

/* hour could be a string or number */
export const convertHourTo24hFormat = (hour, period, maxHour = 12) => {
  hour = parseInt(hour)
  if (hour === maxHour && period === 'am') {
    return 0
  } else if (hour < maxHour && period === 'pm') {
    return hour + 12
  }
  return hour
}

/* hour could be a string or number */
export const convertHourTo12hFormat = (hour) => {
  const invalid = { hour: null, period: null }
  let period = 'am'
  hour = parseInt(hour)
  if (isNaN(hour)) {
    return invalid
  }

  if (!(hour >= 0 && hour <= 23)) {
    return invalid
  }

  if (hour >= 12) {
    period = 'pm'
  }
  if (hour > 12) {
    hour -= 12
  }

  if (hour === 0) {
    hour = 12
  }

  return { hour, period }
}

export const isValid = (dateStr) => {
  return moment(dateStr, 'YYYY-MM-DD').isValid()
}

export const getNanosecondsFromHourAndMinute = ({ hour, minute }) => {
  hour = parseInt(hour)
  minute = parseInt(minute)
  if (isNaN(hour) || isNaN(minute)) {
    return 0
  }
  let seconds = hour * 60 * 60
  seconds += minute * 60

  return durationTimeUnitsBySecond * seconds
}

export const getNanosecondsFromHourAndMinuteAndSecond = ({ hour, minute, second }) => {
  hour = parseInt(hour)
  minute = parseInt(minute)
  second = parseInt(second)

  if (isNaN(hour) || isNaN(minute) || isNaN(second)) {
    return 0
  }
  let seconds = hour * 60 * 60
  seconds += minute * 60
  seconds += second

  return durationTimeUnitsBySecond * seconds
}

export const getHourAndMinuteFromNanoseconds = (nanoseconds) => {
  nanoseconds = parseInt(nanoseconds)
  let seconds = nanoseconds / durationTimeUnitsBySecond
  if (isNaN(seconds)) {
    return { hour: null, minute: null }
  }
  const hour = Math.floor(seconds / 3600)
  const minute = Math.floor((seconds % 3600) / 60)

  return { hour, minute }
}

export const getDurationInNanoSeconds = ({ start, end }) => {
  const diff = moment(end).diff(moment(start))
  return convertMilliSecondsToNanoSecond(diff)
}

export const getDurationObj = ({ start, end }) => {
  const spentTimeInNanoSeconds = getDurationInNanoSeconds({ start, end })
  return getHourAndMinuteAndSecondFromNanoseconds(spentTimeInNanoSeconds)
}

export const getHourAndMinuteAndSecondFromNanoseconds = (nanoseconds) => {
  nanoseconds = parseInt(nanoseconds)
  let seconds = nanoseconds / durationTimeUnitsBySecond
  if (isNaN(seconds)) {
    return { hour: null, minute: null, second: null }
  }
  const hour = Math.floor(seconds / 3600)
  const minute = Math.floor((seconds % 3600) / 60)
  const second = Math.floor((seconds % 3600) % 60)

  return { hour, minute, second }
}

export const convertNanosecondsToMinute = (nanoseconds) => nanoseconds / 60000000000
export const convertMinuteToNanoseconds = (nanoseconds) => nanoseconds * 60000000000
export const convertSecondsToNanoseconds = (nanoseconds) => nanoseconds * 1000000000
export const convertMilliSecondsToNanoSecond = (milliseconds) => milliseconds * 1000000
export const convertNanoSecondsToMilliSeconds = (nanoseconds) => nanoseconds / 1000000

/* value, min, max can be a number or string */
export const isHourOrMinuteBetweenLimits = (value, min, max) => {
  let intValue = parseInt(value)
  min = parseInt(min)
  max = parseInt(max)

  /* invalid if the intValue is not number */
  if (isNaN(intValue) || isNaN(min) || isNaN(max)) {
    return false
  }

  /*
   ** if value is a string '0', we consider it valid because the user could write a second digit between min & max
   */
  if (value === '0') {
    return true
  }

  if (intValue < min || intValue > max) {
    return false
  }

  return true
}

/* expect params to be a formatted date string YYYY-MM-DD, but also accept random string (will be last) */
export const compareDates = (dateAStr, dateBStr) => {
  const dateA = moment(dateAStr, 'YYYY-MM-DD', true)
  const dateB = moment(dateBStr, 'YYYY-MM-DD', true)
  if (!dateA.isValid()) {
    return 1
  } else if (!dateB.isValid()) {
    return -1
  }
  return dateB.diff(dateA, 'days')
}

/* nanoduration should be in nanoseconds */
export const beautifyDuration = (nanoduration, showZero = false, withSpace) => {
  const { hour, minute } = getHourAndMinuteFromNanoseconds(nanoduration)
  let result = ''
  /* if duration is 0mins, return an empty string */
  if (!hour && !minute && !showZero) {
    return result
  }

  if (hour > 0) {
    result = `${nbToStr2digit(hour)}:${nbToStr2digit(minute)}${withSpace ? ' ' : ''}h`
  } else {
    result = `${minute}${withSpace ? ' ' : ''}min`
  }
  return result
}

/* expect a date string */
export const beautifyTime = (dateStr, format = '12h') => {
  if (!dateStr) {
    return ''
  }
  let momentFormat = 'hh:mm A'
  if (format === '24h') {
    momentFormat = 'HH:mm'
  }
  return moment(dateStr).format(momentFormat)
}

export const beautifyStartTime = (dateStr, format = '12h') => {
  let momentFormat = 'hh:mm A'
  if (format === '24h') {
    momentFormat = 'HH:mm'
  }
  let str = humanizeDate(dateStr)
  str += ' - ' + moment(dateStr).format(momentFormat)
  return str
}

export const beautifyStartTimeWithoutDate = (dateStr, format = '12h') => {
  let momentFormat = 'hh:mm A'
  if (format === '24h') {
    momentFormat = 'HH:mm'
  }
  let str = moment(dateStr).format(momentFormat)

  return str
}

export const beautifySpentTime = (spentTime) => {
  let { hour, minute, second } = getHourAndMinuteAndSecondFromNanoseconds(spentTime)
  hour = nbToStr2digit(hour)
  minute = nbToStr2digit(minute)
  second = nbToStr2digit(second)
  let s = hour > 0 ? `${hour}:` : ''
  s += `${minute}:${second}`
  return s
}

/* if period is null, we expect hour to be a 0-24h number */
export const createDateTimeStrFromDigitsAndPeriod = (dateStr, hourValue, minuteValue, period = '') => {
  const date = moment(dateStr)
  period = period.toLowerCase()

  let hours = !isNaN(parseInt(hourValue, 10)) ? parseInt(hourValue, 10) : 0
  let minutes = !isNaN(parseInt(minuteValue, 10)) ? parseInt(minuteValue, 10) : 0
  if (period === 'pm' && hours < 12) {
    hours += 12
  } else if (period === 'am' && hours === 12) {
    hours = 0
  }
  date.hours(hours)
  date.minutes(minutes)

  return date.format()
}

export const isFullDateTimeStr = (dateStr) => {
  if (!dateStr || !isValid(dateStr)) {
    return false
  }
  return moment(dateStr).format() === dateStr
}

export const isToday = (dateStr) => {
  if (!dateStr || !isValid(dateStr)) {
    return false
  }
  const date = moment(dateStr)

  return isSameDate(date, moment())
}

export const isTomorrow = (dateStr) => {
  if (!dateStr || !isValid(dateStr)) {
    return false
  }
  const date = moment(dateStr)
  const tomorrow = moment().add(1, 'days').startOf('day')

  return isSameDate(date, tomorrow)
}

export const isYesterday = (dateStr) => {
  if (!dateStr || !isValid(dateStr)) {
    return false
  }
  const date = moment(dateStr)
  const yesterday = moment().subtract(1, 'days').startOf('day')

  return isSameDate(date, yesterday)
}

// undefined is not considered as noDate
export const isBacklog = (dateStr) => {
  return dateStr === ''
}

export const isOnboarding = (dateStr) => {
  return dateStr === 'Onboarding'
}

export const isSameDate = (date1, date2) => {
  date1 = moment(date1)
  date2 = moment(date2)
  return date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')
}

export const isSameWeek = (date1, date2) => {
  date1 = moment(date1)
  date2 = moment(date2)
  return date1.isSame(date2, 'week') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')
}
export const isBefore = (date1, date2) => {
  date1 = moment(date1)
  date2 = moment(date2)
  return date1.isBefore(date2, 'day')
}

export const isBeforeToday = (date) => {
  const date1 = moment(date)
  const date2 = moment(moment().startOf('day'))
  return isBefore(date1, date2)
}

export const isAfter = (date1, date2) => {
  if (isBacklog(date1) && !isBacklog(date2)) {
    return true
  }
  if (isBacklog(date2) && !isBacklog(date1)) {
    return false
  }
  date1 = moment(date1)
  date2 = moment(date2)
  return date1.isAfter(date2, 'day')
}

export const getShortWeekDay = (dateStr) => {
  const date = moment(dateStr, 'YYYY-MM-DD')
  if (!date.isValid()) {
    return null
  }

  return date.format('ddd')
}

export const humanizeDate = (dateStr, format) => {
  if (!dateStr) {
    return null
  }
  const date = moment(dateStr, 'YYYY-MM-DD')

  if (!date.isValid()) {
    return null
  }

  if (format) {
    date.format(format)
  }

  const today = moment()
  return humanizeDateFromToday(date, today)
}

export const humanizeDateFromToday = (date, today, formats) => {
  const startOfToday = today.clone().startOf('day')
  const startOfYesterday = startOfToday.clone().subtract(1, 'days')
  const startOfTomorrow = startOfToday.clone().add(1, 'days')

  if (isSameDate(startOfToday, date)) {
    return translations.general.today
  } else if (isSameDate(startOfTomorrow, date)) {
    return translations.general.tomorrow
  } else if (isSameDate(startOfYesterday, date)) {
    return translations.general.yesterday
  } else if (isSameWeek(startOfToday, date)) {
    return date.format(formats?.sameWeek || 'dddd')
  } else if (isBefore(date, startOfToday)) {
    return date.format(formats?.other || 'MMM DD')
  }
  return date.format(formats?.other || 'MMM DD')
}

export const beautifyDate = (dateStr) => {
  return moment(dateStr).format('MMM DD')
}

export const beautifyDateWithDay = (dateStr) => {
  return moment(dateStr).format('ddd, MMM DD')
}

export const humanizeDateTime = (dateStr, dateTimeStr) => {
  let str = humanizeDate(dateStr)
  if (dateTimeStr) {
    str = `${humanizeDate(dateTimeStr)} at ${beautifyTime(dateTimeStr)}`
  }
  return str
}

export const convertToFullCallendarDuration = (date = defaultScrollToTime) => {
  const day = moment(date)
  const startOfDay = moment(date).startOf('day')
  return moment.duration(day.diff(startOfDay)).asMilliseconds()
}

export const setDateInDateTime = (date, datetime) => {
  date = moment(date)
  return moment(datetime).date(date.date()).month(date.month()).year(date.year())
}

export const isBeforeDateStr = (dateStr1, dateStr2) => {
  if (dateStr1 && dateStr2) {
    return dateStr1 < dateStr2
  } else {
    return !!dateStr1
  }
}
