import { createAction } from 'redux-actions'
import { types } from 'store/taskPanel/types'

const _setDragging = createAction(types.SET_DRAGGING)

const _setFilteredItemsIds = createAction(types.SET_FILTERED_ITEMS_IDS)
const _addFilteredItemId = createAction(types.ADD_FILTERED_ITEM_ID)
const _updateFilteredItemId = createAction(types.UPDATE_FILTERED_ITEM_ID)
const _removeFilteredItemId = createAction(types.REMOVE_FILTERED_ITEM_ID)

export const setFilteredItemsIds = (filteredItemsIds) => (dispatch) =>
  dispatch(_setFilteredItemsIds({ filteredItemsIds }))

export const addFilteredItemId = (itemId) => (dispatch) => dispatch(_addFilteredItemId({ itemId }))
export const updateFilteredItemId = (itemId, previousId) => (dispatch) =>
  dispatch(_updateFilteredItemId({ itemId, previousId }))
export const removeFilteredItemId = (itemId) => (dispatch) => dispatch(_removeFilteredItemId({ itemId }))

export const setDragging = (isDragging) => (dispatch) => dispatch(_setDragging(isDragging))
