import moment from 'moment'

import { type as itemTypes } from 'models/item'
import { getNextDatesUntil } from 'utils/recurrency/scheduling'
import { RecurrencyDetails } from 'utils/recurrency/types'
import { generateId } from 'utils/ids'

const numDaysAheadScheduled = 60
const { TASK } = itemTypes

interface RecurrencyInformation {
  originalRecurrentDate: string
  recurrencyDetails: RecurrencyDetails
  recurringTaskId: string
}

type URLInfo = {
  subject: string
  type: string
  url: string
}

export interface Task {
  creationTime: Date
  estimatedTime: number
  id: string
  pin: {
    time: Date
  } | null
  projects?: any[]
  tags?: any[]
  title: string
  type: string
  urlsInfo?: URLInfo[]
  when: {
    date: string
  } | null
  recurrencyInformation?: RecurrencyInformation
}

export interface RecurringTaskBlueprint {
  estimatedTime: number
  pin: {
    time: Date
  } | null
  projects?: any[]
  tags?: any[]
  title: string
  urlsInfo?: URLInfo[]
  when: {
    date: string
  } | null
}

export interface RecurringTask {
  blueprint: RecurringTaskBlueprint
  creationTime: Date
  id: string
  recurrencyDetails: RecurrencyDetails
}

export const computeRecurringTask = (
  userId: string,
  task: Task,
  recurrencyDetails: RecurrencyDetails
): RecurringTask | null => {
  const { id, creationTime } = generateId(TASK, userId)
  const recTask: RecurringTask = {
    id,
    creationTime,
    recurrencyDetails,
    blueprint: {
      estimatedTime: task.estimatedTime,
      pin: task.pin,
      title: task.title,
      when: task.when,
    },
  }

  if (task.projects) {
    recTask.blueprint.projects = task.projects
  }

  if (task.tags) {
    recTask.blueprint.tags = task.tags
  }

  if (task.urlsInfo) {
    recTask.blueprint.urlsInfo = task.urlsInfo
  }

  return recTask
}

export const scheduleNextTasksForDay = (fromDay: string, recTask: RecurringTask): Task[] | null => {
  const dates = getNextDatesUntil(
    fromDay,
    recTask.recurrencyDetails,
    null,
    moment().add(numDaysAheadScheduled, 'days').format('YYYY-MM-DD')
  )

  if (!dates) return null

  const newTasks: Task[] = dates.map((date) => {
    const dateMoment = moment(date)
    let newPinTime = null
    let whenDate = date

    if (recTask.blueprint.pin?.time) {
      const basePin = moment(recTask.blueprint.pin.time)
      newPinTime = basePin.set({ date: dateMoment.date(), month: dateMoment.month(), year: dateMoment.year() })
      whenDate = newPinTime.format('YYYY-MM-DD')
    }

    const newTask: Task = {
      creationTime: recTask.creationTime,
      estimatedTime: recTask.blueprint.estimatedTime,
      id: `${recTask.id}_${date}`,
      pin: newPinTime
        ? {
            time: newPinTime.toDate(),
          }
        : null,
      recurrencyInformation: {
        recurrencyDetails: recTask.recurrencyDetails,
        recurringTaskId: recTask.id,
        originalRecurrentDate: date,
      },
      title: recTask.blueprint.title,
      type: TASK,
      when: {
        date: whenDate,
      },
    }

    if (recTask.blueprint.projects) {
      newTask.projects = recTask.blueprint.projects
    }

    if (recTask.blueprint.tags) {
      newTask.tags = recTask.blueprint.tags
    }

    if (recTask.blueprint.urlsInfo) {
      newTask.urlsInfo = recTask.blueprint.urlsInfo
    }

    return newTask
  })

  return newTasks
}

export const scheduleNextTasks = (recTask: RecurringTask): Task[] | null => {
  const today = moment().format('YYYY-MM-DD')
  const result = scheduleNextTasksForDay(today, recTask)
  return result
}

export const splitInstances = (
  instances: Task[],
  taskId: string
): { before: Task[]; instance: Task | null; after: Task[] } => {
  let before: Task[] = []
  let after: Task[] = []
  if (!instances?.length) {
    return { before, instance: null, after }
  }

  const sortedInstances = instances.sort((a, b) => {
    const [, aDateStr] = a.id.split('_')
    const [, bDateStr] = b.id.split('_')
    return aDateStr < bDateStr ? -1 : 1
  })

  const instanceIdx = sortedInstances.findIndex((instance) => instance.id === taskId)

  if (instanceIdx === -1) {
    after = instances
    return { before, instance: null, after }
  }

  before = sortedInstances.slice(0, instanceIdx)
  const instance = sortedInstances[instanceIdx]

  if (instanceIdx + 1 < sortedInstances.length) {
    after = sortedInstances.slice(instanceIdx + 1)
  }

  return { before, instance, after }
}
