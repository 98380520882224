import axios from 'utils/axios'
import { utils } from 'gipsy-misc'

let cancelTokens = {}

// params can be of the type {filter:filter}
// with filterTyope taking as value
// active : to only have active projects
// archived : to only have archived projects
// all : to have all of them
// if there's no filter, the default is all
export const activeParam = 'active'
export const archivedParam = 'archived'
export const get = (filter) => {
  let endpoint = `/projects`
  if (filter) {
    endpoint = utils.request.appendToUrl(endpoint, { filter: filter })
  }
  return axios.get(endpoint)
}

export const create = (body) => {
  const endpoint = `/projects`
  return axios.post(endpoint, body)
}

export const edit = (body) => {
  let endpoint = `/projects/edit`
  return axios.post(endpoint, body)
}

export const archive = (projectId) => {
  let endpoint = `/projects/archive`
  const body = { projectId }
  return axios.post(endpoint, body)
}

export const unarchive = (projectId) => {
  let endpoint = `/projects/unarchive`
  const body = { projectId }
  return axios.post(endpoint, body)
}

export const dragAndDropProject = (projectId, toRank) => {
  let endpoint = `/projects/drag-and-drop`
  const payload = { projectId, toRank }
  return axios.post(endpoint, payload)
}

export const dragAndDropTasks = (projectId, body) => {
  let endpoint = `/projects/tasks/drag-and-drop`
  const payload = { projectId: projectId, ...body }
  return axios.post(endpoint, payload)
}

export const dragAndDropTasksInUncategorized = (body) => {
  let endpoint = `/uncategorized/tasks/drag-and-drop`
  return axios.post(endpoint, body)
}

export const del = (projectId) => {
  const endpoint = `/projects/${projectId}`
  return axios.delete(endpoint)
}

export const cancel = (method) => {
  if (cancelTokens[method] && cancelTokens[method].cancel) {
    cancelTokens[method].cancel()
  }
}
