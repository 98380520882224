import moment from 'moment'

import { getUTMInfoFromLocalStorage, clearUTMInfoFromLocalStorage } from 'logic/login'

export const extractStateParameters = (searchParams) => {
  const nonce = window.sessionStorage.getItem('nonceOAuth') || ''
  const utmInfo = getUTMInfoFromLocalStorage()

  const res = {
    error: searchParams.error,
    state: searchParams.state,
    localStateGoogle: window.sessionStorage.getItem('stateOAuth-google'),
    localStateMicrosoft: window.sessionStorage.getItem('stateOAuth-microsoft'),
    code: searchParams.code,
    nonce,
    tz: moment.tz.guess(),
    redirectUri: searchParams.redirectUri || null,
    utmInfo: utmInfo,
  }

  clearOauthDataFromSessionStorage()

  return res
}

export const clearOauthDataFromSessionStorage = (windowRef) => {
  windowRef = windowRef || window
  windowRef.sessionStorage.removeItem('stateOAuth-microsoft')
  windowRef.sessionStorage.removeItem('stateOAuth-google')
  windowRef.sessionStorage.removeItem('nonceOAuth')
  clearUTMInfoFromLocalStorage()
}
