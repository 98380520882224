import { PureComponent } from 'react'
import { checkAppVersionAndUpdate } from 'logic/app'
import { withRouter } from 'react-router'

class UpdateVersion extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      checkAppVersionAndUpdate()
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(UpdateVersion)
