const error = (error, data) => {
  error = error || {}
  if (error.message) {
    return console.error(error.message, data)
  }
  if (error.data && error.data.message) {
    return console.error(error.data.message, data)
  }
  if (typeof error === 'string') {
    return console.error(error, data)
  }
  console.error(error)
  console.debug(data)
}

const log = console.log
const info = console.log
const warn = console.warn
const debug = console.debug

const obj = {
  error,
  log,
  info,
  warn,
  debug,
}

export default obj
