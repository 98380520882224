import React, { Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import AnimateHeight from 'react-animate-height'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { remapData } from 'logic/draganddrop'
import { updateCalendarDate } from 'store/calendar/actions'
import { styles, translations, utils } from 'gipsy-misc'

function ExpandableDragDropGroup(props) {
  const { groupKeys, groups, children, draggedItem, droppablePrefix, isExpanded } = props
  const computeDroppableId = (date) => `${droppablePrefix}-${date}`

  return (
    <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0}>
      <TaskGroupContainer expanded={isExpanded}>
        {groupKeys.map((groupDate) => (
          <DateGroup
            key={groupDate}
            date={groupDate}
            droppableId={computeDroppableId(groupDate)}
            items={groups[groupDate]}
            draggedItem={draggedItem}
            renderItem={children}
          />
        ))}
      </TaskGroupContainer>
    </AnimateHeight>
  )
}

export default React.memo(ExpandableDragDropGroup)

const DateGroup = connect(null, (dispatch) => ({
  actions: bindActionCreators(
    {
      updateCalendarDate,
    },
    dispatch
  ),
}))(
  React.memo((props) => {
    const { date, droppableId, items, draggedItem, renderItem, actions } = props
    const now = moment()
    const remappedItems = remapData(items, draggedItem)
    return (
      <Fragment>
        <TaskGroupDate
          onClick={() => date && actions.updateCalendarDate(date)}
          isPast={date && moment(date).format('YYYY-MM-DD') < now.format('YYYY-MM-DD')}>
          {date ? utils.datetime.humanizeDate(date, 'ddd, MMM DD') : translations.general.noDate}
        </TaskGroupDate>
        <Droppable droppableId={droppableId} isDropDisabled type={'task'}>
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              <DraggableItemList droppableId={droppableId} items={remappedItems} renderItem={renderItem} />
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </Fragment>
    )
  })
)

const DraggableItemList = React.memo(function DraggableItemList({ droppableId, items, renderItem }) {
  return items.map((item, index) => {
    return (
      <Draggable draggableId={`${item.id}`} index={index} key={item.id} type={droppableId}>
        {(draggableProvided, snapshot) => renderItem(draggableProvided, snapshot, item)}
      </Draggable>
    )
  })
})

const TaskGroupContainer = styled.div`
  margin-top: -24px;
  padding-top: 12px;
`

const TaskGroupDate = styled.div`
  width: 100%;
  color: ${(props) => (props.isPast ? styles.colors.pinkColor : styles.colors.primaryColor)};
  font-weight: 500;
  font-size: ${styles.fonts.fontSizeSmall};
  text-align: right;
  cursor: pointer;
  margin-bottom: 12px;
  margin-top: 12px;
`
