import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'Icon'
import { Transition } from 'react-transition-group'
import { styles } from 'gipsy-misc'

function Alert(props) {
  const {
    show,
    top,
    left,
    text,
    absolute,
    animationDuration,
    disableIntrinsicCenter,
    mode,
    iconSize,
    actionButton,
    padding,
    spacedIcon,
    className,
  } = props
  const getIconProps = () => {
    switch (mode) {
      case Alert.mode.DEFAULT:
        return {
          icon: 'CheckRound',
          fill: styles.colors.primaryColor,
        }
      case Alert.mode.SUCCESS:
        return {
          icon: 'CheckRound',
          fill: styles.colors.greenColor,
        }
      case Alert.mode.ERROR:
        return {
          icon: 'Warning',
          fill: 'none',
          stroke: styles.colors.pinkColor,
        }
      case Alert.mode.WARNING:
        return {
          icon: 'Warning',
          fill: 'none',
          stroke: styles.colors.orangeColor,
        }
      default:
        return {
          icon: 'Warning',
          fill: 'none',
          stroke: styles.colors.orangeColor,
        }
    }
  }

  return (
    <Transition in={show} mountOnEnter unmountOnExit timeout={animationDuration}>
      {(state) => (
        <Container
          className={className}
          absolute={absolute}
          animationDuration={animationDuration}
          padding={padding}
          disableIntrinsicCenter={disableIntrinsicCenter}
          transitionState={state}
          mode={mode}
          top={top}
          left={left}
          show={show}>
          <StyledIcon spacedIcon={spacedIcon} size={iconSize || 12} {...getIconProps()} />
          <Content>{text}</Content>
          {actionButton}
        </Container>
      )}
    </Transition>
  )
}

const getAnimationStyle = (state, duration) => {
  switch (state) {
    case 'entering': {
      return css`
        opacity: 0;
      `
    }
    case 'entered': {
      return css`
        opacity: 1;
      `
    }
    case 'exiting': {
      return css`
        transition: all ${duration}ms ease-in-out;
        opacity: 1;
        transform: translate(-100vw, -100vh);
        left: 0;
        top: 0;
      `
    }
    case 'exited': {
      return css`
        opacity: 0;
        left: 0;
        top: 0;
        transform: translate(-100vw, -100vh);
      `
    }
    default: {
      return css``
    }
  }
}

const getPositionValue = (value) => {
  if (!value) return
  if (typeof value !== 'string') {
    return `${value}px`
  }
  return value
}

const getModeStyle = (mode) => {
  switch (mode) {
    case Alert.mode.DEFAULT:
      return css``
    case Alert.mode.SUCCESS:
      return css``
    case Alert.mode.ERROR:
      return css`
        background: ${styles.colors.pinkColor}16;
      `
    case Alert.mode.WARNING:
      return css``
    default:
      return css``
  }
}

const StyledIcon = styled(Icon)`
  margin-right: ${(props) => (props.spacedIcon ? 39 : 14)}px;
  margin-left: ${(props) => (props.spacedIcon ? 18 : 0)}px;
`
const Content = styled.span`
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
`

const Container = styled.div`
  min-width: 250px;
  background: white;
  padding: ${(props) => (isNaN(parseInt(props.padding)) ? 20 : props.padding)}px;
  box-shadow: ${styles.shadows.popup};
  border-radius: 8px;
  z-index: 10;
  align-items: center;
  opacity: 0;
  color: ${styles.colors.textMediumDarkColor};
  display: flex;
  overflow: auto;
  transform: ${(props) =>
    props.disableIntrinsicCenter || !props.absolute ? 'translate(0, 0)' : 'translate(-50%, -50%)'};
  position: ${(props) => (props.absolute ? 'absolute' : 'static')};
  left: ${(props) => (props.absolute && getPositionValue(props.left)) || 0};
  top: ${(props) => (props.absolute && getPositionValue(props.top)) || 0};
  transition: opacity ${(props) => props.animationDuration}ms ease-in-out;
  ${(props) => getAnimationStyle(props.transitionState, props.animationDuration)}
  ${(props) => getModeStyle(props.mode)}
  
  ${StyledIcon}, ${Content} {
    flex-shrink: 0;
  }

  button {
    width: fit-content;
    padding: 0 14px;
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
`

Alert.mode = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

Alert.defaultProps = {
  mode: Alert.mode.DEFAULT,
  animationDuration: 300,
}
Alert.propTypes = {
  show: PropTypes.bool,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  absolute: PropTypes.bool,
  disableIntrinsicCenter: PropTypes.bool,
  mode: PropTypes.string,
  animationDuration: PropTypes.number,
  actionButton: PropTypes.element,
  iconSize: PropTypes.number,
  padding: PropTypes.number,
  spacedIcon: PropTypes.bool,
}

export default Alert
