import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { user as userApi } from 'gipsy-api'
import { styles, translations } from 'gipsy-misc'
import { PageContainer, PageBody, TitleContainer, TitleSeparator, LeftIconContainer } from 'features/layout/pages'

class UnsubscribedPage extends PureComponent {
  componentWillMount() {
    const email = this.getUserEmailFromQueryParams()
    if (!email) this.props.history.push('/')
    this.unsubscribeUser(email)
  }

  unsubscribeUser = (email) => {
    userApi.unsubscribeFromEmailNotifications(email)
  }

  getUserEmailFromQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search)
    return queryParams.get('user')
  }

  goToUserSettings = () => {
    this.props.history.push('/settings')
  }

  renderHeader = () => {
    const { logoImage } = this.props
    return (
      <Header>
        <TitleContainer>
          <LeftIconContainer>
            <Link to="/">
              <IconImg src={logoImage.currentLogoUrl} style={logoImage.style} alt="logo" />
            </Link>
          </LeftIconContainer>
        </TitleContainer>
        <TitleSeparator />
      </Header>
    )
  }

  render() {
    const userEmail = this.getUserEmailFromQueryParams()
    return (
      <PageContainer>
        <PageBody>
          {this.renderHeader()}
          <GrayBall />
          <GreenBall />
          <RedBall />
          <PurpleBall />
          <Section>
            <SectionTitle>
              <span className="emoji" role="img" aria-label="Sad">
                😢
              </span>
              <br />
              {translations.unsubscribe.title}
            </SectionTitle>
            <SectionDescription>{translations.unsubscribe.detail.replace('[userEmail]', userEmail)}</SectionDescription>
          </Section>
          <TitleSeparator />

          <UnsubscribeButton onClick={this.goToUserSettings}>
            {translations.unsubscribe.changeEmailSettings}
          </UnsubscribeButton>
        </PageBody>
      </PageContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  logoImage: state.layout.logoImage,
})

export default withRouter(
  connect(mapStateToProps, null, null, {
    pure: true,
  })(UnsubscribedPage)
)

const Section = styled.div`
  padding: ${styles.spacings.xlarge} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SectionTitle = styled.div`
  font-size: ${styles.fonts.fontSize};
  line-height: ${styles.spacings.medium};
  font-weight: 500;
  text-align: center;

  .emoji {
    font-size: 1.5rem;
  }
`

const Ball = styled.div`
  border-radius: 50%;
  z-index: -1;
`

const GrayBall = styled(Ball)`
  position: absolute;
  width: 857px;
  height: 857px;
  left: -175px;
  top: -425px;
  background: ${styles.colors.middleGrey};
`

const GreenBall = styled(Ball)`
  position: absolute;
  width: 403px;
  height: 403px;
  left: -375px;
  top: -175px;
  background: #6afccd;
  box-shadow: 0px 77px 72px rgba(106, 252, 205, 0.323992);
`
const RedBall = styled(Ball)`
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0;
  top: 415px;
  background: #ff2171;
  box-shadow: 0px 18px 17px rgba(255, 33, 113, 0.221354);
`

const PurpleBall = styled(Ball)`
  position: absolute;
  width: 145px;
  height: 145px;
  left: 335px;
  top: 520px;
  background: #643fdb;
  box-shadow: 0px 77px 72px rgba(100, 63, 219, 0.404891);
`

const SectionDescription = styled.p`
  font-size: ${styles.fonts.fontSizeXSmall};
  max-width: 350px;
  text-align: center;
  line-height: 20px;
  color: ${styles.colors.darkGrey};
`

const Header = styled.div`
  margin-top: ${styles.spacings.xlarge};
`

const UnsubscribeButton = styled.p`
  font-size: ${styles.fonts.fontSizeXSmall};
  line-height: ${styles.spacings.medium};
  padding-top: ${styles.spacings.xsmall};
  color: ${styles.colors.primaryColor};
  cursor: pointer;
`
const IconImg = styled.div`
  ${(props) => ({ ...props.style })}
  background: url("${(props) => props.src}") center center no-repeat;
  background-size: contain;
  transition: all 400ms ease-in-out;
  margin-bottom: ${styles.spacings.xsmall};
`
