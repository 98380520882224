import { types } from './types'
import { getSrc } from 'utils/image'

const defaultLogo = getSrc('/images/from-zen-to-normal.gif')
const initialRoute = window.location.pathname

const initialState = {
  logoImage: {
    currentLogoUrl: defaultLogo,
    style: {
      width: 60,
      height: 70,
      marginLeft: -10,
      marginTop: 0,
    },
  },
  navigationStats: {
    hasNavigated: false,
    initialRoute,
  },
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_LOGO:
      return {
        ...state,
        logoImage: initialState.logoImage,
      }
    case types.SET_ANIMATED_LOGO_OPENING:
      return {
        ...state,
        logoImage: {
          currentLogoUrl: getSrc('/images/from-normal-to-zen.gif') + `?t=${new Date()}`,
          style: {
            width: 60,
            height: 70,
            marginLeft: -10,
            marginTop: 0,
          },
        },
      }

    case types.SET_ANIMATED_LOGO_CLOSING:
      return {
        ...state,
        logoImage: {
          currentLogoUrl: getSrc('/images/from-zen-to-normal.gif') + `?t=${new Date()}`,
          style: {
            width: 60,
            height: 70,
            marginLeft: -10,
            marginTop: 0,
          },
        },
      }

    case types.SET_HAS_NAVIGATED:
      return {
        ...state,
        navigationStats: {
          ...state.navigationStats,
          hasNavigated: true,
        },
      }

    case types.SET_INITIAL_ROUTE:
      return {
        ...state,
        navigationStats: {
          ...state.navigationStats,
          initialRoute: action.payload,
        },
      }

    default:
      break
  }
  return state
}

export default layoutReducer
