import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, styles, translations } from 'gipsy-misc'
import { Icon, RadioInput, SimpleButton } from 'gipsy-ui'

import CalendarAnimation from 'pages/onboarding/components/calendarAnimation'

export const OPTIONS = {
  SPRINT: 'sprint',
  TASK: 'task',
}

export default function MasterTimeBlockingStep({ onStepDone, stepNumber }) {
  const [optionSelected, setOptionSelected] = useState(null)
  const [sprintHovered, setSprintHovered] = useState(false)
  const [taskHovered, setTaskHovered] = useState(false)

  const selectTaskOption = () => {
    setOptionSelected(OPTIONS.TASK)
  }

  const selectSprintOption = () => {
    setOptionSelected(OPTIONS.SPRINT)
  }

  const handleStepDone = () => {
    if (optionSelected === OPTIONS.TASK) {
      mixpanelApi.track({ event: mixpanel.onboardingMasterTimeBlockingEvent }, 'Task')
    } else {
      mixpanelApi.track({ event: mixpanel.onboardingMasterTimeBlockingEvent }, 'Focus Block')
    }

    onStepDone(stepNumber, optionSelected)
  }

  useEffect(() => {
    const handleBlur = () => {
      setSprintHovered(false)
      setTaskHovered(false)
    }

    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('blur', handleBlur)
    }
  })

  const sprintSelected = optionSelected === OPTIONS.SPRINT
  const taskSelected = optionSelected === OPTIONS.TASK

  return (
    <Container>
      <Title>{translations.onboarding.masterTimeBlockingStep.title}</Title>
      <Subtitle>{translations.onboarding.masterTimeBlockingStep.subtitle}</Subtitle>
      <Options>
        <OptionCard
          onClick={selectTaskOption}
          onMouseEnter={() => setTaskHovered(true)}
          onMouseLeave={() => setTaskHovered(false)}
          selected={taskSelected}>
          <OptionInputColumn>
            <StyledRadioInput
              checked={taskSelected}
              checkedColor={styles.colors.primaryColor}
              disableStopPropagation
              size={16}
            />
          </OptionInputColumn>
          <OptionDataColumn>
            <OptionTitleWrapper>
              <OptionIcon fill='transparent' icon='PinOutline' size={24} stroke={styles.colors.primaryColor} />
              <OptionTitle>{translations.onboarding.masterTimeBlockingStep.createTask}</OptionTitle>
            </OptionTitleWrapper>
            <OptionSubtitle>{translations.onboarding.masterTimeBlockingStep.learnHowToCreateTask}</OptionSubtitle>
            <CalendarAnimation active={taskHovered || taskSelected} />
          </OptionDataColumn>
        </OptionCard>
        <OptionCard
          onClick={selectSprintOption}
          onMouseEnter={() => setSprintHovered(true)}
          onMouseLeave={() => setSprintHovered(false)}
          selected={sprintSelected}
          sprint>
          <OptionInputColumn>
            <StyledRadioInput
              checked={sprintSelected}
              checkedColor={styles.colors.orangeColor}
              disableStopPropagation
              size={16}
            />
          </OptionInputColumn>
          <OptionDataColumn>
            <OptionTitleWrapper>
              <OptionIcon fill={styles.colors.orangeColor} icon='Sprint' size={24} />
              <OptionTitle>{translations.onboarding.masterTimeBlockingStep.createSprint}</OptionTitle>
            </OptionTitleWrapper>
            <OptionSubtitle>{translations.onboarding.masterTimeBlockingStep.learnHowToCreateSprint}</OptionSubtitle>
            <CalendarAnimation active={sprintHovered || sprintSelected} sprint />
          </OptionDataColumn>
        </OptionCard>
      </Options>
      <NextButton
        backgroundColor={styles.colors.primaryColor}
        borderColor='transparent'
        borderRadius={8}
        disabled={optionSelected === null}
        height={48}
        onClick={handleStepDone}
        text={translations.onboarding.selectSyncStep.mainButtonText}
        textColor='white'
        width={153}
      />
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`

Container.displayName = 'Container'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 32px;
  font-weight: 500;
  margin: 0 0 24px;
  text-align: center;
`

Title.displayName = 'Title'

const Subtitle = styled.p`
  color: ${styles.colors.textDarkColor};
  margin: 0 0 40px;
`

Subtitle.displayName = 'Subtitle'

const Options = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: center;
  width: 100%;

  & > div:first-of-type {
    margin-right: 16px;
  }
`

Options.displayName = 'Options'

const OptionTitle = styled.p`
  color: ${styles.colors.primaryColor};
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`

OptionTitle.displayName = 'OptionTitle'

const OptionCard = styled.div`
  background-color: white;
  border: 1px solid ${styles.colors.primaryColor}44;
  border-radius: 10px;
  box-shadow: ${styles.shadows.taskMenuHover};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  max-width: 461px;
  min-width: 461px;
  padding: 24px 16px 42px;
  width: 461px;

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${styles.colors.primaryColor};
      border-width: 2px;
    `}

  ${({ selected, sprint }) =>
    sprint &&
    css`
      border-color: ${selected ? styles.colors.orangeColor : styles.colors.orangeColor + '44'};

      ${OptionTitle} {
        color: ${styles.colors.orangeColor};
      }
    `}
`

OptionCard.displayName = 'OptionCard'

const OptionInputColumn = styled.div`
  flex-shrink: 0;
  margin-right: 6px;
`

OptionInputColumn.displayName = 'OptionInputColumn'

const StyledRadioInput = styled(RadioInput)`
  margin-top: 4px;
`

StyledRadioInput.displayName = 'StyledRadioInput'

const OptionDataColumn = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
`

OptionDataColumn.displayName = 'OptionDataColumn'

const OptionTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 12px;
`

OptionTitleWrapper.displayName = 'OptionTitleWrapper'

const OptionIcon = styled(Icon)`
  margin-right: 10px;
`

OptionIcon.displayName = 'OptionIcon'

const OptionSubtitle = styled.p`
  font-size: 14px;
  margin: 0 0 24px 36px;
`

OptionSubtitle.displayName = 'OptionSubtitle'

const NextButton = styled(SimpleButton)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-top: 40px;
  transition: background-color 300ms ease, color 300ms ease;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${styles.colors.primaryColor}4D;
      cursor: not-allowed;
    `}
`

NextButton.displayName = 'NextButton'
