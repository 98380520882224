import styled, { css } from 'styled-components'

export const Grid = styled.div`
  align-items: center;
  display: grid;
  margin-bottom: 8px;
  width: 100%;

  ${(props) =>
    props.taskMode
      ? css`
          grid-template-columns: 95px 1fr 15% minmax(230px, 30%) 15%;
        `
      : css`
          grid-template-columns: 20% 0.5fr 1fr minmax(230px, 30%) 15%;
        `}
`

export const Cell = styled.div`
  ${(props) =>
    props.colStart &&
    css`
      grid-column-start: ${props.colStart};
    `}
  ${(props) =>
    props.colEnd &&
    css`
      grid-column-end: ${props.colEnd};
    `}
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
`

export const Row = styled.div`
  display: contents;

  & ${Cell}:first-child {
    padding-left: 16px;
  }

  & ${Cell}:last-child {
    padding-right: 24px;
  }
`

export const ItemDuration = styled(Cell)`
  font-size: 13px;
  font-weight: 400;
`
