import moment from 'moment'
import { types } from 'store/session/types'

const initialState = {
  accountDeleted: false,
  id: null,
  user: {
    displayName: '',
    settingsPreferences: {
      notification: null,
      calendar: {},
    },
  },
  focusSession: {
    taskId: '',
    startTime: null,
    task: null,
  },
  muteModeOn: false,
  loaded: !localStorage.getItem('id'),
  hideFocusedTask: false,
  completedSession: null,
  lastActionTime: null,
}

const sessionReducer = (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case types.SET_SESSION:
      if (payload.user.id) {
        localStorage.setItem('id', payload.user.id)
      }
      return {
        id: payload.user.id,
        user: payload.user,
        focusSession: payload.focusSession,
        muteModeOn: payload.muteModeOn,
        loaded: true,
        completedSession: null,
      }

    case types.SET_USER:
      return {
        ...state,
        user: payload,
      }
    case types.SET_FOCUSSESSION:
      return {
        ...state,
        focusSession: payload,
      }

    case types.SET_MUTEMODEON:
      return {
        ...state,
        muteModeOn: payload,
      }
    case types.REMOVE_SESSION:
      localStorage.removeItem('id')
      return {
        ...initialState,
        accountDeleted: !!payload,
        loaded: true,
      }

    case types.SET_HIDEFOCUSEDTASK:
      return {
        ...state,
        hideFocusedTask: payload,
      }

    case types.SET_COMPLETED_SESSION:
      return {
        ...state,
        completedSession: payload,
      }

    case types.REMOVE_COMPLETED_SESSION:
      return {
        ...state,
        completedSession: null,
      }

    case types.SET_LAST_ACTION_TIME:
      return {
        ...state,
        lastActionTime: moment().format(),
      }

    default:
      break
  }
  return state
}

export default sessionReducer
