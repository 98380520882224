export const roundMinutesToNearestStartTime = (minutes) => {
  if (minutes < 15) {
    return 0
  } else if (minutes < 30) {
    return 15
  } else if (minutes < 45) {
    return 30
  } else {
    return 45
  }
}
