/* eslint-disable no-throw-literal */
import { models, utils } from 'gipsy-misc'

import RealTime from 'features/realTime'
import { showSnackbarAction } from 'store/snackbar/actions'
import { handleAPIError } from 'store/app/actions'

/* ************************************************************* */
/* ******************** Actions - Front Only ******************* */
/* ************************************************************* */

/* ************************************************************* */
/* ****************** Actions - Async Request ****************** */
/* ************************************************************* */

export const patchTaskRequest = (id, params, body) => async (dispatch, getState, { api }) => {
  if (!id) {
    return dispatch(handleAPIError('Tried to update a task with id undefined', { id, params, body }))
  }

  try {
    const response = await api.task.patch(id, params, body)
    RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

    return response
  } catch (err) {
    const error = dispatch(handleAPIError(err, { id, params }))
    throw { error }
  }
}

export const toggleCompleteTaskRequest = (id, value, context, extraParams) => async (dispatch, getState, { api }) => {
  if (!id) {
    dispatch(handleAPIError('Tried to toggle a task with id undefined', { id, value }))
    return
  }
  let fn = 'uncomplete'
  if (value) {
    fn = 'complete'
  }

  const data = await dispatch(completeFlow(fn, id, value, context, extraParams))
  RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

  return data
}

const completeFlow = (fn, id, value, context, extraParams) => async (dispatch, getState, { api }) => {
  try {
    const data = await api.task[fn](id, context, extraParams)

    if (fn !== 'complete') {
      return data
    }
    const sessionUser = getState().session.user

    const message = utils.task.getRandomCompletionMessage(sessionUser)
    dispatch(
      showSnackbarAction(
        {
          message: message,
          status: 'default',
          showLogo: true,
          showClose: true,
        },
        5 * 1000
      )
    )

    return data
  } catch (err) {
    dispatch(handleAPIError(err, { id, value }))
  }
}
