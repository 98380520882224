import React from 'react'
import { IconButton } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

export default function StartFocusSessionButton(props) {
  return (
    <IconButton
      onClick={props.onClick}
      iconName={'SurroundedPlay'}
      iconSize={20}
      flexGrow
      label={translations.focussession.startTimer}
      labelHoverColor={styles.colors.primaryColor}
      borderHoverColor={styles.colors.lightVioletBorderColor}
    />
  )
}
