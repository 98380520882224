import React from 'react'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { Icon, RadioInput, SimpleButton } from 'gipsy-ui'

import { MultiSyncIcon, TitleContainer } from 'features/calendar/components/CalendarList/components/commonUIComponents'
import { spinAnimation } from 'features/keyframes'
import { getSrc } from 'utils/image'

export const SYNC_OPTIONS = {
  MANY_TO_MANY: 'ManyToMany',
  MANY_TO_ONE: 'ManyToOne',
  NO_SYNC: 'None',
}

function SyncOption({ checked, description, disabled, image, imageAlt, onCheck, title }) {
  const handleCheck = () => {
    if (disabled) return

    onCheck?.()
  }

  return (
    <OptionContainer checked={checked} disabled={disabled} onClick={handleCheck}>
      <OptionWrapper>
        <StyledRadioInput
          checked={checked}
          checkedColor={styles.colors.primaryColor}
          disableStopPropagation
          size={16}
        />
        <OptionTexts>
          <OptionTitle>{title}</OptionTitle>
          <OptionDescription>{description}</OptionDescription>
        </OptionTexts>
      </OptionWrapper>
      <OptionGraphic alt={imageAlt} src={image} />
    </OptionContainer>
  )
}

const OptionContainer = styled.div`
  align-items: flex-start;
  border: 1px solid ${styles.colors.darkGrey}66;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex: 1;
  margin-right: 16px;
  padding: 22px 16px 30px;
  transition: background-color 300ms ease, border-color 300ms ease;

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${styles.colors.middleGrey}66;
      border-color: ${styles.colors.primaryColor};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`

OptionContainer.displayName = 'OptionContainer'

const OptionWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`

OptionWrapper.displayName = 'OptionWrapper'

const OptionTexts = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`

OptionTexts.displayName = 'OptionTexts'

const OptionTitle = styled.span`
  display: block;
  font-weight: 500;
`

OptionTitle.displayName = 'OptionTitle'

const OptionDescription = styled.span`
  display: block;
  font-size: 15px;
  line-height: 22px;
  padding-top: 12px;
`

OptionDescription.displayName = 'OptionDescription'

const StyledRadioInput = styled(RadioInput)`
  margin: 3px 10px 0 0;
`

StyledRadioInput.displayName = 'StyledRadioInput'

const OptionGraphic = styled.img`
  flex-shrink: 0;
  height: 112px;
  margin-left: 8px;
  transition: opacity 300ms ease;
  width: 191px;
`

OptionGraphic.displayName = 'OptionGraphic'

export default function SyncOptions({ isSyncing, mainAccount, optionSaved, onSave, setSyncOption, syncOption }) {
  const saveDisabled = syncOption === optionSaved && optionSaved === SYNC_OPTIONS.NO_SYNC
  const syncActive = optionSaved !== SYNC_OPTIONS.NO_SYNC

  const rollbackCb = () => {
    setSyncOption(optionSaved)
  }

  const handleStartStopSync = () => {
    if (isSyncing || saveDisabled) {
      return
    }

    let optionSelected = syncOption

    if (syncActive) {
      optionSelected = SYNC_OPTIONS.NO_SYNC
      setSyncOption(SYNC_OPTIONS.NO_SYNC)
    }

    onSave(optionSelected, rollbackCb)
  }

  const handleChangeSync = () => {
    if (isSyncing || syncOption === optionSaved) {
      return
    }

    onSave(syncOption, rollbackCb)
  }

  return (
    <Container>
      <TitleContainer>
        <Title>{translations.multiCalendarSettings.syncOptions.title}</Title>
        <SyncButtonWrapper>
          {!isSyncing && syncActive && (
            <SyncActiveIndicator>
              <span>{translations.multiCalendarSettings.syncIsActive}</span>
              <GreenDot />
            </SyncActiveIndicator>
          )}
          <StartSyncButton
            active={syncActive}
            backgroundColor={styles.colors.primaryColor}
            borderColor={styles.colors.primaryColor}
            borderRadius={8}
            disabled={saveDisabled || isSyncing}
            height={40}
            leftIcon={
              isSyncing ? (
                <RefreshIcon fill={styles.colors.primaryColor} icon='Refresh' size={16} stroke='none' />
              ) : (
                <MultiSyncIcon fill='white' icon='CalendarRepeat' size={16} stroke='none' />
              )
            }
            onClick={handleStartStopSync}
            syncing={isSyncing}
            text={
              isSyncing
                ? translations.multiCalendarSettings.syncing
                : syncActive
                ? translations.multiCalendarSettings.stopSync
                : translations.multiCalendarSettings.startSync
            }
            textColor='white'
            width={127}
          />
        </SyncButtonWrapper>
      </TitleContainer>
      <OptionsContainer>
        <SyncOption
          checked={syncOption === SYNC_OPTIONS.MANY_TO_MANY}
          description={translations.multiCalendarSettings.syncOptions.manyToMany.description}
          image={getSrc('/images/multisync/many-to-many.svg')}
          imageAlt='Many to many'
          onCheck={() => setSyncOption(SYNC_OPTIONS.MANY_TO_MANY)}
          title={translations.multiCalendarSettings.syncOptions.manyToMany.title}
        />
        <SyncOption
          checked={syncOption === SYNC_OPTIONS.MANY_TO_ONE}
          description={translations.multiCalendarSettings.syncOptions.manyToOne.description.replace(
            '[account]',
            mainAccount?.email
          )}
          image={getSrc('/images/multisync/many-to-one.svg')}
          imageAlt='Many to one'
          onCheck={() => setSyncOption(SYNC_OPTIONS.MANY_TO_ONE)}
          title={translations.multiCalendarSettings.syncOptions.manyToOne.title}
        />
      </OptionsContainer>
      <ChangeSyncButton
        backgroundColor={styles.colors.primaryColor}
        borderColor={styles.colors.primaryColor}
        borderRadius={8}
        disabled={isSyncing || !syncActive || syncOption === optionSaved}
        height={32}
        onClick={handleChangeSync}
        text={translations.multiCalendarSettings.changeSync}
        width={130}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 0;
`

Container.displayName = 'Container'

const Title = styled.span`
  color: ${styles.colors.textDarkColor};
  display: block;
  font-weight: 500;
  margin-top: auto;
`

Title.displayName = 'Title'

const OptionsContainer = styled.div`
  display: flex;
  margin: 16px 0 20px;

  ${OptionContainer} {
    &:last-of-type {
      margin-right: 0;
    }
  }
`

OptionsContainer.displayName = 'OptionsContainer'

const SyncButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`

SyncButtonWrapper.displayName = 'SyncButtonWrapper'

const SyncActiveIndicator = styled.div`
  align-items: center;
  color: ${styles.colors.textDarkColor};
  display: flex;
  font-size: 11px;
  font-weight: 500;
`

SyncActiveIndicator.displayName = 'SyncActiveIndicator'

const GreenDot = styled.span`
  background-color: ${styles.colors.greenPrimaryColor};
  border-radius: 50%;
  height: 8px;
  margin-left: 8px;
  width: 8px;
`

GreenDot.displayName = 'GreenDot'

const RefreshIcon = styled(Icon)`
  animation: ${spinAnimation} 1s ease-in-out infinite;
  margin-right: 12px;
`

RefreshIcon.displayName = 'RefreshIcon'

const StartSyncButton = styled(SimpleButton)`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 22px;
  transition: background-color 300ms ease, border-color 300ms ease, color 300ms ease;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${styles.colors.veryLightGrey};
      border-color: ${styles.colors.veryLightGrey};
      color: ${styles.colors.darkGrey};

      ${MultiSyncIcon} {
        line {
          stroke: ${styles.colors.darkGrey};
        }

        path {
          fill: ${styles.colors.darkGrey};
        }
      }
    `}

  ${({ active }) =>
    active &&
    css`
      background-color: white;
      border-color: ${styles.colors.redColor};
      color: ${styles.colors.redColor};

      ${MultiSyncIcon} {
        line {
          stroke: ${styles.colors.redColor};
        }

        path {
          fill: ${styles.colors.redColor};
        }
      }
    `}

    ${({ syncing }) =>
    syncing &&
    css`
      background-color: white;
      border-color: ${styles.colors.primaryColor};
      color: ${styles.colors.primaryColor};
    `}
`

StartSyncButton.displayName = 'StartSyncButton'

const ChangeSyncButton = styled(SimpleButton)`
  display: block;
  font-size: 14px;
  margin: 2px auto 0;
  transition: opacity 300ms ease;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

ChangeSyncButton.displayName = 'ChangeSyncButton'
