import { createAction } from 'redux-actions'
import { produce } from 'immer'
import _get from 'lodash/get'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import throttle from 'lodash/throttle'

import { updateItem } from 'store/items/actions'
import { getFindItemByIdFn } from 'store/items/selectors'
import { sendMessage } from 'utils/chromeext'
import { types } from 'store/session/types'
import { openPopup } from 'store/popup/actions'
import { handleAPIError } from 'store/app/actions'
import { showSnackbar } from 'store/snackbar/actions'
import { fetchAndConsumeDataAfterUserAction } from 'store/utils/actions'
import { addCalendarEvent, removeCalendarEvent, updateCalendarEvent } from 'store/calendar/actions'
import inject from 'utils/inject'
import RealTime from 'features/realTime'
import { reduxOptimisticUpdater } from 'store'
import { FS_TOPIC } from 'logic/app/optimisticUpdaterTopics'

import { chromeext, chromeextErrCode, models, styles, translations, utils } from 'gipsy-misc'
import { user, focussession, task as taskApi } from 'gipsy-api'

export const remove = createAction(types.REMOVE_SESSION)
/* set the whole user object (set with GET session or GET User action) */
export const setUser = createAction(types.SET_USER)
/* set the whole focus session object (set with GET session or GET User action) */
export const _setFocusSession = createAction(types.SET_FOCUSSESSION)
const _setMuteModeOn = createAction(types.SET_MUTEMODEON)

export const setHideFocusedTask = createAction(types.SET_HIDEFOCUSEDTASK)
export const setCompletedSession = createAction(types.SET_COMPLETED_SESSION)
export const removeCompletedSession = createAction(types.REMOVE_COMPLETED_SESSION)

const _setLastUserActionTime = createAction(types.SET_LAST_ACTION_TIME)

export const _set = createAction(types.SET_SESSION)
export const set = (session) => async (dispatch, getState, { api }) => {
  inject('gipsy-ui', 'user', session.user)
  inject('gipsy-misc', 'user', session.user)
  dispatch(_set(session))
}

export const get = () => async (dispatch, getState, { api }) => {
  try {
    const tz = moment.tz.guess()
    const session = await api.session.get(tz)
    dispatch(set(session))
    if (session.code && _get(translations, `message.success.${session.code}`)) {
      dispatch(
        openPopup(
          {
            message: _get(translations, `message.success.${session.code}`),
            button: translations.general.ok.toUpperCase(),
            action: 'onClose',
          },
          15 * 1000
        )
      )
    }

    if (session.user.id) {
      try {
        user.fullSyncIntegrations()
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    }
  } catch (err) {
    dispatch(remove())
  }
}

export const getUser = () => async (dispatch, getState, { api }) => {
  try {
    const user = await api.user.get()
    dispatch(setUser(user))
  } catch (err) {
    dispatch(handleAPIError(err))
  }
}

export const signout = (accountDeleted) => async (dispatch, getState, { api }) => {
  api.session.signout()
  dispatch(remove(accountDeleted))
}

const getExtensionId = (state) => {
  let extensionId
  const user = state.session.user

  // only a few users can use a local extensionId
  if (utils.user.isUserInBetaList(user)) {
    extensionId = user.extensionId
  }

  return extensionId
}

export const closeTabs = () => async (dispatch, getState, { api }) => {
  const extensionId = getExtensionId(getState())

  let obj = await sendCloseTabsMessage(extensionId, getState().session.focusSession)

  if (obj && obj.err) {
    dispatch(handleAPIError(obj.err))
  }
}

const sendCloseTabsMessage = throttle(async (extensionId, focusSession) => {
  let obj = await sendMessage(chromeext.closeTabMessage, extensionId, focusSession)
  return obj
}, 1000)

export const createTaskAndFs = (task, context, onCreateTaskCallback) => (dispatch, getState) => {
  const extensionId = getExtensionId(getState())

  let focusSession = models.focussession.initialFocusSession({
    id: `${moment().unix()}`,
    startTime: moment().format(),
    task: task,
    taskId: task.id,
    urlsToOpen: [],
    isTmp: true,
  })

  const callback = async (action, getState) => {
    const response = await taskApi.create(task, context)

    if (!response.task) {
      return
    }

    RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

    const createdTask = response.task
    onCreateTaskCallback(createdTask)

    if (createdTask.id !== task.id) {
      focusSession.task = createdTask
      focusSession.taskId = createdTask.id
    }

    focusSession = utils.ids.addIdToItem(focusSession, models.item.type.FOCUSSESSION, getState().session.id)
    focusSession.isTmp = false
    dispatch(_setFocusSession(focusSession))
    let createdFocusSession = await focussession.create(focusSession, context)
    if (createdFocusSession.id !== focusSession.id) {
      createdFocusSession = { ...focusSession, ...createdFocusSession }
      dispatch(_setFocusSession(createdFocusSession))
    }
    await sendMessage(chromeext.setFocusSession, extensionId, createdFocusSession)
  }

  reduxOptimisticUpdater.dispatch(_setFocusSession({ ...focusSession }), {
    topic: FS_TOPIC,
    callback,
    fallback: (err) => {
      dispatch(handleAPIError(err))
      const code = get(err, 'data.code')
      if (code && code === 'focussession.invalidTaskId') {
        dispatch(_setFocusSession({ taskId: '', startTime: null, task: null }))
      }
    },
  })
}

export const updateFocusSessionTitle = (title) => (dispatch, getState) => {
  let extensionId
  const user = getState().session.user
  if (utils.user.isUserInBetaList(user)) {
    extensionId = user.extensionId
  }

  let updatedFocusSession = { ...getState().session.focusSession }
  updatedFocusSession.task = {
    ...updatedFocusSession.task,
    title,
  }

  updatedFocusSession.title = title
  dispatch(_setFocusSession(updatedFocusSession))
  sendMessage(chromeext.setFocusSession, extensionId, updatedFocusSession)
}

export const updateFocusSessionTask = (task) => (dispatch, getState) => {
  const extensionId = getExtensionId(getState())
  let updatedFocusSession = { ...getState().session.focusSession }
  updatedFocusSession.task = task
  dispatch(_setFocusSession(updatedFocusSession))
  sendMessage(chromeext.setFocusSession, extensionId, updatedFocusSession)
}

export const startFocusSession = (data, context) => async (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  dispatch(removeCompletedSession())
  const { taskId, task, urlsToOpen } = data

  let { startTime } = data
  if (typeof startTime !== 'string') {
    startTime = moment(startTime).format()
  }
  const extensionId = getExtensionId(getState())

  if (urlsToOpen && urlsToOpen.length > 0) {
    dispatch(openUrls(urlsToOpen, taskId))
  }

  let creatingFocusSession = models.focussession.initialFocusSession({
    taskId,
    startTime,
    task,
  })

  creatingFocusSession = utils.ids.addIdToItem(
    creatingFocusSession,
    models.item.type.FOCUSSESSION,
    getState().session.id
  )

  reduxOptimisticUpdater.dispatch(_setFocusSession(creatingFocusSession), {
    topic: FS_TOPIC,
    callback: async () => {
      let createdFocusSession = await focussession.create(creatingFocusSession, context)

      if (createdFocusSession.id !== creatingFocusSession.id) {
        createdFocusSession = { ...creatingFocusSession, ...createdFocusSession }
        dispatch(_setFocusSession(createdFocusSession))
      }
      await sendMessage(chromeext.setFocusSession, extensionId, createdFocusSession)
      RealTime.publishMessage('', [models.realtime.topics.focusSession])
    },
    fallback: (err) => {
      dispatch(handleAPIError(err))
      const code = get(err, 'data.code')
      if (code && code === 'focussession.invalidTaskId') {
        dispatch(_setFocusSession({ taskId: '', startTime: null, task: null }))
      }
    },
  })
}

export const openUrls = (urls, taskId) => async (dispatch, getState, { api }) => {
  const extensionId = getExtensionId(getState())
  const { err } = await sendMessage(chromeext.openUrls, extensionId, {
    urls,
    inactive: true,
    whitelistInfo: { taskId },
  })
  if (err) {
    if (
      err.data &&
      (err.data.code === chromeextErrCode.messageNotReceived || err.data.code === chromeextErrCode.notOnChrome)
    ) {
      if (urls && urls.length > 0) {
        urls.map((url) => window.open(utils.url.setHttp(url), '_blank'))
      }
    } else {
      dispatch(handleAPIError(err))
    }
  }
}

const getWhitelistedUrlsInfo = async (extensionId, focusSession) => {
  const taskLinks = focusSession?.task?.urlsInfo || []

  try {
    const obj = await sendMessage(chromeext.getWhitelistedWebsites, extensionId, focusSession)
    const whitelistedUrlInfos = Object.values(obj?.response?.whitelistedUrls || {})
    await sendMessage(chromeext.clearWhitelistedWebsites, extensionId, focusSession.taskId)
    return whitelistedUrlInfos.filter((urlInfo) => !taskLinks.find((linkInfo) => linkInfo.url === urlInfo.url))
  } catch (err) {
    console.error('Failed to fetch whitelisted URLs from task')
  }
}

export const endSession = (focusSession) => async (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  const extensionId = getExtensionId(getState())
  const whitelistedUrlsInfo = await getWhitelistedUrlsInfo(extensionId, focusSession)

  if (whitelistedUrlsInfo?.length > 0) {
    dispatch(
      setCompletedSession({
        ...focusSession,
        endTime: moment().toISOString(),
        task: {
          ...focusSession.task,
          urlsInfo: focusSession.task.urlsInfo || [],
        },
        whitelistedUrlsInfo,
      })
    )
  } else if (focusSession.discarded) {
    dispatch(
      showSnackbar({
        message: translations.focussession.lessThan2mn,
        showLogo: true,
        showClose: true,
      })
    )
  }

  dispatch(resetFocusSession())
}

export const pauseFocusSession = (focusSession, { discardFocusSession } = {}) => async (dispatch, getState) => {
  dispatch(setLastUserActionTime())

  if (!discardFocusSession) {
    dispatch(
      addCalendarEvent({
        item: { ...focusSession, title: focusSession.task.title },
        color: styles.colors.focusSessionFill,
      })
    )

    const items = getState().items
    const findItemById = getFindItemByIdFn(items)
    const task = findItemById(focusSession.taskId)

    if (task) {
      const updatedTask = produce(task, (draft) => {
        draft.focusSessions = draft.focusSessions || []
        draft.focusSessions.push(focusSession)
      })
      dispatch(updateItem(updatedTask))
    }
  }

  reduxOptimisticUpdater.dispatch(endSession({ ...focusSession, discarded: discardFocusSession }), {
    callback: async () => {
      await focussession.stop()
      RealTime.publishMessage('', [models.realtime.topics.focusSession])
    },
    topic: FS_TOPIC,
    fallback: (err) => {
      dispatch(handleAPIError(err))
      removeCalendarEvent({
        itemId: focusSession.id,
      })
    },
  })
}

export const handleCompletedSession = ({ discardSession = false, session, updatedSession }, callback) => async (
  dispatch
) => {
  dispatch(removeCompletedSession())

  const updatedTask = { ...updatedSession.task }
  updatedTask.urlsInfo = updatedTask.urlsInfo.concat(updatedSession.whitelistedUrlsInfo)

  const sessionData = { ...session }
  const newSessionData = { ...updatedSession }
  delete sessionData.task
  delete newSessionData.task
  delete sessionData.whitelistedUrlsInfo
  delete newSessionData.whitelistedUrlsInfo

  callback({ completedSession: newSessionData, completedSessionTask: updatedTask })

  if (discardSession) {
    focussession.del(newSessionData.id)
  } else if (!isEqual(sessionData, newSessionData)) {
    dispatch(
      updateCalendarEvent({
        idToUpdate: newSessionData.id,
        item: { ...newSessionData, title: updatedSession.task?.title },
      })
    )
    await focussession.update(newSessionData)
  }

  if (!isEqual(session.task, updatedTask)) {
    await taskApi.putFullTask(updatedTask)
    RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
  }
}

export const resetFocusSession = () => async (dispatch, getState) => {
  dispatch(_setFocusSession({ taskId: '', startTime: null, task: null }))
  const extensionId = getExtensionId(getState())
  await sendMessage(chromeext.setFocusSession, extensionId, null)
}

export const getFocusSession = () => async (dispatch, getState, { api }) => {
  reduxOptimisticUpdater.dispatch(() => noop, {
    callback: () => {
      dispatch(
        fetchAndConsumeDataAfterUserAction(api.focussession.get, (fsData) => {
          dispatch(_setFocusSession(fsData.focusSession))
          dispatch(_setMuteModeOn(fsData.muteModeOn))
        })
      )
    },
    topic: FS_TOPIC,
  })
}

export const hideFocusedTask = () => (dispatch) => {
  dispatch(setHideFocusedTask(true))
}

export const showFocusedTask = () => (dispatch) => {
  dispatch(setHideFocusedTask(false))
}

export const setLastUserActionTime = () => (dispatch) => {
  dispatch(_setLastUserActionTime())
}

export const setMuteModeOn = (payload) => async (dispatch, getState) => {
  dispatch(_setMuteModeOn(payload))
  const extensionId = getExtensionId(getState())
  sendMessage(chromeext.setMuteModeOn, extensionId, payload)
}
