import axios from 'utils/axios'

import { utils } from 'gipsy-misc'

export const get = () => {
  const endpoint = `/email-accounts`
  return axios.get(endpoint)
}

export const add = (provider, code, nonce, redirectUrl = null) => {
  utils.provider.validateProvider(provider)
  const body = code
  let endpoint = `/email-accounts/add/${provider}?nonce=${nonce}`
  if (redirectUrl) {
    endpoint = utils.request.appendToUrl(endpoint, { redirectUrl })
  }
  return axios.post(endpoint, body)
}

export const unsync = (email) => {
  const endpoint = `/email-accounts/remove/${email}`
  return axios.post(endpoint)
}
