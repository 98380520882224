import React, { useState, useLayoutEffect, useCallback } from 'react'
import moment from 'moment'
import variables from 'assets/styles/variables'
import useClickOutside from 'features/app/hooks/useClickOutside'
import CalendarItemPopupContainer from 'features/calendar/components/CalendarPanel/components/CalendarItemPopupContainer'
import { computePopupTopAndLeftPosition } from 'features/calendar/utils'
import { EditCalendarFocusSessionPopupContent } from './EditCalendarFocusSessionPopupContent'
import { EditCalendarTaskPopupContent } from './EditCalendarTaskPopupContent'
import { EditCalendarSprintPopupContent } from './EditCalendarSprintPopupContent'

import { models } from 'gipsy-misc'

const EditCalendarItemPopup = React.forwardRef(function EditCalendarItemPopup(props, ref) {
  const {
    getCalendarRef,
    item,
    onClickOutside,
    onClickEdit,
    onClickDelete,
    onClickDeleteTask,
    onClickRemoveTask,
    onClickComplete,
    onUpdateClickedItem,
    onClickStart,
    onClose,
    onUpdateItem,
    pageSource,
    positionVerticallyOnly,
    registerShortcuts,
    unregisterShortcuts,
    firstDayOfWeek,
  } = props
  const [positionProps, setPositionProps] = useState({ left: null, shouldFlipTail: false, top: 0 })

  useClickOutside(ref, onClickOutside)

  const _onClickEdit = useCallback(() => {
    return onClickEdit?.(item, positionProps)
  }, [onClickEdit, item, positionProps])

  const _onClickDelete = useCallback(() => {
    return onClickDelete?.(item)
  }, [onClickDelete, item])

  const _onClickStart = useCallback(
    (componentSource) => {
      const urlsToOpen = (item.urlsInfo || []).map((urlInfoItem) => urlInfoItem.url)
      onClickStart(
        {
          taskId: item.id,
          task: item,
          startTime: moment(),
          urlsToOpen,
        },
        { componentSource, pageSource }
      )
      onClose()
    },
    [item, onClickStart, onClose, pageSource]
  )

  const onClickStartFromTask = useCallback(
    (taskData, componentSource) => {
      onClickStart(taskData, { componentSource, pageSource })
    },
    [onClickStart, pageSource]
  )

  useLayoutEffect(() => {
    if (!item) return

    const calendarRef = getCalendarRef()
    const clickedNode = document.querySelector(`[data-item-id="${item.id}"]`)

    if (!ref.current || !calendarRef || !clickedNode) return

    const { height: popupHeight } = ref.current.getBoundingClientRect()
    const { left, shouldFlipTail, top } = computePopupTopAndLeftPosition(
      calendarRef.getBoundingClientRect(),
      clickedNode.getBoundingClientRect(),
      popupHeight,
      variables.editCalendarItemPopupWidth,
      positionVerticallyOnly
    )

    setPositionProps((prev) => ({
      left: !isNaN(left) ? left : prev.left,
      shouldFlipTail,
      top: !isNaN(top) ? top : prev.top,
    }))
  }, [item, positionVerticallyOnly, getCalendarRef, ref])

  const renderPopupContent = () => {
    if (!item) return null

    if (item.type === models.item.type.TASK) {
      return (
        <EditCalendarTaskPopupContent
          firstDayOfWeek={firstDayOfWeek}
          item={item}
          onClickComplete={onClickComplete}
          onClickDelete={_onClickDelete}
          onClickEdit={_onClickEdit}
          onClickStart={_onClickStart}
          onUpdateItem={onUpdateItem}
        />
      )
    }

    if (item.type === models.item.type.SPRINT) {
      return (
        <EditCalendarSprintPopupContent
          firstDayOfWeek={firstDayOfWeek}
          onClickComplete={onClickComplete}
          onClickDelete={_onClickDelete}
          onClickDeleteTask={onClickDeleteTask}
          onClickEdit={_onClickEdit}
          onClickRemoveTask={onClickRemoveTask}
          onClickStart={onClickStartFromTask}
          onUpdateClickedItem={onUpdateClickedItem}
          onUpdateItem={onUpdateItem}
          sprint={item}
        />
      )
    }

    if (item.type === models.item.type.FOCUSSESSION) {
      return (
        <EditCalendarFocusSessionPopupContent
          focusSession={item}
          onClickDelete={_onClickDelete}
          onUpdateItem={onUpdateItem}
          registerShortcuts={registerShortcuts}
          unregisterShortcuts={unregisterShortcuts}
          firstDayOfWeek={firstDayOfWeek}
        />
      )
    }
  }

  return (
    <CalendarItemPopupContainer
      className='fs-mask'
      ref={ref}
      padding={item?.type === models.item.type.FOCUSSESSION ? 0 : 16}
      minWidth={variables.editCalendarItemPopupWidth}
      maxWidth={variables.editCalendarItemPopupWidth}
      shouldFlipTail={positionProps.shouldFlipTail}
      top={positionProps.top}
      left={positionProps.left}>
      {renderPopupContent()}
    </CalendarItemPopupContainer>
  )
})

export default EditCalendarItemPopup
