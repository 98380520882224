import styled, { css, keyframes } from 'styled-components'
import { styles } from 'gipsy-misc'
import { Icon, AnimatedTitle } from 'gipsy-ui'

export const fadeIn = keyframes`   
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const ActionIcon = styled(Icon)`
  animation: ${fadeIn} 0.25s ease-in-out forwards;
`

export const ActionIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  line-height: 32px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  :not(:last-of-type) {
    margin-right: 16px;
  }
  ${(props) =>
    props.hoverColor &&
    css`
      ${ActionIcon}:hover {
        path {
          fill: ${props.hoverColor} !important;
        }
      }
    `}
`

export const Content = styled.div`
  width: 100%;
  display: flex;
`

export const LeftPart = styled.div`
  margin-right: 14px;
`

export const RightPart = styled.div`
  flex-grow: 1;
`
export const CheckboxContainer = styled.div`
  padding-top: 10px;
`

export const ItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const titleStyles = css`
  font-size: 18px;
  line-height: 32px;
  min-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-weight: 500;
  color: ${styles.colors.textMediumDarkColor};
  max-width: 275px;
`
export const ItemTitle = styled.div`
  ${titleStyles};
`

export const StyledAnimatedTitle = styled(AnimatedTitle)`
  ${titleStyles};
  width: auto;
`

export const ItemTimeInfo = styled.div`
  align-items: center;
  display: flex;
  padding-top: 4px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
`

export const Actions = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-self: flex-end;
`

export const ActionIcons = styled.div`
  display: flex;
`
