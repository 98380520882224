export const capitalize = (str) => {
  str = str || ''
  str = str.charAt(0).toUpperCase() + str.slice(1)
  return str
}

export const hasOnlyDigits = (str) => {
  if (typeof str !== 'string') {
    return false
  }

  for (let i = 0, l = str.length; i < l; ++i) {
    if (isNaN(str[i])) {
      return false
    }
  }

  return true
}

/* check if the param string with 2 digits, i.e. : -3 will return false */
export const isStringWith2digitsOrMore = (value) => {
  return typeof value === 'string' && value.length >= 2 && hasOnlyDigits(value)
}

export const random = (length = 8) => {
  let text = ''
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    text += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return text
}
