import React, { useEffect, useLayoutEffect, useState, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import { BlurLayer, EditingLineComponents } from 'gipsy-ui'
import moment from 'moment'
import styled, { css } from 'styled-components'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

import { pageBlurLayerPortalId } from 'features/layout'
import HomebaseLine from 'features/list/components/line'
import variables from 'assets/styles/variables'
import ClickOutside from 'features/app/ClickOutside'
import TaskPinControls from './TaskPinControls'
import { styles, utils, variables as miscVariables } from 'gipsy-misc'

const { LineContainer: EditingLineContainer } = EditingLineComponents

function TaskLineModal(props) {
  const [creatingItem, setCreatingItem] = useState(null)
  const [editingItem, setEditingItem] = useState(null)
  const [isActive, setActive] = useState(false)
  const [showLine, setShowLine] = useState(false)
  const [taskLineModalPosition, setTaskLineModalPosition] = useState(null)
  const taskLineModalRef = useRef(null)

  const {
    getCalendarRef,
    getSelectedSlotBounds,
    onSave,
    onCancel,
    onChangeDuration,
    onChangeStartTime,
    onTogglePin,
    onCreate,
    creatingCalendarTask,
    selectedSlot,
    onClickOutside,
    portalNode,
    prevEditingPopupPositionProps,
    startSprintCreation,
  } = props

  const computeModalPosition = useCallback(() => {
    const calendarRef = getCalendarRef()
    const selectedSlotBounds = getSelectedSlotBounds()

    if (taskLineModalRef.current && selectedSlotBounds && calendarRef) {
      const taskLineModalNode = ReactDOM.findDOMNode(taskLineModalRef.current)
      const taskLineModalBounds = taskLineModalNode.getBoundingClientRect()
      const calendarBounds = calendarRef.getBoundingClientRect()
      const leftOffset = 48
      const bottomOffset = 48
      const minTop = calendarBounds.top
      const maxTop = calendarBounds.height - bottomOffset
      const minLeft = calendarBounds.left
      const maxLeft = window.innerWidth - taskLineModalBounds.width
      const leftPosition = selectedSlotBounds.left - taskLineModalBounds.width - leftOffset
      const rightPosition = selectedSlotBounds.right + leftOffset / 4

      let left

      if (prevEditingPopupPositionProps && prevEditingPopupPositionProps?.shouldFlipTail) {
        left = Math.max(rightPosition, minLeft)

        if (left >= maxLeft) {
          left = leftPosition
        }
      } else {
        left = Math.max(leftPosition, minLeft)

        if (left <= minLeft) {
          left = rightPosition
        }
      }

      let top = prevEditingPopupPositionProps?.top
        ? prevEditingPopupPositionProps?.top + 8
        : selectedSlotBounds.top - calendarBounds.top - taskLineModalBounds.height / 2

      if (top + taskLineModalBounds.height >= maxTop) {
        top = maxTop - taskLineModalBounds.height
      }

      top = Math.max(minTop, top)

      setTaskLineModalPosition({
        top,
        left,
      })
    }
  }, [getCalendarRef, getSelectedSlotBounds, prevEditingPopupPositionProps])

  useLayoutEffect(() => {
    if (!props.showModal) return
    if (selectedSlot) {
      const { allDay, start, end } = selectedSlot
      const startTime = moment(start)
      const endTime = moment(end)
      const diff = endTime.diff(startTime, 'minutes')
      const estimatedTime = allDay ? 0 : utils.datetime.convertMinuteToNanoseconds(diff)
      const item = cloneDeep(selectedSlot.item) || {}
      item.estimatedTime = estimatedTime

      if (!item.when) {
        item.when = { date: startTime.format('YYYY-MM-DD') }
      }

      requestAnimationFrame(computeModalPosition)

      if (props.editingCalendarTask || props.localEditingCalendarTask) {
        if (!allDay && !item.sprintInfo) {
          item.pin = { time: startTime.format() }
        }

        setEditingItem(item)
      } else {
        const creatingItem = {
          ...item,
          estimatedTime,
          sprintInfo: null,
        }

        if (!allDay) {
          creatingItem.pin = { time: startTime.format() }
        }

        setCreatingItem(creatingItem)
      }
    } else {
      if (props.localEditingCalendarTask) {
        const taskWithoutPin = utils.task.computeTaskOnChange(
          props.localEditingCalendarTask,
          { paramName: 'pin.time', value: null },
          {
            estimatedTime: null,
          }
        )
        setEditingItem(taskWithoutPin)
      }
    }
  }, [selectedSlot, props.showModal, computeModalPosition, props.editingCalendarTask, props.localEditingCalendarTask])

  useEffect(() => {
    if (!props.showModal) {
      setActive(false)
      setCreatingItem(null)
      setEditingItem(null)
    } else {
      setActive(true)
    }
  }, [props.showModal])

  useEffect(() => setShowLine(isActive), [isActive])

  useLayoutEffect(() => {
    if (isActive && props.fixedPosition) {
      requestAnimationFrame(computeModalPosition)
    } else {
      setTaskLineModalPosition(null)
    }
  }, [isActive, computeModalPosition, props.fixedPosition])

  const getLineProps = useCallback(() => {
    let lineProps = {}
    if (editingItem) {
      lineProps = {
        onSave: onSave,
        startEdition: true,
        onCancelEdit: onCancel,
        onTogglePin: onTogglePin,
        item: editingItem,
        when: get(editingItem, 'when'),
        pin: get(editingItem, 'pin'),
        estimatedTime: get(editingItem, 'estimatedTime'),
        hideBlockToCalendarOption: editingItem.pin,
      }
    } else if (creatingItem) {
      lineProps = {
        creating: true,
        onCreate: (task) => onCreate(task, { componentSource: 'calendarPanel' }),
        onTogglePin,
        startCreation: true,
        item: creatingItem,
        when: get(creatingItem, 'when'),
        urlInfo: get(creatingItem, 'urlInfo'),
        project: get(creatingItem, 'project'),
        pin: get(creatingItem, 'pin'),
        estimatedTime: get(creatingItem, 'estimatedTime'),
        title: get(creatingItem, 'title'),
        useTitleProps: true,
      }
      if (creatingCalendarTask) {
        lineProps = {
          ...lineProps,
          onCreate: (task) => onCreate(task, { componentSource: 'inlineAddTask' }),
          onTogglePin: onTogglePin,
        }
      }
    }

    lineProps.verticalView = true
    return lineProps
  }, [editingItem, creatingItem, creatingCalendarTask, onSave, onCancel, onTogglePin, onCreate])

  const lineProps = getLineProps()

  const unpinHandler = useCallback(() => {
    onTogglePin({ item: lineProps.item, isCreating: lineProps.creating })
  }, [lineProps, onTogglePin])

  const getTaskPinControlsProps = useCallback(() => {
    if (!selectedSlot) return

    const estimatedTimeNS = utils.calendar.getDurationNSFromCalendarSlot(selectedSlot)
    const durationFromSelectedSlot = utils.datetime.getHourAndMinuteFromNanoseconds(estimatedTimeNS)

    return {
      selectedSlot,
      date: moment(selectedSlot.start).format('ddd, MMM D'),
      durationProps: {
        onChange: onChangeDuration,
        ...durationFromSelectedSlot,
      },
      startTimeProps: {
        hour: moment(selectedSlot.start).hours(),
        minute: moment(selectedSlot.start).minutes(),
        onChange: onChangeStartTime,
      },
      unpinHandler,
    }
  }, [onChangeDuration, onChangeStartTime, selectedSlot, unpinHandler])

  let renderNode = portalNode

  if (!renderNode) {
    renderNode = document.querySelector(`#${pageBlurLayerPortalId}`)

    if (!renderNode) return null
  }

  const showPinComponent = false // TODO: Properly remove and replace this component

  return ReactDOM.createPortal(
    <StyledBlurLayer
      name={'add-task-modal'}
      active={isActive}
      onClose={props.onCancel}
      position={taskLineModalPosition}
      fixedPosition={props.fixedPosition}
      coverCalendar={!!selectedSlot}>
      {showLine && (
        <ClickOutside targetRef={taskLineModalRef} callback={onClickOutside}>
          <LineContainer ref={taskLineModalRef} fixedPosition={props.fixedPosition} showPinComponent={showPinComponent}>
            <HomebaseLine
              ignoreOutsideClicks
              hideSprint
              onChange={props.onCurrentTaskChange}
              onCancel={props.onCancel}
              onDelete={props.onDelete}
              canBlockToCalendar
              hideBlockToCalendarOption
              marginBottom={0}
              startSprintCreation={startSprintCreation}
              {...lineProps}
            />
            {showPinComponent && <TaskPinControls {...getTaskPinControlsProps()} />}
          </LineContainer>
        </ClickOutside>
      )}
    </StyledBlurLayer>,
    renderNode
  )
}

export default React.memo(TaskLineModal)

const StyledBlurLayer = styled(BlurLayer)`
  display: flex;

  &.add-task-modal-layer {
    position: absolute;
    z-index: ${miscVariables.zIndex.pageBlurLayer};

    ::before {
      background: ${styles.colors.textMediumDarkColor};
    }
  }

  ${(props) => {
    if (!props.fixedPosition) {
      if (props.coverCalendar) {
        return css`
          padding-right: ${variables.calendarPanelWidth + variables.calendarLateralPadding}px;
        `
      } else {
        return css`
          padding-right: ${variables.calendarPanelWidth}px;
        `
      }
    } else {
      return css`
        &.add-task-modal-layer {
          transition-delay: 0s;
          visibility: visible;
          position: absolute;
          top: ${(props.position && props.position.top) || 0}px;
          left: ${(props.position && props.position.left) || 0}px;
          right: unset;
          bottom: unset;
          width: auto;
          max-width: 750px;
          height: auto;
          ::before {
            background: transparent;
            border-radius: 10px;
          }
        }
      `
    }
  }}

  &.add-task-modal-layer .add-task-modal-content {
    overflow: visible;
    position: relative;
    width: 100%;
    opacity: 0;
    transition: opacity ${(props) => (props.fixedPosition ? 0.2 : styles.transitions.blurLayer)}s ease-in-out;
  }

  &.add-task-modal-layer.active {
    .add-task-modal-content {
      opacity: 1;
    }
    ::before {
      opacity: 0.6;
    }
  }
`
StyledBlurLayer.displayName = 'StyledBlurLayer'

const LineContainer = styled.div`
  display: flex;
  margin-left: auto;
  max-width: 750px;
  position: absolute;
  top: 50%;

  ${EditingLineContainer} {
    filter: ${styles.shadows.dropShadowPopup};
  }

  ${(props) =>
    props.fixedPosition
      ? css`
          position: static;
        `
      : css`
          transform: translateY(-50%);
          /* right: ${(props) => (props.showPinComponent ? 12 : 12 + 42)}px; */
          right: 0;
        `};
`
LineContainer.displayName = 'LineContainer'
