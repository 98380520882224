import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  addFilteredId,
  addTmpItem as addTmpItemToTaskPanel,
  clearTmpItems as clearTmpItemsFromTaskPanel,
  removeFilteredId,
  replaceFilteredId,
  resetFilteredIds,
  setSelectMode as setSelectModeInTaskPanel,
} from 'store/taskPanel/actions'
import {
  addTask as addTaskToSprint,
  removeTask as removeTaskFromSprint,
  setTasks as setTasksFromSprint,
  updateTask as updateTaskFromSprint,
} from 'store/sprintComposer/actions'

export default function wrapper(Component) {
  const mapStateToProps = (state) => ({
    addedTasks: state.sprintComposer.addedTasks,
    session: state.session,
  })

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        addFilteredId,
        addTaskToSprint,
        addTmpItemToTaskPanel,
        clearTmpItemsFromTaskPanel,
        removeFilteredId,
        removeTaskFromSprint,
        replaceFilteredId,
        resetFilteredIds,
        setSelectModeInTaskPanel,
        setTasksFromSprint,
        updateTaskFromSprint,
      },
      dispatch
    ),
  })

  return connect(mapStateToProps, mapDispatchToProps)(Component)
}
