import React from 'react'
import { useDispatch } from 'react-redux'

import { translations } from 'gipsy-misc'

import ConfirmPanel from 'features/popup/components/confirmPanel'
import { Title, Wrapper } from 'pages/settings/components/confirmDeletePrompt'
import { closePopup } from 'store/popup/actions'
import { showChat } from 'store/supportPanel/actions'

export default function DeleteErrorPrompt() {
  const dispatch = useDispatch()

  const handleConfirm = () => {
    dispatch(closePopup())
    dispatch(showChat())
  }

  const handleCancel = () => {
    dispatch(closePopup())
  }

  return (
    <Wrapper>
      <Title>{translations.settings.section.deleteErrorPrompt.title}</Title>
      <ConfirmPanel
        cancelLabel={translations.settings.section.deleteErrorPrompt.dismiss}
        confirmLabel={translations.settings.section.deleteErrorPrompt.continue}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        subtitle={translations.settings.section.deleteErrorPrompt.prompt}
      />
    </Wrapper>
  )
}
