import 'sentry.js'
import React, { Component } from 'react'
import { Provider } from 'react-redux'
import Router from 'router'
import { GipsyThemeProvider } from 'gipsy-ui'
import BackgroundListener from 'features/backgroundListener'
import AppRealtime from 'features/realTime/AppRealtime'
import store from 'store'

import Layout from 'features/layout'

import 'gipsy-ui/lib/bundle.css'
import 'assets/styles/index.scss'
class App extends Component {
  render() {
    return (
      <GipsyThemeProvider>
        <Provider store={store}>
          <AppRealtime>
            <BackgroundListener>
              <div className='homebase'>
                <Layout>
                  <Router {...this.props} />
                </Layout>
              </div>
            </BackgroundListener>
          </AppRealtime>
        </Provider>
      </GipsyThemeProvider>
    )
  }
}

export default App
