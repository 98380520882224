import React, { Component } from 'react'
import { Redirect } from 'react-router'
import qs from 'qs'

import { session } from 'gipsy-api'

class RedirectFromWebsite extends Component {
  constructor(props) {
    super(props)

    const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    if (searchParams.google) {
      session.getAuthURL('google').then((data) => {
        window.sessionStorage.setItem('stateOAuth-google', data.state)
        window.sessionStorage.setItem('nonceOAuth', data.nonce)
        window.location.href = data.authURL
      })
    } else if (searchParams.microsoft) {
      session.getAuthURL('microsoft').then((data) => {
        window.sessionStorage.setItem('stateOAuth-microsoft', data.state)
        window.sessionStorage.setItem('nonceOAuth', data.nonce)
        window.location.href = data.authURL
      })
    } else {
      this.state = {
        redirectToLogin: true,
      }
    }
  }

  render = () => {
    if (this.state && this.state.redirectToLogin) {
      return <Redirect to='/login' />
    }

    return <div />
  }
}

export default RedirectFromWebsite
