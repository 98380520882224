import { ALL_TASKS, ROOT, PROJECTS, TAGS, TODAY, UNCATEGORIZED } from 'features/routes'

export const pageSource = {
  calendarView: 'calendar-view',
  planMyDay: 'days-task',
  todayView: 'today-view',
  allTasksView: 'all-tasks-view',
  project: 'project',
  tag: 'tag',
  uncategorized: 'uncategorized',
}

export const getPageSourceFromRoute = (route) => {
  switch (true) {
    case route.startsWith(ALL_TASKS):
      return pageSource.allTasksView

    case route.startsWith(PROJECTS):
      return pageSource.project

    case route.startsWith(TAGS):
      return pageSource.tag

    case route.startsWith(TODAY):
      return pageSource.todayView

    case route.startsWith(UNCATEGORIZED):
      return pageSource.uncategorized

    case route.startsWith(ROOT):
      return pageSource.calendarView

    default:
      return ''
  }
}
