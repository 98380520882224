import listeners from 'listeners'
import injected from 'injected'
import makeRequest from './request'
import { getStatus } from './runningjob'
import get from 'lodash/get'
import { instance as axios } from './instance'

import { constants } from 'gipsy-misc'

let isRefreshing = false
let refreshSubscribers = []

// we init a long time ago so when the first refresh is just
// after the init of this date, it doesn'ttrigger an error message
let lastRefreshed = new Date(2018, 1, 1)

const processRefreshSubscribers = (method) => {
  refreshSubscribers.forEach((subscriber) => {
    const originalRequest = subscriber.originalRequest
    if (method === 'resolve') {
      subscriber.resolve(axios(originalRequest))
    } else {
      subscriber.reject(subscriber.error)
    }
  })
  refreshSubscribers = []
}

const getRefreshToken = () => {
  const now = new Date()
  isRefreshing = true
  if (now - lastRefreshed < 5000) {
    console.error('last refresh happened less than 5 seconds ago')
  }
  lastRefreshed = now
  const params = {}
  if (injected.getRefreshToken) {
    const refreshToken = injected.getRefreshToken()
    params.refreshToken = refreshToken
  }
  /* catch is empty because in case of error : its handled by the error interceptor (below) */
  makeRequest('get', `/authenticate/refresh-token`, null, { params })
    .then((data) => {
      listeners.onTokenChange(data)
      isRefreshing = false
      lastRefreshed = now

      /* retry all subscribers requests with new cookie */
      processRefreshSubscribers('resolve')
    })
    .catch(() => {
      isRefreshing = false
      processRefreshSubscribers('reject')
    })
}

export const request = {
  success: async (config) => {
    if (!injected.getIdToken) return config
    const idToken = await injected.getIdToken()
    if (!idToken) return config
    config.headers = config.headers || {}
    config.headers.authorization = `Bearer ${idToken}`
    return config
  },
  error: (error) => Promise.reject(error),
}

export const response = {
  success: async (response) => {
    if (!!response?.headers['job-id']) {
      /* catch is empty because in case of error : its handled by the error interceptor (below) */
      const jobId = response?.headers['job-id']
      const maxRetries = 20 // this will go a bit over 3 minutes considering latency (we shouldn't be reaching this limit though)

      const pollStatus = async (retryCount = 0, error = null) => {
        if (retryCount > maxRetries) {
          return { error, status: null }
        }

        try {
          const wait = retryCount === 0 ? 1000 : (retryCount + 3) ** 2 * 80 // when retry count is 1, start waiting at 4 ** 2 * 80 = 1280ms
          await new Promise((resolve) => setTimeout(resolve, wait))
          const { status } = await getStatus(jobId)

          if (status === constants.runningJobStatus.failed || status === constants.runningJobStatus.notFound) {
            return { error: null, status }
          }

          if (status === constants.runningJobStatus.running) {
            return pollStatus(retryCount + 1, null)
          }

          if (status === constants.runningJobStatus.succeeded) {
            return { error: null, status }
          }

          return { status: null }
        } catch (err) {
          return pollStatus(retryCount + 1, err)
        }
      }

      const result = await pollStatus()
      return { data: result }
    }

    return response
  },
  error: (error) => {
    let promise
    const originalRequest = error.config

    if (!originalRequest) {
      return Promise.reject(error)
    }

    /* if the signout request fail : do nothing because dispatch session/remove is already called */
    if (originalRequest.url.indexOf('authenticate/signout') !== -1) {
      return Promise.reject(error)
    }

    /* if refresh token failed : reject all requests, remove session and redirect to login page */
    if (originalRequest.url.indexOf('authenticate/refresh-token') !== -1) {
      listeners.onRefreshTokenFailed()
      return Promise.reject(error)
    }

    /* subscribe this request for later resolve|reject (processRefreshSubscribers) */
    if (
      (get(error, 'response.status') === 401 && get(error, 'response.data.code') === 'ExpiredToken') ||
      get(error, 'response.data.code') === 'TokenNotPresent'
    ) {
      promise = new Promise((resolve, reject) => {
        refreshSubscribers.push({ originalRequest, resolve, reject, error })
      })

      /* only try to refresh token ONCE - if status 401 */
      if (!isRefreshing) {
        getRefreshToken()
      }

      /* return a promise to wait for refresh token and retry */
      return promise
    }

    if (get(error, 'response.status') === 401) {
      listeners.onAuthenticationError(error)
    }

    /* any error case will reject the request */
    return Promise.reject(error)
  },
}
