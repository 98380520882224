import { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class ContextMenuContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    onToggleMenu: PropTypes.func,
  }

  state = {
    isActive: null,
  }

  componentWillUnmount() {
    this.unregisterHandlers()
  }

  registerHandlers = () => {
    document.addEventListener('mousedown', this.handleOutsideClick)
    document.addEventListener('touchstart', this.handleOutsideClick)
    document.addEventListener('scroll', this.closeMenu)
    document.addEventListener('contextmenu', this.closeMenu)
    window.addEventListener('resize', this.closeMenu)
  }

  unregisterHandlers = () => {
    document.removeEventListener('mousedown', this.handleOutsideClick)
    document.removeEventListener('touchstart', this.handleOutsideClick)
    document.removeEventListener('scroll', this.closeMenu)
    document.removeEventListener('contextmenu', this.closeMenu)
    window.removeEventListener('resize', this.closeMenu)
  }

  handleOutsideClick = (e) => {
    if (!this.contextMenu.contains(e.target)) {
      this.closeMenu()
    }
  }

  setRef = (ref) => {
    this.contextMenu = ref
  }

  onContextMenu = (e) => {
    const { onToggleMenu } = this.props
    this.registerHandlers()
    e.preventDefault()
    e.stopPropagation()
    this.setState(
      {
        isActive: {
          clientX: e.nativeEvent.clientX,
          clientY: e.nativeEvent.clientY,
        },
      },
      () => onToggleMenu && onToggleMenu(true)
    )
    return false
  }

  closeMenu = () => {
    const { onToggleMenu } = this.props
    this.setState(
      {
        isActive: null,
      },
      () => onToggleMenu && onToggleMenu(false)
    )
    this.unregisterHandlers()
  }

  render() {
    const { children } = this.props
    return children(this.setRef, this.onContextMenu, this.closeMenu, this.state.isActive)
  }
}
