import React from 'react'
import styled from 'styled-components'

import Icon from 'Icon'

import { styles } from 'gipsy-misc'

const SelectButtonAction = ({ className, disabled, onClick, color, icon, iconSize, name }) => (
  <SelectButtonContainer
    className={className}
    disabled={disabled}
    onClick={disabled ? undefined : onClick}
    color={color}>
    <Icon icon={icon} fill={color} size={iconSize} />
    <SelectButtonName color={color}>{name}</SelectButtonName>
  </SelectButtonContainer>
)

const SelectButtonContainer = styled.div`
  height: 40px;
  display: flex;
  visibility: ${(props) => (props.disabled ? 'hidden' : 'visible')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.color};
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
`

const SelectButtonName = styled.span`
  margin-left: 5px;
  color: ${(props) => props.color};
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
`
export default React.memo(SelectButtonAction)
