import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel } from 'gipsy-misc'
import { CustomPopup, GoogleButton, MicrosoftButton, LibraryButton } from 'gipsy-ui'

import useSignInWindow from 'features/hooks/useSignInWindow'

export default function AddAccount({ className, buttonType, width, buttonHeight, text }) {
  const { signInWithGoogle, signInWithMicrosoft } = useSignInWindow()

  const [loginPopupShown, setLoginPopupShown] = useState(false)

  const addAccountContainerRef = useRef(null)

  const clickAddAccount = () => {
    if (!loginPopupShown) {
      mixpanelApi.track({ event: mixpanel.addAccountClickedEvent })
    }
    setLoginPopupShown(!loginPopupShown)
  }

  const handleClickOutside = (e) => {
    if (addAccountContainerRef?.current && !addAccountContainerRef.current.contains(e.target)) {
      setLoginPopupShown(false)
    }
  }

  const onClickConnectGoogle = async () => {
    setLoginPopupShown(false)
    signInWithGoogle()
  }

  const onClickConnectMicrosoft = async () => {
    setLoginPopupShown(false)
    signInWithMicrosoft()
  }

  return (
    <Container className={className} ref={addAccountContainerRef} width={width}>
      <LibraryButton
        borderRadius={8}
        onClick={clickAddAccount}
        height={buttonHeight}
        text={text}
        type={buttonType}></LibraryButton>
      {loginPopupShown && (
        <LoginPopup onClickOutside={handleClickOutside}>
          <GoogleButton label={'Google'} onClick={onClickConnectGoogle} />
          <MicrosoftButton label={'Microsoft'} onClick={onClickConnectMicrosoft} />
        </LoginPopup>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: ${(props) => `${props.width}px` || '100%'};
`

Container.displayName = 'Container'

export const LoginPopup = styled(CustomPopup)`
  background-color: white;
  left: 50%;
  margin: 0;
  padding: 20px;
  top: calc(100% + 8px);
  transform: translateX(-50%);
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

LoginPopup.displayName = 'LoginPopup'
