import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import { getSrc } from 'utils/image'
import { IntegrationContainer, IntegrationRow } from 'features/integrations/components'

import {
  BlockHeader,
  BlockFooter,
  IntegrationInfoRow,
  IntegrationInfo,
  IntegrationSubtitle,
} from 'pages/integrations/common'
import { IntegrationAvatar } from 'features/integrations/components'

import { Icon, Separator, AnimateHeight } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

const Slack = ({ onClickConnect, slackIntegrations }) => {
  const [isExpanded, setExpanded] = useState(true)

  const onClickExpandButton = useCallback(() => {
    setExpanded(!isExpanded)
  }, [isExpanded])

  const hasSlackIntegrations = slackIntegrations && slackIntegrations.length > 0
  return (
    <IntegrationContainer>
      <BlockHeader
        iconSrc={getSrc('/images/slack_icon.svg')}
        title={translations.integrations.apps.slack.title}
        description={translations.integrations.apps.slack.description}
        isInstalled={hasSlackIntegrations}
        installLabel={translations.integrations.actionButtons.connect}
        onClickInstall={onClickConnect}
      />
      {hasSlackIntegrations && (
        <IntegrationRow>
          <IntegrationInfo>
            <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0}>
              <React.Fragment>
                <IntegrationSubtitle>
                  {`${translations.integrations.apps.slack.connectedWorkspace}${
                    slackIntegrations.length > 1 ? 's' : ''
                  }`}
                </IntegrationSubtitle>
                {slackIntegrations &&
                  slackIntegrations.map((slackIntegration) => (
                    <React.Fragment key={slackIntegration.teamId}>
                      <IntegrationInfoRow>
                        <IntegrationAvatar src={slackIntegration.teamPicture} />
                        <SlackIntegrationInfoValue>{slackIntegration.teamName}</SlackIntegrationInfoValue>
                        <SlackWorkspaceArrow icon={'LineRightArrow'} />
                        <IntegrationAvatar src={slackIntegration.userPicture} />
                        <SlackIntegrationInfoValue>{slackIntegration.userName}</SlackIntegrationInfoValue>
                        {/*<SlackWorkspaceDeleteButton>*/}
                        {/*  {translations.integrations.actionButtons.uninstall}*/}
                        {/*</SlackWorkspaceDeleteButton>*/}
                      </IntegrationInfoRow>
                      <Separator />
                    </React.Fragment>
                  ))}
              </React.Fragment>
            </AnimateHeight>

            <BlockFooter
              isExpanded={isExpanded}
              onClickExpand={onClickExpandButton}
              onClickAction={onClickConnect}
              actionLabel={translations.integrations.actionButtons.addWorkspace}
            />
          </IntegrationInfo>
        </IntegrationRow>
      )}
    </IntegrationContainer>
  )
}
export default React.memo(Slack)

const SlackWorkspaceArrow = styled(Icon)`
  margin: 0 9px;
`

const SlackIntegrationInfoValue = styled.span`
  color: ${styles.colors.primaryColor};
  font-weight: 500;
  font-size: ${styles.fonts.fontSizeSmall};
`

// const SlackWorkspaceDeleteButton = styled.span`
//   cursor: pointer;
//   font-weight: 500;
//   font-size: 14px;
//   color: ${styles.colors.darkGrey};
//   margin-left: auto;
// `
