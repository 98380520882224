import styled from 'styled-components'
import { styles } from 'gipsy-misc'

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${styles.colors.veryLightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LogoContainer = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};
`

export const TitleContainer = styled.div`
  font-size: 28px;
  color: ${styles.colors.textDarkColor};
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
`
