import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import AnimateHeight from 'react-animate-height'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, styles, translations } from 'gipsy-misc'
import { SimpleButton } from 'gipsy-ui'

import { SyncExample } from 'features/calendar/components/CalendarList/components/Multisync/SyncExamples'
import { SYNC_OPTIONS } from 'features/calendar/components/CalendarList/components/Multisync/SyncOptions'
import SyncOption from 'pages/onboarding/components/syncOption'
import { SkipSyncButton } from 'pages/onboarding/steps/AddAccounts'
import { getMainAccount } from 'store/accounts/selectors'
import { getSrc } from 'utils/image'

export default function SelectSyncStep({ onStepDone, stepNumber }) {
  const mainAccount = useSelector((state) => getMainAccount(state))

  const [syncOption, setSyncOption] = useState(SYNC_OPTIONS.NO_SYNC)

  const handleSyncSelected = () => {
    mixpanelApi.track({ event: mixpanel.onboardingStartSyncEvent }, syncOption)
    onStepDone(stepNumber, syncOption)
  }

  const handleSkipSync = () => {
    mixpanelApi.track({ event: mixpanel.onboardingCongratsDontSyncClickedEvent })
    onStepDone(stepNumber, null)
  }

  const disabled = syncOption === SYNC_OPTIONS.NO_SYNC

  return (
    <Container>
      <Title>{translations.onboarding.selectSyncStep.optionsTitle}</Title>
      <Subtitle>{translations.onboarding.selectSyncStep.optionsSubtitle}</Subtitle>
      <SyncOptionsLabel>{translations.multiCalendarSettings.syncOptions.title}</SyncOptionsLabel>
      <Wrapper>
        <OptionsContainer>
          <SyncOption
            checked={syncOption === SYNC_OPTIONS.MANY_TO_MANY}
            description={translations.onboarding.selectSyncStep.manyToMany}
            image={getSrc('/images/multisync/many-to-many.svg')}
            imageAlt='Many to many'
            onCheck={() => setSyncOption(SYNC_OPTIONS.MANY_TO_MANY)}
            title={translations.multiCalendarSettings.syncOptions.manyToMany.title}
          />
          <SyncOption
            checked={syncOption === SYNC_OPTIONS.MANY_TO_ONE}
            description={translations.multiCalendarSettings.syncOptions.manyToOne.description.replace(
              '[account]',
              mainAccount?.email
            )}
            image={getSrc('/images/multisync/many-to-one.svg')}
            imageAlt='Many to one'
            onCheck={() => setSyncOption(SYNC_OPTIONS.MANY_TO_ONE)}
            title={translations.multiCalendarSettings.syncOptions.manyToOne.title}
          />
        </OptionsContainer>
        <AnimateHeight duration={300} height={!disabled ? 'auto' : 0}>
          <SyncExampleContainer>
            <SyncExampleArrow right={syncOption === SYNC_OPTIONS.MANY_TO_ONE} />
            <SyncExampleWrapper>
              <SyncExampleLabel>{translations.multiCalendarSettings.beforeSync}</SyncExampleLabel>
              <SyncExample option={SYNC_OPTIONS.NO_SYNC} />
            </SyncExampleWrapper>
            <Separator />
            <SyncExampleWrapper>
              <SyncExampleLabel>{translations.multiCalendarSettings.afterSync}</SyncExampleLabel>
              <SyncExample option={syncOption} />
            </SyncExampleWrapper>
          </SyncExampleContainer>
        </AnimateHeight>
      </Wrapper>
      <NextButton
        backgroundColor={styles.colors.primaryColor}
        borderColor='transparent'
        borderRadius={8}
        disabled={disabled}
        height={48}
        onClick={handleSyncSelected}
        text={translations.onboarding.selectSyncStep.mainButtonText}
        textColor='white'
        width={153}
      />
      <StyledSkipSyncButton onClick={handleSkipSync}>
        {translations.onboarding.addAccountsStep.skipSync}
      </StyledSkipSyncButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`

Container.displayName = 'Container'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 32px;
  font-weight: 500;
  margin: auto auto 24px;
  text-align: center;
`

Title.displayName = 'Title'

const Subtitle = styled.p`
  color: ${styles.colors.textDarkColor};
  margin: 0 auto 48px;
`

Subtitle.displayName = 'Subtitle'

const SyncOptionsLabel = styled(Subtitle)`
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto 26px;
`

SyncOptionsLabel.displayName = 'SyncOptionsLabel'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin: 0 auto;
`

Wrapper.displayName = 'Wrapper'

const OptionsContainer = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  width: 100%;

  & > div:first-of-type {
    margin-right: 16px;
  }
`

OptionsContainer.displayName = 'OptionsContainer'

const SyncExampleContainer = styled.div`
  align-items: stretch;
  background-color: white;
  border-radius: 10px;
  display: flex;
  padding: 35px 30px;
  position: relative;
`

SyncExampleContainer.displayName = 'SyncExampleContainer'

const SyncExampleArrow = styled.div`
  border-bottom: 25px solid white;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  left: 25%;
  position: absolute;
  top: -15px;
  transform: translateX(calc(-50% - 12px));
  width: 0;

  ${({ right }) =>
    right &&
    css`
      left: 77%;
    `}
`

SyncExampleArrow.displayName = 'SyncExampleArrow'

const SyncExampleWrapper = styled.div`
  width: 100%;
`

SyncExampleWrapper.displayName = 'SyncExampleWrapper'

const SyncExampleLabel = styled(SyncOptionsLabel)`
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
`

SyncExampleLabel.displayName = 'SyncExampleLabel'

const Separator = styled.div`
  background-color: ${styles.colors.middleGrey};
  flex-shrink: 0;
  margin: 35px 45px 0;
  width: 1px;
`

Separator.displayName = 'Separator'

const NextButton = styled(SimpleButton)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-shrink: 0;
  margin: 40px auto 20px;
  transition: background-color 300ms ease, color 300ms ease;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${styles.colors.primaryColor}4D;
      cursor: not-allowed;
      margin-top: 22px;
    `}
`

NextButton.displayName = 'NextButton'

const StyledSkipSyncButton = styled(SkipSyncButton)`
  margin: 0 auto auto;
`

StyledSkipSyncButton.displayName = 'StyledSkipSyncButton'
