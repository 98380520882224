import axios from 'utils/axios'

export const get = () => {
  let endpoint = `/favoriteurlinfo`
  return axios.get(endpoint)
}

export const create = (body) => {
  const endpoint = `/favoriteurlinfo`
  return axios.post(endpoint, body)
}

export const dragAndDrop = (id, toRank) => {
  let endpoint = `/favoriteurlinfo/drag-and-drop`
  const payload = { id, toRank }
  return axios.post(endpoint, payload)
}

export const del = (id) => {
  const endpoint = `/favoriteurlinfo/${id}`
  return axios.delete(endpoint)
}
