import React from 'react'
import styled from 'styled-components'
import { ReusableButton } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

export default function MoreButton(props) {
  return (
    <StyledButton
      width={62}
      height={32}
      fontWeight={500}
      fontSize={parseInt(styles.fonts.fontSizeSmall, 10)}
      backgroundColor={styles.colors.veryLightGrey}
      textColor={styles.colors.textMediumDarkColor}
      borderRadius={8}
      onClick={props.onClick}>
      {props.label || translations.general.more}
    </StyledButton>
  )
}

const StyledButton = styled(ReusableButton)`
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`
