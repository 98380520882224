// helper implemented to handle race conditions between task panel's DnD feature and cancelling of sprint editions but can be used for other similar cases
export class CancellableRequestsQueue {
  constructor() {
    this.cancelled = false
    this.cancelCallback = null
    this.requests = []
    this.running = null
  }

  addRequest(fn) {
    this.requests.push(fn)

    if (this.running) return

    this.run()
  }

  cancel(callback) {
    if (!this.running) {
      callback()
      return
    }

    this.cancelled = true
    this.cancelCallback = callback
  }

  run() {
    if (this.running) return this.running

    const doRequests = async () => {
      while (this.requests.length > 0) {
        if (this.cancelled) break

        try {
          const currentRequest = this.requests.shift()
          await currentRequest()
        } catch (e) {
          console.error(e)
        }
      }

      this.requests = []

      if (this.cancelled) {
        this.cancelCallback?.()
      }

      this.cancelled = false
      this.cancelCallback = null
      this.running = null
    }

    this.running = doRequests()
    return this.running
  }
}
