import { injected as uiInjected } from 'gipsy-ui'
import { injected as apiInjected } from 'gipsy-api'
import { injected as miscInjected } from 'gipsy-misc'

const injectFunction = (libraryName, key, value) => {
  const libraries = {
    'gipsy-ui': uiInjected,
    'gipsy-api': apiInjected,
    'gipsy-misc': miscInjected,
  }
  libraries[libraryName][key] = value
}

export default injectFunction
