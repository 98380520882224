type CounterMap = { [key: number]: number }

const toCounterMap = (a: number[]): CounterMap =>
  a.reduce((map, num) => {
    if (!map[num]) {
      map[num] = 0;
    }

    map[num]++;
    return map;
  }, {} as CounterMap);

export const areElementsUniqueAndMatch = (a: number[], b: number[]): boolean => {
  if (a.length !== b.length) return false

  const counterMapOfA = toCounterMap(a)
  return b.every((num) => counterMapOfA[num] === 1)
}
