export const UNCATEGORIZED = '/uncategorized'
export const PROJECTS = '/project'
export const TAGS = '/tag'
export const INTEGRATIONS = '/integrations'
export const SETTINGS = '/settings'
export const LOGIN = '/login'
export const OAUTHCALLBACK = '/oauthcallback'
export const REVALIDATE_OAUTHCALLBACK = '/revalidateoauthcallback'
export const ONBOARDING = '/onboarding'
export const INTEGRATION_OAUTHCALLBACK = '/integrationoauthcallback'
export const REDIRECT_FROM_WEBSITE = '/redirectfromwebsite'
export const EMAIL_UNSUBSCRIBE = '/email-notifications/unsubscribe'
export const SLACK_LANDING_PAGE = '/slack/landing-page'
export const ASANA_LANDING_PAGE = '/asana/landing-page'
export const REPORTS = '/reports'
export const ALL_TASKS = '/tasks/all'
export const TODAY = '/tasks'
export const ROOT = '/'
