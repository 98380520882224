import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { EditableTitle, FocusSessionPopupInputs } from 'gipsy-ui'

import { ActionsButtons } from './ActionsSection'
import { ActionIconContainer, Content } from './commonUIComponents'

const { EditDateContainer, InputValue, PopupInputs, StyledOkButton } = FocusSessionPopupInputs
const componentName = 'EditCalendarFocusSessionPopupContent'

export function EditCalendarFocusSessionPopupContent({
  focusSession,
  onClickDelete,
  onUpdateItem,
  registerShortcuts,
  unregisterShortcuts,
  firstDayOfWeek,
}) {
  const dispatch = useDispatch()
  const itemRef = useRef(null)
  const [isEditing, setEditing] = useState(false)
  const [title, setTitle] = useState(focusSession.title)

  const updateTitle = (updatedTitle) => {
    setTitle(updatedTitle)
  }

  const updateFocusSession = useCallback(
    async (updateFocusSession) => {
      onUpdateItem({ ...updateFocusSession, title })
      setEditing(false)
    },
    [onUpdateItem, title]
  )

  useEffect(() => setTitle(focusSession?.title), [focusSession?.title])

  const handleEditClicked = () => {
    setEditing(true)
  }

  useEffect(() => {
    dispatch(
      pushShortcutsGroup(
        [
          {
            key: 'e',
            label: translations.general.edit,
            callback: handleEditClicked,
          },
          {
            key: 'Backspace',
            label: translations.general.delete,
            callback: onClickDelete,
          },
        ],
        componentName
      )
    )
    return () => dispatch(popShortcutsGroup(componentName))
  }, [dispatch, setEditing, onClickDelete])

  return (
    <Container ref={itemRef}>
      <StyledContent editing={isEditing}>
        <TitleContainer>
          <Label>{translations.calendar.editPopup.taskName}</Label>
          <EditableTitle
            isEditable={isEditing}
            onTitleChange={updateTitle}
            registerShortcuts={registerShortcuts}
            title={title}
            unregisterShortcuts={unregisterShortcuts}
          />
        </TitleContainer>
        <PopupInputs
          isEditing={isEditing}
          item={focusSession}
          onOkClicked={updateFocusSession}
          onOutsideClicked={updateFocusSession}
          parentRef={itemRef}
          firstDayOfWeek={firstDayOfWeek}>
          {({ Date, Duration, OkButton, Time }) => (
            <>
              <AttributeRow>
                <Label>{translations.calendar.editPopup.sessionStarted}</Label>
                {Date}
                {Time}
              </AttributeRow>
              <AttributeRow>
                <Label>{translations.focussession.popup.lastedLabel}</Label>
                {Duration}
                {isEditing ? (
                  <>{OkButton}</>
                ) : (
                  <ActionsButtons onClickDelete={onClickDelete} onClickEdit={handleEditClicked} />
                )}
              </AttributeRow>
            </>
          )}
        </PopupInputs>
      </StyledContent>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  padding: 12px 16px 8px;
  width: 100%;
`

const StyledContent = styled(Content)`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  overflow: visible;

  ${({ editing }) =>
    editing &&
    css`
      ${AttributeRow} {
        margin: 0 0 8px;
      }

      ${Label} {
        margin-bottom: 6px;
        margin-left: 8px;
      }
    `}
`

const labelStyles = css`
  color: ${styles.colors.darkGrey};
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
`

const Label = styled.span`
  ${labelStyles}
  width: 100%;
`

const AttributeRow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 8px 16px;

  & > ${InputValue} {
    ${labelStyles};
    color: ${styles.colors.textMediumDarkColor};
    font-size: 14px;
  }

  & > ${EditDateContainer} {
    font-size: 14px;
  }

  ${ActionIconContainer} {
    height: auto;
  }

  ${StyledOkButton} {
    background-color: ${styles.colors.primaryColor};
    color: white;
    width: 74px;
  }
`

const TitleContainer = styled(AttributeRow)`
  & > input {
    background-color: ${styles.colors.veryLightGrey};
    margin-bottom: 0;
    padding: 0 6px;
  }
`
