import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { produce } from 'immer'

import { projects as projectsApi } from 'gipsy-api'
import { models, translations, utils } from 'gipsy-misc'

import { storageAvailable } from 'utils/storage'
import { handleAPIError } from 'store/app/actions'
import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import { setHighlightedEventId } from 'store/calendar/actions'
import { OPTIONS as sortOptions, sortOptionsKey } from 'features/filters/SortDropdown'
import usePageActions from 'features/hooks/usePageActions2'
import { updateItems } from 'store/items/actions'
import { getTasks } from 'store/items/selectors'
import { getItemProjectKey, sortTaskList } from 'logic/project'

function ActiveTasksPageContainer({ children, focusedTaskProps, projectId, showTaskCreationAlert }) {
  const {
    cancelCalendarTaskAction,
    cancelTaskAction,
    clearLocalTaskState,
    creatingCalendarTask,
    creatingTask,
    editingCalendarTask,
    editingTask,
    ignoreOutsideClicks,
    isCreatingInlineTask,
    isDragging,
    keepCreatingTasks,
    onCreateCalendarTask,
    onDragEnd,
    onDragStart,
    onDeleteCalendarTaskCallback,
    onSaveCalendarTask,
    onTaskEditStart,
    onTogglePin,
    onTogglePinFromCalendarPanel,
    showCalendar,
    sprintComposerProps,
    startInlineTaskCreation,
  } = useCalendarPanelContext()

  const dispatch = useDispatch()
  const {
    completeTask,
    completeTaskFromFS,
    createInlineTask,
    createSprint,
    findItemById,
    getFocusedTaskId,
    handleCompletedSession,
    isTaskCreationAlertShown,
    onClickDelete,
    onClickDeleteFocusSession,
    onClickFocusSession,
    onTitleChange,
    saveTask,
    uncompleteTask,
    updateFocusSession,
    pushShortcutsGroup,
    popShortcutsGroup,
  } = usePageActions()
  const session = useSelector((state) => state.session)
  const allTasks = useSelector((state) => getTasks(state.items))

  const [project, setProject] = useState({})
  const [sortSelected, setSortSelected] = useState(sortOptions.NONE)

  useEffect(() => {
    if (storageAvailable('localStorage')) {
      const value = window.localStorage.getItem(sortOptionsKey)
      if (value && sortOptions[value]) {
        setSortSelected(sortOptions[value])
      }
    }
  }, [])

  const itemList = useMemo(() => {
    const filteredTasks = []
    const nextTaskInstanceMap = {}

    allTasks.forEach((item) => {
      if (
        item.type === models.item.type.SPRINT ||
        item?.completed === 1 ||
        (projectId ? !item.projectsId?.find?.((id) => id === projectId) : !!item.projectsId?.length)
      )
        return

      if (!utils.task.isRecurrent(item) || utils.task.isPast(item)) {
        filteredTasks.push(
          produce(item, (draft) => {
            if (project) {
              draft.project = project
            }
          })
        )
        return
      }

      const recTaskId = utils.task.getRecTaskId(item)
      const nextInstance = nextTaskInstanceMap[recTaskId]

      if (nextInstance) {
        if (utils.task.isScheduledBefore(item, nextInstance)) {
          nextTaskInstanceMap[recTaskId] = item
        }
      } else {
        nextTaskInstanceMap[recTaskId] = item
      }
    })

    Object.values(nextTaskInstanceMap).forEach((item) => {
      filteredTasks.push(
        produce(item, (draft) => {
          if (project) {
            draft.project = project
          }
        })
      )
    })

    return sortTaskList(sortSelected, filteredTasks, projectId)
  }, [allTasks, project, projectId, sortSelected])

  const onCreateInlineTask = useCallback(
    async (task, { componentSource = 'inlineAddTask', dontShowCreationAlert } = {}) => {
      const response = await createInlineTask({
        context: { componentSource, pageSource: projectId ? 'project' : 'uncategorized' },
        dontShowCreationAlert,
        task,
      })

      return response
    },
    [createInlineTask, projectId]
  )

  useEffect(() => {
    pushShortcutsGroup(
      [
        {
          key: 'q',
          label: translations.calendar.createTask,
          callback: (e) => {
            startInlineTaskCreation()
          },
        },
        {
          key: 'b',
          label: translations.calendar.createSprint,
          callback: (e) => {
            sprintComposerProps.startSprintCreation()
          },
        },
      ],
      'activeTasksPageContainer'
    )

    return () => {
      popShortcutsGroup('activeTasksPageContainer')
    }
  }, [pushShortcutsGroup, popShortcutsGroup, startInlineTaskCreation, sprintComposerProps])

  const onCreatePageTask = (task, _, { eventName }) => {
    clearLocalTaskState({ keepCreatingTasks: eventName !== utils.task.clickOutside })
    onCreateInlineTask(task)
  }

  const onComplete = useCallback(
    async ({ id, value }) => {
      if (value) {
        await completeTask({ id })
      } else {
        await uncompleteTask(id)
      }
    },
    [completeTask, uncompleteTask]
  )

  const onCreateSprint = useCallback(
    async (sprint, callback) => {
      const response = await createSprint(sprint)
      callback?.(response)
      return response
    },
    [createSprint]
  )

  const onDrop = useCallback(
    async (data) => {
      if (!data.source || !data.destination || sortSelected !== sortOptions.NONE) return

      const focusedTaskId = getFocusedTaskId()
      const focusedTaskIndex = itemList.findIndex((item) => item.id === focusedTaskId)
      let sourceIndex = data.source.index
      let destinationIndex = data.destination.index

      if (focusedTaskIndex > -1 && sourceIndex >= focusedTaskIndex) {
        sourceIndex += 1
      }

      if (focusedTaskIndex > -1 && destinationIndex >= focusedTaskIndex) {
        destinationIndex += 1
      }

      const sourceItem = itemList[sourceIndex]
      const rankedItemProjectKey = getItemProjectKey(sourceItem, projectId)
      const updatedSourceItem = produce(sourceItem, (draft) => {
        draft.projectsRank = draft.projectsRank || {}
        draft.projectsRank[rankedItemProjectKey] = destinationIndex + 1
      })

      let updatedItems = itemList.filter((item) => item.id !== sourceItem.id)
      updatedItems.splice(destinationIndex, 0, updatedSourceItem)
      updatedItems = updatedItems.map((sectionItem, index) =>
        produce(sectionItem, (draft) => {
          draft.projectsRank = draft.projectsRank || {}
          draft.projectsRank[rankedItemProjectKey] = index + 1
        })
      )

      dispatch(updateItems(updatedItems))

      if (projectId) {
        try {
          await projectsApi.dragAndDropTasks(projectId, { taskId: data.item.id, toRank: destinationIndex + 1 })
        } catch (err) {
          console.error(err)
        }
      } else {
        try {
          await projectsApi.dragAndDropTasksInUncategorized({
            taskId: data.item.id,
            toRank: destinationIndex + 1,
          })
        } catch (err) {
          console.error(err)
        }
      }
    },
    [dispatch, getFocusedTaskId, itemList, projectId, sortSelected]
  )

  const onMoveToTop = useCallback(
    async (taskId) => {
      const item = findItemById(taskId)

      if (!item) return

      const rankedItemProjectKey = getItemProjectKey(item, projectId)
      const updatedItem = produce(item, (draft) => {
        draft.projectsRank[rankedItemProjectKey] = 1
      })

      let updatedItems = itemList.filter((pageItem) => pageItem.id !== updatedItem.id)
      updatedItems.splice(0, 0, updatedItem)
      updatedItems = updatedItems.map((sectionItem, index) =>
        produce(sectionItem, (draft) => {
          draft.projectsRank[rankedItemProjectKey] = index + 1
        })
      )

      dispatch(updateItems(updatedItems))

      if (projectId) {
        try {
          await projectsApi.dragAndDropTasks(projectId, { taskId: taskId, toRank: 1 })
        } catch (err) {
          console.error(err)
        }
      } else {
        try {
          await projectsApi.dragAndDropTasksInUncategorized({
            taskId: taskId,
            toRank: 1,
          })
        } catch (err) {
          console.error(err)
        }
      }
    },
    [dispatch, findItemById, itemList, projectId]
  )

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projects = await projectsApi.get()
        const projectData = projects.filter((project) => project.id === projectId)
        setProject(projectData.length > 0 ? projectData[0] : {})
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    }

    fetchProject()
  }, [dispatch, projectId])

  const onSelectSortingOption = useCallback((value) => {
    setSortSelected(value)
    if (storageAvailable('localStorage')) {
      window.localStorage.setItem(sortOptionsKey, value)
    }
  }, [])

  const onSavePageTask = useCallback(
    (task) => {
      clearLocalTaskState()
      saveTask(task)
    },
    [clearLocalTaskState, saveTask]
  )

  return children({
    cancelCalendarTaskAction,
    cancelTaskAction,
    creatingCalendarTask,
    creatingTask,
    editingCalendarTask,
    editingTask,
    focusedTaskProps: {
      ...focusedTaskProps,
      handleCompletedSession,
      onClickFocusSession,
      onClickDeleteFocusSession,
      onTitleChange,
      onUpdateFocusSession: updateFocusSession,
      setHighlightedEventId,
      onCompleteFromFS: completeTaskFromFS,
    },
    ignoreOutsideClicks,
    isCreatingInlineTask,
    isDragging,
    isTaskCreationAlertShown,
    keepCreatingTasks,
    onClickDelete,
    onClickDeleteFocusSession,
    onComplete,
    onCompleteFromFS: completeTaskFromFS,
    onCreateCalendarTask,
    onCreatePageTask,
    onCreateSprint,
    onDeleteCalendarTaskCallback,
    onDragEnd,
    onDragStart,
    onDrop,
    onMoveToTop,
    onSave: onSavePageTask,
    onSaveCalendarTask,
    onSelectSortingOption,
    onTaskEditStart,
    onTogglePin,
    onTogglePinFromCalendarPanel,
    onUpdateFocusSession: updateFocusSession,
    project,
    session,
    showCalendar,
    showTaskCreationAlert,
    sortSelected,
    sprintComposerProps: sprintComposerProps,
    tasks: itemList,
  })
}

export default React.memo(withRouter(ActiveTasksPageContainer))
