import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Input from 'LineComponents/Input'
import {
  ModifyComponent,
  LinePopupItemContainer,
  LinePopupItemContent,
  OkButton,
} from 'LineComponents/LinePopupWrapper'
import { styles, translations } from 'gipsy-misc'

export default function LinkItem({ badge, onClickLink, urlInfo, index, onSave, onDelete, readOnly }) {
  const itemRef = useRef(null)
  const inputRef = useRef(null)
  const [isEditing, setEditing] = useState(false)
  const [editingUrl, setEditingUrl] = useState(urlInfo.url)

  const onClickSave = useCallback(
    (e) => {
      e && e.stopPropagation()
      onSave(editingUrl, index)
      setEditing(false)
    },
    [onSave, editingUrl, index]
  )

  useEffect(() => {
    if (isEditing) {
      const handleClickOutside = (e) => {
        const itemNode = itemRef.current
        if (itemNode && !itemNode.contains(e.target)) {
          onClickSave()
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isEditing, editingUrl, onClickSave])

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  const onClickEdit = (e) => {
    e.stopPropagation()
    if (isEditing) {
      onClickSave()
    }
    setEditing((isEditing) => !isEditing)
  }

  const onChangeUrl = (e) => {
    setEditingUrl(e.target.value)
  }

  const onClickDelete = (e) => {
    e.stopPropagation()
    onDelete(index)
  }

  const onClickCopy = (e) => {
    e.stopPropagation()
    const copyToClipboard = (str) => {
      const el = document.createElement('textarea')
      el.value = str
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }
    copyToClipboard(urlInfo.url)
  }

  const _onClickLink = useCallback(() => {
    onClickLink(urlInfo.url)
  }, [onClickLink, urlInfo.url])

  return (
    <LinePopupItemContainer ref={itemRef}>
      <LinePopupItemContent>
        {isEditing ? (
          <>
            <Input ref={inputRef} value={editingUrl} onChange={onChangeUrl} />
            <StyledOkButton onClick={onClickSave}>{translations.general.ok}</StyledOkButton>
          </>
        ) : (
          <>
            {badge ? badge : null}
            <UrlReadOnly onClick={_onClickLink}>{(urlInfo && (urlInfo.subject || urlInfo.url)) || ''}</UrlReadOnly>
            <ModifyComponent
              iconSize={16}
              iconHoverColor={styles.colors.primaryColor}
              onClickDelete={onClickDelete}
              onClickCopy={onClickCopy}
              onClickToggleEdit={onClickEdit}
              readOnly={readOnly}
            />
          </>
        )}
      </LinePopupItemContent>
    </LinePopupItemContainer>
  )
}

const StyledOkButton = styled(OkButton)`
  margin-left: 8px;
`

const UrlReadOnly = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 1;
`
