import React from 'react'
import styled from 'styled-components'
import { SimpleButton, media } from 'gipsy-ui'
import { styles, translations, variables } from 'gipsy-misc'

const Container = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #e8e7ec;
  ${media['phone-wide']`
    display: flex;
  `}
  z-index: ${variables.zIndex.mobileWarn};
`

const Box = styled.div`
  position: relative;
  background: white;
  box-shadow: 0 1px 1px rgba(33, 21, 81, 0.08);
  border-radius: 10px;
  width: 295px;
  height: 288px;
  color: ${styles.textMediumDarkColor};
  font-weight: 500;
  font-size: ${styles.fonts.fontSizeSmall};
  line-height: 16px;
  margin: auto;
  padding-top: 82px;
  padding-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
`

const Title = styled.p`
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 12px;
`

const Logo = styled.img`
  position: absolute;
  top: -10px;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 119px;
  height: 119px;
`

export default function MobileWarn() {
  const goToWebsite = () => (window.location = 'https://kosmotime.com')
  return (
    <Container>
      <Box>
        <Logo src={'/images/gipsybot-sad.svg'} />
        <span>
          <Title>{translations.mobileWarn.title}</Title>
          <span>{translations.mobileWarn.description}</span>
        </span>
        <SimpleButton
          onClick={goToWebsite}
          backgroundColor={styles.colors.primaryColor}
          height={56}
          width={180}
          textColor={'white'}
          text={translations.general.goToWebsite}
        />
      </Box>
    </Container>
  )
}
