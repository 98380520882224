import React, { Component } from 'react'

export default class NotFound extends Component {
  render() {
    return (
      <div id="notfound-page">
        <h1>Page not found</h1>
      </div>
    )
  }
}
