import React from 'react'
import styled from 'styled-components'
import parser from 'react-html-parser'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, styles, translations } from 'gipsy-misc'
import { SimpleButton } from 'gipsy-ui'

import useSignInWindow from 'features/hooks/useSignInWindow'
import { Title } from 'pages/login/components/FeaturesColumn'
import { Container } from 'pages/login/components/FeaturesColumn'
import { SkipSyncButton } from 'pages/onboarding/steps/AddAccounts'
import {
  Prompt,
  PromptColumn,
  PromptLogo,
  PromptTitle,
  StepContainer,
} from 'pages/onboarding/steps/MultiCalendarPrompt'
import { getSrc } from 'utils/image'

export default function AddPermissionsStep({ account, onStepDone, stepNumber }) {
  const { reauthenticate } = useSignInWindow()

  const handleAccept = () => {
    mixpanelApi.track({ event: mixpanel.onboardingCalendarPermissionPromptEvent }, 'Grant Access')
    reauthenticate(account.provider.toLowerCase(), account.email, () => {
      onStepDone(stepNumber)
    })
  }

  const handleDismiss = () => {
    mixpanelApi.track({ event: mixpanel.onboardingCalendarPermissionPromptEvent }, 'Use with limited')
    onStepDone(stepNumber)
  }

  return (
    <StepContainer>
      <PrivacyColumn>
        <StyledTitle>{parser(translations.onboarding.addPermissionsStep.title)}</StyledTitle>
        <ListTitle>{translations.onboarding.addPermissionsStep.whyWeAskForItList.title}</ListTitle>
        <List>
          {translations.onboarding.addPermissionsStep.whyWeAskForItList.items.map((item, index) => (
            <ListItem key={index}>{parser(item)}</ListItem>
          ))}
        </List>
        <ListTitle>{translations.onboarding.addPermissionsStep.isItSafeList.title}</ListTitle>
        <List>
          {translations.onboarding.addPermissionsStep.isItSafeList.items.map((item, index) => (
            <ListItem key={index}>{parser(item)}</ListItem>
          ))}
        </List>
      </PrivacyColumn>
      <PromptColumn>
        <StyledPrompt>
          <PromptLogo alt='Prompt Logo' src={getSrc('/images/gipsybot-zen-filled.svg')} />
          <PromptTitle>{translations.onboarding.addPermissionsStep.prompt.title}</PromptTitle>
          <AcceptButton
            height={56}
            onClick={handleAccept}
            text={translations.onboarding.addPermissionsStep.prompt.accept}
            width={260}
          />
          <DismissButton onClick={handleDismiss}>
            {translations.onboarding.addPermissionsStep.prompt.dismiss}
          </DismissButton>
        </StyledPrompt>
      </PromptColumn>
    </StepContainer>
  )
}

const StyledTitle = styled(Title)`
  margin-bottom: 50px;
`

StyledTitle.displayName = 'StyledTitle'

const ListTitle = styled.p`
  color: ${styles.colors.textMediumDarkColor};
  font-weight: bold;
  margin: 0 0 8px;
`

ListTitle.displayName = 'ListTitle'

const List = styled.ul`
  margin: 0;
`

List.displayName = 'List'

const ListItem = styled.li`
  color: ${styles.colors.textDarkColor};
  font-size: 18px;
  line-height: 33px;

  strong {
    font-weight: 600;
  }
`

ListItem.displayName = 'ListItem'

const PrivacyColumn = styled(Container)`
  & > ${List}:first-of-type {
    margin-bottom: 50px;
  }
`

PrivacyColumn.displayName = 'PrivacyColumn'

const StyledPrompt = styled(Prompt)`
  max-width: 628px;
  min-width: 63%;
  padding: 64px 48px 30px;
`

StyledPrompt.displayName = 'StyledPrompt'

const AcceptButton = styled(SimpleButton)`
  display: block;
  margin: 0 auto 30px;
`

AcceptButton.displayName = 'AcceptButton'

const DismissButton = styled(SkipSyncButton)`
  margin: 0 auto;
  width: fit-content;
`

DismissButton.displayName = 'DismissButton'
