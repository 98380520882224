import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import ActiveSeparator from 'ActiveSeparator'
import AnimateHeight from 'react-animate-height'
import { styles, translations, utils } from 'gipsy-misc'
import { Draggable } from 'react-beautiful-dnd'

export function InsideActiveSprintLine(props) {
  const {
    children,
    componentSource,
    draggablesTopOffset,
    pageSource,
    taskList,
    taskComponent,
    taskProps,
    isDraggable,
  } = props

  const [isExpanded, setExpanded] = useState(false)

  const TaskComponent = taskComponent
  // this component must have as prop `item` to specify the task to be shown

  const { active, completed } = utils.task.splitAndSortCompletedTasks(taskList)

  const { innerLeftPadding, innerRightPadding } = taskProps

  const onClick = () => {
    setExpanded(!isExpanded)
  }

  return (
    <Fragment>
      {active &&
        active.map((task, index) => (
          <RegularTask
            componentSource={componentSource}
            draggablesTopOffset={draggablesTopOffset}
            key={task.id}
            taskComponent={taskComponent}
            isDraggable={isDraggable}
            pageSource={pageSource}
            task={task}
            taskProps={taskProps}
            index={index}
          />
        ))}
      {children}
      {completed && completed.length > 0 && (
        <Fragment>
          <StyledActiveSeparator
            title={translations.general.completed + ` (${completed.length})`}
            titleFontSize={10}
            onClick={onClick}
            titleColor={styles.colors.darkGrey}
            isExpanded={isExpanded}
            marginLeft={innerLeftPadding}
            marginRight={innerRightPadding}
          />

          <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0}>
            {completed.map((task) => (
              <TaskComponent
                item={task}
                key={task.id}
                lineThrough={!!task.completed}
                pageSource={pageSource}
                {...taskProps}
              />
            ))}
          </AnimateHeight>
        </Fragment>
      )}
    </Fragment>
  )
}

export const MemoedInsideActiveSprintLine = React.memo(InsideActiveSprintLine)

const RegularTask = (props) => {
  const {
    componentSource,
    taskComponent: TaskComponent,
    draggablesTopOffset,
    isDraggable,
    pageSource,
    task,
    taskProps,
    index,
  } = props

  if (isDraggable) {
    return (
      <Draggable draggableId={JSON.stringify({ id: task.id, type: task.type })} index={index} key={task.id}>
        {(draggableProvided, snapshot) => {
          let draggableStyle = draggableProvided.draggableProps.style

          if (draggablesTopOffset && snapshot.isDragging) {
            draggableStyle = { ...draggableStyle, top: draggableStyle.top - draggablesTopOffset }
          }

          return (
            <TaskComponent
              componentSource={componentSource}
              innerRef={draggableProvided.innerRef}
              draggableProps={draggableProvided.draggableProps}
              dragHandleProps={draggableProvided.dragHandleProps}
              draggableStyle={draggableStyle}
              isDragging={snapshot.isDragging}
              item={task}
              key={task.id}
              lineThrough={!!task.completed}
              isDraggable
              isCalendarDraggable
              pageSource={pageSource}
              {...taskProps}
            />
          )
        }}
      </Draggable>
    )
  } else {
    return (
      <TaskComponent item={task} key={task.id} lineThrough={!!task.completed} pageSource={pageSource} {...taskProps} />
    )
  }
}

const StyledActiveSeparator = styled(ActiveSeparator)`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: ${(props) => props.marginLeft}px;
  margin-right: ${(props) => props.marginRight}px;
`
