import { storageAvailable } from 'utils/storage'

const shouldHideKey = 'shouldHideShortcutsbar'

export const setShouldHideToLocalStorage = (value) => {
  if (storageAvailable('localStorage')) {
    window.localStorage.setItem(shouldHideKey, value)
  }
}

export const getShouldHideFromLocalStorage = (value) => {
  if (storageAvailable('localStorage')) {
    return window.localStorage.getItem(shouldHideKey) === 'true'
  }
}
