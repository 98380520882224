import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, models, styles, translations, utils } from 'gipsy-misc'
import { Icon, StepHelperComponents } from 'gipsy-ui'

import OnboardingVideoPrompt from 'features/header/components/OnboardingVideoPrompt'
import usePageActions from 'features/hooks/usePageActions2'
import { pageBlurLayerPortalId } from 'features/layout/index'
import { updateCalendarDate, updateScrollToTime } from 'store/calendar/actions'
import { closePopup, openPopup } from 'store/popup/actions'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { showFocusBlockStep, totalSteps } from 'pages/onboarding'

const componentName = 'OnboardingHelperOverlay'
const { Container: HelperContainer, StepHelper, Tail: HelperTail } = StepHelperComponents

export default function OnboardingHelperOverlay({ setShowOnboardingVideo, showOnboardingVideo }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { createSprint } = usePageActions()

  const [eventProps, setEventProps] = useState(null)
  const [focusBlockHelperPosition, setFocusBlockHelperPosition] = useState(null)
  const [indicatorStyles, setIndicatorStyles] = useState(null)
  const [stepData, setStepData] = useState({
    currentStep: null,
    onboardingSprint: null,
    onboardingTask: null,
  })

  const helperRef = useRef()
  const wrapperRef = useRef()

  useEffect(() => {
    // for race condition between calendar rendering
    setTimeout(() => {
      dispatch(pushShortcutsGroup([], componentName))
    })

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  useEffect(() => {
    const { currentStep, onboardingSprint = null, onboardingTask = null } = location.state || {}

    if (currentStep && (onboardingSprint || onboardingTask)) {
      const onboardingItem = onboardingSprint || onboardingTask
      const pin = onboardingItem?.pin?.time

      if (pin) {
        const startOfDay = moment(pin).startOf('day')
        let targetScroll = moment(pin).subtract(6, 'hours')
        targetScroll = targetScroll > startOfDay ? targetScroll : startOfDay
        dispatch(updateCalendarDate(targetScroll.toDate(), true))
        dispatch(updateScrollToTime(targetScroll.toDate()))
      }

      setStepData({
        currentStep,
        onboardingSprint,
        onboardingTask,
      })
    }
  }, [dispatch, location.state])

  const computeOnboardingHelperPosition = useCallback(
    (relativeContainerNodeBounds, selectedNodeBounds, helperWidth) => {
      const { height: containerHeight, left: containerLeft, width: containerWidth } = relativeContainerNodeBounds

      const minLeft = containerLeft + 24
      const maxLeft = containerWidth - helperWidth / 2 - 24
      const minTop = 16
      const maxTop = containerHeight - 48

      let left = Math.min(Math.max(minLeft, selectedNodeBounds.left - helperWidth - 24), maxLeft)
      let top = Math.min(Math.max(minTop, selectedNodeBounds.top), maxTop)
      let shiftTail = false

      if (left < minLeft || left + helperWidth > selectedNodeBounds.left) {
        left = selectedNodeBounds.right + 24
        shiftTail = true
      }

      return { left, shiftTail, top }
    },
    []
  )

  const showOnboardingHelperAndIndicator = useCallback(
    (eventNode, onboardingItem) => {
      const eventBounds = eventNode.getBoundingClientRect()
      const helperBounds = helperRef.current.getBoundingClientRect()
      const wrapperBounds = wrapperRef.current.getBoundingClientRect()
      const { left, shiftTail, top } = computeOnboardingHelperPosition(wrapperBounds, eventBounds, helperBounds.width)

      setFocusBlockHelperPosition({
        left: `${left}px`,
        shiftTail,
        top: `${top}px`,
      })

      const eventNodeStyles = getComputedStyle(eventNode)
      const eventStyles = {
        borderRadius: eventNodeStyles.borderRadius,
        height: `${eventBounds.height}px`,
        left: `${eventBounds.left - wrapperBounds.left}px`,
        top: `${eventBounds.top - wrapperBounds.top}px`,
        width: `${eventBounds.width}px`,
      }

      setIndicatorStyles({
        ...eventStyles,
        boxShadow: `rgb(77, 67, 117, 0) 0px 0px 0px 0px, rgb(77, 67, 117, 0.5) 0px 0px 0px ${window.innerWidth * 3}px`,
      })

      const now = new Date()
      const endTime = utils.sprint.getEndTime(onboardingItem)
      const newEventProps = {
        estimatedTime: utils.datetime.convertNanosecondsToMinute(onboardingItem.estimatedTime),
        isPast: endTime < now,
        style: eventStyles,
        title: onboardingItem.title,
      }

      if (onboardingItem.type === models.item.type.SPRINT) {
        newEventProps.isRecurrent = !!onboardingItem.recurrencyInformation
        newEventProps.isSprint = true
      }

      setEventProps(newEventProps)
    },
    [computeOnboardingHelperPosition]
  )

  useEffect(() => {
    if ((!stepData?.onboardingSprint && !stepData?.onboardingTask) || stepData?.currentStep !== showFocusBlockStep)
      return

    // wait for the calendar to properly render, otherwise indicator can be off
    setTimeout(() => {
      let attempts = 10
      let interval = setInterval(() => {
        const onboardingSprint = stepData.onboardingSprint
        const onboardingTask = stepData.onboardingTask
        const onboardingEventNode = document.querySelector(
          `[data-item-id="${onboardingSprint ? onboardingSprint.id : onboardingTask.id}"]`
        )
        attempts--

        if (attempts <= 0 || onboardingEventNode) {
          clearInterval(interval)

          if (onboardingEventNode) {
            if (onboardingSprint) {
              showOnboardingHelperAndIndicator(onboardingEventNode, onboardingSprint)
              createSprint(onboardingSprint)
            }

            if (onboardingTask) {
              showOnboardingHelperAndIndicator(onboardingEventNode, onboardingTask)
            }

            history.push('/', { currentStep: showFocusBlockStep, fromOnboarding: true }) // remove sprint from route state to remove from calendar
          }
        }
      }, 1000)
    })
  }, [createSprint, history, showOnboardingHelperAndIndicator, stepData])

  const handleFocusBlockContinue = () => {
    mixpanelApi.track(
      { event: mixpanel.onboardingCalendarHelperContinueEvent },
      stepData.onboardingSprint ? 'Focus Block' : 'Task'
    )
    history.push('/')

    const onConfirm = () => {
      dispatch(closePopup())
      setShowOnboardingVideo(true)
      mixpanelApi.track({ event: mixpanel.onboardingVideoPromptActionEvent }, 'Yes please')
    }

    const onCancel = () => {
      dispatch(closePopup())
      mixpanelApi.track({ event: mixpanel.onboardingVideoPromptActionEvent }, 'No thanks')
    }

    dispatch(
      openPopup({
        component: <OnboardingVideoPrompt onAccept={onConfirm} onDismiss={onCancel} />,
        contentMaxWidth: 525,
        noPadding: true,
        title: '',
        unclosable: true,
      })
    )
  }

  if (!stepData.currentStep) return null

  const portalNode = document.querySelector(`#${pageBlurLayerPortalId}`)

  if (!portalNode) return null

  return createPortal(
    <Container>
      <Wrapper ref={wrapperRef}>
        <FocusBlockHelperContainer shiftTail={focusBlockHelperPosition?.shiftTail}>
          <StepHelper
            animate={focusBlockHelperPosition && { opacity: 1, x: 0 }}
            continueButtonText={translations.general.continue}
            currentStep={stepData.currentStep}
            exit={{ opacity: 0, transition: { duration: 0.3 }, x: -15 }}
            helperText={
              stepData.onboardingSprint
                ? translations.onboarding.showFocusBlockStep.sprintHelperText
                : translations.onboarding.showFocusBlockStep.taskHelperText
            }
            horizontal
            initial={{ opacity: 0, x: -15 }}
            onContinue={handleFocusBlockContinue}
            ref={helperRef}
            showContinueButton
            style={focusBlockHelperPosition}
          />
        </FocusBlockHelperContainer>
        {eventProps && (
          <>
            <Indicator style={indicatorStyles} />
            <EventContainer
              isSprint={eventProps.isSprint}
              shrink={eventProps.estimatedTime <= 29}
              style={eventProps.style}>
              {eventProps.isPast && <EmojiText>☠️</EmojiText>}
              {eventProps.isSprint ? (
                <>
                  <EventIcon fill={eventProps.isPast ? '#ffffff66' : '#fff'} icon={'Sprint'} size={10} />
                  {eventProps.isRecurrent && (
                    <EventIcon fill={eventProps.isPast ? '#ffffff66' : '#fff'} icon={'Repeat'} size={10} />
                  )}
                </>
              ) : (
                <EventIcon fill={styles.colors.pinkColor} icon={'Pin'} size={10} />
              )}
              <EventTitle isPast={eventProps.isPast} isSprint={eventProps.isSprint}>
                {eventProps.title}
              </EventTitle>
            </EventContainer>
          </>
        )}
        {stepData.currentStep === totalSteps && <Overlay />}
      </Wrapper>
    </Container>,
    portalNode
  )
}

const Container = styled.div`
  height: 100vh;
  left: 0;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: max-height 500ms ease, top 500ms ease;
  width: 100vw;
  z-index: 30;
`

Container.displayName = 'Container'

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`

Wrapper.displayName = 'Wrapper'

const FocusBlockHelperContainer = styled.div`
  ${HelperContainer} {
    opacity: 0;
    position: fixed;
    z-index: 3;
  }

  ${HelperTail} {
    left: calc(100% - 10px);
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }

  ${({ shiftTail }) =>
    shiftTail &&
    css`
      ${HelperTail} {
        left: calc(0% - 14px);
        transform: translateY(-50%) rotate(-90deg);
      }
    `}
`

FocusBlockHelperContainer.displayName = 'FocusBlockHelperContainer'

const Indicator = styled.div`
  background: transparent;
  position: absolute;
  transition: box-shadow 300ms ease;
  z-index: 1;
`

Indicator.displayName = 'Indicator'

const EventContainer = styled.div`
  align-items: flex-start;
  background: ${({ isSprint }) => (isSprint ? styles.colors.orangeColor : styles.colors.veryLightGrey)};
  display: flex;
  flex-direction: row;
  font-size: 11px;
  height: 100%;
  justify-content: flex-start;
  min-width: 0;
  padding-left: 4px;
  padding-top: ${({ shrink }) => (shrink ? 0 : 4)}px;
  position: absolute;
  z-index: 1;
`

EventContainer.displayName = 'EventContainer'

const EventIcon = styled(Icon)`
  height: 12px;
  margin-right: 5px;
  pointer-events: none;
`

EventIcon.displayName = 'EventIcon'

const EventTitle = styled.div`
  color: ${({ isSprint }) => (isSprint ? 'white' : styles.colors.textMediumDarkColor)};
  flex-shrink: 100;
  height: 100%;
  line-height: 12px;
  margin-top: -1px;
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ isPast }) =>
    isPast &&
    css`
      opacity: 0.4;
    `}
`

EventTitle.displayName = 'EventTitle'

const EmojiText = styled.div`
  height: 100%;
  line-height: 12px;
  margin-right: 3px;
  pointer-events: none;
`

EmojiText.displayName = 'EmojiText'

const Overlay = styled.div`
  background: ${styles.colors.textMediumDarkColor};
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
`

Overlay.displayName = 'Overlay'
