import React, { PureComponent } from 'react'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import moment from 'moment'

import { computeActiveProjectPath } from 'logic/project'
import { computeActiveTagPath } from 'logic/tag'
import { openPopup, closePopup } from 'store/popup/actions'
import { isUncategorized, isToday } from 'logic/navigation'
import {
  addProject,
  dragAndDropProject,
  updateProject,
  archiveProject,
  unarchiveProject,
  removeProject,
} from 'store/project/actions'
import { addTag, dragAndDropTag, updateTag, removeTag } from 'store/tag/actions'
import {
  SelectNavbarItem,
  ProjectNavbarItem,
  AddNavbarItem,
  TagNavbarItem,
  DropdownNavbarItem,
  NavbarItemContainer,
} from './NavbarItems'
import { handleAPIError } from 'store/app/actions'
import AddPanel from 'features/popup/components/addPanel'

import { Separator } from 'gipsy-ui'
import { projects as projectsApi } from 'gipsy-api'
import { utils, translations, styles } from 'gipsy-misc'

const mapStateToProps = (state) => ({
  activeProjects: state.projects.items,
  tags: state.tags.items,
  session: state.session,
  logoImage: state.layout.logoImage,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      handleAPIError,
      openPopup,
      closePopup,
      addProject,
      addTag,
      dragAndDropProject,
      dragAndDropTag,
      updateProject,
      updateTag,
      archiveProject,
      unarchiveProject,
      removeProject,
      removeTag,
    },
    dispatch
  ),
})

const projectType = 'projectType'
const tagType = 'tagType'

class SideNavbar extends PureComponent {
  state = {
    areProjectsExpanded: false,
    archivedProjects: [],
    areTagsExpanded: false,
    showArchivedContainer: false,
    higherZIndex: false,
  }

  componentDidMount() {
    this.fetchArchivedProjects()
    // await for render to complete to expand projects, otherwise AnimateHeight miscalculates expandable's height and overflows
    setTimeout(() => {
      this.setState({ areProjectsExpanded: true, areTagsExpanded: true })
    })
  }

  fetchArchivedProjects = async () => {
    try {
      const archivedProjects = (await projectsApi.get(projectsApi.archivedParam)) || []
      this.setState({ archivedProjects })
    } catch (err) {
      console.error(err)
    }
  }

  isFocusView = () => {
    const { location } = this.props
    return location.pathname === '/focusview'
  }

  _isToday = () => {
    const { location } = this.props
    return isToday(location.pathname)
  }

  _isUncategorized = () => {
    const { location } = this.props
    return isUncategorized(location.pathname)
  }

  isProject = (project) => {
    const { location } = this.props
    return location.pathname === computeActiveProjectPath(project)
  }

  isTag = (project) => {
    const { location } = this.props
    return location.pathname === computeActiveTagPath(project)
  }

  onProjectsClick = () => {
    const areProjectsExpanded = this.state.areProjectsExpanded
    this.setState({ areProjectsExpanded: !areProjectsExpanded })
  }

  onTagsClick = () => {
    const areTagsExpanded = this.state.areTagsExpanded
    this.setState({ areTagsExpanded: !areTagsExpanded })
  }

  onAddProject = () => {
    this.props.actions.openPopup({
      title: translations.projects.addNew,
      hideLogo: true,
      component: (
        <AddPanel
          showColorChoice={true}
          placeholder={translations.projects.name}
          confirmLabel={translations.projects.add}
          onCancel={this.props.actions.closePopup}
          onConfirm={this.onConfirmAddProject}
        />
      ),
    })
  }

  onConfirmAddProject = async (project) => {
    project.id = moment().unix().toString()
    try {
      await this.props.actions.addProject(project, true)
      this.props.actions.closePopup()
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onAddTag = () => {
    this.props.actions.openPopup({
      title: translations.tags.addNew,
      hideLogo: true,
      component: (
        <AddPanel
          placeholder={translations.tags.name}
          confirmLabel={translations.tags.add}
          onCancel={this.props.actions.closePopup}
          onConfirm={this.onConfirmAddTag}
        />
      ),
    })
  }

  onConfirmAddTag = async (tag) => {
    tag.id = moment().unix().toString()
    try {
      this.props.actions.addTag(tag)
      this.props.actions.closePopup()
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result
    if (!destination || !source) {
      console.error('no destination or source were found')
      return
    }
    switch (type) {
      case projectType:
        if (destination.index === source.index) {
          return
        }

        try {
          await this.props.actions.dragAndDropProject({
            draggableId,
            source,
            destination,
          })
        } catch (err) {
          this.props.actions.handleAPIError(err)
        }
        return
      case tagType:
        if (destination.index === source.index) {
          return
        }

        try {
          await this.props.actions.dragAndDropTag({
            draggableId,
            source,
            destination,
          })
        } catch (err) {
          this.props.actions.handleAPIError(err)
        }
        return
      default:
        console.error('case ', type, ' unsupported in onDragEnd of navbar')
    }
  }

  onMoveUpProject = async (project, index) => {
    if (index === 0 || index >= this.props.activeProjects.length) {
      return
    }

    try {
      await this.props.actions.dragAndDropProject({
        draggableId: project.id,
        source: { index },
        destination: { index: index - 1 },
      })
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onMoveUpTag = async (tag, index) => {
    if (index === 0 || index >= this.props.tags.length) {
      return
    }

    try {
      await this.props.actions.dragAndDropTag({
        draggableId: tag.id,
        source: { index },
        destination: { index: index - 1 },
      })
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onMoveDownProject = async (project, index) => {
    if (index >= this.props.activeProjects.length - 1) {
      return
    }

    try {
      await this.props.actions.dragAndDropProject({
        draggableId: project.id,
        source: { index },
        destination: { index: index + 1 },
      })
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onMoveDownTag = async (tag, index) => {
    if (index >= this.props.tags.length - 1) {
      return
    }

    try {
      await this.props.actions.dragAndDropTag({
        draggableId: tag.id,
        source: { index },
        destination: { index: index + 1 },
      })
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onEditProject = (project) => {
    this.props.actions.openPopup({
      title: translations.projects.edit,
      hideLogo: true,
      component: (
        <AddPanel
          confirmLabel={translations.general.save}
          placeholder={translations.projects.name}
          id={project.id}
          name={project.name}
          color={project.color}
          showColorChoice={true}
          onCancel={this.props.actions.closePopup}
          onConfirm={this.onConfirmEditProject}
        />
      ),
    })
  }

  onConfirmEditProject = async (editedProject) => {
    try {
      this.props.actions.updateProject(editedProject)
      this.props.actions.closePopup()
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onEditTag = (tag) => {
    this.props.actions.openPopup({
      title: translations.tags.edit,
      hideLogo: true,
      component: (
        <AddPanel
          confirmLabel={translations.general.save}
          placeholder={translations.tags.name}
          id={tag.id}
          name={tag.name}
          onCancel={this.props.actions.closePopup}
          onConfirm={this.onConfirmEditTag}
        />
      ),
    })
  }

  onConfirmEditTag = async (editedTag) => {
    try {
      this.props.actions.updateTag(editedTag)
      this.props.actions.closePopup()
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onArchiveProject = async (project) => {
    let copiedArchivedProjects = JSON.parse(JSON.stringify(this.state.archivedProjects))
    copiedArchivedProjects.unshift(project)
    this.setState({ archivedProjects: copiedArchivedProjects })

    try {
      await this.props.actions.archiveProject(project.id)
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onUnarchiveProject = async (project, index) => {
    let copiedArchivedProjects = JSON.parse(JSON.stringify(this.state.archivedProjects))
    copiedArchivedProjects.splice(index, 1)

    this.setState({ archivedProjects: copiedArchivedProjects })

    try {
      await this.props.actions.unarchiveProject(project)
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onDeleteProject = async (project) => {
    if (this.isProject(project)) {
      const { history } = this.props
      const basePath = `/uncategorized`
      history.push(basePath)
    }

    try {
      await this.props.actions.removeProject(project.id)
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onDeleteArchivedProject = async (project, index) => {
    let copiedArchivedProjects = JSON.parse(JSON.stringify(this.state.archivedProjects))

    copiedArchivedProjects.splice(index, 1)

    this.setState({ archivedProjects: copiedArchivedProjects })

    try {
      await projectsApi.del(project.id)
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onDeleteTag = async (tag) => {
    try {
      await this.props.actions.removeTag(tag.id)
    } catch (err) {
      this.props.actions.handleAPIError(err)
    }
  }

  onClickArchivedContainer = () => {
    const { showArchivedContainer } = this.state
    this.setState({ showArchivedContainer: !showArchivedContainer })
  }

  setHigherZIndex = (higherZIndex) => {
    this.setState({ higherZIndex })
  }

  renderActiveProject = (project, index, draggableProvided) => {
    return (
      <div
        dragable-over-type='project'
        dragable-over-id={project.id}
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        style={{
          cursor: 'inherit',
          ...draggableProvided.draggableProps.style,
        }}>
        <LinkContainer to={computeActiveProjectPath(project)} onClick={this.props.onClickLink}>
          <ProjectNavbarItem
            onToggleMenu={this.setHigherZIndex}
            project={project}
            index={index}
            isSelected={this.isProject(project)}
            onEdit={this.onEditProject}
            onArchive={this.onArchiveProject}
            onDelete={this.onDeleteProject}
            onMoveUp={this.onMoveUpProject}
            onMoveDown={this.onMoveDownProject}
            disableOnMoveUp={index === 0}
            disableOnMoveDown={index === this.props.activeProjects.length - 1}
          />
        </LinkContainer>
      </div>
    )
  }

  renderTag = (tag, index, draggableProvided) => {
    return (
      <div
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        style={{
          cursor: 'inherit',
          ...draggableProvided.draggableProps.style,
        }}>
        <LinkContainer to={computeActiveTagPath(tag)} onClick={this.props.onClickLink}>
          <TagNavbarItem
            onToggleMenu={this.setHigherZIndex}
            key={tag.id}
            tag={tag}
            index={index}
            isSelected={this.isTag(tag)}
            onEdit={this.onEditTag}
            onDelete={this.onDeleteTag}
            onMoveUp={this.onMoveUpTag}
            onMoveDown={this.onMoveDownTag}
            disableOnMoveUp={index === 0}
            disableOnMoveDown={index === this.props.tags.length - 1}
            iconSize={16}
          />
        </LinkContainer>
      </div>
    )
  }

  render() {
    const { onClickLink, activeProjects, tags } = this.props
    const { areProjectsExpanded, archivedProjects, areTagsExpanded, showArchivedContainer } = this.state

    return !this.isFocusView() ? (
      <>
        <ScrollContainer>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <LinkContainer to='/tasks' onClick={onClickLink}>
              <SelectNavbarItem label={translations.general.today} iconName={'Calendar'} isSelected={this._isToday()} />
            </LinkContainer>
            <div dragable-over-type='project' dragable-over-id={''}>
              <LinkContainer to='/uncategorized' onClick={onClickLink}>
                <SelectNavbarItem
                  label={translations.general.uncategorized}
                  iconName={'PaperTray'}
                  isSelected={this._isUncategorized()}
                />
              </LinkContainer>
            </div>

            <ExpandableContainer expanded={areProjectsExpanded || areTagsExpanded}>
              <DropdownNavbarItem
                label={utils.string.capitalize(translations.general.projects)}
                iconName={'Folder'}
                onClick={this.onProjectsClick}
                dataDraggableTitleType='project'
                rightIconName={areProjectsExpanded ? 'SingleChevronDown' : 'SingleChevronLeft'}
              />
              <NavItemsDropdown className='fs-mask' duration={300} grow={2} height={areProjectsExpanded ? 'auto' : 0}>
                <Droppable droppableId={'projectList'} type={projectType}>
                  {(droppableProvided) => (
                    <DroppableListContainer ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                      <DraggableItemList items={activeProjects} renderItem={this.renderActiveProject} />
                      {droppableProvided.placeholder}
                    </DroppableListContainer>
                  )}
                </Droppable>
                <DroppableListContainer>
                  {archivedProjects && archivedProjects.length > 0 && (
                    <ArchivedLabelContainer onClick={this.onClickArchivedContainer}>
                      {showArchivedContainer
                        ? translations.navbar.projects.hideArchived
                        : translations.navbar.projects.showArchived}
                    </ArchivedLabelContainer>
                  )}

                  <AnimateHeight duration={300} height={showArchivedContainer ? 'auto' : 0}>
                    {archivedProjects.map((project, index) => (
                      <LinkContainer key={project.id} to={computeActiveProjectPath(project)} onClick={onClickLink}>
                        <ProjectNavbarItem
                          onToggleMenu={this.setHigherZIndex}
                          project={project}
                          isSelected={this.isProject(project)}
                          index={index}
                          isArchived={true}
                          onUnarchive={this.onUnarchiveProject}
                          onDelete={this.onDeleteArchivedProject}
                        />
                      </LinkContainer>
                    ))}
                  </AnimateHeight>
                </DroppableListContainer>
                <AddNavbarItem onClick={this.onAddProject} />
              </NavItemsDropdown>
              <NavbarSeparator />
              <DropdownNavbarItem
                label={utils.string.capitalize(translations.general.tags)}
                iconName={'FilledTag'}
                iconSize={16}
                onClick={this.onTagsClick}
                rightIconName={areTagsExpanded ? 'SingleChevronDown' : 'SingleChevronLeft'}
              />
              <NavItemsDropdown className='fs-mask' duration={300} grow={1} height={areTagsExpanded ? 'auto' : 0}>
                <Droppable droppableId={'tagList'} type={tagType}>
                  {(droppableProvided) => (
                    <DroppableListContainer ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                      <DraggableItemList className='custom-draggable' items={tags} renderItem={this.renderTag} />
                      {droppableProvided.placeholder}
                    </DroppableListContainer>
                  )}
                </Droppable>
                <AddNavbarItem onClick={this.onAddTag} />
              </NavItemsDropdown>
              <NavbarSeparator />
            </ExpandableContainer>
          </DragDropContext>
        </ScrollContainer>
      </>
    ) : null
  }
}

const DraggableItemList = function DraggableItemList({ className = '', items, renderItem }) {
  return items.map((item, index) => {
    return (
      <Draggable className={className} draggableId={item.id} index={index} key={item.id}>
        {(draggableProvided) => renderItem(item, index, draggableProvided)}
      </Draggable>
    )
  })
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: true,
  })(SideNavbar)
)

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ bottom }) => (bottom ? 'auto' : '0px')};
`

const ExpandableContainer = styled(Block)`
  min-height: ${({ expanded }) => (expanded ? 375 : 120)}px;
  overflow: auto;
  transition: min-height 300ms ease-in-out;
`

const LinkContainer = styled(Link)`
  text-decoration: none;
`

const NavbarSeparator = styled(Separator)`
  margin-top: 10px;
  margin-bottom: 5px;
`

const DroppableListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ArchivedLabelContainer = styled(NavbarItemContainer)`
  color: ${styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeSmall};
  padding-bottom: 0px;
`

const NavItemsDropdown = styled(AnimateHeight)`
  overflow: auto;
`
