import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { signout } from 'store/session/actions'
import { handleAPIError } from 'store/app/actions'
import { closePopup, openPopup } from 'store/popup/actions'

const mapStateToProps = (state) => ({
  user: state.session.user,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ handleAPIError, signout, closePopup, openPopup }, dispatch),
})

export default function wrapper(Component) {
  class SettingsContainer extends PureComponent {
    render() {
      return (
        <Component
          user={this.props.user}
          handleAPIError={this.props.actions.handleAPIError}
          onLogout={this.props.actions.signout}
          openPopup={this.props.actions.openPopup}
          closePopup={this.props.actions.closePopup}
        />
      )
    }
  }

  const containerRedux = connect(mapStateToProps, mapDispatchToProps, null, {
    pure: true,
  })(SettingsContainer)

  return containerRedux
}
