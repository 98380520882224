import React from 'react'
import styled from 'styled-components'

import Icon from 'Icon'

import { styles } from 'gipsy-misc'

const CompleteButton = ({ className, onClick, label }) => (
  <CompleteButtonContainer className={className} onClick={onClick}>
    <Icon className="hover-icon" icon="RegularCheckRound" fill={'white'} size={12} />
    <Icon className="not-hover-icon" icon="RegularRound" fill={'white'} size={12} />
    <CompleteTextContainer>{label}</CompleteTextContainer>
  </CompleteButtonContainer>
)

export default CompleteButton

const CompleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${styles.colors.greenGradientColor};
  cursor: pointer;
  border-radius: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;

  min-width: 100px;

  .hover-icon {
    display: none;
  }

  :hover {
    .hover-icon {
      display: flex;
    }
    .not-hover-icon {
      display: none;
    }
  }
`

const CompleteTextContainer = styled.span`
  color: white;
  margin-left: 5px;
  font-size: ${styles.fonts.fontSizeXSmall};
  padding-bottom: 1px;
`
