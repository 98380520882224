import { areElementsUniqueAndMatch } from 'utils/integers'
import { capitalize } from 'utils/string'
import { shortDayNames } from '../../models/date'
import translations from 'translations'

import { Schedules } from 'models/recurrency'
import { RecurrencyDetails, WeeklyRecurrence } from 'utils/recurrency/types'

export const getDaysChosenLabel = (days: number[]): string => {
  const chosenDays = days.map((dayNumber: number) => {
    const dayName = shortDayNames[dayNumber]
    return capitalize(dayName)
  })

  return translations.sprint.recurrency.weeklyOn.replace('__', chosenDays.join(', '))
}

export const getRecurrencyLabel = (details: RecurrencyDetails): string => {
  switch (details.schedule) {
    case Schedules.Daily: {
      return translations.sprint.recurrency.daily
    }

    case Schedules.Weekly: {
      details = details as WeeklyRecurrence
      const { days } = details.weeklyOptions

      switch (true) {
        case days.length === 1:
          return translations.sprint.recurrency.weeklyOn.replace('__', capitalize(shortDayNames[days[0]]))
        case days.length === 5 && areElementsUniqueAndMatch(days, [1, 2, 3, 4, 5]):
          return translations.sprint.recurrency.everyWeekday
        default:
          return getDaysChosenLabel(days)
      }
    }

    default: {
      return ''
    }
  }
}
