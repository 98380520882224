import { types } from 'store/shortcuts/types'
/*
 *  shortcutsGroup:
 *  {
 *     items: [
 *       {
 *         label: String,
 *         key: String (valid keyboard key)
 *         callback: Function (event triggered by the key)
 *       }
 *     ],
 *     keydownEventCallback: () => {}  (reduced function of all item's callbacks),
 *     componentName: () => {} (identifier when removing),
 *     nodeToInject: null,
 *     zIndex: null
 *  }
 *
 * */
const initialState = {
  shortcutsGroupStack: [],
}

const shortcutsReducer = (state = initialState, action) => {
  let newShortcutsGroupStack
  switch (action.type) {
    case types.PUSH_SHORTCUT_GROUP:
      newShortcutsGroupStack = [...state.shortcutsGroupStack, action.payload.shortcutGroup]
      return {
        ...state,
        shortcutsGroupStack: newShortcutsGroupStack,
      }
    case types.POP_SHORTCUT_GROUP:
      newShortcutsGroupStack = [...state.shortcutsGroupStack]
      newShortcutsGroupStack.splice(action.payload, 1)
      return {
        ...state,
        shortcutsGroupStack: newShortcutsGroupStack,
      }
    case types.CLEAR_SHORTCUT_GROUPS:
      return { ...initialState }
    default:
      break
  }
  return state
}

export default shortcutsReducer
