import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import parser from 'react-html-parser'

import { translations } from 'gipsy-misc'
import { GoogleButton, MicrosoftButton, SimpleButton } from 'gipsy-ui'

import { LoginPopup } from 'features/addAccount'
import breakpoints from 'features/layout/breakpoints'
import { getSrc } from 'utils/image'

interface Props {
  goToGoogleAuth: () => void
  goToMicrosoftAuth: () => void
}

export default function Header({ goToGoogleAuth, goToMicrosoftAuth }: Props) {
  const [loginPopupShown, setLoginPopupShown] = useState(false)

  const ctaContainerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (e) => {
    if (ctaContainerRef.current && !ctaContainerRef.current.contains(e.target)) {
      setLoginPopupShown(false)
    }
  }

  return (
    <Container className='Header'>
      <Content className='Header__content'>
        <Logo className='Header__logo' alt='KosmoTime' src={getSrc('/images/gipsybot-with-title.svg')} />
        <Title className='Header__title'>{parser(translations.timeBlockingExp.header.title)}</Title>
        <CTAContainer className='Header__cta-container' ref={ctaContainerRef}>
          <CTA
            className='Header__cta'
            text={translations.timeBlockingExp.header.cta}
            onClick={() => setLoginPopupShown(!loginPopupShown)}
          />
          {loginPopupShown && (
            <LoginPopup onClickOutside={handleClickOutside}>
              <GoogleButton label={'Google'} onClick={goToGoogleAuth} />
              <MicrosoftButton label={'Microsoft'} onClick={goToMicrosoftAuth} />
            </LoginPopup>
          )}
        </CTAContainer>
      </Content>
    </Container>
  )
}

const Container = styled.header`
  position: relative;
  height: 26%;
  min-height: 208px;
  width: 100%;

  :before {
    background-image: url('${getSrc('/images/time-blocking-exp/header.jpg')}');
    background-position-y: 15%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 100%;
    opacity: 0.2;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    min-height: 257px;

    .Header {
      &__logo {
        height: 61px;
        margin: 31px auto 0;
        width: 153px;
      }

      &__cta-container {
        margin: 33px auto 47px;
      }

      &__cta {
        font-size: 22px;
        height: 45px;
        width: 304px;
      }
    }

    & h1.Header__title {
      font-size: 28px;
    }
  }
`

Container.displayName = 'Container'

const Content = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
`

Content.displayName = 'Content'

const Logo = styled.img`
  height: 53px;
  margin: 21px auto 0;
  width: 132px;
`

Logo.displayName = 'Logo'

const Title = styled.h1`
  &.Header__title {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
    margin: 0 auto;
    text-align: center;
    text-transform: unset;
    width: auto;

    strong {
      font-weight: 500;
    }
  }
`

Title.displayName = 'Title'

const CTAContainer = styled.div`
  margin: 25px auto 34px;
  position: relative;
`

CTAContainer.displayName = 'CTAContainer'

const CTA = styled(SimpleButton)`
  font-size: 18px;
  height: 41px;
  line-height: 32px;
  width: 245px;
`

CTA.displayName = 'CTA'
