import React from 'react'
import googleLogo from './google-logo.svg'
import microsoftLogo from './microsoft-logo.png'
import { styles, translations } from 'gipsy-misc'
import styled, { css } from 'styled-components'
import media from 'styles/media'

export const GoogleButton = ({ onClick, label, height }) => {
  return (
    <AuthButton height={height} className='google-signup' onClick={onClick}>
      <AuthButtonLogo marginRight={8} src={googleLogo} alt='Google logo' />
      {label}
    </AuthButton>
  )
}

export const MicrosoftButton = ({ onClick, label, height }) => {
  return (
    <AuthButton height={height} className='microsoft-signup' onClick={onClick}>
      <AuthButtonLogo marginRight={10} src={microsoftLogo} alt='Microsoft logo' />
      {label}
    </AuthButton>
  )
}

const LoginButtons = ({ onGoogleLogin, onMicrosoftLogin, height, ...p }) => {
  return (
    <LoginButtonsContainer className='login-buttons' {...p}>
      <GoogleButton height={height} onClick={onGoogleLogin} label={translations.auth.loginGoogle} />
      <MicrosoftButton height={height} onClick={onMicrosoftLogin} label={translations.auth.loginMicrosoft} />
    </LoginButtonsContainer>
  )
}

const LoginButtonsContainer = styled.div`
  display: flex;
  > :first-child {
    margin-right: 20px;
  }
  ${media['phone-wide']`
    display: block;
    width: 100%;
    .auth-button {
      width: 100%;
      margin-bottom: 8px;
    }
  `}
`

const AuthButton = styled.button`
  padding: 0 20px 0 15px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: ${(props) => props.height || 40}px;
  justify-content: center;
  cursor: pointer;
  background: white;
  border-radius: 8px;
  border: none;
  border: 1px solid ${styles.colors.middleGrey};
  color: ${styles.colors.textMediumDarkColor};

  :hover {
    background-color: ${styles.colors.middleGrey};
  }

  ${media['phone-wide']`
      width: 100%;
      margin-bottom: 8px;
  `}
`

AuthButton.displayName = 'AuthButton'

const AuthButtonLogo = styled.img`
  height: 20px;
  width: 20px;

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `}
`

AuthButtonLogo.displayName = 'AuthButtonLogo'

export default LoginButtons
