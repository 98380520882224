import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export class ProjectBadge extends Component {
  onClick = () => {
    const { onClick } = this.props
    onClick && onClick()
  }
  render() {
    return (
      <ProjectBadgeContainer
        color={this.props.color}
        className={this.props.className}
        size={this.props.size}
        onClick={this.props.onClick}
      />
    )
  }
}

const ProjectBadgeContainer = styled.div`
  background-color: ${(props) => (props.color ? props.color : 'inherit')};
  height: ${(props) => (props.size ? props.size : 12)}px;
  min-width: ${(props) => (props.size ? props.size : 12)}px;
  border-radius: ${(props) => (props.size ? props.size / 3.0 : 4)}px;
`

ProjectBadge.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
