import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import { styles } from 'gipsy-misc'
import { Icon } from 'gipsy-ui'

function ExpandHandle({ expanded, onClick }) {
  return (
    <HandleContainer
      onClick={onClick}
      animate={expanded ? 'expanded' : 'initial'}
      initial={'initial'}
      variants={handleVariants}>
      <AnimatePresence exitBeforeEnter>
        {expanded ? (
          <motion.div animate={'visible'} exit={'hidden'} initial={'hidden'} key='arrow' variants={iconVariants}>
            <Icon fill={styles.colors.primaryColor} icon='LineLeftArrow' size={10} stroke='none' />
          </motion.div>
        ) : (
          <motion.div animate={'visible'} exit={'hidden'} initial={'hidden'} key='expand-icon' variants={iconVariants}>
            <ExpandIcon fill='none' icon='ExpandTaskPanel' size={30} stroke='white' />
          </motion.div>
        )}
      </AnimatePresence>
    </HandleContainer>
  )
}

const handleVariants = {
  expanded: {
    backgroundColor: styles.colors.veryLightGrey,
  },
  initial: {
    backgroundColor: styles.colors.textMediumDarkColor,
  },
}

export const expandHandleWidth = 32

const HandleContainer = styled(motion.div)`
  align-items: center;
  border-radius: 0 8px 8px 0;
  box-shadow: 2px 0px 2px rgba(33, 21, 81, 0.08);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 100%;
  position: absolute;
  width: ${expandHandleWidth}px;
`

HandleContainer.displayName = 'HandleContainer'

const iconVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

const ExpandIcon = styled(Icon)`
  svg > path:last-child {
    fill: white;
    stroke: none;
  }
`

ExpandIcon.displayName = 'ExpandIcon'

export default ExpandHandle
