import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AnimateHeight from 'react-animate-height'
import styled from 'styled-components'
import moment from 'moment'
import parser from 'react-html-parser'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, models, styles, translations } from 'gipsy-misc'
import { PrimaryButton, RadioInput, recurrenceComponentsUtils, RecurrencyDayPicker } from 'gipsy-ui'

import { openPopup } from 'store/popup/actions'
import { getSrc } from 'utils/image'

const { DailyScheduleOptions } = models.recurrency
const { Day, DayPicker, Days } = RecurrencyDayPicker

const getRecurrenceTrackingLabel = (option) => {
  switch (option) {
    case DailyScheduleOptions.NoRepeat:
      return 'Does not repeat'
    case DailyScheduleOptions.Daily:
      return 'Daily'
    case DailyScheduleOptions.EveryWeekday:
      return 'Every Weekday'
    case DailyScheduleOptions.WeeklyOnCurrentDay:
      return 'Weekly On'
    case DailyScheduleOptions.Custom:
      return 'Custom'
    default:
      return ''
  }
}

export default function RecurrencePopup({ firstDayOfWeek, onContinue, show, startTime }) {
  const dispatch = useDispatch()

  const [daysSelected, setDaysSelected] = useState({})
  const [typeSelected, setTypeSelected] = useState(DailyScheduleOptions.NoRepeat)

  const selectNoRepeat = () => setTypeSelected(DailyScheduleOptions.NoRepeat)
  const selectDaily = () => setTypeSelected(DailyScheduleOptions.Daily)
  const selectWeeklyOnCurrentDay = () => setTypeSelected(DailyScheduleOptions.WeeklyOnCurrentDay)
  const selectEveryWeekday = () => setTypeSelected(DailyScheduleOptions.EveryWeekday)
  const selectCustom = () => setTypeSelected(DailyScheduleOptions.Custom)

  const handleChange = (updatedDaysSelected) => {
    setDaysSelected(updatedDaysSelected)
  }

  const handleContinue = useCallback(() => {
    const days = Object.keys(daysSelected).reduce((days, dayKey) => {
      if (!daysSelected[dayKey]) return days

      days.push(Number.parseInt(dayKey))
      return days
    }, [])

    mixpanelApi.track({ event: mixpanel.onboardingRecurrenceSelectedEvent }, getRecurrenceTrackingLabel(typeSelected))
    onContinue({ days, typeSelected })
  }, [daysSelected, onContinue, typeSelected])

  useEffect(() => {
    if (show) {
      dispatch(
        openPopup({
          component: (
            <Container>
              <Logo alt='gipsybot' src={getSrc('/images/gipsybot-zen-filled.svg')} />
              <RecurrencePanel>
                <Title>{parser(translations.onboarding.calendarStep.recurrencePopup.title)}</Title>
                <OptionContainer onClick={selectNoRepeat}>
                  <StyledRadioInput
                    checked={typeSelected === DailyScheduleOptions.NoRepeat}
                    checkedColor={styles.colors.primaryColor}
                    disableStopPropagation
                    size={16}
                  />
                  <span>{translations.sprint.recurrency.noRepeat}</span>
                </OptionContainer>
                <OptionContainer onClick={selectDaily}>
                  <StyledRadioInput
                    checked={typeSelected === DailyScheduleOptions.Daily}
                    checkedColor={styles.colors.primaryColor}
                    disableStopPropagation
                    size={16}
                  />
                  <span>{translations.sprint.recurrency.daily}</span>
                </OptionContainer>
                <OptionContainer onClick={selectWeeklyOnCurrentDay}>
                  <StyledRadioInput
                    checked={typeSelected === DailyScheduleOptions.WeeklyOnCurrentDay}
                    checkedColor={styles.colors.primaryColor}
                    disableStopPropagation
                    size={16}
                  />
                  <span>
                    {recurrenceComponentsUtils.getRecurrencyLabel({
                      recurrencyType: DailyScheduleOptions.WeeklyOnCurrentDay,
                      sprintStartTime: moment(startTime || new Date()),
                    })}
                  </span>
                </OptionContainer>
                <OptionContainer onClick={selectEveryWeekday}>
                  <StyledRadioInput
                    checked={typeSelected === DailyScheduleOptions.EveryWeekday}
                    checkedColor={styles.colors.primaryColor}
                    disableStopPropagation
                    size={16}
                  />
                  <span>{translations.sprint.recurrency.everyWeekday}</span>
                </OptionContainer>
                <OptionContainer onClick={selectCustom}>
                  <StyledRadioInput
                    checked={typeSelected === DailyScheduleOptions.Custom}
                    checkedColor={styles.colors.primaryColor}
                    disableStopPropagation
                    size={16}
                  />
                  <span>{translations.sprint.recurrency.custom}</span>
                </OptionContainer>
                <AnimateHeight duration={300} height={typeSelected === DailyScheduleOptions.Custom ? 'auto' : 0}>
                  <CustomRecurrence animate={{ height: 'auto' }} exit={{ height: 0 }} initial={{ height: 0 }}>
                    <span>{translations.general.repeatOn}:</span>
                    <DayPicker daysSelected={daysSelected} firstDayOfWeek={firstDayOfWeek} onChange={handleChange} />
                  </CustomRecurrence>
                </AnimateHeight>
                <StyledPrimaryButton
                  height={48}
                  onClick={handleContinue}
                  text={translations.general.continue}
                  width={164}
                />
              </RecurrencePanel>
              <ExampleColumn>
                <ExampleAnimation alt='recurrence animation' src={getSrc('/images/recurring_anim.gif')} />
                <Tip>{parser(translations.onboarding.calendarStep.recurrencePopup.tip)}</Tip>
              </ExampleColumn>
            </Container>
          ),
          contentMaxWidth: 856,
          hideLogo: true,
          hideTitle: true,
          noPadding: true,
          unclosable: true,
        })
      )

      return
    }
  }, [daysSelected, dispatch, firstDayOfWeek, handleContinue, onContinue, show, startTime, typeSelected])

  return null
}

const Container = styled.div`
  align-items: stretch;
  display: flex;
  padding: 16px;
  position: relative;
`

Container.displayName = 'Container'

const Logo = styled.img`
  height: 90px;
  left: 22%;
  position: absolute;
  top: -55px;
  width: 90px;
`

Logo.displayName = 'Logo'

const RecurrencePanel = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  overflow: auto;
  padding: 46px 38px 16px 20px;
`

RecurrencePanel.displayName = 'RecurrencePanel'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0 0 30px;
  text-align: center;

  & strong {
    color: ${styles.colors.orangeColor};
    font-weight: 500;
  }
`

Title.displayName = 'Title'

const OptionContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0 0 16px 32px;
`

OptionContainer.displayName = 'OptionContainer'

const StyledRadioInput = styled(RadioInput)`
  margin-right: 16px;
`

StyledRadioInput.displayName = 'StyledRadioInput'

const CustomRecurrence = styled.div`
  padding: 16px 0;

  ${Days} {
    justify-content: space-evenly;
    margin-top: 8px;

    ${Day}:last-of-type {
      margin-right: 0;
    }
  }

  ${Day} {
    flex: 1;
    height: 32px;
    min-width: 36px;
    padding: 0;
  }
`

CustomRecurrence.displayName = 'CustomRecurrence'

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 16px auto 0;
`

StyledPrimaryButton.displayName = 'StyledPrimaryButton'

const ExampleColumn = styled.div`
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-flow: column;
  max-height: 420px;
`

ExampleColumn.displayName = 'ExampleColumn'

const ExampleAnimation = styled.img`
  flex: 1;
  margin: 2px 2px 0 2px;
`

ExampleAnimation.displayName = 'ExampleAnimation'

const Tip = styled.span`
  color: ${styles.colors.textMediumDarkColor};
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 14px 32px 28px;
  text-align: center;

  & strong {
    color: ${styles.colors.orangeColor};
    font-weight: 500;
  }
`

Tip.displayName = 'Tip'
