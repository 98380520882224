import moment from 'moment'

import { utils } from 'gipsy-misc'

import { OPTIONS } from 'features/filters/SortDropdown'

export const computeActiveProjectPath = (project) => {
  if (!project) {
    return '/'
  }
  return `/project/active/${project.id}`
}

export const getItemProjectKey = (item, projectId) => {
  if (!projectId && item.projectsRank) {
    return Object.keys(item.projectsRank).find((projectId) => projectId.match(/UNCATEGORIZED/i))
  }

  return projectId
}

const isProjectRankBefore = (projectId, a, b) => {
  const projectKey = getItemProjectKey(a, projectId)
  const aHasRank = !!a.projectsRank?.[projectKey]
  const bHasRank = !!b.projectsRank?.[projectKey]

  if (!aHasRank && !bHasRank) {
    return moment(a.creationTime).isBefore(moment(b.creationTime)) ? 1 : -1
  }

  if (!aHasRank || !bHasRank) {
    return aHasRank ? 1 : -1
  }

  if (a.projectsRank[projectKey] === b.projectsRank[projectKey]) return 0
  return a.projectsRank[projectKey] < b.projectsRank[projectKey] ? 1 : -1
}

const sortByProjectRank = (list, projectId) => [...list].sort((a, b) => isProjectRankBefore(projectId, b, a))

export const sortTaskList = (sortOption, list, projectId) => {
  switch (sortOption) {
    case OPTIONS.SCHEDULED_DATE: {
      return utils.task.sortByScheduledDate(list)
    }
    case OPTIONS.NONE: {
      return sortByProjectRank(list, projectId)
    }
    default: {
      return list
    }
  }
}
