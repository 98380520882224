import styled from 'styled-components'
import { styles, variables } from 'gipsy-misc'

const Popup = styled.div`
  border-radius: 10px;
  filter: ${styles.shadows.dropShadowPopup};
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: max-content;
  top: ${(props) => props.top ?? 'unset'};
  left: ${(props) => props.left ?? 'unset'};
  bottom: ${(props) => props.bottom ?? 'unset'};
  right: ${(props) => props.right ?? 'unset'};
  z-index: ${variables.zIndex.linePopup};
  color: ${styles.colors.textMediumDarkColor};
  font-size: 13px;
  margin-bottom: 12px;
  margin-top: 5px;
  ::before {
    position: absolute;
  }
`

export default Popup
