import React from 'react'
import styled from 'styled-components'

import ConnectedTaskFilters from './ConnectedTaskFilters'
import { PaddingContainer, innerLeftPadding, innerRightPadding, paddingContainerCss } from 'features/layout/pages'

import { Separator, ActiveSeparator } from 'gipsy-ui'
import { styles } from 'gipsy-misc'

const marginTopGroupContainer = 28
export const GroupContainer = styled.div`
  margin-top: ${marginTopGroupContainer}px;
  display: flex;
  flex-direction: column;
`

const marginBottomGroupHeader = 16
export const GroupHeader = styled(PaddingContainer)`
  margin-bottom: ${marginBottomGroupHeader}px;
`

export const StyledActiveSeparator = styled(ActiveSeparator)`
  margin-top: 15px;
  margin-bottom: ${marginBottomGroupHeader}px;
  font-weight: 500;
  ${paddingContainerCss};
`

export const SubGroupTitleSeparator = ({ onClick, title, isExpanded }) => {
  return (
    <StyledActiveSeparator
      onClick={onClick}
      title={title}
      titleFontSize={11}
      titleColor={styles.colors.textMediumDarkColor}
      isExpanded={isExpanded}
    />
  )
}

export const DateGroupHeader = styled(PaddingContainer)`
  color: ${styles.colors.primaryColor};
  font-weight: 600;
  font-size: ${styles.fonts.fontSizeSmall};
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 12px 0 0 0;
  cursor: pointer;
`

export const RightTaskFilters = styled(ConnectedTaskFilters)`
  margin-left: auto;
`

export const SelectRowContainer = styled(PaddingContainer)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const SelectModeSeparator = styled(Separator)`
  margin-bottom: 10px;
  margin-left: ${innerLeftPadding}px;
  margin-right: ${innerRightPadding}px;
`
