export class OptimisticUpdate {
  constructor(callback, fallback, topic, actionType) {
    this.callback = callback
    this.fallback = fallback
    this.topic = topic
    this.actionType = actionType
  }
}

export class OptimisticUpdater {
  constructor(dispatch, getState) {
    // redux dispatcher
    this._dispatch = dispatch
    this.getState = getState
    this.scheduler = {}
  }

  getActionKey(action) {
    if (!action) return action
    if (typeof action === 'function') {
      return action.name
    } else {
      return action.type
    }
  }

  dispatch(action, optimisticUpdate) {
    if (typeof action === 'function') {
      action(this._dispatch, this.getState)
    } else {
      const clonedAction = Object.assign({}, action)
      this._dispatch(clonedAction)
    }

    const key = optimisticUpdate.topic ?? this.getActionKey(action)
    optimisticUpdate.action = action

    // Schedule callback
    if (key in this.scheduler) {
      const currentOptimisticUpdate = this.scheduler[key]
      currentOptimisticUpdate.queue.push(optimisticUpdate)
      currentOptimisticUpdate.isCompleted = false
      currentOptimisticUpdate.prevState = this.getState()
    } else {
      this.scheduler[key] = {
        queue: [optimisticUpdate],
        isUpdating: false,
        isCompleted: false,
        prevState: this.getState(),
      }
    }
    this.runOptimisticUpdate(key)
  }

  async runOptimisticUpdate(key) {
    const optimisticUpdate = this.scheduler[key]
    // If queue is waiting to be called
    if (!optimisticUpdate.isCompleted && !optimisticUpdate.isUpdating && !!optimisticUpdate.queue.length) {
      this.scheduler[key].isUpdating = true
      try {
        const currentOptimisticUpdate = optimisticUpdate.queue[0]
        await currentOptimisticUpdate.callback(currentOptimisticUpdate.action, this.getState)

        optimisticUpdate.queue.shift(0)
        optimisticUpdate.isUpdating = false
        optimisticUpdate.isCompleted = !optimisticUpdate.queue.length

        this.runOptimisticUpdate(key)
      } catch (e) {
        // Retrieve previous state
        const { prevState } = this.scheduler[key]

        // Execute fallback if provided
        const { fallback } = this.scheduler[key].queue[0]

        if (typeof fallback !== 'undefined') {
          fallback(e, prevState)
        }
        // Reset scheduler
        this.scheduler[key] = {
          queue: [],
          isUpdating: false,
          isCompleted: true,
          prevState: {},
        }
      }
    }
  }
}
