import styled, { css } from 'styled-components'
import { styles } from 'gipsy-misc'

export const PageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  ${({ isConnected }) =>
    !isConnected &&
    css`
      bottom: 0;
    `}
  &.page-transition-enter-done {
    opacity: 1;
  }

  &.page-transition-appear {
    transition: opacity ${styles.transitions.pageContainerIn}s;
    opacity: 0;
  }

  &.page-transition-appear.page-transition-appear-active {
    opacity: 1;
  }

  &.page-transition-enter {
    opacity: 0;
  }

  &.page-transition-enter.page-transition-enter-active {
    opacity: 1;
    transition: opacity ${styles.transitions.pageContainerIn}s ${styles.transitions.pageContainerIn}s ease-in;
  }

  &.page-transition-exit {
    opacity: 1;
    overflow: hidden;
  }

  &.page-transition-exit.page-transition-exit-active {
    opacity: 0;
    transition: opacity ${styles.transitions.pageContainerOut}s ease-in;
  }
`
