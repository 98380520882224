import React from 'react'
import styled, { css } from 'styled-components'

import { constants, styles, utils } from 'gipsy-misc'

import FixedTooltip from 'FixedTooltip'
import { RepeatIcon } from 'LineView/components/commonUIComponents'

const completionDuration = 0.3

export function Title(props) {
  const { item, title, whenDate, isPastItem, isStruckThrough } = props

  return (
    <TitleContainer>
      {item.recurrencyInformation && (
        <FixedTooltip
          content={utils.recurrency.details.getRecurrencyLabel(item.recurrencyInformation?.recurrencyDetails)}
          portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
          style={{ padding: '8px' }}>
          <RepeatIcon fill={styles.colors.textMediumDarkColor} icon='Repeat' size={10} />
        </FixedTooltip>
      )}
      <TitleText isStruckThrough={isStruckThrough}>
        {whenDate && isPastItem ? '☠️ ' : ''}
        {title}
      </TitleText>
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin-right: 10px;
  min-width: 0;
`

const TitleText = styled.span`
  display: block;
  font-size: 14px;
  line-height: 14px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${styles.colors.textMediumDarkColor};
  font-weight: 500;

  &:after {
    background-color: ${styles.colors.textMediumDarkColor};
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: calc(50% + 1px);
    transition: width ${completionDuration}s ease;
    width: 0;
  }

  ${({ isStruckThrough }) =>
    isStruckThrough &&
    css`
      &:after {
        width: 100%;
      }
    `}
`
