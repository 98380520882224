import translations from 'translations'

export const SUN: number = 0
export const MON: number = 1
export const TUE: number = 2
export const WED: number = 3
export const THU: number = 4
export const FRI: number = 5
export const SAT: number = 6

export const shortDayNames = {
  [SUN]: translations.days.shortLabel.sunday,
  [MON]: translations.days.shortLabel.monday,
  [TUE]: translations.days.shortLabel.tuesday,
  [WED]: translations.days.shortLabel.wednesday,
  [THU]: translations.days.shortLabel.thursday,
  [FRI]: translations.days.shortLabel.friday,
  [SAT]: translations.days.shortLabel.saturday,
}
