import { combineReducers } from 'redux'

import { dragAndDropReducer } from './reducers/dragAndDrop'
import { filteredIdsReducer } from './reducers/filteredIds'
import { settingsReducer } from './reducers/settings'
import { tmpItemsReducer } from './reducers/tmpItems'

export default combineReducers({
  dragAndDrop: dragAndDropReducer,
  filteredIds: filteredIdsReducer,
  settings: settingsReducer,
  tmpItems: tmpItemsReducer,
})
