import { produce } from 'immer'

import { types } from 'store/taskPanel/types'

const defaultState = {}

function addId(state, id) {
  state[id] = true
  return state
}

function removeId(state, id) {
  delete state[id]
  return state
}

function replaceId(state, { id, replacementId }) {
  delete state[id]
  state[replacementId] = true
  return state
}

export function filteredIdsReducer(state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.ADD_FILTERED_ID: {
        return addId(draft, action.payload)
      }

      case types.REMOVE_FILTERED_ID: {
        return removeId(draft, action.payload)
      }

      case types.REPLACE_FILTERED_ID: {
        return replaceId(draft, action.payload)
      }

      case types.RESET_FILTERED_IDS: {
        return defaultState
      }

      default: {
        return draft
      }
    }
  })
}
