import { models, styles, utils } from 'gipsy-misc'

const isGCalItem = (item) => item?.webLink?.includes?.('google')

const getEventTextColor = (event, backgroundColor) => {
  let textColor
  if (event.isPlaceholder) {
    textColor = styles.colors.textDarkColor
  } else if (event.item.type === models.item.type.TASK) {
    textColor = styles.colors.textMediumDarkColor
  } else if (isGCalItem(event.item)) {
    textColor = '#ffffff'
  } else {
    textColor = utils.colors.adjustColorByBrightness(backgroundColor, '#ffffff', styles.colors.textMediumDarkColor)
  }
  if (event.isPast) {
    return `${textColor}66`
  } else {
    return textColor
  }
}

const getBorderStyle = (event) => {
  const props = {
    borderStyle: 'solid',
    borderWidth: '1px',
  }
  if (event.isHighlighted) {
    props.borderColor = 'transparent'
  } else if (event.isPlaceholder) {
    props.borderColor = event.color
  } else {
    props.borderColor = 'white'
  }
  return props
}

/* used to customize event design in all views (background-color etc ...) */
export const eventPropGetter = (event) => {
  const backgroundColor = event.color || event.calendarColor || styles.colors.taskFill
  const style = {
    color: getEventTextColor(event, backgroundColor),
    fontWeight: event.isHighlighted ? 'bold' : 500,
    backgroundColor,
    ...getBorderStyle(event),
  }

  return {
    className: `event-container ${event.isPlaceholder ? 'placeholder' : ''} ${
      event.isHighlighted ? 'highlighted' : ''
    }`,
    style,
  }
}
