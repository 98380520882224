import axios from 'utils/axios'

export function get() {
  const url = `/awsapigateway/presigned-url`
  return axios.get(url)
}

export function publishMessage(topic, message = '') {
  const url = `/awsapigateway/publish`
  return axios.post(url, { message, topic })
}
