import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { styles } from 'gipsy-misc'
import { CustomPopup, DatePicker, datePickerStyles, Icon } from 'gipsy-ui'

export default function DatePickerInput({ closeOnSelection, firstDayOfWeek, onChange, startTime }) {
  const [datePickerShown, setDatePickerShown] = useState(false)
  const [datePickerPopupPosition, setDatePickerPopupPosition] = useState({})

  const containerRef = useRef(null)
  const popupRef = useRef(null)

  const showDatePicker = useCallback(() => {
    setDatePickerShown((prev) => !prev)
  }, [])

  const hideDatePicker = useCallback((e) => {
    if (containerRef.current.contains(e.target)) {
      return
    }

    setDatePickerShown(false)
  }, [])

  const handleChange = useCallback(
    (...data) => {
      if (closeOnSelection) {
        setDatePickerShown(false)
      }

      onChange?.(...data)
    },
    [closeOnSelection, onChange]
  )

  useLayoutEffect(() => {
    if (!datePickerShown || !popupRef.current) {
      setDatePickerPopupPosition({})
      return
    }

    const popupBounds = popupRef.current.getBoundingClientRect()

    if (popupBounds.bottom >= window.innerHeight - 48) {
      setDatePickerPopupPosition({
        top: '0%',
        transform: 'translateY(calc(-100% - 12px))',
      })
    }
  }, [datePickerShown])

  return (
    <InputContainer ref={containerRef}>
      <CalendarIcon fill={styles.colors.darkGrey} icon='CalendarEmpty' onClick={showDatePicker} size={16} />
      <DateLabel onClick={showDatePicker}>{startTime.format('ddd, MMM D')}</DateLabel>
      {datePickerShown && (
        <DatePopup onClickOutside={hideDatePicker} ref={popupRef} style={datePickerPopupPosition}>
          <DatePicker
            firstDayOfWeek={firstDayOfWeek}
            onChange={handleChange}
            paramName={'when.date'}
            selectedDay={startTime.toISOString()}
          />
        </DatePopup>
      )}
    </InputContainer>
  )
}

export const DateLabel = styled.span`
  border: 1px solid transparent;
  line-height: 18px;
  padding: 4px;
  transition: border-color 300ms ease-in-out, color 300ms ease-in-out;
`

DateLabel.displayName = 'DateLabel'

export const InputContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;

  :hover ${DateLabel} {
    color: ${styles.colors.primaryColor};
    border-color: ${styles.colors.middleGrey};
    border-radius: 8px;
  }
`

InputContainer.displayName = 'InputContainer'

export const CalendarIcon = styled(Icon)`
  flex-shrink: 0;
  margin: 0 8px 0 0;
`

CalendarIcon.displayName = 'CalendarIcon'

export const DatePopup = styled(CustomPopup)`
  left: 0;
  padding: 8px;
  top: 100%;

  ${datePickerStyles}
`

DatePopup.displayName = 'DatePopup'
