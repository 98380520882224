import { createAction } from 'redux-actions'

import { user as userApi } from 'gipsy-api'

import { handleAPIError } from 'store/app/actions'
import { types } from 'store/integrations/types'

export const _setIntegrationsData = createAction(types.SET_INTEGRATIONS_DATA)

export const setIntegrationsData = (data) => (dispatch) => {
  dispatch(_setIntegrationsData(data))
}

export const fetchIntegrationsData = () => async (dispatch) => {
  try {
    const integrationsData = await userApi.getIntegrations()
    dispatch(_setIntegrationsData(integrationsData))
  } catch (err) {
    dispatch(handleAPIError(err))
  }
}
