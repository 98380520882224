import React, { PureComponent } from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import ActivePage from './active'
import TimeReportsPage from 'pages/timeReports'

import NotFound from 'pages/notFound'

const mapStateToProps = (state) => ({
  areProjectsLoaded: state.projects.areProjectsLoaded,
  areTagsLoaded: state.tags.areTagsLoaded,
  areEventsLoaded: state.calendar.items.fetchStatus.areLoaded,
})

class TagLayout extends PureComponent {
  render() {
    const { areProjectsLoaded, areTagsLoaded, areEventsLoaded } = this.props
    if (!areProjectsLoaded || !areTagsLoaded || !areEventsLoaded) return null
    return (
      <div className='tag-layout'>
        <TransitionGroup>
          <CSSTransition
            key={this.props.location.pathname}
            timeout={{ enter: 400, exit: 400 }}
            appear={true}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={'page-transition'}>
            <Switch location={this.props.location}>
              <Route exact path='/tag/active/:tagId' component={ActivePage} />
              <Route exact path='/tag/reports/:tagId' component={TimeReportsPage} />
              <Route component={NotFound} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, null, null, {
    pure: true,
  })(TagLayout)
)
