const spacings = {
  tiny: '4px',
  xsmall: '8px',
  small: '12px',
  normal: '16px',
  medium: '24px',
  large: '30px',
  xlarge: '48px',
  marginRightAfterIcon: '16px',
  marginAfterLine: '5px',
  lineContainerPadding: '10px',
  focusSessionZenLogoWidth: '30px',
}
export default spacings
