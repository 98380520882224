import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { constants, styles, translations } from 'gipsy-misc'
import { AnimateHeight, FixedTooltip, Icon, SimpleButton, UserPicture } from 'gipsy-ui'

import AccountCalendar from './AccountCalendar'
import AccountControls from './AccountControls'

export default function AccountItem({
  account,
  onChangeDefaultCalendar,
  onOpenSettings,
  onReauthenticate,
  onSyncAccount,
  onToggleSelect,
  onUnsyncAccount,
  removeBorder,
  syncingMap,
}) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpansion = () => {
    setExpanded((prev) => !prev)
  }

  const handleToggleSelect = (...args) => {
    onToggleSelect(...args)
  }

  const profile = account?.profile
  const provider = account?.provider
  const email = account?.email
  const name = profile?.displayName
  const picture = profile?.picture
  const credentialsInvalid = account?.areCredentialsInvalid
  const missingScopes = account?.missingScopes
  const invalid = credentialsInvalid || missingScopes

  const handleReauthentication = () => {
    onReauthenticate(provider?.toLowerCase?.(), email)
  }

  return (
    <Container expanded={expanded} invalid={invalid} removeBorder={removeBorder}>
      <AccountInfoContainer onClick={toggleExpansion}>
        <UserPicture
          backgroundColor='transparent'
          borderRadius='50px'
          size={32}
          sizeDefault={24}
          src={picture}
          style={{ marginRight: 11 }}
        />
        <AccountInfo>
          <NameContainer>
            <Name title={name}>{name}</Name>
          </NameContainer>
          <Email title={email}>{email}</Email>
        </AccountInfo>
        {invalid && (
          <FixedTooltip
            content={credentialsInvalid ? translations.auth.invalidCredentials : translations.auth.missingScopes}
            horizontalOffset={-6}
            portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
            style={{
              padding: '10px 5px 10px 10px',
              width: '200px',
            }}
            verticalOffset={-4}>
            <WarningIcon fill='none' icon='Warning' size={16} stroke={styles.colors.pinkColor} />
          </FixedTooltip>
        )}
        <ExpandCaret expanded={expanded} icon='CaretDown' size={12} />
      </AccountInfoContainer>
      {invalid && (
        <GrantAccessContainer>
          <SimpleButton
            backgroundColor='white'
            borderColor={styles.colors.lightVioletBorderColor}
            height={32}
            onClick={handleReauthentication}
            text={credentialsInvalid ? translations.auth.reAuthenticate : translations.auth.grantAccess}
            textColor={styles.colors.primaryColor}
            width={168}
          />
        </GrantAccessContainer>
      )}
      <AnimateHeight duration={300} height={expanded ? 'auto' : 0}>
        {!invalid && account.calendarsList && (
          <Calendars>
            {account.calendarsList?.map((calendar) => (
              <AccountCalendar
                calendar={calendar}
                email={account.email}
                key={calendar.calendarId}
                onChangeDefaultCalendar={onChangeDefaultCalendar}
                onOpenSettings={onOpenSettings}
                onToggleSelect={handleToggleSelect}
                syncingMap={syncingMap}
              />
            ))}
          </Calendars>
        )}
        <AccountControls
          email={email}
          invalidCreds={invalid}
          isMainAccount={account?.main}
          onSyncAccount={onSyncAccount}
          onUnsyncAccount={onUnsyncAccount}
        />
      </AnimateHeight>
    </Container>
  )
}

const Container = styled.div`
  background-color: transparent;
  border-bottom: 1px solid ${styles.colors.middleGrey};
  padding: 16px 8px;
  position: relative;

  ${({ expanded }) =>
    expanded &&
    css`
      background-color: white;
      border-bottom-color: transparent;
      border-radius: 8px;
      box-shadow: ${styles.shadows.popup};
      margin-bottom: 4px;
      padding-bottom: 12px;
    `}

  ${({ removeBorder }) =>
    removeBorder &&
    css`
      border-bottom: none;
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      background-color: ${styles.colors.pinkColor}1A;
      border-radius: 8px;
      box-shadow: none;
      margin: 10px 0;
      padding-bottom: 10px;
    `}
`

Container.displayName = 'Container'

const AccountInfoContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0 8px;
`

AccountInfoContainer.displayName = 'AccountInfoContainer'

const AccountInfo = styled.div`
  flex: 1;
  margin-right: 12px;
  min-width: 0;
`

AccountInfo.displayName = 'AccountInfo'

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`

NameContainer.displayName = 'NameContainer'

const Name = styled.span`
  display: block;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

Name.displayName = 'Name'

const Email = styled.span`
  color: ${styles.colors.darkGrey};
  display: block;
  line-height: 15px; // otherwise the font doesn't have enough space for all letters
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

Email.displayName = 'Email'

const WarningIcon = styled(Icon)`
  margin-left: auto;
  margin-right: 22px;
`

WarningIcon.displayName = 'WarningIcon'

const ExpandCaret = styled(Icon)`
  margin-left: auto;
  transform: rotate(90deg);

  ${({ expanded }) =>
    expanded &&
    css`
      transform: unset;
    `}
`

ExpandCaret.displayName = 'ExpandCaret'

const GrantAccessContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  width: 100%;

  button {
    text-transform: capitalize;
  }
`

GrantAccessContainer.displayName = 'GrantAccessContainer'

const Calendars = styled.div`
  margin-top: 18px;
`

Calendars.displayName = 'Calendars'
