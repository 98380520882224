import axios from 'utils/axios'
import { utils } from 'gipsy-misc'

export const create = (body, context) => {
  const endpoint = `/focus-session`
  const headers = utils.request.makeContextHeaders(context)
  return axios.post(endpoint, body, { headers })
}

export const get = () => {
  let endpoint = `/focus-session`
  return axios.get(endpoint)
}

export const stop = () => {
  const endpoint = `/focus-session/stop`
  return axios.post(endpoint)
}

export const update = (body) => {
  const endpoint = `/focus-session`
  return axios.put(endpoint, body)
}

export const del = (fsId) => {
  const endpoint = `/focus-session/${fsId}`
  return axios.delete(endpoint)
}

export const toggleBlockDistractions = (value) => {
  let endpoint = `/block-distractions`
  endpoint = utils.request.appendToUrl(endpoint, { value })
  return axios.post(endpoint)
}

export const getCompletedSessions = (lowerBound, upperBound, groupByTasks) => {
  const groupByParam = groupByTasks ? '&groupBy=tasks' : ''
  const endpoint = `/focus-session/completed?lowerBound=${lowerBound}&upperBound=${upperBound}${groupByParam}`
  return axios.get(endpoint)
}
