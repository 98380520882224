import React from 'react'
import { IconButton } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

export default function AddInlineTaskButton(props) {
  return (
    <IconButton
      onClick={props.onClick}
      iconName={'SurroundedSquarePlus'}
      iconSize={16}
      flexGrow
      labelHoverColor={styles.colors.primaryColor}
      borderHoverColor={styles.colors.lightVioletBorderColor}
      label={translations.inlineAddTask.addTask}
    />
  )
}
