import React from 'react'
import styled from 'styled-components'
import Icon from 'Icon'
import { styles } from 'gipsy-misc'

const Input = React.forwardRef((props, ref) => {
  const { iconName, iconSize, className, ...inputProps } = props
  let leftPadding
  const hasIcon = iconName && iconSize
  if (hasIcon) leftPadding = iconSize + inputIconLeftMargin + inputIconRightMargin
  return (
    <InputContainer className={className}>
      <StyledInput ref={ref} {...inputProps} leftPadding={leftPadding} />
      {hasIcon && <InputIcon icon={iconName} size={iconSize} />}
    </InputContainer>
  )
})

Input.displayName = 'Input'

export const inputHeight = 32

const StyledInput = styled.input`
  color: ${styles.colors.textMediumDarkColor};
  font-size: ${styles.fonts.fontSizeSmall};
  padding-left: ${(props) => (isNaN(parseInt(props.leftPadding)) ? 8 : props.leftPadding)}px;
  border: ${`1px solid ${styles.colors.greyBorderColor}`};
  border-radius: 8px;
  width: 100%;
  height: ${inputHeight}px;
  transition: border 0.2s ease-in-out;
  :focus {
    border: ${`1px solid ${styles.colors.darkGrey}`};
  }
  ::placeholder {
    color: ${styles.colors.darkGrey};
    font-size: ${styles.fonts.fontSizeSmall};
  }
`

const inputIconLeftMargin = 10
const inputIconRightMargin = 5
const InputIcon = styled(Icon)`
  position: absolute;
  left: ${inputIconLeftMargin}px;
  top: 50%;
  transform: translateY(-50%);
`

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`

export default Input
