import { chromeextErrCode } from 'gipsy-misc'

const EXTENSION_ID = process.env.REACT_APP_CHROME_EXTENSION_ID
export const EXTENSION_LINK = EXTENSION_ID && `https://chrome.google.com/webstore/detail/${EXTENSION_ID}`
export const sendMessage = (messageType, extensionId, data) => {
  if (!(extensionId || EXTENSION_ID)) {
    console.warn('Could not find chrome extension id, add it to the homebase .env')
  } else if (window.chrome && window.chrome.runtime) {
    return new Promise((resolve, reject) => {
      window.chrome.runtime.sendMessage(extensionId || EXTENSION_ID, { type: messageType, data: data }, (e) => {
        if (window.chrome.runtime.lastError && !e) {
          const err = {
            data: {
              code: chromeextErrCode.messageNotReceived,
            },
          }
          resolve({ err: err })
        }
        resolve(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      const err = {
        data: {
          code: chromeextErrCode.notOnChrome,
        },
      }
      resolve({ err: err })
    })
  }
}
