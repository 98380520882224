import React from 'react'
import styled from 'styled-components'

import ProjectsAttribute from 'LineComponents/ProjectsAttribute'
import AsanaWorkspaceAttribute from 'LineComponents/AsanaWorkspaceAttribute'

export default function AsanaProjectsAttribute({ asanaWorkspaceName, projects, stopPropagationOnClick }) {
  let projectAmountToCollapse
  if (asanaWorkspaceName) projectAmountToCollapse = 3
  return (
    <AsanaProjectsAttributeContainer splitted={!!asanaWorkspaceName}>
      {asanaWorkspaceName ? <AsanaWorkspaceAttribute asanaWorkspaceName={asanaWorkspaceName} /> : null}
      <ProjectsContainer>
        <ProjectsAttribute
          projects={projects}
          projectAmountToCollapse={projectAmountToCollapse}
          asanaWorkspaceName={asanaWorkspaceName}
          stopPropagationOnClick={stopPropagationOnClick}
        />
      </ProjectsContainer>
    </AsanaProjectsAttributeContainer>
  )
}

export const AsanaProjectsAttributeContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.splitted ? 'minmax(50%, auto) minmax(50%, 100px)' : '100%')};
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const ProjectsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
