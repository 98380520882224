import React, { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import compose from 'lodash/flow'
import throttle from 'lodash/throttle'

import { styles, translations, utils } from 'gipsy-misc'

import FocusSessionsPopup from 'FocusSession/Popup'
import Icon from 'Icon'
import AsanaProjectsAttribute, {
  AsanaProjectsAttributeContainer,
  ProjectsContainer,
} from 'LineComponents/AsanaProjectsAttribute'
import UrlsAttribute from 'LineComponents/UrlsAttribute'
import TagsAttribute from 'LineComponents/TagsAttribute'
import { Attribute, TagsContainer } from 'LineView/components/commonUIComponents'
import { Title } from 'LineView/components/Title'
import { Timer } from 'Timer'

const completionDuration = 0.3
export const containerHeight = 48

export default React.forwardRef(function FocusedLineCompressed(
  {
    animateComplete = true,
    completeDisabled = false,
    onClickFocusSession,
    onCompleteFromFS,
    onDeleteFocusSession,
    onPauseFocusSession,
    onTitleChange,
    onUpdateFocusSession,
    pauseDisabled = false,
    registerShortcuts,
    session,
    unregisterShortcuts,
  },
  ref
) {
  const [justCompleted, setJustCompleted] = useState(false)
  const [showFocusSessionsPopup, setShowFocusSessionsPopup] = useState(false)

  const fsPopupRef = useRef(null)
  const fsPopupTriggerRef = useRef(null)

  const focusSession = session.focusSession
  const focusedTask = focusSession?.task

  const pauseFocus = useCallback(() => {
    if (pauseDisabled) return

    const { discardFocusSession, focusSession: updatedFocusSession } = utils.focussession.addFocusSessionFromPause(
      focusSession
    )
    onPauseFocusSession(updatedFocusSession, { discardFocusSession })
  }, [focusSession, onPauseFocusSession, pauseDisabled])

  const throttlePauseFocus = throttle(pauseFocus, 500)

  const onClickPauseButton = compose([utils.DOM.stopPropagation, throttlePauseFocus])

  const onClickCompleteButton = useCallback(
    (e) => {
      if (completeDisabled) return

      if (focusSession.task.completed) {
        onClickPauseButton(e)
        return
      }
      const completionData = {
        id: focusSession.task.id,
        paramName: 'completed',
        value: 1,
      }
      const newFocusSession = utils.focussession.getCompletedFocusSession(focusSession)

      if (animateComplete) {
        setJustCompleted(true)
        return setTimeout(() => {
          onCompleteFromFS(completionData, newFocusSession)
        }, completionDuration * 1000)
      }

      onCompleteFromFS(completionData, newFocusSession)
    },
    [completeDisabled, focusSession, animateComplete, onCompleteFromFS, onClickPauseButton]
  )

  const toggleSessionsPopup = useCallback(() => {
    if (!focusedTask?.focusSessions?.length) {
      return setShowFocusSessionsPopup(false)
    }

    setShowFocusSessionsPopup((prev) => !prev)
  }, [focusedTask?.focusSessions?.length])

  const _onTitleChange = useCallback(
    (title) => {
      onTitleChange?.(title, focusedTask)
    },
    [onTitleChange, focusedTask]
  )

  useEffect(() => {
    if (!focusedTask?.focusSessions?.length) {
      setShowFocusSessionsPopup(false)
    }
  }, [focusedTask?.focusSessions?.length])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!fsPopupRef.current || fsPopupTriggerRef.current?.contains?.(e.target)) return

      if (!fsPopupRef.current.contains(e.target)) {
        setShowFocusSessionsPopup(false)
      }
    }

    window.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const spentTime = utils.task.getSpentTime(focusedTask)

  return (
    <Container hasSprintTask={!!focusedTask?.sprintInfo} layout ref={ref}>
      <Controls>
        <PauseIcon disabled={pauseDisabled} icon={'InversePause'} onClick={onClickPauseButton} size={24} />
        <CompleteButton disabled={completeDisabled} onClick={onClickCompleteButton}>
          {translations.general.done}
        </CompleteButton>
        <TimeContainer>
          <TimeIcon fill='white' size={14} icon={'HourGlassOutlined'} />
          <TimeWrapper>
            <SessionsPopupTrigger onClick={toggleSessionsPopup} ref={fsPopupTriggerRef}>
              <Timer startDate={focusSession.startTime || Date.now()} timeAlreadySpent={spentTime} showHours />
            </SessionsPopupTrigger>
            {showFocusSessionsPopup && (
              <FocusSessionsPopup
                item={focusedTask}
                onClickFocusSession={onClickFocusSession}
                onDeleteFocusSession={onDeleteFocusSession}
                onUpdateFocusSession={onUpdateFocusSession}
                setRef={fsPopupRef}
                firstDayOfWeek={session?.user?.settingsPreferences?.calendar?.firstDay}
              />
            )}
          </TimeWrapper>
        </TimeContainer>
      </Controls>
      <LineContainer>
        <TitleWrapper justCompleted={justCompleted}>
          <Title
            isEditable
            justCompleted={justCompleted}
            onTitleChange={_onTitleChange}
            registerShortcuts={registerShortcuts}
            title={focusedTask?.title}
            unregisterShortcuts={unregisterShortcuts}
            completionDuration={completionDuration}
          />
        </TitleWrapper>
        <AttributesContainer>
          <UrlsCell>
            <UrlsAttribute urlsInfo={focusedTask?.urlsInfo} />
          </UrlsCell>
          <TagsCell>
            <TagsAttribute tags={focusedTask?.tags} />
          </TagsCell>
          <ProjectsCell>
            <AsanaProjectsAttribute
              projects={focusedTask?.projects}
              asanaWorkspaceName={focusedTask?.asanaInfo?.workspaceName}
            />
          </ProjectsCell>
        </AttributesContainer>
      </LineContainer>
    </Container>
  )
})

const Controls = styled.div`
  align-items: center;
  background-color: ${styles.colors.primaryColor};
  color: white;
  display: flex;
  padding: 14px 24px;
  width: 247px;
`

Controls.displayName = 'Controls'

const PauseIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 8px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`

PauseIcon.displayName = 'PauseIcon'

const CompleteButton = styled.button`
  appearance: none;
  background-color: ${styles.colors.veryLightGrey};
  border: none;
  border-radius: 6px;
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  font-family: NeurialGrotesk, Helvetica, Arial;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  line-height: 16px;
  min-width: 70px;
  outline: none;
  text-align: center;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`

CompleteButton.displayName = 'CompleteButton'

const Container = styled(motion.div)`
  display: flex;
  height: ${containerHeight}px;
  width: 100%;

  ${({ hasSprintTask }) =>
    hasSprintTask &&
    css`
      ${Controls} {
        background-color: ${styles.colors.orangeColor};
      }

      ${PauseIcon} {
        .pause-bar {
          fill: ${styles.colors.orangeColor};
        }
      }

      ${CompleteButton} {
        color: ${styles.colors.orangeColor};
      }
    `}
`

Container.displayName = 'Container'

const TimeContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
  margin-left: 15px;
  font-weight: 500;
`

TimeContainer.displayName = 'TimeContainer'

const TimeIcon = styled(Icon)`
  margin-right: 4px;
`

TimeIcon.displayName = 'TimeIcon'

const TimeWrapper = styled.div`
  position: relative;
`

TimeWrapper.displayName = 'TimeWrapper'

const SessionsPopupTrigger = styled.div`
  cursor: pointer;
`

SessionsPopupTrigger.displayName = 'SessionsPopupTrigger'

const LineContainer = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  min-width: 0;
  padding: 0 16px;
`

LineContainer.displayName = 'LineContainer'

const TitleWrapper = styled.div`
  flex: 1;
  min-width: 270px;
  max-width: 470px;
  padding: 8px 0 4px;

  ${({ justCompleted }) =>
    justCompleted &&
    css`
      padding-bottom: 8px;
      padding-top: 0;
    `}
`

TitleWrapper.displayName = 'TitleWrapper'

const AttributesContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin-left: 24px;
  min-width: 0;
  padding: 8px 0;
`

AttributesContainer.displayName = 'AttributesContainer'

const UrlsCell = styled.div`
  margin-right: 16px;
  max-width: 286px;
  min-width: 65px;
`

UrlsCell.displayName = 'UrlsCell'

const TagsCell = styled.div`
  color: ${styles.colors.darkGrey};
  font-size: 12px;
  margin-right: 16px;
  max-width: 180px;
  min-width: 65px;

  ${TagsContainer} {
    flex-wrap: nowrap;

    ${Attribute} {
      flex-shrink: 0;
    }
  }
`

TagsCell.displayName = 'TagsCell'

const ProjectsCell = styled.div`
  flex: 1;

  ${AsanaProjectsAttributeContainer} {
    display: flex;
    justify-content: flex-end;
  }

  ${ProjectsContainer} {
    width: auto;
  }
`

ProjectsCell.displayName = 'ProjectsCell'
