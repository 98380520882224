import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get as getSession } from 'store/session/actions'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSession,
    },
    dispatch
  ),
})

class Preloader extends Component {
  componentDidMount() {
    this.props.actions.getSession()
  }

  render() {
    return <div id='preloader-page' />
  }
}

export default connect(null, mapDispatchToProps, null, {
  pure: true,
})(Preloader)
