import moment from 'moment'
import findIndex from 'lodash/findIndex'
import differenceBy from 'lodash/differenceBy'

import { convertNanosecondsToMinute, convertMinuteToNanoseconds } from 'utils/datetime'
import { getRemainingMillisecondsToMinute } from './timer'

export const getSprintsInProgress = (sprints = [], now = new Date()) => {
  const leftLimit = now.getTime() - now.getSeconds() * 1000 - now.getMilliseconds() // very beggining of the minute
  const rightLimit = leftLimit + getRemainingMillisecondsToMinute(now) // end of the minute
  return sprints.filter((sprint) => {
    const pinTime = new Date(sprint.pin.time).getTime()
    if (pinTime < now.getTime()) {
      const estimatedTimeInMiliSeconds = sprint.estimatedTime / 1000000
      const endTime = pinTime + estimatedTimeInMiliSeconds
      return endTime >= leftLimit
    } else {
      return pinTime >= leftLimit && pinTime <= rightLimit
    }
  })
}

export const findTaskInSprintArr = (sprints = [], taskId) => {
  for (let sprintIdx = 0; sprintIdx < sprints.length; sprintIdx++) {
    const taskIdx = findIndex(sprints[sprintIdx].tasks, ['id', taskId])
    if (taskIdx !== -1) {
      return { sprintIdx, taskIdx }
    }
  }
  return { sprintIdx: -1, taskIdx: -1 }
}

export const remapSprintTasksWithId = (tasks = [], sprintId) => {
  return tasks.map((task) => ({
    ...task,
    sprintInfo: {
      ...task.sprintInfo,
      id: sprintId,
    },
  }))
}

export const getCompletionTimeAndEstimatedTime = (sprint) => {
  const startTime = moment(sprint.pin.time)
  const endTime = moment(sprint.pin.time).add(convertNanosecondsToMinute(sprint.estimatedTime), 'm')

  let completionTime = moment()
  completionTime = endTime < completionTime ? endTime : completionTime
  const estimatedTime =
    endTime < completionTime
      ? sprint.estimatedTime
      : convertMinuteToNanoseconds(completionTime.diff(startTime, 'minutes'))
  return { completionTime, estimatedTime }
}

export function getPinTimeAndEstimatedTimeFromCalendarSlot({ start, end }) {
  const startTime = moment(start)
  const endTime = moment(end)
  const diff = endTime.diff(startTime, 'minutes')
  const estimatedTime = convertMinuteToNanoseconds(diff)

  return {
    when: {
      date: startTime.format('YYYY-MM-DD'),
    },
    pin: {
      time: startTime.format(),
    },
    estimatedTime,
  }
}

export function removeTask(sprint, task) {
  return {
    ...sprint,
    tasks: (sprint.tasks || []).filter((_task) => _task.id !== task.id),
    tasksId: (sprint.tasksId || []).filter((id) => id !== task.id),
  }
}

export function completeTask(sprint, taskId) {
  const taskIdx = sprint.tasks.findIndex((task) => task.id === taskId)
  const newTasks = [...sprint.tasks]
  newTasks[taskIdx] = {
    ...newTasks[taskIdx],
    completed: 1,
  }
  return {
    ...sprint,
    tasks: newTasks,
  }
}

export function getRemovedAndAddedTaskFromUpdatedSprint(currentSprint, updatedSprint) {
  const newSprint = JSON.parse(JSON.stringify(updatedSprint))
  const addedTasksInSprint = differenceBy(newSprint.tasks || [], currentSprint.tasks || [], 'id')
  const removedTasksFromSprint = differenceBy(currentSprint.tasks || [], newSprint.tasks || [], 'id')
  return { removedTasksFromSprint, addedTasksInSprint }
}

export const isPast = (sprint) => {
  const now = moment()
  const endTime = getEndTime(sprint)
  const isPast = endTime <= now

  return isPast
}

export const getEndTime = (sprint) => {
  return moment(sprint.pin && sprint.pin.time).add(convertNanosecondsToMinute(sprint.estimatedTime), 'm')
}

export const shouldCompleteSprint = (sprint, now) => {
  return !sprint.tasks?.length > 0 && moment(sprint.pin?.time) < moment(now)
}

export const isRecurrent = (sprint) => !!sprint.recurrencyInformation
export const getRecSprintId = (sprint) => sprint.recurrencyInformation?.recurringSprintId

export const hasSprintInfoChanged = (oldSprint, newSprint) => {
  return (
    oldSprint.title !== newSprint.title ||
    oldSprint.estimatedTime !== newSprint.estimatedTime ||
    moment(oldSprint.pin.time).diff(moment(newSprint.pin.time)) !== 0
  )
}

export const areSprintAttributesNotEqual = (s1, s2) => {
  return s1.pin.time !== s2.pin.time || s1.estimatedTime !== s2.estimatedTime || s1.title !== s2.title
}
