export const appendToUrl = (url, params) => {
  Object.keys(params).forEach((key, index) => {
    const isQuestionMarkPresent = url && url.indexOf('?') !== -1
    const separator = isQuestionMarkPresent ? '&' : '?'
    url += `${separator}${key}=${encodeURIComponent(params[key])}`
  })
  return url
}

export const makeContextHeaders = (context) => {
  let headers
  if (context) {
    headers = { 'Gipsy-Context': JSON.stringify(context) }
  }
  return headers
}
