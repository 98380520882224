import { createAction } from 'redux-actions'

export const types = {
  SHOW_SNACKBAR: 'SHOW_SNACKBAR',
  HIDE_SNACKBAR: 'HIDE_SNACKBAR',
}

export const show = createAction(types.SHOW_SNACKBAR)
export const hideSnackbar = createAction(types.HIDE_SNACKBAR)

let timeout = 0

const payloadIsValid = (payload) => {
  /* we dont want to show an empty message */
  if (!payload.message || !payload.message.length) {
    return false
  }

  /* payload status should be null or one of these strings */
  if (payload.status && ['success', 'error', 'default'].indexOf(payload.status) === -1) {
    return false
  }

  return true
}

export const showSnackbar = ({
  message,
  status = 'default',
  showLogo = true,
  showClose = true,
  time = 5 * 1000,
}) => async (dispatch, getState, { api }) => {
  dispatch(
    showSnackbarAction(
      {
        message: message,
        status: status,
        showLogo: showLogo,
        showClose: showClose,
      },
      time
    )
  )
  return
}

export const showSnackbarAction = (payload = {}, duration = 5000) => (dispatch, getState) => {
  if (payload.message && payload.message.toString) {
    payload.message = payload.message.toString()
  } else {
    payload.message = 'default'
  }

  if (!payloadIsValid(payload)) {
    console.warn('Snackbar/actions.js : payload is invalid', payload)
    return
  }

  dispatch(show(payload))
  if (timeout) {
    clearTimeout(timeout)
  }
  timeout = setTimeout(() => {
    dispatch(hideSnackbar())
  }, duration)
}
