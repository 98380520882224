import React from 'react'
import { Alert } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

const topMargin = 20

export default function CreatedTaskAlert(props) {
  return (
    <Alert
      absolute
      top={`calc(100% + ${parseInt(styles.spacings.lineContainerPadding, 10) * 2 + topMargin}px)`}
      show={props.isShown}
      left={'50%'}
      text={translations.inlineAddTask.taskAdded}
    />
  )
}
