import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { styles } from 'gipsy-misc'
import Icon from 'Icon'

class DefaultPicture extends Component {
  static propTypes = {
    children: PropTypes.any,
    iconColor: PropTypes.string.isRequired,
    showIcon: PropTypes.bool.isRequired,
    borderRadius: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    active: PropTypes.bool,
    border: PropTypes.string,
    size: PropTypes.number.isRequired,
    opacity: PropTypes.number,
  }

  static defaultProps = {
    size: 32,
    showIcon: true,
    borderRadius: '50%',
    iconColor: styles.colors.darkGrey,
    backgroundColor: styles.colors.backgroundGreyColor,
    opacity: 1,
  }

  render() {
    const { size, backgroundColor, iconColor, showIcon, borderRadius, active, border, shake, opacity } = this.props

    return (
      <Container
        className="default-picture"
        border={border}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        active={active}
        size={size}
        shake={shake}
        opacity={opacity}>
        {this.props.children}
        {showIcon && <Icon icon="User" size={backgroundColor !== 'transparent' ? size / 2 : size} fill={iconColor} />}
      </Container>
    )
  }
}

export default DefaultPicture

const shakeAnimation = keyframes`
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: rotate(-3deg);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: rotate(3deg);
  }
`

const Container = styled.div`
  opacity: ${(props) => props.opacity};
  background-color: ${(props) => props.backgroundColor}
  position: relative;
  border-radius: ${(props) => props.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${({ border, active }) => css`
    ${
      border &&
      css`
        border: ${border};
      `
    }

    ${
      !border &&
      active &&
      css`
        border: 2px solid ${styles.colors.primaryColor};
      `
    }

    ${
      !border &&
      !active &&
      css`
        border: 0px solid transparent;
      `
    }
  `}
  ${({ additionnalCss }) => additionnalCss}
  ${({ shake }) =>
    shake &&
    css`
      animation: ${shakeAnimation} 0.82s linear infinite;
      backface-visibility: hidden;
      perspective: 1000px;
    `}
`
