import axios from 'utils/axios'

import { utils } from 'gipsy-misc'

let cancelTokens = {}

export const getCalendarEventsRange = (dateFrom, dateTo) => {
  cancelTokens.getRange = require('axios').CancelToken.source()
  let endpoint = `/calendar-events/${dateFrom}/to/${dateTo}`
  return axios.get(endpoint, { cancelToken: cancelTokens.getRange.token })
}

export const synchronize = (email, forceSync = false) => {
  let endpoint = `/calendar/full-sync/${email}`
  if (forceSync) {
    endpoint = utils.request.appendToUrl(endpoint, { forceSync })
  }
  return axios.post(endpoint)
}

export const cancel = (method) => {
  if (cancelTokens[method] && cancelTokens[method].cancel) {
    cancelTokens[method].cancel()
  }
}
