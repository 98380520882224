import React from 'react'
import styled from 'styled-components'

import ReusableButton from 'ReusableButton'
import Icon from 'Icon'

import { styles, translations } from 'gipsy-misc'

/*
  how it works :
    These are just "ready to use" templates of <Button />, already styled.
    However you can change the style in 2 ways :
      it will inherit any props you pass, you can customize it the same way you would with <Button backgroundColor='red' style={...} />
      you can override it with styled-components (ie: const Button = styled(LargeButtonIcon)`...`)
*/

/* ie : task panel - create button */
export const LargeButton = ({
  leftIconName,
  rightIconName,
  iconBackgroundColor,
  iconBackground,
  children,
  ...rest
}) => (
  <ReusableButton
    height={56}
    leftIcon={
      leftIconName ? (
        <LargeIconLeft
          icon={leftIconName}
          size={40}
          backgroundColor={iconBackgroundColor}
          background={iconBackground}
          fill='white'
        />
      ) : undefined
    }
    rightIcon={
      rightIconName ? (
        <LargeIconRight
          icon={rightIconName}
          size={40}
          backgroundColor={iconBackgroundColor}
          background={iconBackground}
          fill='white'
        />
      ) : undefined
    }
    {...rest}>
    {children}
  </ReusableButton>
)

const LargeIcon = styled(Icon)`
  border-radius: 18px;
  padding: 14px;
  background-color: ${(props) => props.backgroundColor || styles.colors.primaryDarkColor};
  position: absolute;
  background: ${(props) => props.background};
`

const LargeIconRight = styled(LargeIcon)`
  top: 8px;
  right: 8px;
  svg {
    height: auto;
  }
`

const LargeIconLeft = styled(LargeIcon)`
  top: 8px;
  left: 8px;
  svg {
    height: auto;
  }
`

export const TertiaryButton = ({ className, width, height, onClick, text, disabled }) => (
  <ReusableButton
    className={className}
    width={width}
    height={height}
    fontSize={parseInt(styles.fonts.fontSizeSmall, 10)}
    backgroundColor={styles.colors.veryLightGrey}
    textColor={styles.colors.textMediumDarkColor}
    borderRadius={8}
    fontWeight={500}
    disabled={disabled}
    onClick={onClick}>
    {text}
  </ReusableButton>
)

export const SecondaryButton = ({ className, width, height, onClick, text, disabled }) => (
  <ReusableButton
    className={className}
    width={width}
    height={height}
    fontSize={parseInt(styles.fonts.fontSizeSmall, 10)}
    textColor={styles.colors.primaryColor}
    backgroundColor={'transparent'}
    borderColor={styles.colors.lightVioletBorderColor}
    borderRadius={8}
    fontWeight={500}
    disabled={disabled}
    onClick={onClick}>
    {text}
  </ReusableButton>
)

export const PrimaryButton = ({ className, width, height, onClick, text, disabled }) => (
  <ReusableButton
    className={className}
    width={width}
    height={height}
    fontSize={parseInt(styles.fonts.fontSizeSmall, 10)}
    textColor={'white'}
    backgroundColor={styles.colors.primaryColor}
    borderRadius={8}
    fontWeight={500}
    disabled={disabled}
    onClick={onClick}>
    {text}
  </ReusableButton>
)

export const LibraryButton = ({ type, className, width, height, onClick, text, disabled }) => {
  switch (type) {
    case buttonType.primary:
      return (
        <PrimaryButton
          className={className}
          width={width}
          height={height}
          onClick={onClick}
          text={text}
          disabled={disabled}
        />
      )
    case buttonType.secondary:
      return (
        <SecondaryButton
          className={className}
          width={width}
          height={height}
          onClick={onClick}
          text={text}
          disabled={disabled}
        />
      )
    case buttonType.tertiary:
      return (
        <TertiaryButton
          className={className}
          width={width}
          height={height}
          onClick={onClick}
          text={text}
          disabled={disabled}
        />
      )
    default:
      return (
        <PrimaryButton
          className={className}
          width={width}
          height={height}
          onClick={onClick}
          text={text}
          disabled={disabled}
        />
      )
  }
}

export const buttonType = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
}

export const SimpleButton = ({
  className,
  width,
  height,
  backgroundColor,
  textColor,
  borderColor,
  onClick,
  leftIcon,
  text,
  disabled,
}) => (
  <ReusableButton
    className={className}
    width={width}
    height={height}
    fontSize={parseInt(styles.fonts.fontSizeSmall, 10)}
    borderColor={borderColor}
    backgroundColor={backgroundColor}
    textColor={textColor}
    leftIcon={leftIcon}
    borderRadius={8}
    fontWeight={500}
    disabled={disabled}
    onClick={onClick}>
    {text}
  </ReusableButton>
)

export const DeleteButton = ({ className, onClick }) => {
  return (
    <DeleteContainer className={className} onClick={onClick}>
      {translations.general.delete}
    </DeleteContainer>
  )
}

const DeleteContainer = styled.span`
  margin-top: ${styles.spacings.xsmall};
  color: ${styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
  cursor: pointer;
`

export const DatePickerButton = styled(ReusableButton)`
  height: 32px;
  font-size: 12px;
  border-radius: 14px;
  font-weight: 500;
  width: 144px;
  margin: 4px;
  padding: 6px 10px;
  flex: initial;
  @media (max-width: 359px) {
    width: 120px;
  }
  &:hover {
    background-color: ${styles.colors.darkGrey};
    .button-label {
      color: white;
    }
  }
`
