import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { utils, styles } from 'gipsy-misc'

const propTypes = {
  id: PropTypes.any,
  checkedColor: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  paramName: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  checkmarkWidth: PropTypes.number.isRequired,
  checkmarkHeight: PropTypes.number.isRequired,
  checkmarkLeft: PropTypes.number.isRequired,
  checkmarkTop: PropTypes.number.isRequired,
  checkmarkBorderWidth: PropTypes.number,
  checkMarkColor: PropTypes.string,
  borderRadius: PropTypes.number,
  isHovered: PropTypes.bool,
  offsetTop: PropTypes.number,
  extraSpace: PropTypes.number /* extra clickable area around the checkbx */,
  hoverCheckMarkColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  hoverBorderColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
}

class Checkbox extends Component {
  static propTypes = propTypes

  static defaultProps = {
    size: 24,
    extraSpace: 0,
    offsetTop: 2,
    checkmarkWidth: 4,
    checkmarkHeight: 8,
    checkmarkBorderWidth: 3,
    borderWidth: 2,
    checkmarkLeft: 7,
    checkmarkTop: 3,
    borderRadius: 10,
  }

  /*
   ** We use handmade shouldComponentUpdate instead of PureComponent in order to ignore the onChange function
   ** it allows us to create a new function on every render in the parent : onChange={() => { doSomething() }}
   ** without rerendering the component everytime the parent is rendered (PureComponent have this side effect )
   */
  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onChange']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }
      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    return false
  }

  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange({
        id: this.props.id,
        paramName: this.props.paramName,
        value: (1 + this.props.checked) % 2,
      })
    }
  }

  render() {
    const {
      className,
      id,
      paramName,
      onChange,
      checked,
      size,
      borderRadius,
      extraSpace,
      offsetTop,
      checkmarkWidth,
      checkmarkHeight,
      checkmarkLeft,
      checkmarkTop,
      checkmarkBorderWidth,
      showBoxShadow,
      checkedColor,
      checkMarkColor,
      hoverCheckMarkColor,
      hoverBackgroundColor,
      hoverBorderColor,
      borderColor,
      borderWidth,
      isHovered,
      disableTransition,
      disabled,
      ...props
    } = this.props
    return (
      <Container
        checkedColor={checkedColor}
        size={size}
        disabled={disabled}
        isHovered={isHovered}
        extraSpace={extraSpace}
        offsetTop={offsetTop}
        className={'gp-checkbox-container' + (className ? ` ${className}` : '')}
        onClick={utils.DOM.stopPropagation}
        showBoxShadow={showBoxShadow}
        checkMarkColor={checkMarkColor}
        hoverCheckMarkColor={hoverCheckMarkColor}
        hoverBackgroundColor={hoverBackgroundColor}
        hoverBorderColor={hoverBorderColor}
        borderColor={borderColor}
        borderWidth={borderWidth}>
        <input type="checkbox" onChange={this.onChange} className={'gp-checkbox'} checked={checked} {...props} />
        <CheckMark
          size={size}
          disableTransition={disableTransition}
          checkMarkColor={checkMarkColor}
          isHovered={isHovered}
          borderRadius={borderRadius}
          extraSpace={extraSpace}
          width={checkmarkWidth}
          height={checkmarkHeight}
          left={checkmarkLeft}
          top={checkmarkTop}
          borderWidth={borderWidth}
          checkMarkBorderWidth={checkmarkBorderWidth}
          className="checkmark"
        />
      </Container>
    )
  }
}

export default Checkbox

const checkedStyles = css`
  background-color: ${(props) => props.checkedColor || styles.colors.greenColor};
  border: ${(props) => props.borderWidth}px solid ${(props) => props.checkedColor || styles.colors.greenColor};
  box-shadow: ${({ showBoxShadow, checkedBoxShadow }) =>
    showBoxShadow && `6px 6px 10px rgba(${checkedBoxShadow || '69,223,173,0.16'})`};
`

const Container = styled.label`
  position: relative;
  top: -${(props) => props.extraSpace - props.offsetTop}px;
  left: -${(props) => props.extraSpace}px;
  height: ${({ size, extraSpace }) => size + extraSpace * 2}px;
  width: ${({ size, extraSpace }) => size + extraSpace * 2}px;
  min-height: ${({ size, extraSpace }) => size + extraSpace * 2}px;
  min-width: ${({ size, extraSpace }) => size + extraSpace * 2}px;
  display: block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  input {
    position: absolute;
    opacity: 0;
  }

  & input:not(:checked) ~ .checkmark {
    background-color: ${(props) => (props.isHovered ? props.checkedColor || styles.colors.greenColor : 'white')};
    border: ${(props) => props.borderWidth}px solid
      ${(props) =>
        props.isHovered ? props.checkedColor || styles.colors.greenColor : props.borderColor || styles.colors.grey};
  }

  & input:checked ~ .checkmark,
  &:hover input:checked ~ .checkmark {
    ${checkedStyles}
  }
  & input:checked ~ .checkmark:after,
  &:hover input ~ .checkmark:after {
    opacity: 1;
  }

  &:hover input:not(:checked) ~ .checkmark {
    ${checkedStyles};
    background-color: ${(props) => props.hoverBackgroundColor || props.checkedColor || styles.colors.greenColor};
    border-color: ${(props) => props.hoverBorderColor || props.checkedColor || styles.colors.greenColor};
  }

  &:hover input:not(:checked) ~ .checkmark:after {
    border-color: ${(props) => props.hoverCheckMarkColor || props.checkMarkColor || 'white'};
  }
`

const CheckMark = styled.span`
  content: '';
  position: absolute;
  top: ${(props) => props.extraSpace}px;
  left: ${(props) => props.extraSpace}px;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: ${(props) => props.borderRadius}px;
  ${(props) =>
    !props.disableTransition &&
    css`
      transition: opacity 0.2s;
    `}

  &:after {
    flex-shrink: 0;
    content: '';
    position: absolute;
    opacity: ${(props) => (props.isHovered ? 1 : 0)};
    left: ${(props) => props.left}px;
    top: ${(props) => props.top}px;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    border: solid ${(props) => props.checkMarkColor || 'white'};
    border-width: ${(props) => `0 ${props.checkMarkBorderWidth}px ${props.checkMarkBorderWidth}px 0`};
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    ${(props) =>
      !props.disableTransition &&
      css`
        transition: opacity 0.2s;
      `}
  }
`
