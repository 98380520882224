import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { closePopup } from 'store/popup/actions'
import { PrimaryButton, BlurLayer } from 'gipsy-ui'
import { getSrc } from 'utils/image'
import { styles } from 'gipsy-misc'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      closePopup,
    },
    dispatch
  ),
})

const mapStateToProps = (state) => ({
  active: state.popup.active,
  title: state.popup.title,
  message: state.popup.message,
  button: state.popup.button,
  buttonClass: state.popup.buttonClass,
  action: state.popup.action,
  onClose: state.popup.onClose,
  component: state.popup.component,
  headerComponent: state.popup.headerComponent,
  logo: state.popup.logo,
  hideLogo: state.popup.hideLogo,
  hideTitle: state.popup.hideTitle,
  centeredTitle: state.popup.centeredTitle,
  contentMaxWidth: state.popup.contentMaxWidth,
  noPadding: state.popup.noPadding,
  unclosable: state.popup.unclosable,
})

class Popup extends PureComponent {
  doAction = () => {
    if (this.props.action && typeof this.props.action === 'function') {
      this.props.action()
    } else if (this.props.action && typeof this.props.action === 'string' && this[this.props.action]) {
      this[this.props.action]()
    }
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
    this.props.actions.closePopup()
  }

  render() {
    const {
      active,
      title,
      message,
      button,
      buttonClass,
      component,
      headerComponent,
      logo,
      hideLogo,
      hideTitle,
      centeredTitle,
      contentMaxWidth,
      noPadding,
      unclosable,
    } = this.props

    let logoSrc
    if (logo === 'sad') {
      logoSrc = getSrc('/images/gipsybot-sad.svg')
    } else if (logo === 'zen') {
      logoSrc = getSrc('/images/gipsybot-zen-filled.svg')
    } else if (logo === 'happy') {
      logoSrc = getSrc('/images/gipsybot-looking-at-you.svg')
    } else if (logo === 'angry') {
      logoSrc = getSrc('/images/gipsybot-angry.svg')
    } else {
      logoSrc = getSrc('/images/gipsybot-default.svg')
    }

    return (
      <StyledBlurLayer
        contentMaxWidth={contentMaxWidth}
        dataId='homebase-popup'
        name='popup'
        showLogo={!hideLogo}
        active={active}
        noPadding={noPadding}
        onClose={this.onClose}
        unclosable={unclosable}>
        <Row>{headerComponent}</Row>
        <Row>
          {!hideLogo && (
            <PopupLogo>
              <PopupImg src={logoSrc} alt='logo' />
            </PopupLogo>
          )}
          {!hideTitle && <PopupTitle centered={centeredTitle}>{title}</PopupTitle>}
          <PopupMessage>{message}</PopupMessage>

          {this.props.action && (
            <PopupAction>
              <PrimaryButton
                width={120}
                height={32}
                onClick={this.doAction}
                className={buttonClass}
                text={button}></PrimaryButton>
            </PopupAction>
          )}
          {component}
        </Row>
      </StyledBlurLayer>
    )
  }
}

const Row = styled.div``

const StyledBlurLayer = styled(BlurLayer)`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ::before {
    background-color: ${styles.colors.textMediumDarkColor} !important;
  }
  .popup-content {
    border-radius: 12px;
    margin: auto;
    border: none;
    box-shadow: 0 1px 1px rgba(33, 21, 81, 0.08);
    overflow: visible !important;
    opacity: 0;
    position: relative;
    width: 100%;
    background-color: white;
    max-width: ${({ contentMaxWidth }) => (contentMaxWidth ? contentMaxWidth : 404)}px;
    transition: opacity 0.4s !important;
    padding: ${(props) => (props.noPadding ? '0' : `${props.showLogo ? 54 : 27}px 27px 25px 26px`)};
    .popup-component {
      margin-top: 8px;
    }
  }
  &.active {
    .popup-content {
      opacity: 1;
    }
    ::before {
      opacity: 0.6 !important;
    }
  }
`

const PopupLogo = styled.div`
  transform: translateX(-50%);
  left: 50%;
  height: 90px;
  width: 90px;
  top: -45px;
  position: absolute;
`

const PopupImg = styled.img`
  height: 100%;
  width: 100%;
`

const PopupTitle = styled.div`
  color: ${styles.colors.textDarkColor};
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 18px;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`

const PopupMessage = styled.div`
  color: ${styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeSmall};
  line-height: 24px;
  font-weight: normal;
  text-align: center;
`

const PopupAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  button {
    &.ok {
      min-width: 100px;
    }
  }
`

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: true,
})(Popup)
