import { utils } from 'gipsy-misc'

import axios from 'utils/axios'

export function getAuthURL() {
  let endpoint = `/asana/authurl`
  return axios.get(endpoint)
}

export function getUserData(asanaUid) {
  let endpoint = `/asana/user-data`
  endpoint = utils.request.appendToUrl(endpoint, { asanaUid })
  return axios.get(endpoint)
}

export function selectProjects(data) {
  let endpoint = `/asana/user-data/select-projects`
  return axios.post(endpoint, data)
}

export function authenticate(state, code) {
  let endpoint = `/asana/authenticate`
  endpoint = utils.request.appendToUrl(endpoint, { state, code })
  return axios.get(endpoint)
}

export function sync(asanaUid) {
  let endpoint = '/asana/sync'
  endpoint = utils.request.appendToUrl(endpoint, { asanaUid })
  return axios.post(endpoint)
}

export function unsync(asanaUid) {
  let endpoint = '/asana/unsync'
  endpoint = utils.request.appendToUrl(endpoint, { asanaUid })
  return axios.post(endpoint)
}
