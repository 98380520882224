import axios from 'utils/axios'
import { utils } from 'gipsy-misc'

export const create = (body, context) => {
  let endpoint = `/sprints`
  const headers = utils.request.makeContextHeaders(context)
  return axios.post(endpoint, body, { headers })
}

export const editWithTasks = (body, { context, recurrenceOption } = {}) => {
  let endpoint = `/sprints/with-tasks`
  const headers = utils.request.makeContextHeaders(context)

  if (recurrenceOption) {
    endpoint = utils.request.appendToUrl(endpoint, { option: recurrenceOption })
  }

  return axios.put(endpoint, body, { headers })
}

export const getSprintWithTasks = (sprintId) => {
  let endpoint = `/sprints/with-tasks/sprint/${sprintId}`
  return axios.get(endpoint)
}

export const edit = (body, { context, recurrenceOption } = {}) => {
  let endpoint = `/sprints`
  const headers = utils.request.makeContextHeaders(context)

  if (recurrenceOption) {
    endpoint = utils.request.appendToUrl(endpoint, { option: recurrenceOption })
  }
  return axios.put(endpoint, body, { headers })
}

export const getWithTasks = (active, completedFrom, completedTo) => {
  let endpoint = `/sprints/with-tasks`
  if (active) {
    endpoint = utils.request.appendToUrl(endpoint, { active: true })
  }
  if (completedFrom && completedTo) {
    endpoint = utils.request.appendToUrl(endpoint, { completedFrom, completedTo })
  }
  return axios.get(endpoint)
}

export const getInProgress = () => {
  let endpoint = `/sprints/in-progress`
  return axios.get(endpoint)
}

export const del = (sprintId, recurrenceOption) => {
  let endpoint = `/sprints/${sprintId}`

  if (recurrenceOption) {
    endpoint = utils.request.appendToUrl(endpoint, { option: recurrenceOption })
  }

  return axios.delete(endpoint)
}

export const dragAndDropTasks = (body) => {
  let endpoint = `/sprints/with-tasks/drag-and-drop`
  return axios.post(endpoint, body)
}

export const end = (sprintId) => {
  const endpoint = `/sprints/end/${sprintId}`
  return axios.post(endpoint)
}
