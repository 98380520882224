import React, { PureComponent } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { projects as projectsApi } from 'gipsy-api'

export default class DragDropContextExtended extends PureComponent {
  componentDidMount() {
    this.fetchActiveProjects()
  }

  dragDropContextRef = React.createRef()
  fetchActiveProjects = async () => {
    try {
      const activeProjects = (await projectsApi.get(projectsApi.activeParam)) || []
      this.setState({ activeProjects })
    } catch (err) {
      console.error(err)
    }
  }

  observer = null

  state = {
    draggedItem: null,
    activeProjects: [],
  }

  resetDraggedItem = (from) => {
    const { draggedItem } = this.state
    if (draggedItem.attributesFromDragging) {
      delete draggedItem[draggedItem.attributesFromDragging.name]
    }
    delete draggedItem.attributesFromDragging
    this.setState(
      {
        draggedItem: draggedItem,
      },
      () =>
        this.props.updatesFromDragging({
          draggedItem: this.state.draggedItem,
          from,
        })
    )
  }

  hoverOverUpdate = (id, type) => {
    if (type === 'project') {
      let selectedProject = {
        id: '',
      }
      if (id) {
        selectedProject = this.state.activeProjects.find((el) => el.id === id)
      }

      this.setState(
        {
          draggedItem: {
            ...this.state.draggedItem,
            project: selectedProject,
            attributesFromDragging: {
              name: 'projects',
              value: selectedProject,
            },
          },
        },
        () =>
          this.props.updatesFromDragging({
            draggedItem: this.state.draggedItem,
          })
      )
    } else if (type === null && id === null) {
      this.resetDraggedItem()
    }
  }

  findIfOverDraggable = (x, y) => {
    let node = document.elementFromPoint(x, y)
    if (!node) {
      return false
    }
    while (node.parentNode) {
      if (node.getAttribute('dragable-over-id') || node.getAttribute('dragable-over-type')) {
        return node
      } else {
        node = node.parentNode
      }
    }
  }

  findIfOverDraggableTitle = (x, y) => {
    let node = document.elementFromPoint(x, y)
    if (!node) {
      return false
    }
    while (node.parentNode) {
      if (node.dataset.draggableTitleType) {
        return node.dataset.draggableTitleType
      } else {
        node = node.parentNode
      }
    }
  }

  onDragStart = (dragableItem) => {
    const { items, onDragStart } = this.props
    if (this.dragDropContextRef.current) {
      const targetNode = this.dragDropContextRef.current.querySelector(
        `[data-rbd-draggable-id="${dragableItem.draggableId}"]`
      )
      var observerOptions = {
        childList: false,
        attributes: true,
        subtree: false,
      }
      this.observer = new MutationObserver((record) => {
        if (record[0] && record[0].target) {
          const coordinates = record[0].target.getBoundingClientRect()
          const overElement = this.findIfOverDraggable(coordinates.x, coordinates.y + coordinates.height / 2.15)
          const overTitle = this.findIfOverDraggableTitle(coordinates.x, coordinates.y + coordinates.height / 2.15)
          if (overTitle) {
            const dragOverGroupTitleEvent = new CustomEvent('dragOverGroupTitle', { detail: overTitle })
            window.dispatchEvent(dragOverGroupTitleEvent)
          }
          if (overElement) {
            this.hoverOverUpdate(
              overElement.getAttribute('dragable-over-id'),
              overElement.getAttribute('dragable-over-type')
            )
          } else {
            this.hoverOverUpdate(null, null)
          }
        }
      })
      this.observer.observe(targetNode, observerOptions)
    }
    if (onDragStart) onDragStart(dragableItem)
    this.setState(
      {
        draggedItem: items.find((el) => el && el.id === dragableItem.draggableId),
      },
      () =>
        this.props.updatesFromDragging({
          draggedItem: this.state.draggedItem,
        })
    )
  }

  onDragEnd = (dragableItem) => {
    const { items, onDrop, onDropOnDraggableOver, onDragEnd } = this.props
    const { draggedItem } = this.state
    this.observer.disconnect()
    if (onDragEnd) onDragEnd(dragableItem)
    if (draggedItem && draggedItem.attributesFromDragging && draggedItem.attributesFromDragging.name) {
      onDropOnDraggableOver(draggedItem.id, {
        paramName: draggedItem.attributesFromDragging.name,
        value: draggedItem.attributesFromDragging.value,
      })
    } else {
      onDrop &&
        onDrop({
          ...dragableItem,
          item: items.find((el) => el.id === dragableItem.draggableId),
        })
    }
    this.resetDraggedItem('dragEnd')
  }

  render() {
    return (
      <div ref={this.dragDropContextRef}>
        <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
          {this.props.children}
        </DragDropContext>
      </div>
    )
  }
}
