import React, { useState, useCallback, useRef } from 'react'
import {
  Project,
  ProjectColor,
  ProjectDropdown,
  ProjectDropdownPopup,
  ProjectDropdownPopupContainer,
  ProjectName,
} from 'LineView/components/commonUIComponents'
import { ProjectAttributeIcon, ProjectCaretIcon } from './components'
import { translations } from 'gipsy-misc'
import { CustomPopup } from 'Popup/components'

function ProjectsAttribute(props) {
  const { projects, alignRight, stopPropagationOnClick } = props
  let { projectAmountToCollapse = 4 } = props
  const [isProjectDropdownShown, setIsProjectDropdownShown] = useState(false)
  const projectDropdownRef = useRef(null)

  const handleLinkClickMultipleProjects = useCallback(
    (e) => {
      if (stopPropagationOnClick) {
        e.stopPropagation()
      }
      setIsProjectDropdownShown((prev) => !prev)
    },
    [stopPropagationOnClick]
  )

  const onClickOutside = useCallback(
    (e) => {
      if (projectDropdownRef?.current && !projectDropdownRef.current?.contains(e.target)) {
        setIsProjectDropdownShown(false)
      }
    },
    [projectDropdownRef]
  )

  if (!projects) return null

  if (projects.length < projectAmountToCollapse) {
    return projects.map((project) => (
      <Project key={project.id} title={project.name}>
        <ProjectName maxWidth={projects.length > 1 ? 32 : 0}>{project.name}</ProjectName>
        <ProjectColor color={project.color} />
      </Project>
    ))
  } else {
    return (
      <ProjectDropdown onClick={handleLinkClickMultipleProjects} ref={projectDropdownRef}>
        <ProjectAttributeIcon size={12} icon={'Folder'} />
        <ProjectName>{`${projects.length} ${translations.general.projects}`}</ProjectName>
        <ProjectCaretIcon size={7.5} icon={`${isProjectDropdownShown ? 'SingleChevronDown' : 'SingleChevronLeft'}`} />
        {isProjectDropdownShown && (
          <CustomPopup
            alignRight={alignRight}
            ContainerComponent={ProjectDropdownPopup}
            onClickOutside={onClickOutside}>
            <ProjectDropdownPopupContainer>
              {projects.map((project) => (
                <Project key={project.id} title={project.name}>
                  <ProjectName>{project.name}</ProjectName>
                  <ProjectColor color={project.color} />
                </Project>
              ))}
            </ProjectDropdownPopupContainer>
          </CustomPopup>
        )}
      </ProjectDropdown>
    )
  }
}

export default React.memo(ProjectsAttribute)
