import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Icon, SecondaryButton, Separator } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'
import { user } from 'gipsy-api'

import { SyncingLoading } from 'features/integrations/components'
import { PageContainer, PageBody, TitleContainer, NavigationTitle } from 'features/layout/pages'

import AccountDeletePanel from './components/accountDeletePanel'
import ConfirmDeletePrompt from './components/confirmDeletePrompt'
import CustomExtensionId from './components/customextensionid'
import DeleteErrorPrompt from './components/deleteErrorPrompt'
import EmailNotifications from './components/emailnotifications'
import container from './container'

export class Settings extends PureComponent {
  showDeleteLoading = () => {
    this.props.openPopup({
      centeredTitle: true,
      contentMaxWidth: 345,
      component: <SyncingLoading text={translations.settings.section.deletingAccount} />,
      hideLogo: true,
      noPadding: true,
      unclosable: true,
    })
  }

  onDelete = async (params) => {
    this.showDeleteLoading()

    try {
      await user.del(params)
      this.props.closePopup()
      this.props.onLogout(true)
    } catch (err) {
      this.props.openPopup({
        centeredTitle: true,
        contentMaxWidth: 500,
        hideTitle: true,
        logo: 'angry',
        component: <DeleteErrorPrompt />,
      })
    }
  }

  showConfirmPrompt = (params) => {
    this.props.openPopup({
      centeredTitle: true,
      contentMaxWidth: 553,
      hideTitle: true,
      logo: 'sad',
      component: <ConfirmDeletePrompt onCancel={this.props.closePopup} onConfirm={() => this.onDelete(params)} />,
    })
  }

  onClickDelete = () => {
    const onContinue = (params) => {
      this.showConfirmPrompt(params)
    }

    this.props.openPopup({
      centeredTitle: true,
      contentMaxWidth: 635,
      hideTitle: true,
      logo: 'sad',
      noPadding: true,
      component: <AccountDeletePanel onContinue={onContinue} onDismiss={this.props.closePopup} />,
    })
  }
  render() {
    const { user, onLogout, handleAPIError } = this.props
    return (
      <PageContainer>
        <PageBody>
          <TitleContainer>
            <NavigationTitle>{translations.settings.title}</NavigationTitle>
          </TitleContainer>
          <ComponentsContainer>
            <CustomExtensionId user={user} />
            <EmailNotifications handleAPIError={handleAPIError} user={user} />
            <ButtonSeparator />
            <ButtonContainer>
              <SecondaryButton
                onClick={onLogout}
                height={32}
                width={110}
                text={translations.settings.section.logout.title}
              />
              <DeleteContainer onClick={this.onClickDelete}>
                <Icon icon='Trash' size={14} />
                <DeleteTitleContainer>{translations.settings.section.delete.title}</DeleteTitleContainer>
              </DeleteContainer>
            </ButtonContainer>
          </ComponentsContainer>
        </PageBody>
      </PageContainer>
    )
  }
}

const ButtonSeparator = styled(Separator)`
  margin-bottom: 60px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DeleteContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  color: ${styles.colors.darkGrey};
  cursor: pointer;
`

const DeleteTitleContainer = styled.span`
  margin-left: 10px;
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 14px;
`

const ComponentsContainer = styled.div`
  margin-top: 40px;
`

export default container(Settings)
