import styled from 'styled-components'

const PopupItemContent = styled.div`
  border-radius: 6px;
  padding: 12px 10px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  transition: background 200ms ease;
`

export default PopupItemContent
