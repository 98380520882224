/* eslint-disable no-throw-literal */
import { createAction } from 'redux-actions'
import { types } from 'store/tag/types'
import { findInListById } from 'logic/list'

const _removeTag = createAction(types.REMOVE_TAG)
const _updateTag = createAction(types.UPDATE_TAG)
const _reorderTag = createAction(types.REORDER_TAG)
const _addTag = createAction(types.ADD_TAG)
const _fetchTags = createAction(types.FETCH_TAGS)
const _setTagsLoaded = createAction(types.SET_TAGS_LOADED)

/* ************************************************************* */
/* ****************** Actions - Async Request ****************** */
/* ************************************************************* */
export const addTag = (tag, immediate) => async (dispatch, getState, { api }) => {
  const tempKey = tag.id
  dispatch(_addTag({ tag }))
  const _tag = await api.tags.create(tag)
  dispatch(_updateTag({ tag: _tag, tagId: tempKey }))
}

export const updateTag = (tag) => async (dispatch, getState, { api }) => {
  dispatch(_updateTag({ tag: tag, tagId: tag.id }))
  const _tag = await api.tags.edit(tag)
  dispatch(_updateTag({ tag: _tag, tagId: tag.id }))
}

export const removeTag = (tagId) => async (dispatch, getState, { api }) => {
  dispatch(_removeTag({ tagId }))
  await api.tags.del(tagId)
}

export const dragAndDropTag = ({ draggableId, source, destination }) => async (dispatch, getState, { api }) => {
  const droppedTag = findInListById(getState().tags.items, draggableId)
  dispatch(_reorderTag({ tag: droppedTag, from: source.index, to: destination.index }))
  await api.tags.dragAndDropTag(draggableId, destination.index + 1)
}

export const fetchActiveTags = () => async (dispatch, getState, { api }) => {
  const tags = await api.tags.get(api.tags.activeParam)
  dispatch(_fetchTags({ tags }))
  dispatch(_setTagsLoaded(true))
}
