import React, { memo } from 'react'

import { withRouter } from 'react-router-dom'
import ActiveTaskPageContainer from 'features/task/components/activeTasksPageContainer'

export default function wrapper(Component) {
  const UncategorizedPageContainer = () => {
    return (
      <ActiveTaskPageContainer>{({ ...taskPageProps }) => <Component {...taskPageProps} />}</ActiveTaskPageContainer>
    )
  }

  return withRouter(memo(UncategorizedPageContainer))
}
