import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'

export const TABS = {
  SESSIONS: 'SESSIONS',
  TASKS: 'TASKS',
}

function ReportsHeader({ activeTab = TABS.SESSIONS, onTabClick, totalTimeRef }) {
  const handleTabClick = (tab) => {
    onTabClick?.(tab)
  }

  return (
    <Container>
      <Tabs>
        <Tab active={activeTab === TABS.TASKS} onClick={() => handleTabClick(TABS.TASKS)}>
          {translations.timeReports.tasksTab}
        </Tab>
        <Tab active={activeTab === TABS.SESSIONS} onClick={() => handleTabClick(TABS.SESSIONS)}>
          {translations.timeReports.sessionsTab}
        </Tab>
      </Tabs>
      <TimeStats>
        <TimeTitle>{translations.timeReports.totalTime}</TimeTitle>
        <TimeAmount>{totalTimeRef?.current}</TimeAmount>
      </TimeStats>
    </Container>
  )
}

ReportsHeader.propTypes = {
  activeTab: PropTypes.oneOf(Object.keys(TABS)),
  onTabClick: PropTypes.func,
  totalTimeRef: PropTypes.shape({ current: PropTypes.string }),
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 38px;
  padding: 40px 0 30px;
`

const Tabs = styled.div`
  align-items: flex-end;
  display: flex;
`

const Tab = styled.div`
  cursor: pointer;
  font-size: ${styles.fonts.fontSizeSmall};
  margin-right: 30px;
  position: relative;

  &::after {
    background-color: ${styles.colors.primaryColor};
    content: '';
    display: none;
    height: 1px;
    left: 0;
    position: absolute;
    top: calc(100% + 28px);
    width: 100%;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${styles.colors.primaryColor};

      &::after {
        display: block;
      }
    `}
`

const TimeStats = styled.div`
  display: flex;
  margin-left: auto;
`

const TimeTitle = styled.div`
  align-self: flex-end;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 48px;
`

const TimeAmount = styled.span`
  color: ${styles.colors.primaryColor};
  font-size: 23px;
  font-weight: 600;
`

export default ReportsHeader
