import React from 'react'
import styled from 'styled-components'
import Icon from 'Icon'

import { styles } from 'gipsy-misc'

const iconSizeRatio = 2.5
const playIconWidthRatio = 8 / 7

export default function StartButton(props) {
  const { playIconBackground, fillColor, size = 20 } = props
  const iconHeight = size / iconSizeRatio
  const iconWidth = iconHeight / playIconWidthRatio
  const iconMarginLeft = iconHeight - iconWidth
  return (
    <IconContainer
      padding={(size - iconHeight) / 2}
      paddingLeft={iconMarginLeft}
      size={size}
      playIconBackground={playIconBackground}>
      <PlayIcon width={iconWidth} height={iconHeight} size={iconHeight} icon={'CaretRight'} fill={fillColor} />
    </IconContainer>
  )
}

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background: ${(props) => props.playIconBackground || styles.colors.veryLightGrey};
  border-radius: 50%;
  padding: ${(props) =>
    `${props.padding}px ${props.padding}px ${props.padding}px ${props.padding + props.paddingLeft}px`};
`

export const PlayIcon = styled(Icon)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`
