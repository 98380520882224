import React, { useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import debounce from 'lodash/debounce'

import { clearOauthDataFromSessionStorage } from 'logic/auth'
import { handleRevalidateOAuthCallback } from 'logic/incrementalCallback'
import { showSnackbar } from 'store/snackbar/actions'
import { getAccounts } from 'store/accounts/actions'
import { handleAPIError } from 'store/app/actions'
import { getGoogleAuthUrlAndStoreState, getMicrosoftAuthUrlAndStoreState } from 'logic/integrationOauth'

import { styles, translations } from 'gipsy-misc'
import { SimpleButton, Alert } from 'gipsy-ui'

const CredentialsAlert = (props) => {
  const invalidCreds = useMemo(() => {
    let invalidCredentials = []
    props.accounts?.forEach((account) => {
      const { missingScopes, areCredentialsInvalid, provider, email } = account
      const hasMissingScopes = missingScopes && missingScopes.length > 0
      if (hasMissingScopes || areCredentialsInvalid) {
        invalidCredentials.push({
          email,
          missingScopes,
          areCredentialsInvalid,
          provider,
        })
      }
    })
    return invalidCredentials
  }, [props.accounts])
  const windowObjectReference = useRef(null)
  const previousUrl = useRef(null)
  const isLoading = useRef(false)

  const onClickGrantAccessButton = debounce(async () => {
    if (windowObjectReference.current !== null || isLoading.current) return
    isLoading.current = true
    const redirectURL = window.location.origin + '/revalidateoauthcallback'
    const email = invalidCreds?.[0].email
    const provider = invalidCreds?.[0].provider
    if (provider.toLowerCase() === 'google') {
      const authURL = await getGoogleAuthUrlAndStoreState(true, redirectURL, email)
      isLoading.current = false
      openSignInWindow(authURL, 'revalidateoauthcallback', email, provider)
    } else if (provider.toLowerCase() === 'microsoft') {
      const authURL = await getMicrosoftAuthUrlAndStoreState(true, redirectURL, email)
      isLoading.current = false
      openSignInWindow(authURL, 'revalidateoauthcallback', email, provider)
    }
  }, 500)

  const openSignInWindow = (url, name, email, provider) => {
    const receiveMessage = async (event) => {
      if (event.origin !== process.env.REACT_APP_FRONTEND_URL) {
        return
      }
      const {
        data,
        data: { searchParams },
      } = event
      if (!searchParams || !searchParams.code) return
      if (searchParams.error) {
        return props.actions.showSnackbar(
          {
            message: 'Unexpected Error',
            status: 'error',
          },
          5000
        )
      }
      if (data.source === 'revalidate-oauth') {
        try {
          await handleRevalidateOAuthCallback(searchParams)
          props.actions.getAccounts()
        } catch (err) {
          props.actions.handleAPIError(err)
        } finally {
          if (windowObjectReference.current) {
            windowObjectReference.current.close()
          }
        }
      }
    }
    const width = 700
    const height = 800
    var left = window.screenLeft + window.outerWidth / 2 - width / 2
    var top = window.screenTop + window.outerHeight / 2 - height / 2
    const strWindowFeatures = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top} left=${left}`

    if (windowObjectReference.current === null || windowObjectReference.current.closed) {
      windowObjectReference.current = window.open(url, name, strWindowFeatures)
    } else if (previousUrl.current !== url) {
      windowObjectReference.current = window.open(url, name, strWindowFeatures)
      windowObjectReference.current.focus()
    } else {
      windowObjectReference.current.focus()
    }
    const parentWindow = window

    var listener = setInterval(function () {
      if (windowObjectReference.current?.closed) {
        window.removeEventListener('message', receiveMessage)
        clearInterval(listener)
        clearOauthDataFromSessionStorage(parentWindow)
        windowObjectReference.current = null
      }
    }, 300)

    window.addEventListener('message', receiveMessage, false)
    previousUrl.current = url
  }

  return (
    <Alert
      show={invalidCreds && invalidCreds.length > 0}
      spacedIcon
      iconSize={14}
      padding={12}
      mode={Alert.mode.ERROR}
      className={props.className}
      text={translations.auth.invalidCredentials}
      actionButton={
        <SimpleButton
          disabled={isLoading.current}
          onClick={onClickGrantAccessButton}
          height={26}
          backgroundColor={styles.colors.primaryColor}
          textColor={'white'}
          text={translations.auth.grantAccess}
        />
      }
    />
  )
}

const mapStateToProps = (state) => ({ accounts: state.accounts })
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      handleAPIError,
      showSnackbar,
      getAccounts,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CredentialsAlert)
