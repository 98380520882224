import React from 'react'
import styled from 'styled-components'
import parser from 'react-html-parser'

import { translations } from 'gipsy-misc'

import breakpoints, { verticalBreakpoints } from 'features/layout/breakpoints'
import { getSrc } from 'utils/image'

export default function UsedBy() {
  return (
    <Container className='UsedBy'>
      <Title className='UsedBy__title'>{parser(translations.timeBlockingExp.usedBy.title)}</Title>
      <Logos>
        <Logo
          alt='Chili Piper'
          className='UsedBy__logo--chilipiper'
          src={getSrc('/images/time-blocking-exp/chilipiper.png')}
        />
        <Logo alt='Zapier' className='UsedBy__logo--zapier' src={getSrc('/images/time-blocking-exp/zapier.png')} />
        <Logo
          alt='Atlassian'
          className='UsedBy__logo--atlassian'
          src={getSrc('/images/time-blocking-exp/atlassian.png')}
        />
        <Logo
          alt='BlaBlaCar'
          className='UsedBy__logo--blablacar'
          src={getSrc('/images/time-blocking-exp/blablacar.png')}
        />
        <Logo alt='Adobe' className='UsedBy__logo--adobe' src={getSrc('/images/time-blocking-exp/adobe.png')} />
      </Logos>
    </Container>
  )
}

const Container = styled.section`
  background-color: white;
  display: flex;
  flex-flow: column;
  height: 19%;
  justify-content: center;
  padding: 0px 20px;

  .UsedBy {
    &__logo--chilipiper {
      height: 53px;
      margin-right: 45px;
      width: 59px;
    }

    &__logo--zapier {
      height: 27px;
      margin-right: 38px;
      width: 54px;
    }

    &__logo--atlassian {
      height: 58px;
      margin-right: 38px;
      top: 20px;
      width: 102px;
    }

    &__logo--blablacar {
      height: 17px;
      margin-right: 38px;
      top: -6px;
      width: 82px;
    }

    &__logo--adobe {
      height: 41px;
      top: 8px;
      width: 70px;
    }
  }

  @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
    height: 21%;
    padding: 20px 20px 10px;
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    min-height: 210px;
    padding: 0 20px;

    .UsedBy {
      &__logo--chilipiper {
        height: 70px;
        margin-right: 30px;
        width: 88px;
      }

      &__logo--zapier {
        height: 30px;
        margin-right: 15px;
        width: 77px;
      }

      &__logo--atlassian {
        height: 81px;
        margin-right: 15px;
        top: 29px;
        width: 155px;
      }

      &__logo--blablacar {
        height: 29px;
        margin-right: 31px;
        top: -6px;
        width: 132px;
      }

      &__logo--adobe {
        height: 56px;
        top: 11px;
        width: 102px;
      }
    }

    & h2.UsedBy__title {
      font-size: 22px;
    }
  }
`

Container.displayName = 'Container'

const Title = styled.h2`
  &.UsedBy__title {
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    margin: 0 auto;
    text-align: center;
    text-transform: unset;
    width: auto;

    strong {
      font-weight: 500;
    }
  }
`

Title.displayName = 'Title'

const Logos = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

Logos.displayName = 'Logos'

const Logo = styled.img`
  object-fit: contain;
  position: relative;
`

Logo.displayName = 'Logo'
