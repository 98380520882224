import React, { useCallback, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { Droppable } from 'react-beautiful-dnd'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { styles, translations, utils } from 'gipsy-misc'

import { SubGroupTitleSeparator } from 'features/list/components/commonUI'

import { sections } from './DragDropContext'
import TaskPanelItem from './TaskPanelItem'

const getSectionDroppableId = ({ date, section }) => JSON.stringify({ date, section, type: 'section' })

function ItemsGroup({
  addTaskToSprintComposer,
  date,
  draggablesTopOffset,
  hideCompleteCheckbox,
  onDelete,
  onComplete,
  onSave,
  onTitleClick,
  onTogglePin,
  otherItems,
  pinnedItems,
  onCreateInlineTaskFromSprint,
}) {
  const [arePinnedItemsExpanded, setArePinnedItemsExpanded] = useState(true)
  const [areOtherItemsExpanded, setAreOtherItemsExpanded] = useState(true)

  const togglePinnedItemsExpanded = useCallback(() => setArePinnedItemsExpanded((expanded) => !expanded), [])
  const toggleOtherItemsExpanded = useCallback(() => setAreOtherItemsExpanded((expanded) => !expanded), [])

  const isPast = date && moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')

  const handleTitleClick = () => {
    onTitleClick?.()
  }

  const renderTaskPanelItem = (item, index) => (
    <TaskPanelItem
      addTaskToSprintComposer={addTaskToSprintComposer}
      draggablesTopOffset={draggablesTopOffset}
      hideCompleteCheckbox={hideCompleteCheckbox}
      index={index}
      item={item}
      key={item.id}
      onDelete={onDelete}
      onComplete={onComplete}
      onCreateInlineTaskFromSprint={onCreateInlineTaskFromSprint}
      onSave={onSave}
      onTogglePin={onTogglePin}
    />
  )

  return (
    <Container hasExpandables={!!date}>
      <TitleWrapper>
        <Title clickable={!!onTitleClick} isPast={isPast} onClick={handleTitleClick}>
          {date ? utils.datetime.humanizeDate(date, 'ddd, MMM DD') : translations.general.noDate}
        </Title>
      </TitleWrapper>
      {date ? (
        <>
          {pinnedItems?.length ? (
            <>
              <SubGroupTitleContainer>
                <SubGroupTitleSeparator
                  lateralPadding={0}
                  marginBottom={8}
                  onClick={togglePinnedItemsExpanded}
                  title={translations.todayView.pinnedInCalendar}
                  isExpanded={arePinnedItemsExpanded}
                />
              </SubGroupTitleContainer>
              <AnimateHeight duration={300} height={arePinnedItemsExpanded ? 'auto' : 0}>
                <Droppable droppableId={getSectionDroppableId({ date, section: sections.PINNED })} isDropDisabled>
                  {(droppableProvided) => (
                    <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                      {pinnedItems.map((item, index) => renderTaskPanelItem(item, index))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </AnimateHeight>
            </>
          ) : null}
          {otherItems?.length ? (
            <>
              <SubGroupTitleContainer>
                <SubGroupTitleSeparator
                  lateralPadding={0}
                  marginBottom={8}
                  onClick={toggleOtherItemsExpanded}
                  title={translations.todayView.other}
                  isExpanded={areOtherItemsExpanded}
                />
              </SubGroupTitleContainer>
              <AnimateHeight duration={300} height={areOtherItemsExpanded ? 'auto' : 0}>
                <Droppable droppableId={getSectionDroppableId({ date, section: sections.OTHER })}>
                  {(droppableProvided) => (
                    <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                      {otherItems.map((item, index) => renderTaskPanelItem(item, index))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </AnimateHeight>
            </>
          ) : null}
        </>
      ) : (
        <Droppable droppableId={getSectionDroppableId({ date, section: sections.BACKLOG })}>
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              {otherItems.map((item, index) => renderTaskPanelItem(item, index))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </Container>
  )
}

const Title = styled.p`
  color: ${({ isPast }) => (isPast ? styles.colors.pinkColor : styles.colors.blueColor)};
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0 10px 9px;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`

const Container = styled.div`
  ${({ hasExpandables }) =>
    !hasExpandables &&
    css`
      ${Title} {
        margin-bottom: 16px;
      }
    `}
`

const TitleWrapper = styled.div`
  display: flex;
`

const SubGroupTitleContainer = styled.div`
  margin-left: 9px;
`

export default ItemsGroup
