import { types } from 'store/integrations/types'

const initialState = null

function integrationsDataReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_INTEGRATIONS_DATA: {
      return action.payload
    }

    default: {
      return state
    }
  }
}

export default integrationsDataReducer
