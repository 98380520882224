export const isMobile = () => {
  return window.innerWidth < 600
}

export const getWindowSize = () => {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0]

  const width = w.innerWidth || e.clientWidth || g.clientWidth
  const height = w.innerHeight || e.clientHeight || g.clientHeight

  return { width, height }
}
