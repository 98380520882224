import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import 'moment-timezone'
import './runtime'
import App from 'app.js'
import { unregister } from 'registerServiceWorker'

// change favicon depending on environment
document.querySelector("link[rel*='icon']").href = process.env.REACT_APP_FAVICON

if (process.env.REACT_APP_ELECTRON === 'true') {
  ReactDOM.render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.getElementById('root')
  )
} else {
  ReactDOM.render(
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>,
    document.getElementById('root')
  )
}

unregister()
