import React, { PureComponent } from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import container from './container'
import NotFound from 'pages/notFound'
import {
  PageContainer,
  PageBody,
  innerLeftPadding,
  innerRightPadding,
  innerNestedLeftPadding,
  innerNestedRightPadding,
} from 'features/layout/pages'
import TodaySection from './today'

import { translations, utils } from 'gipsy-misc'

export class TodayPage extends PureComponent {
  state = {
    usedPastSprints: {},
  }

  componentName = 'TodayPage'
  dragItems = React.createRef([])

  componentDidMount() {
    const { pushShortcutsGroup, sprintComposerProps } = this.props
    pushShortcutsGroup(
      [
        {
          key: 'q',
          label: translations.calendar.createTask,
          callback: (e) => {
            this.props.startInlineTaskCreation()
          },
        },
        {
          key: 'b',
          label: translations.calendar.createSprint,
          callback: (e) => {
            sprintComposerProps.startSprintCreation()
          },
        },
      ],
      this.componentName
    )
  }

  componentWillUnmount() {
    this.props.popShortcutsGroup(this.componentName)
    clearTimeout(this.timeout)
  }

  onCreatePageTask = (task, _, { eventName }) => {
    this.props.clearLocalTaskState({ keepCreatingTasks: eventName !== utils.task.clickOutside })
    this.props.onCreateInlineTask(task)
  }

  onSavePageTask = (task) => {
    this.props.clearLocalTaskState()
    this.props.onSave(task)
  }

  onClickDeleteFocusSession = ({ focusSession }) => {
    this.props.setIgnoreOutsideClicks(true)
    const callback = () => {
      this.props.setIgnoreOutsideClicks(false)
    }
    this.props.onClickDeleteFocusSession({ focusSession, callback })
  }

  onStartFsAndCreate = (taskData, componentSource) => {
    this.props.startFsAndCreateTask(taskData, componentSource)
    this.props.clearLocalTaskState()
  }

  getFocusSessionPopupProps = () => ({
    onDeleteFocusSession: this.onClickDeleteFocusSession,
    onClickFocusSession: this.props.onClickFocusSession,
    onUpdateFocusSession: this.props.onUpdateFocusSession,
    setHighlightedEventId: this.props.setHighlightedEventId,
  })

  getIgnoreOutsideClicks = () => this.props.ignoreOutsideClicks || this.props.sprintComposerProps.ignoreOutsideClicks

  getRegularTaskProps = ({ item, canScheduleToToday, canMoveToTopToday }) => {
    const { editingTask, showCalendar } = this.props
    const isEditingTask = editingTask && item.id === editingTask.id
    const isItemPast = item.when?.date < moment().format('YYYY-MM-DD')
    const focusSessionPopupProps = this.getFocusSessionPopupProps()
    const ignoreOutsideClicks = this.getIgnoreOutsideClicks()
    const itemData = isEditingTask ? editingTask : item

    return {
      hideWhenDate: true,
      showCalendarEventInfo: isItemPast,
      canBlockToCalendar: true,
      isCalendarDraggable: true,
      startSprintCreation: this.props.sprintComposerProps.startSprintCreation,
      ignoreOutsideClicks,
      innerLeftPadding,
      innerRightPadding,
      item: itemData,
      key: item.id,
      startEdition: isEditingTask,
      onCancelEditTitle: isEditingTask && item.title,
      onEditStart: this.props.onTaskEditStart,
      onTogglePin: showCalendar ? this.props.onTogglePin : undefined,
      onCancel: this.props.cancelTaskAction,
      onScheduleToToday: canScheduleToToday ? this.props.onScheduleToToday : undefined,
      onMoveToTop: canMoveToTopToday ? this.props.onMoveToTopTodaySection : undefined,
      animateComplete: true,
      keepJustCompleted: true,
      isDraggable: true,
      onSave: this.onSavePageTask,
      onComplete: this.props.onComplete,
      onDelete: this.props.onClickDelete,
      updateTaskInState: this.props.updateTaskInState,
      onCreateSprint: this.props.sprintComposerProps.onCreateSprint,
      pin: itemData?.pin,
      setHighlightedEventId: this.props.setHighlightedEventId,
      ...focusSessionPopupProps,
    }
  }

  getCompletedTaskProps = ({ item }) => {
    const { editingTask, showCalendar } = this.props
    const isEditingTask = editingTask && item.id === editingTask.id
    const ignoreOutsideClicks = this.getIgnoreOutsideClicks()
    const focusSessionPopupProps = this.getFocusSessionPopupProps()
    const itemData = isEditingTask ? editingTask : item

    return {
      hideMiddleRow: true,
      ignoreOutsideClicks,
      innerLeftPadding,
      innerRightPadding,
      item: itemData,
      isArchivable: true,
      key: item.id,
      lineThrough: true,
      onArchive: this.props.onArchive,
      onCancelEdit: this.props.cancelTaskAction,
      onComplete: this.props.onComplete,
      onDelete: this.props.onClickDelete,
      onSave: this.onSavePageTask,
      onTogglePin: showCalendar ? this.props.onTogglePin : undefined,
      pin: itemData?.pin,
      ...focusSessionPopupProps,
    }
  }

  getPinnedTaskProps = ({ item }) => {
    const { editingTask, showCalendar } = this.props
    const isEditingTask = editingTask && item.id === editingTask.id
    const ignoreOutsideClicks = this.getIgnoreOutsideClicks()
    const focusSessionPopupProps = this.getFocusSessionPopupProps()
    const itemData = isEditingTask ? editingTask : item

    return {
      item: itemData,
      animateComplete: true,
      keepJustCompleted: true,
      hideWhenDate: true,
      startSprintCreation: this.props.sprintComposerProps.startSprintCreation,
      onSave: this.onSavePageTask,
      onComplete: this.props.onComplete,
      updateTaskInState: this.props.updateTaskInState,
      onDelete: this.props.onClickDelete,
      onEditStart: this.props.onTaskEditStart,
      innerLeftPadding,
      innerRightPadding,
      showCalendarEventInfo: true,
      ignoreOutsideClicks,
      startEdition: isEditingTask,
      onCancelEditTitle: isEditingTask && item.title,
      onTogglePin: showCalendar ? this.props.onTogglePin : undefined,
      onCancel: this.props.cancelTaskAction,
      onCancelEdit: this.props.cancelTaskAction,
      pin: itemData?.pin,
      canBlockToCalendar: true,
      setHighlightedEventId: this.props.setHighlightedEventId,
      isCalendarDraggable: true,
      isDraggable: true,
      ...focusSessionPopupProps,
    }
  }

  render() {
    const {
      allItems,
      creatingTask,
      session,
      onSave,
      updateTaskInState,
      onComplete,
      onCompleteFromFS,
      onArchive,
      onClickDelete,
      onCreateInlineTaskFromSprint,
      onMoveToTopTodaySection,
      onArchiveAllCompletedToday,
      onDrop,
      onDragStart,
      onDragEnd,
      isDragging,
      onDropOnDraggableOver,
      filterBy,
      onChangeFilterBy,
      toHideTaskId,
      onClickDeleteSprint,
      onEndSprint,
      inProgressSprints,
      onClickDateHeader,
      onClickSprint,
      onClickOutsideSprint,
      sprintComposerProps,
      setHighlightedEventId,
      showCalendar,
      onRemoveFromSprint,
      draggingData,
      computeDroppableId,
      computeSprintsDroppableId,
      isTaskCreationAlertShown,
      startFsAndCreateTask,
    } = this.props

    const focusSessionPopupProps = this.getFocusSessionPopupProps()
    const ignoreOutsideClicks = this.getIgnoreOutsideClicks()

    const creationLineProps = {
      innerLeftPadding,
      innerRightPadding,
      ignoreOutsideClicks,
      onCreate: this.onCreatePageTask,
      canBlockToCalendar: true,
      creating: true,
      startCreation: !!creatingTask || this.props.keepCreatingTasks,
      useTitleProps: !!creatingTask,
      item: creatingTask || undefined,
      onTogglePin: showCalendar ? this.props.onTogglePin : undefined,
      startSprintCreation: sprintComposerProps.startSprintCreation,
      onCancel: this.props.cancelTaskAction,
      onStartFsAndCreate: this.onStartFsAndCreate,
    }

    const sprintTaskProps = {
      animateComplete: true,
      keepJustCompleted: true,
      hideScheduleSection: true,
      hideWhenDate: true,
      onSave,
      onComplete,
      updateTaskInState,
      onDelete: onClickDelete,
      innerLeftPadding: innerNestedLeftPadding,
      innerRightPadding: innerNestedRightPadding,
      startSprintCreation: sprintComposerProps.startSprintCreation,
      isSprintTask: true,
      ...focusSessionPopupProps,
      setHighlightedEventId,
      onRemoveFromSprint,
    }

    const sprintLineProps = {
      onClickEdit: sprintComposerProps.onClickEditSprint,
      onClickDelete: onClickDeleteSprint,
      innerLeftPadding,
      innerRightPadding: innerLeftPadding,
      isCalendarDraggable: true,
      onClickCallback: onClickSprint,
      onClickOutside: onClickOutsideSprint,
      onEnd: onEndSprint,
      onDeleteFocusSession: this.onClickDeleteFocusSession,
      sprintInlineTaskProps: {
        creating: true,
        hideBlockToCalendarOption: true,
        hideDateInput: true,
        hideScheduleSection: true,
        hideSprint: true,
        isCreating: true,
        isSprintTask: true,
        onCreate: onCreateInlineTaskFromSprint,
      },
      sprintTaskProps: sprintTaskProps,
    }

    const commonSectionProps = {
      allItems,
      onSave,
      updateTaskInState,
      onComplete,
      onCompleteFromFS,
      session,
      onDrop,
      onDragStart,
      onDragEnd,
      inProgressSprints,
      onChangeFilterBy,
      toHideTaskId,
      onDropOnDraggableOver,
      filterBy,
      startSprintCreation: sprintComposerProps.startSprintCreation,
      onClickDateHeader,
      creatingCalendarTask: this.props.creatingCalendarTask,
      isCreatingInlineTask: this.props.isCreatingInlineTask,
      startInlineTaskCreation: this.props.startInlineTaskCreation,
      startFsAndCreateTask,
      editingCalendarTask: this.props.editingCalendarTask,
      sprintLineProps,
      creationLineProps,
      sprintTaskProps,
      getCompletedTaskProps: this.getCompletedTaskProps,
      getPinnedTaskProps: this.getPinnedTaskProps,
      getRegularTaskProps: this.getRegularTaskProps,
      draggingData,
      computeDroppableId,
      computeSprintsDroppableId,
      isTaskCreationAlertShown,
      focusSessionPopupProps,
      ignoreOutsideClicks,
      onStartTimer: this.onStartFsAndCreate,
      showCalendar,
    }

    return (
      <PageContainer>
        <PageBody>
          <Switch location={this.props.location}>
            <Route
              exact
              path='/tasks'
              render={() => (
                <TodaySection
                  {...commonSectionProps}
                  onArchive={onArchive}
                  onMoveToTopTodaySection={onMoveToTopTodaySection}
                  onArchiveAllCompletedToday={onArchiveAllCompletedToday}
                  onClickDelete={onClickDelete}
                  isDragging={isDragging}
                />
              )}
            />
            <Route component={NotFound} />
          </Switch>
        </PageBody>
      </PageContainer>
    )
  }
}

TodayPage.propTypes = {
  allItems: PropTypes.object,
  onCreateSprint: PropTypes.func,
}

export default container(TodayPage)
