import { useState } from 'react'

const INITIAL_INSTANCES_DISPLAYED = 2

export default function RecurrenceDropdownHandler({ children, recurrenceId, recurrenceInstances, selectedOption }) {
  const [instancesDisplayed, setInstancesDisplayed] = useState(() => {
    const selectedOptionIdx = recurrenceInstances.findIndex((sprint) => selectedOption?.id === sprint.id)
    return selectedOptionIdx > INITIAL_INSTANCES_DISPLAYED ? recurrenceInstances.length : INITIAL_INSTANCES_DISPLAYED
  })
  const [isGroupExpanded, setIsGroupExpanded] = useState(true)

  const toggleGroupExpansion = () => {
    setIsGroupExpanded((prev) => !prev)
  }

  const loadMoreInstances = () => {
    setInstancesDisplayed(recurrenceInstances.length)
  }

  const allInstancesSelected = selectedOption?.id === recurrenceId
  const [firstInstance] = recurrenceInstances
  const displayingAllInstances = instancesDisplayed >= recurrenceInstances.length
  const instancesToRender = displayingAllInstances
    ? recurrenceInstances
    : recurrenceInstances.slice(0, instancesDisplayed)

  return children({
    allInstancesSelected,
    displayingAllInstances,
    firstInstance,
    instancesToRender,
    isGroupExpanded,
    loadMoreInstances,
    toggleGroupExpansion,
  })
}
