import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  name: PropTypes.string.isRequired,
  wrapper: PropTypes.func,
  onClose: PropTypes.func,
  active: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
}

const Parent = ({ children }) => children()

class BlurLayer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      mouseDown: false,
    }
  }

  onClose = (e) => {
    if (e && this.node !== e.target) {
      return
    }
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  onMouseDown = (e) => {
    if (this.node === e.target) {
      this.setState({
        mouseDown: true,
      })
    }
  }

  onMouseUp = (e) => {
    if (!this.props.unclosable && this.state.mouseDown && this.node === e.target) {
      this.onClose(e)
    }
    this.setState({
      mouseDown: false,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.active) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }
  }

  render() {
    const { dataId, name, active, children, wrapper } = this.props
    const Wrapper = wrapper || Parent
    const className = `${name}-layer blur-layer` + (active ? ' active ' : ' ') + (this.props.className || '')
    return (
      <div
        className={className}
        data-id={dataId}
        ref={(ref) => (this.node = ref)}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onTouchStart={this.onClose}>
        <Wrapper active={active} onClose={this.onClose}>
          {({ moving, style } = {}) => (
            <div style={style} className={`${name}-content layer-content` + (moving ? ' moving' : '')}>
              {children}
            </div>
          )}
        </Wrapper>
      </div>
    )
  }
}

BlurLayer.propTypes = propTypes

export default BlurLayer
