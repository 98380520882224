import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import parser from 'react-html-parser'

import { styles, translations } from 'gipsy-misc'
import { SimpleButton } from 'gipsy-ui'

import CalendarAnimation from 'pages/onboarding/components/calendarAnimation'
import { openPopup } from 'store/popup/actions'

export default function TaskCreatedPopup({ onAccept, onDismiss, show }) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (show) {
      dispatch(
        openPopup({
          centeredTitle: true,
          component: (
            <Content>
              <Title>{parser(translations.onboarding.calendarStep.taskCreatedPopup.title)}</Title>
              <AcceptButton
                height={48}
                onClick={onAccept}
                text={translations.onboarding.calendarStep.taskCreatedPopup.accept}
                width={276}
              />
              <DismissButton onClick={onDismiss}>
                {translations.onboarding.calendarStep.taskCreatedPopup.dismiss}
              </DismissButton>
              <AnimationContainer>
                <CalendarAnimation active sprint />
              </AnimationContainer>
              <Tip>{parser(translations.onboarding.calendarStep.taskCreatedPopup.tip)}</Tip>
            </Content>
          ),
          contentMaxWidth: 520,
          noPadding: true,
          title: '',
          unclosable: true,
        })
      )

      return
    }
  }, [dispatch, onAccept, onDismiss, show])

  return null
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 65px 42px 30px;
`

Content.displayName = 'Content'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0 0 30px;
  text-align: center;
`

Title.displayName = 'Title'

const AcceptButton = styled(SimpleButton)`
  margin-bottom: 12px;
`

AcceptButton.displayName = 'AcceptButton'

const DismissButton = styled.span`
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`

DismissButton.displayName = 'DismissButton'

const AnimationContainer = styled.div`
  margin-bottom: 22px;
  padding: 0 32px;
  width: 100%;
`

AnimationContainer.displayName = 'AnimationContainer'

const Tip = styled.span`
  color: ${styles.colors.textMediumDarkColor};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 24px;
  text-align: center;

  & strong {
    color: ${styles.colors.orangeColor};
    font-weight: 500;
  }
`

Tip.displayName = 'Tip'
