import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import { ConnectedTaskFilters } from 'pages/today/active/components'
import EditableLine from 'features/list/components/editableLine'
import usePageActions from 'features/hooks/usePageActions'

import { focussession } from 'gipsy-api'
import { styles, translations, utils } from 'gipsy-misc'
import { TaskFiltersComponents } from 'gipsy-ui'

import { Grid, Row, Cell } from './commonUIComponents'
import DateDropdown from './DateDropdown'
import ItemSession from './ItemSession'
import ReportsHeader, { TABS } from './ReportsHeader'
import ReportItem from './Report'
import TimeDropdown, { RANGES } from './TimeDropdown'
import { findInListById, updateTaskInArr } from 'logic/list'

function ReportsList(props) {
  const { completeTask, deleteTask, deletionPopup, saveTask, taskDeletePopUp, uncompleteTask } = usePageActions()

  const { user, defaultProjectIdToFilter, defaultTagIdToFilter } = props

  const [activeTab, setActiveTab] = useState(TABS.TASKS)
  const [calendarRange, setCalendarRange] = useState({
    start: null,
    end: null,
  })
  const [filterBy, setFilterBy] = useState({
    projects: [],
    tags: [],
  })
  const [loadedSessions, setLoadedSessions] = useState([])
  const [loadedTasks, setLoadedTasks] = useState([])
  const [range, setRange] = useState(RANGES.MONTH)

  const totalTime = useRef('00:00:00')

  const queryTasks = activeTab === TABS.TASKS

  const changeTab = (tab) => setActiveTab(tab)

  const onChangeFilterBy = (filtered) => {
    setFilterBy(filtered)
  }

  const getDuration = (start, end) => moment.duration(moment(end.diff(start)))

  const formatTime = (time) => (time < 10 ? `0${time}` : time)

  const formatDuration = (duration) => {
    const hours = formatTime(Math.floor(duration.asHours()))
    const minutes = formatTime(duration.minutes())
    const seconds = formatTime(duration.seconds())
    return `${hours}:${minutes}:${seconds}`
  }

  useEffect(() => {
    if (defaultProjectIdToFilter) {
      setFilterBy((filterBy) => ({
        ...filterBy,
        projects: [defaultProjectIdToFilter],
      }))
    }
  }, [defaultProjectIdToFilter])

  useEffect(() => {
    if (defaultTagIdToFilter) {
      setFilterBy((filterBy) => ({
        ...filterBy,
        tags: [defaultTagIdToFilter],
      }))
    }
  }, [defaultTagIdToFilter])

  const getBounds = useCallback(
    (selectedRange) => {
      const todayDate = moment().format('YYYY-MM-DD')
      switch (selectedRange) {
        case RANGES.MONTH: {
          return {
            lowerBound: moment().startOf('day').startOf('month').toISOString(),
            upperBound: moment().endOf('day').endOf('month').toISOString(),
          }
        }

        case RANGES.WEEK: {
          const begOfWeek = utils.date.getStartOfWeek(todayDate, user.settingsPreferences.calendar.firstDay)
          const endOfWeek = utils.date.getEndOfWeek(todayDate, user.settingsPreferences.calendar.firstDay)
          return {
            lowerBound: moment(begOfWeek).startOf('day').toISOString(),
            upperBound: moment(endOfWeek).endOf('day').toISOString(),
          }
        }

        case RANGES.LAST_MONTH: {
          return {
            lowerBound: moment().subtract(1, 'month').startOf('day').startOf('month').toISOString(),
            upperBound: moment().subtract(1, 'month').endOf('day').endOf('month').toISOString(),
          }
        }

        case RANGES.LAST_WEEK: {
          const begOfWeek = utils.date.getStartOfWeek(todayDate, user.settingsPreferences.calendar.firstDay)
          const endOfWeek = utils.date.getEndOfWeek(todayDate, user.settingsPreferences.calendar.firstDay)
          return {
            lowerBound: moment(begOfWeek).subtract(1, 'week').startOf('day').toISOString(),
            upperBound: moment(endOfWeek).subtract(1, 'week').endOf('day').toISOString(),
          }
        }

        default: {
          throw new Error(`Unsupported date range "${selectedRange}"`)
        }
      }
    },
    [user]
  )

  const findTaskById = (taskList, id) => {
    const index = taskList.findIndex((task) => task.id === id)
    return { index, task: taskList[index] }
  }

  const onSave = useCallback(
    async (updatedTask) => {
      setLoadedTasks((loadedTasks) => {
        const { index } = findTaskById(loadedTasks, updatedTask.id)

        if (index < 0) {
          console.warn('task not found')
          return loadedTasks
        }

        let updatedTasks = [...loadedTasks]
        const currentTask = loadedTasks[index]
        updatedTasks[index] = updatedTask
        const hasPinTimeChanged = get(currentTask, 'pin.time') !== get(updatedTask, 'pin.time')
        saveTask({ hasPinTimeChanged, task: currentTask, updatedTask })
        return updatedTasks
      })
    },
    [saveTask]
  )

  const onDeleteTask = useCallback(
    (taskId) => {
      setLoadedTasks((loadedTasks) => {
        const { index, task } = findTaskById(loadedTasks, taskId)

        if (!task) {
          console.warn('task not found')
          return loadedTasks
        }

        deleteTask(task)
        const updatedTasks = [...loadedTasks]
        updatedTasks.splice(index, 1)
        return updatedTasks
      })
    },
    [deleteTask]
  )

  const handleDeleteTaskClick = useCallback(
    (taskId) => {
      taskDeletePopUp({
        onConfirmed: () => {
          onDeleteTask(taskId)
        },
      })
    },
    [onDeleteTask, taskDeletePopUp]
  )

  const handleCompleteLogic = useCallback(
    async ({ id }, extraParams, toAddFocusSession) => {
      let toCompleteTask
      setLoadedTasks((loadedTasks) => {
        toCompleteTask = findInListById(loadedTasks, id)
        toCompleteTask = utils.task.updateTaskWhenComplete(toCompleteTask, new Date())
        toCompleteTask.focusSessions = (toCompleteTask.focusSessions || []).concat([toAddFocusSession])

        if (toCompleteTask?.id) {
          return updateTaskInArr([...loadedTasks], toCompleteTask)
        }

        return loadedTasks
      })

      if (toCompleteTask) {
        await completeTask({ extraParams, id, task: toCompleteTask, toAddFocusSession })
      }
    },
    [completeTask]
  )

  const debouncedHandleCompleteLogic = debounce(handleCompleteLogic, 400)

  const onUncompleteTask = useCallback(
    (id) => {
      setLoadedTasks((loadedTasks) => {
        const { index, task } = findTaskById(loadedTasks, id)

        if (!task) {
          console.warn('task not found')
          return loadedTasks
        }

        let updatedTasks = [...loadedTasks]
        uncompleteTask(
          { id, task },
          {
            onLocalTaskCompleted: (updatedTask) => {
              updatedTasks[index] = updatedTask
            },
          }
        )
        return updatedTasks
      })
    },
    [uncompleteTask]
  )

  const showUncompleteConfirmation = useCallback(
    (id) => {
      deletionPopup(
        {
          cancelLabel: translations.timeReports.uncomplete.deny,
          confirmLabel: translations.timeReports.uncomplete.consent,
          text: translations.timeReports.uncomplete.prompt,
          title: translations.timeReports.uncomplete.prompt,
        },
        {
          onConfirmed: () => {
            onUncompleteTask(id)
          },
        }
      )
    },
    [deletionPopup, onUncompleteTask]
  )

  const handleToggleCompleteTask = useCallback(
    ({ id, value }) => {
      if (value) {
        const task = findInListById(loadedTasks, id)
        if (task) {
          let completionFocusSession
          const completionTime = utils.task.getCompletionTime(task)
          completionFocusSession = utils.focussession.createDefaultFocusSession({ task, endTime: completionTime })
          debouncedHandleCompleteLogic({ id }, undefined, completionFocusSession)
        }
      } else {
        showUncompleteConfirmation(id)
      }
    },
    [debouncedHandleCompleteLogic, loadedTasks, showUncompleteConfirmation]
  )

  const fetchReports = useCallback(
    async (lowerBound, upperBound) => {
      try {
        const reports = await focussession.getCompletedSessions(lowerBound, upperBound, queryTasks)

        if (queryTasks) {
          setLoadedTasks(reports || [])
        } else {
          setLoadedSessions(reports || [])
        }
      } catch (err) {
        console.error(err)
      }
    },
    [queryTasks]
  )

  const handleDropdownRangeSelected = useCallback(
    (selectedRange) => {
      if (calendarRange.start || calendarRange.end) {
        // allow to query by same dropdown option if the user queried by calendar before
        setCalendarRange({
          start: null,
          end: null,
        })
        const { lowerBound, upperBound } = getBounds(selectedRange)
        fetchReports(lowerBound, upperBound)
      }

      setRange(selectedRange)
    },
    [calendarRange, fetchReports, getBounds]
  )

  const fetchTasksByCalendarRange = useCallback(
    ({ start, end }) => {
      fetchReports(start, end)
    },
    [fetchReports]
  )

  useEffect(() => {
    const { start, end } = calendarRange

    if (start && end) {
      fetchReports(moment(start).toISOString(), moment(end).toISOString())
    } else if (!start && !end) {
      const { lowerBound, upperBound } = getBounds(range)
      fetchReports(lowerBound, upperBound)
    }
  }, [calendarRange, fetchReports, getBounds, range])

  const renderHeaders = () =>
    queryTasks ? (
      <Row>
        <ColHeader></ColHeader>
        <ColHeader>{translations.timeReports.headers.task}</ColHeader>
        <ColHeader></ColHeader>
        <ColHeader textAlign={'end'}>{translations.timeReports.headers.project}</ColHeader>
        <ColHeader textAlign={'end'}>{translations.timeReports.headers.duration}</ColHeader>
      </Row>
    ) : (
      <Row>
        <ColHeader colStart={1} colEnd={3}>
          {translations.timeReports.headers.started}
        </ColHeader>
        <ColHeader>{translations.timeReports.headers.task}</ColHeader>
        <ColHeader textAlign={'end'}>{translations.timeReports.headers.project}</ColHeader>
        <ColHeader textAlign={'end'}>{translations.timeReports.headers.duration}</ColHeader>
      </Row>
    )

  const filterItems = (items) => {
    if (filterBy.projects.length === 0 && filterBy.tags.length === 0) return items

    let uncategorizedSelected = false

    if (filterBy.projects.length > 0 && filterBy.projects.includes(TaskFiltersComponents.UNCATEGORIZED)) {
      uncategorizedSelected = true
    }

    return items.filter((item) => {
      let add = true

      if (filterBy.projects.length > 0) {
        if (!item.projects) {
          add = add && uncategorizedSelected
        } else {
          add =
            add &&
            filterBy.projects.some((projectId) => {
              return item.projects?.[0]?.id === projectId
            })
        }
      }

      if (filterBy.tags.length > 0) {
        if (!item.tags) return false

        add =
          add &&
          filterBy.tags.some((tagId) => {
            return !!item.tags.find((tag) => tag.id === tagId)
          })
      }

      return add
    })
  }

  const renderSessions = () => {
    const totalDuration = moment.duration(0)

    const items = filterItems(loadedSessions).map((session) => {
      const start = moment(session.startTime)
      const end = moment(session.endTime)
      const duration = getDuration(start, end)

      totalDuration.add(duration)

      return (
        <ReportItem
          date={start.format('ddd, DD MMM YYYY')}
          duration={formatDuration(duration)}
          isTask={false}
          key={session.id}
          projects={session.projects}
          asanaWorkspaceName={session.asanaInfo?.workspaceName}
          time={start.format('h:mm A')}
          title={session.title}
        />
      )
    })

    totalTime.current = formatDuration(totalDuration)

    return items
  }

  const renderTasks = () => {
    const totalDuration = moment.duration(0)

    const items = filterItems(loadedTasks).map((task) => {
      const taskDuration = moment.duration(0)

      const taskSessions = task.focusSessions.map((session) => {
        const start = moment(session.startTime)
        const end = moment(session.endTime)
        const duration = getDuration(start, end)

        taskDuration.add(duration)

        return (
          <ItemSession
            date={start.format('ddd, DD MMM YYYY')}
            duration={formatDuration(duration)}
            key={session.id}
            time={start.format('h:mm A')}
          />
        )
      })

      totalDuration.add(taskDuration)

      const renderRegularLine = ({ startEditMode }) => {
        return (
          <ReportItem
            duration={formatDuration(taskDuration)}
            id={task.id}
            isCompleted={!!task.completed}
            isTask
            onClickTaskTitle={startEditMode}
            onToggleComplete={handleToggleCompleteTask}
            projects={task.projects}
            asanaWorkspaceName={task.asanaInfo?.workspaceName}
            urlsInfo={task.urlsInfo}
            taskSessions={taskSessions}
            title={task.title}
          />
        )
      }

      return (
        <EditableLine
          hideDateInput
          hideSpentTimeInput
          hideScheduleSection={!!task.completed}
          hideSprint
          item={task}
          key={task.id}
          onDelete={handleDeleteTaskClick}
          onSave={onSave}
          renderRegularLine={renderRegularLine}
        />
      )
    })
    totalTime.current = formatDuration(totalDuration)
    return items
  }

  const rangeLabel =
    calendarRange.start || calendarRange.end
      ? `${moment(calendarRange.start).format('D MMM')} - ${
          calendarRange.end ? moment(calendarRange.end).format('D MMM') : ''
        }`
      : undefined

  return (
    <div>
      <ReportsHeader activeTab={activeTab} onTabClick={changeTab} totalTimeRef={totalTime} />
      <Filters>
        <ConnectedTaskFilters filterBy={filterBy} onChange={onChangeFilterBy} />
        <TimeDropdown label={rangeLabel} onChange={handleDropdownRangeSelected} value={range} />
        <DateDropdown
          onChange={setCalendarRange}
          onValidRangeSelected={fetchTasksByCalendarRange}
          value={calendarRange}
          firstDayOfWeek={user?.settingsPreferences?.calendar?.firstDay}
        />
      </Filters>
      <Grid taskMode={queryTasks}>{renderHeaders()}</Grid>
      {queryTasks ? renderTasks() : renderSessions()}
    </div>
  )
}

const Filters = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 26px;
`

const ColHeader = styled(Cell)`
  color: ${styles.colors.darkGrey};
  font-size: 11px;
  font-weight: 400;
`

export default ReportsList
