import React from 'react'
import noop from 'lodash/noop'
import { Actions, ActionIcons, ActionIcon, ActionIconContainer } from './commonUIComponents'
import { StartAndCreateButton } from 'gipsy-ui'
import { styles } from 'gipsy-misc'

export function ActionsButtons({ isEditDisabled, isDeleteDisabled, onClickEdit, onClickDelete }) {
  return (
    <ActionIcons>
      <ActionIconContainer
        isDisabled={isEditDisabled}
        hoverColor={styles.colors.primaryColor}
        onClick={isEditDisabled ? noop : onClickEdit}>
        <ActionIcon icon={'Edit'} size={16} fill={styles.colors.darkGrey} />
      </ActionIconContainer>
      <ActionIconContainer
        isDisabled={isDeleteDisabled}
        hoverColor={styles.colors.primaryColor}
        onClick={isDeleteDisabled ? noop : onClickDelete}>
        <ActionIcon icon={'Trash'} size={16} fill={styles.colors.darkGrey} />
      </ActionIconContainer>
    </ActionIcons>
  )
}

export function ActionsSection(props) {
  const { shouldShowStartButton, onClickStart } = props
  return (
    <Actions>
      <StartAndCreateButton
        isHidden={!shouldShowStartButton}
        width={110}
        startIconSize={16}
        onClick={shouldShowStartButton ? onClickStart : noop}
      />
      <ActionsButtons {...props} />
    </Actions>
  )
}
