import get from 'lodash/get'
import { translations } from 'gipsy-misc'
import logger from 'utils/logger'
import { showSnackbar as showSnackbarAction } from 'store/snackbar/actions'

export const handleAPIError = (err, data) => (dispatch, getState, { api }) => {
  const code =
    get(err, 'data.code') && get(translations, `message.error.${err.data.code}`) ? err.data.code : 'unexpected'
  const message = get(err, 'data.message') || err.message || null
  const translatedMessage = get(translations, `message.error.${code}`).replace('{{message}}', message)
  if (message) {
    logger.error(`${code} - ${message}`, data)
  } else {
    logger.error(err, data)
  }

  dispatch(
    showSnackbarAction(
      {
        message: translatedMessage,
        status: 'error',
      },
      5000
    )
  )

  return code
}
