import { createAction } from 'redux-actions'

import { types } from 'store/taskPanel/types'
import { setIsExpanded } from 'features/taskPanel/utils'

const _setPanelExpanded = createAction(types.SET_PANEL_EXPANDED)
const _setPanelOpen = createAction(types.SET_PANEL_OPEN)
const _setSelectMode = createAction(types.SET_SELECT_MODE)

export const setPanelExpanded = (value) => (dispatch) => {
  dispatch(_setPanelExpanded(value))
  setIsExpanded(value)
}
export const setPanelOpen = (isOpen) => (dispatch) => dispatch(_setPanelOpen(isOpen))
export const setSelectMode = (value) => (dispatch) => dispatch(_setSelectMode(value))
