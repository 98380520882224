import React, { PureComponent } from 'react'
import ActiveTagTasksPageContainer from 'features/task/components/activeTagTasksPageContainer'
import { withRouter } from 'react-router-dom'

export default function wrapper(Component) {
  class TagPageContainer extends PureComponent {
    state = {
      tagId: this.props.match.params.tagId,
    }

    componentDidUpdate(prevProps) {
      let { tagId: prevTagId } = prevProps.match.params
      let { tagId } = this.props.match.params
      if (prevTagId !== tagId) {
        this.setState({ tagId: tagId })
      }
    }

    render() {
      return (
        <ActiveTagTasksPageContainer tagId={this.state.tagId}>
          {({ ...taskPageProps }) => <Component {...taskPageProps} />}
        </ActiveTagTasksPageContainer>
      )
    }
  }

  return withRouter(TagPageContainer)
}
