import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'

import { translations } from 'gipsy-misc'

import RecurringItemsDropdown from 'ListDropdowns/RecurringItems'

import DropdownSelector from './DropdownSelector'
import RecurrenceDropdownSelector from './RecurrenceDropdownSelector'

import TaskSelectInput from './TaskSelectInput'

export default function TaskSelect({
  activeTasks,
  className,
  hideCreateButton,
  inlineCreateButton,
  onChange,
  onClickCreateButton,
  placeholder,
  value,
}) {
  const [dropdownShown, setDropdownShown] = useState(false)

  const containerRef = useRef(null)

  const handleInputClicked = () => {
    setDropdownShown((prev) => {
      return !prev
    })
  }

  const onSelect = (task) => {
    onChange(task)
    setDropdownShown(false)
  }

  const onClickOutsideOfInput = (e) => {
    const dropdown = document.querySelector('.TaskSelect__RecurringItemsDropdown')
    if (dropdown?.contains?.(e.target)) return
    setDropdownShown(false)
  }

  useEffect(() => {
    if (dropdownShown) {
      containerRef.current?.scrollIntoView?.()
    }
  }, [dropdownShown])

  let isSingleInstance = true
  let task = value

  if (value?.instances) {
    isSingleInstance = false
    task = value.instances[0]
  }

  return (
    <Container className={clsx('TaskSelect', className)} ref={containerRef}>
      <TaskSelectInput
        active={dropdownShown}
        isSingleInstance={isSingleInstance}
        onClear={() => onSelect(null)}
        onClick={handleInputClicked}
        onClickOutside={onClickOutsideOfInput}
        placeholder={placeholder}
        task={task}
      />
      {dropdownShown && (
        <RecurringItemsDropdown
          className='TaskSelect__RecurringItemsDropdown'
          createLabel={translations.sprint.createNew}
          dropdownSelector={DropdownSelector}
          emptyLabel={translations.sprint.noSprints}
          groupDropdownSelector={RecurrenceDropdownSelector}
          inlineCreateButton={inlineCreateButton}
          itemsMap={activeTasks}
          onCreate={onClickCreateButton}
          onSelect={onSelect}
          selectedOption={value}
          withCreate={!hideCreateButton}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  .TaskSelect__RecurringItemsDropdown {
    margin-top: 6px;
  }
`

Container.displayName = 'Container'
