import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import noop from 'lodash/noop'

import { Checkbox, TimeRange } from 'gipsy-ui'
import { styles, utils } from 'gipsy-misc'

import DatePickerInput from 'features/calendar/components/CalendarPanel/components/DatePickerInput'
import { getItemDurationInMinutes } from 'logic/calendar'

import { ActionsSection } from './ActionsSection'
import {
  CheckboxContainer,
  Content,
  ItemInfo,
  ItemTimeInfo,
  ItemTitle,
  LeftPart,
  RightPart,
  StyledAnimatedTitle,
} from './commonUIComponents'
import { useShortCuts } from './useShortCuts'

const completeAnimationDuration = 0.3
const componentSource = 'calendarTaskPopup'

export function EditCalendarTaskPopupContent({
  firstDayOfWeek,
  item,
  onClickComplete,
  onClickDelete,
  onClickEdit,
  onClickStart,
  onUpdateItem,
}) {
  const [isJustCompleted, setIsJustCompleted] = useState(false)

  const _onClickComplete = useCallback(
    ({ id: itemId = item.id }) => {
      if (!isJustCompleted) {
        setIsJustCompleted(true)
        setTimeout(() => onClickComplete?.(itemId), completeAnimationDuration * 1000)
      }
    },
    [onClickComplete, item, isJustCompleted]
  )

  useShortCuts({
    onClickComplete: _onClickComplete,
    onClickDelete,
    onClickStart,
    onClickEdit,
  })

  const handleDateChange = useCallback(
    (newDateData) => {
      const updatedTask = utils.task.computeTaskOnChange(item, newDateData)
      onUpdateItem?.(updatedTask)
    },
    [item, onUpdateItem]
  )

  const handleTimeRangeChange = useCallback(
    (range, e) => {
      const rangeData = utils.sprint.getPinTimeAndEstimatedTimeFromCalendarSlot(range)
      const updatedTask = utils.task.computeTaskOnChange(
        item,
        { paramName: 'pin.time', value: rangeData.pin.time },
        {
          estimatedTime: rangeData.estimatedTime,
        }
      )

      onUpdateItem?.(updatedTask, e)
    },
    [item, onUpdateItem]
  )

  const handleClickStart = useCallback(() => {
    onClickStart(componentSource)
  }, [onClickStart])

  const allDay = !item?.pin?.time
  const duration = getItemDurationInMinutes(item)
  const focusSession = useSelector((state) => state.session.focusSession)
  const shouldShowStartButton = !(focusSession?.id || isJustCompleted)
  const startTime = moment(item?.pin?.time || item?.when?.date)

  return (
    <Content>
      <LeftPart>
        <CheckboxContainer>
          <Checkbox
            size={16}
            disabled={isJustCompleted}
            checkmarkTop={1.2}
            checkmarkLeft={4.5}
            checkmarkWidth={3}
            checkmarkHeight={6}
            checkmarkBorderWidth={1.5}
            borderWidth={1.5}
            checkedColor={styles.colors.greenColor}
            checkMarkColor={'white'}
            hoverCheckMarkColor={styles.colors.greyBorderColor}
            hoverBackgroundColor={'white'}
            hoverBorderColor={styles.colors.darkGrey}
            borderColor={styles.colors.darkGrey}
            id={item?.id}
            paramName='completed'
            checked={!!item?.completed || isJustCompleted}
            onChange={!item?.completed ? _onClickComplete : noop}
          />
        </CheckboxContainer>
      </LeftPart>
      <RightPart>
        <ItemInfo>
          {isJustCompleted ? (
            <StyledAnimatedTitle
              title={item?.title}
              completionDuration={completeAnimationDuration}
              lineThrough={'false'}
              justCompleted>
              {item?.title}
            </StyledAnimatedTitle>
          ) : (
            <ItemTitle title={item?.title}>{item?.title}</ItemTitle>
          )}

          <ItemTimeInfo>
            <DatePickerInput
              closeOnSelection
              firstDayOfWeek={firstDayOfWeek}
              onChange={handleDateChange}
              startTime={startTime}
            />
            {!allDay && <TimeRange duration={duration} onChange={handleTimeRangeChange} startTime={startTime} />}
          </ItemTimeInfo>
        </ItemInfo>
        <ActionsSection
          shouldShowStartButton={shouldShowStartButton}
          isEditDisabled={isJustCompleted}
          isDeleteDisabled={isJustCompleted}
          onClickStart={handleClickStart}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      </RightPart>
    </Content>
  )
}
