import React, { useCallback } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { utils, styles } from 'gipsy-misc'
import { Icon, SprintTaskCompressedLine, TimeRange } from 'gipsy-ui'

import DatePickerInput from 'features/calendar/components/CalendarPanel/components/DatePickerInput'
import { getItemDurationInMinutes } from 'logic/calendar'

import { ActionsSection } from './ActionsSection'
import {
  CheckboxContainer,
  Content,
  ItemInfo,
  ItemTimeInfo,
  ItemTitle,
  LeftPart,
  RightPart,
} from './commonUIComponents'
import { useShortCuts } from './useShortCuts'

export const completeAnimationDuration = 0.3
const componentSource = 'calendarSprintPopup'

export function EditCalendarSprintPopupContent({
  firstDayOfWeek,
  onClickComplete,
  onClickDelete,
  onClickDeleteTask,
  onClickEdit,
  onClickRemoveTask,
  onClickStart,
  onUpdateClickedItem,
  onUpdateItem,
  sprint,
}) {
  const session = useSelector((state) => state.session)

  useShortCuts({
    onClickDelete,
    onClickEdit,
  })

  const _onClickComplete = useCallback(
    ({ id: taskId }) => {
      const updatedSprint = utils.sprint.completeTask(sprint, taskId)
      updatedSprint.tasks = (updatedSprint.tasks || []).filter((task) => !task.completed)
      onUpdateClickedItem(updatedSprint)
      onClickComplete(taskId, true)
    },
    [sprint, onUpdateClickedItem, onClickComplete]
  )

  const handleDateChange = useCallback(
    (newDateData) => {
      const newPin = moment(newDateData.value)
      const oldPin = moment(sprint.pin.time)
      newPin.hour(oldPin.hour()).minute(oldPin.minute()).second(oldPin.second())

      const updatedSprint = { ...sprint }
      updatedSprint.pin = { time: newPin.toDate() }
      updatedSprint.when = { date: newPin.format('YYYY-MM-DD') }
      onUpdateItem?.(updatedSprint)
    },
    [onUpdateItem, sprint]
  )

  const handleTimeRangeChange = useCallback(
    (range, e) => {
      const rangeData = utils.sprint.getPinTimeAndEstimatedTimeFromCalendarSlot(range)
      const updatedSprint = { ...sprint }
      updatedSprint.pin = rangeData.pin
      updatedSprint.estimatedTime = rangeData.estimatedTime
      onUpdateItem?.(updatedSprint, e)
    },
    [onUpdateItem, sprint]
  )

  const handleClickStart = useCallback(
    (taskData) => {
      onClickStart(taskData, componentSource)
    },
    [onClickStart]
  )

  const duration = getItemDurationInMinutes(sprint)
  const hasTasks = sprint.tasks?.length > 0
  const startTime = moment(sprint?.pin?.time)

  return (
    <>
      <Content>
        <LeftPart>
          <CheckboxContainer>
            <Icon icon={'Sprint'} size={16} fill={styles.colors.orangeColor} />
          </CheckboxContainer>
        </LeftPart>
        <RightPart>
          <ItemInfo>
            <SprintTitle title={sprint?.title}>{sprint?.title}</SprintTitle>
            <ItemTimeInfo>
              <DatePickerInput
                closeOnSelection
                firstDayOfWeek={firstDayOfWeek}
                onChange={handleDateChange}
                startTime={startTime}
              />
              <TimeRange duration={duration} onChange={handleTimeRangeChange} startTime={startTime} />
            </ItemTimeInfo>
          </ItemInfo>
        </RightPart>
      </Content>
      {hasTasks && (
        <ActionsPopupRelativeContainer>
          <SprintTasksContainer isScrollable={sprint.tasks.length > 3}>
            {sprint.tasks.map((task) => (
              <SprintTaskCompressedLine
                key={task.id}
                innerLeftPadding={0}
                innerRightPadding={0}
                item={task}
                sprint={sprint}
                keepJustCompleted
                disableUncomplete
                onComplete={_onClickComplete}
                onDelete={onClickDeleteTask}
                onRemove={onClickRemoveTask}
                onStartFocus={handleClickStart}
                session={session}
              />
            ))}
          </SprintTasksContainer>
        </ActionsPopupRelativeContainer>
      )}
      <ActionsSection onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
    </>
  )
}

const SprintTitle = styled(ItemTitle)`
  color: ${styles.colors.orangeColor};
`
const ActionsPopupRelativeContainer = styled.div`
  position: relative;
  width: 100%;
`

const lineHeight = 36
const boxShadowHeight = 1
const leftOffsetSpace = 8
const SprintTasksContainer = styled.div`
  direction: rtl;
  overflow: ${(props) => (props.isScrollable ? 'auto' : 'hidden')};
  width: calc(100% + ${leftOffsetSpace}px);
  min-width: 247px;
  max-height: ${lineHeight * 3 + boxShadowHeight}px;
  padding-bottom: ${boxShadowHeight}px;
  margin-top: 12px;
  margin-left: -${leftOffsetSpace}px;
`
