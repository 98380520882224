import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { styles, translations } from 'gipsy-misc'
import { Icon, media, Separator } from 'gipsy-ui'

const propTypes = {
  title: PropTypes.string.isRequired,
  isSaved: PropTypes.bool,
}

export const marginBottomSection = 60

export class Header extends PureComponent {
  render = () => {
    const { title, isSaved, className } = this.props
    return (
      <React.Fragment>
        <HeaderContainer className={`header ${className}`}>
          <TitleContainer>{title}</TitleContainer>
          {isSaved && (
            <SavedContainer>
              <Icon icon="Save" fill={styles.colors.greenColor} size={16} style={{ marginRight: 8 }} />
              <span>{translations.settings.section.common.saved}</span>
            </SavedContainer>
          )}
        </HeaderContainer>
        <HeaderSeparator />
      </React.Fragment>
    )
  }
}

export const SectionContainer = styled.div`
  width: 100%;
  margin-bottom: ${marginBottomSection}px;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;

  ${media['phone-wide']`
   margin-bottom: 10px;
`}
`

const TitleContainer = styled.div`
  font: ${styles.fonts.primaryFont};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.3px;
  width: auto;
  color: ${styles.colors.textDarkColor};
`

const SavedContainer = styled.div`
  &&& {
    color: ${styles.colors.greenColor};
    margin-left: 16px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-top: 2px;
  }
`

const HeaderSeparator = styled(Separator)`
  margin-top: 10px;
  margin-bottom: 30px;
`

Header.propTypes = propTypes
