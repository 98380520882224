import React from 'react'
import styled from 'styled-components'

import { styles } from 'gipsy-misc'

import BlurLayer from 'BlurLayer'
import FocusSessionStats from 'FocusedLine/focusSessionStats'

function NewLinksPopup({ session, onOkClicked, registerShortcuts, unregisterShortcuts }) {
  return (
    <NewLinksBlurLayer active name="new-links-popup">
      <StatsContainer>
        <FocusSessionStats
          onOkClicked={onOkClicked}
          registerShortcuts={registerShortcuts}
          session={session}
          unregisterShortcuts={unregisterShortcuts}
        />
      </StatsContainer>
    </NewLinksBlurLayer>
  )
}

const NewLinksBlurLayer = styled(BlurLayer)`
  align-items: center;
  display: flex;
  justify-content: center;

  &.new-links-popup-layer,
  &.new-links-popup-layer.active {
    ::before {
      background: ${styles.colors.textMediumDarkColor};
      opacity: 0.6;
      transition: none;
      z-index: -1;
    }

    .new-links-popup-content {
      max-width: 752px;
      min-width: 465px;
      width: 45%;
    }
  }
`

NewLinksBlurLayer.displayName = 'NewLinksBlurLayer'

const StatsContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
`

StatsContainer.displayName = 'StatsContainer'

export default NewLinksPopup
