import React, { PureComponent } from 'react'
import { CardAttribute, IconWrapper, PlaceHolder, getColor } from './commonUIComponents'
import Icon from 'Icon'

import { utils, translations } from 'gipsy-misc'

export class SpentTimeWrapper extends PureComponent {
  onWrapperClick = () => {
    this.props.toggleFocusSessionsPopup()
  }

  renderIcon() {
    const { shrinked, item, active } = this.props
    const hasSpentTime = item.focusSessions && item.focusSessions.length > 0

    return (
      <IconWrapper noMargin={shrinked}>
        <Icon size={14} icon={'HourGlassOutlined'} fill={getColor({ isSelected: active, hasValue: hasSpentTime })} />
      </IconWrapper>
    )
  }

  render() {
    const { active, shrinked, item } = this.props
    const hasSpentTime = item.focusSessions && item.focusSessions.length > 0
    return (
      <CardAttribute
        squared={shrinked}
        focused={active}
        onClick={this.onWrapperClick}
        ref={(ref) => (this.fieldNode = ref)}>
        {shrinked ? (
          this.renderIcon()
        ) : (
          <>
            {this.renderIcon()}
            <PlaceHolder color={getColor({ isSelected: active, hasValue: hasSpentTime })}>
              {hasSpentTime
                ? utils.datetime.beautifySpentTime(utils.task.getSpentTime(item) + ` (${item.focusSessions.length})`)
                : translations.task.spentTime.fieldLabel}
            </PlaceHolder>
          </>
        )}
      </CardAttribute>
    )
  }
}
