import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { models, styles, translations } from 'gipsy-misc'
import { user as userApi } from 'gipsy-api'
import { SquareCheckbox } from 'gipsy-ui'

import RealTime from 'features/realTime'
import { Header, SectionContainer } from 'pages/settings/components/common'

const EmailNotifications = ({ user, handleAPIError }) => {
  let timeout
  const { settingsPreferences } = user
  const [isSaved, setSaved] = useState(false)
  const [emailNotifications, setEmailNotifications] = useState({ ...settingsPreferences.emailNotifications })

  useEffect(() => {
    return () => {
      clearTimeout(timeout)
    }
  }, [timeout])

  useEffect(() => {
    setEmailNotifications(settingsPreferences.emailNotifications)
  }, [settingsPreferences.emailNotifications])

  const showIsSaved = () => {
    setSaved(true)
    timeout = setTimeout(() => setSaved(false), 5000)
  }

  const updateDailyDigestPreferences = async ({ value }) => {
    try {
      const newEmailNotificationsPrefs = {
        ...emailNotifications,
        dailyDigest: !!value,
      }
      setEmailNotifications(newEmailNotificationsPrefs)
      await userApi.patch(newEmailNotificationsPrefs, {
        emailNotificationsPreferences: true,
      })
      RealTime.publishMessage('', [models.realtime.topics.user])
      showIsSaved()
    } catch (err) {
      console.error(err)
      handleAPIError(err)
    }
  }

  return (
    <SectionContainer>
      <Header title={translations.settings.section.notifications.title} isSaved={isSaved} />
      <NotificationSectionHeader>
        <NotificationSectionTitle>
          {translations.settings.section.notifications.dailyEmail.title}{' '}
        </NotificationSectionTitle>
        <NotificationSectionSubTitle>
          {translations.settings.section.notifications.dailyEmail.label}{' '}
        </NotificationSectionSubTitle>
      </NotificationSectionHeader>

      <NotificationLine>
        <SquareCheckbox checked={!!emailNotifications.dailyDigest} onChange={updateDailyDigestPreferences} size={18} />
        <CheckboxLabel>{translations.settings.section.notifications.dailyEmail.options.yes}</CheckboxLabel>
      </NotificationLine>
    </SectionContainer>
  )
}

const NotificationSectionHeader = styled.div`
  color: ${styles.textDarkColor};
  margin-bottom: 20px;
`

const NotificationSectionTitle = styled.div`
  font-size: ${styles.fonts.fontSize};
  font-weight: 500;
  margin-bottom: 5px;
`

const NotificationSectionSubTitle = styled.div`
  font-size: ${styles.fonts.fontSizeXSmall};
`
const NotificationLine = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`

const CheckboxLabel = styled.span`
  margin-left: 10px;
  font-size: ${styles.fonts.fontSizeSmall};
`

export default EmailNotifications
