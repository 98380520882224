import { createSelector } from 'reselect'
import moment from 'moment'

import { utils } from 'gipsy-misc'

const _getSprints = (state) => state.sprints
const _getTasks = (state) => state.tasks

export const getAllItems = createSelector([_getSprints, _getTasks], (sprints, tasks) => ({ ...sprints, ...tasks }))

export const getItemsAsCalendarEvents = createSelector([_getSprints, _getTasks], (sprints, tasks) => {
  const events = []

  Object.values(sprints).forEach((sprint) => {
    const event = utils.calendar.parseItemToEvent(sprint)
    event && events.push(event)
  })

  Object.values(tasks).forEach((task) => {
    const event = utils.calendar.parseItemToEvent(task)
    event && events.push(event)
  })

  return events
})

export const getPinnedTasks = createSelector([_getTasks], (tasks) =>
  Object.keys(tasks).filter((taskId) => !!tasks[taskId].pin?.time)
)

export const getRegularTasks = createSelector([_getTasks], (tasks) =>
  Object.keys(tasks).filter((taskId) => !(tasks[taskId].pin?.time || tasks[taskId].sprintInfo))
)

export const getSprints = createSelector([_getSprints], (sprints) => Object.values(sprints))
export const getSprintsById = createSelector([_getSprints], (sprints) => Object.keys(sprints))
export const getSprintsByScheduledTime = createSelector([getSprints], (sprints) => {
  return utils.list.sortListByScheduledTime(sprints)
})

export const getActiveSprintsMapByScheduledTimeAndGroupedByRecurrence = createSelector(
  [getSprintsByScheduledTime],
  (sprints) => {
    return sprints.reduce((sMap, sprint) => {
      if (sprint.completionTime) return sMap

      if (sprint.recurrencyInformation) {
        const recurrenceId = sprint.recurrencyInformation.recurringSprintId

        if (sMap[recurrenceId]) {
          sMap[recurrenceId].push(sprint)
        } else {
          sMap[recurrenceId] = [sprint]
        }
      } else {
        sMap[sprint.id] = sprint
      }

      return sMap
    }, {})
  }
)

export const getSprintTasks = createSelector([_getSprints], (sprints) =>
  Object.keys(sprints).reduce((tasks, sprintId) => {
    const sprint = sprints[sprintId]

    sprint.tasks?.forEach((task) => {
      tasks[task.id] = task
    })

    return tasks
  }, {})
)

export const getTasks = createSelector([_getTasks, getSprintTasks], (tasks, sprintTasks) => [
  ...Object.values(tasks),
  ...Object.values(sprintTasks),
])

export const getTasksById = createSelector([_getTasks, getSprintTasks], (tasks, sprintTasks) => [
  ...Object.keys(tasks),
  ...Object.keys(sprintTasks),
])

export const getFindItemByIdFn = createSelector([getAllItems, getSprintTasks], (allItems, sprintTasks) => {
  return (id) => {
    return allItems[id] || sprintTasks[id]
  }
})

export const getItemsByDate = createSelector([_getSprints, _getTasks], (sprints, tasks) => {
  const items = {}

  Object.values(sprints).forEach((sprint) => {
    if (sprint.completionTime) return

    const whenDate = sprint.when.date

    if (!items[whenDate]) {
      items[whenDate] = {}
    }

    items[whenDate][sprint.id] = sprint
  })

  Object.values(tasks).forEach((task) => {
    if (task.archived) return

    const date = task.completionTime ? moment(task.completionTime).format('YYYY-MM-DD') : task.when?.date

    if (!date) return

    if (!items[date]) {
      items[date] = {}
    }

    items[date][task.id] = task
  })

  return items
})
