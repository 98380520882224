import React from 'react'
import styled from 'styled-components'
import parser from 'react-html-parser'

import { styles, translations } from 'gipsy-misc'

import ConfirmPanel from 'features/popup/components/confirmPanel'

export default function ConfirmDeletePrompt({ onCancel, onConfirm }) {
  return (
    <Wrapper>
      <Title>{translations.settings.section.deletePrompt.title}</Title>
      <ConfirmPanel
        cancelLabel={translations.general.no}
        confirmLabel={translations.general.yes}
        onCancel={onCancel}
        onConfirm={onConfirm}
        subtitle={parser(translations.settings.section.deletePrompt.prompt)}
      />
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  .ConfirmPanel__Subtitle {
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 10px;
  }

  .ConfirmPanel__CancelButton,
  .ConfirmPanel__ConfirmButton {
    height: 40px;
    width: 132px;
  }

  .ConfirmPanel__CancelButton {
    margin: 0 15px 0 auto;
  }

  .ConfirmPanel__ConfirmButton {
    margin: 0 auto 0 15px;
  }
`

Wrapper.displayName = 'Wrapper'

export const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0 0 16px;
  text-align: center;
`

Title.displayName = 'Title'
