import { types } from 'store/project/types'
import { moveElementInArrFromPositionToPosition } from 'logic/list'

const initialState = {
  items: [],
  areProjectsLoaded: false,
}

const projectsReducer = (state = initialState, action) => {
  const { payload } = action
  let items = []
  switch (action.type) {
    case types.FETCH_PROJECTS:
      return {
        ...state,
        items: [...items, ...payload.projects],
      }

    case types.ADD_PROJECT:
      if (!payload || !payload.project) {
        return state
      }
      items = [...state.items, payload.project]
      return {
        ...state,
        items,
      }
    case types.UPDATE_PROJECT:
      if (!payload || !payload.projectId || !payload.project) {
        return state
      }

      const projectIndex = state.items.findIndex((item) => item.id === payload.projectId)
      if (projectIndex === -1) {
        return state
      }

      items = [...state.items]
      items[projectIndex] = payload.project
      return {
        ...state,
        items,
      }

    case types.REORDER_PROJECT:
      if (!payload || isNaN(payload.from) || isNaN(payload.to) || !payload.project) {
        return state
      }

      items = [...state.items]
      const { from, to, project } = payload
      items = moveElementInArrFromPositionToPosition(items, project, from, to)
      return {
        ...state,
        items,
      }

    case types.REMOVE_PROJECT:
      if (!payload || !payload.projectId) {
        return state
      }

      items = [...state.items]
      items = items.filter((item) => item.id !== payload.projectId)

      return {
        ...state,
        items,
      }

    case types.SET_PROJECTS_LOADED:
      return {
        ...state,
        areProjectsLoaded: action.payload,
      }

    default:
      break
  }
  return state
}

export default projectsReducer
