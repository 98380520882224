import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

export const getFirstKey = (obj) => {
  const keys = Object.keys(obj)
  return keys && keys.length ? keys[0] : null
}

export const isObjectEqual = (objectA, objectB, pickFields) => {
  if (!pickFields) {
    return isEqual(objectA, objectB)
  }

  return isEqual(pick(objectA, pickFields), pick(objectB, pickFields))
}

export const areObjectsMatchingAtLeastOneField = (objectA, objectB, pickFields) => {
  for (let field of pickFields) {
    if (objectA[field] && objectA[field] === objectB[field]) {
      return true
    }
  }
  return false
}

export const hasAtleastOneKeyInArray = (object, keys) => {
  for (const k of keys) {
    if (typeof object[k] !== 'undefined') {
      return true
    }
  }
  return false
}
