import { produce } from 'immer'

import { types } from 'store/taskPanel/types'

const defaultState = {}

function addTmpId(state, id) {
  state[id] = true
  return state
}

function removeTmpId(state, id) {
  delete state[id]
  return state
}

export function tmpItemsReducer(state = defaultState, action) {
  return produce(state, (draft) => {
    const { payload } = action

    switch (action.type) {
      case types.ADD_TMP_ITEM: {
        return addTmpId(draft, payload)
      }

      case types.SET_TMP_ITEMS: {
        return defaultState
      }

      case types.REMOVE_TMP_ITEM: {
        return removeTmpId(draft, payload)
      }

      default: {
        return draft
      }
    }
  })
}
