import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { constants, styles, utils } from 'gipsy-misc'

import FixedTooltip from 'FixedTooltip'
import Icon from 'Icon'
import RadioInput from 'Radio'
import { LabelContainer, PinInfoGroup } from 'Sprint/DropdownSelector'

export default function DropdownSelector({ groupMode, onClick, selected, value }) {
  const [isHovered, setIsHovered] = useState(false)

  const pinTime = value.sprintInfo ? value.sprintInfo.pin.time : value.pin?.time
  const whenDate = value.sprintInfo ? moment(value.sprintInfo.pin.time).format('YYYY-MM-DD') : value.when?.date
  let endTime

  if (pinTime) {
    endTime = moment(pinTime).add(utils.datetime.convertNanosecondsToMinute(value.estimatedTime), 'm')
  } else if (whenDate) {
    endTime = moment(whenDate).endOf('day')
  }

  const isPast = !!endTime && endTime <= moment()

  return (
    <TaskContainer
      groupMode={groupMode}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}>
      <LabelContainer>
        <span>{value.title}</span>
      </LabelContainer>
      <StyledLabelContainer>
        <StyledPinInfoGroup
          color={isPast ? styles.colors.pinkColor : value.sprintInfo ? styles.colors.orangeColor : undefined}>
          {value.sprintInfo && (
            <FixedTooltip
              content={value.sprintInfo.title}
              horizontalPosition='left'
              portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
              style={{ padding: '8px' }}
              verticalOffset={-20}
              verticalPosition='bottom'>
              <SprintIcon fill={styles.colors.orangeColor} icon='Sprint' size={10} />
            </FixedTooltip>
          )}
          {pinTime && whenDate
            ? `${utils.datetime.beautifyDateWithDay(whenDate)} - ${utils.datetime.beautifyTime(pinTime)}`
            : whenDate
            ? `${utils.datetime.beautifyDateWithDay(whenDate)}`
            : ''}
        </StyledPinInfoGroup>
      </StyledLabelContainer>
      <StyledRadioInput
        checked={selected}
        checkedColor={styles.colors.primaryColor}
        disableStopPropagation
        isHovered={isHovered}
        size={16}
      />
    </TaskContainer>
  )
}

export const TaskContainer = styled.div`
  align-items: center;
  background-color: ${(props) => (props.isHovered ? styles.colors.veryLightGrey : 'inherit')};
  display: flex;
  padding: 12px 16px 16px;
  width: 100%;

  ${({ groupMode }) =>
    groupMode &&
    css`
      padding-left: 41px;
    `}
`

TaskContainer.displayName = 'TaskContainer'

const StyledLabelContainer = styled(LabelContainer)`
  align-items: center;
  flex-direction: row;
  margin: 0 16px 0 auto;
`

StyledLabelContainer.displayName = 'StyledLabelContainer'

const StyledPinInfoGroup = styled(PinInfoGroup)`
  align-items: center;
  min-width: 175px;
  justify-content: flex-end;
`

StyledPinInfoGroup.displayName = 'StyledPinInfoGroup'

const SprintIcon = styled(Icon)`
  margin-right: 16px;
`

SprintIcon.displayName = 'SprintIcon'

const StyledRadioInput = styled(RadioInput)`
  flex-shrink: 0;
`

StyledRadioInput.displayName = 'StyledRadioInput'
