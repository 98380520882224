import React, { useState, useCallback } from 'react'
import throttle from 'lodash/throttle'
import moment from 'moment'
import { SwitchTransition, Transition } from 'react-transition-group'
import {
  AddItemButtonsContainer,
  CreatingItemsSection as CreatingItemsSectionContainer,
  CreatingTaskContainer,
} from 'features/list/components/commonUI'
import HomebaseLine from 'features/list/components/line'
import CreatedTaskAlert from 'features/list/components/createdTaskAlert'
import { innerLeftPadding } from 'features/layout/pages'
import AddInlineTaskButton from 'features/list/components/addInlineTaskButton'
import AddFocusBlockButton from 'features/list/components/addFocusBlockButton'
import StartFocusSessionButton from 'features/list/components/startFocusSessionButton'

export default function CreatingItemsSection(props) {
  const {
    isCreatingInlineTask,
    isCreatingCalendarTask,
    creationLineDate,
    creationLineProps,
    isTaskCreationAlertShown,
    startInlineTaskCreation,
    startSprintCreation,
    onStartTimer,
    removeStartTimerButton,
    removeFocusBlockButton,
    session,
  } = props
  const [isStartTimerButtonHidden, setStartTimerButtonHidden] = useState(false)
  const isCreationLineShown = isCreatingInlineTask || creationLineProps.startCreation

  const onClickStartFocusSession = throttle(
    useCallback(async () => {
      setStartTimerButtonHidden(true)
      try {
        await onStartTimer(undefined, 'startTimerButton')
      } finally {
        setStartTimerButtonHidden(false)
      }
    }, [onStartTimer])
  )
  const isFocusSessionActive = session && session.focusSession && session.focusSession.id
  const isStartTimerButtonShown = !removeStartTimerButton && !isStartTimerButtonHidden && !isFocusSessionActive

  return (
    <CreatingItemsSectionContainer isCreationLineShown={isCreationLineShown}>
      <SwitchTransition mode={'in-out'}>
        <Transition
          key={isCreationLineShown ? 'in' : 'out'}
          timeout={{
            enter: isCreationLineShown ? 600 : 400,
            exit: 400,
          }}>
          {(transitionState) =>
            isCreationLineShown ? (
              <CreatingTaskContainer transitionState={transitionState}>
                {!isCreatingCalendarTask && (
                  <>
                    <HomebaseLine
                      {...creationLineProps}
                      componentSource='inlineAddTask'
                      creating
                      startCreation
                      hideCreationLinePlaceholder
                      hasStartFsAndCreateButton
                      when={{
                        date: creationLineDate || moment().format('YYYY-MM-DD'),
                      }}
                    />
                  </>
                )}
                <CreatedTaskAlert isShown={isTaskCreationAlertShown} />
              </CreatingTaskContainer>
            ) : (
              <AddItemButtonsContainer transitionState={transitionState} lateralMargin={innerLeftPadding}>
                {isStartTimerButtonShown && <StartFocusSessionButton onClick={onClickStartFocusSession} />}
                <AddInlineTaskButton onClick={startInlineTaskCreation} />
                {!removeFocusBlockButton && <AddFocusBlockButton onClick={startSprintCreation} />}
              </AddItemButtonsContainer>
            )
          }
        </Transition>
      </SwitchTransition>
    </CreatingItemsSectionContainer>
  )
}
