import React, { Component } from 'react'
import styled from 'styled-components'
import Icon from 'Icon'
import { styles } from 'gipsy-misc'

class AddTagButton extends Component {
  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    return (
      <PlusButton onClick={this.onClick}>
        <Icon icon="Plus" size={15} />
      </PlusButton>
    )
  }
}

const PlusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  cursor: pointer;
  background-color: ${styles.colors.buttonLightColor};
  width: 40px;
  height: 40px;
  line-height: 23px;
`

export default AddTagButton
