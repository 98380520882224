import { createAction } from 'redux-actions'

import { fetchItemsByDate } from 'store/calendar/actions/items'
import { types } from 'store/calendar/types'
import { utils } from 'gipsy-misc'

const _updateCalendarDate = createAction(types.SET_CALENDARDATE)
const _setCalendarExpanded = createAction(types.SET_CALENDAR_EXPANDED)
export const updateScrollToTime = createAction(types.SET_SCROLLTOTIME)

export const setCalendarExpanded = (expanded) => (dispatch) => dispatch(_setCalendarExpanded(expanded))

export const updateCalendarDate = (date, disableFetch) => async (dispatch) => {
  const newCalendarDate = new Date(date)
  let scrollToTime = new Date(newCalendarDate)
  if (utils.datetime.isToday(newCalendarDate)) {
    scrollToTime = new Date()
  } else {
    scrollToTime.setHours(9, 0, 0, 0)
  }
  if (!disableFetch) {
    dispatch(fetchItemsByDate({ date }))
  }
  dispatch(_updateCalendarDate(newCalendarDate))
  dispatch(updateScrollToTime(scrollToTime))
}
