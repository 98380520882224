import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import propTypes from './propTypes'
import { utils } from 'gipsy-misc'
export default function wrapper(Component) {
  class LineContainer extends PureComponent {
    static propTypes = {
      ...propTypes,
      index: PropTypes.number,
      history: PropTypes.object,
    }

    onClick = () => {
      if (this.props.onClick) {
        this.props.onClick(this.props.item.id, this.props.item.type, this.props.item.webLink)
      }
    }

    onStartFocus = ({ urlsToOpen }) => {
      const { item } = this.props
      this.props.startFocusSession({
        taskId: item.id,
        startTime: moment(),
        task: item,
        urlsToOpen: urlsToOpen,
      })
    }

    onPauseFocus = () => {
      const { session, item, updateTaskInState } = this.props
      const { discardFocusSession, focusSession, taskFocusSessions } = utils.focussession.addFocusSessionFromPause(
        session.focusSession
      )

      if (updateTaskInState && taskFocusSessions) {
        updateTaskInState(item, { paramName: 'focusSessions', value: taskFocusSessions }, null, {
          preventRefetch: true,
        })
      }

      this.props.pauseFocusSession(focusSession, { discardFocusSession })
    }

    onComplete = (data) => {
      const { session, item } = this.props
      const focusedTaskId = session.focusSession && session.focusSession.taskId
      const isTaskOnFocusSession = focusedTaskId && focusedTaskId === item.id

      if (isTaskOnFocusSession && data.value) {
        this.onCompleteFromFS(data)
      } else if (this.props.onComplete) {
        this.props.onComplete(data)
      }
    }

    onCompleteFromFS = (data) => {
      const { session } = this.props
      const newFocusSession = utils.focussession.getCompletedFocusSession(session.focusSession)
      this.props.onCompleteFromFS && this.props.onCompleteFromFS(data, newFocusSession)
    }

    onArchive = () => this.props.onArchive({ id: this.props.item.id })

    render() {
      const {
        item = {},
        creating,
        onMoveToTop,
        showSnackbar,
        onCancel,
        onCreate,
        onStartFsAndCreate,
        onChange,
        onSave,
      } = this.props

      return (
        <Component
          {...this.props}
          onStartFsAndCreate={onStartFsAndCreate}
          onClick={this.onClick}
          onComplete={this.onComplete}
          onChange={onChange}
          onCreate={onCreate}
          onCancel={onCancel}
          onArchive={this.onArchive}
          onStartFocus={this.onStartFocus}
          onPauseFocus={this.onPauseFocus}
          onSave={onSave}
          onMoveToTop={onMoveToTop || null}
          showSnackbar={showSnackbar}
          item={item}
          isCreating={creating} //TODO: standardize this props
        />
      )
    }
  }

  return LineContainer
}
