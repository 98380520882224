import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { produce } from 'immer'
import throttle from 'lodash/throttle'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { models, styles, translations, utils, variables as miscVariables } from 'gipsy-misc'
import { recurrenceComponentsUtils, ReusableButton, Separator, StepHelperComponents } from 'gipsy-ui'

import variables from 'assets/styles/variables'
import HomebaseLine from 'features/list/components/line'
import TitleSection from 'features/sprintComposer/components/TitleSection'
import Recurrency from 'features/sprintComposer/components/Recurrency'
import { openIntegrationOAuthWindow, getAsanaAuthUrlAndStoreState } from 'logic/integrationOauth'
import Asana from 'pages/integrations/asana'
import { fetchIntegrationsData, setIntegrationsData } from 'store/integrations/actions'
import { closePopup, openPopup } from 'store/popup/actions'
import { fetchActiveProjects } from 'store/project/actions'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { showSnackbar } from 'store/snackbar/actions'
import { addTask, removeTask, updateTask } from 'store/sprintComposer/actions'
import {
  addFilteredId,
  replaceFilteredId,
  removeFilteredId,
  setPanelExpanded,
  setPanelOpen,
} from 'store/taskPanel/actions'

export const droppableId = 'onboardingTasks'
export const droppableType = 'onboardingSprintComposer'
const componentName = 'AddTasks'
const { Container: HelperContainer, StepHelper, Tail: HelperTail } = StepHelperComponents
const { taskPanelContainerWidth } = variables

export default function AddTaskStep({
  onCreateTask,
  onDeleteTask,
  onSaveTask,
  onStepDone,
  session,
  sprintData: { recurrencyData, sprint },
  stepNumber,
}) {
  const addedTasks = useSelector((state) => state.sprintComposer.addedTasks)
  const dispatch = useDispatch()
  const integrationsData = useSelector((state) => state.integrations)
  const isDragging = useSelector((state) => state.sprintComposer.isDragging)

  const [editingRecurrencyDetails, setEditingRecurrencyDetails] = useState(recurrencyData)
  const [editingSprint, setEditingSprint] = useState({ ...sprint })
  const [isCreatingSprint, setIsCreatingSprint] = useState(false)
  const [showHelper, setShowHelper] = useState(true)
  const [startCreation, setStartCreation] = useState(false)

  const handleTitleChange = (e) => {
    const newTitle = e.target.value

    setEditingSprint((prev) =>
      produce(prev, (draft) => {
        draft.title = newTitle
      })
    )
  }

  const handleRecurrencyChosen = (type, customSettings = null) => {
    setEditingRecurrencyDetails((prev) =>
      produce(prev, (draft) => {
        draft.customTypeSettings = customSettings
        draft.recurrencyType = type
      })
    )
  }

  const handleTaskCreation = async (task) => {
    if (showHelper) {
      setShowHelper(false)
    }

    task = utils.ids.addIdToItem(task, models.item.type.TASK, session.id)
    dispatch(addTask(task))
    dispatch(addFilteredId(task.id))

    const result = await onCreateTask({ task })
    const createdTask = result.task

    if (createdTask.id !== task.id) {
      dispatch(updateTask(createdTask, task.id))
      dispatch(replaceFilteredId(task.id, createdTask.id))
    }
  }

  const handleTaskDeletion = (id) => {
    dispatch(removeTask(id))
    onDeleteTask(id)
  }

  const handleTaskSave = (task) => {
    onSaveTask(task)
  }

  const handleFocusBlockSave = useCallback(async () => {
    setIsCreatingSprint(true)

    const sprintWithTasks = {
      ...editingSprint,
      id: 'ONBOARDING',
      recurrencyInformation: {
        recurrencyDetails: recurrenceComponentsUtils.getRecurrencyDetails(editingRecurrencyDetails),
      },
      tasks: addedTasks,
      tasksId: addedTasks.map((task) => task.id),
    }

    if (!sprintWithTasks.recurrencyInformation.recurrencyDetails) {
      delete sprintWithTasks.recurrencyInformation
    } else {
      const recurringSprint = utils.recurrency.sprints.computeRecurringSprint(
        session.id,
        sprintWithTasks,
        sprintWithTasks.recurrencyInformation.recurrencyDetails
      )
      const [firstInstance] = utils.recurrency.sprints.scheduleNextSprints(recurringSprint)
      sprintWithTasks.pin = firstInstance.pin
      sprintWithTasks.when = firstInstance.when
    }

    onStepDone(stepNumber, sprintWithTasks)
  }, [addedTasks, editingRecurrencyDetails, editingSprint, onStepDone, session.id, stepNumber])

  const showSaveButton = !!addedTasks.length

  const activateTaskCreationState = () => setStartCreation(true)
  const deactivateTaskCreationState = () => setStartCreation(false)

  useEffect(() => {
    if (startCreation) return

    const shortcuts = [
      {
        key: 'q',
        label: translations.calendar.createTask,
        callback: activateTaskCreationState,
      },
    ]

    if (showSaveButton) {
      shortcuts.unshift({
        key: 'Enter',
        label: translations.general.save,
        callback: handleFocusBlockSave,
      })
    }

    dispatch(pushShortcutsGroup(shortcuts, componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch, handleFocusBlockSave, showSaveButton, startCreation])

  const closePopupAction = (...args) => dispatch(closePopup(...args))
  const fetchActiveProjectsAction = async (...args) => await dispatch(fetchActiveProjects(...args))
  const openPopupAction = (...args) => dispatch(openPopup(...args))

  const onClickAsanaApp = async () => {
    const authURL = await getAsanaAuthUrlAndStoreState()
    openIntegrationOAuthWindow(
      authURL,
      {
        onSuccess: () => dispatch(fetchIntegrationsData()),
        onError: (err) => {
          console.error(err)
          dispatch(
            showSnackbar(
              {
                message: 'Unexpected Error',
                status: 'error',
                showLogo: false,
                showClose: false,
              },
              5000
            )
          )
        },
      },
      { width: 800, height: 950 }
    )
  }

  const onSyncAsanaAccount = () => {
    dispatch(fetchIntegrationsData())
    dispatch(fetchActiveProjects())
  }

  const onUnsyncAsanaAccount = (accountId) => {
    dispatch(fetchActiveProjects())

    let newAsanaIntegrations = [...integrationsData.asanaIntegrations].filter(
      (asanaIntegration) => asanaIntegration.id !== accountId
    )

    dispatch(
      setIntegrationsData({
        ...this.props.integrationsData,
        asanaIntegrations: newAsanaIntegrations,
      })
    )
  }

  useEffect(() => {
    if (integrationsData?.asanaIntegrations) {
      setShowHelper(false)
      dispatch(setPanelOpen(true))
      dispatch(setPanelExpanded(true))
    }
  }, [dispatch, integrationsData])

  const throttledAddTaskToSprintComposer = throttle((task) => {
    dispatch(removeTask(task.id))
    dispatch(removeFilteredId(task.id))
  }, 300)

  const hasAsanaIntegrations = !!integrationsData?.asanaIntegrations

  return (
    <Container isDragging={isDragging} withTaskPanel={hasAsanaIntegrations}>
      <SprintContainer>
        <TitleSection
          isEmptyTitleErrorShown={!editingSprint.title.length}
          onChange={handleTitleChange}
          title={editingSprint.title}
        />
        <Separator />
        <RecurrenceContainer>
          <Recurrency {...editingRecurrencyDetails} onChange={handleRecurrencyChosen} />
        </RecurrenceContainer>
      </SprintContainer>
      <AnimatePresence>
        {showHelper && (
          <StepHelper
            animate={{ opacity: 1, transition: { delay: 1 }, top: 120 }}
            currentStep={3}
            exit={{ opacity: 0 }}
            helperText={translations.onboarding.addTasksStep.helperText}
            horizontal
            initial={{ opacity: 0, top: 100 }}
          />
        )}
      </AnimatePresence>
      <Tasks>
        <Droppable droppableId={JSON.stringify({ id: droppableId, type: droppableType })}>
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              {addedTasks.map((task, index) => (
                <Draggable draggableId={task.id} index={index} key={task.id}>
                  {(draggableProvided, snapshot) => (
                    <HomebaseLine
                      draggableProps={draggableProvided.draggableProps}
                      draggableStyle={draggableProvided.draggableProps.style}
                      dragHandleProps={draggableProvided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                      hideBlockToCalendarOption
                      hideCompleteCheckbox
                      hideDateInput
                      hideMoreActions
                      hideSprint
                      hideStartButton
                      hideWhenDate
                      innerRef={draggableProvided.innerRef}
                      item={task}
                      onClickLeftArrow={hasAsanaIntegrations && throttledAddTaskToSprintComposer}
                      onDelete={handleTaskDeletion}
                      onSave={handleTaskSave}
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
        <AddTasksBlock>
          <HomebaseLine
            creating
            hideBlockToCalendarOption
            hideDateInput
            hideMoreActions
            hideSprint
            onCancel={deactivateTaskCreationState}
            onCreate={handleTaskCreation}
            startCreation={startCreation}
            startSprintCreation={false}
          />
          {!hasAsanaIntegrations && (
            <>
              <OrSeparator>{translations.general.or}</OrSeparator>
              <Asana
                asanaIntegrations={integrationsData?.asanaIntegrations}
                closePopup={closePopupAction}
                fetchActiveProjects={fetchActiveProjectsAction}
                onClickConnect={onClickAsanaApp}
                onSyncAccount={onSyncAsanaAccount}
                onUnsyncAccount={onUnsyncAsanaAccount}
                openPopup={openPopupAction}
              />
            </>
          )}
        </AddTasksBlock>
      </Tasks>
      {showSaveButton && (
        <SaveFocusBlockContainer>
          <ReusableButton
            backgroundColor={styles.colors.orangeColor}
            borderRadius={8}
            disabled={isCreatingSprint}
            fontWeight={500}
            onClick={handleFocusBlockSave}
            width={172}>
            {translations.onboarding.addTasksStep.saveFocusBlock}
          </ReusableButton>
        </SaveFocusBlockContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 64px auto;
  max-height: calc(100vh - 128px);
  max-width: 688px;
  position: relative;
  width: 100%;

  ${HelperContainer} {
    left: 50%;
    opacity: 0;
    top: 100px;
    transform: translate(-50%, -100%);
    z-index: 3;
  }

  ${HelperTail} {
    left: 50%;
    top: calc(100% - 6px);
    transform: translateX(-50%) rotate(180deg);
  }

  ${({ isDragging, withTaskPanel }) =>
    withTaskPanel &&
    css`
      max-width: calc(688px + ${taskPanelContainerWidth}px);
      padding-left: ${taskPanelContainerWidth + 12}px;
      z-index: ${isDragging ? miscVariables.zIndex.taskPanel + 1 : miscVariables.zIndex.sprintComposer};
    `}
`

Container.displayName = 'Container'

const SprintContainer = styled.div`
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

SprintContainer.displayName = 'SprintContainer'

const RecurrenceContainer = styled.div`
  display: flex;
  margin: 12px 8px;
`

RecurrenceContainer.displayName = 'RecurrenceContainer'

const RecurrenceLabel = styled.div`
  align-items: center;
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  display: flex;
  padding: 8px 14px;
`

RecurrenceLabel.displayName = 'RecurrenceLabel'

const Tasks = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: auto;
  padding: 16px 0;
  position: relative;
`

Tasks.displayName = 'Tasks'

const AddTasksBlock = styled.div`
  bottom: 0;
  display: flex;
  flex-flow: column;
  margin-top: 16px;

  .creation-line-container {
    background: ${styles.colors.primaryColor};
    color: white;

    .icon {
      path {
        fill: white;
      }

      rect {
        stroke: white;
      }
    }
  }
`

AddTasksBlock.displayName = 'AddTasksBlock'

const OrSeparator = styled.div`
  align-items: center;
  color: ${styles.colors.darkGrey};
  display: flex;
  font-size: 11px;
  line-height: 16px;
  margin: 22px auto;
  padding: 8px 0;
  text-transform: uppercase;

  ::before,
  ::after {
    background: ${styles.colors.greyBorderColor};
    content: '';
    height: 1px;
    width: 97px;
  }

  ::before {
    margin-right: 14px;
  }

  ::after {
    margin-left: 14px;
  }
`

OrSeparator.displayName = 'OrSeparator'

const SaveFocusBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-top: 24px;
`

SaveFocusBlockContainer.displayName = 'SaveFocusBlockContainer'
