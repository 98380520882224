import React from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'

import { constants, styles, translations } from 'gipsy-misc'

import SquareCheckbox from 'Checkbox/SquareCheckbox'
import FixedTooltip from 'FixedTooltip'
import Icon from 'Icon'
import RecurrenceDropdownHandler from 'ListDropdowns/RecurrenceDropdownHandler'
import { GroupCaret } from 'Sprint/RecurrenceDropdownSelector'

import DropdownSelector, { TaskContainer } from './DropdownSelector'

export default function RecurrenceDropdownSelector({ onClick, recurrenceId, recurrenceInstances, selectedOption }) {
  const selectAllInstances = (e, allInstancesSelected) => {
    e.stopPropagation()

    if (allInstancesSelected) {
      onClick(null)
    } else {
      onClick({ id: recurrenceId, instances: recurrenceInstances })
    }
  }

  return (
    <RecurrenceDropdownHandler
      recurrenceId={recurrenceId}
      recurrenceInstances={recurrenceInstances}
      selectedOption={selectedOption}>
      {({
        allInstancesSelected,
        displayingAllInstances,
        firstInstance,
        instancesToRender,
        isGroupExpanded,
        loadMoreInstances,
        toggleGroupExpansion,
      }) => (
        <>
          <StyledTaskContainer onClick={toggleGroupExpansion}>
            <IconContainer fill={styles.colors.darkGrey} icon='Repeat' size={13} />
            <GroupTitle>{firstInstance.title}</GroupTitle>
            <CheckboxContainer>
              <FixedTooltip
                content={translations.general.addToAllInstances}
                horizontalPosition='left'
                portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
                style={{ padding: '8px' }}
                verticalOffset={-12}
                verticalPosition='bottom'>
                <SquareCheckbox
                  checked={allInstancesSelected}
                  color={styles.colors.primaryColor}
                  onClick={(e) => selectAllInstances(e, allInstancesSelected)}
                  size={16}
                />
              </FixedTooltip>
            </CheckboxContainer>
            <StyledGroupCaret expanded={isGroupExpanded} icon='CaretDown' fill={styles.colors.darkGrey} size={10} />
          </StyledTaskContainer>
          <StyledAnimateHeight duration={300} height={isGroupExpanded ? 'auto' : 0}>
            {instancesToRender.map((task) => (
              <DropdownSelector
                groupMode
                key={task.id}
                onClick={() => onClick(task)}
                selected={selectedOption?.id === task.id}
                value={task}
              />
            ))}
            {!displayingAllInstances && (
              <LoadMoreInstancesContainer>
                <LoadMoreInstancesButton onClick={loadMoreInstances}>
                  {translations.general.loadMoreInstances}
                </LoadMoreInstancesButton>
              </LoadMoreInstancesContainer>
            )}
          </StyledAnimateHeight>
        </>
      )}
    </RecurrenceDropdownHandler>
  )
}

const StyledTaskContainer = styled(TaskContainer)`
  min-height: 45px;

  :hover {
    background-color: ${styles.colors.veryLightGrey};
  }
`

StyledTaskContainer.displayName = 'StyledTaskContainer'

export const IconContainer = styled(Icon)`
  margin-right: 12px;
`

IconContainer.displayName = 'IconContainer'

const GroupTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`

GroupTitle.displayName = 'GroupTitle'

const CheckboxContainer = styled.div`
  display: flex;
  margin-left: auto;
`

CheckboxContainer.displayName = 'CheckboxContainer'

const StyledGroupCaret = styled(GroupCaret)`
  margin-left: 15px;
`

StyledGroupCaret.displayName = 'StyledGroupCaret'

const StyledAnimateHeight = styled(AnimateHeight)`
  width: 100%;
`

StyledAnimateHeight.displayName = 'StyledAnimateHeight'

const LoadMoreInstancesContainer = styled.div`
  display: flex;
  min-height: 33px;
`

LoadMoreInstancesContainer.displayName = 'LoadMoreInstancesContainer'

const LoadMoreInstancesButton = styled.span`
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  font-size: 12px;
  line-height: 40px;
  margin: auto;
  opacity: 0.6;
  text-decoration: underline;

  :hover {
    opacity: 1;
  }
`

LoadMoreInstancesButton.displayName = 'LoadMoreInstancesButton'
