import React, { PureComponent, Fragment } from 'react'
import styled from 'styled-components'
import { utils, translations } from 'gipsy-misc'
import Icon from 'Icon'
import Tooltip from 'Tooltip'
import { CardAttribute, IconWrapper, PlaceHolder, getColor } from './commonUIComponents'
import AttributeDropdown from 'ListDropdowns/Attribute'
import { DropdownSelector } from 'Tag'

export class TagsWrapper extends PureComponent {
  state = {
    tagsDropdownShown: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  showTagsDropdown = (value) => {
    this.setState({
      tagsDropdownShown: value,
    })
  }

  handleClickOutside = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      // we also avoid hiding tags when the user click on the field input
      // this logic is already handled by onWrapperClick
      if (this.fieldNode && !this.fieldNode.contains(e.target)) {
        this.showTagsDropdown(false)
      }
    }
  }

  onWrapperClick = () => {
    this.showTagsDropdown(!this.state.tagsDropdownShown)
  }

  addTag = (tag) => {
    const data = {
      paramName: 'tags',
      value: tag,
      method: 'add',
    }
    this.props.onChange(data)
  }

  removeTag = (tag) => {
    const data = {
      paramName: 'tags',
      value: tag,
      method: 'remove',
    }
    this.props.onChange(data)
  }

  onClick = (e) => {
    // we stop the propagation to avpoid triggerring the logic to unfocus fields
    e.stopPropagation()
    return false
  }

  onChange = (tag, selected) => {
    if (selected) {
      this.addTag(tag)
    } else {
      this.removeTag(tag)
    }
  }

  renderIcon() {
    const { shrinked, value } = this.props
    const { tagsDropdownShown } = this.state
    const hasValue = value && value.length

    return (
      <IconWrapper noMargin={shrinked}>
        <Icon size={14} icon='FilledTag' fill={getColor({ isSelected: tagsDropdownShown, hasValue: hasValue })} />
        <Tooltip text={`${translations.general.tags} $`} vs={25} hs={5} position='top left' />
      </IconWrapper>
    )
  }

  render() {
    const { shrinked, value, activeTags } = this.props
    const { tagsDropdownShown } = this.state

    const hasValue = value && value.length
    let label = utils.string.capitalize(translations.general.tags)
    const options = activeTags.map((activeTag) => ({ value: activeTag, label: activeTag.name }))
    if (hasValue) {
      label = (
        <Fragment>
          {value.map((el, i) => (
            <TagNameSpan key={el.id} first={i === 0}>
              {el.name}
            </TagNameSpan>
          ))}
        </Fragment>
      )
    }

    return (
      <StyledCardAttribute
        value={hasValue}
        focused={tagsDropdownShown}
        onClick={this.onWrapperClick}
        ref={(ref) => (this.fieldNode = ref)}
        squared={shrinked}>
        {shrinked ? (
          this.renderIcon()
        ) : (
          <>
            {this.renderIcon()}
            <PlaceHolderContainer color={getColor({ isSelected: tagsDropdownShown, hasValue: hasValue })}>
              {label}
            </PlaceHolderContainer>
          </>
        )}
        {tagsDropdownShown && (
          <TagsDropdownContainer ref={(ref) => (this.node = ref)} onClick={this.onClick}>
            <AttributeDropdown
              options={options}
              width={181}
              isMulti
              selectedOption={value}
              onSelect={this.onChange}
              emptyLabel={translations.tags.noTags}
              dropdownSelector={DropdownSelector}
            />
          </TagsDropdownContainer>
        )}
      </StyledCardAttribute>
    )
  }
}

const StyledCardAttribute = styled(CardAttribute)`
  max-width: 180px;
`

const PlaceHolderContainer = styled(PlaceHolder)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const TagsDropdownContainer = styled.div`
  left: 0;
  position: absolute;
  top: 32px;
  z-index: 3;
`

const TagNameSpan = styled.span`
  margin-left: ${(props) => (props.first ? 0 : '8px')};
`
