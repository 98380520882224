import React from 'react'
import styled from 'styled-components'
import Select from 'features/select'

import { styles, translations } from 'gipsy-misc'

export const departmentOption = 'department'
export const dailyTaskLoadOption = 'dailyTaskLoad'
export const toolTrackTasksOption = 'toolTrackTasks'

const options = {
  [departmentOption]: [
    { value: 'businessOwner', label: 'Business Owner' },
    { value: 'customerSuccess', label: 'Customer Success' },
    { value: 'engineering', label: 'Engineering' },
    { value: 'finance', label: 'Finance' },
    { value: 'independent', label: 'Independent' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'product', label: 'Product' },
    { value: 'sales', label: 'Sales' },
    { value: 'student', label: 'Student' },
    { value: 'support', label: 'Support' },
    { value: 'other', label: 'Other' },
  ],
  [dailyTaskLoadOption]: [
    { value: 'manyTasks', label: 'Many tasks (>5)' },
    { value: 'fewLong', label: 'Few, long tasks' },
    { value: 'varies', label: 'Varies a lot' },
  ],
  [toolTrackTasksOption]: [
    { value: 'asana', label: 'Asana' },
    { value: 'notion', label: 'Notion' },
    { value: 'todoist', label: 'Todoist' },
    { value: 'trello', label: 'Trello' },
    { value: 'otherTaskSoftware', label: 'Other task software' },
    { value: 'paper', label: 'Paper' },
    { value: 'nothing', label: 'Nothing' },
  ],
}

export const SurveyOption = (props) => {
  const optionName = props.name

  const onChange = (value) => {
    props.onChange(optionName, value)
  }

  return (
    <SurveyOptionContainer>
      <SurveyOptionTitle>{translations.onboarding.survey[optionName].title}</SurveyOptionTitle>
      <Select isClearable={true} options={options[optionName]} onChange={onChange} />
    </SurveyOptionContainer>
  )
}

const SurveyOptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const SurveyOptionTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${styles.colors.textMediumDarkColor};
  margin-right: 16px;
  max-width: 250px;
  line-height: 24px;
`
