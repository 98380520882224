import { createAction } from 'redux-actions'
import { types } from 'store/taskPanel/types'

const _addItem = createAction(types.ADD_ITEM)
const _updateItem = createAction(types.UPDATE_ITEM)
const _removeItem = createAction(types.REMOVE_ITEM)
const _addSprintTask = createAction(types.ADD_SPRINT_TASK)
const _removeSprintTask = createAction(types.REMOVE_SPRINT_TASK)

export const addItem = (item) => (dispatch) => dispatch(_addItem({ item }))
export const updateItem = (updatedItem, itemId) => (dispatch) => dispatch(_updateItem({ itemId, item: updatedItem }))
export const removeItem = (itemId) => (dispatch) => dispatch(_removeItem({ itemId }))

export const addTaskInSprint = (taskToAdd, sprint) => (dispatch) =>
  dispatch(_addSprintTask({ item: taskToAdd, sprint }))

export const removeTaskInSprint = (taskToRemove, sprint) => (dispatch) =>
  dispatch(_removeSprintTask({ item: taskToRemove, sprint }))
