import { utils } from 'gipsy-misc'

import axios from 'utils/axios'

export function getAuthURL(teamId = '') {
  let url = `/slack/authurl`
  if (teamId) {
    url = utils.request.appendToUrl(url, { teamId })
  }
  return axios.get(url)
}

export function authenticate(state, code) {
  let url = `/slack/authenticate`
  url = utils.request.appendToUrl(url, { state, code })
  return axios.get(url)
}
