import React from 'react'
import styled from 'styled-components'

const RedDot = ({ className, ...rest }) => <RedDotContainer {...rest} className={className + ' red-dot'} />

export default RedDot

const RedDotContainer = styled.div`
  display: block;
  width: ${(props) => (props.size ? props.size : '14px')};
  height: ${(props) => (props.size ? props.size : '14px')};
  border: 3px solid;
  border-color: ${(props) => (props.borderColor ? props.borderColor : 'white')};
  border-radius: 50%;
  background-color: red;
`
