import React, { Component } from 'react'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { GoogleButton, MicrosoftButton } from 'gipsy-ui'

import { PageContainer } from 'features/auth/components'

import FeaturesColumn, { Container, Title } from './components/FeaturesColumn'
import container from './container'
class LoginPage extends Component {
  render() {
    const { accountDeleted, multiCalendarSyncExp, goToGoogleAuth, goToMicrosoftAuth } = this.props

    return (
      <StyledPageContainer>
        <FeaturesColumn accountDeleted={accountDeleted} multiCalendarSyncExp={multiCalendarSyncExp} />
        <LoginColumn>
          <AuthButtonsCard>
            <AuthCardTitle>{translations.login.cardTitle}</AuthCardTitle>
            <AuthButtons>
              <GoogleButton label={'Google'} onClick={goToGoogleAuth} />
              <MicrosoftButton label={'Microsoft'} onClick={goToMicrosoftAuth} />
            </AuthButtons>
          </AuthButtonsCard>
        </LoginColumn>
      </StyledPageContainer>
    )
  }
}

const StyledPageContainer = styled(PageContainer)`
  flex-flow: row;
  justify-content: flex-start;
`

StyledPageContainer.displayName = 'StyledPageContainer'

const LoginColumn = styled(Container)`
  align-items: center;
  background-color: unset;
  padding: 0 24px;
  width: 60%;
`

LoginColumn.displayName = 'LoginColumn'

const AuthButtonsCard = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: ${styles.shadows.taskMenuHover};
  min-width: 62%;
  padding: 30px;
  transform: translateY(22%);
`

AuthButtonsCard.displayName = 'AuthButtonsCard'

const AuthCardTitle = styled(Title)`
  font-size: 18px;
  margin: 0 auto 28px;
  text-align: center;
`

AuthCardTitle.displayName = 'AuthCardTitle'

const AuthButtons = styled.div`
  margin: 0 60px;

  button {
    font-size: 18px;
    font-weight: 700;
    height: 56px;
    margin: 0 auto;
    max-width: 376px;
    width: 100%;

    &:first-of-type {
      margin-bottom: 8px;
    }
  }

  img {
    height: 32px;
    width: 32px;
  }
`

AuthButtons.displayName = 'AuthButtons'

export default container(LoginPage)
