import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'Icon'

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string,
}

const defaultProps = {
  color: 'default',
}

class LabelIcon extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hover: false,
      showText: false,
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout)
  }

  mouseEnter = () => {
    this.setState({ hover: true, showText: true })
    clearTimeout(this.timeout)
  }

  mouseLeave = () => {
    this.setState({ hover: false })
    this.timeout = setTimeout(() => {
      this.setState({ showText: false })
    }, 500)
  }

  render() {
    const { icon, className, color, value } = this.props
    const { hover, showText } = this.state
    let finalClassName =
      'gp-label-content' + (className ? ` ${className}` : '') + (color ? ` ${color}` : '') + (hover ? ' hover' : '')
    return (
      <span className="gp-label" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        <span className={finalClassName}>
          <StyledIcon icon={icon} fill="white" />
          {value ? <span className="gp-label-text">{showText ? value : ''}</span> : ''}
        </span>
      </span>
    )
  }
}

LabelIcon.propTypes = propTypes
LabelIcon.defaultProps = defaultProps

export default LabelIcon

const StyledIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  min-width: 14px;
  margin-right: 5px;
`
