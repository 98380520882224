import makeRequest from 'utils/request'
import { response, request } from 'utils/interceptors'
import { instance as axios } from './instance'

axios.interceptors.response.use(response.success, response.error)
axios.interceptors.request.use(request.success, request.error)

const axiosFuncs = {
  get(endpoint, settings) {
    return makeRequest('get', endpoint, null, settings)
  },

  post(endpoint, body, settings) {
    return makeRequest('post', endpoint, body, settings)
  },

  put(endpoint, body, settings) {
    return makeRequest('put', endpoint, body, settings)
  },

  patch(endpoint, body, settings) {
    return makeRequest('patch', endpoint, body, settings)
  },

  delete(endpoint, body, settings) {
    return makeRequest('delete', endpoint, settings)
  },
}

export default axiosFuncs
