import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { Icon, TaskFiltersComponents } from 'gipsy-ui'

export const RANGES = {
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  LAST_MONTH: 'LAST_MONTH',
  LAST_WEEK: 'LAST_WEEK',
}

function TimeDropdown({ label, onChange, value }) {
  const [expanded, setExpanded] = useState(false)

  const containerRef = useRef(null)

  const getRangeLabel = (rangeId) => {
    switch (rangeId) {
      case RANGES.MONTH: {
        return translations.timeReports.dropdown.month
      }

      case RANGES.WEEK: {
        return translations.timeReports.dropdown.week
      }

      case RANGES.LAST_MONTH: {
        return translations.timeReports.dropdown.lastMonth
      }

      case RANGES.LAST_WEEK: {
        return translations.timeReports.dropdown.lastWeek
      }

      default: {
        return null
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!containerRef?.current?.contains(e.target)) {
        setExpanded(false)
      }
    }

    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <FilterContainer onClick={() => setExpanded((prev) => !prev)} ref={containerRef}>
      <StyledFilterTitle>{label || getRangeLabel(value)}</StyledFilterTitle>
      <Icon size={10} icon={expanded ? 'SingleChevronDown' : 'SingleChevronLeft'} unclickable />
      {expanded && (
        <FilterItemList>
          <FilterItem onClick={() => onChange(RANGES.MONTH)}>
            <FilterItemLabel>{getRangeLabel(RANGES.MONTH)}</FilterItemLabel>
          </FilterItem>
          <FilterItem onClick={() => onChange(RANGES.WEEK)}>
            <FilterItemLabel>{getRangeLabel(RANGES.WEEK)}</FilterItemLabel>
          </FilterItem>
          <FilterItem onClick={() => onChange(RANGES.LAST_MONTH)}>
            <FilterItemLabel>{getRangeLabel(RANGES.LAST_MONTH)}</FilterItemLabel>
          </FilterItem>
          <FilterItem onClick={() => onChange(RANGES.LAST_WEEK)}>
            <FilterItemLabel>{getRangeLabel(RANGES.LAST_WEEK)}</FilterItemLabel>
          </FilterItem>
        </FilterItemList>
      )}
    </FilterContainer>
  )
}

const { FilterContainer, FilterItem, FilterItemLabel, FilterItemList, FilterTitle } = TaskFiltersComponents

const StyledFilterTitle = styled(FilterTitle)`
  color: ${styles.colors.textMediumDarkColor};
`

export default TimeDropdown
