import { types } from 'store/calendar/types'

const initialState = {
  calendarDate: new Date(),
  isExpanded: false,
  lastActionTime: null,
  scrollToTime: new Date(),
}

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CALENDARDATE:
      return {
        ...state,
        calendarDate: action.payload,
      }

    case types.SET_SCROLLTOTIME:
      return {
        ...state,
        scrollToTime: action.payload,
      }

    case types.SET_CALENDAR_EXPANDED:
      return {
        ...state,
        isExpanded: action.payload,
      }

    default:
      break
  }
  return state
}
