import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Icon from 'Icon'
import Tooltip from 'Tooltip'
import { CardAttribute, IconWrapper, PlaceHolder, getColor } from 'EditingLine/components/commonUIComponents'
import PopupCreationItem from './components/PopupCreationItem'

import { models, translations, utils } from 'gipsy-misc'
import LinkListPopup, { StyledPopup } from 'LineComponents/LinkListPopup'

export default class LinkWrapper extends PureComponent {
  state = {
    linkContainerShown: false,
  }

  containerRef = React.createRef()
  popUpWrapperRef = React.createRef()

  showLinkContainer = (value) => {
    this.setState({
      linkContainerShown: value,
    })
  }

  handleClickOutside = (e) => {
    if (!this.containerRef.current?.contains?.(e.target)) {
      this.showLinkContainer(false)
    }
  }

  onWrapperClick = (e) => {
    if (this.popUpWrapperRef.current?.contains?.(e.target)) {
      this.showLinkContainer(true)
    } else {
      this.showLinkContainer(!this.state.linkContainerShown)
    }
  }

  onAddLink = (url) => {
    const { value } = this.props
    const newUrlInfo = models.urlinfo({
      url: url,
    })

    const newValue = value && value.length > 0 ? [...value] : []
    newValue.push(newUrlInfo)

    this.props.onChange && this.props.onChange({ value: newValue })
  }

  onSave = (url, index) => {
    const { value } = this.props

    const newValue = value && value.length > 0 ? [...value] : []
    if (newValue && index < newValue.length) {
      const urlInfo = { ...newValue[index] }
      urlInfo.url = url
      newValue[index] = urlInfo
      this.props.onChange && this.props.onChange({ value: newValue })
    }
  }

  onDelete = (index) => {
    const { value } = this.props
    const newValue = value && value.length > 0 ? [...value] : []
    newValue.splice(index, 1)
    this.props.onChange && this.props.onChange({ value: newValue })
  }

  onClickLink = (url) => {
    window.open(utils.url.setHttp(url), '_blank')
  }

  renderIcon() {
    const { shrinked, value } = this.props
    const { linkContainerShown } = this.state
    const hasValue = value && value.length

    return (
      <IconWrapper noMargin={shrinked}>
        <Icon size={14} icon="Link" fill={getColor({ isSelected: linkContainerShown, hasValue: hasValue })} />
        <Tooltip text={translations.task.urlInfo.fieldLabel} vs={25} hs={5} position="top left" />
      </IconWrapper>
    )
  }

  computeDropdownLeftPosition = () => {
    let left = 0

    if (this.containerRef?.current) {
      left = -this.containerRef.current.offsetLeft + 5
    }

    if (this.popUpWrapperRef?.current) {
      const { right } = this.popUpWrapperRef.current.getBoundingClientRect()
      const calendarPanelWidth = 296
      const maxRight = window.innerWidth - calendarPanelWidth
      if (right > maxRight) {
        left -= right - maxRight
      }
    }

    return left
  }

  render() {
    const { maxWidth, shrinked, value } = this.props
    const hasValue = value && value.length > 0
    const { linkContainerShown } = this.state
    return (
      <StyledCardAttribute
        value={hasValue}
        focused={linkContainerShown}
        onClick={this.onWrapperClick}
        ref={this.containerRef}
        squared={shrinked}
        leftPopup={this.computeDropdownLeftPosition()}>
        {shrinked ? (
          this.renderIcon()
        ) : (
          <>
            {this.renderIcon()}
            <PlaceHolderContainer color={getColor({ isSelected: linkContainerShown, hasValue: hasValue })}>
              {utils.task.computeLinksPlaceholder(value)}
            </PlaceHolderContainer>
          </>
        )}
        {linkContainerShown && (
          <div ref={this.popUpWrapperRef}>
            <LinkListPopup
              itemProps={{ onDelete: this.onDelete, onSave: this.onSave }}
              maxWidth={maxWidth}
              onClickOutside={this.handleClickOutside}
              urlsInfo={value}>
              <PopupCreationItem onAdd={this.onAddLink} />
            </LinkListPopup>
          </div>
        )}
      </StyledCardAttribute>
    )
  }
}

const StyledCardAttribute = styled(CardAttribute)`
  max-width: 180px;

  ${StyledPopup} {
    left: ${(props) => props.leftPopup}px;
    top: 110%;
  }
`

const PlaceHolderContainer = styled(PlaceHolder)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
