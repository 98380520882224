import produce from 'immer'

import models from 'models'

type MessageListener = {
  component: string
  handler: (message: string, topic: string) => void
}

const topicList: string[] = Object.values(models.realtime.topics)

const validateTopic = (topic: string): void => {
  if (!topicList.includes(topic)) {
    throw new Error(`Realtime topic "${topic}" is not a valid one.`)
  }
}

class RealTime {
  constructor(
    public wsURLResolver: () => Promise<string>,
    private connected: boolean,
    private connecting: boolean,
    private messageListeners: { [key: string]: MessageListener[] },
    private toPublishMessages: (() => void)[],
    private ws: WebSocket | null
  ) {
    this.connected = false
    this.connecting = false
    this.messageListeners = {}
    this.toPublishMessages = []
    this.ws = null
    this.wsURLResolver = wsURLResolver
  }

  connect = async (): Promise<void> => {
    if (this.connected || this.connecting) return

    try {
      this.connecting = true
      const wssURL = await this.wsURLResolver()
      const socket = new WebSocket(wssURL)
      socket.onopen = this.onConnectionOpened
      socket.onclose = this.onConnectionClosed
      socket.onerror = this.onError
      socket.onmessage = this.onMessage
      this.ws = socket
    } catch (err) {
      console.error(err)
      this.connecting = false
    }
  }

  disconnect = (): void => {
    if (!this.ws || !this.connected) return

    this.ws.close()
    this.messageListeners = {}
    this.toPublishMessages = []
  }

  onConnectionOpened = (): void => {
    this.connected = true
    this.connecting = false
    const ws = this.ws as WebSocket
    ws.send(JSON.stringify({ action: models.realtime.actions.subscribe, topics: topicList }))

    if (this.toPublishMessages.length > 0) {
      this.toPublishMessages = produce(this.toPublishMessages, (draft) => {
        draft.forEach((publishFn) => {
          publishFn()
          draft.pop()
        })
      })
    }
  }

  onConnectionClosed = (): void => {
    this.connected = false
    this.connecting = false
  }

  onError = (err: Event): void => {
    console.error(err)
  }

  onMessage = (e: MessageEvent): void => {
    const data = JSON.parse(e.data)
    const { message, topic } = data

    if (this.messageListeners[topic]) {
      this.messageListeners[topic].forEach((listener) => {
        listener?.handler?.(message, topic)
      })
    }
  }

  publishMessage = (message: string, topics: string[] = []): void => {
    topics.forEach((topic) => {
      validateTopic(topic)
    })

    const publishFn = () => {
      const ws = this.ws as WebSocket
      ws.send(JSON.stringify({ action: models.realtime.actions.publish, message, topics }))
    }

    if (!this.connected) {
      this.toPublishMessages = produce(this.toPublishMessages, (draft) => {
        draft.push(publishFn)
      })
    } else {
      publishFn()
      return
    }

    if (!this.connecting && (!this.ws || this.ws.readyState !== WebSocket.OPEN)) {
      this.connect()
      return
    }
  }

  addMessageListener = (component: string, topic: string, handler: () => void): void => {
    validateTopic(topic)
    this.messageListeners = produce(this.messageListeners, (draft) => {
      if (!draft[topic]) {
        draft[topic] = []
      }

      const prevListenerIdx = draft[topic].findIndex((listener) => listener.component === component)
      const newListener = { component, handler }

      if (prevListenerIdx > -1) {
        draft[topic].splice(prevListenerIdx, 1, newListener)
      } else {
        draft[topic].push(newListener)
      }
    })
  }

  removeMessageListener = (component: string, topic: string): void => {
    validateTopic(topic)

    this.messageListeners = produce(this.messageListeners, (draft) => {
      if (!draft[topic]) return

      const listenerIdx = draft[topic].findIndex((listener) => listener.component === component)
      draft[topic].splice(listenerIdx, 1)
    })
  }
}

export default RealTime
