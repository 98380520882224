import { types } from 'store/tag/types'
import { moveElementInArrFromPositionToPosition } from 'logic/list'

const initialState = {
  items: [],
  areTagsLoaded: false,
}

const tagsReducer = (state = initialState, action) => {
  const { payload } = action
  let items = []
  switch (action.type) {
    case types.FETCH_TAGS:
      return {
        ...state,
        items: [...items, ...payload.tags],
      }

    case types.ADD_TAG:
      if (!payload || !payload.tag) {
        return state
      }
      items = [...state.items, payload.tag]
      return {
        ...state,
        items,
      }

    case types.REORDER_TAG:
      if (!payload || isNaN(payload.from) || isNaN(payload.to) || !payload.tag) {
        return state
      }

      items = [...state.items]
      const { from, to, tag } = payload
      items = moveElementInArrFromPositionToPosition(items, tag, from, to)
      return {
        ...state,
        items,
      }

    case types.UPDATE_TAG:
      if (!payload || !payload.tagId || !payload.tag) {
        return state
      }

      const tagIndex = state.items.findIndex((item) => item.id === payload.tagId)
      if (tagIndex === -1) {
        return state
      }

      items = [...state.items]
      items[tagIndex] = payload.tag
      return {
        ...state,
        items,
      }
    case types.REMOVE_TAG:
      if (!payload || !payload.tagId) {
        return state
      }

      items = [...state.items]
      items = items.filter((item) => item.id !== payload.tagId)

      return {
        ...state,
        items,
      }

    case types.SET_TAGS_LOADED:
      return {
        ...state,
        areTagsLoaded: action.payload,
      }

    default:
      break
  }
  return state
}

export default tagsReducer
