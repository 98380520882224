import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import DefaultPicture from 'DefaultPicture'

class UserPicture extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    src: PropTypes.string,
    borderRadius: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    iconColor: PropTypes.string,
    showDefaultIcon: PropTypes.bool,
    style: PropTypes.object,
    border: PropTypes.string /* ie: 1px solid white */,
    size: PropTypes.number.isRequired,
    sizeDefault: PropTypes.number,
    shake: PropTypes.bool,
    opacity: PropTypes.number,
  }

  static defaultProps = {
    borderRadius: '50%',
    size: 32,
    opacity: 1,
  }

  render() {
    const {
      className,
      src,
      backgroundColor,
      iconColor,
      showDefaultIcon,
      borderRadius,
      style,
      border,
      size,
      sizeDefault,
      shake,
      opacity,
    } = this.props

    return (
      <Container
        className={'user-picture ' + className}
        borderRadius={borderRadius}
        size={size}
        style={style}
        shake={shake}>
        {src ? (
          <Image
            className={'user-image'}
            src={src}
            size={size}
            border={border}
            borderRadius={borderRadius}
            opacity={opacity}
          />
        ) : (
          <DefaultPicture
            className={'default-picture'}
            border={border}
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
            iconColor={iconColor}
            showIcon={showDefaultIcon}
            size={sizeDefault || size}
            opacity={opacity}
          />
        )}
        {this.props.children}
      </Container>
    )
  }
}

export default UserPicture

const shakeAnimation = keyframes`
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: rotate(-4deg);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: rotate(4deg);
  }
`

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  border-radius: ${(props) => props.borderRadius};
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  ${(props) => props.style} ${({ shake }) =>
    shake &&
    css`
      animation: ${shakeAnimation} 0.82s linear infinite;
      backface-visibility: hidden;
      perspective: 1000px;
    `};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  opacity: ${(props) => props.opacity};
  background-image: url('${(props) => props.src}');
  background-size: cover;
  border-radius: ${(props) => props.borderRadius};
  flex-shrink: 0;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  ${(props) => props.border && `border: ${props.border};`}
`
