import React, { PureComponent } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import Line from 'Line'
import { utils } from 'gipsy-misc'

import FocusSessionStats from './focusSessionStats'

class FocusedLine extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shown: false,
      item: null,
    }
    this.timeoutId = null
    this.focusedLineRef = React.createRef()
  }

  componentDidMount() {
    this.componentDidUpdate(this.props)
  }

  componentDidUpdate(prevProps, prevState) {
    const { shown, item } = this.state
    const { session, onAnimatedLogoOpening, onAnimatedLogoClosing } = this.props
    const focusedItem = utils.session.getFSTaskIdFromSession(session) ? session.focusSession.task : null
    const prevFocusedItem = utils.session.getFSTaskIdFromSession(prevProps.session)
      ? prevProps.session.focusSession.task
      : null
    if (shown && item && !focusedItem) {
      onAnimatedLogoOpening && onAnimatedLogoOpening()
      this.setState({
        shown: false,
        item: null,
      })
    }
    if (!shown && focusedItem && !session.hideFocusedTask) {
      this.setState(
        {
          shown: true,
          item: focusedItem,
        },
        () => {
          setTimeout(() => {
            //wait for a render in order to trigger the animation
            if (this.props.session.muteModeOn) {
              onAnimatedLogoClosing && onAnimatedLogoClosing()
            }
          }, 1)
        }
      )
    } else if (focusedItem !== prevFocusedItem) {
      this.setState({ item: focusedItem })
    }
  }

  render() {
    const {
      innerLeftPadding = 0,
      innerRightPadding = 0,
      session,
      color,
      playIconBackground,
      marginTop,
      disableEnterAnimation,
      distractionButtonMargin,
      registerShortcuts,
      unregisterShortcuts,
      marginBottom,
      statsMarginBottom,
      onTitleChange,
      showSprintInfo,
      shouldFocusTitle,
      handleCompletedSession,
      onClickFocusSession,
      onDeleteFocusSession,
      onUpdateFocusSession,
      setHighlightedEventId,
    } = this.props

    const { completedSession } = session

    const { shown, item } = this.state

    const completed = !!completedSession

    if (!completed && !item) return null

    let containerMarginTop = 0
    if (shown || disableEnterAnimation || completed) {
      containerMarginTop = isNaN(parseInt(marginTop)) ? 30 : marginTop
    }

    return (
      <Wrapper
        ref={this.focusedLineRef}
        shown={shown || disableEnterAnimation}
        distractionButtonMargin={distractionButtonMargin}
        marginBottom={isNaN(parseInt(marginBottom)) ? 20 : marginBottom}
        statsMarginBottom={isNaN(+statsMarginBottom) ? 0 : statsMarginBottom}
        leftPadding={innerLeftPadding}
        rightPadding={innerRightPadding}>
        <Container shown={shown || disableEnterAnimation} marginTop={containerMarginTop}>
          <AnimatePresence>
            {!completed && (
              <AnimatedWrapper exit={{ opacity: 0, position: 'absolute' }} initial={false}>
                <Line
                  pauseFocusSession={this.props.onPauseFocusSession}
                  completeTask={this.props.onCompleteFromFS}
                  onCompleteFromFS={this.props.onCompleteFromFS}
                  updateTaskInState={this.props.updateTaskInState}
                  disableEdit
                  disableHover
                  showSprintInfo={showSprintInfo}
                  hideWhenDate={true}
                  isSprintTask={item.sprintInfo && item.sprintInfo.id}
                  noHover
                  shouldFocusEditableTitle={shouldFocusTitle}
                  noMargin
                  color={color}
                  playIconBackground={playIconBackground}
                  item={item}
                  lineThrough={!!item.completed}
                  keepJustCompleted
                  session={session}
                  animateComplete
                  registerShortcuts={registerShortcuts}
                  unregisterShortcuts={unregisterShortcuts}
                  showSnackbar={this.props.showSnackbar}
                  onTitleChange={onTitleChange}
                  onClickFocusSession={onClickFocusSession}
                  onDeleteFocusSession={onDeleteFocusSession}
                  onUpdateFocusSession={onUpdateFocusSession}
                  setHighlightedEventId={setHighlightedEventId}
                />
              </AnimatedWrapper>
            )}
          </AnimatePresence>
          {completed && (
            <AnimatedWrapper animate="expandIn" initial="initial" key="session-stats" variants={statsVariants}>
              <FocusSessionStats
                onOkClicked={handleCompletedSession}
                registerShortcuts={registerShortcuts}
                session={session.completedSession}
                unregisterShortcuts={unregisterShortcuts}
              />
            </AnimatedWrapper>
          )}
        </Container>
      </Wrapper>
    )
  }
}

const statsVariants = {
  expandIn: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        bounce: 0.4,
        delay: 0.5,
        duration: 0.8,
        type: 'spring',
      },
      opacity: { delay: 1.25 },
    },
    visibility: 'visible',
  },
  initial: { height: 0, opacity: 0, visibility: 'hidden' },
}

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: ${(props) => props.leftPadding}px;
  padding-right: ${(props) => props.rightPadding}px;
  margin-bottom: ${(props) =>
    props.shown ? props.marginBottom : props.statsMarginBottom ? props.statsMarginBottom : 0}px;
  z-index: 1;
`

const Container = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0 16px 48px rgba(33, 21, 81, 0.08);
  border-radius: 8px;
  margin-top: ${(props) => props.marginTop}px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const AnimatedWrapper = styled(motion.div)`
  width: 100%;
`

export default FocusedLine
