import { type } from './item'

export const CalendarMode = {
  COMPLETED: 'completed',
  REGULAR: 'regular',
}

export const initialFocusSession = (params) => ({
  taskId: '',
  startTime: new Date(),
  task: null,
  type: type.FOCUSSESSION,
  ...params,
})
