import React, { useEffect, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'

import variables from 'assets/styles/variables'
import UserSelect from 'features/asana/UserSelect'
import { SyncingLoading } from 'features/integrations/components'
import { changeSelectValueOfProject, selectAllProjects } from 'logic/asana'

import { BlurLayer, Icon, PrimaryButton } from 'gipsy-ui'
import { asana as asanaApi } from 'gipsy-api'
import { styles, translations } from 'gipsy-misc'

function AsanaSelectModal(props) {
  const [isActive, setActive] = useState(false)
  const [showPanel, setShowPanel] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  const [savingData, setSavingData] = useState(false)
  const [userData, setUserData] = useState(undefined)

  const { onCancel, showModal, account, fetchActiveProjects } = props

  useEffect(() => {
    if (!showModal) {
      setActive(false)
    } else {
      setActive(true)
    }
  }, [showModal])

  useEffect(() => {
    setShowPanel(isActive)
  }, [isActive])

  useEffect(() => {
    if (account?.id) {
      fetchData(account.id)
    } else {
      setUserData(undefined)
    }
  }, [account])

  const fetchData = async (asanaUserId) => {
    setFetchingData(true)
    const fetchedData = (await asanaApi.getUserData(asanaUserId)) || {}
    setUserData(fetchedData)
    setFetchingData(false)
  }

  const saveData = async () => {
    setSavingData(true)
    try {
      await asanaApi.selectProjects(userData)
      await fetchActiveProjects()
    } catch (err) {
      console.error(err)
    } finally {
      setSavingData(false)
      onCancel()
    }
  }

  const onSelectProject = useCallback(
    ({ workspaceIdx, teamIdx = -1, projectIdx }) => {
      let newUserData = JSON.parse(JSON.stringify(userData))
      newUserData = changeSelectValueOfProject(newUserData, { workspaceIdx, teamIdx, projectIdx })
      setUserData(newUserData)
    },
    [userData]
  )

  const onSelectAllProjects = useCallback(
    ({ workspaceIdx, teamIdx = -1 }, value) => {
      let newUserData = JSON.parse(JSON.stringify(userData))
      newUserData = selectAllProjects(newUserData, { workspaceIdx, teamIdx }, value)
      setUserData(newUserData)
    },
    [userData]
  )

  const loadingData = fetchingData || savingData

  return (
    <StyledBlurLayer active={isActive} name={'asana-select-panel'} onClose={onCancel}>
      {/* it is important to keep the showPanel there to make sure the component is unmounted once hidden */}
      {showPanel && (
        <Container loadingData={loadingData}>
          {loadingData ? (
            <SyncingLoading
              height={'100%'}
              text={savingData ? translations.integrations.syncingData : translations.integrations.fetchingData}
            />
          ) : userData ? (
            <>
              <UserSelect
                userData={userData}
                onSelectProject={onSelectProject}
                onSelectAllProjects={onSelectAllProjects}>
                <CloseIconContainer onClick={onCancel}>
                  <Icon icon='Close' className='close-icon' size={12} />
                </CloseIconContainer>
              </UserSelect>
              <PrimaryButtonContainer>
                <PrimaryButton onClick={saveData} text={translations.general.save} height={44} width={140} />
              </PrimaryButtonContainer>
            </>
          ) : (
            <div />
          )}
        </Container>
      )}
    </StyledBlurLayer>
  )
}

export default React.memo(AsanaSelectModal)

const StyledBlurLayer = styled(BlurLayer)`
  &.asana-select-panel-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    ::before {
      background: ${styles.colors.textMediumDarkColor};
    }
    .asana-select-panel-content {
      overflow: visible;
      opacity: 0;
      position: relative;
      width: 75%;
      max-width: 1440px;
    }
    &.active .asana-select-panel-content {
      opacity: 1;
    }
    &.active:before {
      opacity: 0.6;
    }
  }
`
StyledBlurLayer.displayName = 'StyledBlurLayer'

const Container = styled.div`
  height: 85vh;
  max-height: 1000px;
  background-color: ${variables.bodyBackgroundColor};
  box-shadow: 0px 65px 82px -32px rgba(33, 21, 81, 0.2);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ loadingData }) =>
    loadingData &&
    css`
      background-color: transparent;
      height: auto;
    `}
`

const CloseIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px 12px 5px 5px;
  cursor: pointer;
`

const PrimaryButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`
