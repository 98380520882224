import React from 'react'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { Icon, SimpleButton, UserPicture } from 'gipsy-ui'

export default function AccountItem({ account, onReauthenticate, showTitle }) {
  const profile = account?.profile
  const provider = account?.provider
  const email = account?.email
  const name = profile?.displayName
  const picture = profile?.picture
  const credentialsInvalid = account?.areCredentialsInvalid
  const missingScopes = account?.missingScopes
  const invalid = credentialsInvalid || missingScopes

  const handleReauthentication = () => {
    onReauthenticate(provider?.toLowerCase?.(), email)
  }

  return (
    <Container>
      {showTitle && (
        <Title>
          {account.main
            ? translations.onboarding.addAccountsStep.mainAccount
            : translations.onboarding.addAccountsStep.connectedAccounts}
        </Title>
      )}
      <Wrapper invalid={invalid}>
        <AccountInfo>
          <UserPicture
            backgroundColor='transparent'
            borderRadius='50px'
            size={32}
            sizeDefault={24}
            src={picture}
            style={{ marginRight: 11 }}
          />
          <Name title={name}>{name}</Name>
          <Email title={email}>{email}</Email>
        </AccountInfo>
        {invalid && (
          <>
            <WarningContainer>
              <WarningIcon fill='none' icon='Warning' size={26} stroke={styles.colors.redColor} />
              {translations.onboarding.addAccountsStep.grantAccessLabel}
            </WarningContainer>
            <StyledSimpleButton
              borderRadius={8}
              height={40}
              onClick={handleReauthentication}
              text={translations.auth.grantAccess}
            />
          </>
        )}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`

Container.displayName = 'Container'

export const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-weight: 500;
  margin: 0 0 14px 76px;
`

Title.displayName = 'Title'

const AccountInfo = styled.div`
  align-items: center;
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  display: flex;
  flex: 1;
  min-height: 52px;
  padding: 8px 24px;

  img {
    margin-right: 8px;
  }
`

AccountInfo.displayName = 'AccountInfo'

export const Wrapper = styled.div`
  margin-bottom: 8px;
  padding: 0 56px;
  position: relative;

  ${({ invalid }) =>
    invalid &&
    css`
      background-color: ${styles.colors.pinkColor}1A;
      border-radius: 8px;
      margin-bottom: 12px;
      padding: 4px 56px 8px;

      ${AccountInfo} {
        background-color: white;
        border-color: ${styles.colors.pinkColor};
      }
    `}
`

Wrapper.displayName = 'Wrapper'

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 6px;
  width: 100%;
`

NameContainer.displayName = 'NameContainer'

const Name = styled.span`
  display: block;
  flex-shrink: 0.5;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

Name.displayName = 'Name'

const Email = styled.span`
  color: ${styles.colors.darkGrey};
  display: block;
  flex-shrink: 2;
  font-size: 16px;
  margin-left: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

Email.displayName = 'Email'

const WarningContainer = styled.div`
  align-items: center;
  color: ${styles.colors.redColor};
  display: flex;
  font-weight: 500;
  justify-content: center;
  margin: 8px 0 12px;
`

WarningContainer.displayName = 'WarningContainer'

const WarningIcon = styled(Icon)`
  margin-right: 8px;
`

WarningIcon.displayName = 'WarningIcon'

const StyledSimpleButton = styled(SimpleButton)`
  font-size: 18px;
  font-weight: 500;
`

StyledSimpleButton.displayName = 'StyledSimpleButton'
