import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Textarea from 'react-textarea-autosize'
import styled from 'styled-components'
import { styles } from 'gipsy-misc'

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  paramName: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

const defaultProps = {
  value: '',
}

class TextAreaAutosize extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lastValue: props.value,
      focus: false,
    }
  }

  /*
   ** We use handmade shouldComponentUpdate instead of PureComponent in order to ignore the onChange function
   ** it allows us to create a new function on every render in the parent : onChange={() => { doSomething() }}
   ** without rerendering the component everytime the parent is rendered (PureComponent have this side effect )
   */
  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onChange', 'onSubmit', 'onFocus', 'onBlur', 'onKeyDown']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }

      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    if (this.state.focus !== nextState.focus) {
      return true
    }
    return false
  }

  /* called from outside in TimeInput */
  focus = () => {
    this.textarea.focus()
  }

  blur = () => {
    if (!this.state.focus) {
      return
    }
    this.setState({ focus: false }, () => this.textarea.blur())
  }

  onFocus = (e) => {
    this.setState({
      focus: true,
    })
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  onBlur = (e) => {
    this.setState({
      focus: false,
    })

    if (this.props.onBlur) {
      this.props.onBlur({
        paramName: this.props.paramName,
        value: this.props.value,
      })
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.setState({
      lastValue: this.props.value,
    })

    if (this.props.onSubmit) {
      this.props.onSubmit({
        paramName: this.props.paramName,
        value: this.props.value,
      })
    }
  }

  onCancel = (e) => {
    if (this.props.value !== this.state.lastValue) {
      this.onChange(null, this.state.lastValue)
    }
    if (this.props.onCancel) {
      this.props.onCancel(e)
    }
  }

  onChange = (e, value) => {
    if (this.props.onChange) {
      this.props.onChange({
        paramName: this.props.paramName,
        value: e ? e.target.value : value,
      })
    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      /* enter keypress */
      this.blur()
      this.onSubmit(e)
    } else if (e.keyCode === 27) {
      /* esc keypress */
      this.blur()
    }
  }

  render() {
    const { className, paramName, onChange, value, ...props } = this.props
    const { focus } = this.state

    return (
      <Container className={this.props.className}>
        <StyledTextArea
          {...props}
          inputRef={(ref) => {
            this.textarea = ref
          }}
          autoFocus={true}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          className={focus ? 'focus ' : ''}
          value={this.props.value}
        />
      </Container>
    )
  }
}

TextAreaAutosize.propTypes = propTypes
TextAreaAutosize.defaultProps = defaultProps

export default TextAreaAutosize

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledTextArea = styled(Textarea)`
  border: none;
  width: 100%;
  line-height: ${styles.fonts.fontSize};
  padding: ${styles.spacings.xsmall} ${styles.spacings.normal};
  outline: none;
  font-weight: 400;
  color: ${styles.colors.textDarkColor};
  border-radius: ${styles.spacings.tiny};
  background: none;
  &.focus,
  &:hover {
    background-color: ${styles.colors.backgroundGreyColor};
  }
  &::placeholder {
    color: ${styles.colors.darkGrey};
  }
  &.duration {
    display: inline-block;
    font-size: ${styles.fonts.fontSize};
    width: 80px;
    text-align: center;
    padding-bottom: 6px;
  }
`
