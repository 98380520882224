export const TaskObjectGroups = {
  TODAY: 'today',
  WEEK: 'week',
  LATER: 'later',
  BACKLOG: 'backlog',
}

export const TaskSubGroups = {
  SPRINT_TASKS: 'sprintTasks',
  TASKS: 'tasks',
}
