export const getAllInstancesOfRecSprint = (sprintsById, findItemById, recSprintId, keepActiveOnly = false) => {
  var recSprintInstances = []
  if (!recSprintId) {
    return recSprintInstances
  }
  sprintsById.forEach((currentSprintId) => {
    if (!currentSprintId.includes(recSprintId)) return

    const currentSprint = findItemById(currentSprintId)

    // we need to make sure the sprint is recurring. The id isn't enough
    if (currentSprint && currentSprint.recurrencyInformation?.recurringSprintId === recSprintId) {
      if (keepActiveOnly) {
        if (!currentSprint.completionTime) {
          recSprintInstances.push(currentSprint)
        }
      } else {
        recSprintInstances.push(currentSprint)
      }
    }
  })
  return recSprintInstances
}
