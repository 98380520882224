import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'Icon'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { styles, utils } from 'gipsy-misc'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  hour: PropTypes.number,
  minute: PropTypes.number,
  second: PropTypes.number,
  width: PropTypes.number,
  incrementBy: PropTypes.number,
  hideControls: PropTypes.bool,
  withSeconds: PropTypes.bool,
}

const minHour = 0
const maxHour = 23
const minMinute = 0
const maxMinute = 59
const minSecond = 0
const maxSecond = 59

class Duration extends PureComponent {
  constructor(props) {
    super(props)

    this.state = this.getStateFromProps(props)
  }

  getStateFromProps = (props) => {
    props = props || this.props
    let state = {
      hour: props.hour,
      minute: props.minute,
      second: props.second,
      incrementBy: props.incrementBy || 15,
      focused: false,
    }

    let intHour = parseInt(state.hour || 0)
    let intMinute = parseInt(state.minute || 0)
    let intSecond = parseInt(state.second || 0)

    if (intHour > maxHour) {
      intHour = maxHour
    } else if (intHour < minHour) {
      intHour = minHour
    }

    if (intMinute > maxMinute) {
      intMinute = maxMinute
    } else if (intMinute < minMinute) {
      intMinute = minMinute
    }

    if (intSecond > maxSecond) {
      intSecond = maxSecond
    } else if (intSecond < minSecond) {
      intSecond = minSecond
    }

    state.hour = utils.datetime.nbToStr2digit(intHour)
    state.minute = utils.datetime.nbToStr2digit(intMinute)
    state.second = utils.datetime.nbToStr2digit(intSecond)

    return state
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const filterFields = ['hour', 'minute', 'second']
    if (!isEqual(pick(prevProps, filterFields), pick(this.props, filterFields))) {
      const nextState = this.getStateFromProps(this.props)
      this.setState(nextState)
    }
  }

  /* work with string|int value, (called with string when keyboard writing, int when increment (keyboard up/down & - + button)) */
  isValid = (paramName, value) => {
    let min
    switch (paramName) {
      case 'hour':
        min = minHour
        break
      case 'minute':
        min = minMinute
        break
      case 'second':
        min = minSecond
        break
      default:
        break
    }

    let max
    switch (paramName) {
      case 'hour':
        max = maxHour
        break
      case 'minute':
        max = maxMinute
        break
      case 'second':
        max = maxSecond
        break
      default:
        break
    }

    return utils.datetime.isHourOrMinuteBetweenLimits(value, min, max)
  }

  /* make sure the state is clean : 2 digits hour|minute valid string */
  get2digitState = () => {
    const hour = utils.datetime.nbToStr2digit(
      this.isValid('hour', parseInt(this.state.hour)) ? parseInt(this.state.hour) : minHour
    )
    const minute = utils.datetime.nbToStr2digit(
      this.isValid('minute', parseInt(this.state.minute)) ? parseInt(this.state.minute) : minMinute
    )

    const second = utils.datetime.nbToStr2digit(
      this.isValid('second', parseInt(this.state.second)) ? parseInt(this.state.second) : minMinute
    )
    return { hour, minute, second }
  }

  /* return an object {paramName, value} to send to parent with int hour|minute, hour is converted to 24 format */
  getDataForParent = () => {
    const result = {
      hour: parseInt(this.state.hour),
      minute: parseInt(this.state.minute),
    }
    if (this.props.withSeconds) {
      result.second = parseInt(this.state.second)
    }

    return result
  }

  onChange = ({ paramName, value }) => {
    if (!value) {
      return this.setState({ [paramName]: value })
    }

    if (!this.isValid(paramName, value)) {
      return
    }

    if (paramName === 'hour' && value && value.length >= 2 && this.secondInput) {
      this.secondInput.focus()
    }

    if (paramName === 'minute' && value && value.length >= 2) {
      if (this.thirdInput) {
        this.thirdInput.focus()
      }
    }

    this.setState({ [paramName]: value }, () => {
      if (value.length >= 2) {
        this.props.onChange(this.getDataForParent())
      }
    })
  }

  onBlur = (e) => {
    const currentTarget = e.currentTarget

    setTimeout(() => {
      /* avoid calling on blur when target is a child component (input) */
      if (currentTarget.contains(document.activeElement)) {
        return
      }
      this.setState({ ...this.get2digitState(), focused: false }, () => {
        if (!this.props.onChange) {
          return
        }

        const propsData = { hour: this.props.hour, minute: this.props.minute, second: this.props.second }
        const stateData = this.getDataForParent().value
        if (isEqual(propsData, stateData)) {
          return
        }

        this.props.onChange(this.getDataForParent())
      })
    }, 0)
  }

  increment = (paramName, i) => {
    let currentValue = parseInt(this.state[paramName])
    let nextState = {
      [paramName]: utils.number.incrementToNearestMultiple(currentValue, i),
    }

    switch (paramName) {
      case 'hour':
        if (!this.isValid(paramName, nextState[paramName])) {
          nextState[paramName] = i > 0 ? minHour : maxHour
        }

        break

      case 'minute':
        if (!this.isValid(paramName, nextState[paramName])) {
          /* increment | decrement hour when minute is over limit (reached maximum | minimum) */
          if (i > 0) {
            const reachedMaximum = !this.isValid('hour', parseInt(this.state.hour) + 1)
            nextState['minute'] = reachedMaximum ? maxMinute : minMinute
            nextState['hour'] = reachedMaximum ? maxHour : parseInt(this.state.hour) + 1
          } else {
            const reachedMinimum = !this.isValid('hour', parseInt(this.state.hour) - 1)
            /* default : reachedMinimum ? 00 : 60 - 15 */
            nextState['minute'] = reachedMinimum ? minMinute : maxMinute + 1 + i
            nextState['hour'] = reachedMinimum ? minHour : parseInt(this.state.hour) - 1
          }
        }
        break

      case 'second':
        if (!this.isValid(paramName, nextState[paramName])) {
          /* increment | decrement hour when minute is over limit (reached maximum | minimum) */
          if (i > 0) {
            const reachedMaximumMin = !this.isValid('minute', parseInt(this.state.minute) + 1)
            if (reachedMaximumMin) {
              const reachedMaximumHour = !this.isValid('hour', parseInt(this.state.hour) + 1)
              if (reachedMaximumHour) {
                nextState['second'] = maxSecond
                nextState['minute'] = maxMinute
                nextState['hour'] = maxHour
              } else {
                nextState['hour'] = parseInt(this.state.hour) + 1
                nextState['minute'] = minMinute
                nextState['second'] = minSecond
              }
            } else {
              nextState['minute'] = parseInt(this.state.minute) + 1
              nextState['second'] = minSecond
            }
          } else {
            const reachedMinimumMinute = !this.isValid('minute', parseInt(this.state.minute) - 1)

            if (reachedMinimumMinute) {
              const reachedMinumumHour = !this.isValid('hour', parseInt(this.state.hour) - 1)
              if (reachedMinumumHour) {
                nextState['second'] = minSecond
                nextState['minute'] = minMinute
                nextState['hour'] = minHour
              } else {
                nextState['hour'] = parseInt(this.state.hour) - 1
                nextState['minute'] = maxMinute
                nextState['second'] = maxSecond + 1 + i
              }
            } else {
              nextState['minute'] = parseInt(this.state.minute) - 1
              nextState['second'] = maxSecond + 1 + i
            }
          }
        }
        break

      default:
        break
    }

    if (typeof nextState['second'] !== 'undefined') {
      nextState['second'] = utils.datetime.nbToStr2digit(nextState['second'])
    }

    if (typeof nextState['minute'] !== 'undefined') {
      nextState['minute'] = utils.datetime.nbToStr2digit(nextState['minute'])
    }

    if (typeof nextState['hour'] !== 'undefined') {
      nextState['hour'] = utils.datetime.nbToStr2digit(nextState['hour'])
    }

    this.setState(nextState, () => this.props.onChange(this.getDataForParent()))
  }

  onKeyPressHour = (e) => {
    if (e.nativeEvent.keyCode === 13 || e.nativeEvent.keyCode === 39) {
      /* enter or right arrow */
      if (this.secondInput) {
        this.secondInput.focus()
      }
      e.preventDefault()
      /* this will only update and render if a modification is made : change hour "4" to "04" for example */
    } else if (e.nativeEvent.keyCode === 38) {
      /* keyup */
      this.increment('hour', 1)
    } else if (e.nativeEvent.keyCode === 40) {
      /* keydown */
      this.increment('hour', -1)
    }
  }

  onKeyPressMinute = (e) => {
    if (e.nativeEvent.keyCode === 13 || e.nativeEvent.keyCode === 39) {
      /* enter or right arrow */
      if (this.thirdInput) {
        this.thirdInput.focus()
      }
      e.preventDefault()
    } else if (e.nativeEvent.keyCode === 37) {
      /* left arrow */
      if (this.firstInput) {
        this.firstInput.focus()
      }
      e.preventDefault()
    } else if (e.nativeEvent.keyCode === 38) {
      /* keyup */
      this.increment('minute', 1)
    } else if (e.nativeEvent.keyCode === 40) {
      /* keydown */
      this.increment('minute', -1)
    }
  }

  onKeyPressSecond = (e) => {
    if (e.nativeEvent.keyCode === 13 || e.nativeEvent.keyCode === 39) {
      /* enter or right arrow */
      e.preventDefault()
    } else if (e.nativeEvent.keyCode === 37) {
      /* left arrow */
      if (this.secondInput) {
        this.secondInput.focus()
      }
      e.preventDefault()
    } else if (e.nativeEvent.keyCode === 38) {
      /* keyup */
      this.increment('second', 1)
    } else if (e.nativeEvent.keyCode === 40) {
      /* keydown */
      this.increment('second', -1)
    }
  }

  onFocus = (event) => {
    event.target.select()
    this.setState({ focused: true })
  }

  onClickDecrementButton = () => {
    const { hour, minute, second, incrementBy } = this.state
    const { withSeconds } = this.props

    if (withSeconds) {
      this.increment('second', parseInt(minute) === 0 && second <= incrementBy ? -5 : -incrementBy)
    } else {
      this.increment('minute', parseInt(hour) === 0 && minute <= incrementBy ? -5 : -incrementBy)
    }
  }

  onClickIncrementButton = () => {
    const { hour, minute, second, incrementBy } = this.state
    const { withSeconds } = this.props
    if (withSeconds) {
      this.increment('second', parseInt(minute) === 0 && second < incrementBy ? 5 : incrementBy)
    } else {
      this.increment('minute', parseInt(hour) === 0 && minute < incrementBy ? 5 : incrementBy)
    }
  }

  render() {
    const { hour, minute, second, focused } = this.state
    const {
      controlMargins = 8,
      smallFontSize,
      width,
      inputWidth,
      hideControls,
      className,
      inputFontWeight,
      colonPadding,
      withSeconds,
    } = this.props
    return (
      <Container
        className={`${className} ${focused ? 'Duration--focused' : ''}`}
        smallFontSize={smallFontSize}
        onClick={utils.DOM.stopPropagation}
        onBlur={this.onBlur}
        width={width}
        inputWidth={inputWidth}
        inputFontWeight={inputFontWeight}
        colonPadding={colonPadding}
        controlMargins={controlMargins}
        focused={focused}>
        <div className={`time input-container ${hideControls ? '' : 'increment-buttons'}`}>
          {!hideControls && (
            <button className="decrement-button" onClick={this.onClickDecrementButton}>
              <Icon icon="Substract" size={8} fill={styles.colors.textMediumDarkColor} />
            </button>
          )}
          <input
            className="gp-input hour"
            ref={(ref) => {
              this.firstInput = ref
            }}
            type="text"
            value={hour}
            placeholder="08"
            onKeyDown={this.onKeyPressHour}
            onChange={(e) => this.onChange({ paramName: 'hour', value: e.target.value })}
            onFocus={this.onFocus}
          />
          <span className="colon">:</span>
          <input
            className="gp-input minute"
            ref={(ref) => {
              this.secondInput = ref
            }}
            type="text"
            value={minute}
            placeholder="00"
            onChange={(e) => this.onChange({ paramName: 'minute', value: e.target.value })}
            onKeyDown={this.onKeyPressMinute}
            onFocus={this.onFocus}
          />
          {withSeconds && (
            <React.Fragment>
              <span className="colon">:</span>
              <input
                className="gp-input second"
                ref={(ref) => {
                  this.thirdInput = ref
                }}
                type="text"
                value={second}
                placeholder="00"
                onChange={(e) => this.onChange({ paramName: 'second', value: e.target.value })}
                onKeyDown={this.onKeyPressSecond}
                onFocus={this.onFocus}
              />
            </React.Fragment>
          )}
          {!hideControls && (
            <button className="increment-button" onClick={this.onClickIncrementButton}>
              <Icon icon="PlusSmall" size={8} fill={styles.colors.textMediumDarkColor} />
            </button>
          )}
        </div>
      </Container>
    )
  }
}

Duration.propTypes = propTypes

export default Duration

const Container = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.smallFontSize &&
    css`
      input {
        font-size: 12px;
      }
      .colon {
        top: 0;
      }
    `}

  .input-container {
    background-color: transparent;
    border-radius: 8px;
  }

  .colon {
    padding: 0 ${(props) => (isNaN(parseInt(props.colonPadding)) ? 2 : props.colonPadding)}px;
    font-size: 14px;
    line-height: 16px;
    top: -1px;
    position: relative;
  }

  input {
    border: none;
    color: ${styles.colors.textMediumDarkColor};
    font-size: ${styles.fonts.fontSizeSmall};
    font-weight: 400;
    line-height: 16px;
    padding: 0;
  }

  .colon::selection,
  input::selection {
    background: ${styles.colors.primaryColor};
    color: white;
  }

  .input-container.time {
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;

    input {
      width: ${(props) => (props.inputWidth ? props.inputWidth : 22)}px;
      text-align: center;
      background-color: transparent;
    }
  }

  .input-container.time.increment-buttons {
    padding: 0;
  }

  .decrement-button,
  .increment-button {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 1px solid ${styles.colors.textMediumDarkColor};
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    height: 16px;
    justify-content: center;
    padding: 0;
    width: 16px;
  }

  ${({ controlMargins }) =>
    controlMargins &&
    css`
      .decrement-button {
        margin-right: ${controlMargins}px;
      }

      .increment-button {
        margin-left: ${controlMargins}px;
      }
    `}

  &:hover {
    input {
      color: ${styles.colors.primaryColor};
    }

    .decrement-button,
    .increment-button {
      border-color: ${styles.colors.primaryColor};

      path {
        fill: ${styles.colors.primaryColor};
      }
    }
  }

  ${({ focused }) =>
    focused &&
    css`
      input {
        color: ${styles.colors.primaryColor};
      }

      .decrement-button,
      .increment-button {
        border-color: ${styles.colors.primaryColor};

        path {
          fill: ${styles.colors.primaryColor};
        }
      }
    `}
`
