import { emailaccounts } from 'gipsy-api'
import { extractStateParameters } from 'logic/auth'

export const handleRevalidateOAuthCallback = async (searchParams) => {
  const { error, state, localStateGoogle, localStateMicrosoft, code, nonce } = extractStateParameters(searchParams)

  const querySucceeded = !error && code
  if (!querySucceeded) {
    throw new Error('Query failed.')
  }
  const redirectURL = window.location.origin + '/revalidateoauthcallback'
  let provider
  if (state === localStateGoogle) {
    provider = 'google'
  } else if (state === localStateMicrosoft) {
    provider = 'microsoft'
  } else {
    throw new Error('Unknown OAuth method.')
  }
  await emailaccounts.add(provider, code, nonce, redirectURL)
}
