export const getAllInstancesOfRecTask = (tasksById, findItemById, recTaskId, keepActiveOnly = false) => {
  var recSprintInstances = []
  if (!recTaskId) {
    return recSprintInstances
  }
  tasksById.forEach((currentTaskId) => {
    if (!currentTaskId.includes(recTaskId)) return

    const currentTask = findItemById(currentTaskId)

    if (currentTask && currentTask.recurrencyInformation?.recurringTaskId === recTaskId) {
      if (keepActiveOnly) {
        if (!currentTask.completionTime) {
          recSprintInstances.push(currentTask)
        }
      } else {
        recSprintInstances.push(currentTask)
      }
    }
  })
  return recSprintInstances
}
