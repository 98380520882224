import React, { PureComponent } from 'react'
import ActiveTaskPageContainer from 'features/task/components/activeTasksPageContainer'
import { withRouter } from 'react-router-dom'

export default function wrapper(Component) {
  class ProjectPageContainer extends PureComponent {
    state = {
      projectId: this.props.match.params.projectId,
    }

    componentDidUpdate(prevProps) {
      let { projectId: prevProjectId } = prevProps.match.params
      let { projectId } = this.props.match.params
      if (prevProjectId !== projectId) {
        this.setState({ projectId: projectId })
      }
    }

    render() {
      return (
        <ActiveTaskPageContainer projectId={this.state.projectId}>
          {({ ...taskPageProps }) => <Component {...taskPageProps} />}
        </ActiveTaskPageContainer>
      )
    }
  }

  return withRouter(ProjectPageContainer)
}
