import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { models, styles, translations } from 'gipsy-misc'
import { PrimaryButton, RadioInput, SecondaryButton } from 'gipsy-ui'

import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

const componentName = 'RecurrentItemPanel'
const { InstanceOptions } = models.recurrency

export default function RecurrentItemPanel({
  forSprint,
  hideAllOption,
  hideSingleOption,
  noOptionsMessageText,
  noOptionsValue = InstanceOptions.Next,
  onCancel,
  onConfirm,
}) {
  const dispatch = useDispatch()

  const hideOptions = hideAllOption && hideSingleOption

  const [optionChecked, setOptionChecked] = useState(hideOptions ? noOptionsValue : InstanceOptions.Single)

  const handleConfirm = useCallback(() => {
    onConfirm?.(optionChecked)
  }, [onConfirm, optionChecked])

  const handleCancel = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  const translationType = forSprint ? 'sprint' : 'task'

  useEffect(() => {
    dispatch(
      pushShortcutsGroup(
        [
          {
            label: translations[translationType].recurrencyPanel.delete.deny,
            key: 'Escape',
            callback: handleCancel,
          },
          {
            label: translations[translationType].recurrencyPanel.delete.consent,
            key: 'Enter',
            callback: handleConfirm,
          },
        ],
        componentName
      )
    )

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch, handleCancel, handleConfirm, translationType])

  const selectSingle = useCallback(() => setOptionChecked(InstanceOptions.Single), [])
  const selectNext = useCallback(() => setOptionChecked(InstanceOptions.Next), [])
  const selectAll = useCallback(() => setOptionChecked(InstanceOptions.All), [])

  return (
    <Container>
      <OptionsContainer>
        {hideOptions ? (
          <NoOptionsMessage>
            {noOptionsMessageText || translations[translationType].recurrencyPanel.noOptions}
          </NoOptionsMessage>
        ) : (
          <>
            {!hideSingleOption && (
              <OptionContainer onClick={selectSingle}>
                <StyledRadioInput
                  checked={optionChecked === InstanceOptions.Single}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={18}
                />
                <span>{translations[translationType].recurrencyPanel.onlyThis}</span>
              </OptionContainer>
            )}
            <OptionContainer onClick={selectNext}>
              <StyledRadioInput
                checked={optionChecked === InstanceOptions.Next}
                checkedColor={styles.colors.primaryColor}
                disableStopPropagation
                size={18}
              />
              <span>{translations[translationType].recurrencyPanel.thisAndFollowing}</span>
            </OptionContainer>
            {!hideAllOption && (
              <OptionContainer onClick={selectAll}>
                <StyledRadioInput
                  checked={optionChecked === InstanceOptions.All}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={18}
                />
                <span>{translations[translationType].recurrencyPanel.all}</span>
              </OptionContainer>
            )}
          </>
        )}
      </OptionsContainer>
      <ButtonContainer>
        <CancelButton
          height={32}
          onClick={handleCancel}
          text={translations[translationType].recurrencyPanel.delete.deny}
        />
        <ConfirmButton
          height={32}
          onClick={handleConfirm}
          borderRadius={8}
          text={translations[translationType].recurrencyPanel.delete.consent}
        />
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

Container.displayName = 'Container'

const OptionsContainer = styled.div`
  margin: 16px 0;
`

OptionsContainer.displayName = 'OptionsContainer'

const OptionContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  margin-bottom: 16px;
`

OptionContainer.displayName = 'OptionContainer'

const NoOptionsMessage = styled.div`
  margin: -16px 0 8px;
  text-align: center;
`

NoOptionsMessage.displayName = 'NoOptionsMessage'

const StyledRadioInput = styled(RadioInput)`
  margin-right: 7px;
`

StyledRadioInput.displayName = 'StyledRadioInput'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

ButtonContainer.displayName = 'ButtonContainer'

const CancelButton = styled(SecondaryButton)`
  margin-right: 20px;
  min-width: 116px;
`

CancelButton.displayName = 'CancelButton'

const ConfirmButton = styled(PrimaryButton)`
  min-width: 116px;
`

ConfirmButton.displayName = 'ConfirmButton'
