import React from 'react'
import styled, { css } from 'styled-components'
import parser from 'react-html-parser'

import { styles, translations } from 'gipsy-misc'
import { Icon } from 'gipsy-ui'

import { LogoContainer } from 'features/auth/components'
import { getSrc } from 'utils/image'

export function FeatureItem({ text }) {
  return (
    <FeatureContainer className='FeatureItem'>
      <CheckIcon className='FeatureItem__icon' fill={styles.colors.greenPrimaryColor} icon='Tick' size={14} />
      <p className='FeatureItem__text'>{parser(text)}</p>
    </FeatureContainer>
  )
}

const FeatureContainer = styled.div`
  align-items: center;
  color: ${styles.colors.textDarkColor};
  display: flex;
  margin-bottom: 14px;

  p {
    margin: 0;
  }

  strong {
    font-weight: 600;
  }
`

FeatureContainer.displayName = 'FeatureContainer'

const CheckIcon = styled(Icon)`
  margin-right: 16px;
`

CheckIcon.displayName = 'CheckIcon'

export default function FeaturesColumn({ accountDeleted, multiCalendarSyncExp }) {
  return (
    <Container accountDeletedExp={accountDeleted}>
      <Logo src={getSrc('/images/gipsybot-with-title.svg')} />
      {accountDeleted ? (
        <>
          <Title>{parser(translations.login.accountDeletedTitle)}</Title>
          <Subtitle>{translations.login.accountDeletedSubtitle}</Subtitle>
        </>
      ) : multiCalendarSyncExp ? (
        <>
          <Title>{parser(translations.login.multiCalendarTitle)}</Title>
          <FeaturesContainer>
            <FeatureItem text={translations.login.eventsSyncedFeature} />
            <FeatureItem text={translations.login.noDoubleBookingsFeature} />
            <FeatureItem text={translations.login.noMissedMeetingsFeature} />
          </FeaturesContainer>
          <SyncAnimation className='Login__SyncAnimation' src={getSrc('/images/anim_sync.gif')} />
        </>
      ) : (
        <>
          <Title>{parser(translations.login.title)}</Title>
          <FeaturesContainer>
            <FeatureItem text={translations.login.syncFeature} />
            <FeatureItem text={translations.login.timeBlockingFeature} />
            <FeatureItem text={translations.login.distractionBlockingFeature} />
          </FeaturesContainer>
        </>
      )}
    </Container>
  )
}

export const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  margin: 0 0 55px;

  strong {
    font-weight: 500;
  }
`

Title.displayName = 'Title'

export const Container = styled.div`
  align-items: flex-start;
  background-color: white;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  padding: 0 5% 0 7%;
  width: 40%;

  ${({ accountDeletedExp }) =>
    accountDeletedExp &&
    css`
      ${Title} {
        margin-bottom: 20px;
      }
    `}
`

Container.displayName = 'Container'

export const Logo = styled(LogoContainer)`
  cursor: default;
  height: auto;
  margin-bottom: 42px;
  width: 153px;
`

Logo.displayName = 'Logo'

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 33px;
  margin: 0;
`

Subtitle.displayName = 'Subtitle'

const FeaturesContainer = styled.div``

FeaturesContainer.displayName = 'FeaturesContainer'

const SyncAnimation = styled.img`
  display: block;
  margin-top: 30px;

  &.Login__SyncAnimation {
    margin-left: -13%;
    max-width: 800px;
    width: 120%;
  }
`

SyncAnimation.displayName = 'SyncAnimation'
