import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { user as userApi } from 'gipsy-api'
import { models, styles, translations, utils } from 'gipsy-misc'
import { CustomPopup, Icon } from 'gipsy-ui'

import RealTime from 'features/realTime'
import Select from 'features/select'
import { setUser } from 'store/session/actions'

const options = [
  { value: 0, label: utils.string.capitalize(translations.days.label.sunday) },
  { value: 1, label: utils.string.capitalize(translations.days.label.monday) },
]

export default function Settings() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.session.user)

  const [settingsShown, setSettingsShown] = useState(false)

  const settingsContainerRef = useRef(null)

  const toggleSettingsShown = () => {
    setSettingsShown((prev) => !prev)
  }

  const handleClickOutside = (e) => {
    if (!settingsContainerRef.current?.contains?.(e.target)) {
      setSettingsShown(false)
    }
  }

  const closeSettings = () => {
    setSettingsShown(false)
  }

  const onChange = async ({ value }) => {
    const newCalendarPreferences = { ...user.settingsPreferences.calendar }
    newCalendarPreferences.firstDay = value
    const newUser = { ...user }
    newUser.settingsPreferences.calendar = newCalendarPreferences
    dispatch(setUser(newUser))
    await userApi.patch(newCalendarPreferences, {
      calendarPreferences: true,
    })
    RealTime.publishMessage('', [models.realtime.topics.user])
  }

  const calendarPreferences = user.settingsPreferences.calendar
  const optionSelected = options.find((option) => option.value === calendarPreferences.firstDay)

  return (
    <Container ref={settingsContainerRef}>
      <SettingsIcon active={settingsShown} icon='SettingsCog' onClick={toggleSettingsShown} size={18} />
      {settingsShown && (
        <SettingsPopup onClickOutside={handleClickOutside}>
          <SettingsPopupContent>
            <CloseIcon icon='Close' onClick={closeSettings} size={10} />
            <SettingLabel>{translations.calendarSettings.sections.view.startWeekOn}:</SettingLabel>
            <Select
              options={options}
              defaultOption={optionSelected}
              isClearable={false}
              onChange={onChange}
              styles={{
                control: {
                  width: 172,
                },
              }}
              value={optionSelected}
            />
          </SettingsPopupContent>
        </SettingsPopup>
      )}
    </Container>
  )
}

const Container = styled.div`
  margin-right: 16px;
  position: relative;
`

Container.displayName = 'Container'

const SettingsIcon = styled(Icon)`
  cursor: pointer;

  :hover {
    path {
      fill: ${styles.colors.primaryColor};
    }
  }

  ${({ active }) =>
    active &&
    css`
      path {
        fill: ${styles.colors.primaryColor};
      }
    `}
`

SettingsIcon.displayName = 'SettingsIcon'

const SettingsPopup = styled(CustomPopup)`
  border-radius: 10px;
  left: -20px;
  min-width: 204px;
  top: calc(100% + 12px);
`

SettingsPopup.displayName = 'SettingsPopup'

const SettingsPopupContent = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
`

SettingsPopupContent.displayName = 'SettingsPopupContent'

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`

CloseIcon.displayName = 'CloseIcon'

const SettingLabel = styled.span`
  display: block;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
`

SettingLabel.displayName = 'SettingLabel'
