import styled, { css } from 'styled-components'

import { styles } from 'gipsy-misc'

import { SimpleButton } from 'ReusableButton/Buttons'

export const Container = styled.div.attrs(({ maxWidth }) => {
  return {
    style: {
      maxWidth: Number.isInteger(maxWidth) ? `${maxWidth}px` : 'none',
    },
  }
})`
  background-color: white;
  border-radius: 10px;
  box-shadow: ${styles.shadows.popup};
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  overflow: hidden;
  width: ${(props) => (Number.isFinite(props.width) ? `${props.width}px` : props.width)};
`

Container.displayName = 'Container'

export const CreateButton = styled(SimpleButton)`
  align-items: center;
  background-color: ${styles.colors.veryLightGrey};
  border-color: ${styles.colors.veryLightGrey};
  border-radius: 8px;
  color: ${styles.colors.textMediumDarkColor};
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  margin: 0 8px 8px 8px;
  transition: all 0.25s ease-in-out;
`

CreateButton.displayName = 'CreateButton'

export const SearchBar = styled.input`
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: ${styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeSmall};
  margin-bottom: 16px;
  padding: 16px 16px 0 16px;
  width: 100%;

  ::placeholder {
    color: ${styles.colors.darkGrey};
    font-size: ${styles.fonts.fontSizeSmall};
  }
`

SearchBar.displayName = 'SearchBar'

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.inlineCreateButton ? 'row' : 'column')};
  padding: ${(props) => (props.inlineCreateButton ? '16px 9px 0 7px' : '0')};
  width: 100%;

  ${CreateButton} {
    ${(props) =>
      props.inlineCreateButton &&
      css`
        height: 40px;
      `}
  }

  ${SearchBar} {
    padding-top: ${(props) => (props.inlineCreateButton ? 12 : 16)}px;
  }
`

FlexContainer.displayName = 'FlexContainer'

export const EmptyLabel = styled.span`
  color: ${styles.colors.darkGrey};
  display: block;
  font-size: ${styles.fonts.fontSizeSmall};
  padding: 24px 16px;
  text-align: center;
  width: 100%;
`

EmptyLabel.displayName = 'EmptyLabel'

export const Separator = styled.span`
  border-top: 1px solid ${styles.colors.greyBorderColor};
  height: 1px;
  display: flex;
  width: 100%;
`

Separator.displayName = 'Separator'

export const ItemsContainer = styled.div`
  max-height: 276px;
  overflow-y: scroll;
  width: 100%;
`

ItemsContainer.displayName = 'ItemsContainer'
