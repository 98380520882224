import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import container from './container'
import DraggableList from 'features/list/components/draggableList'
import { onMouseEnter, onMouseLeave } from 'logic/draganddrop'
import SortDropdown from 'features/filters/SortDropdown'
import {
  FiltersContainer,
  PageContainer,
  PageBody,
  HeaderContainer,
  innerLeftPadding,
  innerRightPadding,
  NavigationTitle,
  NavigationProjectBadge,
  NavigationContainer,
  PaddingContainer,
} from 'features/layout/pages'
import HomebaseLine from 'features/list/components/line'
import CredentialsAlert from 'features/auth/components/CredentialsAlert'
import styled from 'styled-components'
import { CreatingTaskContainer } from 'features/list/components/commonUI'
import CreatedTaskAlert from 'features/list/components/createdTaskAlert'

import { models, utils } from 'gipsy-misc'

export class ProjectPage extends PureComponent {
  constructor(props) {
    super(props)
    this.onMouseEnter = onMouseEnter.bind(this)
    this.onMouseLeave = onMouseLeave.bind(this)
    this.draggableListRef = React.createRef()
  }

  render() {
    const {
      project,
      tasks,
      onComplete,
      onCompleteFromFS,
      onClickDelete,
      onSave,
      updateTaskInState,
      onMoveToTop,
      onDropOnDraggableOver,
      ignoreOutsideClicks,
      isDragging,
      creatingCalendarTask,
      editingCalendarTask,
      editingTask,
      creatingTask,
      onTogglePin,
      onCreatePageTask,
      onDragEnd,
      onDragStart,
      onDrop,
      cancelTaskAction,
      onTaskEditStart,
      sprintComposerProps,
      keepCreatingTasks,
      isCreatingInlineTask,
      onClickFocusSession,
      onUpdateFocusSession,
      onClickDeleteFocusSession,
      isTaskCreationAlertShown,
      session,
      onSelectSortingOption,
      showCalendar,
      sortSelected,
    } = this.props

    const toHideTasksIdsMap = {}
    if (editingCalendarTask) {
      toHideTasksIdsMap[editingCalendarTask.id] = true
    }

    if (session?.completedSession?.taskId) {
      toHideTasksIdsMap[session.completedSession.taskId] = true
    }

    const filteredTasks = utils.list.getFilteredTasksOfGroup(tasks, {}, toHideTasksIdsMap)

    return (
      <PageContainer>
        <PageBody>
          <HeaderContainer>
            <NavigationContainer>
              <NavigationTitle textEllipsis>
                <NavigationProjectBadge color={project.color} size={18} />
                {project.name}
              </NavigationTitle>
            </NavigationContainer>
          </HeaderContainer>
          <PaddingContainer>
            <CredentialsAlert />
          </PaddingContainer>
          <FiltersContainer>
            <SortDropdown onChange={onSelectSortingOption} optionSelected={sortSelected} />
          </FiltersContainer>
          <TasksContainer>
            {filteredTasks && (
              <DraggableList
                setDraggableListRef={this.draggableListRef}
                dragging={isDragging}
                showSprintInfo
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
                onDropOnDraggableOver={onDropOnDraggableOver}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onMoveToTop={onMoveToTop}
                list={filteredTasks}
                highlightIds={[]}
                onSave={onSave}
                updateTaskInState={updateTaskInState}
                onComplete={onComplete}
                onCompleteFromFS={onCompleteFromFS}
                startSprintCreation={sprintComposerProps.startSprintCreation}
                onDelete={onClickDelete}
                ignoreOutsideClicks={ignoreOutsideClicks}
                editingTask={editingTask}
                onCancelEdit={cancelTaskAction}
                canBlockToCalendar={true}
                onEditStart={onTaskEditStart}
                onTogglePin={onTogglePin}
                session={session}
                onClickFocusSession={onClickFocusSession}
                onUpdateFocusSession={onUpdateFocusSession}
                onClickDeleteFocusSession={onClickDeleteFocusSession}
                showCalendar={showCalendar}
              />
            )}
            <CreatingTaskContainer>
              {!creatingCalendarTask && (
                <HomebaseLine
                  onCreate={onCreatePageTask}
                  canBlockToCalendar
                  componentSource={models.item.type.TASK}
                  startCreation={!!creatingTask || keepCreatingTasks || isCreatingInlineTask}
                  useTitleProps={!!creatingTask}
                  item={creatingTask ? creatingTask : undefined}
                  project={project}
                  creating={true}
                  startSprintCreation={sprintComposerProps.startSprintCreation}
                  innerLeftPadding={innerLeftPadding}
                  innerRightPadding={innerRightPadding}
                  ignoreOutsideClicks={ignoreOutsideClicks}
                  onTogglePin={showCalendar ? onTogglePin : undefined}
                  onCancel={cancelTaskAction}
                />
              )}
              <CreatedTaskAlert isShown={isTaskCreationAlertShown} />
            </CreatingTaskContainer>
          </TasksContainer>
        </PageBody>
      </PageContainer>
    )
  }
}

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
`

ProjectPage.propTypes = {
  tasks: PropTypes.array,
  project: PropTypes.object,
  onComplete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
}

export default container(ProjectPage)
