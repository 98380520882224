import React, { useCallback, useState, useRef } from 'react'
import { AttributeIcon, ClickableAttributeName, UrlAttribute } from 'LineView/components/commonUIComponents'
import { utils } from 'gipsy-misc'
import LinkListPopup from 'LineComponents/LinkListPopup'
import styled from 'styled-components'

function UrlsAttribute(props) {
  const { urlsInfo, disableLinks, maxWidth, stopPropagationOnClick } = props
  const [isLinksListShown, setIsLinksListShown] = useState(false)

  const attributeRef = useRef(null)

  const handleLinkClick = useCallback(
    (e, url) => {
      e.preventDefault()
      if (stopPropagationOnClick) {
        e.stopPropagation()
      }
      window.open(utils.url.setHttp(url), '_blank')
    },
    [stopPropagationOnClick]
  )

  const handleLinkClickMultipleLinks = useCallback(
    (e) => {
      e.preventDefault()
      if (stopPropagationOnClick) {
        e.stopPropagation()
      }
      setIsLinksListShown((prev) => !prev)
    },
    [stopPropagationOnClick]
  )

  const shouldShowLinksList = isLinksListShown && !disableLinks

  const onClickOutside = useCallback(
    (e) => {
      if (attributeRef?.current && !attributeRef.current?.contains(e.target)) {
        setIsLinksListShown(false)
      }
    },
    [attributeRef]
  )

  if (!urlsInfo || urlsInfo.length === 0) return null

  return (
    <UrlAttribute title={urlsInfo.length === 1 ? urlsInfo[0].url : ''} maxWidth={maxWidth} ref={attributeRef}>
      <AttributeIcon size={11} icon={'Link'} />

      {urlsInfo.length > 1 ? (
        <ClickableAttributeName onClick={handleLinkClickMultipleLinks}>
          {utils.task.computeLinksPlaceholder(urlsInfo)}
        </ClickableAttributeName>
      ) : (
        <AttributeNameLink
          href={urlsInfo[0].url}
          onClick={(e) => handleLinkClick(e, urlsInfo[0].url)}
          rel="noreferrer"
          target="_blank">
          {utils.task.computeLinksPlaceholder(urlsInfo)}
        </AttributeNameLink>
      )}
      {shouldShowLinksList ? <LinkListPopup onClickOutside={onClickOutside} readOnly urlsInfo={urlsInfo} /> : null}
    </UrlAttribute>
  )
}

const AttributeNameLink = styled(ClickableAttributeName).attrs({ as: 'a' })`
  color: inherit;
  text-decoration: none;
`

export default React.memo(UrlsAttribute)
