import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { styles } from 'gipsy-misc'
import { Icon } from 'gipsy-ui'

import breakpoints, { verticalBreakpoints } from 'features/layout/breakpoints'

import Testimonial from './Testimonial'

const TESTIMONIALS_COUNT = 4

export default function Testimonials() {
  const [slideData, setSlideData] = useState({ current: 0, direction: 1 })

  const sliderInterval = useRef<number>(-1)

  const slideRight = () => {
    setSlideData((prev) => {
      return { current: prev.current === TESTIMONIALS_COUNT - 1 ? 0 : prev.current + 1, direction: 1 }
    })
  }

  const resetInterval = useCallback(() => {
    window.clearInterval(sliderInterval.current)
    sliderInterval.current = window.setInterval(() => {
      slideRight()
    }, 8000)
  }, [])

  const handleLeftControlClicked = () => {
    resetInterval()
    setSlideData((prev) => {
      return { current: prev.current === 0 ? TESTIMONIALS_COUNT - 1 : prev.current - 1, direction: -1 }
    })
  }

  const handleRightControlClicked = () => {
    resetInterval()
    slideRight()
  }

  useEffect(() => {
    resetInterval()

    return () => {
      clearInterval(sliderInterval.current)
    }
  }, [resetInterval])

  return (
    <Container className='Testimonial'>
      <LeftSliderControl className='Testimonial__left-control' onClick={handleLeftControlClicked}>
        <Icon
          className='Testimonial__left-control__icon'
          fill={styles.colors.textMediumDarkColor}
          icon='CaretDown'
          size={10}
        />
      </LeftSliderControl>
      <CarouselContainer>
        <AnimatePresence custom={slideData.direction} initial={false}>
          <Slide
            animate='center'
            custom={slideData.direction}
            exit='exit'
            initial='enter'
            key={slideData.current}
            transition={{
              x: { type: 'linear' },
            }}
            variants={variants}>
            <Testimonial testimonialNumber={slideData.current} />
          </Slide>
        </AnimatePresence>
        <SlidePlaceholder>
          <Testimonial testimonialNumber={slideData.current} />
        </SlidePlaceholder>
      </CarouselContainer>
      <RightSliderControl className='Testimonial__right-control' onClick={handleRightControlClicked}>
        <Icon
          className='Testimonial__right-control__icon'
          fill={styles.colors.textMediumDarkColor}
          icon='CaretDown'
          size={10}
        />
      </RightSliderControl>
    </Container>
  )
}

const variants = {
  center: {
    x: 0,
    zIndex: 1,
  },
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
    }
  },
  exit: (direction) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      zIndex: 0,
    }
  },
}

const Container = styled.section`
  align-items: stretch;
  background-color: ${styles.colors.veryLightGrey};
  display: flex;
  height: 19%;
  min-height: 132px;
  padding: 18px 20px;

  @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
    min-height: 164px;
    padding: 20px;
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    min-height: 185px;
    padding: 35px 20px;

    .Testimonial {
      &__left-control {
        margin: auto 4px auto auto;
      }

      &__right-control {
        margin: auto auto auto 16px;
      }
    }
  }
`

Container.displayName = 'Container'

const LeftSliderControl = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  margin: auto 35px auto auto;
  width: 30px;

  .Testimonial__left-control__icon {
    transform: rotate(90deg);
  }
`

LeftSliderControl.displayName = 'LeftSliderControl'

const RightSliderControl = styled(LeftSliderControl)`
  margin: auto auto auto 35px;

  .Testimonial__right-control__icon {
    transform: rotate(-90deg);
  }
`

RightSliderControl.displayName = 'RightSliderControl'

const CarouselContainer = styled.div`
  align-items: center;
  display: flex;
  max-width: 605px;
  overflow: hidden;
  position: relative;
`

CarouselContainer.displayName = 'CarouselContainer'

const Slide = styled(motion.div)`
  height: 100%;
  position: absolute;
  width: 100%;
`

Slide.displayName = 'Slide'

const SlidePlaceholder = styled.div`
  height: 100%;
  pointer-events: none;
  opacity: 0;
  width: 100%;
`

SlidePlaceholder.displayName = 'SlidePlaceholder'
