import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import clone from 'lodash/clone'
import get from 'lodash/get'

import { task as taskApi } from 'gipsy-api'
import { models, styles, translations } from 'gipsy-misc'

import RealTime from 'features/realTime'
import { handleAPIError } from 'store/app/actions'
import { addCalendarEvent, removeCalendarEvent, updateCalendarEvent } from 'store/calendar/actions'
import ConfirmPanel from 'features/popup/components/confirmPanel'
import { closePopup, openPopup } from 'store/popup/actions'
import { toggleCompleteTaskRequest } from 'store/task/actions'
import { removeItem as removeTaskPanelItem } from 'store/taskPanel/actions'

export default function usePageActions() {
  const dispatch = useDispatch()

  const addItemToCalendar = useCallback(
    (item, color, isSprint) => {
      dispatch(addCalendarEvent({ color, isSprint, item }))
    },
    [dispatch]
  )

  const removeItemFromCalendar = useCallback(
    (id) => {
      dispatch(removeCalendarEvent({ itemId: id }))
    },
    [dispatch]
  )

  const updateCalendarItem = useCallback(
    (idToUpdate, item) => {
      dispatch(
        updateCalendarEvent({
          idToUpdate,
          item,
        })
      )
    },
    [dispatch]
  )

  const removeItemFromTaskPanel = useCallback(
    (id) => {
      dispatch(removeTaskPanelItem(id))
    },
    [dispatch]
  )

  const completeTask = useCallback(
    async ({ extraParams, id, task, toAddFocusSession }, { onBackendTaskCompleted } = {}) => {
      if (task && get(task, 'pin.time')) {
        removeItemFromCalendar(task.id)
      }

      if (toAddFocusSession) {
        addItemToCalendar({ ...toAddFocusSession, type: models.item.type.FOCUSSESSION }, styles.colors.focusSessionFill)
      }

      const out = await dispatch(toggleCompleteTaskRequest(id, 1, undefined, extraParams))

      if (out?.createdFocusSession) {
        onBackendTaskCompleted?.(out)
        if (toAddFocusSession) removeItemFromCalendar(toAddFocusSession.id)
        addItemToCalendar(
          { ...out.createdFocusSession, type: models.item.type.FOCUSSESSION },
          styles.colors.focusSessionFill
        )
      }
    },
    [addItemToCalendar, dispatch, removeItemFromCalendar]
  )

  const uncompleteTask = useCallback(
    async ({ id, task }, { onLocalTaskCompleted } = {}) => {
      const updatedTask = clone(task)
      updatedTask.completionTime = ''
      updatedTask.completed = 0

      if (updatedTask.focusSessions) {
        const filteredFocusSessions = []
        updatedTask.focusSessions.forEach((fs) => {
          if (fs.mode !== models.focussession.CalendarMode.COMPLETED) {
            filteredFocusSessions.push(fs)
          } else {
            removeItemFromCalendar(fs.id)
          }
        })
        updatedTask.focusSessions = filteredFocusSessions
      }

      onLocalTaskCompleted?.(updatedTask)

      if (get(updatedTask, 'pin.time')) {
        updateCalendarItem(updatedTask.id, updatedTask)
      }

      await dispatch(toggleCompleteTaskRequest(id, 0))
    },
    [dispatch, removeItemFromCalendar, updateCalendarItem]
  )

  const deleteTask = useCallback(
    async (task, { onBackendTaskDeleted, onError } = {}) => {
      if (task && get(task, 'pin.time')) {
        removeItemFromCalendar(task.id)
      }

      removeItemFromTaskPanel(task.id)

      try {
        await taskApi.del(task.id)
        RealTime.publishMessage(task.id, [models.realtime.topics.itemDelete])
        onBackendTaskDeleted?.()
      } catch (err) {
        dispatch(handleAPIError(err, { task }))
        onError?.()
      }
    },
    [dispatch, removeItemFromCalendar, removeItemFromTaskPanel]
  )

  const saveTask = useCallback(
    async ({ hasPinTimeChanged, task, updatedTask }) => {
      if (hasPinTimeChanged) {
        const wasPinTimeRemoved = !get(updatedTask, 'pin.time')
        const wasPinTimeAdded = !get(task, 'pin.time')

        if (wasPinTimeRemoved) {
          removeItemFromCalendar(updatedTask.id)
        } else if (wasPinTimeAdded) {
          addItemToCalendar({ ...updatedTask })
        } else {
          updateCalendarItem(updatedTask.id, { ...updatedTask })
        }
      } else {
        const isPinnedTask = get(updatedTask, 'pin.time')

        if (isPinnedTask) {
          updateCalendarItem(updatedTask.id, { ...updatedTask })
        }
      }

      try {
        await taskApi.putFullTask(updatedTask)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    },
    [addItemToCalendar, dispatch, removeItemFromCalendar, updateCalendarItem]
  )

  const deletionPopup = useCallback(
    ({ cancelLabel, confirmLabel, text, title }, { onCancelled, onConfirmed } = {}) => {
      const onConfirm = () => {
        onConfirmed?.()
        dispatch(closePopup())
      }

      const onCancel = () => {
        onCancelled?.()
        dispatch(closePopup())
      }

      dispatch(
        openPopup({
          title,
          centeredTitle: true,
          logo: 'sad',
          component: (
            <ConfirmPanel
              cancelLabel={cancelLabel}
              confirmLabel={confirmLabel}
              onCancel={onCancel}
              onConfirm={onConfirm}
              text={text}
            />
          ),
        })
      )
    },
    [dispatch]
  )

  const taskDeletePopUp = useCallback(
    (callbacks) => {
      deletionPopup(
        {
          cancelLabel: translations.taskPanel.delete.deny,
          confirmLabel: translations.taskPanel.delete.consent,
          text: translations.taskPanel.delete.prompt,
          title: translations.taskPanel.delete.prompt,
        },
        callbacks
      )
    },
    [deletionPopup]
  )

  return {
    completeTask,
    deleteTask,
    deletionPopup,
    saveTask,
    taskDeletePopUp,
    uncompleteTask,
  }
}

export const withPageActions = (Component) => {
  return (props) => {
    const actions = usePageActions()

    return <Component {...props} {...actions} />
  }
}
