import React from 'react'
import styled from 'styled-components'
import { produce } from 'immer'
import Popup from 'Popup'
import noop from 'lodash/noop'
import { PopupItem as BasePopupItemContainer } from 'Popup/components'
import { PopupItemContainer } from './PopupItem'
import { styles, translations } from 'gipsy-misc'

export default function FocusSessionsPopup(props) {
  const {
    item: { focusSessions },
    setRef,
    maxWidth,
    ...restProps
  } = props
  return (
    <StyledFocusSessionPopup ref={setRef ?? noop} maxWidth={maxWidth}>
      <PopupTitle disableHover>{translations.focussession.popup.title}</PopupTitle>
      {focusSessions &&
        produce(focusSessions, (draft) =>
          draft.sort((a, b) => new Date(b.startTime) - new Date(a.startTime))
        ).map((item) => <PopupItemContainer key={item.id} item={item} maxWidth={maxWidth} {...restProps} />)}
    </StyledFocusSessionPopup>
  )
}

const PopupTitle = styled(BasePopupItemContainer)`
  padding: 13px;
  font-weight: 500;
  font-size: 14px;
`

const StyledPopup = styled(Popup)`
  left: 0;
  top: 100%;
  color: ${styles.colors.textMediumDarkColor};
  font-size: 13px;
  margin-bottom: 12px;
  margin-top: 5px;
  padding: 16px 6px;
`

const StyledFocusSessionPopup = styled(StyledPopup).attrs(({ maxWidth }) => ({
  style: {
    maxWidth: Number.isInteger(maxWidth) ? `${maxWidth}px` : 'none',
  },
}))`
  padding: 0;
  width: 580px;
  min-width: 0;
`
