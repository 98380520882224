import comment from './comment'
import * as focussession from './focussession'
import * as date from './date'
import item from './item'
import * as realtime from './realtime'
import * as recurrency from './recurrency'
import sprint from './sprint'
import task from './task'
import urlinfo from './urlinfo'

const models = {
  comment,
  date,
  focussession,
  item,
  realtime,
  recurrency,
  sprint,
  task,
  urlinfo,
}

export default models
