import React, { PureComponent } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { styles, translations } from 'gipsy-misc'

import Icon from 'Icon'
import WhenDatePicker from 'WhenDatePicker'

import { PlaceHolder, getColor } from './commonUIComponents'

const baseTop = 32
const color = styles.colors.textMediumDarkColor
const iconColor = styles.colors.darkGrey
export class DateWrapper extends PureComponent {
  state = {
    calendarShown: false,
    whenDatePickerPosition: {
      left: 0,
      top: baseTop,
    },
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.calendarShown && !this.state.calendarShown) {
      this.setState({
        whenDatePickerPosition: { left: 0, top: baseTop },
      })
    }

    if (this.state.calendarShown && prevProps.value !== this.props.value) {
      setTimeout(() => {
        this.setState({
          whenDatePickerPosition: { ...this.computeDropdownPosition() },
        })
      })
    }
  }

  showCalendar = (value) => {
    this.setState(
      {
        calendarShown: value,
      },
      () => {
        if (value) {
          this.setState({
            whenDatePickerPosition: { ...this.computeDropdownPosition() },
          })
        }
      }
    )
  }

  handleClickOutside = (e) => {
    if (this.whenDatePickerPopup && !this.whenDatePickerPopup.contains(e.target)) {
      // we also avoid hiding calendar when the user click on the field input
      // this logic is already handled by onWrapperClick
      if (this.fieldNode && !this.fieldNode.contains(e.target)) {
        this.showCalendar(false)
      }
    }
  }

  onWrapperClick = () => {
    if (!this.props.disabled) {
      this.showCalendar(!this.state.calendarShown)
    }
  }

  computeDropdownPosition = () => {
    const { getLineBounds } = this.props
    const lineBounds = getLineBounds?.()

    if (!this.state.calendarShown || !this.whenDatePickerPopup || !lineBounds) return { left: 0, top: baseTop }

    const fieldBounds = this.fieldNode.getBoundingClientRect()
    const { bottom, height, right } = this.whenDatePickerPopup.getBoundingClientRect()
    const { right: lineRightPosition } = lineBounds
    const prevLeft = this.state.whenDatePickerPosition.left
    const prevTop = this.state.whenDatePickerPosition.top

    let headerOffset = 0
    let header = document.querySelector('.HomebaseHeader')
    if (header) {
      headerOffset = header.clientHeight
    }

    let left = prevLeft
    if (right > lineRightPosition) {
      left = lineRightPosition - right
    } else {
      left = Math.min(prevLeft, 0)
    }

    let top = prevTop
    if (bottom >= window.innerHeight) {
      top = 0 - height
    }

    if (top < 0 && fieldBounds.top - headerOffset + top < 0) {
      top = top + Math.abs(fieldBounds.top - headerOffset + top)
    }

    return { left: Math.min(left, prevLeft), top }
  }

  handleChange = (...data) => {
    this.showCalendar(false)
    this.props.onChange?.(...data)
  }

  render() {
    const {
      value,
      title,
      pinTime,
      shouldBlockInCalendar,
      estimatedTime,
      disabled,
      editing,
      itemId,
      firstDayOfWeek,
    } = this.props
    const { whenDatePickerPosition, calendarShown } = this.state

    return (
      <Container className='DateWrapper' disabled={disabled}>
        <Wrapper onClick={this.onWrapperClick} ref={(ref) => (this.fieldNode = ref)}>
          <StyledIcon
            className='DateWrapper__Icon'
            fill={getColor({
              isSelected: calendarShown,
              hasValue: iconColor,
              valueColor: iconColor,
              selectedColor: iconColor,
            })}
            icon={value ? 'CalendarEmpty' : 'CalendarCrossed'}
            size={16}
          />
          <StyledPlaceHolder
            className='DateWrapper__Placeholder'
            color={getColor({
              isSelected: calendarShown,
              hasValue: color,
              valueColor: color,
              selectedColor: color,
            })}
            isSelected={calendarShown}>
            {value ? moment(value).format('ddd, MMM D') : translations.general.noDate}
          </StyledPlaceHolder>
          {calendarShown && (
            <WhenDatePickerContainer
              left={whenDatePickerPosition.left}
              ref={(ref) => (this.whenDatePickerPopup = ref)}
              top={whenDatePickerPosition.top}>
              <WhenDatePicker
                value={value}
                title={title}
                pinTime={pinTime}
                onChange={this.handleChange}
                editing={editing}
                itemId={itemId}
                shouldBlockInCalendar={shouldBlockInCalendar}
                estimatedTime={estimatedTime}
                firstDayOfWeek={firstDayOfWeek}
              />
            </WhenDatePickerContainer>
          )}
        </Wrapper>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  padding: 0 10px;
`

Container.displayName = 'Container'

const StyledPlaceHolder = styled(PlaceHolder)`
  border: 1px solid transparent;
  line-height: normal;
  padding: 4px;
  transition: border-color 300ms ease-in-out, color 300ms ease-in-out;
`

StyledPlaceHolder.displayName = 'StyledPlaceHolder'

const Wrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;

  :hover ${StyledPlaceHolder} {
    color: ${styles.colors.primaryColor};
    border-color: ${styles.colors.middleGrey};
    border-radius: 8px;
  }
`

Wrapper.displayName = 'Wrapper'

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`

StyledIcon.displayName = 'StyledIcon'

const WhenDatePickerContainer = styled.div.attrs(({ left, top }) => ({
  style: {
    left: `${left}px`,
    top: `${top}px`,
  },
}))`
  position: absolute;
  z-index: 3;
  padding-bottom: 20px;
`

WhenDatePickerContainer.displayName = 'WhenDatePickerContainer'
