import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pushShortcutsGroup, popShortcutsGroup } from 'store/shortcuts/actions'

import { styles, translations } from 'gipsy-misc'
import { ProjectBadge, PrimaryButton, SecondaryButton } from 'gipsy-ui'
import variables from 'assets/styles/variables'

const componentName = 'AddPanel'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      pushShortcutsGroup,
      popShortcutsGroup,
    },
    dispatch
  ),
})

const cancelLabel = translations.general.cancel

class AddPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.id,
      name: props.name,
      color: props.color || styles.colors.projectColors[0].color,
      showNameError: false,
      showColorError: false,
    }
  }

  clearState = () => {
    this.setState({ id: undefined, name: '', color: '' })
  }

  componentDidMount() {
    const {
      actions: { pushShortcutsGroup },
      confirmLabel,
    } = this.props
    pushShortcutsGroup(
      [
        {
          label: cancelLabel,
          key: 'Escape',
          callback: this.onCancel,
        },
        {
          label: confirmLabel,
          key: 'Enter',
          callback: this.onConfirm,
        },
      ],
      componentName
    )
    this.timeout = setTimeout(() => {
      this.input.focus()
    }, styles.transitions.blurLayer * 1000)
  }

  componentWillUnmount() {
    const { popShortcutsGroup } = this.props.actions
    popShortcutsGroup(componentName)
    clearTimeout(this.timeout)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ id: this.props.id, name: this.props.name, color: this.props.color })
    }
  }

  handleChange = (event) => {
    this.setState({ name: event.target.value, showNameError: false })
  }

  onChangeColor = ({ color }) => {
    this.setState({ color, showColorError: false })
  }

  onConfirm = () => {
    const { onConfirm, showColorChoice } = this.props
    const { id, name, color } = this.state
    if (!name) {
      this.setState({ showNameError: true })
      return
    }
    if (showColorChoice && !color) {
      this.setState({ showColorError: true })
      return
    }
    onConfirm && onConfirm({ id, name, color })
    this.clearState()
  }

  onCancel = () => {
    const { onCancel } = this.props
    onCancel && onCancel()
  }

  render() {
    const { name, color, showNameError, showColorError } = this.state
    const { showColorChoice, placeholder } = this.props

    const projectColors = styles.colors.projectColors

    return (
      <Container>
        <Input
          type='text'
          value={name || ''}
          placeholder={placeholder}
          onChange={this.handleChange}
          ref={(input) => {
            this.input = input
          }}
          error={showNameError}
        />
        {showColorChoice && (
          <React.Fragment>
            <ColorTextContainer>{translations.projects.color}</ColorTextContainer>
            <ColorsContainer error={showColorError}>
              {projectColors.map((projectColor) => {
                const selected = color === projectColor.color
                return (
                  <ColorPick
                    key={projectColor.color}
                    color={projectColor.color}
                    size={23}
                    onClick={() => this.onChangeColor(projectColor)}
                    selected={selected}
                  />
                )
              })}
            </ColorsContainer>
          </React.Fragment>
        )}
        <ButtonContainer>
          <CancelButton width={120} height={48} onClick={this.onCancel} text={cancelLabel}></CancelButton>
          <PrimaryButton
            width={160}
            height={48}
            onClick={this.onConfirm}
            text={this.props.confirmLabel}></PrimaryButton>
        </ButtonContainer>
      </Container>
    )
  }
}

export default connect(null, mapDispatchToProps, null, {
  pure: true,
})(AddPanel)

AddPanel.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  showColorChoice: PropTypes.bool,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  margin-top: 20px;
  border-radius: 20px;
  padding: 15px 25px;
  background-color: ${variables.bodyBackgroundColor};
  color: ${styles.colors.textDarkColor};
  font: inherit;
  font-size: ${styles.fonts.fontSizeSmall};
  border: ${(props) =>
    props.error ? `1px solid ${styles.colors.pinkColor}` : `1px solid ${variables.bodyBackgroundColor}`};
`

const ColorTextContainer = styled.div`
  margin-top: 20px;
  color: ${styles.colors.textMediumDarkColor};
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
`

const ColorsContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  border-radius: 20px;
  border: ${(props) => (props.error ? `1px solid ${styles.colors.pinkColor}` : `1px solid inherit`)};
`

const ColorPick = styled(ProjectBadge)`
  cursor: pointer;
  box-shadow: ${(props) => (props.selected ? `0 8px 12px 0 ${props.color}` : '')};
  align-self: ${(props) => props.selected && 'flex-end'};
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const CancelButton = styled(SecondaryButton)`
  margin-right: 20px;
`
