import { createAction } from 'redux-actions'
import { styles } from 'gipsy-misc'
import { types } from 'store/popup/types'

export const openPopupInternal = createAction(types.OPEN_POPUP)
export const closePopupInternal = createAction(types.CLOSE_POPUP)
export const clearPopup = createAction(types.CLEAR_POPUP)

let timeout

export const closePopup = () => async (dispatch, getState, { api }) => {
  dispatch(closePopupInternal())
  timeout = setTimeout(() => {
    dispatch(clearPopup())
  }, styles.transitions.blurLayer * 1000)
}

export const openPopup = (payload) => async (dispatch, getState, { api }) => {
  clearTimeout(timeout)
  dispatch(openPopupInternal(payload))
}
