import styled, { css } from 'styled-components'
import variables from 'assets/styles/variables'

import { styles } from 'gipsy-misc'

const tailCommonStyles = css`
  width: 0;
  height: 0;
  border-top: ${variables.addCalendarTaskPopupTailWidth}px solid transparent;
  border-bottom: ${variables.addCalendarTaskPopupTailWidth}px solid transparent;
  border-left: ${variables.addCalendarTaskPopupTailWidth}px solid white;
  top: 12px;
  position: absolute;
  content: '';
`

const CalendarItemPopupContainer = styled.div.attrs(({ left, top }) => ({
  style: {
    left: Number.isFinite(left) ? `${left}px` : 'unset',
    right: Number.isFinite(left) ? 'unset' : 'calc(100% + 12px)',
    top: `${top || 0}px`,
  },
}))`
  z-index: ${(props) => props.zIndex ?? 3};
  min-width: ${(props) => props.minWidth}px;
  max-width: ${(props) => props.maxWidth}px;
  align-items: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: white;
  padding: ${(props) => props.padding ?? 8}px;
  border-radius: 8px;
  filter: ${styles.shadows.dropShadowPopup};
  width: ${variables.editCalendarItemPopupWidth}px;

  &::after {
    ${tailCommonStyles};
    right: -${variables.addCalendarTaskPopupTailWidth - 1}px;
  }

  &::before {
    ${tailCommonStyles};
    border-left-color: rgba(33, 21, 81, 0.1);
    right: -${variables.addCalendarTaskPopupTailWidth}px;
    opacity: 0.2;
  }

  ${({ shouldFlipTail }) =>
    shouldFlipTail &&
    css`
      &::after {
        left: -${variables.addCalendarTaskPopupTailWidth - 1}px;
        right: 0;
        transform: rotate(180deg);
      }

      &::before {
        left: -${variables.addCalendarTaskPopupTailWidth}px;
        right: 0;
        transform: rotate(180deg);
      }
    `}
`

export default CalendarItemPopupContainer
