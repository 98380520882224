import styled from 'styled-components'

import { styles } from 'gipsy-misc'

const Separator = styled.div`
  height: 1px;
  background-color: ${styles.colors.middleGrey};
`

export default Separator
