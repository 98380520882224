import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Popup from 'Popup'

const CustomPopup = React.forwardRef(function CustomPopup(
  { children, ContainerComponent = Popup, onClickOutside, ...props },
  outerRef
) {
  const innerRef = useRef(null)

  useEffect(() => {
    const clickHandler = (e) => {
      const ref = outerRef ? outerRef : innerRef

      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside?.(e)
      }
    }

    window.addEventListener('mousedown', clickHandler)

    return () => {
      window.removeEventListener('mousedown', clickHandler)
    }
  }, [onClickOutside, outerRef])

  return (
    <ContainerComponent {...props} ref={outerRef || innerRef}>
      {children}
    </ContainerComponent>
  )
})

CustomPopup.propTypes = {
  ContainerComponent: PropTypes.elementType,
  onClickOutside: PropTypes.func,
}

export default CustomPopup
