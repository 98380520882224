import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { styles } from 'gipsy-misc'
import * as icons from './icons'
import RedDot from 'RedDot'

const propTypes = {
  icon: PropTypes.string.isRequired,
  dot: PropTypes.bool,
  size: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
  fill: PropTypes.string,
  fillRect: PropTypes.string,
  stroke: PropTypes.string,
  hoverFill: PropTypes.string,
  style: PropTypes.object,
  dotSpaceRight: PropTypes.number,
  dotSpaceTop: PropTypes.number,
  dotBorderColor: PropTypes.string,
  disabled: PropTypes.bool,
}

class Icon extends Component {
  static propTypes = propTypes

  static defaultProps = {
    dotSpaceRight: 6,
    dotSpaceTop: 6,
    fill: styles.colors.darkGrey,
    stroke: 'none',
    size: 20,
    disabled: false,
    style: {},
  }

  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onClick']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }
      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    return false
  }

  onClick = (e) => {
    this.props.onClick && this.props.onClick(e)
  }

  render() {
    const {
      dotSpaceRight,
      dotSpaceTop,
      dotBorderColor,
      fill,
      fillRect,
      hoverFill,
      stroke,
      size,
      disabled,
      style,
      unclickable,
    } = this.props
    const SvgIcon = icons[this.props.icon]

    if (!SvgIcon) {
      console.error('icon', this.props.icon, "doesn't exist")
    }

    return (
      <Container
        className={this.props.className}
        fill={fill}
        fillRect={fillRect}
        hoverFill={hoverFill}
        size={size}
        disabled={disabled}
        onClick={this.onClick}
        cursorPointer={this.props.onClick}
        stroke={stroke}
        style={style}
        unclickable={unclickable}>
        <SvgIcon />
        {this.props.dot && (
          <AbsoluteRedDot
            dotSpaceRight={dotSpaceRight}
            dotSpaceTop={dotSpaceTop}
            size={size < 20 && '13px'}
            borderColor={dotBorderColor}
          />
        )}
      </Container>
    )
  }
}

export default Icon

const Container = styled.div`
  display: block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  position: relative;
  cursor: ${(props) => (props.cursorPointer ? 'pointer' : 'inherit')};
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.3;
    `}
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: ${(props) => props.fill};
    stroke: ${(props) => props.stroke};
  }
  path {
    fill: ${(props) => props.fill};
    stroke: ${(props) => props.stroke};
  }
  rect {
    fill: ${(props) => props.fillRect};
    stroke: ${(props) => props.strokeRect};
  }
  &:hover svg,
  &:hover path {
    fill: ${(props) => (props.hoverFill ? props.hoverFill : props.fill)};
  }
  ${(props) => props.style};

  ${({ unclickable }) =>
    unclickable &&
    css`
      pointer-events: none;
    `}
`

const AbsoluteRedDot = styled(RedDot)`
  position: absolute;
  top: -${(props) => props.dotSpaceTop}px;
  right: -${(props) => props.dotSpaceRight}px;
`
