export const isToday = (pathname) => {
  return pathname === '/tasks' || pathname === '/tasks/all'
}

export const isUncategorized = (pathname) => {
  return pathname === '/uncategorized'
}

export const isProject = (pathname) => {
  return pathname?.startsWith('/project')
}

export const isTag = (pathname) => {
  return pathname?.startsWith('/tag')
}
