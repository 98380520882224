/* eslint-disable no-throw-literal */
import { createAction } from 'redux-actions'
import { types } from 'store/sprintComposer/types'

const _setTasks = createAction(types.SET_FOCUS_BLOCK_TASKS)
const _addTask = createAction(types.ADD_FOCUS_BLOCK_TASK)
const _adjustRanks = createAction(types.ADJUST_FOCUS_BLOCK_RANKS)
const _removeTask = createAction(types.REMOVE_FOCUS_BLOCK_TASK)
const _setDragging = createAction(types.SET_SPRINT_COMPOSER_DRAGGING)
const _updateTask = createAction(types.UPDATE_FOCUS_BLOCK_TASK)

export const setTasks = (tasks) => (dispatch) => dispatch(_setTasks({ tasks }))
export const addTask = (task) => (dispatch) => dispatch(_addTask({ task }))
export const adjustRanks = (draggableId, destinationIndex) => (dispatch) =>
  dispatch(_adjustRanks({ destinationIndex, draggableId }))
export const setDragging = (value) => (dispatch) => dispatch(_setDragging(value))
export const updateTask = (updatedTask, taskId) => (dispatch) => dispatch(_updateTask({ updatedTask, taskId }))
export const removeTask = (taskId) => (dispatch) => dispatch(_removeTask({ taskId }))
