import moment from 'moment'

import { computeLastDayOfWeekSection } from 'logic/today'
import { TaskObjectGroups } from './models'

import { models, utils } from 'gipsy-misc'

export const isBefore = (item1, item2) => {
  switch (true) {
    case item1.when?.date !== item2.when?.date:
      return utils.datetime.isBeforeDateStr(item1.when?.date, item2.when?.date)
    case item1.type !== item2.type:
      return item1.type === models.item.type.SPRINT
    case !!(item1.pin?.time && item2.pin?.time):
      return isTimeBefore(item1, item2)
    case !!(item1.pin?.time || item2.pin?.time):
      return !!item1.pin?.time
    default:
      return moment(item1.creationTime).isBefore(moment(item2.creationTime))
  }
}

export const isTimeBefore = (item1, item2) => {
  const pinTime1 = moment(item1.pin.time)
  const pinTime2 = moment(item2.pin.time)
  switch (true) {
    case pinTime1.unix() !== pinTime2.unix():
      return pinTime1.isBefore(pinTime2)
    case item1.estimatedTime !== item2.estimatedTime:
      return item1.estimatedTime < item2.estimatedTime
    default:
      return moment(item1.creationTime).isBefore(moment(item2.creationTime))
  }
}

export const getScheduledGroup = (item) => {
  const today = moment().format('YYYY-MM-DD')
  const lastDayOfWeekSection = computeLastDayOfWeekSection(moment())

  switch (true) {
    case !item.when?.date:
      return TaskObjectGroups.BACKLOG
    case item.when?.date <= today:
      return TaskObjectGroups.TODAY
    case item.when?.date <= lastDayOfWeekSection:
      return TaskObjectGroups.WEEK
    default:
      return TaskObjectGroups.LATER
  }
}

export const findItemByIdInTaskList = (taskList, id) => {
  for (let group in taskList) {
    const groupItems = taskList[group]

    if (!groupItems) continue

    let index = null
    let sprint = null
    let subIndex = null
    let task = null

    groupItems.findIndex((item, itemIndex) => {
      if (item.id === id) {
        index = itemIndex
        return true
      }

      if (item.type === models.item.type.SPRINT && item.tasks) {
        item.tasks.findIndex((task, taskIndex) => {
          if (task.id === id) {
            index = itemIndex
            subIndex = taskIndex
            return true
          }

          return false
        })
      }

      return false
    })

    if (index !== null || subIndex !== null) {
      task = subIndex !== null ? groupItems[index]?.tasks[subIndex] : groupItems[index]
      sprint = subIndex !== null ? groupItems[index] : null

      // edge case when searching by sprint id
      if (sprint === null && task.type === models.item.type.SPRINT) {
        sprint = task
        task = null
      }

      return { group, index, sprint, subIndex, task }
    }
  }

  return {}
}

export const filterItemsByIdInList = (taskList = {}, idsMap = {}) => {
  const idsToFilter = Object.keys(idsMap)

  if (idsToFilter.length === 0) return taskList

  const updatedList = JSON.parse(JSON.stringify(taskList))

  idsToFilter.forEach((idToFilter) => {
    const { group, index, sprint, subIndex } = findItemByIdInTaskList(updatedList, idToFilter)

    if (!group) return

    if (sprint) {
      sprint.tasks.splice(subIndex, 1)
    } else {
      updatedList[group].splice(index, 1)
    }
  })

  return updatedList
}

export const sortListByTypeAndScheduledTime = (list) =>
  utils.list.sortListByConditionFn(list, (a, b) => !isBefore(a, b))

export const getFilteredTasksAndFirstSprintInstancesMap = (allItems) => {
  const mapRecSprintIdFirstInstanceId = {} // we create a map to know the first instance of reccuring items
  const filteredTasks = []
  const mapRecTaskNextActiveInstance = {}

  Object.keys(allItems).forEach((id) => {
    const item = allItems[id]

    if (!item || item?.completed === 1 || item.completionTime) return

    if (item.type === models.item.type.SPRINT && utils.sprint.isRecurrent(item)) {
      const recSprintId = utils.sprint.getRecSprintId(item)
      const firstInstanceId = mapRecSprintIdFirstInstanceId[recSprintId]

      if (firstInstanceId) {
        const firstInstance = allItems[firstInstanceId]

        if (isBefore(item, firstInstance)) {
          mapRecSprintIdFirstInstanceId[recSprintId] = id
        }
      } else {
        mapRecSprintIdFirstInstanceId[recSprintId] = id
      }
    }

    if (item.type === models.item.type.TASK) {
      if (!utils.task.isRecurrent(item) || utils.task.isPast(item)) {
        filteredTasks.push(item)
        return
      }

      const recTaskId = utils.task.getRecTaskId(item)
      const nextInstance = mapRecTaskNextActiveInstance[recTaskId]

      if (nextInstance) {
        if (utils.task.isScheduledBefore(item, nextInstance)) {
          mapRecTaskNextActiveInstance[recTaskId] = item
        }
      } else {
        mapRecTaskNextActiveInstance[recTaskId] = item
      }
    }
  })

  filteredTasks.push(...Object.values(mapRecTaskNextActiveInstance))
  return { filteredTasks, mapRecSprintIdFirstInstanceId }
}
