export const types = {
  SET_PANEL_EXPANDED: 'SET_PANEL_EXPANDED',
  SET_PANEL_OPEN: 'SET_PANEL_OPEN__TASK_PANEL',
  SET_SELECT_MODE: 'SET_SELECT_MODE__TASK_PANEL',

  SET_DRAGGING: 'SET_DRAGGING__TASK_PANEL',
  SET_FILTERED_ITEMS_IDS: 'SET_FILTERED_ITEMS_IDS__TASK_PANEL',
  ADD_FILTERED_ITEM_ID: 'ADD_FILTERED_ITEMS_IDS__TASK_PANEL',
  UPDATE_FILTERED_ITEM_ID: 'UPDATE_FILTERED_ITEMS_IDS__TASK_PANEL',
  REMOVE_FILTERED_ITEM_ID: 'REMOVE_FILTERED_ITEMS_IDS__TASK_PANEL',

  ADD_TMP_ITEM: 'ADD_TMP_ITEM__TASK_PANEL',
  REMOVE_TMP_ITEM: 'REMOVE_TMP_ITEM__TASK_PANEL',
  SET_TMP_ITEMS: 'SET_TMP_ITEMS__TASK_PANEL',

  ADD_ITEM: 'ADD_ITEM__TASK_PANEL',
  UPDATE_ITEM: 'UPDATE_ITEM__TASK_PANEL',
  REMOVE_ITEM: 'REMOVE_ITEM__TASK_PANEL',
  ADD_SPRINT_TASK: 'ADD_SPRINT_TASK__TASK_PANEL',
  REMOVE_SPRINT_TASK: 'REMOVE_SPRINT_TASK__TASK_PANEL',

  ADD_FILTERED_ID: 'ADD_FILTERED_ID',
  REMOVE_FILTERED_ID: 'REMOVE_FILTERED_ID',
  REPLACE_FILTERED_ID: 'REPLACE_FILTERED_ID',
  RESET_FILTERED_IDS: 'RESET_FILTERED_IDS',
}
