import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

import { calendar, emailaccounts, mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, styles, translations } from 'gipsy-misc'
import { Icon } from 'gipsy-ui'

import { fadeIn, pulseAnimation, spinAnimation } from 'features/keyframes'
import ConfirmPanel from 'features/popup/components/confirmPanel'
import { closePopup, openPopup } from 'store/popup/actions'

const ONE_MINUTE = 1000 * 60 * 1

export default function AccountControls({ email, isMainAccount, onSyncAccount, onUnsyncAccount, invalidCreds }) {
  const dispatch = useDispatch()

  const [isJustSyncedMessageShown, setJustSyncedMessageShown] = useState(false)
  const [isSyncDisabledMessageShown, setSyncDisabledMessageShown] = useState(false)
  const [isSyncing, setSyncing] = useState(false)
  const [isUnsyncing, setUnsyncing] = useState(false)

  const disabledMessageTimeoutRef = useRef(null)
  const syncedMessageTimeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(disabledMessageTimeoutRef.current)
      clearTimeout(syncedMessageTimeoutRef.current)
    }
  }, [])

  const lastSyncId = `lastEmailSync_${email}`

  const checkSyncButtonEnabled = () => {
    const lastSync = window.localStorage.getItem(lastSyncId)
    return isSyncing || !lastSync || (Date.now() > lastSync && Date.now() - lastSync > ONE_MINUTE)
  }

  const getSyncButtonMessage = () => {
    if (isJustSyncedMessageShown) {
      return translations.general.refreshed
    } else if (isSyncing) {
      return translations.general.refreshing
    } else if (isUnsyncing) {
      return translations.integrations.unsyncing
    } else {
      return translations.general.refresh
    }
  }

  const showSyncedMessage = () => {
    setJustSyncedMessageShown(true)

    if (syncedMessageTimeoutRef.current) clearTimeout(syncedMessageTimeoutRef.current)

    syncedMessageTimeoutRef.current = setTimeout(() => setJustSyncedMessageShown(false), 2500)
  }

  const showSyncDisabledMessage = () => {
    setSyncDisabledMessageShown(true)

    if (disabledMessageTimeoutRef.current) clearTimeout(disabledMessageTimeoutRef.current)

    disabledMessageTimeoutRef.current = setTimeout(() => {
      setSyncDisabledMessageShown(false)
    }, 3500)
  }

  const syncAccount = async () => {
    const shouldSync = checkSyncButtonEnabled()

    if (isSyncing) return

    if (shouldSync) {
      setSyncing(true)

      try {
        await calendar.synchronize(email, true)
        onSyncAccount()
        window.localStorage.setItem(lastSyncId, Date.now())
        showSyncedMessage()
      } catch (e) {
        console.log('sync failed')
      } finally {
        setSyncing(false)
      }
    } else {
      showSyncDisabledMessage()
    }
  }

  const onClickUnsyncAccount = () => {
    const onConfirm = async () => {
      dispatch(closePopup())
      setUnsyncing(true)
      await emailaccounts.unsync(email)
      onUnsyncAccount(email)
    }

    const onCancel = () => dispatch(closePopup())

    dispatch(
      openPopup({
        title: translations.general.areSure,
        centeredTitle: true,
        logo: 'sad',
        component: (
          <ConfirmPanel
            cancelLabel={translations.general.noKeep}
            confirmLabel={translations.general.yesPlease}
            onCancel={onCancel}
            onConfirm={onConfirm}
            subtitle={translations.calendarSettings.sections.connectedAccounts.unsync.prompt}
          />
        ),
      })
    )

    mixpanelApi.track({ event: mixpanel.disconnectAccountClickedEvent })
  }

  return (
    <Container>
      <Controls>
        {!invalidCreds && (
          <RefreshButton onClick={syncAccount}>
            <RefreshIcon
              fill={checkSyncButtonEnabled() ? styles.colors.darkGrey : styles.colors.middleGrey}
              icon='Refresh'
              isSyncing={isSyncing}
              size={14}
            />
            <ActionText
              animate={isSyncing || isUnsyncing}
              color={
                isJustSyncedMessageShown
                  ? styles.colors.greenColor
                  : checkSyncButtonEnabled() || isUnsyncing
                  ? styles.colors.darkGrey
                  : styles.colors.middleGrey
              }>
              {getSyncButtonMessage()}
            </ActionText>
          </RefreshButton>
        )}
        {!isMainAccount && <Disconnect onClick={onClickUnsyncAccount}>{translations.general.disconnect}</Disconnect>}
      </Controls>
      {isSyncDisabledMessageShown && (
        <MessageContaniner>
          <ActionText fadeIn={true}>{translations.integrations.sync.disabled}</ActionText>
        </MessageContaniner>
      )}
    </Container>
  )
}

const Container = styled.div`
  margin: 18px 0 8px;
  padding: 0 10px 0 8px;
`

Container.displayName = 'Container'

const Controls = styled.div`
  align-items: center;
  display: flex;
`

Controls.displayName = 'Controls'

const RefreshButton = styled.div`
  align-items: center;
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px;
  width: 160px;
`

RefreshButton.displayName = 'RefreshButton'

const RefreshIcon = styled(Icon)`
  margin-right: 8px;

  ${(props) =>
    props.isSyncing &&
    css`
      animation: ${spinAnimation} 1s ease-in-out infinite;
    `}
`

RefreshIcon.displayName = 'RefreshIcon'

const ActionText = styled.div`
  color: ${(props) => props.color || styles.colors.darkGrey};
  font-size: 13px;

  ${(props) =>
    props.animate &&
    css`
      animation: ${pulseAnimation} 1s ease-in-out infinite;
      font-style: italic;
    `};

  ${(props) =>
    props.fadeIn &&
    css`
      animation: ${fadeIn} 1s ease-in-out;
    `};
`

ActionText.displayName = 'ActionText'

const Disconnect = styled.span`
  color: ${styles.colors.darkGrey};
  cursor: pointer;
  font-size: 13px;
  margin-left: auto;
`

Disconnect.displayName = 'Disconnect'

const MessageContaniner = styled.div`
  margin-top: 8px;
`

MessageContaniner.displayName = 'MessageContaniner'
