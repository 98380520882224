const breakpoints = {
  tabletSmall: 650,
  tablet: 769,
  tabletLarge: 829,
  desktopSmall: 1024,
  desktop: 1200,
  desktopLarge: 1520,
}

export const verticalBreakpoints = {
  phoneLarge: 800,
}

export default breakpoints
