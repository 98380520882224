import React from 'react'
import styled, { css } from 'styled-components'

import { constants, styles } from 'gipsy-misc'
import { FixedTooltip } from 'gipsy-ui'

import { NavLinkContainer, NavLinkContent, NavLinkIcon } from './commonUIComponents'

function NavBarLink({ active, colorFill, colorStroke, icon, shrinked, text, to, onClick }) {
  return (
    <StyledNavLinkContainer className='NavBarLink' to={to} onClick={onClick}>
      <StyledNavLinkContent active={active} colorFill={colorFill} colorStroke={colorStroke}>
        {shrinked ? (
          <FixedTooltip
            content={text}
            horizontalOffset={-8}
            horizontalPosition='left'
            portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
            style={{
              padding: '8px',
            }}
            verticalOffset={-12}
            verticalPosition='bottom'>
            <NavLinkIcon icon={icon} noMargin={shrinked} size={14} />
          </FixedTooltip>
        ) : (
          <>
            <NavLinkIcon icon={icon} noMargin={shrinked} size={14} />
            <span>{text}</span>
          </>
        )}
      </StyledNavLinkContent>
    </StyledNavLinkContainer>
  )
}

const StyledNavLinkContainer = styled(NavLinkContainer)`
  display: block;
  height: 100%;

  .FixedTooltip__wrapper {
    align-items: center;
    display: flex;
    margin: auto;
  }
`

StyledNavLinkContainer.displayName = 'StyledNavLinkContainer'

const StyledNavLinkContent = styled(NavLinkContent)`
  border-bottom: 2px solid transparent;
  height: 100%;
  padding: 0 32px;
  transition: border-bottom-color 400ms ease-in-out;

  ${({ active }) => css`
    ${active &&
    css`
      border-bottom-color: ${styles.colors.primaryColor};
    `}
  `}
`

StyledNavLinkContent.displayName = 'StyledNavLinkContent'

export default NavBarLink
