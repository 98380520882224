import { createStore, applyMiddleware } from 'redux'
// import logger from 'redux-logger'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { remove } from 'store/session/actions'
import { types as sessionTypes } from 'store/session/types'
import reducers from './reducers'
import { OptimisticUpdater } from 'logic/app/optimisticUpdater'
import * as api from 'gipsy-api'
let reduxOptimisticUpdater

const stateSyncMiddlewareConfig = {
  whitelist: [
    sessionTypes.SET_FOCUSSESSION,
    sessionTypes.REMOVE_SESSION,
    sessionTypes.SET_MUTEMODEON,
    sessionTypes.SET_COMPLETED_SESSION,
    sessionTypes.REMOVE_COMPLETED_SESSION,
    sessionTypes.SET_HIDEFOCUSEDTASK,
  ],
}

let storeOptions
let middlewares = [thunk.withExtraArgument({ api }), createStateSyncMiddleware(stateSyncMiddlewareConfig)]
if (process.env.REACT_APP_BACKEND_URL !== 'https://api.kosmotime.com') {
  middlewares = [
    ...middlewares,
    // logger
  ]
  storeOptions = composeWithDevTools(applyMiddleware(...middlewares))
} else {
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  })
  storeOptions = composeEnhancers(applyMiddleware(...middlewares))
}

const init = () => {
  let store = createStore(reducers, storeOptions)
  initMessageListener(store)
  api.listeners.onRefreshTokenFailed = () => {
    store.dispatch(remove())
  }
  reduxOptimisticUpdater = new OptimisticUpdater(store.dispatch, store.getState)
  return store
}

export default init()

export { reduxOptimisticUpdater }
