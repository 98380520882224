export const getSelectedDataOfProjects = (projects) => {
  let hasProjectSelected = false
  let hasProjectUnselected = false

  if (!projects) {
    return { hasProjectSelected, hasProjectUnselected }
  }

  for (let project of projects) {
    if (project.selected) {
      if (!hasProjectSelected) {
        hasProjectSelected = true
      }
      if (hasProjectUnselected) {
        return { hasProjectSelected, hasProjectUnselected }
      }
    } else {
      if (!hasProjectUnselected) {
        hasProjectUnselected = true
      }
      if (hasProjectSelected) {
        return { hasProjectSelected, hasProjectUnselected }
      }
    }
  }

  return { hasProjectSelected, hasProjectUnselected }
}

export const getSelectedDataOfTeams = (teams) => {
  let hasProjectSelected = false
  let hasProjectUnselected = false

  if (!teams) {
    return { hasProjectSelected, hasProjectUnselected }
  }

  for (let team of teams) {
    const {
      hasProjectSelected: tmpHasProjectSelected,
      hasProjectUnselected: tmpHasProjectUnselected,
    } = getSelectedDataOfProjects(team.projects)

    hasProjectSelected = hasProjectSelected || tmpHasProjectSelected
    hasProjectUnselected = hasProjectUnselected || tmpHasProjectUnselected
    if (hasProjectSelected && hasProjectUnselected) {
      return { hasProjectSelected, hasProjectUnselected }
    }
  }

  return { hasProjectSelected, hasProjectUnselected }
}

export const changeSelectValueOfProject = (userData, { workspaceIdx, teamIdx = -1, projectIdx } = {}) => {
  if (teamIdx > -1) {
    const project = userData.workspaces[workspaceIdx].teams[teamIdx].projects[projectIdx]
    project.selected = !project.selected
    userData.workspaces[workspaceIdx].teams[teamIdx].projects[projectIdx] = project
  } else {
    const project = userData.workspaces[workspaceIdx].projects[projectIdx]
    project.selected = !project.selected
    userData.workspaces[workspaceIdx].projects[projectIdx] = project
  }
  return userData
}

export const selectAllProjects = (userData, { workspaceIdx, teamIdx = -1 } = {}, value) => {
  const workspace = userData.workspaces[workspaceIdx]
  if (teamIdx > -1) {
    for (let i = 0; i < workspace.teams[teamIdx].projects?.length; i++) {
      workspace.teams[teamIdx].projects[i].selected = value
    }
  } else {
    if (workspace.isOrganization) {
      for (let i = 0; i < workspace.teams?.length; i++) {
        for (let j = 0; j < workspace.teams[i].projects?.length; j++) {
          workspace.teams[i].projects[j].selected = value
        }
      }
    } else {
      for (let j = 0; j < workspace.projects?.length; j++) {
        workspace.projects[j].selected = value
      }
    }
  }
  userData.workspaces[workspaceIdx] = workspace
  return userData
}
