import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { styles, utils } from 'gipsy-misc'
import Icon from 'Icon'

export const Timer = (props) => {
  const { className, startDate, timeAlreadySpent, showHours } = props

  const calculateTimeElapsed = () => {
    let difference = +new Date() - +new Date(startDate)

    if (timeAlreadySpent) {
      difference += timeAlreadySpent / utils.datetime.durationTimeUnitsByMilliSecond
    }
    let timeElapsed = {}
    if (difference > 0) {
      timeElapsed = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeElapsed
  }

  const [timeElapsed, setTimeElapsed] = useState(calculateTimeElapsed())

  useEffect(() => {
    let timeout = setTimeout(() => {
      setTimeElapsed(calculateTimeElapsed())
    }, 1000)
    return function cleanup() {
      clearTimeout(timeout)
    }
  })

  return Object.keys(timeElapsed).length > 0 ? (
    <div className={className}>
      {timeElapsed.hours > 0 || showHours ? `${utils.datetime.nbToStr2digit(timeElapsed.hours)}:` : ''}
      {utils.datetime.nbToStr2digit(timeElapsed.minutes)}:{utils.datetime.nbToStr2digit(timeElapsed.seconds)}
    </div>
  ) : (
    <div />
  )
}

export const FixedClockTimer = (props) => {
  const { timeSpent, className } = props
  const { hour, minute, second } = utils.datetime.getHourAndMinuteAndSecondFromNanoseconds(timeSpent)

  return (
    <Container className={className}>
      <Icon size={14} icon="FilledClock" fill={styles.colors.textDarkColor} />
      <TimeContainer>
        {hour > 0 ? `${utils.datetime.nbToStr2digit(hour)}:` : ''}
        {utils.datetime.nbToStr2digit(minute)}:{utils.datetime.nbToStr2digit(second)}
      </TimeContainer>
    </Container>
  )
}

export const ClockTimer = (props) => {
  const { startDate, timeAlreadySpent, className } = props

  return (
    <Container className={className}>
      <Icon size={14} icon="FilledClock" fill={styles.colors.darkGrey} />
      <StyledTimer startDate={startDate} timeAlreadySpent={timeAlreadySpent} />
    </Container>
  )
}

const Container = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0;
  }
`
const StyledTimer = styled(Timer)`
  ${() => timeStyle}
  min-width: 40px;
`

const TimeContainer = styled.span`
  ${() => timeStyle}
`

const timeStyle = css`
  margin-left: 5px;
  color: ${styles.colors.textDarkColor};
  font-size: ${styles.fonts.fontSizeXSmall};
  font-weight: 500;
`
