import { areElementsUniqueAndMatch } from 'utils/integers'

import { Schedules } from 'models/recurrency'
import { DailyOptions, DailyRecurrence, RecurrencyDetails, WeeklyOptions, WeeklyRecurrence } from './types'

const areDailyOptionsEqual = (opts1: DailyOptions, opts2: DailyOptions): boolean => {
  return opts1.every === opts2.every
}

const areWeeklyOptionsEqual = (opts1: WeeklyOptions, opts2: WeeklyOptions): boolean => {
  return opts1.every === opts2.every && areElementsUniqueAndMatch(opts1.days, opts2.days)
}

export const isRecurrenceEqual = (details1: RecurrencyDetails, details2: RecurrencyDetails): boolean => {
  if (!details1 && !details2) return true
  if (!details1 || !details2) return false

  switch (details1.schedule) {
    case Schedules.Daily: {
      return (
        details2.schedule === Schedules.Daily &&
        areDailyOptionsEqual((details1 as DailyRecurrence).dailyOptions, (details2 as DailyRecurrence).dailyOptions)
      )
    }

    case Schedules.Weekly: {
      return (
        details2.schedule === Schedules.Weekly &&
        areWeeklyOptionsEqual(
          (details1 as WeeklyRecurrence).weeklyOptions,
          (details2 as WeeklyRecurrence).weeklyOptions
        )
      )
    }

    default: {
      return false
    }
  }
}
