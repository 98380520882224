import React from 'react'
import { IconButton } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

export default function AddFocusBlockButton(props) {
  return (
    <IconButton
      onClick={props.onClick}
      iconName={'Sprint'}
      flexGrow
      iconSize={16}
      labelHoverColor={styles.colors.orangeColor}
      borderHoverColor={`${styles.colors.orangeColor}80`}
      label={translations.sprint.add}
    />
  )
}
