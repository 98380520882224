import { types } from 'store/taskPanel/types'
import { getIsExpanded as getIsCalendarListExpanded } from 'features/calendar/components/CalendarList/utils'
import { getIsExpanded as getIsTaskPanelExpanded } from 'features/taskPanel/utils'

const initialState = {
  isExpanded: !getIsCalendarListExpanded() && getIsTaskPanelExpanded(),
  isShown: false,
  inSelectMode: false,
}

export function settingsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case types.SET_SELECT_MODE:
      return {
        ...state,
        inSelectMode: payload,
      }
    case types.SET_PANEL_EXPANDED:
      return {
        ...state,
        isExpanded: payload,
      }
    case types.SET_PANEL_OPEN:
      return {
        ...state,
        isShown: payload,
      }

    default:
      break
  }
  return state
}
