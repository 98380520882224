import inject from 'utils/inject'

const executeAtRuntime = () => {
  inject('gipsy-api', 'BACKEND_URL', process.env.REACT_APP_BACKEND_URL)
  let source
  if (process.env.REACT_APP_ELECTRON === 'true') {
    source = 'desktop'
  } else {
    source = 'homebase'
  }
  inject('gipsy-api', 'source', source)
  inject('gipsy-ui', 'source', source)
  inject('gipsy-ui', 'FRONTEND_URL', process.env.REACT_APP_FRONTEND_URL)
  inject('gipsy-misc', 'env', process.env.REACT_APP_ENV)
}

executeAtRuntime()
