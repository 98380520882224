import { produce } from 'immer'
import moment from 'moment'
import {
  convertNanosecondsToMinute,
  convertSecondsToNanoseconds,
  getDurationInNanoSeconds,
  beautifySpentTime,
  getDurationObj,
} from 'utils/datetime'

import models from 'models'

export const defaultDurationFSMin = 15
export const minDurationFSNS = convertSecondsToNanoseconds(30)

export const getSpentTimeInNanoSeconds = ({ startTime, endTime }) => {
  return getDurationInNanoSeconds({ start: startTime, end: endTime })
}

export const getSpentTimeObj = ({ startTime, endTime }) => {
  return getDurationObj({ start: startTime, end: endTime })
}

export const getSpentTimeString = ({ startTime, endTime }) => {
  const spentTimeInNanoSeconds = getSpentTimeInNanoSeconds({ startTime, endTime })
  return beautifySpentTime(spentTimeInNanoSeconds)
}

const computeDefaultFocusSessionPrefixId = (taskId) => {
  return 'defaultFocusSession_' + taskId + '_'
}

export const createDefaultFocusSession = ({ endTime, task }) => {
  let startTime
  if (task.pin && task.pin.time) {
    const endTimeEvent = moment(task.pin.time).add(convertNanosecondsToMinute(task.estimatedTime), 'm')
    if (moment(endTime) < endTimeEvent && moment(task.pin.time) < moment(endTime)) {
      startTime = new Date(task.pin.time)
    } else {
      startTime = new Date(moment(endTime).add(-convertNanosecondsToMinute(task.estimatedTime), 'm'))
    }
  } else {
    startTime = new Date(moment(endTime).add(-defaultDurationFSMin, 'm'))
  }
  return {
    id: computeDefaultFocusSessionPrefixId(task.id) + Math.random().toString(36).substring(2, 15),
    startTime: startTime.toISOString(),
    endTime: new Date(endTime).toISOString(),
    taskId: task.id,
    title: task.title,
    type: models.item.type.FOCUSSESSION,
    mode: models.focussession.CalendarMode.COMPLETED,
  }
}

export const isCreatedDefaultFocusSession = (focusSession, taskId) => {
  return !!focusSession.id && focusSession.id.startsWith(computeDefaultFocusSessionPrefixId(taskId))
}

export const addFocusSessionFromPause = (currentSession) => {
  const startTime = currentSession?.startTime
  const currentFocusSessions = currentSession?.task?.focusSessions || []
  const endTime = new Date()
  let discardFocusSession

  if (startTime) {
    const spentTimeNS = getSpentTimeInNanoSeconds({ endTime, startTime: new Date(startTime) })
    discardFocusSession = spentTimeNS < minDurationFSNS
  } else {
    discardFocusSession = true
  }

  const newFocusSession = produce(currentSession, (draft) => {
    if (!discardFocusSession) {
      draft.endTime = endTime.toISOString()
    }
  })

  const updatedItemFocusSessions = !discardFocusSession
    ? produce(currentFocusSessions, (draft) => {
        draft.push(newFocusSession)
      })
    : null

  return { discardFocusSession, focusSession: newFocusSession, taskFocusSessions: updatedItemFocusSessions }
}

export const getCompletedFocusSession = (currentSession) => {
  const itemTitle = currentSession?.task?.title
  const startTime = currentSession?.startTime
  const endTime = new Date()
  const spentTimeNS = getSpentTimeInNanoSeconds({ endTime, startTime: new Date(startTime) })

  const newFocusSession = produce(currentSession, (draft) => {
    if (spentTimeNS < minDurationFSNS) {
      draft.startTime = new Date(moment(endTime).add(-defaultDurationFSMin, 'm')).toISOString()
    }

    draft.endTime = endTime.toISOString()
    draft.title = itemTitle
    draft.mode = models.focussession.CalendarMode.COMPLETED
  })

  return newFocusSession
}
