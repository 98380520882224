import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { translations } from 'gipsy-misc'

const componentName = 'editCalendarTaskPopup'

export function useShortCuts({ onClickEdit, onClickComplete, onClickStart, onClickDelete }) {
  const dispatch = useDispatch()

  const shortcuts = useMemo(() => {
    let shortcuts = []
    if (onClickEdit) {
      shortcuts.push({
        key: 'e',
        label: translations.general.edit,
        callback: onClickEdit,
      })
    }
    if (onClickDelete) {
      shortcuts.push({
        key: 'Backspace',
        label: translations.general.delete,
        callback: onClickDelete,
      })
    }

    if (onClickComplete) {
      shortcuts.push({
        key: 'Enter',
        shiftKey: true,
        label: translations.general.complete,
        callback: onClickComplete,
      })
    }
    if (onClickStart) {
      shortcuts.push({
        key: 's',
        label: translations.general.start,
        shiftKey: true,
        callback: onClickStart,
      })
    }

    return shortcuts
  }, [onClickEdit, onClickComplete, onClickStart, onClickDelete])

  useEffect(() => {
    dispatch(pushShortcutsGroup(shortcuts, componentName))
    return () => dispatch(popShortcutsGroup(componentName))
  }, [dispatch, shortcuts])
}
