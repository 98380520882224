import React, { PureComponent } from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import NotFound from 'pages/notFound'
import ActivePage from './active'
import TimeReports from 'pages/timeReports'

const mapStateToProps = (state) => ({
  areProjectsLoaded: state.projects.areProjectsLoaded,
  areTagsLoaded: state.tags.areTagsLoaded,
  areEventsLoaded: state.calendar.items.fetchStatus.areLoaded,
})

class UncategorizedLayout extends PureComponent {
  render() {
    const { areProjectsLoaded, areTagsLoaded, areEventsLoaded } = this.props
    if (!areProjectsLoaded || !areTagsLoaded || !areEventsLoaded) return null
    return (
      <div className="project-layout">
        <TransitionGroup>
          <CSSTransition
            key={this.props.location.pathname}
            timeout={{ enter: 400, exit: 400 }}
            appear={true}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={'page-transition'}>
            <Switch location={this.props.location}>
              <Route exact path="/uncategorized" render={(routeProps) => <ActivePage {...routeProps} />} />
              <Route exact path="/uncategorized/reports" render={(routeProps) => <TimeReports {...routeProps} />} />
              <Route component={NotFound} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, null, null, {
    pure: true,
  })(UncategorizedLayout)
)
