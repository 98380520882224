import fonts from './fonts'
import colors from './colors'
import shadows from './shadows'
import spacings from './spacings'
import transitions from './transitions'

const styles = {
  fonts,
  colors,
  shadows,
  spacings,
  transitions,
}

export default styles
