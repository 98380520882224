import React, { useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'

import { mixpanel, translations, variables } from 'gipsy-misc'
import { mixpanel as mixpanelApi } from 'gipsy-api'
import { FocusedLineCompressedConsts, UserPicture } from 'gipsy-ui'

import HomeBaseFocusedLine from 'features/focusSession/components/line'
import layoutBreakpoints from 'features/layout/breakpoints'
import { isUncategorized, isToday, isProject, isTag } from 'logic/navigation'
import { signout } from 'store/session/actions'
import { isChromeBrowser } from 'utils/chrome'
import { EXTENSION_LINK } from 'utils/chromeext'

import FocusControls from './components/FocusControls'
import NavBarLink from './components/NavBarLink'
import NavBarMenu from './components/NavBarMenu'
import NavBarSupport from './components/NavBarSupport'
import OnboardingVideoOverlay from './components/OnboardingVideoOverlay'
import { OnboardingExtensionInstallPrompt } from './components/Prompts'

const reportsRoute = '/reports'
const rootRoute = '/'
const tasksRoute = '/tasks'

const { containerHeight: focusedLineHeight } = FocusedLineCompressedConsts
export const navbarHeight = 56
export const focusBlockContainerId = 'header-focus-block'

function Header({ location, setShowOnboardingVideo, showOnboardingVideo, windowWidth }) {
  const controls = useAnimation()
  const dispatch = useDispatch()
  const session = useSelector((state) => state.session)

  const [showOnboardingExtensionPrompt, setShowOnboardingExtensionPrompt] = useState(false)

  const wrapperRef = useRef(null)

  useLayoutEffect(() => {
    let height = session?.focusSession?.taskId ? focusedLineHeight + navbarHeight : navbarHeight

    controls.start({
      height,
    })
  }, [controls, session?.focusSession?.taskId])

  const handleLogoutClicked = () => {
    dispatch(signout())
  }

  const fromOnboarding = location.state?.fromOnboarding

  const handleContinueToCalendar = () => {
    setShowOnboardingVideo(false)
    mixpanelApi.track({ event: mixpanel.onboardingVideoContinueClickedEvent })

    if (isChromeBrowser) {
      setShowOnboardingExtensionPrompt(true)
    }
  }

  const handleExtensionInstallClicked = () => {
    window.open(EXTENSION_LINK, '_blank')
    setShowOnboardingExtensionPrompt(false)
    mixpanelApi.track({ event: mixpanel.onboardingFirstChromePromptActionEvent }, 'Install')
  }

  const dismissExtensionInstallPrompt = () => {
    setShowOnboardingExtensionPrompt(false)
    mixpanelApi.track({ event: mixpanel.onboardingFirstChromePromptActionEvent }, 'Later')
  }

  const isConnected = !!session?.id
  const pathname = location.pathname

  const showFocusControls = windowWidth > layoutBreakpoints.tabletSmall
  const shrinkFocusControls = windowWidth < layoutBreakpoints.tabletLarge
  const shrinkNavLinks = windowWidth < layoutBreakpoints.desktop
  const user = session?.user

  return (
    <Container animate={controls}>
      {showOnboardingVideo && <OnboardingVideoOverlay onButtonClicked={handleContinueToCalendar} />}
      {showOnboardingExtensionPrompt && (
        <OnboardingExtensionInstallPrompt
          onAccept={handleExtensionInstallClicked}
          onDismiss={dismissExtensionInstallPrompt}
        />
      )}
      <FixedWrapper className='HomebaseHeader' ref={wrapperRef}>
        <HomeBaseFocusedLine containerItemId={focusBlockContainerId} pauseDisabled={fromOnboarding} />
        <NavBar className='HomebaseNavbar'>
          {showFocusControls && (
            <NavBarColumn>
              <FocusControls session={session} shrinked={shrinkFocusControls} />
            </NavBarColumn>
          )}
          {isConnected && (
            <>
              <NavBarColumn>
                <NavBarLink
                  active={pathname === rootRoute}
                  colorFill
                  icon='CalendarNoDate'
                  shrinked={shrinkNavLinks}
                  text={translations.header.nav.calendar}
                  to={rootRoute}
                />
                <NavBarLink
                  active={isToday(pathname) || isUncategorized(pathname) || isProject(pathname) || isTag(pathname)}
                  colorStroke
                  icon='TaskListChecked'
                  shrinked={shrinkNavLinks}
                  text={translations.header.nav.tasks}
                  to={tasksRoute}
                />
                <NavBarLink
                  active={pathname === reportsRoute}
                  colorStroke
                  icon='StopWatchOutlined'
                  shrinked={shrinkNavLinks}
                  text={translations.header.nav.timeReports}
                  to={reportsRoute}
                  onClick={() =>
                    mixpanelApi.track({ event: mixpanel.navigatedEvent }, mixpanel.clickedTimeReportsEvent)
                  }
                />
              </NavBarColumn>
              <NavBarColumn>
                {user && (
                  <UserPicture
                    backgroundColor='transparent'
                    borderRadius='50px'
                    size={32}
                    sizeDefault={24}
                    src={user.picture}
                    style={{ marginRight: 24 }}
                  />
                )}
                <NavBarSupport />
                <NavBarMenu
                  activePathname={pathname}
                  showRightBorder={false}
                  onLogout={handleLogoutClicked}
                  session={session}
                />
              </NavBarColumn>
            </>
          )}
        </NavBar>
      </FixedWrapper>
    </Container>
  )
}

const Container = styled(motion.header)`
  min-height: ${navbarHeight}px;
  min-width: 100vw;
  position: relative;
  width: 100%;
`

Container.displayName = 'Container'

const FixedWrapper = styled(motion.div)`
  border-bottom: 1px solid rgba(33, 21, 81, 0.08);
  background-color: white;
  display: flex;
  flex-flow: column;
  min-height: ${navbarHeight}px;
  position: fixed;
  width: 100%;
  z-index: ${variables.zIndex.header};
`

FixedWrapper.displayName = 'FixedWrapper'

const NavBar = styled(motion.nav)`
  border-top: 1px solid rgba(33, 21, 81, 0.08);
  display: flex;
  flex: 1;
  height: ${navbarHeight}px;
  justify-content: space-between;
  width: 100%;
`

NavBar.displayName = 'NavBar'

const NavBarColumn = styled.div`
  align-items: center;
  display: flex;
  user-select: none;
`

NavBarColumn.displayName = 'NavBarColumn'

export default withRouter(Header)
