export const type = {
  TASK: 'task',
  SPRINT: 'sprint',
  FOCUSSESSION: 'focussession',
  EVENT: 'event',
}

const item = {
  type,
}

export default item
