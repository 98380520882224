import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import enGb from 'date-fns/locale/en-GB'
import moment from 'moment'
import { utils } from 'gipsy-misc'

// we need to register a local to be able to show the week starting on monday
// see https://github.com/Hacker0x01/react-datepicker#localization
registerLocale('en-gb', enGb)

const propTypes = {
  paramName: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  selectedDay: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  pastDatesOnly: PropTypes.bool,
  endDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  filterDates: PropTypes.bool,
  filterDatesFn: PropTypes.func,
  selectsRange: PropTypes.bool,
  monthsShown: PropTypes.number,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  showPreviousMonths: PropTypes.bool,
}

class DatePicker extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick, false)
  }

  /*
   ** We use handmade shouldComponentUpdate instead of PureComponent in order to ignore the onClick function
   ** it allows us to create a new function on every render in the parent : onClick={() => { doSomething() }}
   ** without rerendering the component everytime the parent is rendered (PureComponent have this side effect )
   */
  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onChange', 'onClose']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }
      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    return false
  }

  handleDocumentClick = (event) => {
    if (ReactDOM.findDOMNode(this).contains(event.target)) {
      return
    }
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose()
    }
  }

  filterDate = (dateStr) => {
    const { pastDatesOnly } = this.props
    const today = moment()
    const date = moment(dateStr)
    if (pastDatesOnly) {
      return utils.datetime.isToday(date) || date.isBefore(today)
    } else {
      return utils.datetime.isToday(date) || date.isAfter(today)
    }
  }

  onChange = (dateData) => {
    if (Array.isArray(dateData)) {
      return this.props.onChange?.({
        paramName: this.props.paramName,
        value: dateData,
      })
    }

    const { pastDatesOnly } = this.props
    const today = moment()
    const date = moment(dateData)
    if (pastDatesOnly && !utils.datetime.isToday(date) && today.isBefore(date)) {
      return
    }
    if (!pastDatesOnly && !utils.datetime.isToday(date) && today.isAfter(date)) {
      return
    }
    if (this.props.onChange) {
      this.props.onChange({
        paramName: this.props.paramName,
        value: date.format('YYYY-MM-DD'),
      })
    }
  }

  render() {
    const {
      className,
      endDate,
      filterDates = true,
      filterDatesFn,
      maxDate,
      minDate,
      monthsShown = 1,
      selectsRange,
      showPreviousMonths,
      startDate,
      firstDayOfWeek,
    } = this.props
    const formattedDay = this.props.selectedDay ? moment(this.props.selectedDay).toDate() : null

    const configProps = {}

    if (selectsRange) {
      configProps.endDate = endDate
      configProps.startDate = startDate
      configProps.selectsRange = true
    }

    if (filterDates) {
      configProps.filterDate = filterDatesFn || this.filterDate
    }

    if (maxDate) configProps.maxDate = maxDate
    if (minDate) configProps.minDate = minDate

    return (
      <div className={'datepicker' + (className ? ` ${className}` : '')}>
        <ReactDatePicker
          inline
          monthsShown={monthsShown}
          selected={formattedDay}
          showPreviousMonths={showPreviousMonths}
          onChange={this.onChange}
          locale={firstDayOfWeek === 1 && 'en-gb'} // by default it is en-US
          {...configProps}
        />
      </div>
    )
  }
}

DatePicker.propTypes = propTypes

export default DatePicker
