export const remapData = (data, draggedItem) => {
  if (!draggedItem) return data
  const idx = data.findIndex((el) => el.id === draggedItem.id)
  if (idx > -1) {
    data[idx] = draggedItem
  }
  return data
}

export const onMouseEnter = function () {
  this.isInsideDraggableList = true
}

export const onMouseLeave = function () {
  this.isInsideDraggableList = false
}

export const onDragStart = function (data, cancelFunction) {
  cancelFunction()
  this.setState({
    dragging: true,
  })
}

/*
 ** we do that to avoid setting dragging to false when task is drop inside a draggable list :
 ** if dropped inside, it will call onDropComplete which will move the task
 ** if we set dragging to false before this action is done, it will show the list empty for a few ms which breaks the UI
 ** (const height = (list && list.length > 0) || dragging ? 'auto' : 1) height would be 1 when we want 'auto'
 */
export const onDragEnd = function () {
  if (!this.isInsideDraggableList) {
    this.setState({
      dragging: false,
    })
  }
}
