import React from 'react'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'

import { verticalBreakpoints } from 'features/layout/breakpoints'
import { getSrc } from 'utils/image'

const imageMap = {
  0: 'testimonial-1.jpg',
  1: 'testimonial-2.jpg',
  2: 'testimonial-3.jpg',
  3: 'testimonial-4.jpg',
}

interface Props {
  testimonialNumber: number
}

export default function Testimonial({ testimonialNumber }: Props) {
  const testimonialData = translations.timeBlockingExp.testimonials[testimonialNumber]

  return (
    <Container className='Testimonial'>
      <Image
        alt={testimonialData.reviewer}
        className='Testimonial__image'
        src={getSrc(`/images/time-blocking-exp/${imageMap[testimonialNumber]}`)}
      />
      <ReviewContainer className='Testimonial__review-container'>
        <Review className='Testimonial__review'>{testimonialData.review}</Review>
        <Reviewer className='Testimonial__reviewer'>{testimonialData.reviewer}</Reviewer>
      </ReviewContainer>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
    .Testimonial {
      &__image {
        height: 71px;
        width: 71px;
      }

      &__review {
        line-height: 22px;
      }
    }
  }
`

Container.displayName = 'Container'

const Image = styled.img`
  border-radius: 50%;
  flex-shrink: 0;
  height: 61px;
  margin-right: 23px;
  width: 61px;
`

Image.displayName = 'Image'

const ReviewContainer = styled.div`
  color: ${styles.colors.textDarkColor};
  display: flex;
  flex-flow: column;
  max-width: 464px;
`

ReviewContainer.displayName = 'ReviewContainer'

const Review = styled.p`
  font-size: 15px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
`

Review.displayName = 'Review'

const Reviewer = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
`

Reviewer.displayName = 'Reviewer'
