import moment from 'moment'

import { type as itemType } from 'models/item'
import colors from 'styles/colors'
import { getEndTime } from 'utils/sprint'

import { convertMinuteToNanoseconds } from './datetime'

export const getDurationNSFromCalendarSlot = ({ start, end }) => {
  const startTime = moment(start)
  const endTime = moment(end)
  const diff = endTime.diff(startTime, 'minutes')
  return convertMinuteToNanoseconds(diff)
}

export const normalizeEvent = (calendarItem) => {
  const { start, end } = calendarItem
  return {
    ...calendarItem,
    start: new Date(start),
    end: new Date(end),
    title: calendarItem.item?.title,
  }
}

export const parseItemToEvent = (item) => {
  switch (item?.type) {
    case itemType.SPRINT: {
      return {
        allDay: false,
        color: colors.sprintFill,
        end: getEndTime(item).toDate(),
        id: item.id,
        item,
        start: moment(item.pin.time).toDate(),
      }
    }

    case itemType.TASK: {
      if (item.sprintInfo || !item.when?.date || item.completed) return null

      const allDay = !item.pin?.time
      const date = item.when.date || moment().set({ hour: 9, minute: 0, second: 0, milliseconds: 0 })
      let timeStats = {}

      if (!allDay) {
        timeStats = {
          end: getEndTime(item).toDate(),
          start: moment(item.pin.time).toDate(),
        }
      } else {
        timeStats = {
          end: moment(date).endOf('day').toDate(),
          start: moment(date).startOf('day').toDate(),
        }
      }

      return {
        allDay,
        color: colors.taskFill,
        id: item.id,
        item,
        ...timeStats,
      }
    }

    default: {
      return null
    }
  }
}
