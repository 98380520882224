import styled from 'styled-components'
import { AttributeIcon } from 'LineView/components/commonUIComponents'

export const ProjectAttributeIcon = styled(AttributeIcon)`
  display: inline-flex;
  line-height: 16px;
  :first-of-type {
    margin-right: 4px;
  }
`

export const ProjectCaretIcon = styled(AttributeIcon)`
  line-height: 16px;
  margin-left: 4px;
  margin-top: 6px;
  margin-bottom: 4px;
  margin-right: 0;
`
