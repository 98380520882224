import React from 'react'
import styled from 'styled-components'
import parser from 'react-html-parser'

import { translations } from 'gipsy-misc'

import breakpoints, { verticalBreakpoints } from 'features/layout/breakpoints'
import { FeatureItem } from 'pages/login/components/FeaturesColumn'

export default function Features() {
  return (
    <Container className='Features'>
      <Title className='Features__title'>{parser(translations.timeBlockingExp.features.title)}</Title>
      <FeaturesContainer className='Features__features-container'>
        {translations.timeBlockingExp.features.items.map((item, index) => (
          <FeatureItem key={index} text={item} />
        ))}
      </FeaturesContainer>
    </Container>
  )
}

const Container = styled.section`
  background-color: white;
  display: flex;
  flex-flow: column;
  height: 26%;
  min-height: 208px;
  justify-content: center;
  padding: 0 20px;

  @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
    & h2.Features__title {
      margin-top: 22px;
    }
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    min-height: 256px;

    .Features {
      &__features-container {
        margin: 0 auto 16px;

        .FeatureItem {
          &__icon {
            margin-right: 14px;
          }

          &__text {
            font-size: 22px;
          }
        }
      }
    }

    & h2.Features__title {
      font-size: 22px;
      margin: 26px auto 33px;
    }
  }
`

Container.displayName = 'Container'

const Title = styled.h2`
  &.Features__title {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    margin: 18px auto 10px;
    text-align: center;
    width: auto;

    strong {
      font-weight: 500;
    }
  }
`

Title.displayName = 'Title'

const FeaturesContainer = styled.div`
  margin: 0 auto 14px;

  .FeatureItem {
    margin-bottom: 0;

    &__icon {
      margin-right: 12px;
    }

    &__text {
      font-size: 18px;
      line-height: 33px;
    }
  }
`

FeaturesContainer.displayName = 'FeaturesContainer'
