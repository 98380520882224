import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled, { css, keyframes } from 'styled-components'
import ReactDOM from 'react-dom'
import debounce from 'lodash/debounce'

import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import {
  CalendarItemContent,
  CalendarHeaderContent,
  MoreLink,
  moreLinkContainerClassName,
  ShowHideAllDays,
} from './components'
import { eventPropGetter } from './getters'
import Icon from 'Icon'
import Tooltip from 'Tooltip'

import { models, utils, styles, translations } from 'gipsy-misc'

const pulseAnimationDuration = 300
const navigationButtonWidth = 32
const eventMaxRows = 3
const eventRowHeight = 21
const eventMinHeight = 11

class Calendar extends PureComponent {
  state = {
    allDayEventsCountMap: {},
    draggedEvent: null,
    isAllDaySectionExpanded: false,
    calendarContainerRef: React.createRef(),
    allDayHeight: eventRowHeight * 3,
    currentEventMax: eventMaxRows,
  }
  static propTypes = {
    className: PropTypes.string,
    calendarItems: PropTypes.array.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    scrollToTime: PropTypes.instanceOf(Date).isRequired,
    firstDay: PropTypes.number.isRequired,
    onSelectSlot: PropTypes.func,
    hideTimeHeader: PropTypes.bool,
    viewMode: PropTypes.string,
    isDraggable: PropTypes.bool,
    onEventResize: PropTypes.func,
    onEventDrop: PropTypes.func,
    setCalendarRef: PropTypes.func,
    dragFromOutsideItem: PropTypes.object,
    hasDndNavigation: PropTypes.bool,
    onClickPrevButton: PropTypes.func,
    onClickNextButton: PropTypes.func,
  }

  static viewModes = {
    DAY: 'timeGridDay',
    FOUR_DAYS: 'timeGridFourDay',
    WEEK: 'timeGridWeek',
  }
  static defaultProps = {
    className: '',
    viewMode: 'timeGridDay',
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const calendarApi = this.getCalendarApi()
    if (calendarApi) {
      if (prevProps.viewMode !== this.props.viewMode) {
        calendarApi.changeView(this.props.viewMode)
      }
      if (prevProps.date !== this.props.date) {
        calendarApi.gotoDate(this.props.date)
      }
      if (prevProps.scrollToTime !== this.props.scrollToTime) {
        this.scrollToTime(this.props.scrollToTime)
      }
      if (
        prevProps.calendarItems !== this.props.calendarItems ||
        prevProps.highlightedEventId !== this.props.highlightedEventId
      ) {
        this.refreshEvents()
      }
    }
  }

  startLongPressNavigation = (direction) => {
    const calendarNode = ReactDOM.findDOMNode(this.calendar)
    calendarNode.removeEventListener('mousemove', this.checkIfHoverNavigation)
    calendarNode.addEventListener('mousemove', this.checkIfLeaveNavigation)
    if (direction === 'prev') {
      this.onClickPrevButtonWithDelay()
    } else {
      this.onClickNextButtonWithDelay()
    }
  }

  removeNavigationMouseEvents = () => {
    const calendarNode = ReactDOM.findDOMNode(this.calendar)
    calendarNode.removeEventListener('mousemove', this.checkIfHoverNavigation)
    calendarNode.removeEventListener('mousemove', this.checkIfLeaveNavigation)
  }

  onClickPrevButtonWithDelay = (delay = 1000) => {
    this.prevNavigationButtonTimeout = setTimeout(() => {
      this.setState({ animatePrevButton: true }, () => {
        this.prevButtonPulseAnimationTimeout = setTimeout(
          () => this.setState({ animatePrevButton: false }),
          pulseAnimationDuration
        )
      })
    }, delay - pulseAnimationDuration)
    this.prevNavigationButtonTimeout = setTimeout(() => {
      this.props.onClickPrevButton()
      this.onClickPrevButtonWithDelay(500)
    }, delay)
    this.setState({
      isPrevNavigationButtonHovered: true,
    })
  }

  onClickNextButtonWithDelay = (delay = 1000) => {
    this.nextButtonPulseAnimationTimeout = setTimeout(() => {
      this.setState({ animateNextButton: true }, () => {
        this.nextButtonPulseAnimationTimeout = setTimeout(
          () => this.setState({ animateNextButton: false }),
          pulseAnimationDuration
        )
      })
    }, delay - pulseAnimationDuration)

    this.nextNavigationButtonTimeout = setTimeout(() => {
      this.props.onClickNextButton()
      this.onClickNextButtonWithDelay(500)
    }, delay)
    this.setState({
      isNextNavigationButtonHovered: true,
    })
  }

  isOverPrevNavigationButton = (element) =>
    element === this.prevNavigationButton || this.prevNavigationButton.contains(element)
  isOverNextNavigationButton = (element) =>
    element === this.nextNavigationButton || this.nextNavigationButton.contains(element)

  checkIfLeaveNavigation = debounce(({ x, y }) => {
    let draggingElement = document.elementFromPoint(x, y)
    if (!draggingElement) return this.clearNavigationTimeouts() // moved out of the screen

    if (this.isOverPrevNavigationButton(draggingElement) || this.isOverNextNavigationButton(draggingElement)) {
      return
    }
    if (draggingElement.closest('.fc-event') || draggingElement.classList.contains('.fc-event')) {
      let parentNode = draggingElement.closest('.fc-event') || draggingElement

      parentNode.style.visibility = 'hidden'
      const element = document.elementFromPoint(x, y)
      parentNode.style.visibility = 'visible'

      if (this.isOverPrevNavigationButton(element) || this.isOverNextNavigationButton(element)) {
        return
      }
    }

    this.clearNavigationTimeouts()
    this.removeNavigationMouseEvents()
    return this.initDragListener()
  }, 1)

  checkIfHoverNavigation = debounce(({ x, y }) => {
    let draggingElement = document.elementFromPoint(x, y)
    if (!draggingElement) return // moved out of the screen

    if (this.isOverPrevNavigationButton(draggingElement)) {
      return this.startLongPressNavigation('prev')
    } else if (this.isOverNextNavigationButton(draggingElement)) {
      return this.startLongPressNavigation('next')
    }

    if (draggingElement.closest('.fc-event') || draggingElement.classList.contains('.fc-event')) {
      let parentNode = draggingElement.closest('.fc-event') || draggingElement

      parentNode.style.visibility = 'hidden'
      const element = document.elementFromPoint(x, y)
      parentNode.style.visibility = 'visible'

      if (this.isOverPrevNavigationButton(element)) {
        return this.startLongPressNavigation('prev')
      } else if (this.isOverNextNavigationButton(element)) {
        return this.startLongPressNavigation('next')
      }
    }
  }, 20)

  componentDidMount() {
    this.refreshEvents()
    this.scrollToTime(this.props.scrollToTime)
  }

  scrollToTime = (scrollToTime) => {
    const calendarApi = this.getCalendarApi()
    if (calendarApi) {
      calendarApi.scrollToTime(utils.datetime.convertToFullCallendarDuration(scrollToTime))
    }
  }

  refreshEvents = () => {
    const calendarApi = this.getCalendarApi()
    if (calendarApi) {
      calendarApi.removeAllEvents()
      calendarApi.addEventSource({
        events: this.remapEventProps(this.props.calendarItems || []) || [],
        id: 'reduxStore',
      })
    }
  }

  getCalendarApi = () => this.calendar && this.calendar.getApi && this.calendar.getApi()

  remapEventProps = (events) => {
    const { highlightedEventId } = this.props
    const allDayEventsCountMap = { maxEventsOnDay: 0 }
    let currentEventMax = 0

    const addDayToCount = (day) => {
      if (!allDayEventsCountMap[day]) {
        allDayEventsCountMap[day] = 1

        if (!currentEventMax) {
          currentEventMax = 1
        }
      } else {
        allDayEventsCountMap[day]++

        if (allDayEventsCountMap[day] > currentEventMax) {
          currentEventMax = allDayEventsCountMap[day]
        }
      }
    }

    let mappedEvents = events.map((event) => {
      if (event.id === highlightedEventId) {
        event = {
          ...event,
          isHighlighted: true,
        }
      }
      event.isPast = moment(event.end) < moment()
      const customProps = eventPropGetter(event)
      const { backgroundColor, borderColor, color, fontWeight } = customProps.style
      const isEditableItem = [models.item.type.SPRINT, models.item.type.TASK, models.item.type.FOCUSSESSION].includes(
        event.item.type
      )
      event.isLocalEvent = isEditableItem && this.props.isDraggable

      if (event.allDay) {
        const start = moment(event.start).startOf('day')
        const end = moment(event.end).endOf('day')

        addDayToCount(start.format('YYYY-MM-DD'))

        if (!start.isSame(end, 'day')) {
          const diffInDays = end.diff(start, 'days')

          if (diffInDays >= 2) {
            let currentDiff = diffInDays

            while (currentDiff > 1) {
              start.add(1, 'day')
              addDayToCount(start.format('YYYY-MM-DD'))
              currentDiff = end.diff(start, 'days')
            }
          }
        }
      }

      return {
        ...event,
        editable: (isEditableItem && this.props.isDraggable) || event.isHighlighted,
        backgroundColor,
        borderColor,
        textColor: color,
        style: {
          fontWeight,
          backgroundColor,
        },
        className: customProps.className,
      }
    })

    this.setState({
      allDayEventsCountMap,
      allDayHeight: currentEventMax * eventRowHeight,
      currentEventMax,
    })
    return mappedEvents
  }

  onSelectSlot = (info) => {
    const calendarNode = ReactDOM.findDOMNode(this.calendar)
    const highlightedSlot = calendarNode.querySelector('.fc-highlight')
    const bounds = highlightedSlot?.getBoundingClientRect()
    let calendarApi = info.view.calendar
    calendarApi.unselect() // clear date selection
    const { jsEvent } = info
    if (jsEvent?.srcElement?.className?.includes(moreLinkContainerClassName)) {
      return
    }
    this.props.onSelectSlot({ ...info, bounds })
  }

  onEventDrop = (info) => {
    this.props.onEventDrop && this.props.onEventDrop(info.event, false, info)
  }

  onEventReceive = (info) => {
    this.props.onEventDrop(info.event, true, info)
  }

  onEventDragStop = (info) => {
    const { event, jsEvent } = info
    const calendarNode = ReactDOM.findDOMNode(this.calendar)
    if (this.props.hasDndNavigation) {
      this.removeNavigationMouseEvents()
      this.clearNavigationTimeouts()
    }
    const elementFromPoint = document.elementFromPoint(jsEvent.pageX, jsEvent.pageY)
    const wasDroppedInside = calendarNode.contains(elementFromPoint)
    if (!wasDroppedInside) {
      if (event.extendedProps.item.type === 'task') {
        this.props.onDragOut && this.props.onDragOut(event.toPlainObject())
        event.remove()
      }
    }
  }

  clearNavigationTimeouts = () => {
    if (this.prevButtonPulseAnimationTimeout) {
      clearTimeout(this.prevButtonPulseAnimationTimeout)
      this.prevButtonPulseAnimationTimeout = null
    }
    if (this.nextButtonPulseAnimationTimeout) {
      clearTimeout(this.nextButtonPulseAnimationTimeout)
      this.nextButtonPulseAnimationTimeout = null
    }

    if (this.prevNavigationButtonTimeout) {
      clearTimeout(this.prevNavigationButtonTimeout)
      this.prevNavigationButtonTimeout = null
      this.setState({
        isPrevNavigationButtonHovered: false,
      })
    }

    if (this.nextNavigationButtonTimeout) {
      clearTimeout(this.nextNavigationButtonTimeout)
      this.nextNavigationButtonTimeout = null
      this.setState({
        isNextNavigationButtonHovered: false,
      })
    }
  }

  onEventClick = (info) => {
    const { event, jsEvent } = info
    this.props.onClickEvent && this.props.onClickEvent(event.toPlainObject(), jsEvent)
  }

  initDragListener = () => {
    const calendarNode = ReactDOM.findDOMNode(this.calendar)
    calendarNode.addEventListener('mousemove', this.checkIfHoverNavigation)
  }

  updateAllDaySection = (isAllDaySectionExpanded) => {
    this.setState({ ...this.state, isAllDaySectionExpanded })
  }

  onMoreLinkClicked = () => {
    this.updateAllDaySection(true)
  }

  render() {
    const now = moment()

    const commonProps = {
      ref: (ref) => {
        this.calendar = ref
        this.props.setCalendarRef && this.props.setCalendarRef(ref)
      },
      selectable: !!this.props.onSelectSlot && !this.props.disableSelection,
      initialDate: this.props.date,
      headerToolbar: {
        center: '',
        end: '',
        start: '',
      },
      slotDuration: '00:15:00',
      slotLabelInterval: '01:00',
      eventContent: (props) => <CalendarItemContent {...props} />,
      nowIndicator: true,
      dayHeaderContent: (args) => (
        <CalendarHeaderContent {...args} now={now} onClick={this.props.onPlanMyDayTriggerClicked} />
      ),
      eventDragStart: (info) => {
        if (this.props.hasDndNavigation) {
          this.initDragListener()
        }
      },
      eventMinHeight,
      eventDragStop: this.onEventDragStop,
      eventOrder: [
        (a, b) => {
          if (a.allDay && b.allDay) {
            switch (true) {
              case a.item?.type === 'event' && a.item?.type === b.item?.type: {
                const aDiff = moment(a.item?.endTime?.date).diff(moment(a.item?.startTime?.date), 'days')
                const bDiff = moment(b.item?.endTime?.date).diff(moment(b.item?.startTime?.date), 'days')
                return aDiff > bDiff ? -1 : 1
              }

              case a.item?.type === 'event' || b.item?.type === 'event': {
                return a.item?.type === 'event' ? -1 : 1
              }

              default: {
                return a.item?.when?.rank > b.item?.when?.rank ? 1 : -1
              }
            }
          }

          return 0
        },
        'start',
        '-duration',
        'allDay',
        'title',
      ],
      eventResize: (info) => this.props.onEventResize(info.event.toPlainObject(), info),
      eventDrop: this.onEventDrop,
      eventReceive: this.onEventReceive,
      select: this.onSelectSlot,
      plugins: [timeGridPlugin, interactionPlugin],
      initialView: this.props.viewMode,
      eventClick: this.onEventClick,
      firstDay: this.props.firstDay,
      defaultTimedEventDuration: '00:30:00',
      scrollTimeReset: !!this.props.scrollTimeReset,
      views: {
        timeGridFourDay: {
          type: 'timeGrid',
          duration: { days: 4 },
        },
      },
    }

    if (this.props.contentHeight) {
      commonProps.contentHeight = this.props.contentHeight
    }

    if (this.props.disablePlanMyDay) {
      commonProps.dayHeaderContent = (args) => moment(args.date).format('ddd, D')
    }

    const {
      isPrevNavigationButtonHovered,
      animatePrevButton,
      isNextNavigationButtonHovered,
      animateNextButton,
    } = this.state

    return (
      <Container
        isExpanded={this.state.isAllDaySectionExpanded}
        currentEventMax={this.state.currentEventMax}
        allDayHeight={this.state.allDayHeight}
        hasDndNavigation={this.props.hasDndNavigation}
        ref={this.state.calendarContainerRef}
        hideTimeHeader={this.props.hideTimeHeader}
        className={'gipsy-calendar ' + this.props.className}>
        {this.props.hasDndNavigation && (
          <DndNavigation
            hovered={isPrevNavigationButtonHovered}
            animate={animatePrevButton}
            onClick={this.props.onClickPrevButton}
            ref={(ref) => (this.prevNavigationButton = ref)}>
            <Tooltip
              text={translations.calendar.navigationButton.tooltip.description}
              hs={navigationButtonWidth}
              vs={this.prevNavigationButton && this.prevNavigationButton.getBoundingClientRect().height / 2}
              position='top right'
            />
            <Icon
              icon='SingleChevronLeft'
              size={10}
              fill={isPrevNavigationButtonHovered ? styles.colors.primaryColor : styles.colors.darkGrey}
            />
          </DndNavigation>
        )}

        <FullCalendar
          {...commonProps}
          droppable={this.props.isDraggable}
          eventDurationEditable={this.props.isDraggable}
          allDaySlot={true}
          dayCellContent={(data) => (
            <MoreLink
              eventsCountForDay={this.state.allDayEventsCountMap[moment(data.date).format('YYYY-MM-DD')]}
              hide={this.state.isAllDaySectionExpanded}
              maxEvents={eventMaxRows}
              onClick={this.onMoreLinkClicked}
              rowHeight={eventRowHeight}
            />
          )}
          allDayContent={() => {
            return (
              <ShowHideAllDays
                calendarRef={this.state.calendarContainerRef}
                date={this.props.date}
                eventsCount={this.state.allDayEventsCountMap}
                isExpanded={this.state.isAllDaySectionExpanded}
                maxEvents={eventMaxRows}
                onChange={this.updateAllDaySection}
              />
            )
          }}
        />
        {this.props.hasDndNavigation && (
          <DndNavigation
            right
            hovered={isNextNavigationButtonHovered}
            animate={animateNextButton}
            onClick={this.props.onClickNextButton}
            ref={(ref) => (this.nextNavigationButton = ref)}>
            <Tooltip
              text={translations.calendar.navigationButton.tooltip.description}
              hs={navigationButtonWidth}
              vs={this.nextNavigationButton && this.nextNavigationButton.getBoundingClientRect().height / 2}
              position='top left'
            />
            <Icon
              icon='SingleChevronRight'
              size={10}
              fill={isNextNavigationButtonHovered ? styles.colors.primaryColor : styles.colors.darkGrey}
            />
          </DndNavigation>
        )}
      </Container>
    )
  }
}

export default Calendar

const pulse = keyframes`
  50% {
    width: 35px;
    height: calc(100% - 88px);
  }
`
const computeEventsVisibility = ({ currentEventMax, isExpanded }) => {
  if (isExpanded) {
    return css`
      opacity: 1;
      pointer-events: all;
    `
  }

  const expression = currentEventMax <= eventMaxRows ? `n + ${eventMaxRows + 1}` : `n + ${eventMaxRows}`
  return css`
    .fc-daygrid-event-harness:nth-child(${expression}) {
      opacity: 0;
      pointer-events: none;

      transition: opacity 300ms ease;

      ${({ isExpanded }) =>
        isExpanded &&
        css`
          opacity: 1;
          pointer-events: all;
        `}
    }
  `
}

const Container = styled.div`
  &.gipsy-calendar {
    background-color: white;
  }

  ${(props) =>
    props.hasDndNavigation &&
    css`
      width: calc(100% - 38px) !important;
    `};
  display: flex;
  height: 100%;

  .fc-theme-standard td {
    border-color: ${styles.colors.middleGrey};
  }

  a.fc-col-header-cell-cushion {
    width: 100%;
  }

  .fc {
    flex-grow: 1;
    height: 100%;

    /* Hide scrollbar for Chrome, Safari and Opera */
    .fc-scroller::-webkit-scrollbar {
      display: none;
      width: 0;
      -ms-overflow-style: none; /* IE and Edge */
    }
    .fc-scroller {
      scrollbar-width: none; /* Firefox */
    }

    .fc-daygrid-event-harness {
      z-index: 2;
    }

    .fc-col-header,
    .fc-daygrid-body,
    .fc-daygrid-body .fc-scrollgrid-sync-table,
    .fc-timegrid-body .fc-timegrid-slots > table,
    .fc-timegrid-body .fc-timegrid-cols > table {
      width: 100% !important;
    }

    .fc-daygrid-day-events {
      max-height: 63px;
      transition: max-height 300ms ease;

      ${({ isExpanded, allDayHeight }) =>
        isExpanded &&
        css`
          max-height: ${allDayHeight}px;
        `}
    }

    ${computeEventsVisibility}

    .fc-scrollgrid-section:not(.fc-scrollgrid-section-liquid) {
      .fc-scroller {
        overflow: hidden hidden !important;

        .fc-daygrid-body-natural {
          overflow-y: hidden;
        }
      }
    }

    .fc-toolbar.fc-header-toolbar {
      margin-bottom: 0;
    }

    .fc-daygrid-body .fc-scrollgrid-sync-table {
      border-top: 1px solid ${styles.colors.middleGrey};
    }

    .fc-timegrid-event-harness {
      min-height: ${eventMinHeight}px;
    }

    .fc-timegrid-slot {
      line-height: 10px;
      height: ${eventMinHeight}px;
    }

    .fc-timegrid-slot.fc-timegrid-slot-label {
      width: 42px;
    }

    .fc-timegrid-event {
      border: 0;
      border-radius: 5px;
      bottom: 0;
      cursor: pointer;
      margin-bottom: 0;
    }

    .fc-timegrid-slots > table > colgroup > col:first-child,
    .fc-timegrid-cols > table > colgroup > col:first-child,
    .fc-daygrid-body.fc-daygrid-body-unbalanced.fc-daygrid-body-natural > table > colgroup > col:first-child,
    .fc-timeGridWeek-view .fc-scrollgrid-section-header .fc-col-header > colgroup > col:first-child,
    .fc-timeGridFourDay-view .fc-scrollgrid-section-header .fc-col-header > colgroup > col:first-child {
      width: 42px !important;
    }

    .fc-timegrid-axis-cushion {
      flex-shrink: 1;
      font-size: 0;
    }

    .fc-timegrid-slot-label-cushion {
      white-space: nowrap;
      color: ${styles.colors.darkGrey};
      font-size: ${styles.fonts.fontSizeXXSmall};
      padding: 0;
      text-align: left;
      color: ${styles.colors.textMediumDarkColor};
    }

    .fc-daygrid-day-number {
      padding: 0px;
      width: 100%;
    }

    .fc-timegrid-col.fc-day-today,
    .fc-daygrid-day.fc-day-today {
      background-color: transparent;
    }

    .fc-timeGridDay-view .fc-col-header {
      display: none;
    }

    .fc-timegrid-divider {
      padding: 0;
      border: none;
      border-top: solid 1px ${styles.colors.greyBorderColor};
      background: none;
    }

    .fc-timegrid.fc-timeGridDay-view > table {
      border: none;
    }

    .fc-timegrid-body,
    .fc-timegrid-body .fc-timegrid-slots,
    .fc-timegrid-body .fc-timegrid-cols {
      width: 100% !important;
    }

    .fc-scrollgrid-section.fc-scrollgrid-section-body > td {
      border: none;
    }

    .fc-timegrid-now-indicator-container {
      overflow: visible;
    }

    .fc-timegrid-now-indicator-arrow {
      display: none;
    }

    .fc-timegrid-now-indicator-line {
      border: none;
      border-bottom: 2px solid ${styles.colors.primaryColor};

      :before {
        background-color: ${styles.colors.primaryColor};
        border-radius: 50%;
        content: '';
        height: 8px;
        left: -4px;
        position: absolute;
        top: -3px;
        width: 8px;
      }
    }

    .fc-timegrid-slot-minor {
      border-top-style: none;
    }

    .fc-timegrid-slot-label-frame {
      text-align: left;
      padding-left: 8px;
    }

    .fc-timegrid-slots > table > tbody > tr {
      height: ${eventMinHeight}px;
    }

    .fc-timeGridDay-view {
      .fc-timegrid-col:first-child,
      .fc-timegrid-axis,
      .fc-timegrid-slot.fc-timegrid-slot-label {
        border-right: none;
      }
    }

    .fc-timeGridWeek-view,
    .fc-timeGridFourDay-view {
      --fc-border-color: ${styles.colors.greyBorderColor};
      & > table {
        border: none;
        background: white;
        width: calc(100% + 0.5px); /* Hack for 'display:table' widths */
      }
      .fc-scrollgrid-section-header {
        & > td {
          border: none;
        }
        .fc-col-header {
          height: 30px;
          background: white;
          .fc-timegrid-axis {
            border: none;
          }
          th {
            border: none;
          }
        }
      }

      .fc-col-header-cell.fc-day {
        height: 100%;
        padding: 0 0 0 8px;
        text-align: left;
        vertical-align: middle;
      }

      .fc-col-header-cell-cushion {
        font-weight: 500;
        font-size: ${styles.fonts.fontSize};
        color: ${styles.colors.textMediumDarkColor};
        padding: 2px 0;
      }

      .fc-day-today {
        &.fc-col-header-cell {
          & > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
        .fc-col-header-cell-cushion {
          //color: ${styles.colors.primaryColor};
        }
      }
      .fc-scrollgrid-section-body {
        background: white;
        &:first-child {
          // all-day row
          .fc-scroller-harness {
            border-radius: 8px 8px 0 0;
          }
        }
        .fc-scroller-harness {
          border-radius: 0 0 8px 8px;
          background: white;
          .fc-timegrid-cols {
            .fc-timegrid-col:not(:first-child) {
              border-right: 1px solid ${styles.colors.greyBorderColor};
            }
          }
          .fc-timegrid-slots {
            border-bottom: 4px solid white;
          }
        }
      }

      .fc-col-header,
      .fc-daygrid-body,
      .fc-daygrid-body .fc-scrollgrid-sync-table,
      .fc-timegrid-body .fc-timegrid-slots > table,
      .fc-timegrid-body .fc-timegrid-cols > table {
        width: 100% !important;
      }

      .fc-timegrid-col-events {
        margin: 0 2px;
      }
    }

    .fc-timegrid-col:nth-child(2),
    .fc-timegrid-slot.fc-timegrid-slot-lane,
    .fc-daygrid-day {
      border-left: none;
      .fc-daygrid-day-events {
        min-height: 0;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .fc-daygrid-event {
      min-height: 20px;
    }

    .fc-event-main {
      padding: 0;
      cursor: pointer;
    }

    .fc-highlight {
      background: ${styles.colors.primaryColor}20;
      border-radius: 5px;
      border: none !important;
      color: ${styles.colors.textDarkColor};
    }

    .fc-v-event .fc-event-resizer-end {
      cursor: ns-resize;
    }

    .highlighted {
      filter: drop-shadow(2px 18px 21px rgba(33, 21, 81, 0.41));
      border-radius: 5px;
    }

    .fc-timegrid-col-misc {
      .${moreLinkContainerClassName} {
        display: none;
      }
    }
  }

  .fc-popover {
    pointer-events: none;
    visibility: hidden;
  }
`

const DndNavigation = styled.div`
  width: ${navigationButtonWidth}px;
  height: calc(100% - 93px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  border: 1px solid transparent;

  ${({ hovered }) =>
    hovered &&
    css`
      cursor: grab;
      box-shadow: inset ${({ right }) => (right ? 7 : -7)}px 0px 17px rgba(25, 22, 143, 0.25);
      border-color: ${styles.colors.lightVioletColor};
      background-color: ${styles.colors.veryLightGrey};
    `}

  ${({ right }) =>
    right
      ? css`
          border-right: none;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          right: 0;
        `
      : css`
          border-left: none;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          left: 0;
        `};
  ${({ animate }) =>
    animate &&
    css`
      animation: ${pulse} ${pulseAnimationDuration}ms ease-in-out;
    `}
`
