import moment from 'moment'

import { DailyRecurrence } from 'utils/recurrency/types'

export const getDailyFirstOcurrence = (creationDate: string): string => creationDate

export const getDailySchedule = (start: string, details: DailyRecurrence, maxIterations: number): string[] => {
  const options = details.dailyOptions
  const result: string[] = Array(maxIterations).fill('')
  const deltaFirstDate = moment(start).diff(moment(details.firstOccurrence), 'days')
  let delta = deltaFirstDate + ((options.every - (deltaFirstDate % options.every)) % options.every)

  result.forEach((_, index) => {
    result[index] = moment(details.firstOccurrence).add(delta, 'days').format('YYYY-MM-DD')
    delta += options.every
  })

  return result
}

export const getDailyScheduleUntil = (start: string, details: DailyRecurrence, until: string): string[] => {
  const options = details.dailyOptions
  const result: string[] = []
  const untilDate = moment(until)
  const deltaFirstDate = moment(start).diff(moment(details.firstOccurrence), 'days')
  let delta = deltaFirstDate + ((options.every - (deltaFirstDate % options.every)) % options.every)
  let date = moment(details.firstOccurrence).add(delta, 'days')

  while (date <= untilDate) {
    result.push(date.format('YYYY-MM-DD'))
    delta += options.every
    date = moment(details.firstOccurrence).add(delta, 'days')
  }

  return result
}
