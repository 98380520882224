import React, { PureComponent } from 'react'

import { translations } from 'gipsy-misc'

import RecurringItemsDropdown from 'ListDropdowns/RecurringItems'

import { DropdownSelector } from './DropdownSelector'
import RecurrenceDropdownSelector from './RecurrenceDropdownSelector'

export default class SprintSelect extends PureComponent {
  onSelect = (sprint) => {
    const { onChange, value } = this.props
    if (!value?.id || value.id !== sprint.id) {
      onChange(sprint)
    } else {
      onChange({ id: '' })
    }
  }

  render() {
    const {
      className,
      value,
      activeSprintsMap,
      setInnerRef,
      onClickCreateButton,
      hideCreateButton,
      inlineCreateButton,
      maxWidth,
      style,
      width,
    } = this.props

    return (
      <RecurringItemsDropdown
        className={className}
        createLabel={translations.sprint.createNew}
        dropdownSelector={DropdownSelector}
        emptyLabel={translations.sprint.noSprints}
        groupDropdownSelector={RecurrenceDropdownSelector}
        inlineCreateButton={inlineCreateButton}
        itemsMap={activeSprintsMap}
        maxWidth={maxWidth}
        onCreate={onClickCreateButton}
        onSelect={this.onSelect}
        selectedOption={value}
        ref={setInnerRef}
        style={style}
        width={width || 420}
        withCreate={!hideCreateButton}
      />
    )
  }
}
