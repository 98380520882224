import React, { useState, useRef, useCallback } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Icon from 'Icon'
import { ModifyComponent, LinePopupItemContent, LinePopupItemContainer } from 'LineComponents/LinePopupWrapper'
import { focussession as focussessionApi } from 'gipsy-api'
import { utils, styles, translations } from 'gipsy-misc'

import { breakpoints } from 'styles/media'
import PopupInputs, { EditDateContainer, InputValue, StyledDuration } from './PopupInputs'

export const PopupItemContainer = ({
  item,
  maxWidth,
  onClickFocusSession,
  onUpdateFocusSession,
  onDeleteFocusSession,
  clearHighLightedEvent,
  showSnackbar,
  inChromeExtension,
  firstDayOfWeek,
}) => {
  const deleteFromEditFocusSession = useCallback(
    (focusSession) => {
      onDeleteFocusSession({
        confirmLabel: translations.focussession.deleteOnEditConfirmation.consent,
        cancelLabel: translations.focussession.deleteOnEditConfirmation.cancel,
        prompt: translations.focussession.deleteOnEditConfirmation.prompt,
        focusSession,
      })
    },
    [onDeleteFocusSession]
  )

  const onClickSave = useCallback(
    async (updatedFocusSession) => {
      if (moment(updatedFocusSession.startTime).isAfter(new Date())) {
        return (
          showSnackbar &&
          showSnackbar({
            message: translations.focussession.error.futureStartTime,
            status: 'error',
            showLogo: false,
            showClose: false,
          })
        )
      }

      if (moment(updatedFocusSession.endTime).isAfter(new Date())) {
        return (
          showSnackbar &&
          showSnackbar({
            message: translations.focussession.error.futureStartTime,
            status: 'error',
            showLogo: false,
            showClose: false,
          })
        )
      }

      clearHighLightedEvent && clearHighLightedEvent()

      if (!isValidElapsedTime(updatedFocusSession.startTime, updatedFocusSession.endTime)) {
        deleteFromEditFocusSession(updatedFocusSession)
      } else {
        try {
          onUpdateFocusSession && onUpdateFocusSession(updatedFocusSession)
          if (!inChromeExtension) {
            await focussessionApi.update(updatedFocusSession)
          }
        } catch (e) {
          console.error('fs update failed')
          onUpdateFocusSession && onUpdateFocusSession(item)
        }
      }
    },
    [showSnackbar, deleteFromEditFocusSession, onUpdateFocusSession, inChromeExtension, item, clearHighLightedEvent]
  )

  const isValidElapsedTime = (startTime, endTime) => {
    const spentTimeNS = utils.focussession.getSpentTimeInNanoSeconds({ endTime, startTime })
    return spentTimeNS > utils.focussession.minDurationFSNS
  }
  const shrinked = maxWidth && maxWidth < breakpoints.phone

  return (
    <PopupItem
      item={item}
      onClickSave={onClickSave}
      onClickFocusSession={onClickFocusSession}
      onDeleteFocusSession={onDeleteFocusSession}
      shrinked={shrinked}
      firstDayOfWeek={firstDayOfWeek}
    />
  )
}

export const PopupItem = ({
  item,
  onClickFocusSession,
  onDeleteFocusSession,
  onClickSave,
  shrinked,
  removeDeleteButton = false,
  firstDayOfWeek,
}) => {
  const itemRef = useRef(null)
  const [isEditing, setEditing] = useState(false)

  const save = useCallback(
    async (updatedFocusSession) => {
      onClickSave(updatedFocusSession)
      setEditing(false)
    },
    [onClickSave]
  )

  const onClickItem = () => {
    onClickFocusSession && onClickFocusSession(item)
  }

  const toggleEditing = () => {
    setEditing((isEditing) => !isEditing)
  }

  return (
    <StyledLinePopupItemContainer
      disableHover
      textColor={styles.colors.darkGrey}
      iconColor={styles.colors.darkGrey}
      ref={itemRef}
      onClick={onClickItem}
      isClickable={!!onClickFocusSession}>
      <LinePopupItemContent lateralPadding={13}>
        <PopupInputs
          isEditing={isEditing}
          item={item}
          onOkClicked={save}
          onOutsideClicked={save}
          parentRef={itemRef}
          shrinked={shrinked}
          firstDayOfWeek={firstDayOfWeek}>
          {({ Date, Duration, OkButton, Time }) => (
            <>
              <StyledIcon icon={'Clock'} size={12} />
              <Attribute width={shrinked ? undefined : 290}>
                {shrinked ? '' : translations.focussession.popup.startedLabel}
                {Date}
                {Time}
              </Attribute>
              <Attribute>
                {shrinked ? '' : translations.focussession.popup.lastedLabel}
                {Duration}
              </Attribute>
              {isEditing ? (
                <>{OkButton}</>
              ) : (
                <ModifyComponent
                  disableDelete={removeDeleteButton}
                  iconSize={14}
                  iconHoverColor={styles.colors.primaryColor}
                  onClickDelete={() => onDeleteFocusSession({ focusSession: item })}
                  onClickToggleEdit={toggleEditing}
                />
              )}
            </>
          )}
        </PopupInputs>
      </LinePopupItemContent>
    </StyledLinePopupItemContainer>
  )
}

const StyledLinePopupItemContainer = styled(LinePopupItemContainer)`
  cursor: ${(props) => props.isClickable && 'pointer'};
`

const Attribute = styled.div`
  margin-right: 6px;
  & > ${InputValue}, & > ${StyledDuration}, & > ${EditDateContainer} {
    margin-left: 5px;
  }
  & > ${InputValue} {
    &:first-child {
      margin-left: 14px;
      margin-right: 9px;
    }
  }

  width: ${(props) => props.width}px;
  display: flex;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: 9px;
`
