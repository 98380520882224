import React from 'react'

import { getSrc } from 'utils/image'
import { IntegrationContainer } from 'features/integrations/components'
import { BlockHeader } from 'pages/integrations/common'

import { translations } from 'gipsy-misc'

const ChromeExt = ({ onClick, isChromeExtInstalled }) => {
  return (
    <IntegrationContainer>
      <BlockHeader
        iconSrc={getSrc('/images/chrome.png')}
        title={translations.integrations.apps.chromeext.title}
        description={translations.integrations.apps.chromeext.description}
        isInstalled={isChromeExtInstalled}
        installLabel={translations.integrations.actionButtons.install}
        onClickInstall={onClick}
      />
    </IntegrationContainer>
  )
}
export default React.memo(ChromeExt)
