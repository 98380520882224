import { createAction } from 'redux-actions'
import findLastIndex from 'lodash/findLastIndex'

import { types } from 'store/shortcuts/types'

import { mixpanel, utils } from 'gipsy-misc'
import { mixpanel as mixpanelApi } from 'gipsy-api'

export const _push = createAction(types.PUSH_SHORTCUT_GROUP)
export const _pop = createAction(types.POP_SHORTCUT_GROUP)
export const _clear = createAction(types.CLEAR_SHORTCUT_GROUPS)

const getKeydownEventCallback = (shortcuts) => {
  const shortcutsMap = new Map()
  shortcuts.forEach((shortcut) => shortcutsMap.set(utils.shortcut.getShortcutKey(shortcut), shortcut))
  return (e) => {
    e.persist && e.persist()
    const eventShortcutKey = utils.shortcut.getShortcutKey(e)
    if (shortcutsMap.has(eventShortcutKey)) {
      const shortcut = shortcutsMap.get(eventShortcutKey)
      if (shortcut.callback) {
        e.preventDefault()
        shortcut.callback(e)
        mixpanelApi.track(
          { event: mixpanel.usedShortcut },
          { key: utils.shortcut.getShortcutKey(shortcut), action: shortcut.label }
        )
      }
    }
  }
}

export const pushShortcutsGroup = (shortcutGroup, componentName) => async (dispatch, getState) => {
  const { shortcutsGroupStack } = getState().shortcuts
  if (shortcutsGroupStack.length > 0) {
    const activeShortcutsGroup = shortcutsGroupStack[shortcutsGroupStack.length - 1]
    document.removeEventListener('keydown', activeShortcutsGroup.keydownEventCallback)
  }

  const keydownEventCallback = getKeydownEventCallback(shortcutGroup)
  document.addEventListener('keydown', keydownEventCallback)
  dispatch(_push({ shortcutGroup: { items: shortcutGroup, keydownEventCallback, componentName } }))
}

export const popShortcutsGroup = (componentName) => (dispatch, getState) => {
  const { shortcutsGroupStack } = getState().shortcuts
  if (shortcutsGroupStack.length > 0) {
    const lastIndex = findLastIndex(
      shortcutsGroupStack,
      (shortcutGroup) => shortcutGroup.componentName === componentName
    )
    if (shortcutsGroupStack[lastIndex]) {
      document.removeEventListener('keydown', shortcutsGroupStack[lastIndex].keydownEventCallback)
      if (shortcutsGroupStack.length > 1 && lastIndex === shortcutsGroupStack.length - 1) {
        const prevShortcutsGroup = shortcutsGroupStack[shortcutsGroupStack.length - 2]
        document.addEventListener('keydown', prevShortcutsGroup.keydownEventCallback)
      }
      dispatch(_pop(lastIndex))
    }
  }
}

export const clearShortcutGroupsStack = () => async (dispatch, getState) => {
  const { shortcutsGroupStack } = getState().shortcuts
  shortcutsGroupStack.forEach((shortcutsGroup) => {
    document.removeEventListener('keydown', shortcutsGroup.keydownEventCallback)
  })
  dispatch(_clear())
}
