export const types = {
  FETCH_EVENTS: 'FETCH_EVENTS',
  SET_EVENTS: 'SET_EVENTS',
  ADD_EVENT: 'ADD_EVENT',
  ADD_EVENTS: 'ADD_EVENTS',
  UPDATE_EVENT: 'UPDATE_EVENT',
  REMOVE_EVENT: 'REMOVE_EVENT',
  REMOVE_EVENTS: 'REMOVE_EVENTS',
  SET_EVENTS_LOADED: 'SET_EVENTS_LOADED',
  SET_EVENTS_LOCKED: 'SET_EVENTS_LOCKED',
  SET_EVENTS_PENDING_FETCH: 'SET_EVENTS_PENDING_FETCH',
  SET_CALENDARDATE: 'SET_CALENDARDATE',
  SET_SCROLLTOTIME: 'SET_SCROLLTOTIME',
  SET_HIGHLIGHTEDEVENTID: 'SET_HIGHLIGHTEDEVENTID',
  SET_CALENDAR_EXPANDED: 'SET_CALENDAR_EXPANDED',
}
