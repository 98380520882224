import { types } from 'store/mobileNavbar/types'

const initialState = () => ({
  active: false,
})

const popupReducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.OPEN_MOBILENAVBAR:
      return {
        active: true,
      }

    case types.CLOSE_MOBILENAVBAR:
      return {
        active: false,
      }

    default:
      break
  }
  return state
}

export default popupReducer
