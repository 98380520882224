import React from 'react'
import styled from 'styled-components'

import { styles, translations, utils } from 'gipsy-misc'
import { Icon } from 'gipsy-ui'

import Settings from './Settings'

export default function CalendarNav({
  calendarDate,
  calendarTitle,
  dayView,
  fullWeekContainerProps = {},
  onClickCreate,
  onClickLeftArrow,
  onClickRightArrow,
  onClickToday,
}) {
  if (dayView) {
    return (
      <NavigationContainer spaceBetween>
        <TodayViewButtonContainer>
          <TodayNavigationButtonContainer onClick={onClickToday}>
            {translations.general.today}
          </TodayNavigationButtonContainer>
          <ArrowContainer onClick={onClickLeftArrow} marginLeft={8}>
            <ChevronIcon icon='SingleChevronLeft' size={10} />
          </ArrowContainer>
          <ArrowContainer onClick={onClickRightArrow} marginLeft={8}>
            <ChevronIcon icon='SingleChevronRight' size={10} />
          </ArrowContainer>
        </TodayViewButtonContainer>
        <CalendarTitleContainer justifyContent={'flex-end'}>
          {utils.datetime.isToday(calendarDate) && <RedDot />}
          <CalendarTitle>{calendarTitle}</CalendarTitle>
        </CalendarTitleContainer>
      </NavigationContainer>
    )
  }

  return (
    <NavigationContainer {...fullWeekContainerProps}>
      {onClickCreate && (
        <CreateButton onClick={onClickCreate}>
          <RedBall />
          <GreenBall />
          <PrimaryBall />
          <CreateButtonLabel>{translations.general.create}</CreateButtonLabel>
        </CreateButton>
      )}
      <CalendarTitleContainer>
        <CalendarTitle large>{calendarTitle}</CalendarTitle>
      </CalendarTitleContainer>
      <Settings />
      <TodayNavigationButtonContainer onClick={onClickToday}>
        {translations.general.today}
      </TodayNavigationButtonContainer>
      <ArrowContainer onClick={onClickLeftArrow} marginLeft={8}>
        <ChevronIcon icon='SingleChevronLeft' size={10} />
      </ArrowContainer>
      <ArrowContainer onClick={onClickRightArrow} marginLeft={8}>
        <ChevronIcon icon='SingleChevronRight' size={10} />
      </ArrowContainer>
    </NavigationContainer>
  )
}

export const NavigationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  justify-content: ${(props) => props.spaceBetween && 'space-between'};
  margin-bottom: ${styles.spacings.small};
  margin-right: 0px;
  opacity: 0;
  width: 100%;
`

NavigationContainer.displayName = 'NavigationContainer'

const NavigationButtonContainer = styled.div`
  align-items: center;
  border: 1px solid ${styles.colors.greyBorderColor};
  border-radius: 8px;
  cursor: ${(props) => (props.inactive ? 'inherit' : 'pointer')};
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  margin-left: ${(props) => props.marginLeft}px;
  transition: border 100ms ease-out;

  svg,
  g,
  path {
    fill: ${styles.colors.textMediumDarkColor};
  }

  &:hover {
    border-color: ${styles.colors.textMediumDarkColor};

    svg,
    g,
    path {
      fill: ${styles.colors.textMediumDarkColor};
    }
  }
`

NavigationButtonContainer.displayName = 'NavigationButtonContainer'

const TodayViewButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

TodayViewButtonContainer.displayName = 'TodayViewButtonContainer'

const TodayNavigationButtonContainer = styled(NavigationButtonContainer)`
  color: ${styles.colors.textMediumDarkColor};
  font-size: ${styles.fonts.fontSizeSmall};
  padding-left: 10px;
  padding-right: 10px;
`

TodayNavigationButtonContainer.displayName = 'TodayNavigationButtonContainer'

const ChevronIcon = styled(Icon)`
  display: ${(props) => props.inactive && 'none'};
`

ChevronIcon.displayName = 'ChevronIcon'

const ArrowContainer = styled(NavigationButtonContainer)`
  width: 32px;

  ${ChevronIcon} {
    path {
      fill: ${styles.colors.textMediumDarkColor};
    }
  }
`

ArrowContainer.displayName = 'ArrowContainer'

const CalendarTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
`

CalendarTitleContainer.displayName = 'CalendarTitleContainer'

const CalendarTitle = styled.div`
  color: ${styles.colors.textMediumDarkColor};
  font-size: ${(props) => (props.large ? '24px' : styles.fonts.fontSizeSmall)};
  padding-bottom: 2px;
`

CalendarTitle.displayName = 'CalendarTitle'

const RedDot = styled.div`
  background-color: ${styles.colors.redColor};
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
`

RedDot.displayName = 'RedDot'

const TinyBall = styled.span`
  border-radius: 50%;
  height: 6px;
  width: 6px;
`

TinyBall.displayName = 'TinyBall'

const RedBall = styled(TinyBall)`
  background-color: ${styles.colors.redColor};
`

RedBall.displayName = 'RedBall'

const GreenBall = styled(TinyBall)`
  background-color: ${styles.colors.greenPrimaryColor};
`

GreenBall.displayName = 'GreenBall'

const PrimaryBall = styled(TinyBall)`
  background-color: ${styles.colors.primaryColor};
`

PrimaryBall.displayName = 'PrimaryBall'

const CreateButton = styled.button`
  align-items: center;
  background-color: transparent;
  border-color: ${styles.colors.lightVioletBorderColor};
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  display: flex;
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
  height: 32px;
  justify-content: center;
  margin-left: 40px;
  min-width: 120px;
  outline: none;
  padding: 0;
  position: relative;
  width: 120px;

  &:hover {
    background-color: ${styles.colors.primaryColor};
    border-color: ${styles.colors.primaryColor};
    color: white;

    ${PrimaryBall} {
      background-color: white;
    }
  }

  ${TinyBall} {
    margin-right: 1px;
  }
`

CreateButton.displayName = 'CreateButton'

const CreateButtonLabel = styled.span`
  font-weight: bold;
  margin: 0;
  margin-left: 5px;
  padding: 0;
`

CreateButtonLabel.displayName = 'CreateButtonLabel'
