import React from 'react'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'

import { SYNC_OPTIONS } from './SyncOptions'

export function SyncExample({ noSelection, option }) {
  const getMainCalendarEvents = () =>
    translations.multiCalendarSettings.example1.events.map((event, index) => {
      const isCalendarEvent = index === 1 || index === 3
      const shouldShowExternalEvents = option === SYNC_OPTIONS.MANY_TO_MANY || option === SYNC_OPTIONS.MANY_TO_ONE

      return (
        <ExampleCalendarEvent
          eventColor={styles.colors.purpleColor}
          externalEvent={shouldShowExternalEvents && !isCalendarEvent}
          key={index}
          ownEvent={isCalendarEvent}>
          {event}
        </ExampleCalendarEvent>
      )
    })

  const getSecondCalendarEvents = () =>
    translations.multiCalendarSettings.example2.events.map((event, index) => {
      const isCalendarEvent = index === 0 || index === 4
      const shouldShowExternalEvents = option === SYNC_OPTIONS.MANY_TO_MANY

      return (
        <ExampleCalendarEvent
          eventColor='#DA9A58'
          externalEvent={shouldShowExternalEvents && !isCalendarEvent}
          key={index}
          ownEvent={isCalendarEvent}>
          {event}
        </ExampleCalendarEvent>
      )
    })

  const getThirdCalendarEvents = () =>
    translations.multiCalendarSettings.example3.events.map((event, index) => {
      const isCalendarEvent = index === 2
      const shouldShowExternalEvents = option === SYNC_OPTIONS.MANY_TO_MANY

      return (
        <ExampleCalendarEvent
          eventColor='#8ECF66'
          externalEvent={shouldShowExternalEvents && !isCalendarEvent}
          key={index}
          ownEvent={isCalendarEvent}>
          {event}
        </ExampleCalendarEvent>
      )
    })

  return (
    <ExampleContainer noSelection={noSelection}>
      {noSelection ? (
        <NoSelectionHint>{translations.multiCalendarSettings.noSelectionHint}</NoSelectionHint>
      ) : (
        <>
          <ExampleColumn>
            <ExampleColumnTitle>{translations.multiCalendarSettings.example1.title}</ExampleColumnTitle>
            <ExampleCalendar>{getMainCalendarEvents()}</ExampleCalendar>
          </ExampleColumn>
          <ExampleColumn>
            <ExampleColumnTitle>{translations.multiCalendarSettings.example2.title}</ExampleColumnTitle>
            <ExampleCalendar>{getSecondCalendarEvents()}</ExampleCalendar>
          </ExampleColumn>
          <ExampleColumn>
            <ExampleColumnTitle>{translations.multiCalendarSettings.example3.title}</ExampleColumnTitle>
            <ExampleCalendar>{getThirdCalendarEvents()}</ExampleCalendar>
          </ExampleColumn>
        </>
      )}
    </ExampleContainer>
  )
}

const ExampleContainer = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: space-between;

  ${({ noSelection }) =>
    noSelection &&
    css`
      align-items: center;
      justify-content: center;
      margin: auto;
    `}
`

ExampleContainer.displayName = 'ExampleContainer'

const NoSelectionHint = styled.p`
  color: ${styles.colors.darkGrey};
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  text-align: center;
  width: 274px;
`

NoSelectionHint.displayName = 'NoSelectionHint'

const ExampleColumn = styled.div`
  border-radius: 12px;
  box-shadow: ${styles.shadows.taskMenuHover};
  width: calc(33% - 8px);
`

ExampleColumn.displayName = 'ExampleColumn'

const ExampleColumnTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px;
  text-align: center;
`

ExampleColumnTitle.displayName = 'ExampleColumnTitle'

const ExampleCalendarEvent = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid ${styles.colors.darkGrey}66;
  border-radius: 8px;
  color: transparent;
  display: flex;
  font-size: 8px;
  font-weight: bold;
  height: 23px;
  justify-content: center;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 300ms ease-in-out;
  white-space: nowrap;

  ${({ externalEvent }) =>
    externalEvent &&
    css`
      background-color: ${styles.colors.darkGrey};
      border-color: ${styles.colors.darkGrey};
      color: white;
      font-size: 7px;
    `}

  ${({ eventColor, ownEvent }) =>
    ownEvent &&
    css`
      background-color: ${eventColor};
      border-color: ${eventColor};
      color: white;
      font-size: 9px;
    `}
`

ExampleCalendarEvent.displayName = 'ExampleCalendarEvent'

const ExampleCalendar = styled.div`
  padding: 8px;

  ${ExampleCalendarEvent}:last-of-type {
    margin-bottom: 0;
  }
`

ExampleCalendar.displayName = 'ExampleCalendar'

export default function SyncExamples({ optionSaved, optionSelected }) {
  return (
    <Container>
      <Column>
        <ColumnTitle>
          {optionSaved !== SYNC_OPTIONS.NO_SYNC
            ? translations.multiCalendarSettings.currentSync
            : translations.multiCalendarSettings.beforeSync}
        </ColumnTitle>
        <SyncExample option={optionSaved} />
      </Column>
      <Separator />
      <Column>
        <ColumnTitle>{translations.multiCalendarSettings.afterSync}</ColumnTitle>
        <SyncExample noSelection={optionSelected === SYNC_OPTIONS.NO_SYNC} option={optionSelected} />
      </Column>
    </Container>
  )
}

const Container = styled.div`
  align-items: stretch;
  display: flex;
  width: 100%;
`

Container.displayName = 'Container'

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
`

Column.displayName = 'Column'

const ColumnTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 16px;
  text-align: center;
`

ColumnTitle.displayName = 'ColumnTitle'

const Separator = styled.div`
  background-color: ${styles.colors.middleGrey};
  margin: 22px 20px 0;
  width: 1px;
`
