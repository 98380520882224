import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { styles } from 'gipsy-misc'
import Icon from 'Icon'

class CreatingLine extends PureComponent {
  render() {
    const { onClick, icon, fillIcon, label, marginLeft, marginRight } = this.props

    return (
      <CreatingLineContainer
        className={'creation-line-container '}
        onClick={onClick}
        marginLeft={marginLeft}
        marginRight={marginRight}
        fillIcon={fillIcon}>
        <Icon icon={icon} size={16} fill={fillIcon} className={'icon'} />
        <CreatingLineLabel>{label}</CreatingLineLabel>
      </CreatingLineContainer>
    )
  }
}

export const CreatingLineContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${styles.colors.greyBorderColor};
  margin-bottom: 10px;
  cursor: pointer;
  margin-left: ${(props) => props.marginLeft}px;
  margin-right: ${(props) => props.marginRight}px;
  color: ${styles.colors.darkGrey};
  :hover {
    border-color: ${styles.colors.lightVioletColor};
    color: ${styles.colors.primaryColor};
    .icon {
      svg {
        fill: ${(props) => props.fillIcon !== 'false' && styles.colors.primaryColor};
      }
      path {
        fill: ${styles.colors.primaryColor};
      }
      rect {
        stroke: ${styles.colors.primaryColor};
      }
    }
  }
`

const CreatingLineLabel = styled.div`
  margin-left: 8px;
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
`

CreatingLine.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  fillIcon: PropTypes.string,
  onClick: PropTypes.func,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
}

export default CreatingLine
