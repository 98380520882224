export const types = {
  SET_SESSION: 'SET_SESSION',
  REMOVE_SESSION: 'REMOVE_SESSION',
  SET_USER: 'SET_USER',
  SET_FOCUSSESSION: 'SET_FOCUSSESSION',
  SET_HIDEFOCUSEDTASK: 'SET_HIDEFOCUSEDTASK',
  SET_MUTEMODEON: 'SET_MUTEMODEON',
  SET_COMPLETED_SESSION: 'SET_COMPLETED_SESSION',
  REMOVE_COMPLETED_SESSION: 'REMOVE_COMPLETED_SESSION',
  SET_LAST_ACTION_TIME: 'SET_LAST_ACTION_TIME',
}
