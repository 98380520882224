import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { SimpleButton } from 'gipsy-ui'

import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

const componentName = 'OnboardingVideoPrompt'

export default function OnboardingVideoPrompt({ onAccept, onDismiss }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pushShortcutsGroup([], componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  return (
    <Content>
      <Title>{translations.onboarding.videoPrompt.title}</Title>
      <Subtitle>{translations.onboarding.videoPrompt.subtitle}</Subtitle>
      <AcceptButton height={48} onClick={onAccept} text={translations.onboarding.videoPrompt.accept} width={156} />
      <DismissButton onClick={onDismiss}>{translations.onboarding.videoPrompt.dismiss}</DismissButton>
    </Content>
  )
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 50px 34px 28px;
`

Content.displayName = 'Content'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0 0 20px;
  text-align: center;
`

Title.displayName = 'Title'

const Subtitle = styled.p`
  color: ${styles.colors.textMediumDarkColor};
  margin: 0 0 32px;
  text-align: center;
`

Subtitle.displayName = 'Subtitle'

const AcceptButton = styled(SimpleButton)`
  margin-bottom: 16px;
`

AcceptButton.displayName = 'AcceptButton'

const DismissButton = styled.span`
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`

DismissButton.displayName = 'DismissButton'
