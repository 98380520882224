import React from 'react'
import { connect } from 'react-redux'

import { TaskFilters } from 'gipsy-ui'

const ConnectedTaskFilters = (props) => {
  return <TaskFilters {...props} />
}

const mapStateToProps = (state) => ({
  activeProjects: state.projects.items,
  tags: state.tags.items,
})

export default connect(mapStateToProps, null, null, {
  pure: true,
})(ConnectedTaskFilters)
