export const actions = {
  publish: 'publish',
  subscribe: 'subscribe',
}

export const topics = {
  calendarList: 'calendar-list',
  events: 'events',
  focusSession: 'focus-session',
  itemDelete: 'item/delete',
  taskSchedule: 'task/schedule',
  taskSimpleUpdate: 'task/simple-update',
  user: 'user',
}
