import React from 'react'
import { useSelector } from 'react-redux'

import AllTasksPage from './components/page'

function AllTasks() {
  const areEventsLoaded = useSelector((state) => state.calendar.items.fetchStatus.areLoaded)
  const areItemsLoaded = useSelector((state) => state.items.areItemsLoaded)
  const areProjectsLoaded = useSelector((state) => state.projects.areProjectsLoaded)
  const areTagsLoaded = useSelector((state) => state.tags.areTagsLoaded)

  if (!areEventsLoaded || !areItemsLoaded || !areProjectsLoaded || !areTagsLoaded) return null

  return <AllTasksPage />
}

export default AllTasks
