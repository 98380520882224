import { Schedules } from 'models/recurrency'

export type dayList = number[]

export type DailyOptions = {
  every: number
}

export type WeeklyOptions = {
  days: dayList
  every: number
}

export enum MonthlySchedule {
  Day = 'day',
  Weekday = 'weekday',
  WeekendDay = 'weekend-day',
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export type MonthlyOptions = {
  every: number
  day?: number
  onOrdinal?: number
  onSchedule?: MonthlySchedule
}

interface RecurrencyInfo {
  firstOccurrence: string
  schedule: Schedules
}

export interface DailyRecurrence extends RecurrencyInfo {
  dailyOptions: DailyOptions
}

export interface WeeklyRecurrence extends RecurrencyInfo {
  weeklyOptions: WeeklyOptions
}

export interface MonthlyRecurrence extends RecurrencyInfo {
  monthlyOptions: MonthlyOptions
}

export type RecurrencyDetails = DailyRecurrence | WeeklyRecurrence | MonthlyRecurrence
