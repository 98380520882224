import { createAction } from 'redux-actions'
import { types } from 'store/taskPanel/types'

const _addTmpItem = createAction(types.ADD_TMP_ITEM)
const _removeTmpItem = createAction(types.REMOVE_TMP_ITEM)
const _setTmpItems = createAction(types.SET_TMP_ITEMS)

export const addTmpItem = (id) => (dispatch) => dispatch(_addTmpItem(id))
export const removeTmpItem = (id) => (dispatch) => dispatch(_removeTmpItem(id))
export const clearTmpItems = () => (dispatch) => dispatch(_setTmpItems())
