import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled, { css } from 'styled-components'
import { getSrc } from 'utils/image'
import { Icon, media } from 'gipsy-ui'
import { styles, variables } from 'gipsy-misc'

import { hideSnackbar as hide } from 'store/snackbar/actions'

const mapStateToProps = (state) => ({
  visible: state.snackbar.visible,
  status: state.snackbar.status,
  message: state.snackbar.message,
  showLogo: state.snackbar.showLogo,
  showClose: state.snackbar.showClose,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hide,
    },
    dispatch
  ),
})

const sampleLogo = () => {
  const x = Math.random()
  if (x < 0.1) {
    return { logo: getSrc('/images/gipsybot-animated-bounce.gif'), animated: true }
  }
  return { logo: getSrc('/images/gipsybot-default.svg'), animated: false }
}

class Snackbar extends PureComponent {
  render() {
    const { status, visible, message, showLogo, showClose } = this.props
    let { logo, animated } = sampleLogo()
    return (
      <Container className={status} visible={visible && message}>
        {showLogo && (
          <Logo animated={animated}>
            <LogoImg src={logo} />
          </Logo>
        )}
        <div className='message'>{message}</div>
        <Action>
          {showClose && (
            <div className='close-btn' onClick={this.props.actions.hide}>
              <Icon icon='Close' size={10} fill={styles.colors.darkGrey} />
            </div>
          )}
        </Action>
      </Container>
    )
  }
}

const Container = styled.div`
  border: none;
  position: fixed;
  z-index: ${variables.zIndex.homebaseSnackbar};
  bottom: 50px;
  left: 20px;
  right: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px -6px 45px rgba(33, 21, 81, 0.16);
  text-align: center;
  line-height: 24px;
  max-width: 600px;
  margin: auto;
  font-size: 16px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(0px);
  transition: opacity 0.3s, transform 0.5s;
  padding: 23px 19px 23px 33px;

  ${media['phone-wide']`
    left: 0;
    right: 0;
  `}

  &.default {
    background-color: white;
    bottom: -10px;
    color: ${styles.colors.textDarkColor};
    box-shadow: 0 0 20px 4px #d8d8d873;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .message {
      text-align: left;
      ${media['phone-wide']`
        font-size: ${styles.fonts.fontSizeSmall};
      `}
    }
  }

  &.success {
    background-color: ${styles.colors.greenColor};
    box-shadow: 0 0 20px 4px #48d8a973;
    color: white;
  }
  &.error {
    background-color: ${styles.colors.pinkColor};
    box-shadow: 0 0 20px 4px #ff217170;
    color: white;
  }

  ${(props) =>
    props.visible &&
    css`
      visibility: visible;
      transition: opacity 0.2s, transform 0.5s;
      opacity: 1;
      transform: translateY(-10px);
    `}
`

const Logo = styled.div`
  position: absolute;
  top: -50px;
  left: 20px;
  height: 70px;
  max-height: 70px;

  ${(props) =>
    props.animated &&
    css`
      width: 200px;
      height: 200px;
      max-height: 200px;
      top: -110px;
      left: -50px;
    `}
`

const LogoImg = styled.img`
  height: 100%;
  max-height: 100%;
`

const Action = styled.div`
  flex-shrink: 0;
  padding: 0 0 0 10px;
  button,
  .close-btn {
    display: inline-block;
    vertical-align: middle;
  }
  .close-btn {
    cursor: pointer;
    padding: 10px 0 10px 10px;
  }
`

export default connect(mapStateToProps, mapDispatchToProps, null)(Snackbar)
