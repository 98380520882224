import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'

import { IconButton, Icon, TimeRange } from 'gipsy-ui'
import { models, styles, translations, utils } from 'gipsy-misc'

import variables from 'assets/styles/variables.js'
import useClickOutside from 'features/app/hooks/useClickOutside'
import CalendarItemPopupContainer from 'features/calendar/components/CalendarPanel/components/CalendarItemPopupContainer'
import DatePickerInput from 'features/calendar/components/CalendarPanel/components/DatePickerInput'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

import { MoreButton } from './components'

const componentName = 'AddCalendarItemPopup'
const popupZIndex = 3

const AddCalendarItemPopup = React.forwardRef(function AddCalendarItemPopup(props, ref) {
  const {
    firstDayOfWeek,
    onChangeSlotRange,
    onChangeStartDate,
    onChangeTitle,
    onClickOutside,
    onClose,
    onCreateTask,
    onCreateSprint,
    onTogglePin,
    selectedSlot,
    startSprintCreation,
    startTaskCreation,
  } = props

  useClickOutside(ref, onClickOutside)

  const dispatch = useDispatch()

  const [itemTitle, setItemTitle] = useState('')
  const [emptyTitleErrorShown, setEmptyTitleErrorShown] = useState('')
  const [isItemTitleFocused, setIsItemTitleFocused] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const sessionUser = useSelector((state) => state.session.user)

  const debouncedOnChangeTitleCallback = useRef(debounce((title) => onChangeTitle(title), 300))

  const _onChangeTitle = useCallback(
    (e) => {
      setItemTitle(e.target.value)
      if (emptyTitleErrorShown) {
        setEmptyTitleErrorShown(false)
      }
    },
    [emptyTitleErrorShown]
  )

  let startTime
  let estimatedTimeObj
  if (selectedSlot) {
    startTime = moment(selectedSlot.start)
    estimatedTimeObj = utils.datetime.getDurationObj({ start: startTime, end: selectedSlot.end })
  } else {
    startTime = moment()
    estimatedTimeObj = { hour: 0, minute: 15 }
  }

  let duration =
    (Number.isFinite(estimatedTimeObj.hour) ? estimatedTimeObj.hour * 60 : 0) +
    (Number.isFinite(estimatedTimeObj.minute) ? estimatedTimeObj.minute : 0)

  if (duration === 0) {
    duration = 15
  }

  const _onCreateTask = useCallback(() => {
    setIsSubmitting(true)
    const task = models.task({
      title: itemTitle,
      when: { date: moment(selectedSlot.start).format('YYYY-MM-DD') },
      to: utils.user.computeUserFromSessionUser(sessionUser),
      estimatedTime: utils.datetime.getDurationInNanoSeconds(selectedSlot),
    })
    if (!selectedSlot.allDay) {
      task.pin = {
        time: moment(selectedSlot.start).format(),
      }
    }
    onCreateTask?.(task, { componentSource: 'calendarPanel' })
  }, [selectedSlot, itemTitle, onCreateTask, sessionUser])

  const _onCreateSprint = useCallback(() => {
    setIsSubmitting(true)
    const sprint = models.sprint({
      ...utils.sprint.getPinTimeAndEstimatedTimeFromCalendarSlot(selectedSlot),
      title: itemTitle,
    })
    onCreateSprint?.(sprint)
  }, [selectedSlot, itemTitle, onCreateSprint])

  useEffect(() => {
    debouncedOnChangeTitleCallback.current?.(itemTitle)
  }, [itemTitle])

  const showTitleError = useCallback(() => setEmptyTitleErrorShown(true), [])

  const handlePinToggle = useCallback(() => {
    onTogglePin({ item: selectedSlot.item, isCreating: true })
  }, [onTogglePin, selectedSlot.item])

  const handleDateSelected = useCallback(
    (data) => {
      onChangeStartDate?.(data.value)
    },
    [onChangeStartDate]
  )

  useEffect(() => {
    dispatch(
      pushShortcutsGroup(
        [
          {
            key: 'Escape',
            label: translations.general.cancel,
            callback: onClose,
          },
          {
            key: 'Enter',
            label: translations.calendar.createTask,
            callback: _onCreateTask,
          },
          {
            key: 'Enter',
            label: translations.calendar.createSprint,
            callback: _onCreateSprint,
            shiftKey: true,
          },
        ],
        componentName
      )
    )
    return () => dispatch(popShortcutsGroup(componentName))
  }, [onClose, startTaskCreation, startSprintCreation, _onCreateTask, _onCreateSprint, dispatch, isItemTitleFocused])

  const isFormDisabled = isSubmitting || !itemTitle
  return (
    <CalendarItemPopupContainer
      ref={ref}
      zIndex={popupZIndex}
      padding={containerPadding}
      maxWidth={variables.addCalendarTaskPopupWidth}
      top={props.top}
      shouldFlipTail={props.shouldFlipTail}
      left={props.left}>
      <InputsSection>
        <CloseIcon icon='Close' size={9} onClick={onClose} />
        <TitleInputContainer className='fs-mask'>
          <ItemTitleInput
            hasError={emptyTitleErrorShown}
            autoFocus
            value={itemTitle}
            onChange={_onChangeTitle}
            onFocus={() => setIsItemTitleFocused(true)}
            onBlur={() => setIsItemTitleFocused(false)}
            placeholder={`${translations.general.new}...`}
          />
          {emptyTitleErrorShown && <ErrorContainer>{translations.sprint.panel.emptyTitleErr}</ErrorContainer>}
        </TitleInputContainer>
        <TimeInputSection>
          <DateInputWrapper>
            <DatePickerInput
              closeOnSelection
              firstDayOfWeek={firstDayOfWeek}
              onChange={handleDateSelected}
              startTime={startTime}
            />
          </DateInputWrapper>
        </TimeInputSection>
        <TimeInputSection>
          <PinButton active={!selectedSlot.allDay} onClick={handlePinToggle}>
            <PinIcon icon='Pin' size={10} />
          </PinButton>
          <TimeRange
            disabled={selectedSlot.allDay}
            duration={duration}
            onChange={onChangeSlotRange}
            onClickWhileDisabled={handlePinToggle}
            startTime={startTime}
          />
        </TimeInputSection>
      </InputsSection>
      <AddItemButtonsContainer>
        <AddItemButtonContainer>
          <IconButton
            onClick={isFormDisabled ? showTitleError : _onCreateTask}
            isDisabled={isFormDisabled}
            iconName={'Pin'}
            width={136}
            height={32}
            labelColor={styles.colors.primaryColor}
            fillIcon={styles.colors.primaryColor}
            borderColor={styles.colors.primaryColor}
            borderHoverColor={styles.colors.lightVioletBorderColor}
            iconSize={14}
            label={translations.calendar.createTask}
          />
          <MoreButton onClick={startTaskCreation} />
        </AddItemButtonContainer>
        {!selectedSlot?.allDay && (
          <>
            <OrSeparator>{translations.general.or}</OrSeparator>
            <AddItemButtonContainer>
              <IconButton
                onClick={isFormDisabled ? showTitleError : _onCreateSprint}
                isDisabled={isFormDisabled}
                iconName={'Sprint'}
                width={136}
                height={32}
                labelColor={styles.colors.orangeColor}
                fillIcon={styles.colors.orangeColor}
                iconHoverColor={styles.colors.orangeColor}
                borderColor={styles.colors.orangeColor}
                borderHoverColor={`${styles.colors.orangeColor}80`}
                iconSize={16}
                label={translations.calendar.createBlock}
              />
              <MoreButton onClick={startSprintCreation} />
            </AddItemButtonContainer>
          </>
        )}
      </AddItemButtonsContainer>
    </CalendarItemPopupContainer>
  )
})

AddCalendarItemPopup.propTypes = {
  onClose: PropTypes.func,
  top: PropTypes.number,
  startSprintCreation: PropTypes.func,
  startTaskCreation: PropTypes.func,
}

export default React.memo(AddCalendarItemPopup)

const containerPadding = 8

export const InputsSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${styles.colors.veryLightGrey};
  border-radius: 8px;
  margin-bottom: 8px;
  padding-right: 6px;
  padding-left: 8px;
  padding-top: 8.5px;
  width: 100%;
  & > * {
    margin-bottom: 16px;
  }
`

export const TimeInputSection = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-left: 1px;
  padding: 0 14px;
  position: relative;
`

const TitleInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  & input {
    width: 100%;
  }
`

const ItemTitleInput = styled.input`
  padding: 13px 16px;
  border-radius: 8px;
  border: 1px solid ${styles.colors.greyBorderColor};
  color: ${styles.colors.textMediumDarkColor};
  ${(props) =>
    props.hasError &&
    css`
      border-color: ${styles.colors.errorColor};
    `}
  font-size: 15px;
  ::placeholder {
    color: ${styles.colors.darkGrey};
    text-transform: capitalize;
  }
`

const ErrorContainer = styled.div`
  position: absolute;
  color: red;
  font-size: 10px;
  bottom: 0;
  left: 20px;
`

const CalendarIcon = styled(Icon)`
  margin: 0 16px 0 4px;
`

CalendarIcon.displayName = 'CalendarIcon'

export const PinIcon = styled(Icon)`
  transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
`

PinIcon.displayName = 'PinIcon'

export const PinButton = styled.div`
  background-color: white;
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  cursor: pointer;
  margin-right: 4px;
  padding: 6px;
  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

  :hover {
    background-color: ${styles.colors.primaryColor};
    border-color: ${styles.colors.primaryColor};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${styles.colors.primaryColor};
      border-color: ${styles.colors.primaryColor};

      ${PinIcon} {
        transform: rotate(-45deg);

        path {
          fill: white;
        }
      }
    `}
`

PinButton.displayName = 'PinButton'

export const AddItemButtonsContainer = styled.div`
  padding: 8px 0;
  width: 100%;
`

export const AddItemButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
`

const OrSeparator = styled.span`
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 16px;
  color: ${styles.colors.darkGrey};
  padding: 8px 0;
  text-transform: uppercase;
  margin-left: -${containerPadding}px;
  width: calc(100% + ${containerPadding * 2}px);
  ::before,
  ::after {
    content: '';
    flex: 1 1 auto;
    border-top: 1px solid ${styles.colors.greyBorderColor};
  }
  ::before {
    margin-right: ${containerPadding}px;
  }
  ::after {
    margin-left: ${containerPadding}px;
  }
`

const CloseIcon = styled(Icon)`
  position: relative;
  margin-left: auto;
  margin-right: 2.5px;
  margin-bottom: 7.5px;
`

export const DateInputWrapper = styled.div`
  margin-left: 4px;
`

DateInputWrapper.displayName = 'DateInputWrapper'
