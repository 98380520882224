import { storageAvailable } from 'utils/storage'

const isCalendarListExpandedKey = 'isCalendarListExpanded'

export const getIsExpanded = () => {
  if (storageAvailable('localStorage')) {
    return window.localStorage.getItem(isCalendarListExpandedKey) !== 'false'
  }
  return true
}

export const setIsExpanded = (value) => {
  if (storageAvailable('localStorage')) {
    window.localStorage.setItem(isCalendarListExpandedKey, value)
  }
}
