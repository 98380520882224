import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { findDOMNode } from 'react-dom'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import clone from 'lodash/clone'
import debounce from 'lodash/debounce'
import set from 'lodash/set'
import moment from 'moment'

import { focussession as focussessionApi, sprint as sprintApi, task as taskApi } from 'gipsy-api'
import { models, styles, translations, utils } from 'gipsy-misc'

import variables from 'assets/styles/variables'
import { computePopupTopAndLeftPosition } from 'features/calendar/utils'
import usePageActions from 'features/hooks/usePageActions2'
import RealTime from 'features/realTime'
import {
  changeSlotDuration,
  changeSlotStartDate,
  changeSlotStartTime,
  computeSlotFromCalendarTask,
} from 'logic/calendar'
import { handleAPIError } from 'store/app/actions'
import {
  fetchNextEvents,
  fetchPreviousEvents,
  setEventsLocked,
  updateCalendarDate,
  updateCalendarEvent,
} from 'store/calendar/actions'
import { replaceItems, updateItem } from 'store/items/actions'
import { startFocusSession as _startFocusSession } from 'store/session/actions'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { patchTaskRequest } from 'store/task/actions'

export default function useCalendarActions({
  calendarContainerRef,
  calendarItems,
  callbacks: { onClickDeleteSprintCallback } = {},
  cancelCalendarTaskAction,
  creatingCalendarTask,
  creatingSprint,
  date,
  editingCalendarTask,
  editingSprint,
  fourDayMode,
  isDragging,
  isSprintComposerShown,
  onCreateCalendarTask,
  onCreateSprint: _onCreateSprint,
  onSaveCalendarTask,
  onTogglePinFromCalendarPanel,
  startSprintCreation: _startSprintCreation,
  startSprintEdition: _startSprintEdition,
  updateScrollToTime,
  weekView,
}) {
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    completeTask,
    deleteFocusSession,
    deleteSprint,
    deleteTask,
    editSprint,
    findItemById,
    focusSessionDeletePopup,
    saveTask,
    sprintDeletePopup,
    taskDeletePopUp,
    updateFocusSession,
    recurringItemPopup,
    updateRecurringSprintInstanceWithTasks,
  } = usePageActions()

  const dateMoment = moment(date)

  const [addItemPopupPositionProps, setAddItemPopupPositionProps] = useState({
    left: null,
    shouldFlipTail: false,
    top: 0,
  })
  const [calendarDate, setCalendarDate] = useState(dateMoment.toDate())
  const [clickedItem, setClickedItem] = useState(null)
  const [prevEditingPopupPositionProps, setPrevEditingPopupPositionProps] = useState(null)
  const [isAddItemPopupShown, setIsAddItemPopupShown] = useState(false)
  const [isEditItemPopupShown, setIsEditItemPopupShown] = useState(false)
  const [isTaskLineModalShown, setIsTaskLineModalShown] = useState(false)
  const [localEditingCalendarTask, setLocalEditingCalendarTask] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(null)

  const addItemPopupRef = useRef(null)
  const afterDragEndCallback = useRef(null)
  const calendarRef = useRef(null)
  const editItemPopupRef = useRef(null)
  const previousLocation = useRef(location.pathname)

  useEffect(() => {
    if (location.pathname !== previousLocation.current) {
      previousLocation.current = location.pathname
      setIsTaskLineModalShown(false)
      setSelectedSlot(null)
      setClickedItem(null)
    }
  }, [location])

  useEffect(() => {
    if (!isDragging && afterDragEndCallback.current) {
      afterDragEndCallback.current()
      afterDragEndCallback.current = null
    }
  }, [isDragging])

  useEffect(() => {
    const item = creatingCalendarTask || editingCalendarTask
    if (item) {
      const { allDay, start, end } = computeSlotFromCalendarTask(item)
      setSelectedSlot({
        allDay,
        start,
        end,
        item,
      })

      if (!isTaskLineModalShown && !isEditItemPopupShown) {
        const newDate = new Date(start)
        setCalendarDate(newDate)
        setIsTaskLineModalShown(true)
      }
    }
  }, [creatingCalendarTask, editingCalendarTask, isEditItemPopupShown, isTaskLineModalShown])

  const computeSlotFromSprint = useCallback((sprint) => {
    const { estimatedTime } = sprint
    let startTime

    if (!sprint.pin || !sprint.pin.time) {
      set(sprint, 'pin.time', sprint.startTime)
      startTime = sprint.startTime
    } else {
      startTime = sprint.pin.time
    }

    const newStart = new Date(startTime)
    const newEnd = new Date(newStart)
    newEnd.setMinutes(newEnd.getMinutes() + utils.datetime.convertNanosecondsToMinute(estimatedTime))

    return {
      start: newStart,
      end: newEnd,
      item: sprint,
    }
  }, [])

  useEffect(() => {
    const item = editingSprint || creatingSprint

    if (item) {
      const newSelectedSlot = computeSlotFromSprint(item)
      setSelectedSlot(newSelectedSlot)
    }
  }, [computeSlotFromSprint, creatingSprint, editingSprint, setSelectedSlot])

  const getSelectedSlotBounds = useCallback(() => {
    const calendarNode = calendarContainerRef.current

    if (calendarNode) {
      const currentHighlighted = calendarNode.querySelector('.fc-event.highlighted')
      const currentPlaceholder = calendarNode.querySelector('.fc-event.placeholder')

      if (currentPlaceholder) {
        return currentPlaceholder.getBoundingClientRect()
      } else if (currentHighlighted) {
        return currentHighlighted.getBoundingClientRect()
      }
    }
  }, [calendarContainerRef])

  const getNewAttributes = useCallback(({ start, end }) => {
    const when = {
      date: moment(start).format('YYYY-MM-DD'),
    }

    const pin = {
      time: moment(start).format(),
    }

    const estimatedTime = utils.calendar.getDurationNSFromCalendarSlot({ start, end })

    return { when, pin, estimatedTime }
  }, [])

  const refreshLocalEditingCalendarTask = useCallback(
    ({ start, end, allDay, ...updatedTaskAttributes }) => {
      const newLocalEditingCalendarTask = {
        ...localEditingCalendarTask,
        ...getNewAttributes({ start, end }),
        ...updatedTaskAttributes,
      }

      if (allDay) {
        delete newLocalEditingCalendarTask.pin
      }

      const sprintInfoChanged = localEditingCalendarTask.sprintInfo !== newLocalEditingCalendarTask.sprintInfo

      if (
        prevEditingPopupPositionProps &&
        isTaskLineModalShown &&
        !sprintInfoChanged &&
        (newLocalEditingCalendarTask.when?.date !== localEditingCalendarTask.when?.date ||
          newLocalEditingCalendarTask.pin?.time !== localEditingCalendarTask.pin?.time)
      ) {
        setPrevEditingPopupPositionProps(null)
      }

      setLocalEditingCalendarTask(newLocalEditingCalendarTask)
      return newLocalEditingCalendarTask
    },
    [getNewAttributes, isTaskLineModalShown, localEditingCalendarTask, prevEditingPopupPositionProps]
  )

  const showAddItemPopup = useCallback(
    (bounds) => {
      const calendarRef = calendarContainerRef.current
      bounds = bounds ?? getSelectedSlotBounds()

      if (bounds && calendarRef) {
        const { left, shouldFlipTail, top } = computePopupTopAndLeftPosition(
          calendarRef.getBoundingClientRect(),
          bounds,
          variables.addCalendarTaskPopupHeight,
          variables.addCalendarTaskPopupWidth,
          !weekView
        )

        setAddItemPopupPositionProps((prev) => ({
          left: !isNaN(left) ? left : prev.left,
          shouldFlipTail,
          top: !isNaN(top) ? top : prev.top,
        }))

        if (!isTaskLineModalShown) {
          setIsAddItemPopupShown(true)
        }
      }
    },
    [calendarContainerRef, getSelectedSlotBounds, isTaskLineModalShown, weekView]
  )

  const onSelectSlot = useCallback(
    ({ bounds, start, end, allDay }) => {
      if (isEditItemPopupShown) {
        setIsEditItemPopupShown(false)
        setClickedItem(null)
        return
      }

      if (localEditingCalendarTask) {
        refreshLocalEditingCalendarTask({ start, end })
      }

      if (isTaskLineModalShown) {
        setPrevEditingPopupPositionProps(null)
      }

      setSelectedSlot((prev) => ({
        allDay,
        start,
        end,
        item: isTaskLineModalShown ? prev.item : { title: '' },
      }))

      showAddItemPopup(bounds)
    },
    [
      isEditItemPopupShown,
      isTaskLineModalShown,
      localEditingCalendarTask,
      refreshLocalEditingCalendarTask,
      showAddItemPopup,
    ]
  )

  const onChangeStartDate = useCallback(
    (newStartDate) => {
      setSelectedSlot((selectedSlot) => {
        const newSlot = changeSlotStartDate(selectedSlot, newStartDate)

        if (isAddItemPopupShown) {
          setCalendarDate(newSlot.start)
          updateScrollToTime?.(newSlot.start)
          setTimeout(() => {
            showAddItemPopup()
          })
        }

        return newSlot
      })
    },
    [isAddItemPopupShown, showAddItemPopup, updateScrollToTime]
  )

  const onChangeStartTime = useCallback((newStartTime) => {
    setSelectedSlot((selectedSlot) => changeSlotStartTime(selectedSlot, newStartTime))
  }, [])

  const onChangeDuration = useCallback((newDurationObj) => {
    setSelectedSlot((selectedSlot) => changeSlotDuration(selectedSlot, newDurationObj))
  }, [])

  const onChangeSlotRange = useCallback(({ start, end }) => {
    let newEnd = end

    if (start.isSame(newEnd)) {
      newEnd.add(15, 'minutes')
    }

    setSelectedSlot((selectedSlot) => ({ ...selectedSlot, end: newEnd.toDate(), start: start.toDate() }))
  }, [])

  const hideModalsAndPopups = useCallback(() => {
    setIsTaskLineModalShown(false)
    setIsAddItemPopupShown(false)
    setIsEditItemPopupShown(false)
    setSelectedSlot(null)
    setClickedItem(null)
  }, [])

  const onClickOutsideCalendarModalOrPopup = useCallback(
    (e) => {
      const hideAndDismissSelection = () => {
        setLocalEditingCalendarTask(null)
        hideModalsAndPopups()
      }

      const calendarNode = calendarRef.current && findDOMNode(calendarRef.current)
      const popup = document.querySelector('[data-id="homebase-popup"]')

      if (popup && popup.contains(e.target)) {
        e.stopPropagation()
        return
      }

      if (isEditItemPopupShown && e.target?.className?.includes?.('fc-timegrid-slot')) {
        e.stopPropagation()
        hideAndDismissSelection()
        return
      }

      if (e.target.classList.contains('fc-event-resizer') || e.target.getAttribute('data-is-placeholder') === 'true') {
        return
      }

      if (!calendarNode?.contains(e.target) || e.target.getAttribute('data-is-local-event') !== 'true') {
        hideAndDismissSelection()
        return
      }
    },
    [hideModalsAndPopups, isEditItemPopupShown]
  )

  const onClickLeftArrow = useCallback(() => {
    let newDate = moment(calendarDate)

    if (fourDayMode) {
      newDate.subtract(4, 'days')
    } else {
      newDate.subtract(1, weekView ? 'week' : 'day')
    }

    newDate = newDate.toDate()
    dispatch(fetchPreviousEvents({ date: newDate, isWeekView: weekView }))
    setCalendarDate(newDate, true)
  }, [calendarDate, dispatch, fourDayMode, weekView])

  const onClickRightArrow = useCallback(() => {
    let newDate = moment(calendarDate)

    if (fourDayMode) {
      newDate.add(4, 'days')
    } else {
      newDate.add(1, weekView ? 'week' : 'day')
    }

    newDate = newDate.toDate()
    dispatch(fetchNextEvents({ date: newDate, isWeekView: weekView }))
    setCalendarDate(newDate, true)
  }, [calendarDate, dispatch, fourDayMode, weekView])

  const onClickToday = useCallback(() => {
    setCalendarDate(new Date())
  }, [])

  const moveSprint = useCallback(
    async ({ allDay, item: oldSprint, start, end }, revertCalendarAction) => {
      if (allDay) {
        revertCalendarAction()
        return
      }

      const isOldSprintRecurrent = utils.sprint.isRecurrent(oldSprint)

      const updatedSprint = {
        ...oldSprint,
        ...getNewAttributes({ start, end }),
      }

      const isSprintPast = moment(end) < moment()
      if (!updatedSprint.tasks || updatedSprint.tasks.filter((task) => !task.completed).length === 0) {
        updatedSprint.completionTime = isSprintPast ? end : null
      }

      if (isOldSprintRecurrent) {
        const hasWhenDateChanged = updatedSprint.when.date !== oldSprint.when.date
        let hideAllOption = hasWhenDateChanged

        recurringItemPopup(
          { forSprint: true, hideAllOption, title: translations.sprint.recurrencyPanel.edit.prompt },
          {
            onConfirmed: async (recurrenceOption) => {
              try {
                let createdInstances
                const result = updateRecurringSprintInstanceWithTasks(updatedSprint, oldSprint, recurrenceOption)
                if (!!result) {
                  // to make sure the new id generated is the same as the one the front end generated
                  updatedSprint.creationTime = result?.createdRecurringSprint.creationTime
                  createdInstances = result?.createdInstances
                }

                const out = await sprintApi.edit(updatedSprint, { recurrenceOption })

                if (!out?.instances) return

                RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
                const [firstResponseInstance] = out.instances

                if (
                  out.instances.length !== createdInstances?.length ||
                  firstResponseInstance.id !== createdInstances[0].id
                ) {
                  dispatch(replaceItems(createdInstances, out.instances))
                }
              } catch (err) {
                dispatch(handleAPIError(err, { updatedSprint }))
              }
            },
            onCancelled: () => {
              revertCalendarAction()
            },
          }
        )
      } else {
        dispatch(updateItem(updatedSprint))
        await sprintApi.edit(updatedSprint)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      }
    },
    [dispatch, getNewAttributes, recurringItemPopup, updateRecurringSprintInstanceWithTasks]
  )

  const addTaskInSprint = useCallback(
    async ({ task, sprint }) => {
      const newSprintInfo = {
        id: sprint.id,
        title: sprint.title,
        estimatedTime: sprint.estimatedTime,
        pin: sprint.pin,
      }
      const updatedTask = utils.task.computeTaskOnChange(task, {
        paramName: 'sprintInfo',
        value: newSprintInfo,
      })
      dispatch(updateItem(updatedTask))
      dispatch(setEventsLocked(true))

      try {
        await taskApi.putFullTask(updatedTask)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err))
      }
      dispatch(setEventsLocked(false))
    },
    [dispatch]
  )

  const moveTask = useCallback(
    async ({ item: task, event, start, end }, revertCalendarAction) => {
      const updatedTask = {
        ...task,
        ...getNewAttributes({ start, end }),
        sprintInfo: null,
      }

      if (event.allDay) {
        updatedTask.estimatedTime = 0
        delete updatedTask.pin
      } else if (!updatedTask.estimatedTime && end === null) {
        // task comes from all day section
        const newEnd = moment(start).clone().add(30, 'minutes')
        const estimatedTime = utils.datetime.getDurationInNanoSeconds({ start, end: newEnd })
        updatedTask.estimatedTime = estimatedTime
      }

      saveTask(updatedTask, { onCancel: revertCalendarAction })
    },
    [getNewAttributes, saveTask]
  )

  const insertTask = async ({ item: task, event, start, end }) => {
    const updatedTask = {
      ...task,
      ...getNewAttributes({ start, end }),
      sprintInfo: null,
    }

    if (event.allDay) {
      updatedTask.estimatedTime = 0
      delete updatedTask.pin
    }

    dispatch(updateItem(updatedTask))

    try {
      await taskApi.putFullTask(updatedTask)
      RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
    } catch (err) {
      dispatch(handleAPIError(err))
    }
  }

  const moveFocusSession = useCallback(
    async ({ item: focusSession, start, end }, callback) => {
      const updatedFocusSession = {
        ...focusSession,
        startTime: moment(start).format(),
        endTime: moment(end).format(),
      }

      updateFocusSession(updatedFocusSession)
      callback?.(updatedFocusSession)
      await focussessionApi.update(updatedFocusSession)
    },
    [updateFocusSession]
  )

  const checkIfOverlapsSprint = (draggedEvent) => {
    return calendarItems.find((event) => {
      const isSprint = event.item.type === models.item.type.SPRINT
      const isDifferentEvent = event.id !== draggedEvent.id
      return isSprint && isDifferentEvent && draggedEvent.start >= event.start && draggedEvent.start < event.end
    })
  }

  const onEventDrop = async (event, isExternalItem, calendarActionInfo) => {
    const { allDay, start, end, extendedProps } = event
    const { item, isLocalItem } = extendedProps
    if (item.isPlaceholder) {
      onSelectSlot({ start, end })
      return
    }

    if (![models.item.type.SPRINT, models.item.type.TASK, models.item.type.FOCUSSESSION].includes(item.type)) {
      alert(`We only support moving sprint, tasks and focus sessions. Sorry. `)
      return
    }

    dispatch(setEventsLocked(true))

    if (isLocalItem) {
      if (localEditingCalendarTask) {
        setSelectedSlot({
          start,
          end,
          allDay,
          item: refreshLocalEditingCalendarTask({ start, end, allDay }),
        })
      } else {
        setSelectedSlot((selectedSlot) => ({
          start,
          end,
          allDay,
          item: selectedSlot.item,
        }))

        if (isTaskLineModalShown) {
          setPrevEditingPopupPositionProps(null)
        }
      }
    } else {
      switch (item.type) {
        case models.item.type.SPRINT:
          await moveSprint({ allDay, item, start, end }, calendarActionInfo.revert)
          break
        case models.item.type.TASK:
          const overlappedSprint = checkIfOverlapsSprint(event)
          if (overlappedSprint) {
            event.remove()
            const updatedSprint = { ...overlappedSprint.item }
            updatedSprint.tasks = (updatedSprint.tasks || []).concat(item)
            await addTaskInSprint({ task: item, sprint: updatedSprint })
          } else if (isExternalItem) {
            const isExistingTask = calendarItems.find((event) => event.id === item.id)
            if (isExistingTask) {
              await moveTask({ item, event, start, end }, calendarActionInfo.revert)
            } else {
              await insertTask({ item, event, start, end })
            }
          } else {
            await moveTask({ item, event, start, end }, calendarActionInfo.revert)
          }
          break
        case models.item.type.FOCUSSESSION:
          if (moment(end).isAfter(moment())) {
            alert(translations.calendar.alerts.futureFs)
            // rollback
            calendarActionInfo.revert()
            return
          }
          await moveFocusSession({ item, start, end })
          break
        default:
          alert(translations.calendar.alerts.supportedEvents)
      }
    }
    dispatch(setEventsLocked(false))
  }

  /*
   * We need to handle external dragging differently and wait until onDragEnd event is
   * fired (after onDropAnimation is finished) before making any update to the state
   * */
  const onEventDropMiddleware = (...args) => {
    if (isDragging) {
      afterDragEndCallback.current = () => onEventDrop(...args)
    } else {
      onEventDrop(...args)
    }
  }

  const onEventResize = useCallback(
    (event, calendarActionInfo) => {
      const { start, end, extendedProps } = event
      const { item, isLocalItem } = extendedProps

      if (calendarActionInfo.event.allDay) {
        return calendarActionInfo.revert()
      }

      if (item.isPlaceholder) {
        setSelectedSlot((selectedSlot) => ({
          start,
          end,
          item: selectedSlot.item,
        }))
        return
      }

      if (![models.item.type.SPRINT, models.item.type.TASK, models.item.type.FOCUSSESSION].includes(item.type)) {
        alert(`We only support resizing sprint, tasks and focussessions. Sorry.`)
        return
      }

      if (isLocalItem) {
        if (localEditingCalendarTask) {
          setSelectedSlot({
            start,
            end,
            item: refreshLocalEditingCalendarTask({ start, end }),
          })
        } else {
          setSelectedSlot((selectedSlot) => ({
            start,
            end,
            item: selectedSlot.item,
          }))
        }
      } else {
        switch (item.type) {
          case models.item.type.SPRINT:
            moveSprint({ item, start, end }, calendarActionInfo.revert)
            break
          case models.item.type.TASK:
            moveTask({ item, event, start, end }, calendarActionInfo.revert)
            break
          case models.item.type.FOCUSSESSION:
            if (moment(end).isAfter(moment())) {
              alert(`We don't support resizing a focus session through future dates. Sorry.`)
              calendarActionInfo.revert()
              return
            }
            moveFocusSession({ item, start, end })
            break
          default:
            alert(`We only support resizing sprint and tasks. Sorry.`)
        }
      }
    },
    [moveSprint, moveTask, moveFocusSession, refreshLocalEditingCalendarTask, localEditingCalendarTask]
  )

  const onChangeAddCalendarItemTitle = useCallback((newTitle) => {
    setSelectedSlot((selectedSlot) => {
      if (selectedSlot) {
        return {
          ...selectedSlot,
          item: {
            ...selectedSlot.item,
            title: newTitle,
          },
        }
      }
    })
  }, [])

  const computePlaceholderSlot = useCallback(
    (selectedSlot) => {
      let type
      let color = `${styles.colors.primaryColor}20`
      if (isTaskLineModalShown || isEditItemPopupShown) {
        color = '#ffffff'
        type = models.item.type.TASK
      }
      if (isSprintComposerShown) {
        color = styles.colors.orangeColor
        type = models.item.type.SPRINT
      }

      const isPlaceholder = !(isTaskLineModalShown || isSprintComposerShown || isEditItemPopupShown)

      return {
        ...selectedSlot,
        title: 'test',
        isLocalItem: true,
        item: { ...(localEditingCalendarTask ?? selectedSlot.item), isPlaceholder, type },
        color,
        isSprint: isSprintComposerShown,
        isHighlighted: isTaskLineModalShown || isSprintComposerShown,
        isPlaceholder,
      }
    },
    [isEditItemPopupShown, isSprintComposerShown, isTaskLineModalShown, localEditingCalendarTask]
  )

  const openEditItemPopup = useCallback(
    (item) => {
      hideModalsAndPopups()
      setIsEditItemPopupShown(true)
      setClickedItem({ ...item })
    },
    [hideModalsAndPopups]
  )

  const onClickEvent = (event, jsEvent) => {
    const {
      extendedProps: { item },
    } = event

    if (isSprintComposerShown) return

    switch (item.type) {
      case models.item.type.SPRINT:
      case models.item.type.TASK:
      case models.item.type.FOCUSSESSION:
        if (isTaskLineModalShown) {
          return
        }

        if (clickedItem?.id === item.id) {
          hideModalsAndPopups()
        } else if (!localEditingCalendarTask || localEditingCalendarTask.id !== item.id) {
          if (isTaskLineModalShown) {
            setIsTaskLineModalShown(false)
            setSelectedSlot(null)
            setClickedItem(null)
            setLocalEditingCalendarTask(null)
          }

          openEditItemPopup(item, jsEvent)
        }
        break
      case 'event':
        if (item.webLink) {
          const authUser =
            item.webLink.startsWith('https://www.google.com') && item.email ? `&authuser=${item.email}` : '' // to make sure we open the event in the proper calendar
          const toOpenLink = `${item.webLink}${authUser}`
          window.open(toOpenLink)
        }
        break
      default:
        break
    }
  }

  const startSprintAction = useCallback((actionFn) => {
    const startAction = () => {
      actionFn()
      setIsTaskLineModalShown(false)
      setIsAddItemPopupShown(false)
    }
    startAction()
  }, [])

  const syncItemWithSelectedSlot = useCallback((item) => {
    const { allDay, start, end } = computeSlotFromCalendarTask(item)
    setSelectedSlot({
      allDay,
      start,
      end,
      item,
    })
  }, [])

  const startLocalCalendarTaskEdition = useCallback(
    (item) => {
      setLocalEditingCalendarTask(item)
      syncItemWithSelectedSlot(item)
      setIsEditItemPopupShown(false)
      setIsTaskLineModalShown(true)
    },
    [syncItemWithSelectedSlot]
  )

  const startSprintCreation = useCallback(() => {
    let creatingSprint
    let prevSlot = null
    let sprintActionsCallbacks

    if (selectedSlot) {
      const { start, end, item } = selectedSlot
      prevSlot = { ...selectedSlot }
      const startTime = moment(start)
      const estimatedTime = utils.calendar.getDurationNSFromCalendarSlot({ start, end })
      creatingSprint = {
        startTime,
        estimatedTime,
        title: item?.title,
      }
      setCalendarDate(start)
    }

    if (localEditingCalendarTask) {
      const refocusEditingItem = () => {
        prevSlot?.item && startLocalCalendarTaskEdition(prevSlot.item)
      }

      sprintActionsCallbacks = {
        onCancelCreateSprintCallback: refocusEditingItem,
        onCreateSprintCallback: refocusEditingItem,
      }
    }

    startSprintAction(() => {
      _startSprintCreation({
        creatingSprint,
        sprintActionsCallbacks,
      })
    })
  }, [_startSprintCreation, localEditingCalendarTask, selectedSlot, startLocalCalendarTaskEdition, startSprintAction])

  const startSprintEdition = useCallback(
    (sprintToEdit) => {
      setIsEditItemPopupShown(false)
      setCalendarDate(sprintToEdit.when.date)
      startSprintAction(() => {
        _startSprintEdition({
          editingSprint: sprintToEdit,
        })
      })
    },
    [_startSprintEdition, startSprintAction]
  )

  const startLocalCalendarItemEdition = useCallback(
    (item, editingPopupPositionProps) => {
      if (item.type === models.item.type.SPRINT) {
        startSprintEdition(item)
      } else {
        startLocalCalendarTaskEdition(item)
        setPrevEditingPopupPositionProps(editingPopupPositionProps)
      }
    },
    [startSprintEdition, startLocalCalendarTaskEdition]
  )

  const onClickDeleteSprint = useCallback(
    (sprint) => {
      const onConfirm = async (recurrenceOption) => {
        if (localEditingCalendarTask) {
          setLocalEditingCalendarTask(null)
        }

        hideModalsAndPopups()
        onClickDeleteSprintCallback?.()
        await deleteSprint(sprint.id, recurrenceOption)
      }

      sprintDeletePopup(sprint, {
        onConfirmed: onConfirm,
      })
    },
    [deleteSprint, hideModalsAndPopups, localEditingCalendarTask, onClickDeleteSprintCallback, sprintDeletePopup]
  )

  const onClickDeleteTask = useCallback(
    (task) => {
      const taskId = typeof task === 'string' ? task : task?.id
      const taskToDelete = findItemById(taskId)

      if (!taskToDelete) {
        console.warn('-- task not found')
        return
      }

      const onConfirm = async (recurrenceOption) => {
        if (localEditingCalendarTask) {
          setLocalEditingCalendarTask(null)
        }

        if (clickedItem?.tasks) {
          const updatedSprint = { ...clickedItem }
          updatedSprint.tasks = updatedSprint.tasks.filter((sprintTask) => sprintTask.id !== taskId)
          setClickedItem(updatedSprint)
        } else {
          hideModalsAndPopups()
          cancelCalendarTaskAction()
        }

        await deleteTask(taskId, recurrenceOption)
      }

      taskDeletePopUp(taskToDelete, {
        onConfirmed: onConfirm,
      })
    },
    [
      cancelCalendarTaskAction,
      clickedItem,
      deleteTask,
      findItemById,
      hideModalsAndPopups,
      localEditingCalendarTask,
      taskDeletePopUp,
    ]
  )

  const updateFocusSessionInState = useCallback(
    (focusSession, updateFn) => {
      const task = clone(findItemById(focusSession.taskId))

      if (task) {
        task.focusSessions = updateFn([...(task.focusSessions || [])])
        task.title = focusSession.title
        dispatch(updateItem(task))
      }
    },
    [dispatch, findItemById]
  )

  const onClickDeleteFocusSession = useCallback(
    (focusSession) => {
      const onConfirm = async () => {
        if (localEditingCalendarTask) {
          setLocalEditingCalendarTask(null)
        }

        hideModalsAndPopups()
        // TODO: move this to page hooks once all pages are migrated to new state and uncomment call to hook below
        updateFocusSessionInState(focusSession, (taskFocusSessions) => {
          return taskFocusSessions.filter((fs) => fs.id !== focusSession.id)
        })

        await deleteFocusSession(focusSession)
      }

      focusSessionDeletePopup({
        onConfirmed: onConfirm,
      })
    },
    [
      deleteFocusSession,
      focusSessionDeletePopup,
      hideModalsAndPopups,
      localEditingCalendarTask,
      updateFocusSessionInState,
    ]
  )

  const onClickDeleteClickedItem = useCallback(
    (item) => {
      const matchType = (type) => item.type === type
      if (matchType(models.item.type.SPRINT)) {
        return onClickDeleteSprint(item)
      } else if (matchType(models.item.type.TASK)) {
        return onClickDeleteTask(item)
      } else if (matchType(models.item.type.FOCUSSESSION)) {
        return onClickDeleteFocusSession(item)
      }
    },
    [onClickDeleteFocusSession, onClickDeleteSprint, onClickDeleteTask]
  )

  const onClickRemoveTask = useCallback(
    async (task) => {
      const updatedTask = utils.task.computeTaskOnChange(task, {
        paramName: 'sprintInfo',
        value: null,
      })

      if (clickedItem?.tasks) {
        const updatedSprint = { ...clickedItem }
        updatedSprint.tasks = updatedSprint.tasks.filter((sprintTask) => sprintTask.id !== task.id)
        setClickedItem(updatedSprint)
        dispatch(updateItem(updatedTask))
      }

      await taskApi.putFullTask(updatedTask)
      RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
    },
    [clickedItem, dispatch]
  )

  const handleUpdateClickedItem = useCallback((newItem) => {
    setClickedItem({ ...newItem })
  }, [])

  const startFocusSession = useCallback(
    (...args) => {
      dispatch(_startFocusSession(...args))
    },
    [dispatch]
  )

  const completeCalendarTask = useCallback(
    (id, preventDefault) => {
      completeTask({ id, value: true })

      if (!preventDefault) {
        hideModalsAndPopups()
        setLocalEditingCalendarTask(null)
      }
    },
    [completeTask, hideModalsAndPopups]
  )

  const getCalendarRef = useCallback(() => {
    return calendarContainerRef?.current
  }, [calendarContainerRef])

  const replaceFocusSessionInState = useCallback(
    (focusSession) => {
      updateFocusSessionInState(focusSession, (taskFocusSessions) => {
        const focusSessionIdx = taskFocusSessions.findIndex((fs) => fs.id === focusSession.id)

        if (focusSessionIdx < 0) {
          console.warn('-- focus session not found')
          return taskFocusSessions
        }

        taskFocusSessions[focusSessionIdx] = focusSession
        return taskFocusSessions
      })
    },
    [updateFocusSessionInState]
  )

  const handleFocusSessionUpdate = useCallback(
    (focusSession) => {
      const updatingItem = { ...focusSession }
      const { startTime: start, endTime: end, title } = updatingItem
      let itemRollbackUpdates = { ...clickedItem, title }

      const spentTimeNS = utils.focussession.getSpentTimeInNanoSeconds(updatingItem)
      if (spentTimeNS < utils.focussession.minDurationFSNS) {
        onClickDeleteFocusSession(updatingItem)
        return
      }

      if (title !== clickedItem.title) {
        dispatch(patchTaskRequest(focusSession.taskId, { title }, {}))
      }

      if (moment(end).isAfter(moment())) {
        alert(translations.calendar.alerts.futureFs)
        dispatch(
          updateCalendarEvent({
            idToUpdate: focusSession.id,
            item: itemRollbackUpdates,
          })
        )
        replaceFocusSessionInState(itemRollbackUpdates)
        handleUpdateClickedItem(itemRollbackUpdates)
        return
      }

      moveFocusSession({ item: updatingItem, start, end }, (updatedFocusSession) => {
        replaceFocusSessionInState(updatedFocusSession)
        dispatch(updateCalendarDate(new Date(updatedFocusSession.startTime)))
        handleUpdateClickedItem(updatingItem)
      })
    },
    [
      clickedItem,
      dispatch,
      handleUpdateClickedItem,
      moveFocusSession,
      onClickDeleteFocusSession,
      replaceFocusSessionInState,
    ]
  )

  const debouncedSaveTask = useMemo(
    () =>
      debounce((task) => {
        saveTask(task)
        setClickedItem({ ...task }) // force recomputing of popup's position without blinking
      }, 500),
    [saveTask]
  )

  const handleTaskUpdate = useCallback(
    (task, shouldDebounce) => {
      if (clickedItem?.id === task.id) {
        setClickedItem(task)
      }

      if (isEditItemPopupShown && task?.when?.date && task?.when?.date !== clickedItem?.when?.date) {
        setCalendarDate(task.when.date)
        updateScrollToTime?.(task?.pin?.time ? task.pin.time : task.when.date)
      }

      if (shouldDebounce) {
        debouncedSaveTask(task)
      } else {
        saveTask(task)
        setTimeout(() => {
          setClickedItem({ ...task })
        })
      }
    },
    [clickedItem?.id, clickedItem?.when?.date, debouncedSaveTask, isEditItemPopupShown, saveTask, updateScrollToTime]
  )

  const debouncedEditSprint = useMemo(
    () =>
      debounce((sprint) => {
        editSprint(sprint)
        setClickedItem({ ...sprint }) // force recomputing of popup's position without blinking
      }, 500),
    [editSprint]
  )

  const handleSprintUpdate = useCallback(
    (sprint, shouldDebounce) => {
      if (clickedItem?.id === sprint.id) {
        setClickedItem(sprint)
      }

      if (isEditItemPopupShown && sprint?.when?.date && sprint?.when?.date !== clickedItem?.when?.date) {
        setCalendarDate(sprint.when.date)
        updateScrollToTime?.(sprint.pin.time)
      }

      if (shouldDebounce) {
        debouncedEditSprint(sprint)
      } else {
        editSprint(sprint)
        setTimeout(() => {
          setClickedItem({ ...sprint })
        })
      }
    },
    [
      clickedItem?.id,
      clickedItem?.when?.date,
      debouncedEditSprint,
      editSprint,
      isEditItemPopupShown,
      updateScrollToTime,
    ]
  )

  const handleEditingItemUpdate = useCallback(
    (item, e) => {
      const shouldDebounce = !!e?.key

      if (item.type === models.item.type.FOCUSSESSION) {
        handleFocusSessionUpdate(item)
      }

      if (item.type === models.item.type.TASK) {
        handleTaskUpdate(item, shouldDebounce)
      }

      if (item.type === models.item.type.SPRINT) {
        handleSprintUpdate(item, shouldDebounce)
      }
    },
    [handleFocusSessionUpdate, handleSprintUpdate, handleTaskUpdate]
  )

  const registerShortcuts = useCallback(
    (...args) => {
      dispatch(pushShortcutsGroup(...args))
    },
    [dispatch]
  )

  const unregisterShortcuts = useCallback(
    (...args) => {
      dispatch(popShortcutsGroup(...args))
    },
    [dispatch]
  )

  const onCreateTask = useCallback(
    (task, { componentSource }) => {
      onCreateCalendarTask(task, { componentSource })
      hideModalsAndPopups()
    },
    [hideModalsAndPopups, onCreateCalendarTask]
  )

  const onSaveTask = useCallback(
    (task) => {
      if (localEditingCalendarTask) {
        setLocalEditingCalendarTask(null)
      }
      onSaveCalendarTask(task, { componentSource: 'calendarPanel' })
      hideModalsAndPopups()
    },
    [localEditingCalendarTask, onSaveCalendarTask, hideModalsAndPopups]
  )

  const onCancelTaskAction = useCallback(() => {
    if (localEditingCalendarTask) {
      setLocalEditingCalendarTask(null)
    }
    hideModalsAndPopups()
    cancelCalendarTaskAction()
  }, [cancelCalendarTaskAction, hideModalsAndPopups, localEditingCalendarTask])

  const onCurrentTaskChangeDelay = useRef()

  const onCurrentTaskChange = useCallback(
    (updatedTask) => {
      if (!selectedSlot) return

      const updateSelectedSlot = () => {
        if (localEditingCalendarTask) {
          refreshLocalEditingCalendarTask(updatedTask)
        }

        const updatedSlot = { ...selectedSlot, item: updatedTask }
        const momentWhenDate = moment(updatedTask?.when?.date)

        if (
          updatedTask?.when?.date &&
          moment(updatedSlot.start).format('YYYY-MM-DD') !== momentWhenDate.format('YYYY-MM-DD')
        ) {
          dispatch(updateCalendarDate(momentWhenDate.toDate()))
        }

        if (updatedTask.sprintInfo) {
          setSelectedSlot(updatedSlot)
          return
        }

        if (updatedTask.pin?.time && updatedTask.estimatedTime) {
          updatedSlot.allDay = false
          updatedSlot.start = moment(updatedTask.pin.time).toDate()
          updatedSlot.end = moment(updatedTask.pin.time)
            .add(utils.datetime.convertNanosecondsToMinute(updatedTask.estimatedTime), 'minutes')
            .toDate()
        } else if (updatedTask.when?.date) {
          updatedSlot.allDay = true
          updatedSlot.start = moment(updatedTask.when.date).toDate()
          updatedSlot.end = moment(updatedTask.when.date).add(30, 'minutes').toDate()
        }

        setSelectedSlot(updatedSlot)
      }

      // delay updates to the calendar's pinned task, 80ms works best to prevent lag on input changes
      window.clearTimeout(onCurrentTaskChangeDelay.current)
      onCurrentTaskChangeDelay.current = setTimeout(updateSelectedSlot, 80)
    },
    [dispatch, localEditingCalendarTask, refreshLocalEditingCalendarTask, selectedSlot]
  )

  const onCreateSprint = useCallback(
    (sprint) => {
      _onCreateSprint(sprint)
      hideModalsAndPopups()
    },
    [_onCreateSprint, hideModalsAndPopups]
  )

  const startTaskCreation = useCallback(() => {
    if (addItemPopupPositionProps) {
      setPrevEditingPopupPositionProps(addItemPopupPositionProps)
    }

    setIsAddItemPopupShown(false)
    setIsEditItemPopupShown(false)
    setIsTaskLineModalShown(true)
  }, [addItemPopupPositionProps])

  const onTogglePin = useCallback(
    ({ item, isCreating }) => {
      if (selectedSlot && !localEditingCalendarTask) {
        if (!selectedSlot.allDay) {
          const newItem = {
            ...item,
            estimatedTime: 0,
            when: { date: moment(selectedSlot.start).format('YYYY-MM-DD') },
          }

          delete newItem.pin
          syncItemWithSelectedSlot(newItem)
          setTimeout(() => {
            showAddItemPopup()
          })
          return
        } else {
          const time = utils.task.computeTimeForToggledPin(selectedSlot.start)
          const newItem = {
            ...item,
            estimatedTime: utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 }),
            pin: {
              time: time.format(),
            },
            when: {
              date: time.format('YYYY-MM-DD'),
            },
          }

          setCalendarDate(newItem.when.date)
          updateScrollToTime?.(time.toDate())
          syncItemWithSelectedSlot(newItem)
          setTimeout(() => {
            showAddItemPopup()
          })
          return
        }
      }

      if (item?.pin?.time) {
        if (localEditingCalendarTask) {
          const newItem = { ...item }
          newItem.estimatedTime = 0
          delete newItem.pin
          setLocalEditingCalendarTask(newItem)
          syncItemWithSelectedSlot(newItem)
        } else {
          const updatedTask = utils.task.computeTaskOnChange(
            item,
            { paramName: 'pin.time', value: null },
            {
              estimatedTime: null,
            }
          )
          hideModalsAndPopups()
          onTogglePinFromCalendarPanel({ item: updatedTask, isCreating })
        }
      } else {
        if (!localEditingCalendarTask) return

        const newItem = { ...item }
        const time = utils.task.computeTimeForToggledPin(localEditingCalendarTask.when?.date)
        newItem.pin = { time: time.format() }
        newItem.estimatedTime = utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 })
        setLocalEditingCalendarTask(newItem)
        syncItemWithSelectedSlot(newItem)
      }
    },
    [
      hideModalsAndPopups,
      localEditingCalendarTask,
      onTogglePinFromCalendarPanel,
      selectedSlot,
      showAddItemPopup,
      syncItemWithSelectedSlot,
      updateScrollToTime,
    ]
  )

  const onDragOut = useCallback(
    async (event) => {
      const {
        extendedProps: { item },
      } = event

      const updatedTask = utils.task.computeTaskOnChange(
        item,
        { paramName: 'pin.time', value: null },
        {
          estimatedTime: null,
        }
      )

      dispatch(setEventsLocked(true))
      dispatch(updateItem(updatedTask))

      try {
        await taskApi.putFullTask(updatedTask)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err))
      }
      dispatch(setEventsLocked(false))
    },
    [dispatch]
  )

  const filteredCalendarItems = useMemo(() => {
    let filteredItems = calendarItems.filter((event) => {
      const isCurrentCreatingTask = creatingCalendarTask && event.id === creatingCalendarTask.id
      const isCurrentEditingSprint = editingSprint && event.id === editingSprint.id
      const editingTask = editingCalendarTask || localEditingCalendarTask
      const isCurrentEditingTask = editingTask && event.id === editingTask.id
      return !isCurrentCreatingTask && !isCurrentEditingTask && !isCurrentEditingSprint
    })

    if (selectedSlot) {
      filteredItems = filteredItems.concat([computePlaceholderSlot(selectedSlot)])
    }

    return filteredItems
  }, [
    calendarItems,
    computePlaceholderSlot,
    creatingCalendarTask,
    editingCalendarTask,
    editingSprint,
    localEditingCalendarTask,
    selectedSlot,
  ])

  return {
    addItemPopupRef,
    addItemPopupPositionProps,
    calendarDate,
    calendarRef,
    clickedItem,
    completeCalendarTask,
    computePlaceholderSlot,
    computeSlotFromSprint,
    editItemPopupRef,
    filteredCalendarItems,
    getCalendarRef,
    getSelectedSlotBounds,
    handleEditingItemUpdate,
    handleUpdateClickedItem,
    hideModalsAndPopups,
    isAddItemPopupShown,
    isEditItemPopupShown,
    isTaskLineModalShown,
    localEditingCalendarTask,
    onCancelTaskAction,
    onChangeAddCalendarItemTitle,
    onChangeDuration,
    onChangeSlotRange,
    onChangeStartDate,
    onChangeStartTime,
    onClickDeleteClickedItem,
    onClickDeleteSprint,
    onClickDeleteTask,
    onClickEvent,
    onClickLeftArrow,
    onClickOutsideCalendarModalOrPopup,
    onClickRemoveTask,
    onClickRightArrow,
    onClickToday,
    onCreateSprint,
    onCreateTask,
    onCurrentTaskChange,
    onDragOut,
    onEventDropMiddleware,
    onEventResize,
    onSaveTask,
    onSelectSlot,
    onTogglePin,
    prevEditingPopupPositionProps,
    selectedSlot,
    setCalendarDate,
    setSelectedSlot,
    startFocusSession,
    startLocalCalendarItemEdition,
    startSprintCreation,
    startTaskCreation,
    registerShortcuts,
    unregisterShortcuts,
  }
}
