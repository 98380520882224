import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ActivePage from './active'

const mapStateToProps = (state) => ({
  areProjectsLoaded: state.projects.areProjectsLoaded,
  areTagsLoaded: state.tags.areTagsLoaded,
  areEventsLoaded: state.calendar.items.fetchStatus.areLoaded,
})

class TodayLayout extends PureComponent {
  render() {
    const { areProjectsLoaded, areTagsLoaded, areEventsLoaded } = this.props
    if (!areProjectsLoaded || !areTagsLoaded || !areEventsLoaded) return null
    return (
      <TodayLayoutWrapper>
        <ActivePage />
      </TodayLayoutWrapper>
    )
  }
}

const TodayLayoutWrapper = styled.div`
  position: relative;
`

export default connect(mapStateToProps, null, null)(TodayLayout)
