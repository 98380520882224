import axios from 'utils/axios'

export const select = (email, calendarId) => {
  const encodedCalendarId = encodeURIComponent(calendarId)
  let endpoint = `/calendar-list/select/${email}/${encodedCalendarId}`
  return axios.post(endpoint)
}

export const unselect = (email, calendarId) => {
  const encodedCalendarId = encodeURIComponent(calendarId)
  let endpoint = `/calendar-list/unselect/${email}/${encodedCalendarId}`
  return axios.post(endpoint)
}

export const selectDefault = (email, calendarId) => {
  const encodedCalendarId = encodeURIComponent(calendarId)
  let endpoint = `/calendar-list/select-default-write/${email}/${encodedCalendarId}`
  return axios.post(endpoint)
}
