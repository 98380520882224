import React, { Component } from 'react'
import Dropdown from 'Dropdown'
import moment from 'moment'

class DurationDropdown extends Component {
  constructor() {
    super()

    const maxHours = 8
    const minutesInHour = 60
    const maxDuration = minutesInHour * maxHours
    this.options = []
    for (let i = 15; i <= maxDuration; i += 15) {
      /* after 40mins, only display every 30mins */
      if (i > 45 && i % 30 !== 0) {
        continue
      }

      /* after 3h00, only display every hour */
      if (i > 180 && i % 60 !== 0) {
        continue
      }
      let label = ''
      let duration = moment.duration(i, 'minutes')
      if (duration.hours() > 0) {
        label += duration.hours() + ':'
      }

      label += duration.minutes()
      if (duration.minutes() === 0) {
        label += '0h' /* to display 1:00h */
      }
      if (duration.hours() === 0) {
        label += 'mins'
      }
      this.options.push({ label, value: i * 60 })
    }
  }

  render() {
    return <Dropdown {...this.props} className="dropdown-duration" hideArrow={true} options={this.options} />
  }
}

export default DurationDropdown
