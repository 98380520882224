import React, { useState } from 'react'
import styled from 'styled-components'
import { produce } from 'immer'
import parser from 'react-html-parser'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, styles, translations } from 'gipsy-misc'
import { SimpleButton } from 'gipsy-ui'

import DeleteReasonOption from './components/DeleteReasonOption'

export default function AccountDeletePanel({ onContinue, onDismiss }) {
  const [feedback, setFeedback] = useState('')
  const [hasSelectedReason, setHasSelectedReason] = useState(false)
  const [reasonsMap, setReasonsMap] = useState(
    Object.keys(translations.settings.section.deletePanel.reasons || {}).reduce((currentReasons, id) => {
      currentReasons[id] = {
        text: translations.settings.section.deletePanel.reasons[id],
        value: false,
      }

      return currentReasons
    }, {})
  )

  const handleOptionToggled = ({ id, value }) => {
    setReasonsMap((prev) =>
      produce(prev, (draft) => {
        draft[id].value = value
      })
    )

    if (!hasSelectedReason) {
      setHasSelectedReason(true)
    }
  }

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value)
  }

  const handleContinue = () => {
    const deleteReasons = Object.keys(reasonsMap).reduce((deleteReasons, id) => {
      if (reasonsMap[id].value) {
        deleteReasons.push(reasonsMap[id].text)
      }

      return deleteReasons
    }, [])

    const params = {
      deleteFeedback: feedback || undefined,
      deleteReason: deleteReasons.length ? deleteReasons : undefined,
    }
    onContinue?.(params)
  }

  const trackBookTimeLinkClicked = (e) => {
    if (e.target.id === 'booktime__link') {
      mixpanelApi.track({ event: mixpanel.deleteBookTimeClickedEvent })
    }
  }

  const renderOptions = () =>
    Object.keys(reasonsMap).map((id) => {
      const { text, value } = reasonsMap[id]

      return (
        <DeleteReasonOption active={value} key={id} onClick={handleOptionToggled} optionId={id} optionText={text} />
      )
    })

  return (
    <Container>
      <Title>{translations.settings.section.deletePanel.title}</Title>
      <Subtitle>{translations.settings.section.deletePanel.subtitle}</Subtitle>
      <ReasonsContainer>{renderOptions()}</ReasonsContainer>
      <FeedbackContainer>
        <FeedbackWrapper>
          <FeedbackInput
            maxLength={255}
            onChange={handleFeedbackChange}
            placeholder={translations.settings.section.deletePanel.feedbackPlaceholder}
            value={feedback}
          />
          {feedback && (
            <CharactersIndicator>
              {translations.settings.section.deletePanel.maxCharsIndicator.replace('[n]', 255 - feedback.length)}
            </CharactersIndicator>
          )}
        </FeedbackWrapper>
      </FeedbackContainer>
      {hasSelectedReason && (
        <BookTime onClick={trackBookTimeLinkClicked}>
          {parser(translations.settings.section.deletePanel.bookTime)}
        </BookTime>
      )}
      <ButtonsContainer>
        <DismissButton
          backgroundColor='white'
          borderColor={styles.colors.primaryColor}
          borderRadius={16}
          height={40}
          onClick={onDismiss}
          text={translations.settings.section.deletePanel.dismiss}
          textColor={styles.colors.primaryColor}
          width={132}
        />
        <ContinueButton
          borderRadius={16}
          height={40}
          onClick={handleContinue}
          text={translations.settings.section.deletePanel.continue}
          width={157}
        />
      </ButtonsContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 53px 62px 20px;
`

Container.displayName = 'Container'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0 0 16px;
  text-align: center;
`

Title.displayName = 'Title'

const Subtitle = styled.p`
  letter-spacing: 0;
  margin: 0 0 20px;
  text-align: center;
`

Subtitle.displayName = 'Subtitle'

const ReasonsContainer = styled.div`
  display: flex;
  flex-flow: column;
`

ReasonsContainer.displayName = 'ReasonsContainer'

const FeedbackContainer = styled.div`
  margin: 16px 0 20px;
`

FeedbackContainer.displayName = 'FeedbackContainer'

const FeedbackWrapper = styled.div`
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  height: 72px;
  position: relative;
`

FeedbackWrapper.displayName = 'FeedbackWrapper'

const FeedbackInput = styled.textarea`
  border: none;
  border-radius: 8px;
  height: 44px;
  line-height: 22px;
  padding: 8px 26px 0 12px;
  scrollbar-width: none;
  width: 100%;

  && {
    font-size: 15px;
  }

  ::placeholder {
    color: ${styles.colors.darkGrey};
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

FeedbackInput.displayName = 'FeedbackInput'

const CharactersIndicator = styled.span`
  bottom: 8px;
  color: ${styles.colors.darkGrey};
  display: block;
  font-size: 10px;
  line-height: 16px;
  position: absolute;
  right: 8px;
`

CharactersIndicator.displayName = 'CharactersIndicator'

const BookTime = styled.p`
  font-weight: 500;
  letter-spacing: 0;
  margin: 0 auto 16px;
  text-align: center;

  a {
    color: ${styles.colors.primaryColor};
    text-decoration: none;
  }
`

BookTime.displayName = 'BookTime'

const ButtonsContainer = styled.div`
  display: flex;
`

ButtonsContainer.displayName = 'ButtonsContainer'

const DismissButton = styled(SimpleButton)`
  margin: 0 15px 0 auto;
`

DismissButton.displayName = 'DismissButton'

const ContinueButton = styled(SimpleButton)`
  margin: 0 auto 0 15px;
`

ContinueButton.displayName = 'ContinueButton'
