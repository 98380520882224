import moment from 'moment'

import injected from 'injected'

const comment = (text) => ({
  id: null,
  from: {
    userId: injected.user.id,
    picture: injected.user.picture,
    displayName: injected.user.displayName,
  },
  creationTime: moment().format(),
  richText: text,
})

export default comment
