import moment from 'moment'

import { isBeforeDateStr } from 'utils/datetime'
import { isTimeBefore } from 'utils/task'

export const getListTotalDuration = (list) => {
  let total = 0

  for (let element of list) {
    if (!element || element.free) {
      continue
    }
    total += element.estimatedTime
  }

  return total
}

export const getFilteredTasksOfGroup = (taskGroup, filterBy = {}, toHideTaskIds = {}) => {
  if (!taskGroup) {
    return taskGroup
  }
  let filteredTasks = [...taskGroup]

  if (Object.keys(toHideTaskIds).length > 0) {
    filteredTasks = filteredTasks.filter((task) => !toHideTaskIds[task.id])
  }

  if (filterBy.projects && filterBy.projects.length > 0) {
    filteredTasks = filteredTasks.filter((task) => {
      if (task.projectsID) {
        for (let i = 0; i < task.projectsID.length; i++) {
          if (filterBy.projects.includes(task.projectsID[i])) return true
        }
      }
      return false
    })
  }
  if (filterBy.tags && filterBy.tags.length > 0) {
    filteredTasks = filteredTasks.filter((task) => {
      if (task.tagsId) {
        for (let i = 0; i < task.tagsId.length; i++) {
          if (filterBy.tags.includes(task.tagsId[i])) return true
        }
      }
      return false
    })
  }
  return filteredTasks
}

export const sortListByConditionFn = (list = [], conditionFn) => {
  for (let i = 1; i < list.length; i++) {
    for (let j = 0; j < i; j++) {
      if (conditionFn(list[j], list[i])) {
        ;[list[j], list[i]] = [list[i], list[j]]
      }
    }
  }

  return list
}

const isBefore = (item1, item2) => {
  switch (true) {
    case item1.when.date !== item2.when.date:
      return isBeforeDateStr(item1.when.date, item2.when.date)
    case !!(item1.pin?.time && item2.pin?.time):
      return isTimeBefore(item1, item2)
    case !!(item1.pin?.time || item2.pin?.time):
      return !!item1.pin?.time
    default:
      return moment(item1.creationTime).isBefore(moment(item2.creationTime))
  }
}

export const sortListByScheduledTime = (list) => sortListByConditionFn(list, (a, b) => !isBefore(a, b))
