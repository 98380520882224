import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { styles, translations, utils, variables } from 'gipsy-misc'

import { SimpleButton } from 'ReusableButton/Buttons'
import { IconContainer, LabelContainer, PinInfoGroup, SprintContainer } from 'Sprint/DropdownSelector'
import SprintSelect from 'Sprint/SprintSelect'

import { CardAttribute } from './commonUIComponents'

const sprintColor = styles.colors.sprintFill

export class SprintWrapper extends PureComponent {
  state = {
    dropdownPosition: {
      left: 0,
      top: 0,
    },
    sprintDropdownShown: true,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)

    if (this.props.value?.id) {
      this.setState({ sprintDropdownShown: false })
    }

    this.setState({
      dropdownPosition: { ...this.computeDropdownPosition() },
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sprintDropdownShown && !this.state.sprintDropdownShown) {
      this.setState({
        dropdownPosition: {
          left: 0,
          top: 0,
        },
      })
    }
  }

  showSprintsDropdown = (value) => {
    this.setState(
      {
        sprintDropdownShown: value,
      },
      () => {
        if (value) {
          this.setState({
            dropdownPosition: { ...this.computeDropdownPosition() },
          })
        }
      }
    )
  }

  startSprintCreationWithSettings = () => {
    this.props.startSprintCreation &&
      this.props.startSprintCreation({
        sprintActionsCallbacks: {
          onCreateSprintCallback: this.onCreateSprint,
        },
      })
    this.showSprintsDropdown(false)
  }

  handleClickOutside = (e) => {
    const { sprintDropdownShown } = this.state
    if (sprintDropdownShown) {
      const clickedOutDropdown = this.dropdown && !this.dropdown.contains(e.target)
      const clickedOutFieldNode = this.fieldNode && !this.fieldNode.contains(e.target)
      if (clickedOutDropdown && clickedOutFieldNode) {
        this.showSprintsDropdown(false)
      }
    }
  }

  onChangeClicked = () => {
    this.showSprintsDropdown(true)
  }

  onCreateSprint = async (newSprint) => {
    this.props.onChange(newSprint)
  }

  computeDropdownPosition = () => {
    const { getLineBounds } = this.props
    const lineBounds = getLineBounds?.()

    if (this.props.inChromeExtension || !this.state.sprintDropdownShown || !this.dropdown || !lineBounds) {
      return { left: 0, top: 0 }
    }

    const { bottom, height, right } = this.dropdown.getBoundingClientRect()
    const { right: lineRightPosition } = lineBounds
    const { left: prevLeft, top: prevTop } = this.state.dropdownPosition

    let left = prevLeft
    if (right > lineRightPosition) {
      left = lineRightPosition - right
    } else {
      left = Math.min(prevLeft, 0)
    }

    let top = prevTop
    if (bottom >= window.innerHeight) {
      top = 0 - height
    }

    return { left: Math.min(left, prevLeft), top }
  }

  verifyPositionOnRender = () => {
    setTimeout(() => {
      if (!this.dropdown) return

      const { top } = this.dropdown.getBoundingClientRect()

      if (top < 0) {
        this.setState({ dropdownPosition: { ...this.state.dropdownPosition, top: 0 } })
      }
    })
  }

  handleChange = (data) => {
    this.props.onChange?.(data)

    if (data.id) {
      this.showSprintsDropdown(false)
    }
  }

  render() {
    const { maxWidth, value, activeSprintsMap, hideCreateButton, verticalView } = this.props
    const hasValue = value && value.id
    let sprint

    const { sprintDropdownShown } = this.state

    if (hasValue) {
      if (value.recurrencyInformation) {
        sprint = activeSprintsMap[value.recurrencyInformation.recurringSprintId].find((s) => s.id === value.id)
      } else {
        sprint = activeSprintsMap[value.id]
      }
    }

    this.verifyPositionOnRender()

    return (
      <StyledCardAttribute
        bordered={hasValue || (!sprint && !sprintDropdownShown)}
        focused={sprintDropdownShown}
        focusedColor={sprintColor}
        ref={(ref) => (this.fieldNode = ref)}
        vertical={verticalView}>
        {sprint && !sprintDropdownShown ? (
          <StyledSprintContainer>
            <StyledIconContainer fill={styles.colors.orangeColor} size={14} icon='Sprint' />
            <LabelContainer>
              <span>{sprint.title}</span>
              <PinInfoGroup
                color={
                  moment(sprint.pin.time).add(utils.datetime.convertNanosecondsToMinute(sprint.estimatedTime), 'm') <=
                  moment()
                    ? styles.colors.pinkColor
                    : undefined
                }>
                {`${utils.datetime.beautifyDateWithDay(sprint.when.date)} - ${utils.datetime.beautifyTime(
                  sprint.pin.time
                )}`}
              </PinInfoGroup>
            </LabelContainer>
            <ChangeButton height={32} text={translations.general.change} onClick={this.onChangeClicked} width={76} />
          </StyledSprintContainer>
        ) : sprintDropdownShown ? (
          <StyledSprintSelect
            setInnerRef={(ref) => (this.dropdown = ref)}
            inlineCreateButton={!hideCreateButton}
            onClickCreateButton={this.startSprintCreationWithSettings}
            hideCreateButton={hideCreateButton}
            value={value}
            activeSprintsMap={activeSprintsMap}
            onChange={this.handleChange}
            maxWidth={maxWidth}
            style={{
              left: `${this.state.dropdownPosition.left}px`,
              top: `${this.state.dropdownPosition.top}px`,
            }}
            width='100%'
          />
        ) : (
          <StyledSprintContainer>
            <StyledIconContainer fill={styles.colors.darkGrey} size={14} icon='Sprint' />
            <LabelContainer>
              <NoSprintTitleLabel>No Focus Block</NoSprintTitleLabel>
              <NoSprintSubtitleLabel>Select a Focus Block</NoSprintSubtitleLabel>
            </LabelContainer>
            <ChangeButton height={32} text={translations.general.select} onClick={this.onChangeClicked} width={76} />
          </StyledSprintContainer>
        )}
      </StyledCardAttribute>
    )
  }
}

const StyledSprintContainer = styled(SprintContainer)`
  padding: 14px 8px 14px 16px;
`

StyledSprintContainer.displayName = 'StyledSprintContainer'

const StyledCardAttribute = styled(CardAttribute)`
  border-color: transparent;
  height: 100%;
  min-height: 43px;
  min-width: 310px;

  ${({ bordered }) =>
    bordered &&
    css`
      border-color: ${styles.colors.greyBorderColor};
    `}

  ${({ vertical }) =>
    vertical &&
    css`
      min-width: 0;

      ${StyledSprintContainer} {
        padding: 10px;
      }
    `}
`

StyledCardAttribute.displayName = 'StyledCardAttribute'

const StyledIconContainer = styled(IconContainer)`
  flex-shrink: 0;

  ${({ centered }) =>
    centered &&
    css`
      align-self: center;
      margin-top: 0;
    `}
`

StyledIconContainer.displayName = 'StyledIconContainer'

const StyledSprintSelect = styled(SprintSelect)`
  position: absolute;
  z-index: ${variables.zIndex.linePopup};
`

StyledSprintSelect.displayName = 'StyledSprintSelect'

const ChangeButton = styled(SimpleButton)`
  background-color: ${styles.colors.veryLightGrey};
  border-color: ${styles.colors.veryLightGrey};
  color: ${styles.colors.textMediumDarkColor};
  font-size: 14px;
  margin-left: auto;
`

ChangeButton.displayName = 'ChangeButton'

const NoSprintTitleLabel = styled.span`
  color: ${styles.colors.textMediumDarkColor};
  font-size: 14px;
`

NoSprintTitleLabel.displayName = 'NoSprintTitleLabel'

const NoSprintSubtitleLabel = styled.span`
  color: ${styles.colors.darkGrey};
  font-size: 13px;
`

NoSprintSubtitleLabel.displayName = 'NoSprintSubtitleLabel'
