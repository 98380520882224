import moment from 'moment'

import { models, translations, utils } from 'gipsy-misc'

const { DailyScheduleOptions, Schedules } = models.recurrency

export const getRecurrencyLabel = ({ customTypeSettings, recurrencyType, sprintStartTime }) => {
  switch (recurrencyType) {
    case DailyScheduleOptions.NoRepeat: {
      return translations.sprint.recurrency.noRepeat
    }

    case DailyScheduleOptions.Daily: {
      return translations.sprint.recurrency.daily
    }

    case DailyScheduleOptions.WeeklyOnCurrentDay: {
      return translations.sprint.recurrency.weeklyOn.replace('__', sprintStartTime.format('dddd'))
    }

    case DailyScheduleOptions.EveryWeekday: {
      return translations.sprint.recurrency.everyWeekday
    }

    case DailyScheduleOptions.Custom: {
      return customTypeSettings?.days?.length
        ? utils.recurrency.details.getDaysChosenLabel(customTypeSettings.days)
        : translations.sprint.recurrency.noRepeat
    }

    default: {
      return ''
    }
  }
}

export const getRecurrencyDetails = ({ customTypeSettings, recurrencyType, sprintStartTime }) => {
  switch (recurrencyType) {
    case DailyScheduleOptions.NoRepeat: {
      return null
    }

    case DailyScheduleOptions.Daily: {
      return {
        schedule: Schedules.Daily,
        dailyOptions: {
          every: 1,
        },
      }
    }

    case DailyScheduleOptions.WeeklyOnCurrentDay: {
      return {
        schedule: Schedules.Weekly,
        weeklyOptions: {
          days: [Number.parseInt(sprintStartTime.format('d'))],
          every: 1,
        },
      }
    }

    case DailyScheduleOptions.EveryWeekday: {
      return {
        schedule: Schedules.Weekly,
        weeklyOptions: {
          days: [1, 2, 3, 4, 5],
          every: 1,
        },
      }
    }

    case DailyScheduleOptions.Custom: {
      if (!customTypeSettings?.days?.length) return null

      return {
        schedule: Schedules.Weekly,
        weeklyOptions: {
          days: customTypeSettings.days,
          every: 1,
        },
      }
    }

    default: {
      return null
    }
  }
}

export const getRecurrencyOptionsFromRecurrencyInformation = (sprint) => {
  const { recurrencyInformation, when } = sprint

  if (!recurrencyInformation) {
    return {
      customSettings: null,
      type: DailyScheduleOptions.NoRepeat,
    }
  }

  const { recurrencyDetails } = recurrencyInformation
  const { schedule, weeklyOptions } = recurrencyDetails

  if (schedule === Schedules.Daily) {
    return {
      customSettings: null,
      type: DailyScheduleOptions.Daily,
    }
  }

  const { days } = weeklyOptions

  if (days.length === 1 && Number.parseInt(moment(when.date).format('d')) === days[0]) {
    return {
      customSettings: null,
      type: DailyScheduleOptions.WeeklyOnCurrentDay,
    }
  }

  if (days.length === 5 && utils.integers.areElementsUniqueAndMatch(days, [1, 2, 3, 4, 5])) {
    return {
      customSettings: null,
      type: DailyScheduleOptions.EveryWeekday,
    }
  }

  return {
    customSettings: {
      days,
    },
    type: DailyScheduleOptions.Custom,
  }
}
