import React, { Fragment, useState, useCallback } from 'react'
import styled from 'styled-components'
import { getShouldHideFromLocalStorage, setShouldHideToLocalStorage } from 'features/shortcuts/utils'
import variables from 'assets/styles/variables'
import { styles, utils, variables as miscVariables } from 'gipsy-misc'
import { Icon, media } from 'gipsy-ui'

export default function Shortcuts(props) {
  const [isHidden, setHidden] = useState(getShouldHideFromLocalStorage())
  const { currentShortcuts, zIndex } = props
  const leftSideComponentWidth = props.isTaskPanelShown ? variables.taskPanelContainerWidth : variables.navbarWidth

  const _setHidden = () => {
    setShouldHideToLocalStorage(true)
    setHidden(true)
  }

  const setShown = () => {
    setShouldHideToLocalStorage(false)
    setHidden(false)
  }

  const getKeyLabel = useCallback((key) => {
    if (key.toLowerCase() === 'backspace') {
      return '← (BKSP)'
    } else {
      return key
    }
  }, [])

  return (
    <Fragment>
      <BoxContainer zIndex={zIndex} isShown={!isHidden} leftSideComponentWidth={leftSideComponentWidth}>
        <ShortcutsContainer>
          {currentShortcuts.map((shortcut) => (
            <React.Fragment key={utils.shortcut.getShortcutKey(shortcut)}>
              <ShortcutLabel>{shortcut.label}:</ShortcutLabel>
              {shortcut.shiftKey && (
                <>
                  <ComboKey>{'Shift'}</ComboKey>
                  <ComboConnect>{'+'}</ComboConnect>
                </>
              )}
              <RegularKey>{getKeyLabel(shortcut.key)}</RegularKey>
            </React.Fragment>
          ))}
        </ShortcutsContainer>
        <ToggleButton icon={'DoubleChevronUp'} inverse onClick={_setHidden} size={10} />
        <ToggleContainer isShown={isHidden} leftSideComponentWidth={leftSideComponentWidth}>
          <ToggleButton icon={'DoubleChevronUp'} onClick={setShown} size={10} />
        </ToggleContainer>
      </BoxContainer>
    </Fragment>
  )
}

const sideMargin = 200

const Key = styled.span`
  margin-left: 10px;
  min-width: fit-content;
  height: 18px;
  line-height: 12px;
  padding: 2px 8px 4px;
  background: ${styles.colors.darkGrey};
  border-radius: 4px;
  color: ${styles.colors.veryLightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
`

const ToggleButton = styled(Icon)`
  transform: rotate(${(props) => (props.inverse ? 180 : 0)}deg);
  cursor: pointer;
  align-self: center;
`

const RegularKey = styled(Key)`
  :not(:last-child) {
    margin-right: 30px;
    width: 24px;
  }
`
const ComboConnect = styled.span`
  margin: 0 6px;
  font-size: ${styles.fonts.fontSizeSmall};
  & + ${RegularKey} {
    margin-left: 0;
  }
`

const ComboKey = styled(Key)`
  align-items: center;
`

const ShortcutsContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
  & + ${ToggleButton} {
    margin-left: 20px;
  }
`

const ShortcutLabel = styled.span`
  flex-shrink: 0;
`

const BoxContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  height: ${variables.shortcutsBarHeight}px;
  background-color: rgba(236, 235, 240, 0.81);
  backdrop-filter: blur(2px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: ${styles.colors.darkGrey};
  padding: 0 20px;
  z-index: ${(props) => (isNaN(parseInt(props.zIndex)) ? miscVariables.zIndex.shortcutsBar : props.zIndex)};
  font-weight: 500;
  font-size: 12px;
  bottom: 0;
  min-width: fit-content;
  width: calc(
    100% - ${(props) => parseInt(variables.calendarPanelWidth, 10) + props.leftSideComponentWidth + sideMargin * 2}px
  );
  left: calc(${(props) => props.leftSideComponentWidth + sideMargin}px);
  transform: translateY(${(props) => (props.isShown ? 0 : 100)}%);
  transition: transform 0.3s ease-in-out;
  ${media['tablet-wide']`
     left: calc(${sideMargin}px);
     width: calc(100% - ${parseInt(variables.calendarPanelWidth, 10) + sideMargin * 2}px);
  `}

  ${media['tablet']`
     display: none;
  `}
`
const ToggleContainer = styled(BoxContainer)`
  position: absolute;
  width: auto;
  transform: translate(-100%, ${(props) => (props.isShown ? -100 : 100)}%);
  left: 100%;
  padding: 0 20px;
  ${media['tablet-wide']`
     left: 100%;
  `};
`
