import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'

function CalendarPage({ location }) {
  const areEventsLoaded = useSelector((state) => state.calendar.items.fetchStatus.areLoaded)
  const areItemsLoaded = useSelector((state) => state.items.areItemsLoaded)
  const areProjectsLoaded = useSelector((state) => state.projects.areProjectsLoaded)
  const areTagsLoaded = useSelector((state) => state.tags.areTagsLoaded)

  if (!areEventsLoaded || !areItemsLoaded || !areProjectsLoaded || !areTagsLoaded) return null

  return (
    <Switch location={location}>
      <Route exact path="/index.html" />
      <Route path="/" />
    </Switch>
  )
}

export default withRouter(CalendarPage)
