import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { getPageSourceFromRoute } from 'features/source'
import { getCurrentPathName } from 'utils/location'
import { showSnackbar } from 'store/snackbar/actions'
import { startFocusSession, openUrls } from 'store/session/actions'
import { setHighlightedEventId } from 'store/calendar/actions'
import { pushShortcutsGroup, popShortcutsGroup } from 'store/shortcuts/actions'
import { RightButtonsContainer } from 'features/list/components/commonUI'
import { getActiveSprintsMapByScheduledTimeAndGroupedByRecurrence } from 'store/items/selectors'

import { CreatingLineComponents, Icon, Line, LinePropTypes, SimpleButton, StartAndCreateButton } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

const { CreatingLineContainer } = CreatingLineComponents

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      startFocusSession,
      setHighlightedEventId,
      openUrls,
      showSnackbar,
      registerShortcuts: pushShortcutsGroup,
      unregisterShortcuts: popShortcutsGroup,
    },
    dispatch
  ),
})

const mapStateToProps = (state) => ({
  session: state.session,
  activeProjects: state.projects.items,
  activeTags: state.tags.items,
  activeSprintsMap: getActiveSprintsMapByScheduledTimeAndGroupedByRecurrence(state.items),
})

class HomebaseLine extends PureComponent {
  static propTypes = {
    ...LinePropTypes,
  }
  renderStartButton = (onStartFsAndCreate, shrinkStartButton) => {
    const { session } = this.props
    const focusSession = session.focusSession
    const shouldRender = !focusSession || !focusSession.id
    if (!shouldRender) return null
    else return <StartAndCreateButton margin='8px 0 0' onClick={onStartFsAndCreate} shrinked={shrinkStartButton} />
  }

  renderCreateBottomButtons = (data) => (
    <>
      {this.props.hasStartFsAndCreateButton && this.renderStartButton(data.onStartFsAndCreate, data.shrinkStartButton)}
      <RightButtonsContainer>
        <SimpleButton
          width={100}
          height={32}
          backgroundColor={styles.colors.veryLightGrey}
          textColor={styles.colors.textMediumDarkColor}
          onClick={data.onCancel}
          text={translations.general.cancel}
        />
        <SimpleButton
          width={100}
          height={32}
          backgroundColor={styles.colors.primaryColor}
          textColor={'white'}
          onClick={data.onCreate}
          text={translations.line.addTask}
        />
      </RightButtonsContainer>
    </>
  )

  renderEditBottomButtons = (data) => (
    <React.Fragment>
      <DeleteButton fill={styles.colors.darkGrey} icon='Trash' onClick={data.onDelete} size={16} />
      <RightButtonsContainer>
        <SimpleButton
          width={100}
          height={32}
          backgroundColor={styles.colors.veryLightGrey}
          textColor={styles.colors.textMediumDarkColor}
          onClick={data.onCancelEdit}
          text={translations.general.cancel}
        />
        <SimpleButton
          width={100}
          height={32}
          backgroundColor={styles.colors.primaryColor}
          textColor={'white'}
          onClick={data.onSave}
          text={translations.general.save}
        />
      </RightButtonsContainer>
    </React.Fragment>
  )

  saveRef = (ref) => {
    if (ref && ref.el && ref.el.node && this.isTaskOnFocusSession()) {
      ref.el.node.scrollIntoView({
        block: 'center',
        inline: 'center',
      })
    }
    this.props.setLineRef && this.props.setLineRef(ref)
  }

  isTaskOnFocusSession = () => {
    const { session, item } = this.props
    const focusedTaskId = session.focusSession && session.focusSession.taskId
    return focusedTaskId && item && focusedTaskId === item.id
  }

  handleStartFocusSession = (data) => {
    const context = {
      componentSource: this.props.componentSource,
      pageSource: this.props.pageSource || getPageSourceFromRoute(this.props.location.pathname),
    }
    this.props.actions.startFocusSession(data, context)
  }

  render() {
    const {
      flippedProps,
      startHover,
      endHover,
      activeTags,
      activeProjects,
      activeSprintsMap,
      isInlineSprintTask,
      insideSprintHorizontalMargin,
    } = this.props

    return (
      <Container
        {...flippedProps}
        isInlineSprintTask={isInlineSprintTask}
        insideSprintHorizontalMargin={insideSprintHorizontalMargin}
        onMouseEnter={startHover}
        onMouseLeave={endHover}>
        <Line
          {...this.props}
          ref={this.saveRef}
          activeTags={activeTags}
          allProjects={activeProjects}
          activeSprintsMap={activeSprintsMap}
          renderCreateBottomButtons={this.renderCreateBottomButtons}
          renderEditBottomButtons={this.renderEditBottomButtons}
          getCurrentPathName={getCurrentPathName}
          onCreate={this.props.onCreate}
          onSave={this.props.onSave}
          onCancelEdit={this.props.onCancelEdit}
          startFocusSession={this.handleStartFocusSession}
          openUrls={this.props.actions.openUrls}
          showSnackbar={this.props.actions.showSnackbar}
          registerShortcuts={this.props.actions.registerShortcuts}
          unregisterShortcuts={this.props.actions.unregisterShortcuts}
          setHighlightedEventId={this.props.actions.setHighlightedEventId}
        />
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: true })(withRouter(HomebaseLine))

const Container = styled.div`
  position: relative;

  ${({ isInlineSprintTask, insideSprintHorizontalMargin }) =>
    isInlineSprintTask &&
    css`
      margin: 0 ${insideSprintHorizontalMargin || 32}px 8px;

      ${CreatingLineContainer} {
        :hover {
          border-color: ${styles.colors.orangeColor};
          color: ${styles.colors.orangeColor};

          .icon {
            path {
              fill: ${styles.colors.orangeColor};
            }
            rect {
              stroke: ${styles.colors.orangeColor};
            }
          }
        }
      }
    `}
`

const DeleteButton = styled(Icon)`
  margin-left: 6px;
  margin-top: 8px;
`

DeleteButton.displayName = 'DeleteButton'
