import { storageAvailable } from 'utils/storage'

const authorizedUtmTags = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

const prefix = 'KTLoginUTM_'

export const clearUTMInfoFromLocalStorage = () => {
  if (storageAvailable('localStorage')) {
    for (let utm of authorizedUtmTags) {
      window.localStorage.removeItem(prefix + utm)
    }
  }
}

export const writeUTMInfoToLocalStorage = (searchParams) => {
  if (storageAvailable('localStorage')) {
    for (let utm of authorizedUtmTags) {
      if (searchParams[utm]) {
        window.localStorage.setItem(prefix + utm, searchParams[utm])
      }
    }
  }
}

export const getUTMInfoFromLocalStorage = () => {
  const utms = {}
  if (storageAvailable('localStorage')) {
    for (let utm of authorizedUtmTags) {
      const value = window.localStorage.getItem(prefix + utm)
      if (value) {
        utms[utm] = value
      }
    }
  }
  return utms
}
