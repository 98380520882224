import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { models, styles, translations, utils } from 'gipsy-misc'

import Icon from 'Icon'
import { CustomRecurrenceSettingsPopup, recurrenceComponentsUtils, RecurrencePresetsPopup } from 'Recurrence'
import TimeRange from 'TimeRange'
import Tooltip from 'Tooltip'

import { DateWrapper } from './DateWrapper'

const { DailyScheduleOptions } = models.recurrency

export default function ScheduleMode({
  firstDayOfWeek,
  getLineBounds,
  inChromeExtension,
  isAsanaTask,
  isCreating,
  item,
  onDateChange,
  onPinChange,
  onRecurrenceChange,
  onTogglePin,
  readOnlyDate,
  selectedRecurrency,
  shouldShrinkAttributes,
  verticalView,
}) {
  const [isRecurrenceExpanded, setIsRecurrenceExpanded] = useState(false)
  const [showCustomOptions, setShowCustomOptions] = useState(false)

  const recurrenceLabelRef = useRef(null)
  const recurrencePopupTriggerRef = useRef(null)

  const recurrenceDisabled = isAsanaTask || (!item.when?.date && !item.pin?.time)

  const toggleRecurrencePopupExpanded = () => {
    if (recurrenceDisabled) return

    setIsRecurrenceExpanded((prev) => !prev)
  }

  const handleTypeCliked = (...args) => {
    setIsRecurrenceExpanded(false)
    onRecurrenceChange(...args)
  }

  const handlePopupClickOutside = (e) => {
    if (!recurrencePopupTriggerRef.current.contains(e.target)) {
      setIsRecurrenceExpanded(false)
    }
  }

  const handleCustomClicked = () => {
    setShowCustomOptions(true)
  }

  const hideCustomOptions = () => setShowCustomOptions(false)

  const handleCustomOptionsSaved = (daysSelected) => {
    onRecurrenceChange(DailyScheduleOptions.Custom, { ...selectedRecurrency.customSettings, days: daysSelected })
    setShowCustomOptions(false)
  }

  const date = item.pin?.time ?? item.when?.date ?? ''
  const disableDateInput = readOnlyDate || item.sprintTask?.id
  const momentStartTime = moment(date ? date : undefined)
  const pinTime = item.pin?.time
  const recurrenceLabel = recurrenceComponentsUtils.getRecurrencyLabel({
    customTypeSettings: selectedRecurrency.customSettings,
    recurrencyType: selectedRecurrency.type,
    sprintStartTime: momentStartTime,
  })
  const showCustomOptionsPopup = isRecurrenceExpanded && showCustomOptions
  const showPresetsPopup = isRecurrenceExpanded && !showCustomOptions
  let duration

  if (item.estimatedTime) {
    duration = utils.datetime.convertNanosecondsToMinute(item.estimatedTime)
  }

  return (
    <Container vertical={verticalView}>
      <Row>
        <AttributeContainer>
          <DateWrapper
            disabled={disableDateInput}
            editing={!isCreating}
            estimatedTime={item.estimatedTime}
            firstDayOfWeek={firstDayOfWeek}
            getLineBounds={getLineBounds}
            itemId={item.id}
            onChange={onDateChange}
            pinTime={pinTime}
            shrinked={shouldShrinkAttributes}
            title={item.title}
            value={date}
          />
        </AttributeContainer>
        <PinButton active={!!pinTime} onClick={onTogglePin}>
          <PinIcon icon='Pin' size={10} />
        </PinButton>
        <AttributeContainer>
          <TimeRange
            disabled={!pinTime}
            duration={duration}
            onChange={onPinChange}
            onClickWhileDisabled={onTogglePin}
            shouldAdjustDropdownsPosition={!inChromeExtension}
            startTime={pinTime}
          />
        </AttributeContainer>
      </Row>
      <RecurrenceRow>
        <RecurrenceDropdown
          active={selectedRecurrency.type !== DailyScheduleOptions.NoRepeat}
          disabled={recurrenceDisabled}
          onClick={toggleRecurrencePopupExpanded}
          ref={recurrencePopupTriggerRef}>
          {isAsanaTask && (
            <Tooltip overridePosition position='top right' text={translations.line.asanaRecurrenceDisabledHint} />
          )}
          <RecurrenceIcon icon='Repeat' size={14} />
          <RecurrenceLabel ref={recurrenceLabelRef} title={isAsanaTask ? null : recurrenceLabel}>
            {recurrenceLabel}
          </RecurrenceLabel>
          {!recurrenceDisabled && <CaretIcon fill={styles.colors.textMediumDarkColor} icon='CaretDown' size={12} />}
        </RecurrenceDropdown>
        {showPresetsPopup && (
          <RecurrencePresetsPopup
            className='ScheduleMode__RecurrencePresetsPopup'
            onChange={handleTypeCliked}
            onClickOutside={handlePopupClickOutside}
            onCustomClicked={handleCustomClicked}
            sprintStartTime={momentStartTime}
            typeSelected={selectedRecurrency.type}
          />
        )}
        {showCustomOptionsPopup && (
          <CustomRecurrenceSettingsPopup
            className='ScheduleMode__CustomRecurrenceSettingsPopup'
            customTypeSettings={selectedRecurrency.customSettings}
            firstDayOfWeek={firstDayOfWeek}
            onCancel={hideCustomOptions}
            onClickOutside={handlePopupClickOutside}
            onSave={handleCustomOptionsSaved}
          />
        )}
      </RecurrenceRow>
    </Container>
  )
}

const Row = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`

Row.displayName = 'Row'

const RecurrenceRow = styled(Row)`
  position: relative;

  .FixedTooltip__wrapper {
    width: 100%;
  }
`

RecurrenceRow.displayName = 'RecurrenceRow'

const AttributeContainer = styled.div`
  flex: 1;

  .TimeRange {
    padding-left: 4px;
  }
`

AttributeContainer.displayName = 'AttributeContainer'

const PinIcon = styled(Icon)`
  transition: fill 300ms ease-in-out, transform 300ms ease-in-out;
`

PinIcon.displayName = 'PinIcon'

const PinButton = styled.div`
  background-color: white;
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  cursor: pointer;
  margin: 0 auto;
  padding: 6px;
  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

  :hover {
    background-color: ${styles.colors.primaryColor};
    border-color: ${styles.colors.primaryColor};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${styles.colors.primaryColor};
      border-color: ${styles.colors.primaryColor};

      ${PinIcon} {
        transform: rotate(-45deg);

        path {
          fill: white;
        }
      }
    `}
`

PinButton.displayName = 'PinButton'

const RecurrenceLabel = styled.span`
  color: ${styles.colors.darkGrey};
  display: block;
  font-size: 14px;
  margin-right: 8px;
  max-width: 275px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
`

RecurrenceLabel.displayName = 'RecurrenceLabel'

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding-top: 4px;
  position: relative;

  ${Row} + ${Row} {
    margin-top: 12px;
  }

  ${({ vertical }) =>
    vertical &&
    css`
      ${AttributeContainer} {
        flex: unset;
      }

      ${PinButton} {
        margin: 0 0 0 auto;
      }

      ${RecurrenceLabel} {
        font-size: 12px;
      }

      .DateWrapper {
        padding: 0 4px 0 7px;

        &__Icon {
          margin-right: 4px;
        }

        &__Placeholder {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .TimeRange__input .gp-input.gp-input {
        font-size: 12px;
        width: 18px;
      }
    `}
`

Container.displayName = 'Container'

const RecurrenceIcon = styled(Icon)`
  margin-right: 10px;
`

RecurrenceIcon.displayName = 'RecurrenceIcon'

const RecurrenceDropdown = styled.div`
  align-items: center;
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 8px 12px;
  position: relative;
  width: 100%;

  & .tooltip {
    &.top.right {
      border-radius: 16px;
    }

    left: 0;
    line-height: 12px;
    max-width: 100%;
    padding: 8px;
    top: 0;
    transform: translateY(calc(-100% - 8px));
    white-space: unset;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      ${RecurrenceIcon} {
        path {
          fill: ${styles.colors.middleGrey};
        }
      }

      ${RecurrenceLabel} {
        color: ${styles.colors.middleGrey};
      }
    `}

  ${({ active }) =>
    active &&
    css`
      ${RecurrenceIcon} {
        path {
          fill: ${styles.colors.textMediumDarkColor};
        }
      }

      ${RecurrenceLabel} {
        color: ${styles.colors.textMediumDarkColor};
      }
    `}
`

RecurrenceDropdown.displayName = 'RecurrenceDropdown'

const CaretIcon = styled(Icon)`
  margin-left: auto;
`

CaretIcon.displayName = 'CaretIcon'
