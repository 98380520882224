import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { getSrc } from 'utils/image'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

import { media } from 'gipsy-ui'

const componentName = 'LoadingInsidePopup'

function Loading({ text }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(pushShortcutsGroup([], componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  return (
    <Container>
      <IconImg src={getSrc('/images/loading_kosmo.gif')} alt='loading-logo' />
      <TextContainer> {text}</TextContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const IconImg = styled.div`
  height: 120px;
  width: 120px;
  background: url('${(props) => props.src}') center center no-repeat;
  background-size: contain;
`

const TextContainer = styled.div`
  font-size: 15px;
  font-weight: 500;
  ${media['phone-wide']`
    font-size: 16px;
`}
  margin-bottom:20px;
  margin-top: 20px;
`

export default Loading
