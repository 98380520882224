import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { utils } from 'gipsy-misc'
import Popup from 'Popup'
import { CustomPopup } from 'Popup/components'
import PopupItem from 'LineComponents/LinkItem'

function LinkListPopup({
  children,
  itemProps: { onDelete, onSave } = {},
  maxWidth,
  onClickOutside,
  readOnly = false,
  urlsInfo,
}) {
  const handleLinkClick = (url) => {
    window.open(utils.url.setHttp(url), '_blank')
  }

  return (
    <CustomPopup ContainerComponent={StyledPopup} maxWidth={maxWidth} onClickOutside={onClickOutside}>
      <PopupWrapper>
        <PopupContainer>
          {urlsInfo &&
            urlsInfo.map((urlInfo, index) => (
              <PopupItem
                key={index}
                index={index}
                onClickLink={handleLinkClick}
                onDelete={onDelete}
                onSave={onSave}
                readOnly={readOnly}
                urlInfo={urlInfo}
              />
            ))}
          {children}
        </PopupContainer>
      </PopupWrapper>
    </CustomPopup>
  )
}

LinkListPopup.propTypes = {
  itemProps: PropTypes.shape({
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
  }),
  onClickOutside: PropTypes.func,
  readOnly: PropTypes.bool,
  urlsInfo: PropTypes.array.isRequired,
}

export default LinkListPopup

export const StyledPopup = styled(Popup).attrs(({ maxWidth }) => ({
  style: {
    maxWidth: Number.isInteger(maxWidth) ? `${maxWidth}px` : 'none',
  },
}))`
  background-color: transparent;
  border-radius: none;
  box-shadow: none;
  left: 0;
  min-width: 0;
  top: calc(100% + 6px);
  width: 420px;
`

export const PopupWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 34px rgba(33, 21, 81, 0.14);
  margin-bottom: 20px;
`

export const PopupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 188px;
  overflow-y: auto;
`
