import { types } from 'store/sprintComposer/types'
import { updateTaskInArrById } from 'logic/list'

const initialState = {
  addedTasks: [],
  isDragging: false,
}

function adjustTaskRanks(addedTasks, payload) {
  const { destinationIndex, draggableId } = payload

  const draggedTask = addedTasks.find((task) => task.id === draggableId)

  if (!draggedTask) return addedTasks

  const updatedTasks = addedTasks.filter((task) => task.id !== draggableId)
  updatedTasks.splice(destinationIndex, 0, draggedTask)
  return updatedTasks
}

const sprintComposerReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case types.SET_FOCUS_BLOCK_TASKS:
      return {
        ...state,
        addedTasks: payload.tasks,
      }

    case types.ADD_FOCUS_BLOCK_TASK:
      return {
        ...state,
        addedTasks: [...state.addedTasks, payload.task],
      }

    case types.ADJUST_FOCUS_BLOCK_RANKS:
      return {
        ...state,
        addedTasks: adjustTaskRanks(state.addedTasks, payload),
      }

    case types.REMOVE_FOCUS_BLOCK_TASK:
      return {
        ...state,
        addedTasks: state.addedTasks.filter((addedTask) => addedTask.id !== payload.taskId),
      }

    case types.SET_SPRINT_COMPOSER_DRAGGING:
      return {
        ...state,
        isDragging: payload,
      }

    case types.UPDATE_FOCUS_BLOCK_TASK:
      return {
        ...state,
        addedTasks: updateTaskInArrById(state.addedTasks, payload.updatedTask, payload.taskId),
      }

    default:
      break
  }
  return state
}

export default sprintComposerReducer
