import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { models, styles, translations, utils } from 'gipsy-misc'
import { IconButton, TimeRange } from 'gipsy-ui'

import variables from 'assets/styles/variables.js'
import useClickOutside from 'features/app/hooks/useClickOutside'
import {
  AddItemButtonContainer,
  AddItemButtonsContainer,
  DateInputWrapper,
  PinButton,
  PinIcon,
  InputsSection,
  TimeInputSection,
} from 'features/calendar/components/CalendarPanel/components/AddCalendarItemPopup'
import CalendarItemPopupContainer from 'features/calendar/components/CalendarPanel/components/CalendarItemPopupContainer'
import DatePickerInput from 'features/calendar/components/CalendarPanel/components/DatePickerInput'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

const componentName = 'AddItemPopup'
const popupZIndex = 3

const AddItemPopup = React.forwardRef(function AddItemPopup(props, ref) {
  const {
    firstDayOfWeek,
    onChangeSlotRange,
    onChangeStartDate,
    onClickOutside,
    onClose,
    onCreateSprint,
    onCreateTask,
    onTogglePin,
    selectedSlot,
    sprintMode,
  } = props

  useClickOutside(ref, onClickOutside)

  const dispatch = useDispatch()
  const sessionUser = useSelector((state) => state.session.user)

  const [emptyTitleErrorShown, setEmptyTitleErrorShown] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [itemTitle, setItemTitle] = useState('')

  let startTime
  let estimatedTimeObj

  if (selectedSlot) {
    startTime = moment(selectedSlot.start)
    estimatedTimeObj = utils.datetime.getDurationObj({ start: startTime, end: selectedSlot.end })
  } else {
    startTime = moment()
    estimatedTimeObj = { hour: 0, minute: 30 }
  }

  let duration =
    (Number.isFinite(estimatedTimeObj.hour) ? estimatedTimeObj.hour * 60 : 0) +
    (Number.isFinite(estimatedTimeObj.minute) ? estimatedTimeObj.minute : 0)

  if (duration === 0) {
    duration = 15
  }

  const _onCreateTask = useCallback(() => {
    setIsSubmitting(true)

    const task = models.task({
      title: itemTitle,
      when: { date: moment(selectedSlot.start).format('YYYY-MM-DD') },
      to: utils.user.computeUserFromSessionUser(sessionUser),
      estimatedTime: utils.datetime.getDurationInNanoSeconds(selectedSlot),
    })

    if (!selectedSlot.allDay) {
      task.pin = {
        time: moment(selectedSlot.start).format(),
      }
    }

    onCreateTask?.(task, { componentSource: 'calendarPanel' })
  }, [itemTitle, onCreateTask, selectedSlot, sessionUser])

  const _onCreateSprint = useCallback(() => {
    setIsSubmitting(true)

    const sprint = models.sprint({
      ...utils.sprint.getPinTimeAndEstimatedTimeFromCalendarSlot(selectedSlot),
      title: itemTitle,
    })

    onCreateSprint?.(sprint)
  }, [itemTitle, onCreateSprint, selectedSlot])

  const _onChangeTitle = useCallback(
    (e) => {
      setItemTitle(e.target.value)
      if (emptyTitleErrorShown) {
        setEmptyTitleErrorShown(false)
      }
    },
    [emptyTitleErrorShown]
  )

  const handleDateSelected = useCallback(
    (data) => {
      onChangeStartDate?.(data.value)
    },
    [onChangeStartDate]
  )

  const handlePinToggle = useCallback(() => {
    if (!selectedSlot || sprintMode) return

    onTogglePin({ item: selectedSlot.item, isCreating: true })
  }, [onTogglePin, selectedSlot, sprintMode])

  const showTitleError = useCallback(() => setEmptyTitleErrorShown(true), [])

  useEffect(() => {
    const shortcuts = [
      {
        key: 'Escape',
        label: translations.general.cancel,
        callback: onClose,
      },
    ]

    if (sprintMode) {
      shortcuts.push({
        key: 'Enter',
        label: translations.calendar.createSprint,
        callback: _onCreateSprint,
      })
    } else {
      shortcuts.push({
        key: 'Enter',
        label: translations.calendar.createTask,
        callback: _onCreateTask,
      })
    }

    dispatch(pushShortcutsGroup(shortcuts, componentName))
    return () => dispatch(popShortcutsGroup(componentName))
  }, [_onCreateSprint, _onCreateTask, dispatch, onClose, sprintMode])

  const isFormDisabled = isSubmitting || !itemTitle

  return (
    <CalendarItemPopupContainer
      ref={ref}
      zIndex={popupZIndex}
      padding={containerPadding}
      minWidth={variables.addCalendarTaskPopupWidth}
      maxWidth={variables.addCalendarTaskPopupWidth}
      top={props.top}
      shouldFlipTail={props.shouldFlipTail}
      left={props.left}>
      <StyledInputsSection>
        <TitleInputContainer className='fs-mask'>
          <ItemTitleInput
            autoFocus
            hasError={emptyTitleErrorShown}
            onChange={_onChangeTitle}
            placeholder={`${translations.general.new}...`}
            value={itemTitle}
          />
          {emptyTitleErrorShown && <ErrorContainer>{translations.sprint.panel.emptyTitleErr}</ErrorContainer>}
        </TitleInputContainer>
        <TimeInputSection>
          <DateInputWrapper>
            <DatePickerInput
              closeOnSelection
              firstDayOfWeek={firstDayOfWeek}
              onChange={handleDateSelected}
              startTime={startTime}
            />
          </DateInputWrapper>
        </TimeInputSection>
        <TimeInputSection>
          <PinButton active={sprintMode || !selectedSlot?.allDay} onClick={handlePinToggle}>
            <PinIcon icon='Pin' size={10} />
          </PinButton>
          <TimeRange
            disabled={selectedSlot?.allDay}
            duration={duration}
            onChange={onChangeSlotRange}
            onClickWhileDisabled={handlePinToggle}
            startTime={startTime}
          />
        </TimeInputSection>
      </StyledInputsSection>
      <AddItemButtonsContainer>
        <StyledAddItemButtonContainer sprintMode={sprintMode}>
          {sprintMode ? (
            <IconButton
              onClick={isFormDisabled ? showTitleError : _onCreateSprint}
              iconName={'Sprint'}
              width={136}
              height={32}
              labelColor={'white'}
              fillIcon={'white'}
              iconHoverColor={'white'}
              borderColor={styles.colors.orangeColor}
              borderHoverColor={`${styles.colors.orangeColor}80`}
              iconSize={16}
              label={translations.calendar.createBlock}
            />
          ) : (
            <IconButton
              onClick={isFormDisabled ? showTitleError : _onCreateTask}
              iconName={'PinOutline'}
              width={136}
              height={32}
              labelColor={'white'}
              fillIcon={'transparent'}
              iconHoverColor={'transparent'}
              borderColor={styles.colors.primaryColor}
              borderHoverColor={`${styles.colors.primaryColor}80`}
              iconSize={16}
              label={translations.calendar.createTask}
            />
          )}
        </StyledAddItemButtonContainer>
      </AddItemButtonsContainer>
    </CalendarItemPopupContainer>
  )
})

export default React.memo(AddItemPopup)

const containerPadding = 8

const StyledInputsSection = styled(InputsSection)`
  width: 100%;
`

StyledInputsSection.displayName = 'StyledInputsSection'

const TitleInputContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;

  & input {
    width: 100%;
  }
`

TitleInputContainer.displayName = 'TitleInputContainer'

const ItemTitleInput = styled.input`
  border: 1px solid ${styles.colors.greyBorderColor};
  border-radius: 8px;
  color: ${styles.colors.textMediumDarkColor};
  font-size: 15px;
  padding: 13px 16px;

  ::placeholder {
    color: ${styles.colors.darkGrey};
    text-transform: capitalize;
  }

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${styles.colors.errorColor};
    `}
`

ItemTitleInput.displayName = 'ItemTitleInput'

const ErrorContainer = styled.div`
  bottom: 0;
  color: red;
  font-size: 10px;
  left: 20px;
  position: absolute;
`

ErrorContainer.displayName = 'ErrorContainer'

const StyledAddItemButtonContainer = styled(AddItemButtonContainer)`
  & > div {
    background: ${styles.colors.primaryColor};
    width: 100%;
  }

  & .IconButton svg path {
    fill: transparent;
    stroke: white;
  }

  ${({ sprintMode }) =>
    sprintMode &&
    css`
      & > div {
        background: ${styles.colors.orangeColor};
      }

      & .IconButton svg path {
        fill: white;
        stroke: transparent;
      }
    `}
`

StyledAddItemButtonContainer.displayName = 'StyledAddItemButtonContainer'
