import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { styles } from 'gipsy-misc'

export default function CalendarAnimation({ active, sprint }) {
  const renderedAnimation = useMemo(() => {
    if (sprint) {
      return (
        <>
          <DayCell first />
          <DayCell />
          <DayCell />
          <DayCell last />
          <SprintWrapper>
            <SprintItem animate={active ? 'active' : 'inactive'} variants={sprintVariants}>
              <SprintTitle>Focus Block</SprintTitle>
              <SprintTask custom={1} variants={sprintTaskVariants}>
                Task 1
              </SprintTask>
              <SprintTask custom={2} variants={sprintTaskVariants}>
                Task 2
              </SprintTask>
              <SprintTask custom={3} variants={sprintTaskVariants}>
                Task 3
              </SprintTask>
              <SprintTask custom={4} variants={sprintTaskVariants}>
                Task 4
              </SprintTask>
            </SprintItem>
          </SprintWrapper>
        </>
      )
    }

    return (
      <>
        <DayCell first />
        <DayCell>
          <TaskItem animate={active ? 'active' : 'inactive'} variants={taskVariants}>
            Task 1
          </TaskItem>
        </DayCell>
        <DayCell>
          <TaskItem animate={active ? 'active' : 'inactive'} custom={1} variants={taskVariants}>
            Task 2
          </TaskItem>
        </DayCell>
        <DayCell last />
      </>
    )
  }, [active, sprint])

  return (
    <Calendar>
      <DayColumn>
        <DayColumnTitle>Mon, 22</DayColumnTitle>
        <DayCell first />
        <DayCell />
        <DayCell />
        <DayCell last />
      </DayColumn>
      <DayColumn main>
        <DayColumnTitle>Tue, 23</DayColumnTitle>
        <DayCellColumn>{renderedAnimation}</DayCellColumn>
      </DayColumn>
      <DayColumn>
        <DayColumnTitle>Wed, 24</DayColumnTitle>
        <DayCell first />
        <DayCell />
        <DayCell />
        <DayCell last />
      </DayColumn>
    </Calendar>
  )
}

const Calendar = styled.div`
  align-items: stretch;
  display: flex;
  width: 100%;
`

Calendar.displayName = 'Calendar'

const DayColumn = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;

  ${({ main }) =>
    main &&
    css`
      flex: 1.4;
      margin: 0 2px;
    `}
`

DayColumn.displayName = 'DayColumn'

const DayColumnTitle = styled.span`
  color: ${styles.colors.darkGrey};
  display: block;
  font-size: 12px;
  margin: 0 0 8px 4px;
`

DayColumnTitle.displayName = 'DayColumnTitle'

const DayCellColumn = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
`

DayCellColumn.displayName = 'DayCellColumn'

const DayCell = styled.div`
  background-color: ${styles.colors.veryLightGrey};
  height: 35px;
  margin-bottom: 2px;
  width: 100%;

  ${({ first, last }) => css`
    border-radius: ${first ? '10px 10px 0 0' : last ? '0 0 10px 10px' : '0'};
  `}
`

DayCell.displayName = 'DayCell'

const taskVariants = {
  active: (i) => ({
    opacity: [0, 1, 1, 0],
    transition: {
      delay: i * 0.15,
      duration: 2.5,
      repeat: Infinity,
      times: [0, 0.3, 0.7, 1],
    },
  }),
  inactive: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
}

const TaskItem = styled(motion.div)`
  align-items: center;
  background-color: ${styles.colors.primaryColor}33;
  border: 2px solid ${styles.colors.primaryColor};
  border-radius: 10px;
  color: ${styles.colors.primaryColor};
  display: flex;
  font-size: 14px;
  height: calc(100% - 10px);
  margin: 5px 4px;
  opacity: 0;
  padding: 0 12px;
`

TaskItem.displayName = 'TaskItem'

const SprintWrapper = styled.div`
  height: 100%;
  padding: 5px 4px;
  position: absolute;
  width: 100%;
`

SprintWrapper.displayName = 'SprintWrapper'

const sprintVariants = {
  active: {
    height: ['20%', '20%', '100%', '100%'],
    opacity: [0, 1, 1, 1],
    transition: {
      duration: 4,
      repeat: Infinity,
      times: [0, 0.1, 0.2, 1],
    },
  },
  inactive: {
    opacity: 0,
  },
}

const SprintItem = styled(motion.div)`
  background-color: #ffebd5;
  border: 2px solid ${styles.colors.orangeColor};
  border-radius: 10px;
  color: ${styles.colors.orangeColor};
  font-size: 14px;
  height: 20%;
  width: 100%;
`

SprintItem.displayName = 'SprintItem'

const SprintTitle = styled.span`
  display: block;
  margin: 2px 12px 12px 12px;
`

SprintTitle.displayName = 'SprintTitle'

const sprintTaskVariants = {
  active: (i) => ({
    opacity: [0, 0, 0, 1, 1],
    transition: {
      duration: 4,
      repeat: Infinity,
      times: [0, 0.1, 0.15 + i * 0.025, 0.4, 1],
    },
  }),
  inactive: {
    opacity: 0,
  },
}

const SprintTask = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  color: ${styles.colors.orangeColor};
  font-size: 12px;
  margin: 0 16px 4px;
  opacity: 0;
  padding: 3px 12px;
`

SprintTask.displayName = 'SprintTask'
