import { types } from 'store/calendar/types'
import { combineReducers } from 'redux'
import moment from 'moment'
import { generateDateRange } from 'features/calendar/utils'
import { utils } from 'gipsy-misc'

const getEventDate = (event) => {
  return moment(event.start).format('YYYY-MM-DD')
}

export const sortByDateStr = (dateStrings) =>
  dateStrings.sort((a, b) => {
    const aDate = new Date(a)
    const bDate = new Date(b)
    return aDate.getTime() - bDate.getTime()
  })

function setItems(state, action) {
  let {
    payload: { events },
  } = action
  if (Array.isArray(events)) {
    return events.reduce((resultState, event) => {
      resultState[event.id] = utils.calendar.normalizeEvent(event)
      return resultState
    }, {})
  } else {
    return {
      ...events,
    }
  }
}

function addItems(state, action) {
  let {
    payload: { events },
  } = action
  let newState = { ...state }
  events.forEach((event) => {
    newState[event.id] = utils.calendar.normalizeEvent(event)
  })
  return newState
}

function addItem(state, action) {
  const {
    payload: { event },
  } = action
  const eventId = event.id || moment().unix()
  return {
    ...state,
    [eventId]: utils.calendar.normalizeEvent(event),
  }
}

const updateEventData = (currentEvent, event) => ({
  ...currentEvent,
  ...utils.calendar.normalizeEvent(event),
})

function updateItem(state, action) {
  const {
    payload: { event, idToUpdate },
  } = action
  const newState = { ...state }
  const eventId = event.id

  if (!newState[idToUpdate] && !newState[eventId]) return newState

  if (!newState[idToUpdate] && newState[eventId]) {
    newState[eventId] = updateEventData(newState[eventId], event)
  }

  if (eventId !== idToUpdate && newState[idToUpdate]) {
    newState[eventId] = updateEventData(newState[idToUpdate], event)
    delete newState[idToUpdate]
  } else {
    newState[idToUpdate] = updateEventData(newState[idToUpdate], event)
  }

  return newState
}

function removeItem(state, action) {
  const {
    payload: { itemId },
  } = action
  const newState = { ...state }
  delete newState[itemId]
  return newState
}

function removeItems(state, { items }) {
  const newState = items.reduce((currentState, item) => {
    const updatedState = removeItem(currentState, { payload: { itemId: item.id } })
    return updatedState
  }, state)

  return newState
}

function itemsByIdReducer(state = {}, action) {
  switch (action.type) {
    case types.SET_EVENTS:
      return setItems(state, action)
    case types.ADD_EVENTS:
      return addItems(state, action)
    case types.ADD_EVENT:
      return addItem(state, action)
    case types.UPDATE_EVENT:
      return updateItem(state, action)
    case types.REMOVE_EVENT:
      return removeItem(state, action)
    case types.REMOVE_EVENTS:
      return removeItems(state, action.payload)
    default:
      return state
  }
}

function setItemsIds(state, action) {
  let {
    payload: { events },
  } = action
  if (Array.isArray(events)) {
    return events.map((event) => event.id)
  } else {
    return Object.keys(events)
  }
}

function addItemsIds(state, action) {
  let {
    payload: { events },
  } = action
  const newIds = events.map((event) => event.id)
  return state.concat(newIds)
}

function addItemId(state, action) {
  const {
    payload: { event },
  } = action
  return state.concat(event.id)
}

function updateItemId(state, action) {
  const {
    payload: { event, idToUpdate },
  } = action
  if (event.id !== idToUpdate) {
    const idx = state.findIndex((id) => id === idToUpdate)
    let newState = [...state]
    newState[idx] = event.id
    return newState
  }
  return state
}

function removeItemId(state, action) {
  const {
    payload: { itemId },
  } = action
  return state.filter((id) => id !== itemId)
}

function removeItemsIds(state, { items }) {
  const newState = items.reduce((currentState, item) => {
    const updatedState = removeItemId(currentState, { payload: { itemId: item.id } })
    return updatedState
  }, state)

  return newState
}

export function allItemsReducer(state = [], action) {
  switch (action.type) {
    case types.SET_EVENTS:
      return setItemsIds(state, action)
    case types.ADD_EVENTS:
      return addItemsIds(state, action)
    case types.ADD_EVENT:
      return addItemId(state, action)
    case types.UPDATE_EVENT:
      return updateItemId(state, action)
    case types.REMOVE_EVENT:
      return removeItemId(state, action)
    case types.REMOVE_EVENTS:
      return removeItemsIds(state, action.payload)
    default:
      return state
  }
}

function setItemsDates(state, action) {
  let {
    payload: { events, from, to },
  } = action
  if (!Array.isArray(events)) {
    console.error('events should be an array')
    return {}
  } else {
    if (from && to) {
      const sortedDates = generateDateRange(from, to)
      return sortedDates.reduce((newState, date) => {
        newState[date] = true
        return newState
      }, {})
    } else {
      return events.reduce((newState, event) => {
        newState[getEventDate(event)] = true
        return newState
      }, {})
    }
  }
}

function addItemsDates(state, action) {
  const {
    payload: { events, from, to },
  } = action
  const newDates = { ...state }
  if (from && to) {
    const sortedDates = generateDateRange(from, to)
    sortedDates.forEach((date) => (newDates[date] = true))
  } else {
    events.forEach((item) => (newDates[getEventDate(item)] = true))
  }
  return newDates
}

export function allDatesReducer(state = {}, action) {
  switch (action.type) {
    case types.SET_EVENTS:
      return setItemsDates(state, action)
    case types.ADD_EVENTS:
      return addItemsDates(state, action)
    default:
      return state
  }
}

const statusInitialState = {
  areLoaded: false,
  hasPendingFetch: false,
  areLocked: false,
}
export function statusReducer(state = statusInitialState, action) {
  switch (action.type) {
    case types.SET_EVENTS_LOADED:
      return {
        ...state,
        areLoaded: action.payload,
      }
    case types.SET_EVENTS_LOCKED:
      return {
        ...state,
        areLocked: action.payload.areLocked,
      }

    case types.SET_EVENTS_PENDING_FETCH:
      return {
        ...state,
        hasPendingFetch: action.payload,
      }
    default:
      return state
  }
}

export function highlightedEventIdReducer(state = '', action) {
  switch (action.type) {
    case types.SET_HIGHLIGHTEDEVENTID:
      return action.payload
    default:
      return state
  }
}

export const itemsReducer = combineReducers({
  byId: itemsByIdReducer,
  allIds: allItemsReducer,
  allDates: allDatesReducer,
  fetchStatus: statusReducer,
  highlightedItemId: highlightedEventIdReducer,
})
