import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  paramName: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.element,
  leftIconClick: PropTypes.func,
  rightIcon: PropTypes.element,
  rightIconClick: PropTypes.func,
}

const defaultProps = {
  value: '',
}

class Input extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lastValue: props.value,
      focus: false,
    }
  }

  /*
   ** We use handmade shouldComponentUpdate instead of PureComponent in order to ignore the onChange function
   ** it allows us to create a new function on every render in the parent : onChange={() => { doSomething() }}
   ** without rerendering the component everytime the parent is rendered (PureComponent have this side effect )
   */
  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onChange', 'onSubmit', 'onFocus', 'onBlur', 'onKeyDown']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }

      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    if (this.state.focus !== nextState.focus) {
      return true
    }
    return false
  }

  /* called from outside in TimeInput */
  focus = () => {
    this.input.focus()
  }

  blur = () => {
    if (!this.state.focus) {
      return
    }
    this.setState({ focus: false }, () => this.input.blur())
  }

  onFocus = (e) => {
    this.setState({
      focus: true,
    })
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  onBlur = (e) => {
    this.setState({
      focus: false,
    })

    if (this.props.onBlur) {
      this.props.onBlur({
        paramName: this.props.paramName,
        value: this.props.value,
      })
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.setState({
      lastValue: this.props.value,
    })

    if (this.props.onSubmit) {
      this.props.onSubmit({
        paramName: this.props.paramName,
        value: this.props.value,
      })
    }
  }

  onCancel = (e) => {
    if (this.props.value !== this.state.lastValue) {
      this.onChange(null, this.state.lastValue)
    }
    if (this.props.onCancel) {
      this.props.onCancel(e)
    }
  }

  onChange = (e, value) => {
    if (this.props.onChange) {
      this.props.onChange({
        paramName: this.props.paramName,
        value: e ? e.target.value : value,
      })
    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      /* enter keypress */
      this.blur()
      this.onSubmit(e)
    } else if (e.keyCode === 27) {
      /* esc keypress */
      this.blur()
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  render() {
    const {
      className,
      paramName,
      onChange,
      value,
      leftIcon,
      rightIcon,
      leftIconClick,
      rightIconClick,
      ...props
    } = this.props
    const { focus } = this.state

    return (
      <div className="gp-input">
        {leftIcon && (
          <span className={'left-icon' + (leftIconClick ? ' pointer' : '')} onClick={leftIconClick}>
            {leftIcon}
          </span>
        )}
        <input
          {...props}
          ref={(ref) => {
            this.input = ref
          }}
          autoFocus={true}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          onBlur={() => {
            this.onBlur()
          }}
          onFocus={this.onFocus}
          className={
            (focus ? 'focus ' : '') +
            (leftIcon ? 'left-icon ' : '') +
            (rightIcon ? 'right-icon ' : '') +
            (className ? `${className}` : '')
          }
          value={this.props.value}
          autoComplete="new-password" // we don't want the chrome auto-fill feature
        />
        {rightIcon && (
          <span className={'right-icon' + (rightIconClick ? ' pointer' : '')} onClick={rightIconClick}>
            {rightIcon}
          </span>
        )}
      </div>
    )
  }
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
