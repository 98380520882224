import { createAction } from 'redux-actions'

import { handleAPIError } from 'store/app/actions'

import types from './types'

const _setAccounts = createAction(types.SET_ACCOUNTS)

export const setAccounts = (accounts) => (dispatch) => {
  dispatch(_setAccounts(accounts))
}

export const getAccounts = () => async (dispatch, getState, { api }) => {
  try {
    const accounts = await api.emailaccounts.get()
    dispatch(setAccounts(accounts))
  } catch (err) {
    dispatch(handleAPIError(err))
  }
}
