import get from 'lodash/get'
import qs from 'qs'

export const getCurrentPathName = () => {
  if (process.env.REACT_APP_ELECTRON === 'true') {
    const location = window.location
    const hash = location.hash
    const splitHash = hash.split('#')
    if (splitHash.length > 1) {
      return splitHash[1]
    }
    return splitHash[0]
  } else {
    return window.location.pathname
  }
}

export const getUserFromLocation = (location) => {
  if (process.env.REACT_APP_ELECTRON === 'true') {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryParams.userId) {
      return {
        userId: queryParams.userId,
      }
    } else if (queryParams.email) {
      return {
        email: queryParams.email,
      }
    } else {
      return null
    }
  } else {
    return get(location, 'state.user')
  }
}

export const pushUserToHistory = (history, taskId, user) => {
  let basePath = `/team?scrollto=${taskId}`
  if (process.env.REACT_APP_ELECTRON === 'true') {
    if (user.userId) {
      basePath += `&userId=${user.userId}`
    } else if (user.email) {
      basePath += `&email=${user.email}`
    }
    history.push(basePath)
  } else {
    history.push(basePath, { user: user })
  }
}

export const hasTeamLocationBeenUpdated = (prevProps, props) => {
  if (process.env.REACT_APP_ELECTRON === 'true') {
    const prevQueryParams = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true })
    const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    return prevQueryParams.scrollto !== queryParams.scrollto
  } else {
    return prevProps.location.key !== props.location.key
  }
}
