const chromext = {
  version: 'VERSION',
  closeTabMessage: 'CLOSE_TABS',
  openUrls: 'OPEN_URLS',
  restoreTabs: 'RESTORE_TABS',
  getWhitelistedWebsites: 'GET_WHITELISTED_WEBSITES',
  clearWhitelistedWebsites: 'CLEAR_WHITELISTED_WEBSITES',
  whitelistUrlsInfo: 'WHITELIST_URLSINFO',
  tmpWhitelistWebsite: 'TEMPORARILY_WHITELIST_WEBSITE',
  setFocusSession: 'SET_FOCUS_SESSION',
  setMuteModeOn: 'SET_MUTEMODEON',
  refreshFocusSession: 'REFRESH_FOCUS_SESSION',
  setInProgressSprints: 'SET_IN_PROGRESS_SPRINTS',
  getCurrentTab: 'GET_CURRENT_TAB',
  addSuggestionPopupShowCount: 'ADD_SUGGESTION_POPUP_SHOW_COUNT',
  stopShowingPopup: 'STOP_SHOWING_POPUP',
  getShouldHidePopup: 'GET_SHOULDHIDE_POPUP',

  //Sources
  chromeExtensionSource: 'chromeext',

  //Component sources
  bookmarkButtonComponentSource: 'bookmarkButton',
  focusSessionButtonComponentSource: 'focusSessionButton',

  //Page sources
  gmailStartButtonPageSource: 'gmailStartButton',
  suggestionPopupPageSource: 'suggestionPopup',
  popupPageSource: 'chromeextpopup',
}

export default chromext

export const errCode = {
  messageNotReceived: 'chromeext.messageNotReceived',
  notOnChrome: 'chromeext.notOnChrome',
}
