import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { styles } from 'gipsy-misc'
import PropTypes from 'prop-types'

const propTypes = {
  width: PropTypes.any,
  height: PropTypes.number,
  fontWeight: PropTypes.number,
  backgroundColor: PropTypes.string,
  fontSize: PropTypes.number,
  borderRadius: PropTypes.number,
  borderColor: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any.isRequired,
  iconColor: PropTypes.string,
  iconBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
}

class Button extends Component {
  static propTypes = propTypes

  static defaultProps = {
    height: 48,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 20,
    iconColor: 'white',
    backgroundColor: styles.colors.primaryColor,
    iconBackgroundColor: 'transparent',
    textColor: 'white',
  }
  /*
   ** We use handmade shouldComponentUpdate instead of PureComponent in order to ignore the onClick function
   ** it allows us to create a new function on every render in the parent : onClick={() => { doSomething() }}
   ** without rerendering the component everytime the parent is rendered (PureComponent have this side effect )
   */
  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onClick']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }
      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    return false
  }

  onClick = () => {
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick()
    }
  }

  render() {
    const {
      children,
      leftIcon,
      rightIcon,
      disabled,
      height,
      width,
      fontWeight,
      backgroundColor,
      borderColor,
      borderRadius,
      fontSize,
      textColor,
      className,
      setInnerRef,
      style,
    } = this.props

    const finalWidth = width ? (width === 'auto' ? width : `${width}px`) : undefined
    return (
      <Container
        disabled={disabled}
        className={className}
        height={height}
        width={finalWidth}
        fontWeight={fontWeight}
        fontSize={fontSize}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        textColor={textColor}
        borderColor={borderColor}
        style={style}
        ref={setInnerRef}
        onClick={this.onClick}>
        {leftIcon}
        <Label className="button-label">{children}</Label>
        {rightIcon}
      </Container>
    )
  }
}

export default Button

const Container = styled.button`
  height: ${(props) => props.height}px;
  border-style: solid;
  border-width: 1px;
  border-radius: ${(props) => props.borderRadius}px;
  border-color: ${(props) => (props.borderColor ? props.borderColor : props.backgroundColor)};
  background-color: ${(props) => props.backgroundColor};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.fontSize}px;
  font-family: ${styles.fonts.primaryFont};
  outline: none;
  box-shadow: none;
  && {
    cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
  }
  padding: 0;
  position: relative;
  width: ${(props) => (props.width ? props.width : '100%')};
  ${(props) =>
    props.width &&
    css`
      min-width: ${props.width};
    `};
  ${(props) => props.style};
`

const Label = styled.span`
  margin: 0;
  padding: 0;
  user-select: none;
  display: inline-block;
`
