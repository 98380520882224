import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { utils, styles } from 'gipsy-misc'

const propTypes = {
  id: PropTypes.any,
  checkedColor: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  paramName: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  isHovered: PropTypes.bool,
  /*disabled: PropTypes.bool,*/
  size: PropTypes.number,
}

class Radio extends Component {
  static propTypes = propTypes

  static defaultProps = {
    size: 24,
  }

  /*
   ** We use handmade shouldComponentUpdate instead of PureComponent in order to ignore the onChange function
   ** it allows us to create a new function on every render in the parent : onChange={() => { doSomething() }}
   ** without rerendering the component everytime the parent is rendered (PureComponent have this side effect )
   */
  shouldComponentUpdate(nextProps, nextState) {
    const ignores = ['onChange']
    for (let prop in propTypes) {
      if (ignores.indexOf(prop) !== -1) {
        continue
      }
      if (this.props[prop] !== nextProps[prop]) {
        return true
      }
    }
    return false
  }

  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange({
        id: this.props.id,
        paramName: this.props.paramName,
        value: (1 + this.props.checked) % 2,
      })
    }
  }

  render() {
    const {
      className,
      id,
      paramName,
      onChange,
      checked,
      size,
      checkedColor,
      disableStopPropagation,
      isHovered,
      ...props
    } = this.props

    return (
      <Container
        checkedColor={checkedColor}
        size={size}
        className={'gp-radio-container' + (className ? ` ${className}` : '')}
        onClick={disableStopPropagation ? null : utils.DOM.stopPropagation}>
        <input type="radio" onChange={this.onChange} className={'gp-radio'} checked={checked} {...props} />
        <StyledInput isHovered={isHovered} checkedColor={checkedColor} size={size} />
      </Container>
    )
  }
}

export default Radio

const BORDER_WIDTH = 1.5

const StyledInput = styled.span`
  display: block;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: white;
  border: 2px solid
    ${(props) => (props.isHovered ? props.checkedColor || styles.colors.greenColor : styles.colors.grey)};
  transition: all 0.2s;
  border-radius: 50%;

  &::after {
    flex-shrink: 0;
    position: absolute;
    content: '';
    opacity: ${(props) => (props.isHovered ? 1 : 0)};
    transition: opacity 0.2s;
    background-color: ${(props) => (props.checkedColor ? props.checkedColor : styles.colors.greenColor)};
    width: ${(props) => props.size / 2}px;
    height: ${(props) => props.size / 2}px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`
const Container = styled.label`
  position: relative;
  border-radius: 50%;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  display: block;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
  }

  & input:checked ~ ${StyledInput}, &:hover input ~ ${StyledInput} {
    border: ${BORDER_WIDTH}px solid ${(props) => (props.checkedColor ? props.checkedColor : styles.colors.greenColor)};
  }
  & input:checked ~ ${StyledInput}::after, &:hover input + ${StyledInput}::after {
    opacity: 1;
  }
`
