import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchIntegrationsData, setIntegrationsData } from 'store/integrations/actions'
import { PageContainer, PageBody, TitleContainer, NavigationTitle } from 'features/layout/pages'
import { openPopup, closePopup } from 'store/popup/actions'
import { fetchActiveProjects } from 'store/project/actions'
import {
  openIntegrationOAuthWindow,
  getSlackAuthUrlAndStoreState,
  getAsanaAuthUrlAndStoreState,
} from 'logic/integrationOauth'
import { showSnackbar } from 'store/snackbar/actions'
import { checkIfExtensionIsInstalled as _checkIfExtensionIsInstalled, isChromeBrowser } from 'utils/chrome'
import { EXTENSION_LINK } from 'utils/chromeext'

import ChromeExt from './chromeext'
import Slack from './slack'
import Asana from './asana'

import { translations } from 'gipsy-misc'

export class Integrations extends PureComponent {
  state = {
    isChromeExtInstalled: false,
    isLoading: true,
  }
  onClickExtension = async () => {
    window.open(EXTENSION_LINK, '_blank')
  }

  onClickSlackApp = async () => {
    const authURL = await getSlackAuthUrlAndStoreState()

    openIntegrationOAuthWindow(authURL, {
      onSuccess: () => this.props.actions.fetchIntegrationsData(),
      onError: (err) => {
        this.props.actions.showSnackbar(
          {
            message: 'Unexpected Error',
            status: 'error',
            showLogo: false,
            showClose: false,
          },
          5000
        )
      },
    })
  }

  onClickAsanaApp = async () => {
    const authURL = await getAsanaAuthUrlAndStoreState()
    openIntegrationOAuthWindow(
      authURL,
      {
        onSuccess: () => this.props.actions.fetchIntegrationsData(),
        onError: (err) => {
          console.error(err)
          this.props.actions.showSnackbar(
            {
              message: 'Unexpected Error',
              status: 'error',
              showLogo: false,
              showClose: false,
            },
            5000
          )
        },
      },
      { width: 800, height: 950 }
    )
  }

  async componentDidMount() {
    const { integrationsData } = this.props

    if (!integrationsData) {
      this.props.actions.fetchIntegrationsData()
    }

    this.checkIfExtensionIsInstalled()
  }

  async checkIfExtensionIsInstalled() {
    const { user } = this.props.session
    const isChromeExtInstalled = await _checkIfExtensionIsInstalled(user)
    this.setState({ isChromeExtInstalled })
  }

  onUnsyncAsanaAccount = (accountId) => {
    this.props.actions.fetchActiveProjects()
    let newAsanaIntegrations = [...this.props.integrationsData.asanaIntegrations].filter(
      (asanaIntegration) => asanaIntegration.id !== accountId
    )

    this.props.actions.setIntegrationsData({
      ...this.props.integrationsData,
      asanaIntegrations: newAsanaIntegrations,
    })
  }

  onSyncAsanaAccount = () => {
    this.props.actions.fetchIntegrationsData()
    this.props.actions.fetchActiveProjects()
  }

  render() {
    const { isChromeExtInstalled } = this.state
    const { integrationsData } = this.props
    let slackIntegrations
    let asanaIntegrations

    if (integrationsData) {
      slackIntegrations = integrationsData.slackIntegrations
      asanaIntegrations = integrationsData.asanaIntegrations
    }

    return (
      <PageContainer className='fs-mask'>
        <PageBody>
          <TitleContainer>
            <NavigationTitle>{translations.integrations.title}</NavigationTitle>
          </TitleContainer>
          <ComponentsContainer>
            {isChromeBrowser && (
              <ChromeExt isChromeExtInstalled={isChromeExtInstalled} onClick={this.onClickExtension} />
            )}
            <Slack onClickConnect={this.onClickSlackApp} slackIntegrations={slackIntegrations} />
            <Asana
              onClickConnect={this.onClickAsanaApp}
              asanaIntegrations={asanaIntegrations}
              onSyncAccount={this.onSyncAsanaAccount}
              openPopup={this.props.actions.openPopup}
              closePopup={this.props.actions.closePopup}
              onUnsyncAccount={this.onUnsyncAsanaAccount}
              fetchActiveProjects={this.props.actions.fetchActiveProjects}
            />
          </ComponentsContainer>
        </PageBody>
      </PageContainer>
    )
  }
}

const ComponentsContainer = styled.div`
  margin-top: 40px;
`

const mapStateToProps = (state) => ({
  integrationsData: state.integrations,
  session: state.session,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchIntegrationsData,
      showSnackbar,
      openPopup,
      closePopup,
      fetchActiveProjects,
      setIntegrationsData,
    },
    dispatch
  ),
})
export default connect(mapStateToProps, mapDispatchToProps)(Integrations)
