import axios from 'utils/axios'
import { utils } from 'gipsy-misc'

export const patch = (data, params) => {
  let endpoint = `/user`
  endpoint = utils.request.appendToUrl(endpoint, params)
  return axios.patch(endpoint, data)
}

export const postOnboardingInfo = (info) => {
  let endpoint = `/user/onboarding-info`
  return axios.post(endpoint, info)
}

export const unsubscribeFromEmailNotifications = (email) => {
  return axios.patch(`/email-notifications/unsubscribe/${email}`)
}

export const get = () => {
  let endpoint = `/user`
  return axios.get(endpoint)
}

export const del = (params) => {
  let endpoint = `/user/delete/me`
  return axios.post(endpoint, params)
}

export const getIntegrations = () => {
  const endpoint = `/user/integrations`
  return axios.get(endpoint)
}

export const fullSyncIntegrations = () => {
  const endpoint = `/user/integrations/full-sync`
  return axios.post(endpoint)
}
