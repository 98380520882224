import moment from 'moment'
import { models, utils } from 'gipsy-misc'
import { sortDateStrings } from 'features/calendar/utils'
import { createAction } from 'redux-actions'
import { types } from 'store/calendar/types'
import { setLastUserActionTime } from 'store/session/actions'
import { addItems } from 'store/items/actions'
import { fetchAndConsumeDataAfterUserAction } from 'store/utils/actions'

const _setEvents = createAction(types.SET_EVENTS)
const _addEvents = createAction(types.ADD_EVENTS)
const _addEvent = createAction(types.ADD_EVENT)
const _removeEvent = createAction(types.REMOVE_EVENT)
const _removeEvents = createAction(types.REMOVE_EVENTS)
const _updateEvent = createAction(types.UPDATE_EVENT)

const _setEventsLoaded = createAction(types.SET_EVENTS_LOADED)
const _setHighlightedEventId = createAction(types.SET_HIGHLIGHTEDEVENTID)
const _setEventsLocked = createAction(types.SET_EVENTS_LOCKED)
const _setEventsPendingFetch = createAction(types.SET_EVENTS_PENDING_FETCH)

export const getCurrentCalendarDateBounds = (allDates) => {
  const sortedDateStrings = sortDateStrings(Object.keys(allDates))
  const from = sortedDateStrings[0]
  const to = sortedDateStrings[sortedDateStrings.length - 1]
  return {
    start: moment(from).format('YYYY-MM-DD'),
    end: moment(to).format('YYYY-MM-DD'),
  }
}

const fetchEvents = async ({ from, to, api }) => {
  from = moment(from).format('YYYY-MM-DD')
  to = moment(to).format('YYYY-MM-DD')
  const out = await api.calendar.getCalendarEventsRange(from, to)
  const { events, focusSessions } = out
  return [...(events || []), ...(focusSessions || [])]
}

const fetchEventsWithDelay = async ({ from, to, api }) => {
  from = moment(from).format('YYYY-MM-DD')
  to = moment(to).format('YYYY-MM-DD')
  const getCalendarEventsRange = () => api.calendar.getCalendarEventsRange(from, to)
  const out = (await utils.resolve.awaitOnce('getCalendarEventsRange', getCalendarEventsRange, 3000)) || {}
  const { events, focusSessions } = out
  return [...(events || []), ...(focusSessions || [])]
}

// refresh already loaded events data
export const refetchEvents = () => async (dispatch, getState, { api }) => {
  const calendarState = getState().calendar

  if (calendarState.items.fetchStatus.isLocked) {
    dispatch(_setEventsPendingFetch(true))
  } else {
    const { start, end } = getCurrentCalendarDateBounds(calendarState.items.allDates)
    try {
      const from = start
      const to = end
      dispatch(
        fetchAndConsumeDataAfterUserAction(
          async () => {
            return await fetchEvents({ from, to, api })
          },
          (events) => {
            dispatch(_setEvents({ events, from, to }))
            dispatch(_setEventsLoaded(true))
          }
        )
      )
    } catch (err) {}
  }
}

export const refetchEventsWithDelay = () => async (dispatch, getState, { api }) => {
  const calendarState = getState().calendar
  if (calendarState.isLocked) {
    dispatch(_setEventsPendingFetch(true))
  } else {
    const { start, end } = getCurrentCalendarDateBounds(calendarState.items.allDates)
    try {
      const from = start
      const to = end
      dispatch(
        fetchAndConsumeDataAfterUserAction(
          async () => {
            return await fetchEventsWithDelay({ from, to, api })
          },
          (events) => {
            dispatch(_setEvents({ events, from, to }))
            dispatch(_setEventsLoaded(true))
          }
        )
      )
    } catch (err) {}
  }
}

export const initializeEvents = () => async (dispatch, getState, { api }) => {
  const user = getState().session.user
  const today = moment().format('YYYY-MM-DD')
  const firstDay = user.settingsPreferences.calendar.firstDay
  const begOfWeek = utils.date.getStartOfWeek(today, firstDay)
  const endOfWeek = utils.date.getEndOfWeek(today, firstDay)

  const events = await fetchEvents({ from: begOfWeek, to: endOfWeek, api })
  dispatch(_setEvents({ events, from: today, to: today }))
  dispatch(_setEventsLoaded(true))
  const twoWeeksAgo = moment(begOfWeek).subtract(14, 'days')
  const in2Weeks = moment(begOfWeek).add(14, 'days')
  const nextEvents = await fetchEvents({
    from: twoWeeksAgo,
    to: in2Weeks,
    api,
  })
  dispatch(
    _addEvents({
      events: nextEvents,
      from: moment(twoWeeksAgo).format('YYYY-MM-DD'),
      to: moment(in2Weeks).format('YYYY-MM-DD'),
    })
  )
}

export const fetchPreviousEvents = ({ date, isWeekView }) => async (dispatch, getState, { api }) => {
  const {
    items: { allDates },
  } = getState().calendar
  const user = getState().session.user
  let from
  let to
  if (!allDates[moment(date).format('YYYY-MM-DD')]) {
    if (isWeekView) {
      const firstDay = user.settingsPreferences.calendar.firstDay
      from = utils.date.getStartOfWeek(date, firstDay)
      to = utils.date.getEndOfWeek(date, firstDay)
    } else {
      to = moment(date).subtract(1, 'day').format('YYYY-MM-DD')
      from = moment(to).subtract(7, 'days').format('YYYY-MM-DD')
    }

    const [completedSprints, events] = await Promise.all([
      getCompletedSprints({ from, to, api }),
      fetchEvents({ from, to, api }),
    ])
    dispatch(addItems(completedSprints || []))
    dispatch(_addEvents({ events, from: from, to: to }))
  } else {
    const sortedDateStrings = sortDateStrings(Object.keys(allDates))
    const diffDays = moment(date).diff(moment(sortedDateStrings[0]), 'days')
    if (diffDays < 7) {
      from = moment(sortedDateStrings[0]).subtract(14, 'days').format('YYYY-MM-DD')
      to = moment(sortedDateStrings[0]).subtract(1, 'days').format('YYYY-MM-DD')
      const [completedSprints, events] = await Promise.all([
        getCompletedSprints({ from, to, api }),
        fetchEvents({ from, to, api }),
      ])
      dispatch(addItems(completedSprints || []))
      dispatch(_addEvents({ events, from: from, to: to }))
    }
  }
}

const getCompletedSprints = async ({ from, to, api }) => {
  const today = moment().format('YYYY-MM-DD')
  if (from > today) {
    return []
  }
  if (to > today) {
    to = today
  }
  const completedSprints = await api.sprint.getWithTasks(false, from, to)
  return completedSprints
}

export const fetchNextEvents = ({ date, isWeekView }) => async (dispatch, getState, { api }) => {
  const {
    items: { allDates },
  } = getState().calendar
  const user = getState().session.user
  let from
  let to
  if (!allDates[moment(date).format('YYYY-MM-DD')]) {
    if (isWeekView) {
      const firstDay = user.settingsPreferences.calendar.firstDay
      from = utils.date.getStartOfWeek(date, firstDay)
      to = utils.date.getEndOfWeek(date, firstDay)
    } else {
      from = moment(date).add(1, 'days').format('YYYY-MM-DD')
      to = moment(date).add(7, 'days').format('YYYY-MM-DD')
    }

    const [completedSprints, events] = await Promise.all([
      getCompletedSprints({ from, to, api }),
      fetchEvents({ from, to, api }),
    ])
    dispatch(addItems(completedSprints || []))
    dispatch(_addEvents({ events, from: from, to: to }))
  } else {
    const sortedDateStrings = sortDateStrings(Object.keys(allDates))
    const diffDays = moment(sortedDateStrings[sortedDateStrings.length - 1]).diff(moment(date), 'days')
    if (diffDays < 7) {
      from = moment(sortedDateStrings[sortedDateStrings.length - 1])
        .add(1, 'days')
        .format('YYYY-MM-DD')
      to = moment(sortedDateStrings[sortedDateStrings.length - 1])
        .add(14, 'days')
        .format('YYYY-MM-DD')
      const [completedSprints, events] = await Promise.all([
        getCompletedSprints({ from, to, api }),
        fetchEvents({ from, to, api }),
      ])
      dispatch(addItems(completedSprints || []))
      dispatch(_addEvents({ events, from: from, to: to }))
    }
  }
}

export const fetchItemsByDate = ({ date }) => async (dispatch, getState, { api }) => {
  const {
    items: { allDates },
  } = getState().calendar
  const user = getState().session.user
  let from
  let to
  if (!allDates[moment(date).format('YYYY-MM-DD')]) {
    const firstDay = user.settingsPreferences?.calendar?.firstDay || 0
    from = utils.date.getStartOfWeek(date, firstDay)
    to = utils.date.getEndOfWeek(date, firstDay)

    const events = await fetchEvents({ from, to, api })
    dispatch(_addEvents({ events, from: from, to: to }))
  } else {
    const yesterday = moment(date).subtract(1, 'day').format('YYYY-MM-DD')
    const beforeYesterday = moment(date).subtract(2, 'day').format('YYYY-MM-DD')
    const tomorrow = moment(date).add(1, 'day').format('YYYY-MM-DD')
    const afterTomorrow = moment(date).add(2, 'day').format('YYYY-MM-DD')

    if (!allDates[beforeYesterday]) {
      from = beforeYesterday
      to = beforeYesterday
    }

    if (!allDates[yesterday]) {
      to = yesterday
      if (!from) from = yesterday
    }

    if (!allDates[tomorrow]) {
      to = tomorrow
      if (!from) from = tomorrow
    }

    if (!allDates[afterTomorrow]) {
      to = afterTomorrow
      if (!from) from = afterTomorrow
    }

    if (from && to) {
      const events = await fetchEvents({ from, to, api })
      dispatch(_addEvents({ events, from: from, to: to }))
    }
  }
}

const parseItemIntoEvent = ({ item, color, ...extraParams }) => {
  const isFocusSession = item.type === models.item.type.FOCUSSESSION
  const event = {
    id: item.id,
    start: isFocusSession ? item.startTime : item.pin.time,
    end: isFocusSession
      ? item.endTime
      : moment(item.pin.time)
          .add(item.estimatedTime / 1000000, 'millisecond')
          .toDate(),
    color,
    item,
    ...extraParams,
  }
  return event
}

// add task or sprint to events store
export const addCalendarEvent = (eventData) => async (dispatch) => {
  const event = parseItemIntoEvent(eventData)
  dispatch(setLastUserActionTime())
  dispatch(_addEvent({ event }))
}

// add multiple items of the same type at once
export const addCalendarEvents = ({ items, ...extraParams }) => (dispatch) => {
  const events = items.map((item) => parseItemIntoEvent({ item, ...extraParams }))
  dispatch(setLastUserActionTime())
  dispatch(_addEvents({ events }))
}

export const removeCalendarEvent = ({ itemId }) => async (dispatch) => {
  dispatch(setLastUserActionTime())
  dispatch(_removeEvent({ itemId }))
}

export const removeCalendarEvents = (items) => (dispatch) => {
  dispatch(setLastUserActionTime())
  dispatch(_removeEvents({ items }))
}

export const setHighlightedEventId = (itemId) => async (dispatch) => {
  dispatch(_setHighlightedEventId(itemId))
}

export const updateCalendarEvent = ({ idToUpdate, item }) => async (dispatch, getState) => {
  const previousEvent = getState()?.calendar?.items?.byId[idToUpdate]
  const isFocusSession = item.type === models.item.type.FOCUSSESSION
  const event = {
    id: item.id,
    color: previousEvent?.color,
    start: isFocusSession ? item.startTime : item.pin.time,
    end: isFocusSession
      ? item.endTime
      : moment(item.pin.time)
          .add(item.estimatedTime / 1000000, 'millisecond')
          .toDate(),
    item,
  }
  dispatch(setLastUserActionTime())
  dispatch(_updateEvent({ idToUpdate, event }))
}

function cancelFetchEvents({ api }) {
  api.calendar.cancel('getRange')
  utils.resolve.cancel('getCalendarEventsRange')
}

export const setEventsLocked = (isLocked) => (dispatch, getState, { api }) => {
  dispatch(_setEventsLocked({ isLocked }))
  if (isLocked) {
    cancelFetchEvents({ api })
    dispatch(_setEventsPendingFetch(true))
  } else if (!isLocked && getState().calendar.hasPendingFetch) {
    dispatch(_setEventsPendingFetch(false))
    dispatch(refetchEvents())
  }
}
