import axios from 'utils/axios'
import { utils } from 'gipsy-misc'

export const get = (id) => {
  const endpoint = `/tasks/${id}`
  return axios.get(endpoint)
}

export const update = (id, body) => {
  const endpoint = `/tasks/${id}`
  return axios.put(endpoint, body)
}

export const putFullTask = (fullTask, recurrenceOption) => {
  let endpoint = `/tasks`

  if (recurrenceOption) {
    endpoint = utils.request.appendToUrl(endpoint, { option: recurrenceOption })
  }

  return axios.put(endpoint, fullTask)
}

export const patch = (id, params, body) => {
  let endpoint = `/tasks/${id}`
  endpoint = utils.request.appendToUrl(endpoint, params)
  return axios.patch(endpoint, body)
}

export const create = (body, context) => {
  let endpoint = `/tasks`
  const headers = utils.request.makeContextHeaders(context)
  return axios.post(endpoint, body, { headers })
}

export const del = (idToUpdate, recurrenceOption) => {
  let endpoint = `/tasks/${idToUpdate}`

  if (recurrenceOption) {
    endpoint = utils.request.appendToUrl(endpoint, { option: recurrenceOption })
  }

  return axios.delete(endpoint)
}

export const complete = (id, context, params) => {
  let endpoint = `/tasks/complete/${id}`
  const headers = utils.request.makeContextHeaders(context)
  if (params) {
    endpoint = utils.request.appendToUrl(endpoint, params)
  }
  return axios.post(endpoint, null, { headers })
}

export const uncomplete = (id) => {
  const endpoint = `/tasks/uncomplete/${id}`
  return axios.post(endpoint)
}

export const uploads = {
  request: () => {
    const endpoint = `/uploads/requests`
    return axios.get(endpoint)
  },
  delete: (taskId, fileId) => {
    const endpoint = `/tasks/${taskId}/uploads/${fileId}`
    return axios.delete(endpoint)
  },
  images: {
    request: () => {
      const endpoint = `/uploads/images/requests`
      return axios.get(endpoint)
    },
  },
}

export const getUrl = (url) => {
  let endpoint = `/url/tasks`
  if (url) {
    endpoint = utils.request.appendToUrl(endpoint, { url })
  }
  return axios.get(endpoint)
}

export const getActive = ({ filterTasksInSprint = false, filterPinnedTasks = false }) => {
  let endpoint = `/active-tasks`
  endpoint = utils.request.appendToUrl(endpoint, { filterTasksInSprint, filterPinnedTasks })
  return axios.get(endpoint)
}

export const repairActive = () => {
  let endpoint = `/active-tasks/repair`
  return axios.post(endpoint)
}

export const getCompleted = () => {
  const endpoint = `/completed-today-tasks`
  return axios.get(endpoint)
}
