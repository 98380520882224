import { chromeext, utils } from 'gipsy-misc'

import { sendMessage } from 'utils/chromeext'

export const isChromeBrowser = navigator.userAgent.indexOf('Chrome') > -1

export const checkIfExtensionIsInstalled = async (user) => {
  if (!isChromeBrowser) return false

  let extensionId

  if (utils.user.isUserInBetaList(user)) {
    extensionId = user.extensionId
  }

  try {
    const { err } = await sendMessage(chromeext.version, extensionId)

    if (err && err.data && err.data.code === chromeext.errCode.messageNotReceived) {
      return false
    }

    return true
  } catch (e) {
    return false
  }
}
