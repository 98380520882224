import PropTypes from 'prop-types'

const propTypes = {
  session: PropTypes.object.isRequired,
  item: PropTypes.object,
  highlight: PropTypes.bool,
  activeTags: PropTypes.array,
  allProjects: PropTypes.array,
  onEditStart: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onComplete: PropTypes.func,
  animateComplete: PropTypes.bool,
  keepJustCompleted: PropTypes.bool,
  hideStartButton: PropTypes.bool,
  grayCheckBox: PropTypes.bool,
  lineThrough: PropTypes.bool,
  onDelete: PropTypes.func,
  onScheduleToToday: PropTypes.func,
  onMoveToTop: PropTypes.func,
  isDraggable: PropTypes.bool,
  hideDraggableCell: PropTypes.bool,
  hideWhenDate: PropTypes.bool,
  isInOnboarding: PropTypes.bool,
  group: PropTypes.string,
  innerLeftPadding: PropTypes.number,
  innerRightPadding: PropTypes.number,
  startCreation: PropTypes.bool,
  keepTitleAfterCreation: PropTypes.bool,
  ignoreOutsideClicks: PropTypes.bool,
  getCurrentPathName: PropTypes.func,
  renderCreateBottomButtons: PropTypes.func,
  disableHover: PropTypes.bool,
  disableEdit: PropTypes.bool,
  canBlockToCalendar: PropTypes.bool,
  draggableProps: PropTypes.object, //optional for draggable list
  dragHandleProps: PropTypes.object, //optional for draggable list
}

export default propTypes
