import React, { useRef, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { models, styles, translations } from 'gipsy-misc'
import {
  CustomRecurrenceSettingsPopup,
  FixedTooltip,
  Icon,
  recurrenceComponentsUtils,
  RecurrencePresetsPopup,
} from 'gipsy-ui'

const { DailyScheduleOptions } = models.recurrency

export default function Recurrency({
  customTypeSettings,
  onChange,
  recurrencyType = DailyScheduleOptions.NoRepeat,
  sprintStartTime,
  firstDayOfWeek,
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showCustomOptions, setShowCustomOptions] = useState(false)

  const popupTriggerRef = useRef(null)

  const toggleExpanded = () => setIsExpanded((prev) => !prev)

  const handlePopupClickOutside = (e) => {
    if (!popupTriggerRef.current.contains(e.target)) {
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    if (!isExpanded) {
      setShowCustomOptions(false)
    }
  }, [isExpanded])

  const handleTypeCliked = (...args) => {
    setIsExpanded(false)
    onChange(...args)
  }

  const handleCustomClicked = () => {
    setShowCustomOptions(true)
  }

  const handleCustomOptionsSaved = (daysSelected) => {
    onChange(DailyScheduleOptions.Custom, { ...customTypeSettings, days: daysSelected })
    setShowCustomOptions(false)
  }

  const hideCustomOptions = () => setShowCustomOptions(false)

  const showCustomOptionsPopup = isExpanded && showCustomOptions
  const showPresetsPopup = isExpanded && !showCustomOptions

  return (
    <Wrapper focused={isExpanded}>
      <PopupTrigger onClick={toggleExpanded} ref={popupTriggerRef}>
        {recurrenceComponentsUtils.getRecurrencyLabel({
          customTypeSettings,
          recurrencyType,
          sprintStartTime,
        })}
        <FixedTooltip
          content={translations.sprint.recurrency.tooltip}
          style={{
            lineHeight: '26px',
            padding: '8px 16px',
            width: '300px',
          }}
          verticalPosition='bottom'>
          <InfoIcon icon={'InfoOutline'} size={12} />
        </FixedTooltip>
      </PopupTrigger>
      {showPresetsPopup && (
        <RecurrencePresetsPopup
          onChange={handleTypeCliked}
          onClickOutside={handlePopupClickOutside}
          onCustomClicked={handleCustomClicked}
          parentRef={popupTriggerRef}
          sprintStartTime={sprintStartTime}
          typeSelected={recurrencyType}
          verticalOffset={8}
        />
      )}
      {showCustomOptionsPopup && (
        <CustomRecurrenceSettingsPopup
          firstDayOfWeek={firstDayOfWeek}
          fullWidth
          customTypeSettings={customTypeSettings}
          onCancel={hideCustomOptions}
          onClickOutside={handlePopupClickOutside}
          onSave={handleCustomOptionsSaved}
          parentRef={popupTriggerRef}
          verticalOffset={14}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  color: ${styles.colors.textMediumDarkColor};
  cursor: pointer;
  font-weight: 400;
  position: relative;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;

  .CustomRecurrenceSettingsPopup,
  .RecurrencePresetsPopup {
    left: unset;
    right: 0;

    .PopupTail {
      left: unset;
      right: 56px;
    }
  }

  &::selection {
    background: ${styles.colors.primaryColor};
    color: white;
  }

  &:hover {
    border-color: ${styles.colors.middleGrey};
    color: ${styles.colors.primaryColor};
  }

  ${({ focused }) =>
    focused &&
    css`
      background-color: ${styles.colors.veryLightGrey};
      border-color: ${styles.colors.primaryColor};

      &:hover {
        border-color: ${styles.colors.primaryColor};
      }
    `}
`

Wrapper.displayName = 'Wrapper'

const PopupTrigger = styled.div`
  align-items: center;
  display: flex;
  padding: 6px 4px;

  &::selection {
    background: ${styles.colors.primaryColor};
    color: white;
  }
`

PopupTrigger.displayName = 'PopupTrigger'

const InfoIcon = styled(Icon)`
  margin-left: 6px;

  circle {
    fill: none;
    stroke: ${styles.colors.darkGrey};
  }

  path {
    fill: ${styles.colors.darkGrey};
    stroke: none;
  }

  &:hover {
    circle {
      stroke: ${styles.colors.primaryColor};
    }

    path {
      fill: ${styles.colors.primaryColor};
    }
  }
`

InfoIcon.displayName = 'InfoIcon'
