import React from 'react'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'

import FixedTooltip from 'FixedTooltip'
import Icon from 'Icon'
import Switch from 'Switch'
import Tooltip from 'Tooltip'

function FocusModeToggle({ isOn = false, onClick, shrinked }) {
  const _onClickSpan = () => {
    onClick({ value: !isOn })
  }

  return (
    <Container isOn={isOn}>
      {shrinked ? (
        <FixedTooltip
          content={translations.general.muteModeTooltip}
          horizontalOffset={-8}
          style={{
            padding: '8px',
          }}
          verticalPosition='bottom'>
          <ZenIcon icon='GipsyZenSimple' onClick={_onClickSpan} size={16} />
        </FixedTooltip>
      ) : (
        <>
          <span onClick={_onClickSpan}>{translations.general.muteMode}</span>
          <IconWrapper>
            <StyledIcon icon={'Info'} size={12} />
            <Tooltip text={`${translations.general.muteModeTooltip}`} vs={25} hs={15} position='bottom right' />
          </IconWrapper>
        </>
      )}
      <Switch className='focus-toggle' paramName='focus-toggle' value={isOn} onChange={onClick} />
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 7px;
  display: flex;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 8px 6px 11px;

  span {
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      color: ${styles.colors.primaryColor};
    }
  }

  user-select: none;

  .focus-toggle {
    height: 20px;
    width: 35px;

    .slider:before {
      bottom: 3px;
      height: 14px;
      left: 3px;
      width: 14px;
    }

    input:checked + .slider:before {
      transform: translateX(15px);
    }
  }
`

Container.displayName = 'Container'

const ZenIcon = styled(Icon)`
  margin-right: 11px;
  width: 19px;

  :hover {
    & path {
      fill: ${styles.colors.primaryColor};
    }
  }
`

ZenIcon.displayName = 'ZenIcon'

const StyledIcon = styled(Icon)`
  margin: 0 8px 0 4px;
`

StyledIcon.displayName = 'StyledIcon'

const IconWrapper = styled.div`
  position: relative;

  &:hover {
    ${StyledIcon} {
      path {
        fill: ${styles.colors.primaryColor};
      }
    }
  }
`

IconWrapper.displayName = 'IconWrapper'

export default FocusModeToggle
