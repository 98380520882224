import React, { useCallback } from 'react'

import AccountInfo from 'features/integrations/components/AccountInfo'

import { asana } from 'gipsy-api'
import { translations } from 'gipsy-misc'

const AsanaAccountInfo = React.memo(({ account, onSyncAccount, onUnsyncAccount, openPopup, closePopup, showModal }) => {
  const _onSyncAccount = async () => {
    await asana.sync(account.id)
    onSyncAccount && onSyncAccount(account.id)
  }

  const _onUnsyncAccount = async () => {
    await asana.unsync(account.id)
    onUnsyncAccount && onUnsyncAccount(account.id)
  }

  const onClickSelectProjects = useCallback(() => {
    showModal(account)
  }, [account, showModal])

  return (
    <AccountInfo
      name={account.name}
      picture={account.picture}
      onSyncAccount={_onSyncAccount}
      onUnsyncAccount={_onUnsyncAccount}
      openPopup={openPopup}
      closePopup={closePopup}
      lastSyncId={'lastAsanaSync_' + account.id}
      onClickSecondaryButton={onClickSelectProjects}
      secondaryButtonLabel={'Manage Projects'}
      unsyncPopupTitle={translations.general.areSure}
      unsyncPopupSubtitle={translations.integrations.apps.asana.popup.delete.prompt}></AccountInfo>
  )
})

export default AsanaAccountInfo
