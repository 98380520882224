import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { bindActionCreators } from 'redux'
import qs from 'qs'

import { getSrc } from 'utils/image'
import { showSnackbar } from 'store/snackbar/actions'
import { getSlackAuthUrlAndStoreState, openIntegrationOAuthWindow } from 'logic/integrationOauth'

import { styles, translations } from 'gipsy-misc'
import { media, PrimaryButton } from 'gipsy-ui'

class SlackLandingPage extends PureComponent {
  state = {
    teamName: '',
    showInstalled: false,
  }

  componentDidMount = () => {
    this.setState({
      teamName: this.props.location.state?.teamName,
      showInstalled: this.props.location.state?.showInstalled,
    })
  }

  onClickSlackButton = async () => {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (window.opener) {
      const authURL = await getSlackAuthUrlAndStoreState(queryParams.teamId, window.opener)
      window.location = authURL
    } else {
      const authURL = await getSlackAuthUrlAndStoreState(queryParams.teamId)
      openIntegrationOAuthWindow(authURL, {
        onSuccess: (responseData) =>
          this.setState({
            showInstalled: true,
            teamName: responseData?.teamName,
          }),
        onError: () => {
          this.props.actions.showSnackbar(
            {
              message: 'Unexpected Error',
              status: 'error',
              showLogo: false,
              showClose: false,
            },
            5000
          )
        },
      })
    }
  }

  render() {
    const { teamName, showInstalled } = this.state
    return (
      <Container className='slack-login-page'>
        {showInstalled ? (
          <>
            <KosmoLogo src='/images/gipsybot-looking-at-you.svg' />
            <Subtitle>{translations.slack.appInstalled.hurray}</Subtitle>
            <Subtitle>
              {translations.slack.appInstalled.description.text1}
              <strong>{teamName || 'selected'}</strong>
              {translations.slack.appInstalled.description.text2}
            </Subtitle>
            <AddAnotherButton
              width={220}
              height={40}
              onClick={this.onClickSlackButton}
              text={translations.slack.addOtherToSlack}></AddAnotherButton>
            <TipTitle>{translations.slack.appInstalled.tip}</TipTitle>
            <TipImg src={getSrc('/images/onboarding/create-task-from-slack.png')} />
            <TipImgLabel>{translations.slack.messageAction}</TipImgLabel>
            <TipImg src={getSrc('/images/onboarding/create-task-from-slack-2.png')} />
            <TipImgLabel>{translations.slack.slashCommand}</TipImgLabel>
          </>
        ) : (
          <>
            <AddSlackContainer>
              <UpperContainer>
                <SlackLogo src='/images/slack_icon.svg' />
                {'+'}
                <KosmoLogo src='/images/gipsybot-default.svg' />
              </UpperContainer>
              <AddSlackTitle>{translations.settings.section.apps.section.slackApp.description}</AddSlackTitle>
              <PrimaryButton
                width={160}
                height={48}
                onClick={this.onClickSlackButton}
                text={translations.slack.addToSlack}></PrimaryButton>
            </AddSlackContainer>

            <TipImg src={getSrc('/images/onboarding/create-task-from-slack.png')} />
            <TipImgLabel>{translations.slack.messageAction}</TipImgLabel>
            <TipImg src={getSrc('/images/onboarding/create-task-from-slack-2.png')} />
            <TipImgLabel>{translations.slack.slashCommand}</TipImgLabel>
          </>
        )}
      </Container>
    )
  }
}

const titleWidth = 750
const textWidth = 640
const paddingLateral = (titleWidth - textWidth) / 2

const textTitleCss = css`
  color: ${styles.colors.textDarkColor};
  font-weight: 500;
  ${media['phone-wide']`
      font-size: 16px;
`}
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  ${media['phone-wide']`
      margin-top:0;
      padding:10px;
`}
`

const KosmoLogo = styled.img`
  height: 88px;
  margin-top: 25px;
`

const Subtitle = styled.div`
  max-width: ${textWidth}px;
  ${textTitleCss};
  font-size: 21px;
  text-align: center;
  line-height: 2;
  strong {
    font-weight: 700;
    font-size: 18px;
    padding: 0 8px;
  }
`

const AddAnotherButton = styled(PrimaryButton)`
  margin-top: 20px;
`

const TipTitle = styled(Subtitle)`
  margin-top: 30px;
  font-size: 18px;
`

const TipImg = styled.img`
  width: 480px;
  margin: 20px 0 10px 0;
`

const TipImgLabel = styled.span`
  font-size: 14px;
  margin-bottom: 20px;
`

const AddSlackContainer = styled.div`
  max-width: ${titleWidth}px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const UpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const SlackLogo = styled.img`
  height: 64px;
  margin: 20px;
`

const AddSlackTitle = styled.div`
  ${textTitleCss};
  margin-bottom: 20px;
  padding-left: ${paddingLateral}px;
  padding-right: ${paddingLateral}px;
`

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showSnackbar,
    },
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(SlackLandingPage)
