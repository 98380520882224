import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

import DatePicker from 'DatePicker'
import Icon from 'Icon'
import Switch from 'Switch'
import Calendar from 'Calendar'
import TimeInput from 'TimeInput2'
import Duration from 'Duration'

import { models, styles, translations, utils } from 'gipsy-misc'

const timeInputWidth = 100

export default class WhenDatePicker extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    pinTime: PropTypes.string,
    shouldBlockInCalendar: PropTypes.bool,
    setInnerRef: PropTypes.func,
    estimatedTime: PropTypes.number,
    showCalendar: PropTypes.bool,
  }

  state = {
    calendarItems: [],
    scrollToTime: new Date(),
  }

  componentDidMount() {
    if (this.props.events) {
      this.computeCalendarEvents()
    }
    this.computeSelectedSlot({ callback: this.setScrollToTime })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const hasValueChanged = prevProps.value !== this.props.value
    const hasPinTimeChanged = prevProps.pinTime !== this.props.pinTime
    if (hasValueChanged || hasPinTimeChanged) {
      this.computeSelectedSlot()
    }
  }

  setScrollToTime = () => {
    const { pinTime } = this.props
    let scrollToTime = new Date().setHours(9, 0, 0, 0)
    if (pinTime) scrollToTime = new Date(pinTime)
    this.setState({ scrollToTime })
  }

  computeCalendarEvents = async () => {
    let { events, editing, itemId } = this.props
    let calendarItems = [...events]
    if (editing) {
      calendarItems = calendarItems.filter((calendarItem) => calendarItem.id !== itemId)
    }

    this.setState({ calendarItems })
  }

  computeSelectedSlot = ({ callback } = {}) => {
    const startTime = moment(this.props.pinTime || utils.datetime.defaultStartTime)
    const estimatedTime =
      this.props.estimatedTime || utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 })
    const startDate = new Date(startTime)
    const endDate = new Date(moment(startTime).add(utils.datetime.convertNanosecondsToMinute(estimatedTime), 'm'))
    this.setState(
      {
        selectedSlot: {
          start: startDate,
          end: endDate,
          item: { title: this.props.title || '', type: models.item.type.TASK },
          isHighlighted: true,
        },
      },
      callback
    )
  }

  bottomContainerClick = (e) => {
    e.stopPropagation()
    return false
  }

  selectToday = () => {
    this.props.onChange({
      paramName: 'when.date',
      value: moment().format('YYYY-MM-DD'),
    })
  }

  selectTomorrow = () => {
    this.props.onChange({
      paramName: 'when.date',
      value: moment().add(1, 'days').format('YYYY-MM-DD'),
    })
  }

  selectNoDate = () => {
    this.props.onChange({
      paramName: 'when.date',
      value: '',
    })
  }

  toggleBlockInCalendar = ({ value }) => {
    const { estimatedTime, onChange } = this.props
    if (!value) {
      onChange({ paramName: 'pin.time', value: null })
    } else {
      onChange(
        {
          paramName: 'pin.time',
          value: utils.datetime.defaultStartTime,
        },
        {
          estimatedTime:
            estimatedTime ||
            utils.datetime.getNanosecondsFromHourAndMinute({
              hour: 0,
              minute: 30,
            }),
        }
      )
    }
    this.setState({
      shouldShowBlockInCalendarDetails: value,
    })
  }

  onChangeEstimatedTime = (value) => {
    const { onChange } = this.props
    const newEstimatedTime = utils.datetime.getNanosecondsFromHourAndMinute(value)
    onChange({ paramName: 'estimatedTime', value: newEstimatedTime })

    const { selectedSlot } = this.state
    if (selectedSlot.start) {
      let newState = {}
      const newEnd = new Date(this.props.value)
      newEnd.setMinutes(newEnd.getMinutes() + utils.datetime.convertNanosecondsToMinute(newEstimatedTime))
      newState.selectedSlot = { ...selectedSlot, end: newEnd }
      this.setState(newState)
    }
  }

  onChangeStartTime = (value) => {
    const { onChange, estimatedTime } = this.props
    onChange({ paramName: 'pin.time', value })

    const startTime = moment(this.props.pinTime || utils.datetime.defaultStartTime)
    let newStartTime = moment(value).date(startTime.date()).month(startTime.month()).year(startTime.year())
    const { selectedSlot } = this.state
    if (selectedSlot.start) {
      let newState = {}
      const newStart = new Date(newStartTime)
      const newEnd = new Date(newStart)
      newEnd.setMinutes(newEnd.getMinutes() + utils.datetime.convertNanosecondsToMinute(estimatedTime))
      newState.selectedSlot = { ...selectedSlot, start: newStart, end: newEnd }
      this.setState(newState)
    }
  }

  onChangeWhenDate = ({ value }) => {
    const { onChange } = this.props
    onChange({ paramName: 'when.date', value })
  }

  toggleBlockInCalendarDetails = () => {
    this.setState({
      shouldShowBlockInCalendarDetails: !this.state.shouldShowBlockInCalendarDetails,
    })
  }

  onSelectSlot = ({ start, end }) => {
    const updateCalendarData = () => {
      const startTime = moment(start)
      const endTime = moment(end)
      const diff = endTime.diff(startTime, 'minutes')
      const estimatedTime = utils.datetime.convertMinuteToNanoseconds(diff)

      const { onChange } = this.props
      onChange(
        { paramName: 'pin.time', value: start },
        {
          estimatedTime,
        }
      )
    }

    if (!this.props.shouldBlockInCalendar) {
      this.setState(
        {
          shouldShowBlockInCalendarDetails: true,
        },
        updateCalendarData
      )
    } else {
      updateCalendarData()
    }
  }

  render() {
    const {
      className,
      value,
      shouldBlockInCalendar,
      showCalendar,
      setInnerRef,
      estimatedTime,
      pinTime,
      style,
      firstDayOfWeek,
    } = this.props
    const { selectedSlot, shouldShowBlockInCalendarDetails, calendarItems, scrollToTime } = this.state
    const hour = pinTime ? moment(pinTime).hours() : null
    const minute = pinTime ? moment(pinTime).minutes() : null

    const estimatedTimeFromNS = utils.datetime.getHourAndMinuteFromNanoseconds(estimatedTime)
    const activeColor = styles.colors.primaryColor
    const activeBackgroundColor = styles.colors.veryLightGrey
    const inactiveColor = styles.colors.darkGrey
    const showRightColumn = showCalendar && !utils.datetime.isBacklog(value)
    return (
      <Container onClick={this.bottomContainerClick} className={className} ref={setInnerRef} style={style}>
        <Column>
          <Column>
            <DateSelectorSection
              onClick={this.selectToday}
              color={utils.datetime.isToday(value) ? activeColor : inactiveColor}
              backgroundColor={utils.datetime.isToday(value) && activeBackgroundColor}>
              <IconWrapper>
                <Icon size={16} icon='Calendar' fill={utils.datetime.isToday(value) ? activeColor : inactiveColor} />
              </IconWrapper>
              <span>{translations.general.today}</span>
            </DateSelectorSection>

            <DateSelectorSection
              onClick={this.selectTomorrow}
              color={utils.datetime.isTomorrow(value) ? activeColor : inactiveColor}
              backgroundColor={utils.datetime.isTomorrow(value) && activeBackgroundColor}>
              <IconWrapper>
                <Icon size={16} icon='Sun' fill={utils.datetime.isTomorrow(value) ? activeColor : inactiveColor} />
              </IconWrapper>
              {translations.general.tomorrow}
            </DateSelectorSection>

            <DateSelectorSection
              onClick={this.selectNoDate}
              color={utils.datetime.isBacklog(value) ? activeColor : inactiveColor}
              backgroundColor={utils.datetime.isBacklog(value) && activeBackgroundColor}>
              <IconWrapper>
                <Icon
                  size={16}
                  icon='CalendarNoDate'
                  fill={utils.datetime.isBacklog(value) ? activeColor : inactiveColor}
                />
              </IconWrapper>
              {translations.general.noDate}
            </DateSelectorSection>
          </Column>

          <DatePicker
            onClick={this.bottomContainerClick}
            paramName={'when.date'}
            selectedDay={value}
            onChange={this.onChangeWhenDate}
            firstDayOfWeek={firstDayOfWeek}
          />
        </Column>
        {showRightColumn && (
          <RightColumn>
            <StyledCalendar
              calendarItems={shouldBlockInCalendar ? [selectedSlot, ...calendarItems] : [...calendarItems]}
              date={new Date(value)}
              hideTimeHeader
              scrollToTime={scrollToTime}
              onSelectSlot={this.onSelectSlot}
              firstDay={firstDayOfWeek}
            />
            <SubSection marginTop={'14px'}>
              <StyledSwitch
                size={'small'}
                className='block-in-calendar'
                paramName='shouldBlockInCalendar'
                onChange={this.toggleBlockInCalendar}
                value={!!shouldBlockInCalendar}
              />
              {translations.taskPanel.when.shouldBlockInCalendar}
              {shouldBlockInCalendar && (
                <IconWrapper marginTop={'12px'} marginLeft={'auto'} onClick={this.toggleBlockInCalendarDetails}>
                  <Icon
                    size={10}
                    icon={shouldShowBlockInCalendarDetails ? 'SingleChevronLeft' : 'SingleChevronDown'}
                    fill={styles.colors.darkGrey}
                  />
                </IconWrapper>
              )}
            </SubSection>
            <AnimateHeight
              duration={300}
              height={shouldBlockInCalendar && shouldShowBlockInCalendarDetails ? 'auto' : 0}>
              <Row alignCenter>
                <Row alignCenter justifyLeft>
                  <Label>{translations.general.start}</Label>

                  <TimeInput
                    width={timeInputWidth}
                    transparent={true}
                    showPeriod={true}
                    format='12h'
                    hour={hour}
                    minute={minute}
                    paramName='pin.time'
                    onChange={this.onChangeStartTime}
                  />
                </Row>
                <Row alignCenter justifyLeft>
                  <Label>{translations.general.duration}</Label>

                  <Duration
                    width={timeInputWidth}
                    inputWidth={24}
                    transparent={true}
                    paramName='estimatedTime'
                    hour={estimatedTimeFromNS.hour}
                    minute={estimatedTimeFromNS.minute}
                    onChange={this.onChangeEstimatedTime}
                  />
                </Row>
              </Row>
            </AnimateHeight>
          </RightColumn>
        )}
      </Container>
    )
  }
}

const SubSection = styled.div`
  cursor: ${(props) => props.cursor || 'pointer'};
  display: flex;
  color: ${(props) => props.color || styles.colors.textDarkColor};
  background-color: ${(props) => props.backgroundColor || 'inherit'};
  border-radius: 8px;
  font-size: ${styles.fonts.fontSizeXSmall};
  text-decoration: ${(props) => props.textDecoration};
  line-height: 35px;
  margin-top: ${(props) => props.marginTop || 'inherit'};
  > :first-child {
    margin-right: 12px;
  }
  & .block-in-calendar {
    margin-top: 8px;
  }
`

const DateSelectorSection = styled(SubSection)`
  padding: 0 11px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.justifyLeft ? 'flex-start' : 'space-between')};
  align-items: ${(props) => (props.alignCenter ? 'center' : 'flex-start')};
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const RightColumn = styled(Column)`
  margin-left: 24px;
  width: 312px;
`

const Label = styled.span`
  font-size: ${styles.fonts.fontSizeXSmall};
  color: ${styles.colors.textMediumDarkColor};
`

export const datePickerStyles = css`
  & .react-datepicker__month-container {
    font-size: ${styles.fonts.fontSizeXXSmall};
  }
  & .react-datepicker__day-names {
    & .react-datepicker__day-name {
      &:not(:first-child) {
        margin: 0 6px;
      }
      width: 24px;
    }
  }
  & .react-datepicker__week {
    & .react-datepicker__day {
      &.react-datepicker__day--selected {
        background-color: ${styles.colors.primaryColor};
        border-radius: 6px;
      }
      &:not(:first-child) {
        margin: 0 6px;
      }
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }

  & .react-datepicker__day--keyboard-selected {
    background-color: unset;
    color: unset;
  }
`

const Container = styled.div`
  height: 350px;
  display: flex;
  background-color: white;
  box-shadow: 0 16px 48px ${styles.colors.shadowColor};
  border-radius: 8px;
  padding: 20px 16px 20px 16px;
  ${datePickerStyles}
`

const StyledCalendar = styled(Calendar)`
  width: 313px;
  overflow: scroll;
  border-radius: 8px;
  background-color: ${styles.colors.veryLightGrey};
`

const StyledSwitch = styled(Switch)`
  input:checked + .slider {
    background-color: ${styles.colors.primaryColor} !important;
  }
`

export const IconWrapper = styled.div`
  margin-top: ${(props) => props.marginTop || '8px'};
  margin-right: 6px;
  margin-left: ${(props) => props.marginLeft || 'inherit'};
`
