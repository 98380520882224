import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { styles } from 'gipsy-misc'

const propTypes = {
  tag: PropTypes.object.isRequired,
}

class DropdownSelectedValue extends Component {
  static propTypes = propTypes

  render() {
    const { tag, styles: propStyles = {} } = this.props
    return (
      <TagContainer className="tag-container" {...propStyles.tag}>
        <LabelContainer {...propStyles.label}>{tag.name}</LabelContainer>
      </TagContainer>
    )
  }
}

export { DropdownSelectedValue }

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${styles.colors.darkGrey};
  height: 26px;
  padding: 5px;
  ${(props) => props};
`

const LabelContainer = styled.span`
  font-size: ${styles.fonts.fontSizeSmall};
  color: ${styles.colors.veryLightGrey};
  ${(props) => props};
`
