import { type } from './item'

const initialTask = (params) => ({
  completed: 0,
  description: '',
  estimatedTime: 0,
  when: {
    date: '',
  },
  to: null,
  title: '',
  uploads: [],
  type: type.TASK,
  projectsRank: {},
  ...params,
})

export default initialTask
