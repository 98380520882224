import { useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { emailaccounts } from 'gipsy-api'

import { extractStateParameters, clearOauthDataFromSessionStorage } from 'logic/auth'
import { getGoogleAuthUrlAndStoreState, getMicrosoftAuthUrlAndStoreState } from 'logic/integrationOauth'
import { getAccounts } from 'store/accounts/actions'
import { refetchEvents } from 'store/calendar/actions'

const GOOGLE = 'google'
const MICROSOFT = 'microsoft'

export default function useSignInWindow() {
  const dispatch = useDispatch()

  const previousUrl = useRef(null)
  const windowObjectRef = useRef(null)

  const onSyncAccount = useCallback(() => {
    dispatch(getAccounts())
    dispatch(refetchEvents())
  }, [dispatch])

  const openSignInWindow = useCallback(
    (url, name, onSignedIn) => {
      const receiveMessage = async (event) => {
        if (event.origin !== process.env.REACT_APP_FRONTEND_URL) {
          return
        }
        const {
          data,
          data: { searchParams },
        } = event
        if (!searchParams || !searchParams.code) return
        if (searchParams.error) {
          console.error(searchParams.error)
          return
        }
        if (data.source === 'revalidate-oauth') {
          try {
            const { error, state, localStateGoogle, localStateMicrosoft, code, nonce } = extractStateParameters(
              searchParams
            )

            const querySucceeded = !error && code
            if (!querySucceeded) {
              throw new Error('Query failed.')
            }
            let provider
            if (state === localStateGoogle) {
              provider = GOOGLE
            } else if (state === localStateMicrosoft) {
              provider = MICROSOFT
            } else {
              throw new Error('Unknown OAuth method.')
            }
            const redirectURL = window.location.origin + '/revalidateoauthcallback'
            await emailaccounts.add(provider, code, nonce, redirectURL)
            onSyncAccount()
            onSignedIn?.()
          } catch (err) {
            console.error(searchParams.error)
          } finally {
            if (windowObjectRef.current) {
              windowObjectRef.current.close()
            }
          }
        }
      }
      const width = 700
      const height = 800
      let left = window.screenLeft + window.outerWidth / 2 - width / 2
      let top = window.screenTop + window.outerHeight / 2 - height / 2
      const strWindowFeatures = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top} left=${left}`

      if (windowObjectRef.current === null || windowObjectRef.current.closed) {
        windowObjectRef.current = window.open(url, name, strWindowFeatures)
      } else if (previousUrl.current !== url) {
        windowObjectRef.current = window.open(url, name, strWindowFeatures)
        windowObjectRef.current.focus()
      } else {
        windowObjectRef.current.focus()
      }
      const parentWindow = window

      let listener = setInterval(function () {
        if (windowObjectRef.current?.closed) {
          window.removeEventListener('message', receiveMessage)
          clearInterval(listener)
          clearOauthDataFromSessionStorage(parentWindow)
          windowObjectRef.current = null
        }
      }, 300)

      window.addEventListener('message', receiveMessage, false)
      previousUrl.current = url
    },
    [onSyncAccount]
  )

  const signInWithGoogle = useCallback(
    async (email, onSignedIn) => {
      const redirectURL = window.location.origin + '/revalidateoauthcallback'
      const authURL = await getGoogleAuthUrlAndStoreState(true, redirectURL, email)
      openSignInWindow(authURL, 'revalidateoauthcallback', onSignedIn)
    },
    [openSignInWindow]
  )

  const signInWithMicrosoft = useCallback(
    async (email, onSignedIn) => {
      const redirectURL = window.location.origin + '/revalidateoauthcallback'
      // if we force the consent, then microsoft automatically choose an email
      // hence the user can't decide which account he wants to sign in with
      const forceConsent = !!email
      const authURL = await getMicrosoftAuthUrlAndStoreState(forceConsent, redirectURL, email)
      openSignInWindow(authURL, 'revalidateoauthcallback', onSignedIn)
    },
    [openSignInWindow]
  )

  const reauthenticate = useCallback(
    (provider, email, onSignedIn) => {
      if (provider === GOOGLE) {
        signInWithGoogle(email, onSignedIn)
      } else if (provider === MICROSOFT) {
        signInWithMicrosoft(email, onSignedIn)
      } else {
        console.warn('unknown provider')
      }
    },
    [signInWithGoogle, signInWithMicrosoft]
  )

  return {
    onSyncAccount,
    reauthenticate,
    signInWithGoogle,
    signInWithMicrosoft,
  }
}
