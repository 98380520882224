import styled from 'styled-components'
import { styles } from 'gipsy-misc'

const SideIconContainer = styled.div`
  position: absolute;
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  display: block;
  :hover {
    path {
      fill: ${styles.colors.textDarkColor};
    }
  }
`

export default SideIconContainer
