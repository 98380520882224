import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import noop from 'lodash/noop'
import PlayIcon from 'PlayIcon'
import Checkbox from 'Checkbox'

import { styles, translations } from 'gipsy-misc'

const completionDuration = 0.3

export function Controls(props) {
  const {
    item,
    onStartFocus,
    onComplete,
    onJustCompleted,
    keepJustCompleted,
    isTaskOnFocusSession,
    focusedTaskId,
    disableUncomplete,
  } = props
  const [startButtonHovered, setStartButtonHovered] = useState(false)
  const [justCompleted, setJustCompleted] = useState(false)
  const playIconRef = useRef(null)
  const isStartButtonDisabled = !!focusedTaskId

  useEffect(() => {
    if (item.completed && justCompleted) {
      setJustCompleted(false)
    }
  }, [item.completed, justCompleted])

  const onClickStartButton = () => {
    const urlsToOpen = (item.urlsInfo || []).map((urlInfoItem) => urlInfoItem.url)
    onStartFocus({ urlsToOpen })
  }

  const onPlayHovered = useCallback(() => {
    setStartButtonHovered(true)
  }, [])

  const onPlayUnhovered = useCallback(() => {
    setStartButtonHovered(false)
  }, [])

  const _onComplete = () => {
    const itemData = {
      id: item.id,
      paramName: 'completed',
      value: 1,
    }

    setJustCompleted(true)
    onJustCompleted()
    return setTimeout(() => {
      onComplete(itemData)
      if (!keepJustCompleted) {
        setJustCompleted(false)
      }
    }, completionDuration * 1000)
  }

  const isCheckboxDisabled = disableUncomplete && (item.completed || justCompleted)
  return (
    <ControlsContainer
      onClick={!isStartButtonDisabled && startButtonHovered ? onClickStartButton : noop}
      onMouseLeave={onPlayUnhovered}>
      <PlayIconPlaceholder disabled={isStartButtonDisabled} />
      <StartControlBackground
        animate={{ width: !isStartButtonDisabled && startButtonHovered ? '100%' : '0%' }}
        transition={{ duration: 0.3 }}
      />
      <PlayIconContainer onMouseEnter={onPlayHovered} ref={playIconRef}>
        {!isStartButtonDisabled && <PlayIcon fillColor={styles.colors.primaryColor} />}
      </PlayIconContainer>
      <StartLabel
        animate={startButtonHovered ? 'entering' : 'leaving'}
        variants={{
          entering: {
            opacity: 1,
          },
          leaving: { opacity: 0 },
        }}>
        {translations.general.start}
      </StartLabel>
      <Checkbox
        id={item.id}
        borderWidth={1.5}
        checked={!!item.completed || justCompleted}
        checkmarkBorderWidth={1.5}
        checkmarkHeight={6}
        checkmarkLeft={4.5}
        checkmarkTop={1.2}
        checkmarkWidth={3}
        onChange={isCheckboxDisabled ? noop : _onComplete}
        paramName="completed"
        hide={isTaskOnFocusSession ? 1 : 0}
        size={16}
        disabled={isCheckboxDisabled}
        checkedColor={styles.colors.middleGrey}
        checkMarkColor={styles.colors.darkGrey}
        hoverCheckMarkColor={styles.colors.darkGrey}
        hoverBackgroundColor={'white'}
        hoverBorderColor={styles.colors.darkGrey}
        borderColor={styles.colors.darkGrey}
      />
    </ControlsContainer>
  )
}

const ControlsContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 31px 16px;
  padding: 10px 12px 10px 16px;
  position: relative;
`
const PlayIconPlaceholder = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 100%;
`

const PlayIconContainer = styled(motion.div)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  padding: 10px 0 10px 8px;
  position: absolute;
  z-index: 1;
`

const StartControlBackground = styled(motion.div)`
  background-color: ${styles.colors.primaryColor};
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  height: 100%;
  left: 0;
  max-width: calc(100% - 4px);
  padding: 10px 0;
  position: absolute;
  width: 0;
  z-index: 1;
`

const StartLabel = styled(motion.span)`
  color: white;
  display: block;
  font-size: 13px;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`
