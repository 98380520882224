import React from 'react'
import styled from 'styled-components'
import parser from 'react-html-parser'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, styles, translations } from 'gipsy-misc'
import { SimpleButton } from 'gipsy-ui'

import { PageContainer } from 'features/auth/components'
import FeaturesColumn, { Container, Logo } from 'pages/login/components/FeaturesColumn'
import { getSrc } from 'utils/image'

export default function MultiCalendarPrompt({ onStepDone, stepNumber }) {
  const handleDismiss = () => {
    mixpanelApi.track({ event: mixpanel.onboardingMulticalendarPromptEvent }, 'Maybe Later')
    onStepDone(stepNumber, false)
  }

  const handleAccept = () => {
    mixpanelApi.track({ event: mixpanel.onboardingMulticalendarPromptEvent }, 'YES,please!')
    onStepDone(stepNumber, true)
  }

  return (
    <StepContainer>
      <FeaturesColumn multiCalendarSyncExp />
      <PromptColumn>
        <Prompt>
          <PromptLogo alt='Prompt Logo' src={getSrc('/images/gipsybot-default.svg')} />
          <PromptTitle>{parser(translations.onboarding.multiCalendarPromptStep.title)}</PromptTitle>
          <PromptButtons>
            <DismissButton
              backgroundColor='transparent'
              borderColor={styles.colors.primaryColor}
              height={48}
              onClick={handleDismiss}
              text={translations.onboarding.multiCalendarPromptStep.dismiss}
              textColor={styles.colors.primaryColor}
              width={182}
            />
            <AcceptButton
              height={48}
              onClick={handleAccept}
              text={translations.onboarding.multiCalendarPromptStep.accept}
              width={182}
            />
          </PromptButtons>
        </Prompt>
      </PromptColumn>
    </StepContainer>
  )
}

export const StepContainer = styled(PageContainer)`
  flex-flow: row;
  justify-content: flex-start;

  ${Logo} {
    opacity: 0;
  }
`

StepContainer.displayName = 'StepContainer'

export const PromptColumn = styled(Container)`
  align-items: center;
  background-color: unset;
  padding: 0 24px;
  width: 60%;
`

PromptColumn.displayName = 'PromptColumn'

export const Prompt = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: ${styles.shadows.taskMenuHover};
  max-width: 476px;
  min-width: 48%;
  padding: 64px 48px 32px;
  position: relative;
`

Prompt.displayName = 'Prompt'

export const PromptLogo = styled.img`
  left: 50%;
  position: absolute;
  top: -52px;
  transform: translateX(-50%);
  width: 90px;
`

PromptLogo.displayName = 'PromptLogo'

export const PromptTitle = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 36px;
  text-align: center;
`

PromptTitle.displayName = 'PromptTitle'

const PromptButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

PromptButtons.displayName = 'PromptButtons'

const DismissButton = styled(SimpleButton)`
  font-size: 16px;
  margin-right: 16px;
`

DismissButton.displayName = 'DismissButton'

const AcceptButton = styled(DismissButton)`
  display: block;
  margin-right: 0;
`

AcceptButton.displayName = 'AcceptButton'
