import { types } from './actions'

const initialState = {
  visible: false,
  message: null,
  status: 'default' /* success, error, default */,
  showLogo: false,
  actionLabel: null,
  actionCallback: null,
  showClose: false,
}

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SNACKBAR:
      return {
        ...state,
        visible: true,
        ...action.payload,
      }

    case types.HIDE_SNACKBAR:
      return {
        ...state,
        visible: false,
      }

    default:
      break
  }
  return state
}

export default snackbarReducer
