import * as datetime from 'utils/datetime'
import { isAllDayEvent } from 'utils/event'

/* ********************************************************************** */
/* ********************************************************************** */
/* ********************** Badges Methods for Events ********************* */
/* ********************************************************************** */
/* ********************************************************************** */

const getEventTimeDetails = (event, currentTime) => {
  const STARTING_SOON_CAP = 30
  const pin = event.pin && event.pin.time
  if (isAllDayEvent(event) || !pin) {
    return false
  }
  const diffMinutes = (new Date(pin).getTime() - currentTime.getTime()) / (1000 * 60)

  const isStartingSoon = diffMinutes >= 0 && diffMinutes <= STARTING_SOON_CAP
  const isInProgress =
    diffMinutes <= 0 && -diffMinutes < event.estimatedTime / (60 * datetime.durationTimeUnitsBySecond)
  return { isStartingSoon, isInProgress, diffMinutes: Math.ceil(diffMinutes) }
}

export const getBadgesDataForEvent = (event) => {
  return {
    isAllDay: isAllDayEvent(event),
  }
}

/* this method should be called every x seconds to have realtime updates */
export const getBadgesTimeDataForEvent = (event) => {
  return getEventTimeDetails(event, new Date())
}
