import moment from 'moment'

import { setLastUserActionTime } from 'store/session/actions'

const initDelayedHandler = () => {
  const delayedFns = {}

  const doWithDelay = (id, fn, { delay = 5000, interval = 1000 } = {}) => (dispatch, getState) => {
    if (delayedFns[id]) {
      clearTimeout(delayedFns[id].timeout)
    } else {
      delayedFns[id] = {}
    }

    delayedFns[id].fn = fn

    const delayHandler = () => {
      delayedFns[id].timeout = setTimeout(() => {
        const now = moment()
        const lastActionTime = getState().session.lastActionTime

        if (!lastActionTime) {
          dispatch(setLastUserActionTime())
          clearTimeout(delayedFns[id].timeout)
          delayHandler()
          return
        }

        const nextFunctionTime = moment(lastActionTime).add(delay, 'milliseconds')

        if (now.isAfter(nextFunctionTime)) {
          const delayedFn = delayedFns[id].fn
          delayedFn?.()
          delete delayedFns[id]
        } else {
          delayHandler()
        }
      }, interval)
    }

    delayHandler()
  }

  return doWithDelay
}

export const doAfterUserAction = initDelayedHandler()

export const fetchAndConsumeDataAfterUserAction = (
  fetcherFn,
  consumerFn,
  { fallbackFn, spanAfterUserAction = 5000 } = {}
) => async (dispatch, getState) => {
  let data

  try {
    data = await fetcherFn()
  } catch (err) {
    if (fallbackFn) {
      fallbackFn(err)
    } else {
      console.error(err)
    }

    return
  }

  const lastActionTime = getState().session.lastActionTime

  if (!lastActionTime) {
    return consumerFn(data)
  }

  const unblockTime = moment(lastActionTime).add(spanAfterUserAction, 'milliseconds')
  const now = moment()

  if (now.isAfter(unblockTime)) {
    return consumerFn(data)
  }

  const wait = unblockTime.diff(now)
  setTimeout(() => {
    dispatch(fetchAndConsumeDataAfterUserAction(fetcherFn, consumerFn, { fallbackFn, spanAfterUserAction }))
  }, wait)
}
