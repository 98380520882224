import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import produce from 'immer'

import { calendarlist } from 'gipsy-api'
import { models, translations } from 'gipsy-misc'
import { CustomPopup, buttonType } from 'gipsy-ui'

import useSignInWindow from 'features/hooks/useSignInWindow'
import RealTime from 'features/realTime'
import AddAccount from 'features/addAccount'
import { setAccounts } from 'store/accounts/actions'
import { getAccounts as getAccountSelector } from 'store/accounts/selectors'
import { refetchEvents } from 'store/calendar/actions'

import AccountItem from './AccountItem'
import SettingsLightbox from './SettingsLightbox'
import SyncingOverlay from './SyncingOverlay'

export default function Accounts() {
  const dispatch = useDispatch()
  const emailAccounts = useSelector((state) => getAccountSelector(state))
  const { onSyncAccount, reauthenticate } = useSignInWindow()

  const [showSettingsLightbox, setShowSettingsLightbox] = useState(false)
  const [showSyncincOverlay, setShowSyncingOverlay] = useState(false)
  const [syncingMap, setSyncingMap] = useState({})

  useEffect(() => {
    const syncing = Object.values(syncingMap).some((calendarSyncing) => !!calendarSyncing)
    setShowSyncingOverlay(syncing)
  }, [syncingMap])

  const updateCalendarState = (email, calendar) => {
    const updatedAccounts = produce(emailAccounts, (draft) => {
      const updatedAccount = draft.find((account) => account.email === email)

      if (updatedAccount) {
        const updatedCalendar = updatedAccount.calendarsList.find(
          (draftCalendar) => draftCalendar.calendarId === calendar.calendarId
        )
        if (updatedCalendar) {
          updatedCalendar.isSelected = !updatedCalendar.isSelected
        }
      }
    })

    dispatch(setAccounts(updatedAccounts))
  }

  const onToggleSelect = async (email, calendar) => {
    const mapKey = `${email}_${calendar.calendarId}`

    if (syncingMap[mapKey]) {
      return
    }

    const selectedCalendar = cloneDeep(calendar)
    updateCalendarState(email, calendar)
    setSyncingMap((prev) => {
      const updatedMap = produce(prev, (draft) => {
        draft[mapKey] = true
      })

      return updatedMap
    })

    let funcToCall = selectedCalendar.isSelected ? calendarlist.unselect : calendarlist.select

    try {
      await funcToCall(email, selectedCalendar.calendarId)
      RealTime.publishMessage('', [models.realtime.topics.calendarList, models.realtime.topics.events])
      await dispatch(refetchEvents())
    } catch (err) {
      console.error(err)
    } finally {
      setSyncingMap((prev) => {
        const updatedMap = produce(prev, (draft) => {
          draft[mapKey] = false
        })

        return updatedMap
      })
    }
  }

  const onChangeDefaultCalendar = async (email, calendar) => {
    try {
      await calendarlist.selectDefault(email, calendar.calendarId)
      const updatedAccounts = produce(emailAccounts, (draft) =>
        draft.forEach((account) => {
          account.calendarsList?.forEach?.((draftCalendar) => {
            draftCalendar.appDefaultWriteCalendar =
              account.email === email && calendar.calendarId === draftCalendar.calendarId
          })
        })
      )

      dispatch(setAccounts(updatedAccounts))
    } catch (err) {
      console.error(err)
    }
  }

  const onUnsyncAccount = (email) => {
    const updatedAccounts = produce(emailAccounts, (draft) => {
      const accountToRemoveIdx = draft.findIndex((account) => account.email === email)

      if (accountToRemoveIdx) {
        draft.splice(accountToRemoveIdx, 1)
      }
    })

    dispatch(setAccounts(updatedAccounts))
    dispatch(refetchEvents())
  }

  const onOpenSettings = () => {
    setShowSettingsLightbox(true)
  }

  const onCloseSettingsLightbox = () => {
    setShowSettingsLightbox(false)
  }

  return (
    <Container>
      {emailAccounts.map((account, index) => (
        <AccountItem
          account={account}
          key={index}
          onChangeDefaultCalendar={onChangeDefaultCalendar}
          onOpenSettings={onOpenSettings}
          onReauthenticate={reauthenticate}
          onSyncAccount={onSyncAccount}
          onToggleSelect={onToggleSelect}
          onUnsyncAccount={onUnsyncAccount}
          removeBorder={index === emailAccounts.length - 1}
          syncingMap={syncingMap}
        />
      ))}
      <StyledAddAccount text={translations.calendarList.addAccount} type={buttonType.primary} />
      {showSettingsLightbox && <SettingsLightbox onClose={onCloseSettingsLightbox} />}
      {showSyncincOverlay && <SyncingOverlay />}
    </Container>
  )
}

const Container = styled.div``

Container.displayName = 'Container'

const AddAccountContainer = styled.div`
  position: relative;
  width: 100%;
`

AddAccountContainer.displayName = 'AddAccountContainer'

const StyledAddAccount = styled(AddAccount)`
  margin-top: 16px;
`

StyledAddAccount.displayName = 'StyledAddAccount'

const LoginPopup = styled(CustomPopup)`
  background-color: white;
  left: 50%;
  margin: 0;
  padding: 20px;
  top: calc(100% + 8px);
  transform: translateX(-50%);
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

LoginPopup.displayName = 'LoginPopup'
