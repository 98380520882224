import { createAction } from 'redux-actions'
import moment from 'moment'

import types from './types'
import { getCurrentCalendarDateBounds } from 'store/calendar/actions'
import { setLastUserActionTime } from 'store/session/actions'
import { fetchAndConsumeDataAfterUserAction } from 'store/utils/actions'

import { utils } from 'gipsy-misc'

const _addItem = createAction(types.ADD_ITEM)
const _addItems = createAction(types.ADD_ITEMS)
const _removeItem = createAction(types.REMOVE_ITEM)
const _removeItems = createAction(types.REMOVE_ITEMS)
const _replaceItem = createAction(types.REPLACE_ITEM)
const _replaceItems = createAction(types.REPLACE_ITEMS)
const setAllItems = createAction(types.SET_ALL_ITEMS)
const _updateItem = createAction(types.UPDATE_ITEM)
const _updateItems = createAction(types.UPDATE_ITEMS)

export const addItem = (item) => (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  dispatch(_addItem(item))
  const updatedItems = getState().items
  return updatedItems
}

export const addItems = (items) => (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  dispatch(_addItems(items))
  const updatedItems = getState().items
  return updatedItems
}

export const fetchAllItems = () => async (dispatch, getState, { api }) => {
  const user = getState().session.user
  const calendarDateRange = getState().calendar.items.allDates
  let { start, end } = getCurrentCalendarDateBounds(calendarDateRange)

  if (moment(start).isSame(moment(end))) {
    const today = moment().format('YYYY-MM-DD')
    const begOfWeek = utils.date.getStartOfWeek(today, user.settingsPreferences.calendar.firstDay)
    start = moment(begOfWeek).subtract(14, 'days').format('YYYY-MM-DD')
    end = moment(begOfWeek).add(14, 'days').format('YYYY-MM-DD')
  }

  dispatch(
    fetchAndConsumeDataAfterUserAction(
      async () => {
        return await Promise.all([
          api.task.getActive({ filterTasksInSprint: true, filterPinnedTasks: false }),
          api.sprint.getWithTasks(true, start, end),
          api.task.getCompleted(),
        ])
      },
      (items) => {
        const [activeTasks, sprints, completedTasks] = items
        dispatch(
          setAllItems({
            sprints: sprints || [],
            tasks: [...activeTasks, ...completedTasks],
          })
        )
      }
    )
  )
}

export const removeItem = (item) => (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  dispatch(_removeItem(item))
  const updatedItems = getState().items
  return updatedItems
}

export const removeItems = (items) => (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  dispatch(_removeItems(items))
  const updatedItems = getState().items
  return updatedItems
}

export const replaceItem = (item, replacement) => (dispatch) => {
  dispatch(setLastUserActionTime())
  dispatch(_replaceItem({ item, replacement }))
}

export const replaceItems = (items, replacements) => (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  dispatch(_replaceItems({ items, replacements }))
  const updatedItems = getState().items
  return updatedItems
}

export const updateItem = (item) => (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  dispatch(_updateItem(item))
  const updatedItems = getState().items
  return updatedItems
}

export const updateItems = (items) => (dispatch, getState) => {
  dispatch(setLastUserActionTime())
  dispatch(_updateItems(items))
  const updatedItems = getState().items
  return updatedItems
}
