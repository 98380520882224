import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { translations } from 'gipsy-misc'

import {
  CloseIcon,
  Content,
  StyledBlurLayer,
  Subtitle,
  Title,
  TitleContainer,
} from 'features/calendar/components/CalendarList/components/commonUIComponents'
import { layoutBlurLayerPortalId } from 'features/layout/index'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

import SyncAccountsPopup from './SyncAccountsPopup'
import SyncExamples from './SyncExamples'
import SyncOptions from './SyncOptions'

const componentName = 'MultiSync'

export default function MultiSync({
  numAccounts,
  invalidCredentialsCount,
  isSyncing,
  mainAccount,
  onClose,
  onSaveSyncOption,
  onStartSync,
  syncAccountsPopupSettings,
  syncOptionSaved,
}) {
  const dispatch = useDispatch()

  const [syncOption, setSyncOption] = useState(syncOptionSaved)

  useEffect(() => {
    dispatch(pushShortcutsGroup([], componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  useEffect(() => {
    setSyncOption(syncOptionSaved)
  }, [syncOptionSaved])

  const portalNode = document.querySelector(`#${layoutBlurLayerPortalId}`)

  return portalNode
    ? createPortal(
        <StyledBlurLayer
          active
          maxWidth={syncAccountsPopupSettings?.show ? '676px' : '1024px'}
          name='lightbox'
          onClose={onClose}
          width={syncAccountsPopupSettings?.show ? 'auto' : 'calc(100% - 128px)'}>
          <ContentWrapper>
            {syncAccountsPopupSettings?.show ? (
              <SyncAccountsPopup
                numAccounts={numAccounts}
                invalidCredentialsCount={invalidCredentialsCount}
                isSyncing={isSyncing}
                onClose={onClose}
                onStartSync={onStartSync}
                syncOption={syncAccountsPopupSettings?.option}
              />
            ) : (
              <>
                <StyledCloseIcon icon='Close' onClick={onClose} size={12} />
                <OverflowContent>
                  <TitleContainer>
                    <Title>{translations.multiCalendarSettings.title}</Title>
                  </TitleContainer>
                  <Subtitle>{translations.multiCalendarSettings.description}</Subtitle>
                  <SyncOptions
                    isSyncing={isSyncing}
                    mainAccount={mainAccount}
                    optionSaved={syncOptionSaved}
                    onSave={onSaveSyncOption}
                    setSyncOption={setSyncOption}
                    syncOption={syncOption}
                  />
                  <SyncExamples optionSelected={syncOption} optionSaved={syncOptionSaved} />
                </OverflowContent>
              </>
            )}
          </ContentWrapper>
        </StyledBlurLayer>,
        portalNode
      )
    : null
}

const ContentWrapper = styled.div`
  display: flex;
  max-height: 100%;
  position: relative;
`

ContentWrapper.displayName = 'ContentWrapper'

const StyledCloseIcon = styled(CloseIcon)`
  z-index: 1;
`

StyledCloseIcon.displayName = 'StyledCloseIcon'

const OverflowContent = styled(Content)`
  max-height: 100%;
  overflow: auto;
  padding: 32px 38px;
  position: relative;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

OverflowContent.displayName = 'OverflowContent'

const StyledTitleContainer = styled(TitleContainer)`
  margin-bottom: 10px;
`

StyledTitleContainer.displayName = 'StyledTitleContainer'
