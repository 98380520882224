import { type } from './item'

const initialSprint = (params) => ({
  id: '',
  estimatedTime: 0,
  when: {
    date: '',
  },
  pin: {
    time: new Date(),
  },
  title: '',
  type: type.SPRINT,
  ...params,
})

export default initialSprint
