import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { styles } from 'gipsy-misc'

export default class ContextMenuItem extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    height: PropTypes.number.isRequired,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    height: 20,
  }

  onClick = (args) => {
    const { onClick, disabled } = this.props
    if (!disabled && onClick) {
      onClick(args)
    }
  }

  onMouseEnter = (args) => {
    const { onMouseEnter } = this.props
    onMouseEnter && onMouseEnter(args)
  }

  onMouseLeave = (args) => {
    const { onMouseLeave } = this.props
    onMouseLeave && onMouseLeave(args)
  }

  render() {
    const { height, disabled } = this.props
    return (
      <Container
        className="context-menu-item"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onClick}
        height={height}
        disabled={disabled}>
        {this.props.children}
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  line-height: ${({ height }) => `${height}px`};
  &:hover {
    background-color: ${styles.colors.veryLightGrey};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background-color: transparent;
      }
    `}
`
