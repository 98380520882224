import React from 'react'
import styled, { css } from 'styled-components'

import { styles } from 'gipsy-misc'
import { RadioInput } from 'gipsy-ui'

export default function SyncOption({ checked, description, disabled, image, imageAlt, onCheck, title }) {
  const handleCheck = () => {
    if (disabled) return

    onCheck?.()
  }

  return (
    <OptionContainer checked={checked} disabled={disabled} onClick={handleCheck}>
      <OptionWrapper>
        <StyledRadioInput
          checked={checked}
          checkedColor={styles.colors.primaryColor}
          disableStopPropagation
          size={16}
        />
        <OptionTexts>
          <OptionTitle>{title}</OptionTitle>
          <OptionDescription>{description}</OptionDescription>
        </OptionTexts>
      </OptionWrapper>
      <OptionGraphic alt={imageAlt} src={image} />
    </OptionContainer>
  )
}

const OptionContainer = styled.div`
  background-color: white;
  border: 2px solid transparent;
  border-radius: 10px;
  box-shadow: ${styles.shadows.taskBox};
  cursor: pointer;
  display: flex;
  flex: 1;
  max-width: 461px;
  padding: 22px 16px;
  transition: background-color 300ms ease, border-color 300ms ease;

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${styles.colors.primaryColor};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`

OptionContainer.displayName = 'OptionContainer'

const OptionWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin-right: 12px;
`

OptionWrapper.displayName = 'OptionWrapper'

const OptionTexts = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`

OptionTexts.displayName = 'OptionTexts'

const OptionTitle = styled.span`
  display: block;
  font-weight: 500;
`

OptionTitle.displayName = 'OptionTitle'

const OptionDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 22px;
  padding: 12px 0 16px;
`

OptionDescription.displayName = 'OptionDescription'

const StyledRadioInput = styled(RadioInput)`
  margin: 3px 10px 0 0;
`

StyledRadioInput.displayName = 'StyledRadioInput'

const OptionGraphic = styled.img`
  flex-shrink: 0;
  height: 112px;
  margin: 10px auto 0;
  transition: opacity 300ms ease;
  width: 191px;
`

OptionGraphic.displayName = 'OptionGraphic'
