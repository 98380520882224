import React, { useEffect, useState, useRef, useCallback } from 'react'
import styled from 'styled-components'

import Icon from 'Icon'
import { DropdownSelector as ProjectDropdownSelector } from 'Project'
import { DropdownSelector as TagDropdownSelector } from 'Tag'
import { styles, translations } from 'gipsy-misc'

const Filter = {
  PROJECTS: 'projects',
  TAGS: 'tags',
}

const filterItemPadding = 16

export const UNCATEGORIZED = 'UNCATEGORIZED'

const TaskFilters = (props) => {
  const { activeProjects, tags } = props
  const [isProjectFilterExpanded, setProjectFilterExpanded] = useState(false)
  const [isTagFilterExpanded, setTagFilterExpanded] = useState(false)

  const tagRef = useRef(null)
  const projectRef = useRef(null)

  const handleClickOutside = useCallback(
    (e) => {
      if (isTagFilterExpanded && tagRef && tagRef.current && !tagRef.current.contains(e.target)) {
        setTagFilterExpanded(false)
      }
      if (isProjectFilterExpanded && projectRef && projectRef.current && !projectRef.current.contains(e.target)) {
        setProjectFilterExpanded(false)
      }
    },
    [isProjectFilterExpanded, isTagFilterExpanded]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isTagFilterExpanded, isProjectFilterExpanded, handleClickOutside])

  const isFilterSelected = (filterName, id) => {
    return !!props.filterBy[filterName].find((filter) => filter === id)
  }

  const toggleExpanded = (e, filter) => {
    const target = e.target

    if (filter === Filter.PROJECTS && target === projectRef.current) {
      setProjectFilterExpanded(!isProjectFilterExpanded)
      setTagFilterExpanded(false)
    } else if (target === tagRef.current) {
      setTagFilterExpanded(!isTagFilterExpanded)
      setProjectFilterExpanded(false)
    }
  }

  const addOrRemoveFilter = (filterName, filterId) => {
    return isFilterSelected(filterName, filterId) ? removeFilter(filterName, filterId) : addFilter(filterName, filterId)
  }

  const addFilter = (filterName, filterId) => {
    props.onChange({
      ...props.filterBy,
      [filterName]: [...props.filterBy[filterName], filterId],
    })
  }

  const removeFilter = (filterName, filterId) => {
    props.onChange({
      ...props.filterBy,
      [filterName]: props.filterBy[filterName].filter((filter) => filter !== filterId),
    })
  }

  const hasActiveFilters = (filterName) => {
    return props.filterBy[filterName].length > 0
  }

  const hasProjects = activeProjects && activeProjects.length > 0
  const hasTags = tags && tags.length > 0
  const hasFilteredProjects = hasActiveFilters(Filter.PROJECTS)

  return hasTags || hasProjects ? (
    <FiltersContainer className={`${props.className} fs-mask`}>
      {hasProjects && (
        <FilterContainer
          hasActiveFilters={hasFilteredProjects}
          onClick={(e) => toggleExpanded(e, Filter.PROJECTS)}
          ref={projectRef}>
          <FilterTitle hasActiveFilters={hasFilteredProjects}>
            {translations.general.filterByProjects} {hasFilteredProjects ? `(${props.filterBy.projects.length})` : ''}
          </FilterTitle>
          <Icon
            fill={hasActiveFilters(Filter.PROJECTS) ? styles.colors.primaryDarkColor : styles.colors.darkGrey}
            size={10}
            icon={isProjectFilterExpanded ? 'SingleChevronDown' : 'SingleChevronLeft'}
            unclickable
          />
          {isProjectFilterExpanded && (
            <FilterItemList>
              <ProjectDropdownSelector
                isUncategorized
                padding={filterItemPadding}
                onClick={() => addOrRemoveFilter(Filter.PROJECTS, UNCATEGORIZED)}
                selected={isFilterSelected(Filter.PROJECTS, UNCATEGORIZED)}
              />
              {activeProjects.map((activeProject) => (
                <ProjectDropdownSelector
                  key={activeProject.id}
                  value={activeProject}
                  padding={filterItemPadding}
                  onClick={() => addOrRemoveFilter(Filter.PROJECTS, activeProject.id)}
                  selected={isFilterSelected(Filter.PROJECTS, activeProject.id)}
                />
              ))}
            </FilterItemList>
          )}
        </FilterContainer>
      )}

      {hasTags && (
        <FilterContainer
          small
          hasActiveFilters={hasActiveFilters(Filter.TAGS)}
          onClick={(e) => toggleExpanded(e, Filter.TAGS)}
          ref={tagRef}>
          <FilterTitle hasActiveFilters={hasActiveFilters(Filter.TAGS)}>
            {translations.general.filterByTags} {hasActiveFilters(Filter.TAGS) ? `(${props.filterBy.tags.length})` : ''}
          </FilterTitle>
          <Icon
            size={10}
            icon={isTagFilterExpanded ? 'SingleChevronDown' : 'SingleChevronLeft'}
            fill={hasActiveFilters(Filter.TAGS) ? styles.colors.primaryDarkColor : styles.colors.darkGrey}
            unclickable
          />
          {isTagFilterExpanded && (
            <FilterItemList>
              {tags.map((tag) => (
                <TagDropdownSelector
                  value={tag}
                  hideIcon
                  onClick={() => addOrRemoveFilter(Filter.TAGS, tag.id)}
                  selected={isFilterSelected(Filter.TAGS, tag.id)}
                />
              ))}
            </FilterItemList>
          )}
        </FilterContainer>
      )}
    </FiltersContainer>
  ) : null
}

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`

export const FilterItemList = styled.ul`
  position: absolute;
  top: 35px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  border: 1px solid ${styles.colors.middleGrey};
  z-index: 2;
  background: white;
`

export const FilterContainer = styled.div`
  min-width: ${(props) => (props.small ? '185px' : '215px')};
  display: flex;
  margin-right: ${styles.spacings.small};
  background: white;
  border: ${`1px solid ${styles.colors.borderColor}`};
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  padding: 8px 14px;

  ${FilterItemList} {
    min-width: ${(props) => (props.small ? '185px' : '215px')};
  }
`

export const FilterTitle = styled.span`
  display: flex;
  align-items: center;
  line-height: 16px;
  margin-right: 8px;
  text-transform: capitalize;
  font-size: ${styles.fonts.fontSizeXSmall};
  color: ${(props) => (props.hasActiveFilters ? styles.colors.primaryDarkColor : styles.colors.darkGrey)};
  pointer-events: none;
  user-select: none;
`

export const FilterItem = styled.li`
  padding: ${filterItemPadding}px;
  display: flex;
  font-size: ${styles.fonts.fontSizeSmall};
  color: ${styles.colors.textMediumDarkColor};
  align-items: center;

  &:hover {
    background: ${styles.colors.veryLightGrey};
  }
`

export const FilterItemLabel = styled.span`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 12)}px;
`

export default TaskFilters
