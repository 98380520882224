import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { styles, translations } from 'gipsy-misc'

import { CustomPopup } from 'Popup/components'
import TimeInput from 'TimeInput2'

import { roundMinutesToNearestStartTime } from './utils'

export default function TimeRange({
  closeOnSelection = true,
  disabled,
  duration = 15,
  onChange,
  onClickWhileDisabled,
  shouldAdjustDropdownsPosition = true,
  startTime,
}) {
  const [selectedEndTime, setSelectedEndTime] = useState(moment(startTime || new Date()).add(duration, 'minutes'))
  const [selectedStartTime, setSelectedStartTime] = useState(moment(startTime || new Date()))
  const [timeListPopupPosition, setTimeListPopupPosition] = useState({
    top: shouldAdjustDropdownsPosition ? 0 : `calc(100% + 8px)`,
  })
  const [timeListPopupSettings, setTimeListPopupSettings] = useState({ forEndTimeInput: false, shown: false })

  const inputsRef = useRef(null)
  const selectedTimesRef = useRef({ start: null, end: null })
  const timeListPopupRef = useRef(null)

  useEffect(() => {
    if (!startTime) return // prevent infinite loop when start time is undefined

    const momentStartTime = moment(startTime)
    const momentEndTime = moment(startTime).add(duration, 'minutes')
    setSelectedStartTime(momentStartTime)
    setSelectedEndTime(momentEndTime)
  }, [duration, startTime])

  const showTimeListPopup = (clickEvt, forEndTimeInput) => {
    if (disabled) return

    clickEvt.persist()

    if (
      clickEvt.target?.classList?.contains?.('period') ||
      (timeListPopupSettings.shown && timeListPopupSettings.forEndTimeInput === forEndTimeInput)
    )
      return

    setTimeListPopupSettings({ forEndTimeInput, shown: true })
  }

  const onInputsContainerClicked = () => {
    if (disabled) {
      onClickWhileDisabled?.()
    }
  }

  const onClickOutsideTimeListPopup = (e) => {
    if (inputsRef.current.contains(e.target)) return

    setTimeListPopupSettings((prev) => ({ ...prev, shown: false }))
  }

  const onChangeStartTime = ({ hour, minute }, e) => {
    if (disabled || (selectedStartTime.hour() === hour && selectedStartTime.minute() === minute)) return

    const newStartTime = moment(selectedStartTime).set('hour', hour).set('minute', minute)
    const newEndTime = moment(newStartTime).add(duration, 'minutes')
    setSelectedStartTime(newStartTime)
    setSelectedEndTime(newEndTime)
    onChange?.({ start: newStartTime, end: newEndTime }, e)
  }

  const onChangeEndTime = ({ hour, minute }, e) => {
    if (disabled || (selectedEndTime.hour() === hour && selectedEndTime.minute() === minute)) return

    const newEndTime = moment(selectedStartTime).set('hour', hour).set('minute', minute)

    if (newEndTime.isBefore(selectedStartTime)) {
      newEndTime.add(1, 'day')
    }

    setSelectedEndTime(newEndTime)
    onChange?.({ start: selectedStartTime, end: newEndTime }, e)
  }

  const onTimeOptionSelected = (optionData) => {
    if (disabled) return

    const { forEndTimeInput } = timeListPopupSettings

    if (forEndTimeInput) {
      setSelectedEndTime(optionData.newHour)
      onChange?.({ start: selectedStartTime, end: optionData.newHour })
    } else {
      onChangeStartTime(optionData)
    }

    if (closeOnSelection) {
      setTimeListPopupSettings((prev) => ({ ...prev, shown: false }))
    }
  }

  const getOptionLabel = (hour, minute) => {
    let hourStr = '12'
    let minuteStr = `${minute}`
    let period = translations.general.am

    if (hour > 12) {
      period = translations.general.pm
      hourStr = `${hour % 12}`
    } else if (hour > 0) {
      if (hour === 12) {
        period = translations.general.pm
      }

      hourStr = `${hour}`
    }

    if (minute < 10) {
      minuteStr = `0${minuteStr}`
    }

    return `${hourStr}:${minuteStr}${period}`
  }

  const renderTimeListOptions = () => {
    const { forEndTimeInput } = timeListPopupSettings

    const options = []

    if (!forEndTimeInput) {
      const minutesPerOption = 15
      const optionsPerHour = 4
      const totalOptions = optionsPerHour * 24

      for (let i = 0; i < totalOptions; i++) {
        const currentHour = Math.floor((i % totalOptions) / optionsPerHour)
        const currentMinutes = (i % 4) * minutesPerOption
        const key = `${currentHour}_${currentMinutes}`
        options.push(
          <TimeListOption
            data-hour={key}
            key={key}
            onClick={() => onTimeOptionSelected({ hour: currentHour, minute: currentMinutes, newHour: null })}>
            {getOptionLabel(currentHour, currentMinutes)}
          </TimeListOption>
        )
      }
    } else {
      const baseOptionHour = selectedStartTime.clone()
      const currentOptionHour = baseOptionHour.clone()
      const optionLimit = currentOptionHour.clone().add(23, 'hours').add(30, 'minutes')

      while (currentOptionHour.isBefore(optionLimit)) {
        if (options.length < 4) {
          // first 4 options are handled differently
          currentOptionHour.add(15, 'minutes')
        } else {
          currentOptionHour.add(30, 'minutes')
        }

        const currentHour = currentOptionHour.hour()
        const currentMinutes = currentOptionHour.minute()
        const momentCurrentOption = currentOptionHour.clone()

        const diffMinutes = currentOptionHour.diff(baseOptionHour, 'minutes') % 60
        const diffHours = currentOptionHour.diff(baseOptionHour, 'hours')
        let diffLabel = `${diffHours}${diffMinutes >= 30 ? '.5' : ''} ${translations.general.hrs}`

        if (diffHours === 0) {
          diffLabel = `${diffMinutes} ${translations.general.mins}`
        }

        const key = `${currentHour}_${currentMinutes}`

        options.push(
          <TimeListOption
            data-hour={key}
            key={key}
            onClick={() =>
              onTimeOptionSelected({ hour: currentHour, minute: currentMinutes, newHour: momentCurrentOption })
            }>
            <span>{getOptionLabel(currentHour, currentMinutes)}</span>
            <TaskListOptionDuration>{diffLabel}</TaskListOptionDuration>
          </TimeListOption>
        )
      }
    }

    return options
  }

  useEffect(() => {
    selectedTimesRef.current = {
      startHour: selectedStartTime.hour(),
      startMinute: selectedStartTime.minute(),
      start: `${selectedStartTime.hour()}_${selectedStartTime.minute()}`,
      end: `${selectedEndTime.hour()}_${selectedEndTime.minute()}`,
    }
  }, [selectedEndTime, selectedStartTime])

  useLayoutEffect(() => {
    const { forEndTimeInput, shown } = timeListPopupSettings
    let option

    if (!shown || !selectedTimesRef.current) return

    if (forEndTimeInput) {
      option = timeListPopupRef.current.querySelector(`[data-hour="${selectedTimesRef.current.end}"]`)
    } else {
      let startMinutes = selectedTimesRef.current.startMinute

      if (startMinutes !== 0 || startMinutes !== 15 || startMinutes !== 30 || startMinutes !== 45) {
        startMinutes = roundMinutesToNearestStartTime(startMinutes)
        option = timeListPopupRef.current.querySelector(
          `[data-hour="${selectedTimesRef.current.startHour}_${startMinutes}"]`
        )
      } else {
        option = timeListPopupRef.current.querySelector(`[data-hour="${selectedTimesRef.current.start}"]`)
      }
    }

    if (option) {
      const popupBounds = timeListPopupRef.current.getBoundingClientRect()
      const optionBounds = option.getBoundingClientRect()
      timeListPopupRef.current.scroll(0, optionBounds.top - popupBounds.top)
    }
  }, [timeListPopupSettings])

  useLayoutEffect(() => {
    if (!timeListPopupSettings.shown || !shouldAdjustDropdownsPosition) return

    const inputBounds = inputsRef.current.getBoundingClientRect()
    const popupBounds = timeListPopupRef.current.getBoundingClientRect()
    const prevTop = timeListPopupPosition.top
    let top = prevTop

    if (top === 0) {
      top = inputBounds.height + 8
    }

    if (popupBounds.bottom >= window.innerHeight) {
      top = 0 - popupBounds.height - 8
    }

    if (top !== prevTop) {
      setTimeListPopupPosition({ top })
    }
  }, [shouldAdjustDropdownsPosition, timeListPopupPosition, timeListPopupSettings])

  return (
    <Container disabled={disabled} className='TimeRange'>
      <Inputs onClick={onInputsContainerClicked} ref={inputsRef}>
        <TimeInput
          className='TimeRange__input'
          disabled={disabled}
          format='12h'
          hour={selectedStartTime.hour()}
          minute={selectedStartTime.minute()}
          onChange={onChangeStartTime}
          onClick={(e) => showTimeListPopup(e, false)}
          showPeriod
        />
        <Separator>-</Separator>
        <TimeInput
          className='TimeRange__input'
          disabled={disabled}
          format='12h'
          hour={selectedEndTime.hour()}
          minute={selectedEndTime.minute()}
          onChange={onChangeEndTime}
          onClick={(e) => showTimeListPopup(e, true)}
          showPeriod
        />
      </Inputs>
      {timeListPopupSettings.shown && (
        <TimeListPopup
          onClickOutside={onClickOutsideTimeListPopup}
          ref={timeListPopupRef}
          style={{ top: `${timeListPopupPosition.top}px` }}>
          {renderTimeListOptions()}
        </TimeListPopup>
      )}
    </Container>
  )
}

const Separator = styled.div`
  margin: 0 2px;
`

Separator.displayName = 'Separator'

const Container = styled.div`
  position: relative;

  .TimeRange__input {
    font-size: 14px;
    padding: 4px;

    .gp-input.hour,
    .gp-input.minute,
    .gp-input.period {
      line-height: 18px;
      width: 21px;
    }

    .gp-input.period {
      text-transform: lowercase;
    }

    &.focused {
      border-color: ${styles.colors.primaryColor}4D;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: pointer;

      ${Separator} {
        color: ${styles.colors.middleGrey};
      }
    `}
`

Container.displayName = 'Container'

const Inputs = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

Inputs.displayName = 'Inputs'

const TimeListPopup = styled(CustomPopup)`
  max-height: 200px;
  overflow: auto;
  width: 155px;
`

TimeListPopup.displayName = 'TimeListPopup'

const TimeListOption = styled.div`
  align-items: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 12px 16px;

  :hover {
    background-color: ${styles.colors.middleGrey}4d;
  }
`

TimeListOption.displayName = 'TimeListOption'

const TaskListOptionDuration = styled.span`
  color: ${styles.colors.darkGrey};
  display: block;
`

TaskListOptionDuration.displayName = 'TaskListOptionDuration'
