import qs from 'qs'
import React, { Component } from 'react'

import { SyncingLoading } from 'features/integrations/components'

import { translations } from 'gipsy-misc'

export default class RevalidateOauthCallback extends Component {
  componentDidMount = async () => {
    const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    if (window.opener) {
      window.opener.postMessage({ searchParams, source: 'revalidate-oauth' })
    }
  }
  render = () => {
    return <SyncingLoading height={'100%'} text={translations.integrations.syncingData} />
  }
}
