import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import gipsyBotFilled from 'assets/gipsybot-filled.png'
import Link from 'LineComponents/LinkItem'
import { PopupItem as Stats } from 'FocusSession/Popup/PopupItem'
import { Title } from '../LineView/components/Title'
import ReusableButton from 'ReusableButton'
import { styles, translations, utils } from 'gipsy-misc'

const componentName = 'FocusSessionStats'

function FocusSessionStats({ onOkClicked, registerShortcuts, session, unregisterShortcuts }) {
  const [updatedSession, setUpdatedSession] = useState(session)

  const handleTitleChange = (newTitle) => {
    setUpdatedSession((prev) => ({
      ...prev,
      task: { ...prev.task, title: newTitle },
    }))
  }

  const handleSessionUpdate = (modifiedSession) => {
    setUpdatedSession(modifiedSession)
  }

  const handleLinkClick = (url) => {
    window.open(utils.url.setHttp(url), '_blank')
  }

  const updateWhitelistURL = (newUrl, index) => {
    const newWhiteList = [...updatedSession.whitelistedUrlsInfo]
    if (newWhiteList[index].url !== newUrl) {
      newWhiteList[index].url = newUrl
      newWhiteList[index].subject = ''
    }
    setUpdatedSession({
      ...updatedSession,
      whitelistedUrlsInfo: newWhiteList,
    })
  }

  const deleteWhitelistURL = (index) => {
    const newWhiteList = [...updatedSession.whitelistedUrlsInfo]
    newWhiteList.splice(index, 1)
    setUpdatedSession({
      ...updatedSession,
      whitelistedUrlsInfo: newWhiteList,
    })
  }

  const handleLinkChange = (newUrl, index) => {
    const newLinksList = [...updatedSession.task.urlsInfo]
    if (newLinksList[index].url !== newUrl) {
      newLinksList[index].url = newUrl
      newLinksList[index].subject = ''
    }
    setUpdatedSession({
      ...updatedSession,
      task: {
        ...updatedSession.task,
        urlsInfo: newLinksList,
      },
    })
  }

  const handleLinkRemoval = (index) => {
    const newLinksList = [...updatedSession.task.urlsInfo]
    newLinksList.splice(index, 1)
    setUpdatedSession({
      ...updatedSession,
      task: {
        ...updatedSession.task,
        urlsInfo: newLinksList,
      },
    })
  }

  const handleOkClicked = () => {
    onOkClicked?.({ session, updatedSession })
  }

  useEffect(() => {
    registerShortcuts?.(
      [
        {
          key: 'Enter',
          label: translations.general.save,
          callback: () => {
            onOkClicked?.({ session, updatedSession })
          },
        },
      ],
      componentName
    )

    return () => {
      unregisterShortcuts?.()
    }
  }, [onOkClicked, registerShortcuts, session, unregisterShortcuts, updatedSession])

  const renderLinks = () => {
    const urlsInfo = updatedSession.task.urlsInfo
    const whitelist = updatedSession.whitelistedUrlsInfo

    let newLinks
    if (whitelist.length > 0) {
      newLinks = (
        <Section>
          {whitelist.map((urlInfo, index) => (
            <Link
              key={index}
              badge={<LinkBadge>{translations.general.new}</LinkBadge>}
              index={index}
              onClickLink={handleLinkClick}
              onDelete={deleteWhitelistURL}
              onSave={updateWhitelistURL}
              urlInfo={urlInfo}
            />
          ))}
        </Section>
      )
    }

    let prevLinks
    if (urlsInfo.length > 0) {
      prevLinks = (
        <Section>
          {urlsInfo.map((urlInfo, index) => (
            <Link
              key={index}
              index={index}
              onClickLink={handleLinkClick}
              onDelete={handleLinkRemoval}
              onSave={handleLinkChange}
              urlInfo={urlInfo}
            />
          ))}
        </Section>
      )
    }

    return (
      <>
        <Section>
          <LinksIcon alt="Saved Links Icon" src={gipsyBotFilled} />
          <LinksHeader>{translations.focussession.stats.savedLinks}</LinksHeader>
        </Section>
        {newLinks}
        {prevLinks}
      </>
    )
  }

  return (
    <Container>
      <TitleContainer>
        <Title
          isEditable
          onTitleChange={handleTitleChange}
          registerShortcuts={registerShortcuts}
          title={updatedSession.task.title}
          unregisterShortcuts={unregisterShortcuts}
        />
      </TitleContainer>
      {!session?.discarded && <Header>{translations.focussession.stats.completed}</Header>}
      <Section>
        {session?.discarded ? (
          <DiscardedLabel>{translations.focussession.lessThan2mn}</DiscardedLabel>
        ) : (
          <Stats item={updatedSession} onClickSave={handleSessionUpdate} removeDeleteButton />
        )}
      </Section>
      <ScrollContainer>{renderLinks()}</ScrollContainer>
      <ButtonRow>
        <ReusableButton borderRadius={8} fontWeight={400} height={32} onClick={handleOkClicked} width={90}>
          {translations.general.ok}
        </ReusableButton>
      </ButtonRow>
    </Container>
  )
}

const Container = styled.div`
  padding: 8px 0 22px;
`

const TitleContainer = styled.div`
  margin: 0 8px;
`

const ScrollContainer = styled.div`
  max-height: 375px;
  overflow-y: auto;
`

const Header = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin: 18px 0 8px;
  padding: 0 16px;
`

const Section = styled.div`
  align-items: center;
  border: 1px solid ${styles.colors.middleGrey};
  border-left: 0;
  border-right: 0;
  display: flex;
  flex-wrap: wrap;

  & + & {
    border-top: 0;
  }
`

const DiscardedLabel = styled.p`
  margin: 0;
  padding: 8px 16px;
  color: ${styles.colors.textMediumDarkColor};
  font-size: ${styles.fonts.fontSizeXSmall};
  font-style: italic;
`

const LinksHeader = styled(Header)`
  margin: 0;
  padding: 0;
`

const LinksIcon = styled.img`
  height: 50px;
  margin: 16px 10px 16px 16px;
  width: 50px;
`

const LinkBadge = styled.div`
  background-color: ${styles.colors.orangeColor}21;
  border-radius: 8px;
  color: ${styles.colors.orangeColor};
  flex-shrink: 0;
  font-size: 9px;
  font-weight: 500;
  margin-right: 14px;
  padding: 4px 10px;
  text-transform: uppercase;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`

export default FocusSessionStats
