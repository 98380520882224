import { createSelector } from 'reselect'

const _getAccounts = (state) => state.accounts

export const getAccounts = createSelector([_getAccounts], (accounts) => accounts)

export const getMainAccount = createSelector([_getAccounts], (accounts) => {
  const mainAccount = accounts.find((account) => !!account.main)
  return mainAccount
})

export const getSecondaryAccounts = createSelector([_getAccounts], (accounts) => {
  const secondaryAccounts = accounts.filter((account) => !account.main)
  return secondaryAccounts
})
