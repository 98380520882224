import React, { Fragment, PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getFocusSession } from 'store/session/actions'
import { initializeEvents, refetchEvents } from 'store/calendar/actions'
import { fetchActiveProjects } from 'store/project/actions'
import { fetchActiveTags } from 'store/tag/actions'
import { fetchAllItems } from 'store/items/actions'
import { getAccounts } from 'store/accounts/actions'

import { chromeext } from 'gipsy-misc'
import { task as taskApi } from 'gipsy-api'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchAllItems,
      getFocusSession,
      getAccounts,
      initializeEvents,
      refetchEvents,
      fetchActiveProjects,
      fetchActiveTags,
    },
    dispatch
  ),
})

const mapStateToProps = (state) => ({
  session: state.session,
})

class BackgroundListener extends PureComponent {
  componentDidMount = () => {
    window.addEventListener(chromeext.refreshFocusSession, this.chromeExtensionEventHandler)
    window.addEventListener('focus', this.fetchReduxData)
  }

  chromeExtensionEventHandler = (event) => {
    if (event.type === chromeext.refreshFocusSession) {
      this.props.actions.getFocusSession()
    }
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.session || !prevProps.session.id) && this.props.session && this.props.session.id) {
      this.fetchReduxData()
    }
  }

  componentWillUnmount() {
    window.removeEventListener(chromeext.refreshFocusSession, this.chromeExtensionEventHandler)
    window.removeEventListener('focus', this.fetchReduxData)
  }

  fetchReduxData = () => {
    const { session } = this.props
    if (session && session.id) {
      this.props.actions.fetchAllItems()
      this.props.actions.fetchActiveProjects()
      this.props.actions.fetchActiveTags()
      this.props.actions.getAccounts()
      this.fetchEvents()
      this.repairRanks()
      this.props.actions.getFocusSession()
    }
  }

  fetchEvents = () => {
    if (this.areEventsInitialized) {
      this.props.actions.refetchEvents()
    } else {
      this.props.actions.initializeEvents()
      this.areEventsInitialized = true
    }
  }

  repairRanks = async () => {
    try {
      await taskApi.repairActive()
    } catch (err) {
      if (!err.cancel) {
        console.error(err)
      }
    }
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: true,
})(BackgroundListener)
