import React, { PureComponent } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ReadModeTitle, editableTitleStyle } from './commonUIComponents'
import { styles } from 'gipsy-misc'

const EditableTitle = styled.input`
  height: 32px;
  border-radius: 8px;
  border: ${editableTitleStyle.borderSize}px solid ${styles.colors.greyBorderColor};
  padding: ${editableTitleStyle.verticalPadding}px 12px;
  font-size: 15px;
  color: ${styles.colors.textMediumDarkColor};
  width: 100%;
  transition: width ${styles.transitions.focusSessionZenLogo}s ease-in-out ${styles.transitions.focusSessionZenLogo}s;
  margin-bottom: 4px;
`

const shortcutsGroupLabel = 'LineViewEditableTitle'

export class Title extends PureComponent {
  state = {
    _title: this.props.title,
  }

  editableTitleRef = React.createRef()

  componentDidUpdate(prevProps) {
    const hasInputBeenEnabled = !this.props.isInputDisabled && prevProps.isInputDisabled !== this.props.isInputDisabled
    const hasFocusEditableTitleBeenEnabled =
      this.props.shouldFocusEditableTitle && prevProps.shouldFocusEditableTitle !== this.props.shouldFocusEditableTitle

    if (
      (hasInputBeenEnabled && this.props.shouldFocusEditableTitle) ||
      (hasFocusEditableTitleBeenEnabled && !this.props.isInputDisabled)
    ) {
      this.focusEditableTitle()
    }

    if (this.props.isEditable && !prevProps.isEditable) {
      this.setState({ _title: this.props.title })
    }
  }

  focusEditableTitle = () => {
    this.editableTitleRef.current && this.editableTitleRef.current.focus()
  }

  onChangeTitle = (e) =>
    this.setState({
      _title: e.target.value,
    })

  onBlurTitle = () => {
    this.props.unregisterShortcuts?.(shortcutsGroupLabel)
    this.props.onTitleChange(this.state._title)
  }

  onFocusTitle = () => this.props.registerShortcuts?.([], shortcutsGroupLabel)

  render() {
    const {
      lineThrough,
      justCompleted,
      showCalendarEventInfo,
      completionDuration,
      isEditable,
      isInputDisabled,
    } = this.props
    const { _title } = this.state

    const title = isEditable ? _title : this.props.title

    if (isEditable && !justCompleted) {
      return (
        <EditableTitle
          ref={this.editableTitleRef}
          value={title}
          disabled={isInputDisabled}
          onFocus={this.onFocusTitle}
          onChange={this.onChangeTitle}
          onBlur={this.onBlurTitle}
        />
      )
    } else {
      return (
        <TitleContainer paddingTop={isEditable && justCompleted ? editableTitleStyle.verticalPadding : 0}>
          <AnimatedTitle
            completionDuration={completionDuration}
            title={title}
            linethrough={lineThrough ? lineThrough.toString() : undefined}
            justCompleted={justCompleted}
            fullWidth={!justCompleted && !showCalendarEventInfo}>
            {title}
          </AnimatedTitle>
        </TitleContainer>
      )
    }
  }
}

const strikeKeyframe = keyframes`
  0%   { width : 0; }
  100% { width: 100%; }
`

const TitleContainer = styled.div`
  position: relative;
  padding-top: ${(props) => props.paddingTop}px;
  min-width: 0;
`

export const AnimatedTitle = styled(ReadModeTitle)`
  min-height: 16px;
  width: fit-content;
  max-width: fill-available;
  position: relative;
  ${(props) =>
    props.justCompleted &&
    css`
      ::after {
        content: ' ';
        position: absolute;
        top: calc(50% - 0.5px);
        left: 0;
        width: 100%;
        height: 1px;
        background: ${styles.colors.textMediumDarkColor};
        animation-name: ${strikeKeyframe};
        animation-duration: ${props.completionDuration}s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    `}
`
