import React, { useState, useCallback } from 'react'

import { getSrc } from 'utils/image'
import { IntegrationContainer, IntegrationRow } from 'features/integrations/components'
import { BlockHeader, BlockFooter, IntegrationInfo, IntegrationSubtitle } from 'pages/integrations/common'
import AsanaSelectModal from './components/AsanaSelectModal'
import AsanaAccountInfo from './components/AsanaAccountInfo'

import { Separator, AnimateHeight } from 'gipsy-ui'
import { translations } from 'gipsy-misc'

const Asana = ({
  onClickConnect,
  asanaIntegrations,
  onSyncAccount,
  onUnsyncAccount,
  openPopup,
  closePopup,
  fetchActiveProjects,
}) => {
  const [isExpanded, setExpanded] = useState(true)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const onClickExpandButton = useCallback(() => {
    setExpanded(!isExpanded)
  }, [isExpanded])

  const hasAsanaIntegrations = asanaIntegrations && asanaIntegrations.length > 0

  const hideSelectModal = useCallback(() => setSelectedAccount(null), [])

  const _setSelectedAccount = useCallback((account) => setSelectedAccount(account), [])

  return (
    <IntegrationContainer>
      {hasAsanaIntegrations && (
        <AsanaSelectModal
          showModal={!!selectedAccount}
          account={selectedAccount}
          onCancel={hideSelectModal}
          fetchActiveProjects={fetchActiveProjects}
        />
      )}
      <BlockHeader
        iconSrc={getSrc('/images/asana.png')}
        title={translations.integrations.apps.asana.title}
        description={translations.integrations.apps.asana.description}
        isInstalled={hasAsanaIntegrations}
        installLabel={translations.integrations.actionButtons.connect}
        onClickInstall={onClickConnect}
      />
      {hasAsanaIntegrations && (
        <IntegrationRow>
          <IntegrationInfo>
            <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0}>
              <React.Fragment>
                <IntegrationSubtitle marginBottom={3}>
                  {`${translations.integrations.connectedAccount}${asanaIntegrations.length > 1 ? 's' : ''}`}
                </IntegrationSubtitle>
                <Separator />
                {asanaIntegrations &&
                  asanaIntegrations.map((asanaIntegration) => (
                    <AsanaAccountInfo
                      key={asanaIntegration.id}
                      onSyncAccount={onSyncAccount}
                      openPopup={openPopup}
                      closePopup={closePopup}
                      onUnsyncAccount={onUnsyncAccount}
                      account={asanaIntegration}
                      showModal={_setSelectedAccount}
                    />
                  ))}
              </React.Fragment>
            </AnimateHeight>
            <BlockFooter
              isExpanded={isExpanded}
              onClickExpand={onClickExpandButton}
              onClickAction={onClickConnect}
              actionLabel={translations.integrations.actionButtons.addAccount}
            />
          </IntegrationInfo>
        </IntegrationRow>
      )}
    </IntegrationContainer>
  )
}
export default React.memo(Asana)
