import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { constants, styles, variables } from 'gipsy-misc'

import CalendarPanelContext from 'features/calendar/components/CalendarPanel/context'
import Header from 'features/header'
import layoutBreakpoints from 'features/layout/breakpoints'
import MobileWarn from 'features/layout/components/mobileWarn'
import Navbar from 'features/layout/components/navbar'
import MulticalendarOnboardingOverlay from 'features/multicalendarOnboardingOverlay'
import OnboardingHelper from 'features/onboardingHelperOverlay'
import Popup from 'features/popup'
import Shortcuts from 'features/shortcuts'
import Snackbar from 'features/snackbar'
import { sprintComposerPortalId } from 'features/sprintComposer/index'
import { supportChatPortalId } from 'features/supportchat'

import { routesToHideMobileWarn, shouldShowCalendar, shouldShowHeader, shouldShowNavbar } from 'logic/layout'

export const layoutBlurLayerPortalId = 'layout-blur-layer-portal'
export const pageBlurLayerPortalId = 'page-blur-layer-portal'

function Layout({ children, location }) {
  const sessionId = useSelector((state) => state.session.id)
  const taskPanelShown = useSelector((state) => state.taskPanel.settings.isShown)

  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const trackWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', trackWidth)

    return () => {
      window.removeEventListener('resize', trackWidth)
    }
  }, [])

  const isConnected = !!sessionId
  const pathname = location?.pathname
  const fromOnboarding = pathname === '/' && location.state?.fromOnboarding
  const fromMulticalendarOnboarding = pathname === '/' && location.state?.fromMulticalendarOnboarding
  const hideMobileWarn = isConnected && routesToHideMobileWarn[pathname]
  const showCalendar =
    isConnected && (windowWidth >= layoutBreakpoints.desktop ? shouldShowCalendar(pathname) : pathname === '/')
  const showHeader = shouldShowHeader(pathname)
  const showNavbar = isConnected && shouldShowNavbar(pathname)

  return (
    <Container showLayoutOverlay={taskPanelShown}>
      {!hideMobileWarn && <MobileWarn />}
      {showHeader && (
        <Header
          setShowOnboardingVideo={setShowOnboardingVideo}
          showOnboardingVideo={showOnboardingVideo}
          windowWidth={windowWidth}
        />
      )}
      <PageContainer>
        <div id={layoutBlurLayerPortalId} />
        <div id={pageBlurLayerPortalId} />
        <Navbar collapsedMode={windowWidth < layoutBreakpoints.tabletLarge} show={showNavbar} />
        <div id={sprintComposerPortalId} />
        <CalendarPanelContext pathname={pathname} showCalendar={showCalendar} windowWidth={windowWidth}>
          <PageContent showCalendar={showCalendar}>{children}</PageContent>
        </CalendarPanelContext>
        <div id={supportChatPortalId} />
      </PageContainer>
      <Popup />
      <Snackbar />
      <Shortcuts />
      <AnimatePresence>
        {fromMulticalendarOnboarding ? (
          <motion.div exit={{ opacity: 0 }} initial={{ opacity: 1 }} key='multicalendar-onboarding-overlay'>
            <MulticalendarOnboardingOverlay />
          </motion.div>
        ) : (
          fromOnboarding && (
            <motion.div exit={{ opacity: 0 }} initial={{ opacity: 1 }} key='onboarding-overlay'>
              <OnboardingHelper
                setShowOnboardingVideo={setShowOnboardingVideo}
                showOnboardingVideo={showOnboardingVideo}
              />
            </motion.div>
          )
        )}
      </AnimatePresence>
      <div id={constants.fixedTooltipContainerPortalId} />
      <div id={constants.fixedElementsContainerId} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  z-index: ${variables.zIndex.layout};

  &::after {
    background-color: ${styles.colors.veryLightGrey};
    content: '';
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity ${styles.transitions.calendarSlide}s ease-in-out;
    user-select: none;
    width: 100%;
    z-index: 1;
  }

  ${({ showLayoutOverlay }) =>
    showLayoutOverlay &&
    css`
      &::after {
        opacity: 1;
      }
    `}
`

Container.displayName = 'LayoutContainer'

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`

PageContainer.displayName = 'LayoutPageContainer'

const PageContent = styled.div`
  flex: 1;
  margin: 8px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ showCalendar }) =>
    showCalendar &&
    css`
      // this is done to allow popups to render over calendar
      margin-right: -125px;
      padding-right: 100px;
    `}
`

PageContent.displayName = 'LayoutPageContent'

export default withRouter(Layout)
