import qs from 'qs'

// getOrigin takes an string that's supposed to be an url and returns its origin
export const getOrigin = (strUrl) => {
  try {
    const parsedUrl = new URL(strUrl)
    return parsedUrl.origin
  } catch (err) {
    return strUrl
  }
}

export const getCleanUrl = (strUrl) => {
  try {
    const parsedUrl = new URL(strUrl)
    return parsedUrl.origin + parsedUrl.pathname
  } catch (err) {
    return strUrl
  }
}

export const getFinalAndCleanedUrl = (url) => {
  // that's how google redirects safely from gmail
  if (url.startsWith('https://www.google.com/url')) {
    const searchParams = qs.parse(url.slice(url.lastIndexOf('?') + 1), { ignoreQueryPrefix: true })

    return getCleanUrl(searchParams.q || url)
  }
  return getCleanUrl(url)
}

export const setHttp = (link) => {
  return link.indexOf('://') === -1 ? 'http://' + link : link
}
