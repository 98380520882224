const types = {
  ADD_ITEM: 'ADD_ITEM',
  ADD_ITEMS: 'ADD_ITEMS',
  REMOVE_ITEM: 'REMOVE_ITEM',
  REMOVE_ITEMS: 'REMOVE_ITEMS',
  REPLACE_ITEM: 'REPLACE_ITEM',
  REPLACE_ITEMS: 'REPLACE_ITEMS',
  SET_ALL_ITEMS: 'SET_ALL_ITEMS',
  UPDATE_ITEM: 'UPDATE_ITEM',
  UPDATE_ITEMS: 'UPDATE_ITEMS',
}

export default types
