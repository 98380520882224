import React from 'react'
import styled from 'styled-components'

import { styles } from 'gipsy-misc'
import { SquareCheckbox } from 'gipsy-ui'

export default function DeleteReasonOption({ active, onClick, optionId, optionText }) {
  const handleClick = () => {
    onClick({ id: optionId, value: !active })
  }

  return (
    <Container onClick={handleClick}>
      <SquareCheckbox checked={active} color={styles.colors.primaryColor} id={optionId} size={16} />
      <Label>{optionText}</Label>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`

Container.displayName = 'Container'

const Label = styled.span`
  letter-spacing: 0;
  line-height: 32px;
  margin-left: 12px;
`

Label.displayName = 'Label'
