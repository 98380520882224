import React, { useRef, useState, useCallback } from 'react'
import styled from 'styled-components'
import { PopupItemContent } from 'Popup/components'
import { SecondaryButton } from 'ReusableButton/Buttons'
import Input, { inputHeight } from 'LineComponents/Input'
import { styles, translations } from 'gipsy-misc'

export default function PopupCreationItem({ onAdd }) {
  const [url, setUrl] = useState('')
  const inputRef = useRef(null)

  const onChangeUrl = useCallback((e) => {
    setUrl(e.target.value)
  }, [])

  const onClickAdd = useCallback(() => {
    if (url) {
      onAdd(url)
      setUrl('')
    }
  }, [onAdd, url])

  return (
    <PopupCreationItemContainer>
      <PopupCreationItemContent>
        <Input
          iconName={'Link'}
          iconSize={14}
          placeholder={translations.task.urlInfo.placeholder}
          value={url}
          ref={inputRef}
          onChange={onChangeUrl}
        />
        <StyleSecondaryButton
          disabled={!url}
          height={inputHeight}
          width={60}
          text={translations.general.add}
          onClick={onClickAdd}
        />
      </PopupCreationItemContent>
    </PopupCreationItemContainer>
  )
}

const PopupCreationItemContainer = styled.div`
  font-size: ${styles.fonts.fontSizeSmall};
  display: flex;
  padding: 16px;
  width: 100%;
  color: ${styles.colors.textMediumDarkColor};
  font-weight: normal;
`

const PopupCreationItemContent = styled(PopupItemContent)`
  padding: 0;
`
const StyleSecondaryButton = styled(SecondaryButton)`
  margin-left: 8px;
`
