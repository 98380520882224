import React from 'react'
import styled, { css } from 'styled-components'
import { ActiveSeparator, DeleteButton } from 'gipsy-ui'
import { styles } from 'gipsy-misc'
import { paddingContainerCss } from 'features/layout/pages'

const marginBottomGroupHeader = 8
const StyledActiveSeparator = styled(ActiveSeparator)`
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: ${({ marginBottom }) => (Number.isInteger(marginBottom) ? marginBottom : marginBottomGroupHeader)}px;
  ${(props) =>
    isNaN(parseInt(props.lateralPadding, 10))
      ? paddingContainerCss
      : css`
          padding-left: ${props.lateralPadding}px;
          padding-right: ${props.lateralPadding}px;
        `};
`

export const SubGroupTitleSeparator = ({ onClick, title, isExpanded, lateralPadding, marginBottom }) => {
  return (
    <StyledActiveSeparator
      lateralPadding={lateralPadding}
      onClick={onClick}
      title={title}
      titleFontSize={11}
      titleColor={styles.colors.textMediumDarkColor}
      isExpanded={isExpanded}
      marginBottom={marginBottom}
    />
  )
}

const addItemButtonsContainerHeight = 50

const getOpacityFromTransitionState = (state, hide) => {
  switch (state) {
    case 'exited': {
      return css`
        opacity: 0;
      `
    }
    case 'entered': {
      return css`
        opacity: 1;
      `
    }
    case 'exiting': {
      return css`
        opacity: 0;
      `
    }
    case 'entering': {
      return css`
        opacity: 0;
      `
    }
    default: {
      return css``
    }
  }
}

export const AddItemButtonsContainer = styled.div`
  display: flex;
  transition: opacity 0.4s ease-in-out;
  justify-content: space-between;
  margin-left: ${(props) => props.lateralMargin}px;
  margin-right: ${(props) => props.lateralMargin}px;
  margin-bottom: 10px;
  ${(props) => getOpacityFromTransitionState(props.transitionState, props.hide)}
`

export const CreatingTaskContainer = styled.div`
  position: relative;
  transition: opacity 0.4s ease-in-out;
  ${(props) => getOpacityFromTransitionState(props.transitionState)}
`

export const CreatingItemsSection = styled.div`
  display: grid;
  max-height: ${(props) => (props.isCreationLineShown ? 500 : addItemButtonsContainerHeight)}px;
  transition: max-height 0.4s ease-in-out;
  transition-delay: ${(props) => (props.isCreationLineShown ? 0.4 : 0.6)}s;
  ${AddItemButtonsContainer}, ${CreatingTaskContainer} {
    grid-column: 1;
    grid-row: 1;
    min-width: 0;
  }
`

export const StyledDeleteButton = styled(DeleteButton)`
  margin-left: 5px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: ${styles.spacings.xsmall};
  right: 0;
  & > :first-child {
    margin-right: ${styles.spacings.xsmall};
  }
`

export const RightButtonsContainer = styled(ButtonsContainer)`
  margin-left: auto;
`
