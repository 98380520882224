import moment from 'moment'
import clone from 'lodash/clone'
import isEqual from 'lodash/isEqual'
import setWith from 'lodash/setWith'
import unset from 'lodash/unset'
import get from 'lodash/get'
import set from 'lodash/set'
import pick from 'lodash/pick'
import injected from 'injected'
import {
  convertNanosecondsToMinute,
  durationTimeUnitsByMilliSecond,
  isBeforeToday,
  isBeforeDateStr,
  isToday,
} from 'utils/datetime'
import { getObjectIndexInArray, pickRandomInArray } from 'utils/array'
import { capitalize } from 'utils/string'
import translations from 'translations'
import initialTask from 'models/task'

export const clickOutside = 'clickOutside'

export const shouldShowBottom = ({ task, hideWhenDate, hideEstimatedTime, editing }) => {
  return (
    (task.tags && task.tags.length) ||
    (!hideWhenDate && task.when && task.when.date && task.when.date.length) ||
    (task.estimatedTime && !hideEstimatedTime) ||
    (task.completed && task.completionTime) ||
    (task.urlsInfo && task.urlsInfo.length > 0 && task.urlsInfo[0].url) ||
    editing
  )
}

export const computeTaskBeforeCreate = (task) => {
  if (!task) {
    return task
  }
  const fields = [
    'completed',
    'description',
    'estimatedTime',
    'userId',
    'email',
    'when',
    'title',
    'to',
    'projects',
    'tags',
    'pin',
    'urlInfo',
    'uploads',
    'recurrencyDetails',
    'sprintInfo',
  ]
  let computedTask = pick(task, fields)

  /* if no assign, we assign the task to connected user */
  if (!get(task, 'to.userId') && !get(task, 'to.email')) {
    set(computedTask, 'to.userId', injected.user.id)
  }

  // in case task.to comes from a global state, we avoid modifying globally
  computedTask.to = Object.assign({}, computedTask.to)

  /* CREATE request require having userId OR email, not both */
  if (get(computedTask, 'to.userId') && get(computedTask, 'to.email')) {
    delete computedTask.to.email
  }

  unset(computedTask, 'to.picture')
  unset(computedTask, 'to.displayName')

  if (computedTask.tags) {
    const tagsId = computedTask.tags.map((tag) => tag.id)
    computedTask.tagsId = tagsId
  }

  if (computedTask.projects) {
    const projectsId = computedTask.projects.map((project) => project.id)
    computedTask.projectsId = projectsId
  }

  return computedTask
}

/*
 * return the task updated after a param has changed :
 * in some case, one or more params are related and also have to mutate
 */
export const computeTaskOnChange = (task, { paramName, value, method }, extraParams) => {
  let newTask = Object.assign({}, task)
  let whenDate = get(task, 'when.date') ? moment(task.when.date) : null

  switch (paramName) {
    case 'when.date':
      if (value) {
        setWith(newTask, 'when.date', value, clone)
        if (get(task, 'pin.time')) {
          whenDate = moment(value)
          /* we adjust pin.time date to copy when.date day/month/year */
          let pinTime = moment(task.pin.time).date(whenDate.date()).month(whenDate.month()).year(whenDate.year())
          setWith(newTask, 'pin.time', pinTime.format(), clone)
        } else if (get(task, 'pin.date')) {
          whenDate = moment(value)
          /* we update the pin.date */
          setWith(newTask, 'pin.date', whenDate.format('YYYY-MM-DD'), clone)
        }
      } else {
        unset(newTask, 'pin')
        setWith(newTask, 'when.date', '', clone)
      }
      break

    case 'pin.time':
      if (value) {
        /* if whenDate is null but pinTime is selected, define whenDate to today */
        let pinTime = moment(value)
        setWith(newTask, 'when.date', pinTime.format('YYYY-MM-DD'), clone)
        /* if pin.time is changed, unset pin.date to override it */
        unset(newTask, 'pin.date')
        unset(newTask, 'sprintInfo')
        setWith(newTask, 'pin.time', pinTime.format(), clone)
        if (extraParams && extraParams.estimatedTime) {
          setWith(newTask, 'estimatedTime', extraParams.estimatedTime, clone)
        }
      } else {
        /* if pin.time is deleted, we reset all items linked to it */
        unset(newTask, 'pin')
        unset(newTask, 'estimatedTime')
      }
      break

    case 'comments':
      const comments = newTask.comments ? [].concat(newTask.comments) : []
      if (method === 'add') {
        /* we set pending to true */
        value.pending = true
        comments.unshift(value)
        setWith(newTask, paramName, comments, clone)
      } else if (method === 'remove') {
        /* we set pending to true on the comment to remove after the request */
        const index = getObjectIndexInArray(comments, value)
        if (index !== -1) {
          comments[index].pending = true
          setWith(newTask, paramName, comments, clone)
        }
      }
      break
    case 'uploads':
      const uploads = [].concat(newTask.uploads)
      uploads.push(value)
      setWith(newTask, paramName, uploads, clone)
      break

    case 'tags':
    case 'projects': {
      const items = newTask[paramName] ? [].concat(newTask[paramName]) : []
      if (method === 'add') {
        const newItem = value
        items.push(newItem)
        setWith(newTask, paramName, items, clone)
      } else if (method === 'remove') {
        const index = getObjectIndexInArray(items, value, ['id'])
        if (index !== -1) {
          items.splice(index, 1)
          setWith(newTask, paramName, items, clone)
        }
      }
      break
    }

    case 'sprintInfo':
      if (value && value.id) {
        setWith(newTask, paramName, value, clone)
        unset(newTask, 'pin')
        unset(newTask, 'estimatedTime')
        const when = {
          date: '',
          rank: 0,
        }
        setWith(newTask, 'when', when, clone)
      } else {
        unset(newTask, paramName)
        const when = {
          date: moment().format('YYYY-MM-DD'),
          rank: 0,
        }
        setWith(newTask, 'when', when, clone)
      }
      break
    default:
      setWith(newTask, paramName, value, clone)
      break
  }
  return newTask
}

/* compute the task with the patch success response */
export const computeTaskAfterPatchRequestSuccess = (task, { paramName, value, method = 'change' }, response) => {
  if (!response) {
    return task
  }

  switch (paramName) {
    case 'comments':
      if (method === 'add') {
        /* we add the final comment id and remove the pending property */
        if (!response.newCommentId) {
          break
        }
        /* we create a new task object here to avoid mutating the object if no change is made */
        if (get(task, 'comments[0]') && !get(task, 'comments[0].id')) {
          task = Object.assign({}, task)
          task.comments = [].concat(task.comments)
          task.comments[0] = {
            ...task.comments[0],
            id: response.newCommentId,
          }
          delete task.comments[0].pending
        }
      } else if (method === 'remove') {
        /* we completely remove the comment from the list */
        const index = getObjectIndexInArray(task.comments, value)
        if (index !== -1) {
          task = Object.assign({}, task)
          task.comments = [].concat(task.comments)
          task.comments.splice(index, 1)
          setWith(task, paramName, task.comments, clone)
        }
      }
      break
    default:
      break
  }

  return task
}

/* compute the task with the patch error response */
export const computeTaskAfterPatchRequestError = (task, { paramName, value, method = 'change' }, error) => {
  switch (paramName) {
    case 'comments':
      if (method === 'add') {
        /* remove the comment previously added */
        if (get(task, 'comments[0]') && !get(task, 'comments[0].id')) {
          task = Object.assign({}, task)
          task.comments = [].concat(task.comments)
          task.comments.shift()
        }
      } else {
        /* unset pending property */
        const index = getObjectIndexInArray(task.comments, value)
        if (index !== -1) {
          task = Object.assign({}, task)
          task.comments = [].concat(task.comments)
          task.comments[index] = { ...task.comments[index] }
          delete task.comments[index].pending
          setWith(task, paramName, task.comments, clone)
        }
      }
      break

    default:
      break
  }

  return task
}

/*
 * In some case we change a param and need to send update another param
 * for example : updating when.date when pin.time is active require the app to update pin.time instead
 * this method will compute this and return the final PATCH body that need to be sent
 */
export const computePatchRequest = (task, { paramName, value, method = 'change' }, extraParams = {}) => {
  let params = { [paramName]: value }
  let body = {}

  try {
    if (paramName === 'comments') {
      params = { comments: method }
      if (method === 'add') {
        body = { richText: value.richText }
      } else if (method === 'remove') {
        body = { id: value.id }
      } else if (method === 'edit') {
        body = { id: value.id, richText: value.richText }
      }
    }

    if (paramName === 'uploads') {
      params = { uploads: method }
      if (method === 'add') {
        body = value
      } else {
        throw new Error('Unsupported method for upload PATCH : ' + method)
      }
    }

    if (paramName === 'recurrencyDetails') {
      params = { recurrencyDetails: method }
      if (method !== 'remove') {
        body = value
      }
    }

    if (paramName === 'tags') {
      params = { tags: method }
      body = value
    }

    if (paramName === 'projects') {
      params = { projects: true }
      if (Array.isArray(value)) {
        body = value.map((project) => project.id)
      } else if (typeof value === 'object') {
        body = [value.id]
      }
    }

    if (paramName === 'urlsInfo') {
      params = { urlsInfo: true }
      body = value
    }
  } catch (err) {
    console.error(err)
  }

  params = { ...params, ...extraParams }

  return { params, body }
}

export const updateTaskWhenComplete = (task) => {
  const _task = { ...task }
  _task.completionTime = new Date()
  _task.completed = 1
  _task.sprintInfo = null
  const pinTime = get(_task, 'pin.time')
  if (pinTime) {
    const newStartTime = moment().add(-convertNanosecondsToMinute(_task.estimatedTime), 'minutes')
    _task.pin = { ..._task.pin }
    _task.pin.time = new Date(newStartTime)
  }
  return _task
}

export const getRandomCompletionMessage = (agent) => {
  const randomMessage = pickRandomInArray(translations.task.completed.messages)
  const displayName = agent.displayName || ''
  return capitalize(randomMessage.replace('[displayName]', displayName))
}

export const sortByCompletedAndCreationTimeAndCompletionTime = (tasks) =>
  tasks.sort((a, b) => {
    if (!a.completed && !b.completed) {
      return new Date(b.creationTime) - new Date(a.creationTime)
    } else if (a.completed && b.completed) {
      return new Date(b.completionTime) - new Date(a.completionTime)
    } else if (!a.completed && b.completed) {
      return -1
    } else {
      return 1
    }
  })

export const splitAndSortCompletedTasks = (tasks) => {
  let active = []
  let completed = []
  tasks.forEach((task) => {
    if (task.completed) {
      completed.push(task)
    } else {
      active.push(task)
    }
  })

  return {
    active: active,
    completed: sortByCompletedAndCreationTimeAndCompletionTime(completed), // todo rename function?
  }
}

export const toMap = (tasks) => {
  const m = {}

  if (tasks && tasks.length > 0) {
    for (let task of tasks) {
      m[task.id] = task
    }
  }
  return m
}

export const splitPinAndRegularTasks = (tasks) => {
  let pinnedTasks = []
  let regularTasks = []

  for (let task of tasks) {
    if (task.pin && task.pin.time) {
      pinnedTasks.push(task)
    } else {
      regularTasks.push(task)
    }
  }
  return { pinnedTasks, regularTasks }
}

export const sortByPinTimeAsc = (tasks) => {
  tasks.sort((task1, task2) => {
    const task1Time = get(task1, 'pin.time')
    const task2Time = get(task2, 'pin.time')
    if (!task1Time) return 1
    if (!task2Time) return -1
    return new Date(task1Time) - new Date(task2Time)
  })
}

export const getSpentTime = (task) => {
  let spentTime = 0
  if (task.focusSessions) {
    for (let fs of task.focusSessions) {
      let difference = +new Date(fs.endTime) - +new Date(fs.startTime)
      const nanoSecondsDifference = difference * durationTimeUnitsByMilliSecond
      spentTime += nanoSecondsDifference
    }
  }
  return spentTime
}

export const computeLinksPlaceholder = (urlsInfo) => {
  const numUrls = urlsInfo && urlsInfo.length > 0 ? urlsInfo.length : 0

  let placeHolder
  switch (numUrls) {
    case 1:
      const urlInfo = urlsInfo[0]
      placeHolder = urlInfo.subject || urlInfo.url
      break
    case 0:
      placeHolder = translations.task.urlInfo.fieldLabel
      break
    default:
      placeHolder = `${numUrls} Links`
      break
  }

  return placeHolder
}

export const getCompletionTime = (task) => {
  let completionTime = moment()
  if (get(task, 'pin.time')) {
    const endTime = moment(task.pin.time).add(convertNanosecondsToMinute(task.estimatedTime), 'm')
    completionTime = endTime < completionTime ? endTime : completionTime
  }
  return completionTime
}

export function buildTaskToCreate(creatingData, userId) {
  const taskData = {
    ...initialTask(),
    ...creatingData,
  }
  if (!taskData.to) {
    taskData.to = {
      userId,
    }
  }
  if (taskData.tags) {
    taskData.tagsId = taskData.tags.map((el) => el.id)
  }
  if (taskData.projects) {
    taskData.projectsId = taskData.projects.map((el) => el.id)
  }
  return taskData
}

export const isPast = (item) => {
  const now = moment()
  let isPast = false

  const hasPinTime = item.pin && item.pin.time

  if (hasPinTime) {
    const endTime = moment(item.pin && item.pin.time).add(convertNanosecondsToMinute(item.estimatedTime), 'm')
    isPast = endTime <= now
  } else {
    isPast = item.when && item.when.date && item.when.date < now.format('YYYY-MM-DD')
  }

  return isPast
}

export const computeTimeForToggledPin = (itemDate) => {
  let time

  if (!itemDate || isToday(itemDate) || isBeforeToday(itemDate)) {
    time = moment(new Date(), 'YYYY-MM-DD')
  } else {
    time = moment(itemDate || new Date(), 'YYYY-MM-DD')

    if (time.isAfter(moment())) {
      time.set({ hour: 9, minutes: 0, second: 0, milliseconds: 0 })
    }
  }

  return time
}

const getWhenDate = (item) => {
  if (item?.when?.date) return item.when.date
  if (item?.sprintInfo?.pin?.time) return moment(item.sprintInfo.pin.time).format('YYYY-MM-DD')
}

const hasPin = (item) => !!(item.pin?.time || item.sprintInfo?.pin?.time)

const isPinBefore = (a, b) => {
  const pinA = moment(a.sprintInfo ? a.sprintInfo.pin?.time : a.pin?.time)
  const pinB = moment(b.sprintInfo ? b.sprintInfo.pin?.time : b.pin?.time)

  if (pinA.isSame(pinB)) {
    return moment(a.creationTime).isBefore(moment(b.creationTime))
  }

  return pinA.isBefore(pinB)
}

export const isScheduledBefore = (a, b) => {
  const whenA = getWhenDate(a)
  const whenB = getWhenDate(b)

  switch (true) {
    case whenA !== whenB: {
      return isBeforeDateStr(whenA, whenB)
    }
    case hasPin(a) && hasPin(b): {
      return isPinBefore(a, b)
    }
    case hasPin(a) || hasPin(b): {
      return hasPin(a)
    }
    default: {
      return moment(a.creationTime).isBefore(moment(b.creationTime))
    }
  }
}

export const sortByScheduledDate = (list) => [...list].sort((a, b) => (isScheduledBefore(b, a) ? 1 : -1))
export const isRecurrent = (task) => !!task.recurrencyInformation
export const getRecTaskId = (task) => task.recurrencyInformation?.recurringTaskId

const verifyIfCollectionIsEqual = (c1, c2, collectionId) => {
  if (!c1 && !c2) return true
  if ((c1 && !c2) || (!c1 && c2) || c1?.length !== c2?.length) return false

  const c1Map = {}
  const c2Map = {}

  for (let i = 0; i < c1.length; i++) {
    const c1Item = c1[i]
    const c2Item = c2[i]

    if (!c1Item?.[collectionId] || !c2Item?.[collectionId]) return false

    c1Map[c1Item[collectionId]] = c1Item
    c2Map[c2Item[collectionId]] = c2Item
  }

  const c1ItemsIds = Object.keys(c1Map)

  if (c1ItemsIds.length !== Object.keys(c2Map).length) return false

  for (let i = 0; i < c1ItemsIds.length; i++) {
    const id = c1ItemsIds[i]
    const c1Item = c1Map[id]
    const c2Item = c2Map[id]

    if (!c2Item || !isEqual(c1Item, c2Item)) return false
  }

  return true
}

export const areTaskAttributesNotEqual = (t1, t2) => {
  return (
    t1.pin?.time !== t2.pin?.time ||
    t1.when?.date !== t2.when?.date ||
    t1.estimatedTime !== t2.estimatedTime ||
    t1.title !== t2.title ||
    !verifyIfCollectionIsEqual(t1.urlsInfo, t2.urlsInfo, 'url') ||
    !verifyIfCollectionIsEqual(t1.projects, t2.projects, 'id') ||
    !verifyIfCollectionIsEqual(t1.tags, t2.tags, 'id')
  )
}

export const areTaskSimpleAttributesNotEqual = (t1, t2) => {
  return (
    t1.title !== t2.title ||
    !verifyIfCollectionIsEqual(t1.urlsInfo, t2.urlsInfo, 'url') ||
    !verifyIfCollectionIsEqual(t1.projects, t2.projects, 'id') ||
    !verifyIfCollectionIsEqual(t1.tags, t2.tags, 'id')
  )
}

export const isTimeBefore = (item1, item2) => {
  const pinTime1 = moment(item1.pin.time)
  const pinTime2 = moment(item2.pin.time)
  switch (true) {
    case pinTime1.unix() !== pinTime2.unix():
      return pinTime1.isBefore(pinTime2)
    case item1.estimatedTime !== item2.estimatedTime:
      return item1.estimatedTime < item2.estimatedTime
    default:
      return moment(item1.creationTime).isBefore(moment(item2.creationTime))
  }
}
