import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { styles, variables as miscVariables } from 'gipsy-misc'
import { BlurLayer, Icon } from 'gipsy-ui'

import variables from 'assets/styles/variables'
import { pageBlurLayerPortalId } from 'features/layout'

import NavBar from './NavBar'

export default function CollapsedNavbar() {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleHideNavbar = () => setShowNavbar(false)
  const handleShowNavbar = () => setShowNavbar(true)

  const collapsedModeRendeNode = document.querySelector(`#${pageBlurLayerPortalId}`)

  return (
    <>
      <Trigger onClick={handleShowNavbar} onMouseEnter={handleShowNavbar}>
        <CollapseIcon fill={styles.colors.textMediumDarkColor} icon='Collapse' size={16} />
      </Trigger>
      {showNavbar &&
        collapsedModeRendeNode &&
        createPortal(
          <StyledBlurLayer name={'navbar-modal'} active={showNavbar} onClose={handleHideNavbar}>
            <NavbarWrapper onMouseLeave={handleHideNavbar}>
              <NavBar />
            </NavbarWrapper>
          </StyledBlurLayer>,
          collapsedModeRendeNode
        )}
    </>
  )
}

const Trigger = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 7px;
  display: flex;
  height: 32px;
  left: -2px;
  position: absolute;
  top: 11px;
  width: 46px;
  z-index: 1;
`

Trigger.displayName = 'Trigger'

const CollapseIcon = styled(Icon)`
  margin-left: auto;
  margin-right: 9px;
`

CollapseIcon.displayName = 'CollapseIcon'

const StyledBlurLayer = styled(BlurLayer)`
  display: flex;

  &.navbar-modal-layer {
    position: absolute;
    z-index: ${miscVariables.zIndex.pageBlurLayer};

    ::before {
      background: ${styles.colors.textMediumDarkColor};
    }
  }

  &.navbar-modal-layer .navbar-modal-content {
    background-color: white;
    opacity: 0;
    overflow: visible;
    position: relative;
    transition: opacity ${styles.transitions.blurLayer}s ease-in-out;
    width: ${variables.navbarWidth}px;
  }

  &.navbar-modal-layer.active {
    .navbar-modal-content {
      opacity: 1;
    }

    ::before {
      opacity: 0.6;
    }
  }
`
StyledBlurLayer.displayName = 'StyledBlurLayer'

const NavbarWrapper = styled.div`
  height: 100%;
  padding: 24px 10px;
  width: 100%;
`

NavbarWrapper.displayName = 'NavbarWrapper'
