import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { models, styles, translations, utils, variables as miscVariables } from 'gipsy-misc'
import { AnimateHeight, BlurLayer, Calendar, FocusedLineCompressedConsts, Icon } from 'gipsy-ui'

import {
  AddCalendarItemPopup,
  EditCalendarItemPopup,
  TaskLineModal,
} from 'features/calendar/components/CalendarPanel/components'
import CalendarNav, { NavigationContainer } from 'features/calendar/components/CalendarPanel/components/CalendarNav'
import useCalendarActions from 'features/calendar/components/CalendarPanel/hooks/useCalendarActions'
import { fadeIn } from 'features/keyframes'
import { layoutBlurLayerPortalId } from 'features/layout/index'
import {
  AddItemButtonsContainer,
  CreatingItemsSection as CreatingItemsSectionContainer,
} from 'features/list/components/commonUI'
import InProgressSprintLine from 'features/list/components/inprogress-sprint-line'
import { ArchiveAllButton, CloseIcon } from 'pages/today/active/today'
import { CreatingItemsSection } from 'pages/today/active/components'
import { StyledActiveSeparator } from 'pages/today/active/components/sharedUIComponents'
import CompletedItems from 'pages/today/active/today/components/CompletedItems'
import OtherItems from 'pages/today/active/today/components/OtherItems'
import PinnedItems from 'pages/today/active/today/components/PinnedItems'
import { updateCalendarDate } from 'store/calendar/actions'

import container, { InProgressSprintLineDroppableId, sections } from './container'

const componentName = 'PlanMyDayLightbox'
const { containerHeight: focusedLineHeight } = FocusedLineCompressedConsts

function PlanMyDayLightbox({
  calendarItems,
  cancelCalendarTaskAction,
  creatingCalendarTask,
  creatingSprint,
  creationLineProps,
  date,
  editingCalendarTask,
  editingSprint,
  firstDay,
  getCompletedTaskProps,
  getPinnedTaskProps,
  getRegularTaskProps,
  handleDragEnd,
  inProgressSprints,
  isCreatingInlineTask,
  isDragging,
  isSprintComposerShown,
  isTaskCreationAlertShown,
  items,
  onArchiveAllCompletedToday,
  onChangePlanMyDayDate,
  onClickDelete,
  onClose,
  onComplete,
  onCompleteFromFS,
  onCreateCalendarTask,
  onCreateSprint,
  onDragStart,
  onSave,
  onSaveCalendarTask,
  onTogglePinFromCalendarPanel,
  pageSource,
  session,
  sprintLineProps,
  sprintTaskProps,
  startInlineTaskCreation,
  startSprintCreation,
  startSprintEdition,
  toHideTaskId,
}) {
  const dispatch = useDispatch()

  const [isArchiveAllButtonVisible, setArchiveAllButtonVisible] = useState(false)
  const [scrollToTime, setScrollToTime] = useState(() => {
    if (utils.datetime.isToday(date)) {
      return moment().toDate()
    } else {
      return moment(date).set('hour', 7).toDate()
    }
  })

  const archiveAllButtonRef = useRef(null)
  const calendarContainerRef = useRef(null)
  const taskLineModalNode = useRef(null)

  const {
    addItemPopupRef,
    addItemPopupPositionProps,
    calendarDate,
    calendarRef,
    clickedItem,
    completeCalendarTask,
    editItemPopupRef,
    filteredCalendarItems,
    getCalendarRef,
    getSelectedSlotBounds,
    handleEditingItemUpdate,
    handleUpdateClickedItem,
    hideModalsAndPopups,
    isAddItemPopupShown,
    isEditItemPopupShown,
    isTaskLineModalShown,
    localEditingCalendarTask,
    onCancelTaskAction,
    onChangeAddCalendarItemTitle,
    onChangeDuration,
    onChangeSlotRange,
    onChangeStartDate,
    onChangeStartTime,
    onClickDeleteClickedItem,
    onClickDeleteTask,
    onClickEvent,
    onClickLeftArrow,
    onClickOutsideCalendarModalOrPopup,
    onClickRemoveTask,
    onClickRightArrow,
    onClickToday,
    onCreateSprint: onCreateSprintFromCalendar,
    onCreateTask: onCreateTaskFromCalendar,
    onCurrentTaskChange,
    onEventDropMiddleware,
    onEventResize,
    onSaveTask,
    onSelectSlot,
    onTogglePin,
    selectedSlot,
    startFocusSession,
    startLocalCalendarItemEdition,
    startSprintCreation: startSprintCreationFromCalendar,
    startTaskCreation,
    registerShortcuts,
    unregisterShortcuts,
  } = useCalendarActions({
    calendarContainerRef,
    calendarItems,
    cancelCalendarTaskAction,
    creatingCalendarTask,
    creatingSprint,
    date: moment(date).toDate(),
    editingCalendarTask,
    editingSprint,
    isDragging,
    isSprintComposerShown,
    onCreateCalendarTask,
    onCreateSprint,
    onSaveCalendarTask,
    onTogglePinFromCalendarPanel,
    startSprintCreation,
    startSprintEdition,
    weekView: false,
  })

  useEffect(() => {
    dispatch(updateCalendarDate(calendarDate))
  }, [calendarDate, dispatch])

  useEffect(() => {
    registerShortcuts(
      [
        {
          key: 'q',
          label: translations.calendar.createTask,
          callback: () => {
            startInlineTaskCreation()
          },
        },
      ],
      componentName
    )

    return () => {
      unregisterShortcuts(componentName)
    }
  }, [registerShortcuts, startInlineTaskCreation, unregisterShortcuts])

  const toHideTasksIdsMap = useMemo(() => {
    const idMap = {}

    if (toHideTaskId) {
      idMap[toHideTaskId] = true
    }

    if (editingCalendarTask) {
      idMap[editingCalendarTask.id] = true
    }

    if (session?.completedSession?.taskId) {
      idMap[session.completedSession.taskId] = true
    }

    inProgressSprints.forEach((inProgressSprint) => {
      idMap[inProgressSprint.id] = true
    })

    return idMap
  }, [editingCalendarTask, inProgressSprints, session?.completedSession?.taskId, toHideTaskId])

  const filteredPinnedItems = useMemo(() => {
    const filtered = items[sections.PINNED].reduce((filteredList, item) => {
      if (toHideTasksIdsMap[item.id]) return filteredList

      if (item.type === models.item.type.SPRINT) {
        const filteredSprint = { ...item }
        filteredSprint.tasks = (filteredSprint.tasks || []).filter((task) => !toHideTasksIdsMap[task.id])
        filteredList.push(filteredSprint)
      } else {
        filteredList.push(item)
      }

      return filteredList
    }, [])

    return filtered
  }, [items, toHideTasksIdsMap])

  const filteredOtherItems = useMemo(() => {
    const filtered = items[sections.OTHER].filter((item) => !toHideTasksIdsMap[item.id])
    return filtered
  }, [items, toHideTasksIdsMap])

  const filteredCompletedItems = useMemo(() => {
    const filtered = items[sections.COMPLETED].filter((item) => !toHideTasksIdsMap[item.id])
    return filtered
  }, [items, toHideTasksIdsMap])

  const handleClickToday = () => {
    onClickToday()
    setScrollToTime(new Date())
  }

  const handleStartFocusSession = (data, { componentSource } = {}) => {
    const context = { componentSource, pageSource }
    startFocusSession(data, context)
  }

  const portalNode = document.querySelector(`#${layoutBlurLayerPortalId}`)
  const today = moment()
  const firstDayOfWeek = session.user.settingsPreferences.calendar.firstDay
  const formats = { other: 'ddd, D', sameWeek: 'ddd, D' }
  const dateMoment = moment(date)
  const nextDateMoment = dateMoment.clone().add(1, 'day')
  const prevDateMoment = dateMoment.clone().subtract(1, 'day')

  return portalNode
    ? createPortal(
        <LightboxBlurLayer active name='lightbox' onClose={onClose} showFocusSession={toHideTaskId}>
          <Content>
            <ItemsSection>
              <Navigation>
                <NavigationButton onClick={() => onChangePlanMyDayDate(prevDateMoment.format('YYYY-MM-DD'))}>
                  <Icon fill={styles.colors.primaryColor} icon={'ArrowLeft'} size={12} />
                  <span>{utils.datetime.humanizeDateFromToday(prevDateMoment, today, formats)}</span>
                </NavigationButton>
                <Title>{utils.datetime.humanizeDateFromToday(dateMoment, today, formats)}</Title>
                <NavigationButton onClick={() => onChangePlanMyDayDate(nextDateMoment.format('YYYY-MM-DD'))}>
                  <span>{utils.datetime.humanizeDateFromToday(nextDateMoment, today, formats)}</span>
                  <Icon fill={styles.colors.primaryColor} icon={'ArrowRight'} size={12} />
                </NavigationButton>
              </Navigation>
              <DragDropContext onDragStart={onDragStart} onDragEnd={handleDragEnd}>
                <InProgressSprintsContainer>
                  {inProgressSprints.length > 0 && (
                    <Droppable droppableId={JSON.stringify({ id: InProgressSprintLineDroppableId })} isDropDisabled>
                      {(droppableProvided) => (
                        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                          {inProgressSprints.map((inProgressSprint, index) => (
                            <Draggable
                              draggableId={inProgressSprint.id}
                              index={index}
                              key={inProgressSprint.id}
                              type={models.item.type.SPRINT}>
                              {(draggableProvided, snapshot) => (
                                <InProgressSprintLine
                                  draggableProps={draggableProvided.draggableProps}
                                  draggableStyle={draggableProvided.draggableProps.style}
                                  dragHandleProps={draggableProvided.dragHandleProps}
                                  innerLeftPadding={0}
                                  innerRef={draggableProvided.innerRef}
                                  innerRightPadding={0}
                                  isCalendarDraggable={true}
                                  isDragging={snapshot.isDragging}
                                  onClickDelete={onClickDelete}
                                  onClickDeleteSprint={sprintLineProps.onClickDelete}
                                  onClickEditSprint={sprintLineProps.onClickEdit}
                                  onDeleteFocusSession={sprintLineProps.onDeleteFocusSession}
                                  onComplete={onComplete}
                                  onCompleteFromFS={onCompleteFromFS}
                                  onEndSprint={sprintLineProps.onEnd}
                                  onRemoveFromSprint={sprintTaskProps.onRemoveFromSprint}
                                  onSave={onSave}
                                  sprint={inProgressSprint}
                                  sprintInlineTaskProps={sprintLineProps.sprintInlineTaskProps}
                                  startSprintCreation={startSprintCreation}
                                />
                              )}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  )}
                </InProgressSprintsContainer>
                <CreatingItemsSection
                  creationLineDate={date}
                  creationLineProps={creationLineProps}
                  isCreatingCalendarTask={!!creatingCalendarTask}
                  isCreatingInlineTask={isCreatingInlineTask}
                  isTaskCreationAlertShown={isTaskCreationAlertShown}
                  removeStartTimerButton
                  removeFocusBlockButton
                  session={session}
                  startInlineTaskCreation={startInlineTaskCreation}
                />
                <PinnedItems
                  getTaskProps={getPinnedTaskProps}
                  items={filteredPinnedItems}
                  pageSource={pageSource}
                  sprintProps={sprintLineProps}
                />
                <OtherItems getTaskProps={getRegularTaskProps} items={filteredOtherItems} pageSource={pageSource} />
                {!!filteredCompletedItems.length && (
                  <CompletedItems
                    archiveAllButtonRef={archiveAllButtonRef}
                    getTaskProps={getCompletedTaskProps}
                    isDragging={isDragging}
                    items={filteredCompletedItems}
                    pageSource={pageSource}
                    setArchiveAllButtonVisible={setArchiveAllButtonVisible}
                  />
                )}
              </DragDropContext>
              <AnimateHeight duration={300} height={isArchiveAllButtonVisible ? 'auto' : 0}>
                <ArchiveAllButton
                  ref={archiveAllButtonRef}
                  onMouseLeave={() => setArchiveAllButtonVisible(false)}
                  onClick={onArchiveAllCompletedToday}>
                  <CloseIcon icon='Close' size={9} fill={styles.colors.veryLightGrey} />
                  {translations.todayView.completed.archiveAll}
                </ArchiveAllButton>
              </AnimateHeight>
            </ItemsSection>
            <div ref={taskLineModalNode} />
            <CalendarSection ref={calendarContainerRef}>
              {isAddItemPopupShown && (
                <AddCalendarItemPopup
                  firstDayOfWeek={firstDayOfWeek}
                  left={null}
                  onChangeDuration={onChangeDuration}
                  onChangeSlotRange={onChangeSlotRange}
                  onChangeStartDate={onChangeStartDate}
                  onChangeTitle={onChangeAddCalendarItemTitle}
                  onClickOutside={onClickOutsideCalendarModalOrPopup}
                  onClose={hideModalsAndPopups}
                  onCreateTask={onCreateTaskFromCalendar}
                  onCreateSprint={onCreateSprintFromCalendar}
                  onTogglePin={onTogglePin}
                  ref={addItemPopupRef}
                  selectedSlot={selectedSlot}
                  showTooltip={false}
                  startSprintCreation={startSprintCreationFromCalendar}
                  startTaskCreation={startTaskCreation}
                  top={addItemPopupPositionProps.top}
                />
              )}
              {isEditItemPopupShown && (
                <EditCalendarItemPopup
                  firstDayOfWeek={firstDayOfWeek}
                  getCalendarRef={getCalendarRef}
                  getSelectedSlotBounds={getSelectedSlotBounds}
                  item={clickedItem}
                  onClickComplete={completeCalendarTask}
                  onClickDelete={onClickDeleteClickedItem}
                  onClickDeleteTask={onClickDeleteTask}
                  onClickEdit={startLocalCalendarItemEdition}
                  onClickOutside={onClickOutsideCalendarModalOrPopup}
                  onClickRemoveTask={onClickRemoveTask}
                  onClickStart={handleStartFocusSession}
                  onClose={hideModalsAndPopups}
                  onUpdateClickedItem={handleUpdateClickedItem}
                  onUpdateItem={handleEditingItemUpdate}
                  positionVerticallyOnly
                  ref={editItemPopupRef}
                  registerShortcuts={registerShortcuts}
                  unregisterShortcuts={unregisterShortcuts}
                />
              )}
              {!isEditItemPopupShown && (
                <TaskLineModal
                  showModal={isTaskLineModalShown}
                  onClickOutside={onClickOutsideCalendarModalOrPopup}
                  getCalendarRef={getCalendarRef}
                  getSelectedSlotBounds={getSelectedSlotBounds}
                  onSave={onSaveTask}
                  fixedPosition={false}
                  onTogglePin={onTogglePin}
                  onCancel={onCancelTaskAction}
                  onCreate={onCreateTaskFromCalendar}
                  onDelete={onClickDeleteTask}
                  creatingCalendarTask={creatingCalendarTask}
                  editingCalendarTask={editingCalendarTask}
                  localEditingCalendarTask={localEditingCalendarTask}
                  selectedSlot={selectedSlot}
                  onCurrentTaskChange={onCurrentTaskChange}
                  onChangeDuration={onChangeDuration}
                  onChangeStartTime={onChangeStartTime}
                  portalNode={taskLineModalNode.current}
                />
              )}
              <CalendarNav
                calendarDate={calendarDate}
                calendarTitle={moment(calendarDate).format('ddd, MMM DD')}
                dayView
                onClickLeftArrow={onClickLeftArrow}
                onClickRightArrow={onClickRightArrow}
                onClickToday={handleClickToday}
              />
              <StyledCalendar
                calendarItems={filteredCalendarItems}
                date={calendarDate}
                disableSelection={isEditItemPopupShown}
                firstDay={firstDay}
                hasDndNavigation={false}
                isCalendarSectionExpanded={true}
                isDraggable
                isDragging={isDragging}
                onClickEvent={onClickEvent}
                onEventDrop={onEventDropMiddleware}
                onEventResize={onEventResize}
                onSelectSlot={onSelectSlot}
                ref={calendarRef}
                scrollTimeReset={false}
                scrollToTime={scrollToTime}
                viewMode={Calendar.viewModes.DAY}
              />
            </CalendarSection>
          </Content>
        </LightboxBlurLayer>,
        portalNode
      )
    : null
}

export default container(PlanMyDayLightbox)

const lightBoxBorderRadius = 18
const calendarWidth = 296

const LightboxBlurLayer = styled(BlurLayer)`
  animation: ${fadeIn} 300ms ease-in-out forwards;
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  z-index: ${miscVariables.zIndex.lightbox};

  &.lightbox-layer,
  &.lightbox-layer.active {
    max-height: 100%;
    transition: max-height 300ms ease, top 300ms ease;

    ${({ showFocusSession }) =>
      showFocusSession &&
      css`
        max-height: calc(100% - ${focusedLineHeight}px);
        top: ${focusedLineHeight}px;
      `}

    ::before {
      background: ${styles.colors.textMediumDarkColor};
      opacity: 0.6;
      z-index: -1;
    }

    .lightbox-content {
      height: calc(100% - 252px);
      max-width: 1200px;
      overflow: visible;
      width: 75%;
    }
  }

  & .add-task-modal-layer {
    margin-right: ${calendarWidth}px;
    padding-right: 0;

    ::before {
      background: transparent;
      border-radius: ${lightBoxBorderRadius}px 0 0 ${lightBoxBorderRadius}px;
    }
  }
`

LightboxBlurLayer.displayName = 'LightboxBlurLayer'

const Content = styled.div`
  background-color: ${styles.colors.veryLightGrey};
  border-radius: ${lightBoxBorderRadius}px;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
`

Content.displayName = 'Content'

const ItemsSection = styled.div`
  flex: 1;
  margin: 32px 0;
  overflow: auto;
  padding: 0 ${calendarWidth + 64}px 0 96px;

  ${CreatingItemsSectionContainer} {
    margin-bottom: 25px;
  }

  ${AddItemButtonsContainer} {
    margin: 0;
  }

  ${StyledActiveSeparator} {
    padding: 0;
  }
`

ItemsSection.displayName = 'ItemsSection'

const Navigation = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%;
`

Navigation.displayName = 'Navigation'

const NavigationButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  user-select: none;

  & > :first-child {
    margin-right: 12px;
  }
`

NavigationButton.displayName = 'NavigationButton'

const Title = styled.p`
  font-size: 24px;
  margin: 0;
`

Title.displayName = 'Title'

const InProgressSprintsContainer = styled.div`
  margin-bottom: 24px;
`

InProgressSprintsContainer.displayName = 'InProgressSprintsContainer'

const CalendarSection = styled.div`
  background: white;
  border-radius: 0 ${lightBoxBorderRadius}px ${lightBoxBorderRadius}px 0;
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  height: 100%;
  max-width: ${calendarWidth}px;
  position: absolute;
  right: 0;
  width: ${calendarWidth}px;

  ${NavigationContainer} {
    opacity: 1;
    padding: 24px 16px;
  }
`

CalendarSection.displayName = 'CalendarSection'

const StyledCalendar = styled(Calendar)`
  border-radius: 0 ${lightBoxBorderRadius}px ${lightBoxBorderRadius}px 0;
  overflow: auto;
  width: 100%;
`

StyledCalendar.displayName = 'StyledCalendar'
