import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { Duration, Icon, SimpleButton, TimeInput2 } from 'gipsy-ui'

function TaskPinControls({ date, durationProps, startTimeProps, unpinHandler }) {
  const [buttonHovered, setButtonHovered] = useState(false)

  return (
    <Container>
      <div onMouseEnter={() => unpinHandler && setButtonHovered(true)} onMouseLeave={() => setButtonHovered(false)}>
        <StyledButton
          leftIcon={<StyledIcon fill={styles.colors.primaryColor} icon={'Pin'} size={16} />}
          hovered={buttonHovered}
          onClick={unpinHandler}
          text={buttonHovered ? translations.general.unpin : translations.general.pinned}
        />
      </div>
      <Row>
        <Label>{translations.general.date}:</Label>
        <DateContainer>{date}</DateContainer>
      </Row>
      <Row>
        <Label>{translations.general.start}:</Label>
        <StyledTimeInput2 width={72} transparent={true} showPeriod={true} format='12h' {...startTimeProps} />
      </Row>
      <Row>
        <Label>{translations.general.duration}:</Label>
        <Duration
          colonPadding={1}
          controlMargins={2}
          width={90}
          inputWidth={24}
          transparent={true}
          {...durationProps}
        />
      </Row>
    </Container>
  )
}

TaskPinControls.propTypes = {
  date: PropTypes.string,
  durationProps: PropTypes.object,
  startTimeProps: PropTypes.object,
  unpinHandler: PropTypes.func,
}

export default TaskPinControls

const StyledTimeInput2 = styled(TimeInput2)`
  transform: translateX(6px);
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out, transform 200ms ease-in-out;

  &:hover {
    transform: translateX(0);
  }

  &:focus-within {
    transform: translateX(0);
  }
`
const Container = styled.div`
  background: white;
  border-radius: ${(props) => (props.hasSideIcon ? `10px 0 10px 10px` : `10px`)};
  filter: ${styles.shadows.dropShadowPopup};
  filter: drop-shadow(0px 10px 26px rgba(33, 21, 81, 0.15));
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: ${styles.spacings.lineContainerPadding};
  position: relative;
  width: 180px;
`
Container.displayName = 'TaskPinControls Container'

const StyledIcon = styled(Icon)`
  margin-left: -12px;
  margin-right: 6px;
  transition: transform 100ms ease-in-out;
  transform: translateY(-1px) rotate(-46deg);
`

const StyledButton = styled(SimpleButton)`
  align-items: center;
  background-color: white;
  border-color: ${styles.colors.primaryColor}33;
  color: ${styles.colors.primaryColor};
  display: flex;
  height: 40px;
  justify-content: center;

  ${(props) =>
    props.hovered &&
    css`
      border-color: ${styles.colors.primaryColor};

      ${StyledIcon} {
        transform: translateY(0) rotate(0);
      }
    `}
`

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
  font-size: 13px;
  color: ${styles.colors.darkGrey};
`

const DateContainer = styled.div`
  font-size: 14px;
  color: ${styles.colors.textDarkColor};
`
