import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { Header, SectionContainer } from 'pages/settings/components/common'

import { styles, utils } from 'gipsy-misc'
import { user } from 'gipsy-api'
import { Icon } from 'gipsy-ui'

const DELAY_BEFORE_PATCH_REQUEST = 500

const propTypes = {
  user: PropTypes.object.isRequired,
}

class CustomExtensionId extends PureComponent {
  state = {
    saved: false,
    extensionId: '',
  }

  constructor(props) {
    super(props)
    this.state = { ...this.state, extensionId: this.props.user.extensionId }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.extensionId !== this.props.user.extensionId) {
      this.setState({ extensionId: this.props.user.extensionId })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  onChange = async () => {
    const value = this.input.value
    this.setState({ extensionId: value, error: undefined })

    utils.resolve.once(
      'extensionId',
      () => {
        this.updateExtensionId(value)
      },
      DELAY_BEFORE_PATCH_REQUEST
    )
  }

  updateExtensionId = async (extensionId) => {
    try {
      await user.patch(undefined, { extensionId: extensionId })
      this.setSave(extensionId)
    } catch (err) {
      console.error(err)
      this.setState({ error: get(err, 'data.code') })
    }
  }

  setSave = () => {
    this.setState({ saved: true, error: undefined })
    this.timeout = setTimeout(() => {
      this.setState({ saved: false })
    }, 5000)
  }

  render = () => {
    const { error, extensionId } = this.state
    const { user } = this.props

    return utils.user.isUserInBetaList(user) ? (
      <SectionContainer>
        <Header title={'Chrome Extension ID'} isSaved={this.state.saved} />
        <LinkContainer className="fs-mask">
          <PrefixContainer>{'Your custom extension id'}</PrefixContainer>
          <SuffixContainer error={error}>
            <Input
              value={extensionId}
              ref={(ref) => {
                this.input = ref
              }}
              onChange={this.onChange}
            />
          </SuffixContainer>
        </LinkContainer>
        {error && (
          <ErrorContainer>
            <Icon icon="ExclamationTriangle" size={14} fill={styles.colors.errorColor} />
            <ErrorTextContainer>{'error'}</ErrorTextContainer>
          </ErrorContainer>
        )}
      </SectionContainer>
    ) : (
      <div />
    )
  }
}

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`

const PrefixContainer = styled.span`
  flex-shrink: 0;
`

const SuffixContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #ebeaee;

  border: ${(props) => props.error && `1px solid ${styles.colors.errorColor}`};
  border-radius: 8px;
  height: 40px;
  width: 100%;
  margin-left: 5px;
  padding: 5px;
`

const Input = styled.input`
  background-color: initial;
  border: none;
  font: inherit !important;
  color: ${styles.colors.textMediumDarkColor} !important;
  width: 100%;
  font-size: ${styles.fonts.fontSizeSmall} !important;
`

const ErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ErrorTextContainer = styled.span`
  color: ${styles.colors.errorColor};
  font-size: ${styles.fonts.fontSizeSmall};
  margin-left: 5px;
`
CustomExtensionId.propTypes = propTypes

export default CustomExtensionId
