import React from 'react'

import { Attribute, AttributeIcon, AttributeName, TagsContainer } from 'LineView/components/commonUIComponents'

function TagsAttribute({ tags }) {
  return (
    <TagsContainer>
      {tags &&
        tags.map((tag) => (
          <Attribute hideOverflow key={tag.id} title={tag.name}>
            <AttributeIcon size={11} icon={'Tag'} />
            <AttributeName>{tag.name}</AttributeName>
          </Attribute>
        ))}
    </TagsContainer>
  )
}

export default TagsAttribute
