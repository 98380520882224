import React from 'react'
import styled from 'styled-components'
import asanaLogo from 'assets/asana.png'
import { styles } from 'gipsy-misc'

export default function AsanaWorkspaceAttribute({ asanaWorkspaceName, className }) {
  return (
    <AsanaWorkspaceContainer className={className}>
      <AsanaWorkspaceName>{asanaWorkspaceName}</AsanaWorkspaceName>
      <AsanaIcon alt={'asana logo'} src={asanaLogo} />
    </AsanaWorkspaceContainer>
  )
}

const AsanaWorkspaceContainer = styled.div`
  display: flex;
  padding-right: 15px;
  min-width: 0;
`

const AsanaWorkspaceName = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  color: ${styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeXXSmall};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const AsanaIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 4px;
`
