import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { produce } from 'immer'
import moment from 'moment'

import { todayview as todayviewApi } from 'gipsy-api'
import { models, utils } from 'gipsy-misc'

import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import usePageActions from 'features/hooks/usePageActions2'
import layoutBreakpoints from 'features/layout/breakpoints'
import { pageSource } from 'features/source'
import { innerNestedLeftPadding, innerNestedRightPadding } from 'features/layout/pages'
import { sortListByWhenRank } from 'logic/list'
import { updateItems } from 'store/items/actions'
import { getItemsByDate } from 'store/items/selectors'

export const InProgressSprintLineDroppableId = 'InProgressSprintLineDroppable'
export const sections = {
  COMPLETED: 'completed',
  OTHER: 'other',
  PINNED: 'pinned',
}

export default function wrapper(Component) {
  function PlanMyDayLightboxContainer({ calendarItems, date, ...props }) {
    const { getDroppableId, sprintComposerProps } = useCalendarPanelContext()
    const dispatch = useDispatch()
    const {
      archiveTask,
      completeTask,
      completeTaskFromFS,
      createInlineTask,
      deleteSprint,
      endSprint,
      findItemById,
      getFocusedTaskId,
      isTaskCreationAlertShown,
      onClickDelete,
      onClickDeleteFocusSession: _onClickDeleteFocusSession,
      onClickFocusSession,
      onClickOutsideSprint,
      onClickSprint,
      onTaskDroppedInSprint,
      saveTask,
      sprintDeletePopup,
      sprints,
      startFsAndCreateTask,
      uncompleteTask,
      updateFocusSession,
    } = usePageActions()
    const itemsByDate = useSelector((state) => getItemsByDate(state.items))
    const session = useSelector((state) => state.session)

    const [calendarTaskProps, setCalendarTaskProps] = useState({
      creatingCalendarTask: null,
      editingCalendarTask: null,
      ignoreOutsideClicks: false,
    })

    const [localTaskProps, setLocalTaskProps] = useState({
      creatingTask: null,
      editingTask: null,
      isCreatingInlineTask: false,
      keepCreatingTasks: false,
    })

    const [dragData, setDragData] = useState({
      draggingData: undefined,
      isDragging: false,
    })

    const [windowWidth, setWindowWidth] = useState(Infinity)

    const clearLocalTaskState = useCallback(({ keepCreatingTasks } = {}) => {
      setLocalTaskProps({
        creatingTask: null,
        editingTask: null,
        keepCreatingTasks: !!keepCreatingTasks,
        isCreatingInlineTask: false,
      })
    }, [])

    const cancelTaskAction = useCallback(() => {
      clearLocalTaskState()
    }, [clearLocalTaskState])

    const itemList = useMemo(() => {
      const currentDateItems = itemsByDate[date]
      const pageGroups = {
        [sections.COMPLETED]: [],
        [sections.OTHER]: [],
        [sections.PINNED]: [],
      }

      const items = Object.keys(currentDateItems || {}).reduce((items, id) => {
        const item = currentDateItems[id]
        let group

        if (!item || item.archived || (item.completionTime && item.type === models.item.type.SPRINT)) return items

        if (item.completionTime && item.type === models.item.type.TASK) {
          group = sections.COMPLETED
        } else if (!item.completionTime) {
          group = item.pin?.time ? sections.PINNED : sections.OTHER
        }

        items[group].push(item)
        return items
      }, pageGroups)

      items[sections.PINNED] = utils.list.sortListByScheduledTime(items[sections.PINNED])
      items[sections.OTHER] = sortListByWhenRank(items[sections.OTHER])
      items[sections.COMPLETED] = utils.task.sortByCompletedAndCreationTimeAndCompletionTime(items[sections.COMPLETED])

      return items
    }, [date, itemsByDate])

    const inProgressSprints = useMemo(() => utils.sprint.getSprintsInProgress(sprints), [sprints])

    const shouldShowVerticalLine = useMemo(() => {
      return windowWidth < layoutBreakpoints.desktopLarge
    }, [windowWidth])

    useLayoutEffect(() => {
      const trackWindowWidth = () => {
        setWindowWidth(window.innerWidth)
      }

      trackWindowWidth()
      window.addEventListener('resize', trackWindowWidth)

      return () => {
        window.removeEventListener('resize', trackWindowWidth)
      }
    }, [])

    const onComplete = useCallback(
      async ({ id, value }) => {
        if (value) {
          await completeTask({ id })
        } else {
          await uncompleteTask(id)
        }
      },
      [completeTask, uncompleteTask]
    )

    const setIgnoreOutsideClicks = useCallback((value) => {
      setCalendarTaskProps((prev) => ({
        ...prev,
        ignoreOutsideClicks: value,
      }))
    }, [])

    const onClickDeleteFocusSession = useCallback(
      ({ focusSession }) => {
        setIgnoreOutsideClicks(true)

        const callback = () => {
          setIgnoreOutsideClicks(false)
        }

        _onClickDeleteFocusSession({ focusSession, callback })
      },
      [_onClickDeleteFocusSession, setIgnoreOutsideClicks]
    )

    const focusSessionPopupProps = useMemo(
      () => ({
        onDeleteFocusSession: onClickDeleteFocusSession,
        onClickFocusSession,
        onUpdateFocusSession: updateFocusSession,
      }),
      [onClickDeleteFocusSession, onClickFocusSession, updateFocusSession]
    )

    const getIgnoreOutsideClicks = useCallback(
      () => calendarTaskProps.ignoreOutsideClicks || sprintComposerProps.ignoreOutsideClicks,
      [calendarTaskProps.ignoreOutsideClicks, sprintComposerProps.ignoreOutsideClicks]
    )

    const onSavePageTask = useCallback(
      (task) => {
        clearLocalTaskState()
        saveTask(task)
      },
      [clearLocalTaskState, saveTask]
    )

    const onCreateInlineTask = useCallback(
      async (task, { componentSource = 'inlineAddTask', tmpId, dontShowCreationAlert } = {}) => {
        const response = await createInlineTask({
          context: { componentSource, pageSource: pageSource.planMyDay },
          dontShowCreationAlert,
          task,
          tmpId,
        })

        return response
      },
      [createInlineTask]
    )

    const onCreatePageTask = useCallback(
      (task, _, { eventName }) => {
        clearLocalTaskState({ keepCreatingTasks: eventName !== utils.task.clickOutside })
        onCreateInlineTask(task)
      },
      [clearLocalTaskState, onCreateInlineTask]
    )

    const onStartFsAndCreate = useCallback(
      (taskData, componentSource) => {
        startFsAndCreateTask({ context: { componentSource, pageSource: pageSource.planMyDay }, taskData })
        clearLocalTaskState()
      },
      [clearLocalTaskState, startFsAndCreateTask]
    )

    const startCalendarTaskAction = useCallback(
      ({ item, isCreating }) => {
        setCalendarTaskProps((prev) => {
          const newState = {
            ...prev,
            ignoreOutsideClicks: true,
          }

          if (isCreating) {
            newState.creatingCalendarTask = item
          } else {
            newState.editingCalendarTask = item
          }

          return newState
        })
        clearLocalTaskState()
      },
      [clearLocalTaskState]
    )

    const onTogglePin = useCallback(
      ({ item, isCreating }) => {
        const isPinned = item.pin && item.pin.time
        if (!isPinned) {
          const updatedItem = {
            ...item,
            pin: {
              time: moment(item.when.date, 'YYYY-MM-DD')
                .set({ hour: 9, minutes: 0, second: 0, milliseconds: 0 })
                .format(),
            },
          }
          startCalendarTaskAction({ item: updatedItem, isCreating })
        }
      },
      [startCalendarTaskAction]
    )

    const onDragStart = useCallback(
      (data) => {
        const { source } = data
        const draggingData = getDroppableId(source.droppableId)
        setDragData({ isDragging: true, draggingData })
      },
      [getDroppableId]
    )

    const onDragEnd = useCallback(() => setDragData({ isDragging: false, draggingData: undefined }), [])

    const onDropTaskInSprint = useCallback(
      async ({ destinationIndex, itemId, sprintId }) => {
        if (!sprintId || !itemId) return

        await onTaskDroppedInSprint({
          destinationIndex,
          sprintId,
          taskId: itemId,
        })
      },
      [onTaskDroppedInSprint]
    )

    const onDropInOtherSection = useCallback(
      async ({ destinationIndex, destinationGroup, itemId, sourceData }) => {
        if (destinationGroup === sections.PINNED) return

        const item = findItemById(itemId)

        if (!item) return

        const { extraParams, group: sourceGroup } = sourceData

        const rank = destinationIndex + 1
        const updatedItem = produce(item, (draft) => {
          draft.when.date = date
          draft.todaySectionRank = rank

          if (extraParams?.sprintId) {
            delete draft.sprintInfo
          }
        })

        let updatedItems = [updatedItem]

        if (sourceGroup === sections.OTHER) {
          updatedItems = itemList[sourceGroup].filter((sectionItem) => sectionItem.id !== updatedItem.id)
          updatedItems.splice(destinationIndex, 0, updatedItem)
          updatedItems = updatedItems.map((sectionItem, index) =>
            produce(sectionItem, (draft) => {
              draft.todaySectionRank = index + 1
            })
          )
        }

        dispatch(updateItems(updatedItems))

        await todayviewApi.dragAndDropTasksInTodaySection({
          taskId: updatedItem.id,
          toRank: rank,
          date: updatedItem.when.date,
          inTodaySection: false,
        })
      },
      [date, dispatch, findItemById, itemList]
    )

    const onDrop = useCallback(
      (draggableItem) => {
        if (!draggableItem.source || !draggableItem.destination) return

        const { id: draggableItemId, type: draggableType } = JSON.parse(draggableItem.draggableId)
        const sourceData = getDroppableId(draggableItem.source.droppableId)
        const { extraParams: destinationExtraParams, group: destinationGroup } = getDroppableId(
          draggableItem.destination.droppableId
        )

        if (draggableType !== models.item.type.SPRINT && destinationExtraParams?.sprintId) {
          onDropTaskInSprint({
            destinationIndex: draggableItem.destination.index,
            itemId: draggableItemId,
            sprintId: destinationExtraParams.sprintId,
          })
        } else if (destinationGroup) {
          onDropInOtherSection({
            destinationIndex: draggableItem.destination.index,
            destinationGroup,
            itemId: draggableItemId,
            sourceData,
          })
        }
      },
      [getDroppableId, onDropInOtherSection, onDropTaskInSprint]
    )

    const handleDragEnd = useCallback(
      (draggableItem) => {
        onDragEnd()
        onDrop(draggableItem)
      },
      [onDragEnd, onDrop]
    )

    const startInlineTaskCreation = useCallback(() => {
      setLocalTaskProps((prev) => ({
        ...prev,
        isCreatingInlineTask: true,
      }))
    }, [])

    const onTaskEditStart = useCallback(
      ({ item }) => {
        const { editingTask } = localTaskProps

        if (editingTask && item.id === editingTask.id) {
          setLocalTaskProps((prev) => ({ ...prev, editingTask: null }))
        }

        const isPinned = item.pin && item.pin.time

        if (isPinned) {
          startCalendarTaskAction({ item })
        }
      },
      [localTaskProps, startCalendarTaskAction]
    )

    const creationLineProps = useMemo(
      () => ({
        canBlockToCalendar: true,
        creating: true,
        ignoreOutsideClicks: calendarTaskProps.ignoreOutsideClicks,
        innerLeftPadding: 0,
        innerRightPadding: 0,
        item: localTaskProps.creatingTask || undefined,
        onCancel: cancelTaskAction,
        onCreate: onCreatePageTask,
        onStartFsAndCreate,
        onTogglePin,
        startCreation: !!localTaskProps.creatingTask || localTaskProps.keepCreatingTasks,
        startSprintCreation: sprintComposerProps.startSprintCreation,
        useTitleProps: !!localTaskProps.creatingTask,
        verticalView: shouldShowVerticalLine,
      }),
      [
        calendarTaskProps.ignoreOutsideClicks,
        cancelTaskAction,
        localTaskProps.creatingTask,
        localTaskProps.keepCreatingTasks,
        onCreatePageTask,
        onStartFsAndCreate,
        onTogglePin,
        shouldShowVerticalLine,
        sprintComposerProps.startSprintCreation,
      ]
    )

    const getPinnedTaskProps = useCallback(
      ({ item }) => {
        const isEditingTask = localTaskProps.editingTask && item.id === localTaskProps.editingTask.id
        const ignoreOutsideClicks = getIgnoreOutsideClicks()
        return {
          item: isEditingTask ? localTaskProps.editingTask : item,
          animateComplete: true,
          calendarEventInfoLeft: -75,
          displayTimeOnlyForEventInfo: true,
          keepJustCompleted: true,
          hideWhenDate: true,
          startSprintCreation: sprintComposerProps.startSprintCreation,
          onSave: onSavePageTask,
          onComplete,
          onDelete: onClickDelete,
          onEditStart: onTaskEditStart,
          innerLeftPadding: 0,
          innerRightPadding: 0,
          showCalendarEventInfo: true,
          ignoreOutsideClicks,
          startEdition: isEditingTask,
          onCancelEditTitle: isEditingTask && item.title,
          onTogglePin: onTogglePin,
          onCancel: cancelTaskAction,
          canBlockToCalendar: true,
          isCalendarDraggable: true,
          isDraggable: true,
          ...focusSessionPopupProps,
        }
      },
      [
        cancelTaskAction,
        focusSessionPopupProps,
        getIgnoreOutsideClicks,
        localTaskProps.editingTask,
        onClickDelete,
        onComplete,
        onSavePageTask,
        onTaskEditStart,
        onTogglePin,
        sprintComposerProps.startSprintCreation,
      ]
    )

    const getRegularTaskProps = useCallback(
      ({ item }) => {
        const isEditingTask = localTaskProps.editingTask && item.id === localTaskProps.editingTask.id
        const isItemPast = item.when?.date < moment().format('YYYY-MM-DD')
        const ignoreOutsideClicks = getIgnoreOutsideClicks()
        return {
          hideWhenDate: true,
          showCalendarEventInfo: isItemPast,
          canBlockToCalendar: true,
          isCalendarDraggable: true,
          startSprintCreation: sprintComposerProps.startSprintCreation,
          ignoreOutsideClicks,
          innerLeftPadding: 0,
          innerRightPadding: 0,
          item: isEditingTask ? localTaskProps.editingTask : item,
          key: item.id,
          startEdition: isEditingTask,
          onCancelEditTitle: isEditingTask && item.title,
          onEditStart: onTaskEditStart,
          onTogglePin: onTogglePin,
          onCancel: cancelTaskAction,
          animateComplete: true,
          keepJustCompleted: true,
          isDraggable: true,
          onSave: onSavePageTask,
          onComplete,
          onDelete: onClickDelete,
          onCreateSprint: sprintComposerProps.onCreateSprint,
          verticalView: shouldShowVerticalLine,
          ...focusSessionPopupProps,
        }
      },
      [
        cancelTaskAction,
        focusSessionPopupProps,
        getIgnoreOutsideClicks,
        localTaskProps.editingTask,
        onClickDelete,
        onComplete,
        onSavePageTask,
        onTaskEditStart,
        onTogglePin,
        shouldShowVerticalLine,
        sprintComposerProps.onCreateSprint,
        sprintComposerProps.startSprintCreation,
      ]
    )

    const getCompletedTaskProps = useCallback(
      ({ item }) => {
        const isEditingTask = localTaskProps.editingTask && item.id === localTaskProps.editingTask.id
        const ignoreOutsideClicks = getIgnoreOutsideClicks()
        return {
          hideMiddleRow: true,
          ignoreOutsideClicks,
          innerLeftPadding: 0,
          innerRightPadding: 0,
          item: isEditingTask ? localTaskProps.editingTask : item,
          isArchivable: true,
          key: item.id,
          lineThrough: true,
          onArchive: archiveTask,
          onComplete,
          onDelete: onClickDelete,
          onSave: onSavePageTask,
          ...focusSessionPopupProps,
        }
      },
      [
        archiveTask,
        focusSessionPopupProps,
        getIgnoreOutsideClicks,
        localTaskProps.editingTask,
        onClickDelete,
        onComplete,
        onSavePageTask,
      ]
    )

    const onRemoveFromSprint = useCallback(
      (task) => {
        const updatedTask = utils.task.computeTaskOnChange(task, {
          paramName: 'sprintInfo',
          value: null,
        })

        saveTask(updatedTask)
      },
      [saveTask]
    )

    const sprintTaskProps = useMemo(
      () => ({
        animateComplete: true,
        keepJustCompleted: true,
        hideScheduleSection: true,
        hideWhenDate: true,
        onSave: saveTask,
        onComplete,
        onDelete: onClickDelete,
        innerLeftPadding: innerNestedLeftPadding,
        innerRightPadding: innerNestedRightPadding,
        startSprintCreation: sprintComposerProps.startSprintCreation,
        isSprintTask: true,
        ...focusSessionPopupProps,
        onRemoveFromSprint,
      }),
      [
        focusSessionPopupProps,
        onClickDelete,
        onComplete,
        onRemoveFromSprint,
        saveTask,
        sprintComposerProps.startSprintCreation,
      ]
    )

    const onClickDeleteSprint = useCallback(
      (sprint) => {
        sprintDeletePopup(sprint, {
          onConfirmed: (recurrenceOption) => {
            deleteSprint(sprint.id, recurrenceOption)
          },
        })
      },
      [deleteSprint, sprintDeletePopup]
    )

    const onCreateInlineTaskFromSprint = useCallback(
      async (task) => {
        if (!task.sprintInfo) return

        await createInlineTask({
          context: { componentSource: 'sprint', pageSource: pageSource.planMyDay },
          dontShowCreationAlert: true,
          task,
        })
      },
      [createInlineTask]
    )

    const sprintLineProps = useMemo(
      () => ({
        displayTimeOnlyForEventInfo: true,
        onClickEdit: sprintComposerProps.onClickEditSprint,
        onClickDelete: onClickDeleteSprint,
        hideCalendarInfo: false,
        innerLeftPadding: 0,
        innerRightPadding: 0,
        isCalendarDraggable: true,
        onClickCallback: onClickSprint,
        onClickOutside: onClickOutsideSprint,
        onEnd: endSprint,
        onDeleteFocusSession: onClickDeleteFocusSession,
        sprintInlineTaskProps: {
          creating: true,
          hideBlockToCalendarOption: true,
          hideDateInput: true,
          hideScheduleSection: true,
          hideSprint: true,
          isCreating: true,
          isSprintTask: true,
          onCreate: onCreateInlineTaskFromSprint,
        },
        sprintTaskProps,
      }),
      [
        endSprint,
        onClickDeleteFocusSession,
        onClickDeleteSprint,
        onClickOutsideSprint,
        onClickSprint,
        onCreateInlineTaskFromSprint,
        sprintComposerProps.onClickEditSprint,
        sprintTaskProps,
      ]
    )

    const onArchiveAllCompletedToday = useCallback(() => {
      itemList[sections.COMPLETED].forEach((task) => {
        archiveTask(task)
      })
    }, [archiveTask, itemList])

    const clearCalendarTaskState = useCallback(() => {
      setCalendarTaskProps({
        creatingCalendarTask: null,
        editingCalendarTask: null,
        ignoreOutsideClicks: false,
      })
    }, [])

    const onSaveCalendarTask = useCallback(
      (task, options) => {
        clearCalendarTaskState()
        return saveTask(task, options)
      },
      [clearCalendarTaskState, saveTask]
    )

    const onCreateCalendarTask = useCallback(
      (task, options) => {
        clearCalendarTaskState()
        onCreateInlineTask(task, options)
      },
      [clearCalendarTaskState, onCreateInlineTask]
    )

    const cancelCalendarTaskAction = useCallback(() => {
      const { creatingCalendarTask, editingCalendarTask } = calendarTaskProps
      const task = creatingCalendarTask || editingCalendarTask

      if (task) {
        clearCalendarTaskState()
      }
    }, [calendarTaskProps, clearCalendarTaskState])

    const onTogglePinFromCalendarPanel = useCallback(
      ({ item, isCreating }) => {
        if (isCreating) {
          setLocalTaskProps((prev) => ({ ...prev, creatingTask: item }))
        } else {
          setLocalTaskProps((prev) => ({ ...prev, editingTask: item }))
        }

        clearCalendarTaskState()
      },
      [clearCalendarTaskState]
    )

    const toHideTaskId = getFocusedTaskId()

    return (
      <Component
        {...props}
        calendarItems={calendarItems}
        cancelCalendarTaskAction={cancelCalendarTaskAction}
        creatingCalendarTask={calendarTaskProps.creatingCalendarTask}
        creatingSprint={sprintComposerProps.creatingSprint}
        creationLineProps={creationLineProps}
        editingCalendarTask={calendarTaskProps.editingCalendarTask}
        editingSprint={sprintComposerProps.editingSprint}
        isDragging={dragData.isDragging}
        date={date}
        firstDay={session.user.settingsPreferences.calendar.firstDay}
        getCompletedTaskProps={getCompletedTaskProps}
        getPinnedTaskProps={getPinnedTaskProps}
        getRegularTaskProps={getRegularTaskProps}
        handleDragEnd={handleDragEnd}
        inProgressSprints={inProgressSprints}
        isCreatingInlineTask={localTaskProps.isCreatingInlineTask}
        isSprintComposerShown={sprintComposerProps.isSprintComposerShown}
        isTaskCreationAlertShown={isTaskCreationAlertShown}
        items={itemList}
        onArchiveAllCompletedToday={onArchiveAllCompletedToday}
        onClickDelete={onClickDelete}
        onComplete={onComplete}
        onCompleteFromFS={completeTaskFromFS}
        onCreateCalendarTask={onCreateCalendarTask}
        onCreateSprint={sprintComposerProps.onCreateSprint}
        onDragStart={onDragStart}
        onSave={saveTask}
        onSaveCalendarTask={onSaveCalendarTask}
        onTogglePinFromCalendarPanel={onTogglePinFromCalendarPanel}
        pageSource={pageSource.planMyDay}
        session={session}
        sprintLineProps={sprintLineProps}
        sprintTaskProps={sprintTaskProps}
        startInlineTaskCreation={startInlineTaskCreation}
        startSprintCreation={sprintComposerProps.startSprintCreation}
        startSprintEdition={sprintComposerProps.startSprintEdition}
        toHideTaskId={toHideTaskId}
      />
    )
  }

  return PlanMyDayLightboxContainer
}
