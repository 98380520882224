import resolve from './resolve'

/* 
    TaskPatcher is an util to chain patch requests for consistency and delaying.
    The procedure for chaining is the folllowing:
    - each parameter has its own patch queue, with 'change' overriding the queue and 'add', 'remove' appending
      to the queue
    - when running the patch chain, the queue is popped iteratively by popping the action with the earliest timestamp
    - we always wait for the previous action to be done before popping the next one
    - there is a delay before starting the chain, in order to avoid patching too soon after the first request 
      (eg. when updating the description, title etc.)
*/
class TaskPatcher {
  constructor(delay = 1000) {
    this.delay = delay
    this.pending = {}
    this.running = false
    this.delayKey = Math.random()
  }

  put = (paramName, method, request) => {
    const payload = { request, timestamp: new Date().getTime(), paramName, method }
    switch (method) {
      case 'change':
        this.pending[paramName] = [payload]
        break
      default:
        if (!this.pending[paramName]) {
          this.pending[paramName] = []
        }
        this.pending[paramName].push(payload)
    }
    if (!this.running) {
      resolve.once(this.delayKey, this.run, this.delay)
    }
  }

  run = async () => {
    this.running = true
    const next = this.popNext()
    if (!next) {
      this.running = false
    } else {
      const { request } = next
      await request()
      this.run()
    }
  }

  popNext = () => {
    let earliestKey
    let earliestTimestamp = Infinity
    for (let k of Object.keys(this.pending)) {
      const events = this.pending[k]
      if (events.length > 0) {
        const { timestamp } = events[0]
        if (timestamp < earliestTimestamp) {
          earliestKey = k
          earliestTimestamp = timestamp
        }
      }
    }
    if (!earliestKey) {
      return null
    }
    return this.pending[earliestKey].shift()
  }
}

export default TaskPatcher
