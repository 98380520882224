import { getWindowSize } from 'utils/device'
import Observer from 'utils/observer'

export const stopPropagation = (e) => {
  e.stopPropagation()
  return e
}

/*
  please bind this function to your component with a callback param
  this.onResize = utils.DOM.onResizeEnd.bind(this, this.calculateHeight)
*/
export const onResizeEnd = function (duration = 1000, callback) {
  clearTimeout(this.resizeTimeout)
  this.resizeTimeout = setTimeout(callback, duration)
}

export function isElementVisible(el) {
  const rect = el.getBoundingClientRect()
  const elemTop = rect.top
  const elemBottom = rect.bottom
  return elemTop >= 0 && elemBottom <= window.innerHeight
}

export const preventDefault = (e) => {
  e = e || window.event
  if (e.preventDefault) {
    e.preventDefault()
  }
  e.returnValue = false
}

export const returnFalse = (e) => {
  e.returnValue = false
}

export const disableScroll = (e) => {
  /* older ffx */
  if (window.addEventListener) {
    window.addEventListener('DOMMouseScroll', preventDefault, false)
  }
  /* standard */
  window.onwheel = preventDefault
  /* older browsers, ie */
  window.onmousewheel = document.onmousewheel = preventDefault
  /* mobile */
  window.addEventListener('touchmove', returnFalse, { passive: true })
}

export const enableScroll = () => {
  if (window.removeEventListener) {
    window.removeEventListener('DOMMouseScroll', preventDefault, false)
  }
  window.onmousewheel = document.onmousewheel = null
  window.onwheel = null
  window.removeEventListener('touchmove', returnFalse, { passive: true })
}

export const scrollWhenExistsToElementWithId = (id, scrollContainer) => {
  /* do not console.log scrollContainer : freezes the app (large document element) */
  if (typeof scrollContainer === 'string') {
    scrollContainer = document.getElementById(scrollContainer)
  }
  if (!scrollContainer) {
    console.debug(`scrollContainer undefined`)
    return Promise.resolve()
  }
  return new Promise((resolve, reject) => {
    if (!id) {
      console.debug(`scrollToElementById - id undefined`)
      resolve()
      return
    }
    const observer = new Observer()
    observer.when(`[id='${id}']`, () =>
      setTimeout(() => {
        const element = document.getElementById(id)
        if (!element) {
          console.debug(`scrollToElementById - DOM element with id ${id} not found`)
          resolve()
          return
        }

        /* scroll to element (center of container) */
        let scrollTo = element.getBoundingClientRect().top + scrollContainer.scrollTop - getWindowSize().height / 2
        if (scrollTo < 0) {
          scrollTo = 0
        }

        scrollContainer = scrollContainer || window
        scrollContainer.scrollTo &&
          scrollContainer.scrollTo({
            top: scrollTo,
            behavior: 'smooth',
          })

        resolve()
        observer.disconnect()
      }, 200)
    )
  })
}
