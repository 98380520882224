import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { styles } from 'gipsy-misc'
import media from 'styles/media'

export default class ContextMenu extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    isActive: PropTypes.object,
  }

  state = {
    clientY: 0,
    clientX: 0,
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive && !prevProps.isActive) {
      const x = this.props.clientX ? this.props.isActive.clientX + this.props.clientX : this.props.isActive.clientX
      let y = this.props.clientY ? this.props.isActive.clientY + this.props.clientY : this.props.isActive.clientY

      const menuNode = document.querySelector('.context-menu--active')

      if (menuNode) {
        const bounds = menuNode.getBoundingClientRect()

        if (y + bounds.height >= window.innerHeight) {
          y = window.innerHeight - bounds.height
        }
      }

      this.setState({
        clientY: y,
        clientX: x,
      })
    }
  }

  render() {
    const { setRef, children, isActive } = this.props
    const { clientX, clientY } = this.state

    return (
      <Container
        ref={setRef}
        className={`context-menu ${isActive && 'context-menu--active'}`}
        isActive={isActive}
        clientX={clientX}
        clientY={clientY}>
        {children}
      </Container>
    )
  }
}

const Container = styled.div`
  position: fixed;
  z-index: 10000;
  background-color: white;
  min-width: 200px;
  padding: 10px;
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 4px;
  box-shadow: 0px 1px 2px ${styles.colors.middleGrey};
  left: ${({ clientX }) => (clientX ? `${clientX + 20}px` : '0px')};
  top: ${({ clientY }) => (clientY ? `${clientY - 20}px` : '0px')};
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in, visibility 0s linear 0.2s;
  ${media['phone-wide']`
    display: none;
  `}
  ${({ isActive, clientX, clientY }) =>
    isActive &&
    css`
      transition: opacity 0.2s ease-in, visibility 0s linear 0s;
      visibility: visible;
      opacity: 1;
    `}
`
