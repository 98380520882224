import { storageAvailable } from 'utils/storage'

const isTaskPanelExpandedKey = 'isTaskPanelExpanded'

export const getIsExpanded = () => {
  if (storageAvailable('localStorage')) {
    return window.localStorage.getItem(isTaskPanelExpandedKey) !== 'false'
  }
  return true
}
export const setIsExpanded = (value) => {
  if (storageAvailable('localStorage')) {
    window.localStorage.setItem(isTaskPanelExpandedKey, value)
  }
}
