import React from 'react'
import styled from 'styled-components'

import Icon from 'Icon'
import Separator from 'Separator'

import { styles } from 'gipsy-misc'

export default function ActiveSeparator(props) {
  const { className, onClick, title, titleFontSize, titleColor, isExpanded } = props

  return (
    <Container className={className} onClick={onClick}>
      <TitleContainer fontSize={titleFontSize} color={titleColor}>
        {title}
      </TitleContainer>
      <Separator />
      <Icon size={10} icon={isExpanded ? 'SingleChevronDown' : 'SingleChevronLeft'} />
    </Container>
  )
}

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${Separator} {
    flex-grow: 1;
    margin: 0 8px;
  }
`

export const TitleContainer = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.color || styles.colors.textMediumDarkColor};
  font-size: ${(props) => props.fontSize || 11}px;
  flex-grow: 0;
  flex-shrink: 1;
`
