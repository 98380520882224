import moment from 'moment'
import qs from 'qs'

import { getUTMInfoFromLocalStorage, clearUTMInfoFromLocalStorage } from 'logic/login'

import { session } from 'gipsy-api'

const extractStateParameters = (location) => {
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const nonce = window.sessionStorage.getItem('nonceOAuth') || ''

  const utmInfo = getUTMInfoFromLocalStorage()

  const res = {
    error: searchParams.error,
    state: searchParams.state,
    localStateGoogle: window.sessionStorage.getItem('stateOAuth-google'),
    localStateMicrosoft: window.sessionStorage.getItem('stateOAuth-microsoft'),
    code: searchParams.code,
    nonce,
    tz: moment.tz.guess(),
    redirectUri: searchParams.redirectUri || null,
    utmInfo: utmInfo,
  }

  clearUTMInfoFromLocalStorage()

  window.sessionStorage.removeItem('stateOAuth-microsoft')
  window.sessionStorage.removeItem('stateOAuth-google')
  window.sessionStorage.removeItem('nonceOAuth')

  return res
}

export const handleOAuth = async (location) => {
  const {
    error,
    state,
    localStateGoogle,
    localStateMicrosoft,
    code,
    tz,
    nonce,
    redirectUri,
    utmInfo,
  } = extractStateParameters(location)

  const querySucceeded = !error && code
  if (!querySucceeded) {
    throw new Error('Query failed.')
  }
  let data
  if (state === localStateGoogle) {
    data = await session.authenticate('google', code, nonce, tz, redirectUri, utmInfo)
    data.emailProvider = 'google'
  } else if (state === localStateMicrosoft) {
    data = await session.authenticate('microsoft', code, nonce, tz, redirectUri, utmInfo)
    data.emailProvider = 'microsoft'
  } else {
    throw new Error('Unknown OAuth method.')
  }

  data.utmInfo = utmInfo
  return data
}
