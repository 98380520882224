export const adjustColorByBrightness = (backgroundColor, colorToUse, fallbackColor = '#000000') => {
  const hexPattern = new RegExp(/^#([\da-f]{2})([\da-f]{2})([\da-f]{2})([\da-f]{2})?$/, 'i')
  const match = hexPattern.exec(backgroundColor)

  if (!match) return colorToUse

  const r = parseInt(match[1], 16)
  const g = parseInt(match[2], 16)
  const b = parseInt(match[3], 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000 // YIQ color conversion formula
  return yiq >= 160 ? fallbackColor : colorToUse
}
