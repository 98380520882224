import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import find from 'lodash/find'

const propTypes = {
  paramName: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hideArrow: PropTypes.bool,
  className: PropTypes.string,
}

const defaultProps = {
  placeholder: 'Select...',
  hideArrow: false,
  className: null,
}

class Dropdown extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick, false)
  }

  toggle = (event) => {
    if (this.props.disabled) {
      return
    }
    if (this.props.onFocus && typeof this.props.onFocus === 'function') {
      this.props.onFocus(this.state.isOpen)
    }
    event.stopPropagation()
    event.preventDefault()

    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  setValue = (value, label) => {
    const newState = {
      isOpen: false,
    }
    if (value !== this.props.value && this.props.onChange && typeof this.props.onChange === 'function') {
      this.props.onChange({ paramName: this.props.paramName, value })
    }
    this.setState(newState)
  }

  renderOption(option) {
    let value = option.value
    if (typeof value === 'undefined') {
      value = option.label || option
    }
    const label = option.label || option.value || option
    const isSelected = value === this.props.value

    return (
      <div
        key={value}
        className={'dropdown-option' + (isSelected ? ' is-selected' : '')}
        onClick={() => this.setValue(value, label)}>
        {label}
      </div>
    )
  }

  buildMenu() {
    const { options } = this.props
    const ops = options.map((option) => {
      if (option.type === 'group') {
        const groupTitle = <div className="dropdown-title">{option.name}</div>
        const _options = option.items.map((item) => this.renderOption(item))

        return (
          <div className="dropdown-group" key={option.name}>
            {groupTitle}
            {_options}
          </div>
        )
      } else {
        return this.renderOption(option)
      }
    })

    return ops.length ? ops : <div className="dropdown-noresults">No options found</div>
  }

  handleDocumentClick = (event) => {
    if (!ReactDOM.findDOMNode(this).contains(event.target)) {
      if (this.state.isOpen) {
        this.setState({ isOpen: false })
      }
    }
  }

  findLabelWithValue = (value) => {
    const option = find(this.props.options, ['value', value])
    return option ? option.label : null
  }

  render() {
    const { className, hideArrow, disabled, placeholder, value } = this.props
    const { isOpen } = this.state
    const isOpenClass = isOpen ? ' is-open' : ''
    const disabledClass = disabled ? ' dropdown-disabled' : ''
    const hideArrowClass = hideArrow ? ' dropdown-no-arrow' : ''
    const placeHolderValue = this.findLabelWithValue(value) || placeholder

    return (
      <div className={'dropdown-root' + isOpenClass + (className ? ` ${className}` : '')}>
        <div className={'dropdown-control' + disabledClass + hideArrowClass} onClick={this.toggle}>
          <div className="dropdown-placeholder">{placeHolderValue}</div>
          {!hideArrow && <span className="dropdown-arrow" />}
        </div>
        {isOpen && <div className="dropdown-menu">{this.buildMenu()}</div>}
      </div>
    )
  }
}

Dropdown.defaultProps = defaultProps
Dropdown.propTypes = propTypes

export default Dropdown
