import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { utils } from 'gipsy-misc'

const propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  ignoreOverflow: PropTypes.bool,
  hz: PropTypes.number,
  vt: PropTypes.number,
}

const defaultProps = {
  vs: 50 /* vertical space */,
  hs: 50 /* horizontal space */,
}

class Tooltip extends PureComponent {
  constructor(props) {
    super(props)

    this.id = utils.string.random()
    this.state = {
      forceX: 0,
    }
  }

  componentDidMount() {
    this.avoidOverflow()
    window.addEventListener('resize', this.onResizeWindow)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow)
  }

  onResizeWindow = async () => {
    utils.resolve.once(`resize-window-${this.id}`, this.avoidOverflow, 600)
  }

  avoidOverflow = () => {
    const { position, hs, ignoreOverflow } = this.props
    if (!this.tooltip) {
      return
    }
    const windowSize = utils.device.getWindowSize()
    const element = this.tooltip.getBoundingClientRect()
    const parent = this.tooltip.parentElement.getBoundingClientRect()

    const overflowLeft = !ignoreOverflow ? element.width + hs - (parent.left + parent.width) : 0
    const overflowRight = !ignoreOverflow ? parent.left + element.width + hs - windowSize.width : 0
    const awayFromBorder = 20

    /* prevent tooltip to overflow the window */
    if (position.indexOf('left') !== -1 && overflowLeft > 0) {
      this.setState({
        forceX: -(overflowLeft - hs + awayFromBorder),
      })
    } else if (position.indexOf('right') !== -1 && overflowRight > 0) {
      this.setState({
        forceX: -(overflowRight - hs + awayFromBorder),
      })
    } else {
      this.setState({
        forceX: 0,
      })
    }
  }

  render() {
    const { overridePosition, text, position, vs, hs } = this.props
    const { forceX } = this.state
    let style = {}

    if (!overridePosition) {
      if (position.indexOf('top') !== -1) {
        style.bottom = vs
      } else {
        style.top = vs
      }

      if (position.indexOf('left') !== -1) {
        style.right = forceX ? forceX : hs
      } else {
        style.left = forceX ? forceX : hs
      }
    }

    return (
      <div
        ref={(ref) => (this.tooltip = ref)}
        className={'tooltip ' + (forceX === 0 || overridePosition ? position : '')}
        style={style}>
        {text}
      </div>
    )
  }
}

Tooltip.propTypes = propTypes
Tooltip.defaultProps = defaultProps

export default Tooltip
