import React, { useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { CustomPopup, Icon, SimpleButton } from 'gipsy-ui'

import { popupStyles, PopupTail, TailWrapper } from './commonUIComponents'
import RecurrencyDayPicker from './RecurrencyDayPicker'

export default function CustomRecurrenceSettingsPopup({
  className,
  customTypeSettings,
  firstDayOfWeek,
  fullWidth,
  onCancel,
  onClickOutside,
  onSave,
}) {
  const [daysSelected, setDaysSelected] = useState(() => {
    if (!customTypeSettings?.days) return {}

    return customTypeSettings?.days.reduce((selected, dayNumber) => {
      selected[dayNumber] = true
      return selected
    }, {})
  })
  const [popupPosition, setPopupPosition] = useState({})
  const [popupWidth, setPopupWidth] = useState(null)

  const popupRef = useRef(null)

  const handleChange = (updatedDaysSelected) => {
    setDaysSelected(updatedDaysSelected)
  }

  const handleSave = () => {
    onSave(
      Object.keys(daysSelected).reduce((days, dayKey) => {
        if (!daysSelected[dayKey]) return days

        days.push(Number.parseInt(dayKey))
        return days
      }, [])
    )
  }

  useLayoutEffect(() => {
    const trackPopupWidth = () => {
      if (popupRef.current) {
        setPopupWidth(popupRef.current.clientWidth)
      }
    }

    trackPopupWidth()
    window.addEventListener('resize', trackPopupWidth)
    return () => {
      window.removeEventListener('resize', trackPopupWidth)
    }
  }, [])

  useLayoutEffect(() => {
    if (!popupRef.current) return

    const popupBounds = popupRef.current.getBoundingClientRect()
    const maxTop = document.documentElement.clientHeight - popupBounds.height
    let top = popupBounds.top

    if (top >= maxTop) {
      top = 0 - popupBounds.height - 14

      setPopupPosition({
        top: `${top}px`,
      })
    }
  }, [])

  const shrink = !fullWidth && popupWidth < 437

  return (
    <Popup
      className={`CustomRecurrenceSettingsPopup ${className}`}
      flipTail={!!popupPosition?.top}
      fullWidth={fullWidth}
      onClickOutside={onClickOutside}
      ref={popupRef}
      shrinked={shrink}
      style={popupPosition}>
      <PopupTail />
      <BackTrail onClick={onCancel}>
        <LeftCaret fill={styles.colors.textMediumDarkColor} icon='SingleChevronLeft' size={10} />
        {translations.sprint.recurrency.customRecurrence}
      </BackTrail>
      <RecurrencyDayPicker
        daysSelected={daysSelected}
        firstDayOfWeek={firstDayOfWeek}
        onChange={handleChange}
        shrinked={shrink}
      />
      <Buttons>
        <SimpleButton
          backgroundColor={styles.colors.veryLightGrey}
          height={32}
          onClick={onCancel}
          text={translations.general.cancel}
          textColor={styles.colors.textMediumDarkColor}
          width={62}
        />
        <SaveButton
          backgroundColor={styles.colors.primaryColor}
          onClick={handleSave}
          height={32}
          text={translations.general.save}
          textColor={'white'}
          width={62}
        />
      </Buttons>
    </Popup>
  )
}

const Popup = styled(CustomPopup)`
  ${popupStyles}

  display: flex;
  flex-flow: column;
  max-width: 437px;
  min-width: 0;
  padding: 22px;
  width: 100%;

  ${TailWrapper} {
    left: 56px;
  }

  ${({ flipTail }) =>
    flipTail &&
    css`
      ${TailWrapper} {
        bottom: -11px;
        top: unset;
        transform: rotate(-180deg);
      }
    `}

  ${({ shrinked }) =>
    shrinked &&
    css`
      padding: 12px;
      width: max-content;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 437px;
    `}
`

Popup.displayName = 'Popup'

const LeftCaret = styled(Icon)`
  margin-right: 13px;
`

LeftCaret.displayName = 'LeftCaret'

const BackTrail = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-right: auto;

  &:hover {
    color: ${styles.colors.primaryColor};

    ${LeftCaret} {
      path {
        fill: ${styles.colors.primaryColor};
      }
    }
  }
`

BackTrail.displayName = 'BackTrail'

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`

Buttons.displayName = 'Buttons'

const SaveButton = styled(SimpleButton)`
  margin-left: 16px;
`

SaveButton.displayName = 'SaveButton'
