import React from 'react'
import styled from 'styled-components'
import { transitionDurationMs } from '../sharedVariables'

import { SimpleButton, DeleteButton } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

function Footer(props) {
  const { isShown, customCancelButton, onCreate, onCancel, onDelete, isLoading, createButtonLabel } = props
  return (
    <FooterContainer isShown={isShown}>
      {onDelete && <DeleteButton onClick={onDelete} />}
      <RightButtonsContainer>
        <SimpleButton
          height={40}
          width={customCancelButton && customCancelButton.width ? customCancelButton.width : 100}
          onClick={onCancel}
          disabled={isLoading}
          backgroundColor={
            customCancelButton && customCancelButton.textColor ? customCancelButton.backgroundColor : 'white'
          }
          borderColor={
            customCancelButton && customCancelButton.borderColor
              ? customCancelButton.borderColor
              : styles.colors.primaryColor
          }
          textColor={
            customCancelButton && customCancelButton.textColor
              ? customCancelButton.textColor
              : styles.colors.primaryColor
          }
          text={customCancelButton && customCancelButton.text ? customCancelButton.text : translations.general.cancel}
        />
        <SimpleButton
          width={175}
          height={40}
          backgroundColor={styles.colors.primaryColor}
          textColor={'white'}
          onClick={onCreate}
          text={createButtonLabel}
          disabled={isLoading}
        />
      </RightButtonsContainer>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  background-color: transparent;
  transition: transform ${transitionDurationMs}ms ease-in-out;
  transition-delay: ${transitionDurationMs}ms;
  transform: translateY(${(props) => (props.isShown ? 0 : '100%')});
`

const RightButtonsContainer = styled.div`
  display: flex;
  margin: 0 auto;

  & > :first-child {
    margin-right: ${styles.spacings.xsmall};
  }
`

export default React.memo(Footer)
